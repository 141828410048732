/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { useTheme } from 'bold-ui'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { useErrorHandler } from 'components/error'
import { useInformarFaltaCidadaoMutation } from 'graphql/hooks.generated'
import { ReactComponent as UserWarning } from 'images/agenda/user-warning.svg'
import Permissions from 'types/Permissions'

import MenuAcoesAgendamentoButton from '../MenuAcoesAgendamentoButton'
import { AcaoAgendamentoButtonProps } from '../model'

export function InformarFaltaCidadaoButton(props: AcaoAgendamentoButtonProps) {
  const { event, disabled, tooltip, isDropdown } = props
  const alert = useAlert()
  const theme = useTheme()
  const handleException = useErrorHandler()
  const [informarFalta, { loading }] = useInformarFaltaCidadaoMutation()

  const onClick = () => {
    informarFalta({ variables: { agendamentoId: event.id } })
      .then(() => {
        alert('success', `Falta foi registrada para o cidadão ${event.nomeCidadao.titleCase()}.`)
      })
      .catch(handleException)
  }

  return (
    <CheckPermission permission={Permissions.visualizarAgenda.informarFaltaDoCidadao}>
      <MenuAcoesAgendamentoButton
        text='Informar falta do cidadão'
        tooltip={tooltip}
        disabled={disabled}
        loading={loading}
        isDropdown={isDropdown}
        onClick={onClick}
        icon={
          <UserWarning
            css={css`
              fill: ${theme.pallete.text.main};
            `}
          />
        }
      />
    </CheckPermission>
  )
}
