/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, VFlow } from 'bold-ui'
import { DateField, TextAreaField } from 'components/form'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Fragment } from 'react'
import { MetaPath } from 'util/metaPath'

import { ExameQueryModel, ResultadoExameEspecificoModel } from '../../model'
import { getResultadoExameEspecificoField } from '../../util'
import { ResultadoExameExpandableContainer } from '../components/ResultadoExameExpandableContainer'

interface ResultadoExameEspecificoFieldProps {
  name: MetaPath<ResultadoExameEspecificoModel>
  exame: ExameQueryModel
  expanded: boolean
  disableOtherFields: boolean
  hasSolicitacao?: boolean
  dataSolicitacao?: Date
}

export const ResultadoExameEspecificoField = (props: ResultadoExameEspecificoFieldProps) => {
  const { name, exame, hasSolicitacao, dataSolicitacao, disableOtherFields, expanded } = props

  const {
    atendimentoProfissional: { iniciadoEm },
  } = useAtendimentoContext()

  const ResultadoExameEspecificoField = getResultadoExameEspecificoField(exame)

  return (
    ResultadoExameEspecificoField && (
      <Fragment>
        <ResultadoExameEspecificoField
          name={name.resultado}
          exame={exame}
          expanded={expanded}
          dataSolicitacao={dataSolicitacao}
        />

        <ResultadoExameExpandableContainer expanded={expanded}>
          <VFlow
            style={css`
              margin-top: 1rem;
            `}
          >
            <TextAreaField
              name={name.descricao}
              label='Descrição'
              maxLength={2000}
              disabled={disableOtherFields}
              style={css`
                resize: vertical;
              `}
            />

            <Grid gap={1.5}>
              {!hasSolicitacao && (
                <Fragment>
                  <Cell size={5}>
                    <DateField
                      name={name.dataSolicitado}
                      label='Solicitado em'
                      disabled={disableOtherFields}
                      maxDate={iniciadoEm}
                      minDate={hasSolicitacao && dataSolicitacao}
                      inline
                    />
                  </Cell>

                  <Cell size={7} />
                </Fragment>
              )}

              <Cell size={5}>
                <DateField
                  name={name.dataRealizado}
                  label='Realizado em'
                  disabled={disableOtherFields}
                  maxDate={iniciadoEm}
                  minDate={hasSolicitacao && dataSolicitacao}
                  required
                  inline
                />
              </Cell>
              <Cell size={5}>
                <DateField
                  name={name.dataResultado}
                  label='Resultado em'
                  disabled={disableOtherFields}
                  maxDate={iniciadoEm}
                  minDate={hasSolicitacao && dataSolicitacao}
                  inline
                />
              </Cell>
            </Grid>
          </VFlow>
        </ResultadoExameExpandableContainer>
      </Fragment>
    )
  )
}
