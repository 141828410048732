import { confirm } from 'components/modals/confirm'
import { format } from 'date-fns'
import { useAgendamentosConflitantesQuery } from 'graphql/hooks.generated'
import { useCallback } from 'react'

export function useVerificarAgendamentosConflitantes() {
  const { loading, refetch: refetchAgendamentosConflitantes } = useAgendamentosConflitantesQuery({
    skip: true,
  })

  const verificarAgendamentosConflitantes = useCallback(
    async (cidadaoId: ID, lotacaoId: ID, horario: number, nomeCidadao: string) => {
      if (!lotacaoId || !cidadaoId || !horario) return true

      const response = await refetchAgendamentosConflitantes({
        input: {
          cidadaoId,
          lotacaoId,
          horario,
        },
      })

      const {
        nomesOutrosProfissionaisAgendamentosConflitantes,
        possuiAgendamentoMesmoProfissionalDataFutura,
      } = response.data

      let confirmed = true

      if (nomesOutrosProfissionaisAgendamentosConflitantes?.length)
        confirmed = await confirmProfissionaisConflitantes(
          nomesOutrosProfissionaisAgendamentosConflitantes,
          horario,
          nomeCidadao
        )

      if (confirmed && possuiAgendamentoMesmoProfissionalDataFutura)
        confirmed = await confirmAgendamentoMesmoProfissional(
          horario,
          nomeCidadao,
          possuiAgendamentoMesmoProfissionalDataFutura
        )

      return confirmed
    },
    [refetchAgendamentosConflitantes]
  )

  return {
    verificarAgendamentosConflitantes,
    loading,
  }
}

const confirmAgendamentoMesmoProfissional = (horario: number, nomeCidadao: string, dataOutroAgendamento: Instant) =>
  confirm({
    title: `Deseja salvar um agendamento no dia ${format(horario, 'dd/MM/yyyy')} às ${format(horario, 'HH:mm')}?`,
    body: `O cidadão ${nomeCidadao.titleCase()} já possui um agendamento com o mesmo profissional no dia ${format(
      new Date(dataOutroAgendamento),
      'dd/MM/yyyy'
    )}.`,
    confirmLabel: 'Salvar agendamento',
    depthLevel: 2,
  })()

const confirmProfissionaisConflitantes = (
  nomesOutrosProfissionaisAgendamentosConflitantes: string[],
  horario: number,
  nomeCidadao: string
) =>
  confirm({
    title: `Deseja salvar um agendamento no dia ${format(horario, 'dd/MM/yyyy')} às ${format(horario, 'HH:mm')}?`,
    body: `O cidadão ${nomeCidadao.titleCase()} já possui agendamento com ${nomesOutrosProfissionaisAgendamentosConflitantes
      .join(', ')
      .replaceLastOccurrence(',', ' e')
      .titleCase()} no mesmo horário.`,
    confirmLabel: 'Salvar agendamento',
    depthLevel: 2,
  })()
