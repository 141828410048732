import { FormControl, HFlow, VFlow } from 'bold-ui'
import { ErrorField, RadioField } from 'components/form'
import React from 'react'
import { MetaPath } from 'util/metaPath'

export interface NecessidadeProteseFieldProps {
  name: MetaPath<string>
}

export function NecessidadeProteseField(props: NecessidadeProteseFieldProps) {
  const { name } = props

  return (
    <VFlow>
      <FormControl label='Necessidade de prótese inferior/superior' required>
        <VFlow vSpacing={0.5}>
          <ErrorField name={name} />

          <HFlow style={{ marginLeft: '-0.25rem' }}>
            <RadioField name={name} label='Não avaliado' value='null' />
            <RadioField name={name} label='Não' value='false' />
            <RadioField name={name} label='Sim' value='true' />
          </HFlow>
        </VFlow>
      </FormControl>
    </VFlow>
  )
}
