import { Omit, SelectHelperMenuItem, VFlow } from 'bold-ui'
import locale from 'bold-ui/lib/i18n/locales/pt-BR'
import { SelectField, SelectFieldProps } from 'components/form'
import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { HLabel } from 'components/HLabel'
import { Cpf } from 'components/label'
import { LotacaoSelectFieldDocument } from 'graphql/hooks.generated'
import { LotacaoSelectFieldQuery, LotacaoSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'

export type LotacaoSelectFieldModel = LotacaoSelectFieldQuery['lotacoes']['content'][0]

export interface LotacaoSelectFieldProps
  extends Omit<SelectFieldProps<LotacaoSelectFieldModel>, 'items' | 'itemToString'> {
  unidadeSaudeId?: string
  equipeId?: string
  excludeProfissional?: ID
  loadItemsOnOpen?: boolean
  possuiAgendaCadastrada?: boolean
}

LotacaoSelectField.defaultProps = {
  loadItemsOnOpen: true,
} as Partial<LotacaoSelectFieldProps>

export function LotacaoSelectField(props: LotacaoSelectFieldProps) {
  const { loadItemsOnOpen, unidadeSaudeId, equipeId, excludeProfissional, possuiAgendaCadastrada, ...rest } = props

  const { skiping, ...asyncProps } = useAsyncQuerySelect<
    LotacaoSelectFieldModel,
    LotacaoSelectFieldQuery,
    LotacaoSelectFieldQueryVariables
  >({
    query: LotacaoSelectFieldDocument,
    extractItems: (data) => data?.lotacoes.content,
    variables: (inputString) => ({
      input: {
        query: inputString,
        unidadeSaude: unidadeSaudeId,
        equipe: equipeId,
        ativo: true,
        canWriteAtendimento: true,
        excludeProfissional: excludeProfissional,
        possuiAgendaCadastrada: possuiAgendaCadastrada,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
    }),
    skip: (inputString) => (!loadItemsOnOpen ? inputString?.trim()?.length < 2 : undefined),
    debounceTime: 500,
  })

  const itemToString = (item: LotacaoSelectFieldModel) => item && item.profissional.nome

  const renderItem = (item: LotacaoSelectFieldModel) => {
    return (
      item && (
        <>
          <VFlow vSpacing={0}>
            <b>
              {item.profissional.nome} - <Cpf value={item.profissional.cpf} />
            </b>
            <HLabel title='CBO '>{`${item.cbo.nome} - ${item.cbo.cbo2002}`}</HLabel>
            <HLabel title='INE '> {(item.equipe && `${item.equipe.nome} - ${item.equipe.ine}`) || 'Sem equipe'}</HLabel>
            {!unidadeSaudeId && (
              <HLabel title='Unidade de saúde '>
                {item.unidadeSaude.nome} - {item.unidadeSaude.cnes}
              </HLabel>
            )}
          </VFlow>
        </>
      )
    )
  }

  return (
    <SelectField<LotacaoSelectFieldModel>
      itemToString={itemToString}
      renderItem={renderItem}
      components={
        !loadItemsOnOpen && {
          EmptyItem: () => (
            <SelectHelperMenuItem>
              {skiping ? 'Digite para buscar um profissional.' : locale.select.emptyItem}
            </SelectHelperMenuItem>
          ),
        }
      }
      {...asyncProps}
      {...rest}
    />
  )
}
