/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, Theme, useTheme } from 'bold-ui'
import { AccordionField } from 'components/form/field/AccordionField'
import { useMemo } from 'react'
import { AccordionItem, AccordionItemPanel } from 'react-accessible-accordion'
import { MetaArray } from 'util/metaPath'

import { FormAccordionButton } from './FormSectionAccordionButton'

export interface FormSectionAccordionProps {
  title: string
  children: React.ReactNode
  uuid: string
  name: MetaArray<string | number>
  style?: ExternalStyles
}

export function FormSectionAccordion(props: FormSectionAccordionProps) {
  const { title, children, name, uuid, style } = props
  const theme = useTheme()
  const classes = useMemo(() => createStyles(theme), [theme])

  return (
    <div css={css({ margin: '1rem 0' }, style)}>
      <AccordionField name={name} allowZeroExpanded={true}>
        <AccordionItem uuid={uuid}>
          <FormAccordionButton text={title} />
          <AccordionItemPanel css={classes.panel}>{children}</AccordionItemPanel>
        </AccordionItem>
      </AccordionField>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  panel: css`
    border: 1px ${theme.pallete.divider} solid;
    border-top-width: 0;
  `,
})
