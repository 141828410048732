import { Icons } from 'bold-ui'
import { DoseImunobiologicoVacinacaoSelectFieldModel } from 'components/form/field/select/DoseImunobiologicoVacinacaoSelectField'
import { EstrategiaVacinacaoSelectFieldModel } from 'components/form/field/select/EstrategiaVacinacaoSelectField'
import { GrupoAtendimentoSelectFieldModel } from 'components/form/field/select/GrupoAtendimentoSelectField'
import { LocalAplicacaoSelectFieldModel } from 'components/form/field/select/LocalAplicacaoSelectField'
import { LoteImunobiologicoSelectModel } from 'components/form/field/select/LoteImunobiologicoSelectField/LoteImunobiologicoSelectField'
import { ViaAdministracaoVacinaSelectModel } from 'components/form/field/select/ViaAdministracaoVacinaSelectField'
import theme from 'config/theme'
import {
  EstrategiaVacinacaoEnum,
  OrigemAtendimento,
  TipoAtendimentoProfissional,
  TipoRegistroVacinacaoEnum,
} from 'graphql/types.generated'
import { tipoAtendimentoToTipoServicoMap } from 'types/enums'
import { metaPath } from 'util/metaPath'
import { ImunobiologicoSelectModel } from 'view/lote-imunobiologico/components/ImunobiologicoSelectField'
import { LoteImunobiologicoFabricanteSelectFieldModel } from 'view/lote-imunobiologico/components/LoteImunobiologicoFabricanteSelectField'

import { DoseItemProps } from '../detail/components/vacinacao/DoseItem'
import { DesfechoFormModel } from '../detail/soap/desfecho'
import { ManterCidadaoLista } from '../detail/soap/desfecho/components/DesfechoAtendimentoPanel'
import { TiposServicoEscutaInicial } from '../escuta-inicial/model'
import { AgendamentoDia } from '../types/AgendamentoDia'
import { imunosComValidacoesIds, ValidacoesVacinacaoEnum } from './validator'

export interface AtendimentoVacinacaoState {
  condicoesVacinacao: CondicoesFormModel
  desfechoVacinacao: DesfechoFormModel
  registroVacinacao: RegistroVacinacaoFormModel[]
  imprimirAtendimento?: boolean
  lastSaved?: Date
}

export const initialValue = (
  puerpera: boolean,
  gestante: boolean,
  tiposServico: TiposServicoEscutaInicial[],
  canCreateNovoAtendimento: boolean,
  agendamentosDia: AgendamentoDia[]
): AtendimentoVacinacaoState => {
  const tiposServicoAtendimento = tiposServico
    ?.map((t) => t.id)
    .filter((item) => !tipoAtendimentoToTipoServicoMap[TipoAtendimentoProfissional.VACINACAO].includes(item))

  const manterNaLista = canCreateNovoAtendimento && (agendamentosDia?.length || tiposServicoAtendimento?.length > 0)

  return {
    condicoesVacinacao: {
      gestante: gestante,
      puerpera: gestante ? false : puerpera,
      comunicanteHanseniase: false,
      viajante: false,
    },
    lastSaved: new Date(),
    registroVacinacao: [],
    desfechoVacinacao: {
      desfechoAtendimento: {
        atendimento: { tiposServico: tiposServicoAtendimento },
        manterCidadaoLista: manterNaLista ? ManterCidadaoLista.SIM : ManterCidadaoLista.NAO,
      },
    },
  } as AtendimentoVacinacaoState
}

export interface CondicoesFormModel {
  gestante: boolean
  puerpera: boolean
  viajante: boolean
  comunicanteHanseniase: boolean
}

export interface VacinacaoRegistrosLink {
  imunobiologicoId: ID
  doseId: ID
  idUnicoRegistro: ID
  estrategiaVacinacao?: EstrategiaVacinacaoSelectFieldModel
  dataAprazamentoProxDose?: LocalDate
}

export interface RegistroVacinacaoFormModel {
  id: ID
  idUnicoRegistro: ID
  tipoRegistroVacinacao?: TipoRegistroVacinacaoEnum
  isRegistroAnterior?: boolean
  outrosImunosImunobiologico?: ImunobiologicoSelectModel
  imunobiologicoId?: string
  imunobiologicoNome?: string
  imunobiologicoSigla?: string
  estrategiaVacinacao?: EstrategiaVacinacaoSelectFieldModel
  grupoAtendimento?: GrupoAtendimentoSelectFieldModel
  outrosImunosDose?: DoseImunobiologicoVacinacaoSelectFieldModel
  doseId?: string
  doseNome?: string
  dataAprazamento?: LocalDate
  // Utilizado quando o registro possui uma data de aprazamento automatico para linkar com o registro de aprazamento criado
  dataAprazamentoProxDose?: LocalDate
  // Utilizado para linkar o aprazamento automatico com a aplicacao que o criou
  aprazamentoProxDoseLink?: VacinacaoRegistrosLink
  // Utilizado para linkar a aplicacao com o aprazamento automatico criado
  aplicacaoLink?: VacinacaoRegistrosLink
  dataAplicacao?: LocalDate
  dataRegistro: LocalDate
  loteNome?: string
  loteFabricante?: LoteImunobiologicoFabricanteSelectFieldModel
  loteDataValidade?: LocalDate
  loteImunobiologico?: LoteImunobiologicoSelectModel
  viaAdministracao?: ViaAdministracaoVacinaSelectModel
  localAplicacaoVacinacao?: LocalAplicacaoSelectFieldModel
  observacoes?: string
  origemDados: OrigemAtendimento
  isGestante: boolean
  isViajante: boolean
  isPuerpera: boolean
  isComunicanteHanseniase?: boolean
  isCadastrarNovoLote?: boolean
  proximaDoseSigla?: string
  proximaDoseId?: ID
  proximaDoseNome?: string
  isPesquisaEstrategia?: boolean
  isAplicadoExterior?: boolean
}

export const meta = metaPath<AtendimentoVacinacaoState>()

export interface ImunobiologicoRowModel {
  imunobiologicoId: ID
  nome: string
  doses: DoseItemProps[]
  ordem: number
}

export interface DoseItemModel {
  calendarioId: ID
  imunobiologicoId: ID
  doseId: ID
  status: StatusDoseEnum
  nome: string
  idadeRecomendada: string
  dataAprazamento?: string
  registradoAtendimento?: boolean
  mostrarNoCalendarioDoGrupo: boolean
  imunobiologicoNome: string
  imunobiologicoNomeCompleto: string
  imunobiologicoSigla: string
  prontuarioId: ID
  cidadaoCpf: string
  cidadaoId: string
  dataAplicacao?: any
  quantidadeAplicacoes?: number
  dataRegistroAprazamento?: Instant
  ordem?: number
}

export interface VacinasAplicadas {
  imunobiologicoId?: string
  imunobiologicoSigla?: string
  imunobiologicoNome?: string
  imunobiologicoNomeFiltro?: string
  doseId?: string
  doseNomeApresentacao?: string
  doseNome?: string
  doseSigla?: string
  dataAprazamento?: any
  dataRegistroAprazamento?: any
}

export const isBcg = (idImunobiologico: string) =>
  idImunobiologico === imunosComValidacoesIds[ValidacoesVacinacaoEnum.BCG][0]

export const isDtpaAdulto = (idImunobiologico: string) =>
  idImunobiologico === imunosComValidacoesIds[ValidacoesVacinacaoEnum.DTPA_ADULTO][0]

export enum StatusDoseEnum {
  APLICADA,
  DISPONIVEL,
  ATRASADA,
  FUTURA,
}

interface StatusProps {
  hintVacinacao: string
  hintAcompanhamento: string
  hintProntuario: string
  icon?: Icons
  fontColor: string
  tagColor?: string
  tagTextColor?: string
  backgroundColor: string
  borderColor: string
}

export const statusDoseRecord: Record<StatusDoseEnum, StatusProps> = {
  [StatusDoseEnum.APLICADA]: {
    hintVacinacao: 'Aplicada - Visualizar ou realizar registro',
    hintAcompanhamento: 'Visualizar registro',
    hintProntuario: 'Visualizar registro',
    icon: 'checkCircleFilled',
    fontColor: theme.pallete.status.success.c40,
    tagColor: theme.pallete.status.success.c40,
    tagTextColor: theme.pallete.gray.c100,
    backgroundColor: theme.pallete.status.success.c90,
    borderColor: theme.pallete.status.success.c60,
  },
  [StatusDoseEnum.DISPONIVEL]: {
    hintVacinacao: 'A aplicar - Visualizar ou realizar registro',
    hintAcompanhamento: 'Adicionar transcrição de caderneta',
    hintProntuario: 'Não há nenhum registro',
    fontColor: theme.pallete.gray.c10,
    backgroundColor: theme.pallete.gray.c100,
    borderColor: theme.pallete.gray.c60,
  },
  [StatusDoseEnum.ATRASADA]: {
    hintVacinacao: 'Atrasada - Visualizar ou realizar registro',
    hintAcompanhamento: 'Adicionar transcrição de caderneta',
    hintProntuario: 'Não há nenhum registro',
    icon: 'timesFilled',
    fontColor: theme.pallete.status.danger.c40,
    tagColor: theme.pallete.status.danger.c40,
    tagTextColor: theme.pallete.gray.c100,
    backgroundColor: theme.pallete.status.danger.c90,
    borderColor: theme.pallete.status.danger.c60,
  },
  [StatusDoseEnum.FUTURA]: {
    hintVacinacao: 'A aprazar - Visualizar ou realizar registro',
    hintAcompanhamento: 'Adicionar transcrição de caderneta',
    hintProntuario: 'Não há nenhum registro',
    icon: 'clockFilled',
    fontColor: theme.pallete.gray.c10,
    tagColor: theme.pallete.gray.c50,
    tagTextColor: theme.pallete.gray.c100,
    backgroundColor: theme.pallete.gray.c80,
    borderColor: theme.pallete.gray.c60,
  },
}

interface OrigemAtendimentoProps {
  label: string
  hint: string
}

export const origemDadosVacinacao: Partial<Record<OrigemAtendimento, OrigemAtendimentoProps>> = {
  [OrigemAtendimento.PEC]: {
    label: 'PEC',
    hint: 'Registro realizado através do PEC desta instalação',
  },
  [OrigemAtendimento.CDS]: {
    label: 'CDS',
    hint: 'Registro realizado através do CDS',
  },
  [OrigemAtendimento.SISTEMA_TERCEIRO]: {
    label: 'Sistema 3º',
    hint: 'Registro realizado através de sistema 3º',
  },
  [OrigemAtendimento.RAS_PEC]: {
    label: 'RAS PEC',
    hint: 'Registro realizado através do PEC de outra instalação',
  },
  [OrigemAtendimento.APP_VACINACAO]: {
    label: 'e-SUS Vacinação',
    hint: 'Registro realizado através do aplicativo e-SUS Vacinação.',
  },
}

export const ESTRATEGIAS_GRUPO_ATENDIMENTO_REQUIRED = [
  EstrategiaVacinacaoEnum.CAMPANHA_INDISCRIMINADA,
  EstrategiaVacinacaoEnum.CAMPANHA_SELETIVA,
]
