import { Heading, InfoLabel, isEmpty, Text, VFlow } from 'bold-ui'
import {
  CondutaEnum,
  FornecimentoOdontoEnum,
  Procedimento,
  RacionalidadeEmSaudeEnum,
  SinanNotificacaoEvolucao,
  TipoEncaminhamentoOdontoEnum,
} from 'graphql/types.generated'
import React from 'react'
import { condutaOdontoRecord } from 'types/enums'
import { stringGrouper } from 'util/string/string-grouper'
import { historicoSubsectionStyles } from 'view/atendimentos/detail/historico/model/historicoModel'
import { showCodigoProcedimento } from 'view/atendimentos/detail/soap/objetivo/resultados-exames/util'
import { condutaDesfecho } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { fornecimentoOdontoDesfecho, racionalidadeEmSaudeDesfecho } from '../../../model/historicoModel'
import { naoRegistradoNesseAtendimento } from '../../../utils/historicoUtils'
import HistoricoDetailTable from '../../table/HistoricoDetailTable'

interface HistoricoDesfechoPecPanelProps {
  condutas?: CondutaEnum[]
  condutasOdonto?: TipoEncaminhamentoOdontoEnum[]
  fornecimentos?: FornecimentoOdontoEnum[]
  notificacoesCasoSuspeito?: SinanNotificacaoEvolucao[]
  procedimentosAdministrativos?: Partial<Procedimento>[]
  racionalidadeEmSaude?: RacionalidadeEmSaudeEnum
}

export default function HistoricoDesfechoPecPanel(props: HistoricoDesfechoPecPanelProps) {
  const {
    condutas,
    condutasOdonto,
    notificacoesCasoSuspeito,
    procedimentosAdministrativos,
    racionalidadeEmSaude,
    fornecimentos,
  } = props

  const hasDesfecho =
    !isEmpty(condutas) ||
    !isEmpty(condutasOdonto) ||
    !isEmpty(procedimentosAdministrativos) ||
    !isEmpty(notificacoesCasoSuspeito) ||
    !isEmpty(fornecimentos) ||
    racionalidadeEmSaude

  return hasDesfecho ? (
    <VFlow>
      {fornecimentos && (
        <InfoLabel title='Fornecimento'>
          {stringGrouper(...fornecimentos.map((fornecimento) => fornecimentoOdontoDesfecho[fornecimento]))}
        </InfoLabel>
      )}
      {procedimentosAdministrativos && (
        <VFlow vSpacing={0.5}>
          <Heading level={5} style={historicoSubsectionStyles.desfecho}>
            Procedimentos administrativos (SIGTAP)
          </Heading>
          <HistoricoDetailTable hasLines>
            <VFlow vSpacing={0.5}>
              {procedimentosAdministrativos
                .sort((a, b) => a.descricao.localeCompare(b.descricao))
                .map((proced) => (
                  <Text key={proced.id}>
                    {proced.descricao} - {showCodigoProcedimento(proced.codigo)}
                  </Text>
                ))}
            </VFlow>
          </HistoricoDetailTable>
        </VFlow>
      )}
      {notificacoesCasoSuspeito && (
        <VFlow vSpacing={0.5}>
          <Text fontWeight='bold'>Ficha de notificação de caso suspeito</Text>
          <HistoricoDetailTable hasLines>
            <VFlow vSpacing={0.5}>
              {notificacoesCasoSuspeito
                .sort((a, b) => a.notificacao.nomeNotificacao.localeCompare(b.notificacao.nomeNotificacao))
                .map((notificacao) => (
                  <Text key={notificacao.id}>{notificacao.notificacao.nomeNotificacao}</Text>
                ))}
            </VFlow>
          </HistoricoDetailTable>
        </VFlow>
      )}
      {racionalidadeEmSaude && (
        <InfoLabel title='Racionalidade em saúde'>{racionalidadeEmSaudeDesfecho[racionalidadeEmSaude]}</InfoLabel>
      )}
      {condutas && (
        <InfoLabel title='Conduta'>{stringGrouper(...condutas.map((conduta) => condutaDesfecho[conduta]))}</InfoLabel>
      )}
      {condutasOdonto && (
        <InfoLabel title='Conduta'>
          {stringGrouper(...condutasOdonto.map((conduta) => condutaOdontoRecord[conduta]))}
        </InfoLabel>
      )}
    </VFlow>
  ) : (
    <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
  )
}
