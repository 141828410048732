/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, colors, HFlow, Text, Theme, useTheme } from 'bold-ui'
import puericulturaImage from 'images/soap/ic_puericultura.png'
import { useMemo } from 'react'

interface PuericulturaButtonProps {
  puericulturaAtivo: boolean
  handleClick(): void
}

export function PuericulturaButton(props: PuericulturaButtonProps) {
  const { puericulturaAtivo, handleClick } = props
  const theme = useTheme()
  const classes = useMemo(() => styles(theme, puericulturaAtivo), [puericulturaAtivo, theme])

  return (
    <Button size='medium' style={classes.puericulturaButton} onClick={handleClick}>
      <HFlow alignItems='center'>
        <img src={puericulturaImage} alt='Puericultura' />
        <Text fontWeight='bold' style={classes.puericulturaButtonText}>
          Puericultura
        </Text>
      </HFlow>
    </Button>
  )
}

const styles = (theme: Theme, puericulturaAtivo: boolean) => ({
  puericulturaButton: css`
    border: 1px ${puericulturaAtivo ? colors.turquoise.c50 : colors.turquoise.c60} solid;
    background-color: ${puericulturaAtivo ? colors.turquoise.c60 : theme.pallete.gray.c100};
    margin: 0;
    &:not(:disabled):hover {
      background-color: ${puericulturaAtivo ? colors.turquoise.c50 : colors.turquoise.c90};
    }
  `,
  puericulturaButtonText: css`
    color: ${puericulturaAtivo ? theme.pallete.gray.c100 : colors.turquoise.c40};
  `,
})
