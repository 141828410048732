import { Alert, DateRange, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import useFirebase from 'components/firebase/useFirebase'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { PivotTable } from 'components/pivot-table/PivotTable'
import { useTreeBuilder } from 'components/pivot-table/useTreeBuilder'
import React, { useCallback, useEffect, useState } from 'react'

import { BiTableWrapper } from '../components/BiTableWrapper'
import { Vacinacao } from './Vacinacao'
import { vacinacaoKeyMapping } from './vacinacaoKeyMapping'

const operations = {
  build: '/bi/vacinacao/build',
  meta: '/bi/vacinacao/meta',
}

const NAME = 'Relatório gerencial de vacinação'

export function VacinacaoView() {
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()
  const { meta, setDateFilter, ...rest } = useTreeBuilder<Vacinacao>({
    keyMapping: vacinacaoKeyMapping,
    operations: operations,
  })

  const { analytics } = useFirebase()

  const loading = meta?.isEmpty === undefined || meta?.keyValues === undefined || meta?.numberKeys === undefined

  const handleRangeChange = useCallback(
    (dateRange: DateRange, periodUnit: string) => {
      setDateRangeFilter(dateRange)
      setDateFilter(dateRange, periodUnit)
    },
    [setDateFilter]
  )

  useEffect(() => {
    analytics.logEvent('relatorios_bi_selecionou_relatório', {
      relatorio: NAME,
    })
  }, [analytics])

  return (
    <>
      <Breadcrumb title={NAME} />

      <PageHeaderSection title={NAME} />

      <PageContent type='filled'>
        <VFlow>
          <Alert inline type='info'>
            Este relatório tem por objetivo apresentar a contagem de doses de vacinas aplicadas de acordo com os filtros
            selecionados. A origem dos atendimentos deste relatório são o CDS e o PEC.
          </Alert>
          <BiTableWrapper loading={loading} isEmpty={meta?.isEmpty} handleRangeSelect={handleRangeChange}>
            <PivotTable<Vacinacao>
              title={NAME}
              meta={meta}
              keyMapping={vacinacaoKeyMapping}
              dateRangeFilter={dateRangeFilter}
              {...rest}
            />
          </BiTableWrapper>
        </VFlow>
      </PageContent>
    </>
  )
}
