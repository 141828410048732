import { pink } from 'bold-ui/lib/styles/colors'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { SoapSection } from '../SoapSection'
import { SubjetivoForm, SubjetivoFormModel } from './SubjetivoForm'

export interface SubjetivoSectionProps {
  name: MetaPath<SubjetivoFormModel>
  cidadao: CidadaoAtendimento
  isAtendimentoOdonto: boolean
}

export function SubjetivoSection(props: SubjetivoSectionProps) {
  return (
    <SoapSection initial='S' title='Subjetivo' color={pink}>
      <SubjetivoForm {...props} />
    </SoapSection>
  )
}
