import api from 'api'
import { generatePdf } from 'util/generatePdf'

import { ImpressaoMedicamentosInput } from './ImpressaoMedicamentosInput'

export const downloadMedicamentos = async (input: ImpressaoMedicamentosInput) => {
  return await api.prescricaoMedicamento
    .imprimirMedicamentos(input)
    .then((response) => {
      generatePdf(new Blob([response.data], { type: 'application/pdf' }), 'Imprimir prescrição de medicamentos')
      return response
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('loadend', (e) => {
          reject((e.srcElement as any).result)
        })
        reader.readAsText(error.response.data)
      })
    })
}
