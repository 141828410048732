/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Cell, Grid } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacao } from 'components/auth/useAcessoLotacao'
import { PageContent } from 'components/layout/PageContent'
import { parseISO } from 'date-fns'
import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { Dispatch } from 'react'
import { useRouteMatch } from 'react-router'
import { calculateAge } from 'util/date/calculateAge'
import { isCidadaoIdoso } from 'util/isCidadaoIdoso'
import { isCidadaoPuericultura } from 'util/isCidadaoPuericultura'
import { TipoAtendimentoFooter } from 'view/atendimentos/model'
import { CheckJustificativaAcessoProntuario } from 'view/prontuario/justificativa/CheckJustificativaAcessoProntuario'

import { AtendimentoFooter } from '../AtendimentoFooter'
import { PeriodoGestacaoModel } from '../components/modals/types/PeriodoGestacaoModel'
import { grupoCboAcompanhamentoIdoso } from '../soap/aside/acompanhamento-idoso/acessos'
import { AcompanhamentoIdosoSection } from '../soap/aside/acompanhamento-idoso/AcompanhamentoIdosoSection'
import { AcompanhamentoPuericulturaSection } from '../soap/aside/acompanhamento-puericultura/AcompanhamentoPuericulturaSection'
import { AcompanhamentoVacinacaoSection } from '../soap/aside/acompanhamento-vacinacao/AcompanhamentoVacinacaoSection'
import { AlergiasSection } from '../soap/aside/alergias/AlergiasSection'
import { CondicoesAutorreferidasSection } from '../soap/aside/condicoes-autorreferidas/CondicoesAutorreferidasSection'
import { LembretesSection } from '../soap/aside/lembretes/LembretesSection'
import { ProblemasSection } from '../soap/aside/lista-problemas/ProblemasSection'
import { MedicamentosSection } from '../soap/aside/medicamentos/MedicamentosSection'
import { MedicoesSection } from '../soap/aside/medicoes/MedicoesSection'
import { PreNatalSection } from '../soap/aside/pre-natal/components/PreNatalSection'
import { ResultadosExamesSection } from '../soap/aside/resultados-exames/ResultadosExamesSection'
import { grupoCboMedicoes, grupoCboResultadosExames } from '../soap/objetivo/acessos'
import { PuericulturaModel } from '../soap/objetivo/puericultura/model'
import { grupoCboLembretes } from '../soap/plano/acessos'
import { grupoCboMedicamentosFolhaRosto } from '../soap/plano/acessos'
import { grupoCboAcompanhamentoPreNatal } from '../soap/pre-natal/acessos'
import { AntecedentesFolhaRostoSection } from './antecedentes/AntecedentesFolhaRostoSection'
import { FolhaRostoEscutaInicialPanel } from './components/escuta-pre-atendimento/FolhaRostoEscutaPreAtendimentoPanel'
import { FolhaRostoUltimosContatosPanel } from './components/ultimos-contatos/FolhaRostoUltimosContatosPanel'
import { CidadaoFolhaRosto, LotacaoFolhaRosto } from './model'
import { PlanoFolhaRostoView } from './plano/PlanoFolhaRostoView'

const PRE_ATENDIMENTO = 'PRE_ATENDIMENTO'

interface FolhaRostoViewProps {
  cidadao: CidadaoFolhaRosto
  lotacao: LotacaoFolhaRosto
  isGestante: boolean
  gestacoes: PeriodoGestacaoModel[]
  dataReferencia?: Instant
  atendimentoId?: ID
  prontuarioId: ID
  tipoAtendimentoProfissional?: TipoAtendimentoFooter
  registroTardio?: boolean
  isAtendimentoAgendado?: boolean
  clearCache?: Dispatch<void>
  atendimentoPuericultura?: PuericulturaModel
  vacinacaoEmDia?: boolean
}

export function FolhaRostoView(props: FolhaRostoViewProps) {
  const { getServerTimeNow } = useServerTime()

  const {
    prontuarioId,
    atendimentoId,
    cidadao,
    lotacao,
    isAtendimentoAgendado = false,
    isGestante,
    gestacoes,
    tipoAtendimentoProfissional,
    registroTardio,
    dataReferencia = Number(getServerTimeNow()),
    clearCache,
    atendimentoPuericultura,
    vacinacaoEmDia,
  } = props

  const match = useRouteMatch()
  const { unidadeSaude } = useAcessoLotacao()

  const idadeCidadao = calculateAge(dataReferencia, cidadao.dataNascimento).years

  const showPlanoTab =
    tipoAtendimentoProfissional === undefined ||
    tipoAtendimentoProfissional === TipoAtendimentoProfissional.ESCUTA_INICIAL ||
    tipoAtendimentoProfissional === TipoAtendimentoProfissional.VACINACAO ||
    tipoAtendimentoProfissional === PRE_ATENDIMENTO

  const showEscutaInicialPreAtendimento =
    !!atendimentoId &&
    ![TipoAtendimentoProfissional.ESCUTA_INICIAL, PRE_ATENDIMENTO].includes(tipoAtendimentoProfissional)

  return (
    <React.Fragment>
      <PageContent fluid type='filled'>
        <CheckJustificativaAcessoProntuario
          prontuarioId={prontuarioId}
          cidadaoId={cidadao.id}
          basePath={match.path.substring(0, match.path.lastIndexOf('/'))}
          disabled={!!atendimentoId}
        >
          <Grid>
            <Cell size={6}>
              <Grid>
                {showEscutaInicialPreAtendimento && (
                  <Cell size={12} alignSelf='flex-start'>
                    <FolhaRostoEscutaInicialPanel
                      cidadaoId={cidadao.id}
                      atendimentoId={atendimentoId}
                      isPreAtendimento={isAtendimentoAgendado}
                    />
                  </Cell>
                )}
                <Cell size={12} alignSelf='flex-start'>
                  <FolhaRostoUltimosContatosPanel
                    atendimentoId={atendimentoId}
                    cidadao={{
                      id: cidadao.id,
                      cpf: cidadao.cpf,
                      dataNascimento: cidadao.dataNascimento,
                    }}
                    unidadeSaudeCnes={unidadeSaude.cnes}
                  />
                </Cell>
                <Cell size={12}>
                  <AntecedentesFolhaRostoSection
                    prontuarioId={prontuarioId}
                    cidadaoDataNascimento={parseISO(cidadao.dataNascimento)}
                  />
                </Cell>
              </Grid>
            </Cell>
            <Cell size={3}>
              <Grid gapVertical={0.5}>
                <Cell size={12}>
                  <CheckPermission permission={grupoCboMedicoes.visualizar}>
                    <MedicoesSection
                      prontuarioId={prontuarioId}
                      cidadao={cidadao}
                      isGestante={isGestante}
                      gestacoes={gestacoes}
                      dataAtendimento={dataReferencia}
                      readOnly
                    />
                  </CheckPermission>
                </Cell>
                {isCidadaoIdoso(idadeCidadao) && (
                  <Cell size={12}>
                    <CheckPermission permission={grupoCboAcompanhamentoIdoso}>
                      <AcompanhamentoIdosoSection prontuarioId={prontuarioId} cidadaoId={cidadao.id} />
                    </CheckPermission>
                  </Cell>
                )}
                {isCidadaoPuericultura(idadeCidadao) && (
                  <Cell size={12}>
                    <AcompanhamentoPuericulturaSection
                      prontuarioId={prontuarioId}
                      vacinacaoEmDia={vacinacaoEmDia}
                      puericultura={atendimentoPuericultura}
                      atendimentoId={atendimentoId}
                      cidadao={cidadao}
                      dataReferencia={dataReferencia}
                    />
                  </Cell>
                )}
                <Cell size={12}>
                  <AcompanhamentoVacinacaoSection idProntuario={prontuarioId} dataAtendimento={dataReferencia} />
                </Cell>
                <Cell size={12}>
                  <CondicoesAutorreferidasSection cidadaoId={cidadao.id} />
                </Cell>
                {isGestante && (
                  <Cell size={12}>
                    <CheckPermission permission={grupoCboAcompanhamentoPreNatal}>
                      <PreNatalSection
                        atendimentoId={atendimentoId}
                        prontuarioId={prontuarioId}
                        cidadaoId={cidadao.id}
                        readOnly
                      />
                    </CheckPermission>
                  </Cell>
                )}
                <CheckPermission permission={grupoCboLembretes.visualizar}>
                  <Cell size={12}>
                    <LembretesSection prontuarioId={prontuarioId} lotacao={lotacao} />
                  </Cell>
                </CheckPermission>
              </Grid>
            </Cell>
            <Cell size={3}>
              <Grid gapVertical={0.5}>
                <Cell size={12}>
                  <AlergiasSection prontuarioId={prontuarioId} expanded={false} />
                </Cell>
                <Cell size={12}>
                  <ProblemasSection cidadao={cidadao} prontuarioId={prontuarioId} readOnly />
                </Cell>
                <Cell size={12}>
                  <CheckPermission permission={grupoCboResultadosExames.visualizar}>
                    <ResultadosExamesSection prontuarioId={prontuarioId} />
                  </CheckPermission>
                </Cell>
                <Cell size={12}>
                  <CheckPermission permission={grupoCboMedicamentosFolhaRosto}>
                    <MedicamentosSection
                      prontuarioId={prontuarioId}
                      dataReferencia={dataReferencia}
                      isCidadaoIdoso={isCidadaoIdoso(idadeCidadao)}
                      mostrarInativos
                    />
                  </CheckPermission>
                </Cell>
              </Grid>
            </Cell>
            {showPlanoTab && (
              <Cell size={12}>
                <PlanoFolhaRostoView
                  prontuarioId={prontuarioId}
                  atendimentoId={atendimentoId}
                  cidadao={cidadao}
                  lotacao={lotacao}
                  tipoAtendimentoProfissional={tipoAtendimentoProfissional}
                  dataReferencia={dataReferencia}
                  registroTardio={registroTardio}
                />
              </Cell>
            )}
          </Grid>
        </CheckJustificativaAcessoProntuario>
      </PageContent>
      {atendimentoId && (
        <AtendimentoFooter
          atendimentoId={atendimentoId}
          tipoAtendimentoProfissional={tipoAtendimentoProfissional}
          clearCache={clearCache}
          registroTardio={registroTardio}
        />
      )}
    </React.Fragment>
  )
}
