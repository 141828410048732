/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DataTable, Heading, InfoLabel, Text, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import {
  AtendimentoProfissionalOdonto,
  EncaminhamentoExterno,
  EvolucaoPlano,
  ReceitaMedicamento,
  ResultadoExame,
  SolicitacaoExame,
} from 'graphql/types.generated'
import { Fragment } from 'react'
import { historicoSubsectionStyles } from 'view/atendimentos/detail/historico/model/historicoModel'
import { naoRegistradoNesseAtendimento } from 'view/atendimentos/detail/historico/utils/historicoUtils'
import { showCodigoProcedimento } from 'view/atendimentos/detail/soap/objetivo/resultados-exames/util'
import { classificacaoRiscoColors } from 'view/atendimentos/detail/soap/plano/encaminhamento-externo/ClassificacaoRiscoEncaminhamentoButton'

import StatusSquare from '../../../../StatusSquare'
import HistoricoEvolucoesOdontologicasPanel from '../../atendimento-odontologico/pec/evolucoes-odontologicas/HistoricoEvolucoesOdontologicasPanel'
import HistoricoPlanoPecIntervencoesProcedimentosPanel from './soap/plano/HistoricoPlanoPecIntervencoesProcedimentosPanel'
import HistoricoPlanoPecPrescricaoPanel from './soap/plano/HistoricoPlanoPecPrescricaoPanel'

interface HistoricoPlanoPecPanelProps {
  isAtendProcedimento?: boolean
  evolucaoPlano: EvolucaoPlano
  atendProfOdonto?: AtendimentoProfissionalOdonto
  quantidadeAtestados?: number
  quantidadeOrientacoes?: number
  encaminhamentos?: EncaminhamentoExterno[]
  prescricaoMedicamentos?: ReceitaMedicamento[]
  examesSolicitados?: SolicitacaoExame[]
  isOrigemApp?: boolean
}

const colunasTabelaEncaminhamentos = [
  {
    name: 'especialidade',
    header: 'Especialidade',
    render: (item: EncaminhamentoExterno) => (
      <VFlow
        style={css`
          padding: 0.5rem 0;
          overflow-wrap: anywhere;
        `}
        vSpacing={0.5}
      >
        <Text fontWeight='bold'>{item.especialidadeSisreg.descricao.toLowerCase().capitalize()}</Text>
        {item.complemento && <HLabel title='Complemento:'>{item.complemento}</HLabel>}

        {item.motivoEncaminhamento?.removeTags() && (
          <HLabel title='Motivo do encaminhamento:'>
            <Text dangerouslySetInnerHTML={{ __html: item.motivoEncaminhamento }} />
          </HLabel>
        )}

        {item.observacoes?.removeTags() && (
          <HLabel title='Observações:'>
            <Text dangerouslySetInnerHTML={{ __html: item.observacoes }} />
          </HLabel>
        )}
      </VFlow>
    ),
    style: css`
      width: 35%;
    `,
  },
  {
    name: 'hipoteseDiagnostico',
    header: 'Hipótese / Diagnóstico',
    render: (item: EncaminhamentoExterno) => (
      <Text>
        <Text fontWeight='bold'>{`${item.hipoteseDiagnosticoCid10.nome} - ${item.hipoteseDiagnosticoCid10.codigo}`}</Text>{' '}
        (CID10)
      </Text>
    ),
  },
  {
    name: 'classificacaoRisco',
    header: 'Classificação de risco',
    render: (item: EncaminhamentoExterno) => (
      <StatusSquare
        description={classificacaoRiscoColors[item.classificacaoRiscoEncaminhamento].descricao}
        color={classificacaoRiscoColors[item.classificacaoRiscoEncaminhamento].fontColor}
      />
    ),
  },
]

const colunasExamesSolicitados = [
  {
    name: 'exame',
    header: 'Exame',
    render: (item: SolicitacaoExame) => (
      <VFlow
        style={css`
          padding: 0.5rem 0;
        `}
        vSpacing={0.5}
      >
        {item.exames
          .sort((a, b) => a.exame.descricao.localeCompare(b.exame.descricao))
          .map((solicitacaoExame: ResultadoExame) => (
            <VFlow key={solicitacaoExame.id} vSpacing={0.5}>
              <Text>
                <Text fontWeight='bold'>{solicitacaoExame.exame.descricao}</Text> -{' '}
                {showCodigoProcedimento(solicitacaoExame.exame.codigo)}
              </Text>
              {solicitacaoExame.observacao && (
                <HLabel title='Observação do exame:'>{solicitacaoExame.observacao}</HLabel>
              )}
            </VFlow>
          ))}
        {item.observacoes && <HLabel title='Observações:'>{item.observacoes}</HLabel>}
      </VFlow>
    ),
    style: css`
      width: 40%;
    `,
  },
  {
    name: 'justificativa',
    header: 'Justificativa do procedimento',
    render: (item: SolicitacaoExame) => (
      <VFlow
        style={css`
          padding: 0.5rem 0;
        `}
        vSpacing={0}
      >
        {item.cid10 && (
          <Text>
            {item.cid10.nome} - {item.cid10.codigo} (CID10)
          </Text>
        )}
        <Text
          style={css`
            white-space: pre-line;
          `}
        >
          {item.justificativa}
        </Text>
      </VFlow>
    ),
    style: css`
      width: 40%;
    `,
  },
]

export default function HistoricoPlanoPecPanel(props: HistoricoPlanoPecPanelProps) {
  const {
    evolucaoPlano,
    atendProfOdonto,
    quantidadeAtestados,
    quantidadeOrientacoes,
    encaminhamentos,
    examesSolicitados,
    prescricaoMedicamentos,
    isAtendProcedimento,
    isOrigemApp,
  } = props

  const descricao = evolucaoPlano?.descricao?.removeTags()

  const possuiOrientacoes = quantidadeOrientacoes > 0

  const possuiAtestados = quantidadeAtestados > 0

  const hasPlano =
    descricao ||
    evolucaoPlano?.intervencoesProcedimentos ||
    atendProfOdonto ||
    examesSolicitados ||
    prescricaoMedicamentos ||
    encaminhamentos ||
    possuiAtestados ||
    possuiOrientacoes

  return (
    <Fragment>
      {hasPlano ? (
        <VFlow>
          {descricao && <Text dangerouslySetInnerHTML={{ __html: evolucaoPlano?.descricao }} />}

          {evolucaoPlano?.intervencoesProcedimentos && (
            <VFlow vSpacing={0.5}>
              <Heading style={historicoSubsectionStyles.plano} level={5}>
                Intervenções e/ou procedimentos clínicos
              </Heading>
              <HistoricoPlanoPecIntervencoesProcedimentosPanel
                intervencoesProcedimentos={evolucaoPlano.intervencoesProcedimentos}
              />
            </VFlow>
          )}
          {!isAtendProcedimento && !isOrigemApp && (
            <InfoLabel titleStyles={historicoSubsectionStyles.plano} title='Atestado'>
              <Text fontStyle='italic'>
                {quantidadeAtestados > 0
                  ? `${quantidadeAtestados > 1 ? 'Foram emitidos' : 'Foi emitido'} ${'atestado'.pluralizeAndConcatValue(
                      quantidadeAtestados
                    )} neste atendimento.`
                  : 'Não foram emitidos atestados neste atendimento.'}
              </Text>
            </InfoLabel>
          )}
          {examesSolicitados && (
            <VFlow vSpacing={0.5}>
              <Heading style={historicoSubsectionStyles.plano} level={5}>
                Exames solicitados
              </Heading>
              <DataTable<SolicitacaoExame>
                rows={examesSolicitados.sort((a, b) => a.cid10?.nome.localeCompare(b.cid10?.nome))}
                columns={colunasExamesSolicitados}
              />
            </VFlow>
          )}
          {prescricaoMedicamentos && (
            <VFlow vSpacing={0.5}>
              <Heading style={historicoSubsectionStyles.plano} level={5}>
                Medicamentos prescritos
              </Heading>
              <HistoricoPlanoPecPrescricaoPanel prescricaoMedicamentos={prescricaoMedicamentos} />
            </VFlow>
          )}
          {!isOrigemApp && (
            <InfoLabel titleStyles={historicoSubsectionStyles.plano} title='Orientações'>
              <Text fontStyle='italic'>
                {quantidadeOrientacoes > 0
                  ? `${quantidadeOrientacoes > 1 ? 'Foram emitidas' : 'Foi emitida'} ${quantidadeOrientacoes} ${
                      quantidadeOrientacoes > 1 ? 'orientações' : 'orientação'
                    } neste atendimento.`
                  : 'Não foram emitidas orientações neste atendimento.'}
              </Text>
            </InfoLabel>
          )}
          {encaminhamentos && (
            <VFlow vSpacing={0.5}>
              <Heading style={historicoSubsectionStyles.plano} level={5}>
                Encaminhamentos
              </Heading>
              <DataTable<EncaminhamentoExterno> rows={encaminhamentos} columns={colunasTabelaEncaminhamentos} />
            </VFlow>
          )}
          {atendProfOdonto && <HistoricoEvolucoesOdontologicasPanel atendProfOdonto={atendProfOdonto} />}
        </VFlow>
      ) : (
        <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
      )}
    </Fragment>
  )
}
