/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ButtonGroup, HFlow } from 'bold-ui'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Fragment } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import { ProblemaCondicaoModel } from '../avaliacao/components/problemas-condicoes/model'
import { FamiliarAntecedentesView } from './FamiliarAntecedentesView'
import { FamiliarButton } from './FamiliarButton'
import { FamiliarAntecedentesModel, PessoalAntecedentesModel } from './model'
import { PessoalAntecedentesView } from './PessoalAntecedentesView'
import { PessoalButton } from './PessoalButton'

export enum FormAtivoAntecedentesEnum {
  PESSOAL,
  FAMILIAR,
}

export interface AntecedentesFormModel {
  formAtivo: FormAtivoAntecedentesEnum
  pessoal?: PessoalAntecedentesModel
  familiar?: FamiliarAntecedentesModel
}

export interface AntecedentesFormProps {
  name: MetaPath<AntecedentesFormModel>
  dataAtualizacaoObstetricos: Instant
  dataAtualizacaoPuericultura: Instant
  problemasAvaliados: ProblemaCondicaoModel[]
}

export function AntecedentesForm(props: AntecedentesFormProps) {
  const { name, dataAtualizacaoObstetricos, dataAtualizacaoPuericultura, problemasAvaliados } = props

  const {
    input: { value: formAtivo, onChange: updateFormAtivo, onBlur: onBlurFormAtivo },
  } = useField<FormAtivoAntecedentesEnum>(name.formAtivo.absolutePath())

  const {
    cidadao,
    prontuario: { id: prontuarioId },
  } = useAtendimentoContext()

  const handleClick = (stateButton: FormAtivoAntecedentesEnum) => () => {
    updateFormAtivo(stateButton)
    onBlurFormAtivo()
  }

  return (
    <Fragment>
      <HFlow hSpacing={1} justifyContent='flex-start'>
        <ButtonGroup>
          <HFlow hSpacing={1} justifyContent='flex-start'>
            <PessoalButton
              pessoalAtivo={formAtivo === FormAtivoAntecedentesEnum.PESSOAL}
              handleClick={handleClick(FormAtivoAntecedentesEnum.PESSOAL)}
            />
          </HFlow>
        </ButtonGroup>
        <ButtonGroup>
          <HFlow hSpacing={1} justifyContent='flex-start'>
            <FamiliarButton
              familiarAtivo={formAtivo === FormAtivoAntecedentesEnum.FAMILIAR}
              handleClick={handleClick(FormAtivoAntecedentesEnum.FAMILIAR)}
            />
          </HFlow>
        </ButtonGroup>
      </HFlow>

      {formAtivo === FormAtivoAntecedentesEnum.PESSOAL && (
        <PessoalAntecedentesView
          name={name.pessoal}
          cidadao={cidadao}
          prontuarioId={prontuarioId}
          dataAtualizacaoObstetricos={dataAtualizacaoObstetricos}
          dataAtualizacaoPuericultura={dataAtualizacaoPuericultura}
          problemasAvaliados={problemasAvaliados}
        />
      )}
      {formAtivo === FormAtivoAntecedentesEnum.FAMILIAR && (
        <FamiliarAntecedentesView name={name.familiar} cidadao={cidadao} />
      )}
    </Fragment>
  )
}
