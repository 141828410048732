import { Alert, Button, Cell, Grid, Heading, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { DateTime } from 'components/date'
import useFirebase from 'components/firebase/useFirebase'
import { confirm } from 'components/modals/confirm'
import { useAlterarCadsusMutation } from 'graphql/hooks.generated'
import { ConexaoQuery } from 'graphql/types.generated'
import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import { IntervaloCadsusSelectField, IntervaloCadsusSelectModel, items } from './IntervaloCadsusSelectField'

interface ConfiguracoesCadSusBoxProps {
  cadsus: ConexaoQuery['conexao']['cadsus']
  internetHabilitada: boolean

  load(): void
}

interface FormType {
  intervalo: IntervaloCadsusSelectModel
}

export const ConfiguracoesCadSusBox = (props: ConfiguracoesCadSusBoxProps) => {
  const { cadsus, internetHabilitada } = props
  const alert = useAlert()
  const { analytics } = useFirebase()
  const [alterarCadsus] = useAlterarCadsusMutation()

  const handleSubmit = (values: FormType) => {
    if (cadsus.habilitado) {
      confirm({
        title: 'Deseja desabilitar a conexão com o CADSUS?',
        body:
          'A conexão com o CADSUS deve ser desabilitada somente em casos de instabilidade ' +
          'da conexão com a Internet ou do serviço do CADSUS.',
        confirmLabel: 'Desabilitar',
        onConfirm: () => {
          analytics.logEvent('desabilita_cadsus')
          submitIntervalo(values, 'Conexão com o CADSUS foi desabilitada com sucesso')
        },
      })()
    } else {
      analytics.logEvent('habilita_cadsus')
      submitIntervalo(values, 'Conexão com o CADSUS foi habilitada com sucesso')
    }
  }

  const submitIntervalo = (values: FormType, msg: string) => {
    return alterarCadsus({
      variables: {
        alterarCadsusInput: {
          id: '1',
          habilitado: !cadsus.habilitado,
          intervalo: values.intervalo && values.intervalo.value,
        },
      },
    }).then(() => {
      alert('success', msg)
    })
  }

  const renderForm = (formProps: FormRenderProps) => {
    return (
      <Box style={{ height: '100%' }}>
        <Grid>
          <Cell flexGrow={1}>
            <VFlow>
              <Heading level={3} style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                CADSUS
                {cadsus.habilitado ? (
                  <Tag type='success' icon='checkCircleFilled'>
                    Habilitado
                  </Tag>
                ) : (
                  <Tag type='normal' icon='banFilled'>
                    Desabilitado
                  </Tag>
                )}
                {cadsus.desabilitadoAteData && (
                  <Text fontWeight='normal'>
                    Desabilitado até <DateTime value={cadsus.desabilitadoAteData} />.
                  </Text>
                )}
                {!cadsus.habilitado && !cadsus.desabilitadoAteData && (
                  <Text fontWeight='normal'>Desabilitado por tempo indeterminado.</Text>
                )}
              </Heading>

              <Alert type='warning' inline>
                Temporariamente, o PEC não está enviando ou atualizando os dados no CADSUS. Apenas a busca dos dados no
                CADSUS se mantém.
              </Alert>

              {!cadsus.habilitado ? (
                <Tooltip text={!internetHabilitada && 'Não pode ser habilitada sem conexão com a internet'}>
                  <Button
                    kind='primary'
                    size='small'
                    onClick={formProps.handleSubmit}
                    disabled={!internetHabilitada}
                    style={{ marginTop: '1.5rem' }}
                  >
                    Habilitar
                  </Button>
                </Tooltip>
              ) : (
                <Grid gap={1}>
                  <Cell flexGrow={1}>
                    <IntervaloCadsusSelectField name='intervalo' />
                  </Cell>
                  <Cell>
                    <Button
                      kind='primary'
                      size='small'
                      onClick={formProps.handleSubmit}
                      style={{ marginTop: '1.5rem' }}
                    >
                      Desabilitar
                    </Button>
                  </Cell>
                  <Cell style={{ flex: '2' }}></Cell>
                </Grid>
              )}
            </VFlow>
          </Cell>
        </Grid>
      </Box>
    )
  }

  const intervaloValue = cadsus.habilitado ? items[1] : undefined
  const initialValues = { ...cadsus, intervalo: intervaloValue }

  return <Form onSubmit={handleSubmit} initialValues={initialValues} render={renderForm} />
}
