import { Breadcrumb } from 'components/breadcrumb'
import { PecSwitch, PrivateRoute } from 'components/route'
import React from 'react'
import { useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import { ListaAtendimentoRootView } from 'view/atendimentos/ListaAtendimentoRootView'
import { grupoCboAtendimento } from 'view/cidadao/acessos'

import { AtendimentoVacinacaoRootView } from './atendimento-vacinacao/AtendimentoVacinacaoRootView'
import { AtendimentoView } from './detail/AtendimentoView'
import { EscutaInicialView } from './escuta-inicial/EscutaInicialView'
import { EscutaInicialVisualizarRootView } from './escuta-inicial/visualizar/EscutaInicialVisualizarRootView'

export function AtendimentoRootView() {
  const match = useRouteMatch()
  return (
    <>
      <Breadcrumb title='Lista de atendimentos' />

      <PecSwitch>
        <PrivateRoute
          path={`${match.url}/escuta-inicial/:atendimentoId`}
          component={EscutaInicialView}
          permission={Permissions.visualizarListaDeAtendimento.visualizarEscutaInicial.realizarEscutaInicial}
        />
        <PrivateRoute
          path={`${match.url}/visualizar-escuta/:atendimentoId`}
          component={EscutaInicialVisualizarRootView}
          permission={Permissions.visualizarListaDeAtendimento.visualizarEscutaInicial}
        />
        <PrivateRoute
          path={`${match.url}/atendimento-vacinacao/:atendimentoId`}
          component={AtendimentoVacinacaoRootView}
          permission={Permissions.visualizarListaDeAtendimento.registrarAtendimento}
        />
        <PrivateRoute
          path={`${match.url}/atendimento/:atendimentoId`}
          component={AtendimentoView}
          permission={Permissions.visualizarListaDeAtendimento.registrarAtendimento}
          cbos={grupoCboAtendimento}
        />
        <PrivateRoute
          path={`${match.url}`}
          component={ListaAtendimentoRootView}
          permission={Permissions.visualizarListaDeAtendimento}
        />
      </PecSwitch>
    </>
  )
}
