import { SelectHelperMenuItem } from 'bold-ui'
import locale from 'bold-ui/lib/i18n/locales/pt-BR'
import React from 'react'

export function ProcedimentoSelectFieldEmptyItem(skiping: boolean, loadItemsOnOpen: boolean) {
  if (!skiping) {
    return <SelectHelperMenuItem>{locale.select.emptyItem}</SelectHelperMenuItem>
  } else if (!loadItemsOnOpen) {
    return <SelectHelperMenuItem>{'Digite para buscar um procedimento.'}</SelectHelperMenuItem>
  }
  return null
}
