/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, VFlow } from 'bold-ui'
import { InfoIcon } from 'components/InfoIcon'
import { useField } from 'react-final-form'
import { meta } from 'view/atendimentos/atendimento-individual/model'

import { ProblemaCondicaoModel } from '../../avaliacao/components/problemas-condicoes/model'
import { hasProblemaCondicaoGravidezAltoRiscoAtivoOuLatente } from '../../avaliacao/components/problemas-condicoes/utils/verifications'
import { RiscoGravidezTag } from './RiscoGravidezTag'
interface RiscoGravidezMarkerProps {
  isAltoRisco: boolean
}

export function RiscoGravidezMarker(props: RiscoGravidezMarkerProps) {
  const { isAltoRisco } = props

  const {
    input: { value: problemasCondicoes },
  } = useField<ProblemaCondicaoModel[]>(meta.avaliacao.problemasECondicoes.absolutePath(), {
    subscription: { value: true },
  })

  return (
    <VFlow vSpacing={0.5}>
      <HFlow hSpacing={0.5} alignItems='center'>
        <Text fontWeight='bold'>Risco da gravidez</Text>
        <InfoIcon icon='infoCircleFilled' text={infoIconText} tooltipStyle={styles.tooltip} />
      </HFlow>
      <RiscoGravidezTag
        isAltoRisco={isAltoRisco || hasProblemaCondicaoGravidezAltoRiscoAtivoOuLatente(problemasCondicoes)}
      />
    </VFlow>
  )
}

const styles = {
  tooltip: css`
    white-space: pre-wrap;
  `,
}

const infoIconText = `Para alterar o risco da gestação no sistema, uma das condições abaixo deve ser incluída na Avaliação:

CID10: Grupo Z35 (Z35 / Z350 / Z351 / Z352 / Z353 / Z354 / Z355 / Z356 / Z357 / Z358 / Z359)

CIAP2: W71, W72, W73, W75, W76, W79, W80, W81, W84, W85`
