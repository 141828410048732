import { ProblemaCondicaoModel } from '../components/problemas-condicoes/model'

export const hasMudancaAvaliacoesAutomaticas = (
  listaAvaliacoesA: ProblemaCondicaoModel[],
  listaAvaliacoesB: ProblemaCondicaoModel[]
) =>
  getDiferencaEntreListasAvaliacoes(listaAvaliacoesA, listaAvaliacoesB)
    .concat(getDiferencaEntreListasAvaliacoes(listaAvaliacoesB, listaAvaliacoesA))
    .find((avaliacao) => avaliacao.automatico)

const getDiferencaEntreListasAvaliacoes = (
  listaAvaliacaoA: ProblemaCondicaoModel[],
  listaAvaliacaoB: ProblemaCondicaoModel[]
) => listaAvaliacaoA.filter((itemA) => !listaAvaliacaoB.some((itemB) => saoAvaliacoesIguais(itemA, itemB)))

export const saoAvaliacoesIguais = (avaliacaoA: ProblemaCondicaoModel, avaliacaoB: ProblemaCondicaoModel) =>
  avaliacaoA.cid?.codigo === avaliacaoB.cid?.codigo &&
  avaliacaoA.ciap?.codigo === avaliacaoB.ciap?.codigo &&
  avaliacaoA.automatico === avaliacaoB.automatico
