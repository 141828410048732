import { Button, DropdownDivider, DropdownItem, Heading, HFlow, Icon, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { Box } from 'components/Box'
import { DateTime } from 'components/date'
import { Age } from 'components/date/Age'
import { DropdownButton } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import { Cpf, Telefone } from 'components/label'
import { confirm } from 'components/modals/confirm'
import { useExcluirCidadaoMutation, useReativarCidadaoMutation } from 'graphql/hooks.generated'
import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { identidadeGenero, sexo } from 'types/enums'
import Permissions from 'types/Permissions'

import { CidadaoListingFormModel } from '../types/CidadaoPesquisarFormModel'
import { CidadaoUrlParams } from '../types/CidadaoUrlParams'
import { useNavigateToCidadaoCallbackUrl } from '../useNavigateToCidadaoCallbackUrl'

interface CidadaoListingItemProps {
  data: CidadaoListingFormModel
  refetchLocal(): void
  cidadaoUrlParams: CidadaoUrlParams
}

export const CidadaoListingItem = (props: CidadaoListingItemProps) => {
  const {
    data,
    cidadaoUrlParams: { callbackUrl },
    refetchLocal,
  } = props

  const { hasAuthorization } = useSession()
  const [excluirCidadao] = useExcluirCidadaoMutation()
  const [reativarCidadao] = useReativarCidadaoMutation()
  const externalAccess = !!callbackUrl

  const alert = useAlert()
  const handleRejection = useErrorHandler()
  const location = useLocation()
  const history = useHistory()

  const navigateToCidadaoCallbackUrl = useNavigateToCidadaoCallbackUrl()

  const telefone = data.telefoneCelular
    ? data.telefoneCelular
    : data.telefoneResidencial
    ? data.telefoneResidencial
    : null

  const cadastrarEEditar = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar)
  const excluir = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.excluir)
  const inativar = hasAuthorization(Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.inativar)
  const visualizarAgenda = hasAuthorization(Permissions.visualizarAgenda)

  const hasAnyPermission = cadastrarEEditar || excluir || inativar || visualizarAgenda

  const onExcluirClick = () => {
    confirm({
      title: `Deseja excluir o cadastro do cidadão?`,
      confirmLabel: 'Excluir',
      type: 'danger',
      onConfirm: () => {
        return excluirCidadao({ variables: { cidadaoId: data.id } })
          .then(() => {
            alert('success', 'Cadastro do cidadão excluído.')
            refetchLocal()
          })
          .catch(handleRejection)
      },
    })()
  }

  const onVisualizarClick = () => {
    history.push(`/cidadao/${data.id}${location.search}`)
  }

  const onEditarClick = () => {
    history.push(`/cidadao/${data.id}/edit${location.search}`)
  }

  const onReativarClick = () => {
    confirm({
      title: `Deseja reativar o cadastro do cidadão?`,
      confirmLabel: 'Reativar',
      type: 'primary',
      onConfirm: () => {
        return reativarCidadao({ variables: { cidadaoId: data.id } })
          .then(() => {
            alert('success', 'Cadastro do cidadão reativado.')
            refetchLocal()
          })
          .catch(handleRejection)
      },
    })()
  }

  const handleVerAgendamentosClick = () => {
    history.push(`/cidadao/${data.id}/agendamentos`)
  }
  return (
    <>
      <Box style={{ minHeight: 136, textAlign: 'left' }}>
        <HFlow justifyContent='space-between'>
          <VFlow vSpacing={0}>
            <HFlow hSpacing={0.5} alignItems='baseline'>
              <Heading level={3}>{(data.nomeSocial ? data.nomeSocial : data.nome)?.titleCase()}</Heading>
              {data.nomeSocial && <Text fontWeight='bold'>(Nome social)</Text>}
              {data.nomeSocial && <Text>{'| ' + data.nome?.titleCase()}</Text>}
              {data.faleceu && <Tag>Óbito</Tag>}
              {!data.ativo && <Tag>Inativo</Tag>}
              {data.unificado && <Tag>Cadastro unificado</Tag>}
              {data.unificacaoBase && <Tag>Origem de base unificada</Tag>}
            </HFlow>
            <HFlow hSpacing={2}>
              <VFlow vSpacing={0} style={{ width: 350 }}>
                <HFlow>
                  <HFlow alignItems='center' hSpacing={0.5}>
                    <Text fontWeight='bold'>CPF</Text>
                    {data.cpf ? <Cpf value={data.cpf} /> : '-'}
                  </HFlow>
                  <HFlow hSpacing={0.5}>
                    <Text fontWeight='bold'>CNS</Text>
                    {data.cns ? <Text>{data.cns}</Text> : '-'}
                  </HFlow>
                </HFlow>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Text fontWeight='bold'>Data de nascimento</Text>
                  {data.dataNascimento ? (
                    <>
                      <DateTime format='DD/MM/YYYY' value={data.dataNascimento} />
                      <Age dateOfBirth={data.dataNascimento} />
                    </>
                  ) : (
                    '-'
                  )}
                </HFlow>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Sexo</Text>
                  <Text>{sexo[data.sexo]}</Text>
                  <Text fontWeight='bold'>{data.identidadeGeneroDbEnum ? 'Id. de gênero' : null}</Text>
                  <Text>{data.identidadeGeneroDbEnum && identidadeGenero[data.identidadeGeneroDbEnum]}</Text>
                </HFlow>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Text fontWeight='bold'>Última atualização</Text>
                  <DateTime format='DD/MM/YYYY' value={data.dataAtualizado ? data.dataAtualizado : '-'} />
                </HFlow>
              </VFlow>
              <VFlow vSpacing={0}>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Nome da mãe</Text>
                  <Text>{data.nomeMae ? data.nomeMae.titleCase() : '-'}</Text>
                </HFlow>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Text fontWeight='bold'>Telefone</Text>
                  {telefone ? <Telefone value={telefone}></Telefone> : <Text>-</Text>}
                </HFlow>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Unidade responsável</Text>
                  <Text>{data.cidadaoVinculacaoEquipe?.unidadeSaude?.nome?.titleCase() || '-'}</Text>
                </HFlow>
                <HFlow hSpacing={0.5}>
                  <Text fontWeight='bold'>Município de nascimento</Text>
                  <Text>
                    {data.localidadeNascimento?.nome
                      ? data.localidadeNascimento?.nome?.titleCase() +
                        ' - ' +
                        data.localidadeNascimento?.uf?.sigla?.titleCase()
                      : '-'}
                  </Text>
                </HFlow>
              </VFlow>
            </HFlow>
          </VFlow>
          <HFlow alignItems='center' hSpacing={0.5}>
            {externalAccess ? (
              <Button kind='normal' skin='outline' size='small' onClick={() => navigateToCidadaoCallbackUrl(data.id)}>
                <Icon icon='plus' style={{ marginRight: '0.5rem' }} />
                Selecionar e voltar
              </Button>
            ) : (
              <Button kind='normal' skin='outline' size='small' onClick={onVisualizarClick}>
                <Icon icon='zoomOutline' style={{ marginRight: '0.5rem' }} />
                Visualizar
              </Button>
            )}
            {hasAnyPermission && (
              <DropdownButton>
                {externalAccess && <DropdownItem onClick={onVisualizarClick}>Visualizar</DropdownItem>}

                {cadastrarEEditar && (
                  <Tooltip text={!data?.ativo && 'Este cadastro não pode ser atualizado pois está inativo'}>
                    <DropdownItem disabled={!data.ativo} onClick={onEditarClick}>
                      Atualizar cadastro
                    </DropdownItem>
                  </Tooltip>
                )}

                {!data.ativo && inativar && !externalAccess && (
                  <DropdownItem onClick={onReativarClick}>Reativar cadastro</DropdownItem>
                )}

                {visualizarAgenda && !externalAccess && (
                  <Tooltip text={!data.possuiAgendamento && 'Não há agendamentos registrados para o cidadão'}>
                    <DropdownItem onClick={handleVerAgendamentosClick} disabled={!data.possuiAgendamento}>
                      Ver agendamentos
                    </DropdownItem>
                  </Tooltip>
                )}

                {excluir && !externalAccess && (
                  <>
                    <DropdownDivider />
                    <DropdownItem
                      type='danger'
                      style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem', display: 'flex', alignItems: 'center' }}
                      onClick={onExcluirClick}
                    >
                      <Icon icon='trashOutline' style={{ marginRight: '0.5rem' }} /> Excluir
                    </DropdownItem>
                  </>
                )}
              </DropdownButton>
            )}
          </HFlow>
        </HFlow>
      </Box>
    </>
  )
}
