import { Button, Heading, HeadingSection, HFlow, VFlow } from 'bold-ui'
import { alert } from 'components/alert'
import { LogoutLinkWrapper } from 'components/auth/LogoutLinkWrapper'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import useFirebase from 'components/firebase/useFirebase'
import { Form, SubmitButton } from 'components/form'
import { getItems, ModuloSelectField } from 'components/form/field/select/ModulosSelectField'
import { FormFooter } from 'components/form/FormFooter'
import { PageContent } from 'components/layout/PageContent'
import { useFlags } from 'config/useFlagsContext'
import { SessaoDocument, useDefinirModuloInicialMutation } from 'graphql/hooks.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { useHistory } from 'react-router'
import { createValidator, required } from 'util/validation'

interface EscolhaModuloAcessoRootViewProps {
  refresh(): void
}

const validator = createValidator({ moduloInicial: [required] })

export const EscolhaModuloAcessoRootView = ({ refresh }: EscolhaModuloAcessoRootViewProps) => {
  const user = useSession()
  const [mutate] = useDefinirModuloInicialMutation()
  const history = useHistory()
  const { analytics } = useFirebase()

  const renderForm = ({ handleSubmit, initialValues }: FormRenderProps) => (
    <VFlow style={{ paddingTop: '1rem' }} vSpacing={38 / 16}>
      <Heading level={1}>Módulo inicial</Heading>
      <Breadcrumb title='Alteração de módulo inicial' />
      <HeadingSection title='Escolha o módulo que deseja ver após acessar o sistema:' level={2}>
        <HFlow>
          <ModuloSelectField name='moduloInicial' style={{ width: `${368 / 16}rem` }} />
          {initialValues.moduloInicial && (
            <SubmitButton handleSubmit={handleSubmit} data-testid='FormFooter.salvar' size='small'>
              Salvar
            </SubmitButton>
          )}
        </HFlow>
      </HeadingSection>
      {!initialValues.moduloInicial && (
        <FormFooter>
          <LogoutLinkWrapper>
            <Button data-testid='FormFooter.sair'>Sair</Button>
          </LogoutLinkWrapper>
          <SubmitButton handleSubmit={handleSubmit} data-testid='FormFooter.salvar'>
            Salvar
          </SubmitButton>
        </FormFooter>
      )}
    </VFlow>
  )

  const moduloInicial = user.data && user.data.acesso.moduloInicial

  const { LISTA_REGISTRO_TARDIO_NOVA_ENABLED } = useFlags()

  const initialValue = getItems(LISTA_REGISTRO_TARDIO_NOVA_ENABLED).find((menuItem) => menuItem.to === moduloInicial)

  const save = (values) => {
    return mutate({
      variables: {
        input: {
          moduloInicial: values && values.moduloInicial && values.moduloInicial.to,
        },
      },
      refetchQueries: [{ query: SessaoDocument }],
    }).then(() => {
      alert('success', `Módulo inicial alterado com sucesso para ${values.moduloInicial.title}`)
      analytics.logEvent('modulo_inicial', { modulo_selecionado: values.moduloInicial.title })
      history.push('/')
    })
  }

  return (
    <PageContent type='filled'>
      <Form
        onSubmit={save}
        render={renderForm}
        validate={validator}
        initialValues={{
          moduloInicial: initialValue,
        }}
      />
    </PageContent>
  )
}
