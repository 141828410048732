/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FormSectionAccordion } from 'components/accordion/FormSectionAccordion'
import { useAlert } from 'components/alert'
import useFirebase from 'components/firebase/useFirebase'
import { LoadingIndicator } from 'components/loading/LoadingIndicator'
import { FormApi } from 'final-form'
import { useAlergiaSelectFieldQuery } from 'graphql/hooks.generated'
import { MetaRoot } from 'util/metaPath'
import { EditableList, useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { formatNomePrincipioAtivo } from '../../../aside/alergias/formatNomePrincipioAtivo'
import { AvaliacaoFormModel } from '../../AvaliacaoForm'
import { AlergiasReacoesForm } from './AlergiasReacoesForm'
import { AlergiasReacoesTable } from './AlergiasReacoesTable'
import { AlergiaReacaoModel } from './model'

export interface AlergiasReacoesFieldProps {
  name: MetaRoot<AvaliacaoFormModel>
  cidadao?: CidadaoAtendimento
  prontuarioId: ID
  dataAtendimento: Instant
}

export function AlergiasReacoesField(props: AlergiasReacoesFieldProps) {
  const { name, cidadao, prontuarioId, dataAtendimento } = props
  const alert = useAlert()
  const { analytics } = useFirebase()

  const {
    handleSubmit,
    handleRowChanged,
    removeItem,
    input: { value },
  } = useEditableListField({
    name: name.alergias,
  })

  const handleAddItem = (values: AlergiaReacaoModel, api: FormApi<AlergiaReacaoModel>) => {
    handleSubmit({ ...values, isAvaliacao: true }, api)
    alert('success', alertMessageAddAlergia(values))
    analytics.logEvent('adiciona_alergia_via_AV')
  }

  const handleEditItem = (values: AlergiaReacaoModel) => {
    handleRowChanged(values)
    alert('success', alertMessageEditAlergia(values))
  }

  const handleCancel = () => {
    alert('success', alertMessageCancelAddAlergia())
  }

  const {
    loading,
    data: { alergias },
  } = useAlergiaSelectFieldQuery({
    variables: {
      filtro: {
        prontuarioId,
      },
    },
  })

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <FormSectionAccordion
      uuid='alergias'
      name={name.openedAccordions}
      title='Alergias e reações adversas'
      style={css`
        margin: 0;
      `}
    >
      <EditableList style={{ borderWidth: 0 }}>
        <AlergiasReacoesForm
          formValues={value}
          cidadao={cidadao}
          dataAtendimento={dataAtendimento}
          onSubmit={handleAddItem}
          onCancel={handleCancel}
          alergiasCidadao={alergias.content}
        />

        <AlergiasReacoesTable
          alergias={value}
          cidadao={cidadao}
          handleEditItem={handleEditItem}
          handleRemoveItem={removeItem}
        />
      </EditableList>
    </FormSectionAccordion>
  )
}

const alertMessageAddAlergia = (model: AlergiaReacaoModel) => {
  return model.alergiaReacaoEvoluir
    ? `A avaliação da alergia/reação adversa à ${
        model.substanciaCbara?.nome ??
        model.substanciaImunobiologico?.nome ??
        formatNomePrincipioAtivo({
          principioAtivo: model?.substanciaMedicamento?.medicamento?.principioAtivo,
          concentracao: model?.substanciaMedicamento?.medicamento?.concentracao,
        })
      }
      foi incluída na Lista de alergias/reações adversas.`
    : `A alergia/reação adversa à ${
        model.substanciaCbara?.nome ??
        model.substanciaImunobiologico?.nome ??
        formatNomePrincipioAtivo({
          principioAtivo: model?.substanciaMedicamento?.medicamento?.principioAtivo,
          concentracao: model?.substanciaMedicamento?.medicamento?.concentracao,
        })
      }
      foi incluída na Lista de alergias/reações adversas.`
}

const alertMessageEditAlergia = (model: AlergiaReacaoModel) =>
  `A avaliação da alergia/reação adversa à ${
    model.substanciaCbara?.nome ??
    model.substanciaImunobiologico?.nome ??
    formatNomePrincipioAtivo({
      principioAtivo: model?.substanciaMedicamento?.medicamento?.principioAtivo,
      concentracao: model?.substanciaMedicamento?.medicamento?.concentracao,
    })
  }
  foi alterada na Lista de alergias/reações adversas.`

const alertMessageCancelAddAlergia = () => `A avaliação da alergia/reação adversa foi cancelada.`
