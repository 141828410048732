import { Alert, Heading, HFlow, Text, VFlow } from 'bold-ui'
import React from 'react'

import acompanhamentoCriancaGif from '../../images/novidades/cincoZero/acompanhamento_crianca.gif'
import acompanhamentoIdosoCard from '../../images/novidades/cincoZero/acompanhamento_idoso_card.png'
import acompanhamentoIdosoSoap from '../../images/novidades/cincoZero/acompanhamento_idoso_soap.png'
import acompanhamentoPreNatalCard from '../../images/novidades/cincoZero/acompanhamento_pre_natal_card.png'
import acompanhamentoPreNatalMedicoes from '../../images/novidades/cincoZero/acompanhamento_pre_natal_medicoes.png'
import acompanhamentoPreNatalSoap from '../../images/novidades/cincoZero/acompanhamento_pre_natal_soap.png'
import alergiaAntigo from '../../images/novidades/cincoZero/alergia_antes.png'
import alergiaRedesign from '../../images/novidades/cincoZero/alergia_depois.png'
import avaliacaoAlergia from '../../images/novidades/cincoZero/alergia.gif'
import antecedentesAcompanhamento from '../../images/novidades/cincoZero/antecedentes_acompanhamento.gif'
import antecedentesAntes from '../../images/novidades/cincoZero/antecedentes_antes.png'
import antecedentesCirurgias from '../../images/novidades/cincoZero/antecedentes_cirurgias.gif'
import antecedentesDepois from '../../images/novidades/cincoZero/antecedentes_depois.png'
import antecedentesFamiliar from '../../images/novidades/cincoZero/antecedentes_familiar.gif'
import antecedentesPessoal from '../../images/novidades/cincoZero/antecedentes_pessoal.gif'
import atendimentoEstruturaGif from '../../images/novidades/cincoZero/atendimento_estrutura.gif'
import atendimentoPequisaGif from '../../images/novidades/cincoZero/atendimento_pesquisa.gif'
import atendimentoProcedimentosGif from '../../images/novidades/cincoZero/atendimento_procedimentos.gif'
import atestadoBrancoAntes from '../../images/novidades/cincoZero/atestado_branco_antes.png'
import atestadoBrancoDepois from '../../images/novidades/cincoZero/atestado_branco_depois.png'
import atestadoModeloCadastro from '../../images/novidades/cincoZero/atestado_modelo_cadastro.png'
import atestadoModeloExemplo from '../../images/novidades/cincoZero/atestado_modelo_exemplo.png'
import atestadoPadraoAntes from '../../images/novidades/cincoZero/atestado_padrao_antes.png'
import atestadoPadraodepois from '../../images/novidades/cincoZero/atestado_padrao_depois.png'
import encaminhamentosAntes from '../../images/novidades/cincoZero/encaminhamentos_antes.png'
import encaminhamentosDepois from '../../images/novidades/cincoZero/encaminhamentos_depois.png'
import escutaInicialAntes2 from '../../images/novidades/cincoZero/escuta_inicial_antes_2.png'
import escutaInicialAntes from '../../images/novidades/cincoZero/escuta_inicial_antes.png'
import escutaInicialDepois2 from '../../images/novidades/cincoZero/escuta_inicial_depois_2.png'
import escutaInicialDepois from '../../images/novidades/cincoZero/escuta_inicial_depois.png'
import examesHistoricoAntes from '../../images/novidades/cincoZero/exames_historico_antes.jpeg'
import examesHistoricoDepois from '../../images/novidades/cincoZero/exames_historico_depois.png'
import examesHistoricoRegistroGif from '../../images/novidades/cincoZero/exames_historico_registros.gif'
import exameRegistroResultadoGif from '../../images/novidades/cincoZero/exames_registro_resultado.gif'
import exameResultadoAntes from '../../images/novidades/cincoZero/exames_resultado_antes.jpeg'
import exameResultadoDepois from '../../images/novidades/cincoZero/exames_resultado_depois.png'
import exameSolicitadoAntes from '../../images/novidades/cincoZero/exames_solicitados_antes.jpeg'
import exameSolicitadoDepois from '../../images/novidades/cincoZero/exames_solicitados_depois.png'
import examesSolicitarAltoCustoAntes from '../../images/novidades/cincoZero/exames_solicitar_alto_custo_antes.png'
import examesSolicitarAltoCustoDepois from '../../images/novidades/cincoZero/exames_solicitar_alto_custo_depois.png'
import examesSolicitarComumAntes from '../../images/novidades/cincoZero/exames_solicitar_comum_antes.jpeg'
import examesSolicitarComumDepois from '../../images/novidades/cincoZero/exames_solicitar_comum_depois.png'
import folhaRostoAntes from '../../images/novidades/cincoZero/folha_rosto_antes.png'
import folhaRostoAtendimentoGif from '../../images/novidades/cincoZero/folha_rosto_atendimento.gif'
import folhaRostoDepois from '../../images/novidades/cincoZero/folha_rosto_depois.png'
import folhaRostoProntuarioGif from '../../images/novidades/cincoZero/folha_rosto_prontuario.gif'
import acompanhamentoCriancaPaused from '../../images/novidades/cincoZero/gif_pausado/acompanhamento_criança.png'
import avaliacaoAlergiaPaused from '../../images/novidades/cincoZero/gif_pausado/alergia.png'
import antecedentesAcompanhamentoPaused from '../../images/novidades/cincoZero/gif_pausado/antecedentes_acompanhamento.png'
import antecedentesCirurgiasPaused from '../../images/novidades/cincoZero/gif_pausado/antecedentes_cirurgia.png'
import antecedentesFamiliarPaused from '../../images/novidades/cincoZero/gif_pausado/antecedentes_familiar.png'
import antecedentesPessoalPaused from '../../images/novidades/cincoZero/gif_pausado/antecedentes_pessoal.png'
import atendimentoEstruturaGifPaused from '../../images/novidades/cincoZero/gif_pausado/atendimento_estrutura.png'
import atendimentoPesquisaPaused from '../../images/novidades/cincoZero/gif_pausado/atendimento_pesquisa.png'
import atendimentoProcedimentosPaused from '../../images/novidades/cincoZero/gif_pausado/atendimento_procedimentos.png'
import examesHistoricoRegistroGifPaused from '../../images/novidades/cincoZero/gif_pausado/exames_historico_registros.png'
import exameRegistroResultadoGifPaused from '../../images/novidades/cincoZero/gif_pausado/exames_registro_resultado.png'
import folhaRostoAtendimentoGifPaused from '../../images/novidades/cincoZero/gif_pausado/folha_rosto_atendimento.png'
import folhaRostoProntuarioGifPaused from '../../images/novidades/cincoZero/gif_pausado/folha_rosto_prontuario.png'
import historicoExclusaoPaused from '../../images/novidades/cincoZero/gif_pausado/historico_exclusao.png'
import historicoFiltrosPaused from '../../images/novidades/cincoZero/gif_pausado/historico_filtros.png'
import historicoImpressaoPaused from '../../images/novidades/cincoZero/gif_pausado/historico_impressao.png'
import historicoVisualizacaoPaused from '../../images/novidades/cincoZero/gif_pausado/historico_visualizacao.png'
import medicamentosImpressaoPaused from '../../images/novidades/cincoZero/gif_pausado/medicamentos_impressao.png'
import medicamentosInterrupcaoPaused from '../../images/novidades/cincoZero/gif_pausado/medicamentos_interrupcao.png'
import medicamentosPesquisaPaused from '../../images/novidades/cincoZero/gif_pausado/medicamentos_pesquisa.png'
import medicamentosReplicarPaused from '../../images/novidades/cincoZero/gif_pausado/medicamentos_replicar.png'
import odontoOdontogramaGifPaused from '../../images/novidades/cincoZero/gif_pausado/odonto_odontograma.png'
import odontoPeriogramaCompletoGifPaused from '../../images/novidades/cincoZero/gif_pausado/odonto_periograma_completo.png'
import odontoTecidosMolesGifPaused from '../../images/novidades/cincoZero/gif_pausado/odonto_tecidos_moles.png'
import preNatalGifPaused from '../../images/novidades/cincoZero/gif_pausado/pre_natal_novo.png'
import vacinacaoAprazamentoPaused from '../../images/novidades/cincoZero/gif_pausado/vacinacao_aprazamento.png'
import historicoAntes from '../../images/novidades/cincoZero/historico_antes.png'
import historicoDepois from '../../images/novidades/cincoZero/historico_depois.png'
import historicoExclusao from '../../images/novidades/cincoZero/historico_exclusao.gif'
import historicoFiltros from '../../images/novidades/cincoZero/historico_filtros.gif'
import historicoImpressao from '../../images/novidades/cincoZero/historico_impressao.gif'
import historicoVisualizacao from '../../images/novidades/cincoZero/historico_visualizacao.gif'
import medicamentosAntes from '../../images/novidades/cincoZero/medicamentos_antes.png'
import medicamentosDepois from '../../images/novidades/cincoZero/medicamentos_depois.png'
import medicamentosImpressao from '../../images/novidades/cincoZero/medicamentos_impressao.gif'
import medicamentosInterrupcao from '../../images/novidades/cincoZero/medicamentos_interrupcao.gif'
import medicamentosPesquisa from '../../images/novidades/cincoZero/medicamentos_pesquisa.gif'
import medicamentosReplicar from '../../images/novidades/cincoZero/medicamentos_replicar.gif'
import odontoArcadaAntes from '../../images/novidades/cincoZero/odonto_arcada_antes.jpeg'
import odontoArcadaDepois from '../../images/novidades/cincoZero/odonto_arcada_depois.png'
import odontoDentesAntes from '../../images/novidades/cincoZero/odonto_dentes_antes.jpeg'
import odontoDentesDepois from '../../images/novidades/cincoZero/odonto_dentes_depois.png'
import odontoOdontogramaGif from '../../images/novidades/cincoZero/odonto_odontograma.gif'
import odontoOutrosAntes from '../../images/novidades/cincoZero/odonto_outros_antes.jpeg'
import odontoOutrosDepois from '../../images/novidades/cincoZero/odonto_outros_depois.png'
import odontoPeriogramaCompletoGif from '../../images/novidades/cincoZero/odonto_periograma_completo.gif'
import odontoRegistroPeriodontalSimplificado from '../../images/novidades/cincoZero/odonto_registro_periodontal_simplificado.png'
import odontoSextanteAntes from '../../images/novidades/cincoZero/odonto_sextante_antes.jpeg'
import odontoSextanteDepois from '../../images/novidades/cincoZero/odonto_sextante_depois.png'
import odontoTecidosMolesGif from '../../images/novidades/cincoZero/odonto_tecidos_moles.gif'
import odontoTecidosMolesEDuros from '../../images/novidades/cincoZero/odonto_tecidos_molese_duros.png'
import orientacoesAntes from '../../images/novidades/cincoZero/orientacoes_antes.png'
import orientacoesDepois from '../../images/novidades/cincoZero/orientacoes_depois.png'
import preNatalAntes from '../../images/novidades/cincoZero/pre_natal_antes.png'
import preNatalDepois from '../../images/novidades/cincoZero/pre_natal_depois.png'
import preNatalGif from '../../images/novidades/cincoZero/pre_natal_novo.gif'
import vacinaFolhaRosto from '../../images/novidades/cincoZero/vacina_folha_rosto.png'
import vacinacaoAprazamento from '../../images/novidades/cincoZero/vacinacao_aprazamento.gif'
import { Carousel, CarouselItem } from './carousel/Corousel'
import { NewsGif } from './NewsGif'
import { NewsHeadingSection } from './NewsHeadingSection'
import { NewsImg } from './NewsImg'

export function NewsInternalPanelCincoZero() {
  if (!process.env.REACT_APP_VERSION) {
    return (
      <Alert type='danger' inline>
        Nenhuma informação sobre versões anteriores está disponível.
      </Alert>
    )
  }

  return (
    <VFlow vSpacing={1}>
      <Text fontSize={0.875} fontWeight='bold'>
        Módulos com novidades:{' '}
      </Text>
      <HFlow hSpacing={5}>
        <VFlow vSpacing={0.2}>
          <Text fontSize={0.875} component='li'>
            Acompanhamentos
          </Text>
          <Text fontSize={0.875} component='li'>
            Alergias
          </Text>
          <Text fontSize={0.875} component='li'>
            Antecedentes
          </Text>
          <Text fontSize={0.875} component='li'>
            Atendimentos
          </Text>
          <Text fontSize={0.875} component='li'>
            Atendimento Odontológico
          </Text>
        </VFlow>
        <VFlow vSpacing={0.2}>
          <Text component='li' fontSize={0.875}>
            Atestados
          </Text>
          <Text fontSize={0.875} component='li'>
            Encaminhamentos e Orientações
          </Text>
          <Text fontSize={0.875} component='li'>
            Escuta inicial / Pré-atendimento
          </Text>
          <Text fontSize={0.875} component='li'>
            Exames
          </Text>
          <Text fontSize={0.875} component='li'>
            Histórico Clínico do Cidadão
          </Text>
        </VFlow>
        <VFlow vSpacing={0.2}>
          <Text fontSize={0.875} component='li'>
            Medicamentos
          </Text>
          <Text fontSize={0.875} component='li'>
            Pré-natal / Puerpério
          </Text>
          <Text fontSize={0.875} component='li'>
            Prontuário / Folha de Rosto
          </Text>
          <Text fontSize={0.875} component='li'>
            Vacinação
          </Text>
        </VFlow>
      </HFlow>
      <NewsHeadingSection title='Acompanhamentos' id='acompanhamentos-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! Agora a lateral do atendimento irá mostrar as informações dos acompanhamentos específicos do
            cidadão. <br></br> <br></br>
          </Text>
          <Heading level={5}>Acompanhamento da criança:</Heading>
          <Text fontSize={0.875} component='li'>
            Marcos de desenvolvimento integrados ao SOAP, possibilitando que os profissionais consultem outras
            informações durante a avaliação.
          </Text>
          <NewsGif
            src={acompanhamentoCriancaGif}
            pausedSrc={acompanhamentoCriancaPaused}
            alt='Gif mostrando o acompanhamento da criança'
          />{' '}
          <br></br>
          <Heading level={4}>Acompanhamento de pré-natal:</Heading>
          <Text fontSize={0.875} component='li'>
            Apresentação de todas as informações que antes estavam na tela de acompanhamento. Risco da gravidez, DPPs,
            IGs, DUM, consultas de pré-natal realizadas e a última consulta odontológica feita durante a gestação serão
            informações acessadas rapidamente pelos profissionais através da lateral.
          </Text>
          <Text fontSize={0.875} component='li'>
            Medições antropológicas com as curvas de referência durante a fase de gestação.
          </Text>
          <Text fontSize={0.875} component='li'>
            Medições específicas do atendimento de pré-natal apresentadas na modal de medições em uma aba separada
            juntamente com o gráfico de referência de altura uterina.{' '}
          </Text>
          <Carousel>
            <CarouselItem image={acompanhamentoPreNatalSoap} alt='Acompanhamento de pré-natal no SOAP' />
            <CarouselItem image={acompanhamentoPreNatalMedicoes} alt='Acompanhamento de pré-natal nas medições' />
            <CarouselItem image={acompanhamentoPreNatalCard} alt='Acompanhamento de pré-natal na barra lateral' />
          </Carousel>
          <Heading level={4}>Acompanhamento do idoso:</Heading>
          <Text fontSize={0.875} component='li'>
            Apresentação da data da última avaliação multidimensional feita para os cidadãos com 60 anos ou mais.
          </Text>
          <Text fontSize={0.875} component='li'>
            Apresentação de um alerta quando 5 ou mais medicamentos de uso contínuo estiverem ativos no cadastro do
            cidadão.
          </Text>
          <Carousel>
            <CarouselItem image={acompanhamentoIdosoSoap} alt='Acompanhamento do idoso no SOAP' />
            <CarouselItem image={acompanhamentoIdosoCard} alt='Acompanhamento do idoso na barra lateral' />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Alergias' id='alergias-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image={alergiaAntigo} alt='Módulo de alergias antigo' />
            <CarouselItem image={alergiaRedesign} alt='Módulo de alergias novo' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            O bloco para registrar a evolução de alergias estará sempre disponível na Avaliação do SOAP.
          </Text>
          <NewsGif src={avaliacaoAlergia} pausedSrc={avaliacaoAlergiaPaused} alt='Gif da avaliação de alergias' />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Antecedentes' id='antecedentes-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image={antecedentesAntes} alt='antecedentes nas versões antigas' />
            <CarouselItem image={antecedentesDepois} alt='antecedentes na nova versão' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Nova forma de cadastrar os antecedentes pessoais. Agora, o registro dos antecedentes é direto na Lista de
            Problemas e Condições através do botão "Adicionar antecedente resolvido".
          </Text>
          <NewsGif
            src={antecedentesPessoal}
            pausedSrc={antecedentesPessoalPaused}
            alt='gif mostrando os antecedentes pessoais'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Antecedentes familiares em uma área exclusiva, com novo campo de observações para cada registro.
          </Text>
          <NewsGif
            src={antecedentesFamiliar}
            pausedSrc={antecedentesFamiliarPaused}
            alt='gif mostrando os antecedentes familiares'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Novo grupo de antecedentes hospitalares para cadastro de cirurgias e internações, com novos campos para data
            e idade.
          </Text>
          <NewsGif
            src={antecedentesCirurgias}
            pausedSrc={antecedentesCirurgiasPaused}
            alt='gif mostrando os antecedentes hospitalares'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Novo botão para acompanhamento da criança, exibido após o cidadão completar 19 anos. Antes, ao atingir essa
            idade, a informação não era mais acessível. Agora, é possível através dos antecedentes.
          </Text>
          <NewsGif
            src={antecedentesAcompanhamento}
            pausedSrc={antecedentesAcompanhamentoPaused}
            alt='gif mostrando o acompanhamento da criança'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Atendimentos' id='atendimentos-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface com estrutura de informações na lateral do atendimento! Facilita o acesso à informações do
            prontuário que podem ser relevantes durante um atendimento. <br />
            São agrupadas em: Alergias/Reações adversas, Problemas/condições, Medições e gráficos, Medicamentos em uso,
            Lembretes, Resultados de exames, Condições autorreferidas e Acompanhamentos de pré-natal, do idoso e da
            criança.
          </Text>
          <NewsGif
            src={atendimentoEstruturaGif}
            pausedSrc={atendimentoEstruturaGifPaused}
            alt='Gif mostrando a nova estrutura da tela de Atendimento'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Pesquisa na Avaliação de problemas e condições que estão ativos ou latentes na Lista de Problema/Condições
            do cidadão, facilitando o registro da evolução.
          </Text>
          <NewsGif
            src={atendimentoPequisaGif}
            pausedSrc={atendimentoPesquisaPaused}
            alt='Gif mostrando as novas opções de pesquisa durante um atendimento'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Visualização das listas de Problemas e Condições e de Alergias/Reações adversas do cidadão no Atendimento de
            Procedimentos.
          </Text>
          <NewsGif
            src={atendimentoProcedimentosGif}
            pausedSrc={atendimentoProcedimentosPaused}
            alt='Gif mostrando o novo Atendimento de Procedimentos'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Atendimento Odontológico' id='atendimento-odonto-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo está em um novo formato, que une a Evolução Odontológica do sistema antigo com a
            inserção de Problemas e Condições nos dentes. Confira nas imagens abaixo as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image={odontoArcadaAntes} alt='Arcada antiga' />
            <CarouselItem image={odontoArcadaDepois} alt='Arcada nova' />
            <CarouselItem image={odontoDentesAntes} alt='Dentes antigo' />
            <CarouselItem image={odontoDentesDepois} alt='Dentes novo' />
            <CarouselItem image={odontoSextanteAntes} alt='Sextante antigo' />
            <CarouselItem image={odontoSextanteDepois} alt='Sextante novo' />
            <CarouselItem image={odontoOutrosAntes} alt='Outros antigo' />
            <CarouselItem image={odontoOutrosDepois} alt='Outros novo' />
            <CarouselItem image={odontoTecidosMolesEDuros} alt='Tecidos moles e duros' />
            <CarouselItem image={odontoRegistroPeriodontalSimplificado} alt='Registro periodontal simplificado' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Novos campos "Possui aparelho" e "Possui contenção";
          </Text>
          <Text fontSize={0.875} component='li'>
            Botão "Mostrar dentes decíduos" para ativar ou desativar a visualização dos dentes;
          </Text>
          <Text fontSize={0.875} component='li'>
            Registro de procedimentos em dentes supranumerários através de um botão abaixo do odontograma;
          </Text>
          <NewsGif
            src={odontoOdontogramaGif}
            pausedSrc={odontoOdontogramaGifPaused}
            alt='Gif do registro de dentes supranumerarios no odontograma'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Nova aba "Tecidos duros e moles", onde é possível fazer a análise de outras parte da boca que não sejam os
            dentes;
          </Text>
          <NewsGif
            src={odontoTecidosMolesGif}
            pausedSrc={odontoTecidosMolesGifPaused}
            alt='Gif da nova aba de tecidos moles e duros'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Nova aba "Periodontia", onde é possível fazer o Registro de procedimentos para sextantes, Registro
            Periodontal Simplificado e Periograma Completo.
          </Text>
          <NewsGif
            src={odontoPeriogramaCompletoGif}
            pausedSrc={odontoPeriogramaCompletoGifPaused}
            alt='Gif da nova aba de periodontia'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Atestados' id='atestados-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image={atestadoPadraoAntes} alt='Atestado padrão antigo' />
            <CarouselItem image={atestadoPadraodepois} alt='Atestado padrão novo' />
            <CarouselItem image={atestadoBrancoAntes} alt='Atestado em branco antigo' />
            <CarouselItem image={atestadoBrancoDepois} alt='Atestado em branco novo' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Cadastro de modelos de atestado para otimizar o tempo dos profissionais que usam atestados diferentes do
            modelo padrão.
          </Text>
          <Carousel>
            <CarouselItem image={atestadoModeloCadastro} alt='Cadastro de modelo de atestado' />
            <CarouselItem image={atestadoModeloExemplo} alt='Exemplo de atestado modelo' />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Encaminhamentos e Orientações' id='encaminhamento-orientacoes-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! Os módulos foram redesenhados e agora está ainda mais fácil de usar. Confira nas imagens
            abaixo as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image={orientacoesAntes} alt='Orientacoes antigo' />
            <CarouselItem image={orientacoesDepois} alt='Orientacoes novo' />
            <CarouselItem image={encaminhamentosAntes} alt='Encaminhamentos antigo' />
            <CarouselItem image={encaminhamentosDepois} alt='Encaminhamentos novo' />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Escuta inicial / Pré-atendimento' id='escuta-inicial-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image={escutaInicialAntes} alt='Escuta inicial antiga' />
            <CarouselItem image={escutaInicialDepois} alt='Escuta inicial nova' />
            <CarouselItem image={escutaInicialAntes2} alt='Escuta inicial antiga' />
            <CarouselItem image={escutaInicialDepois2} alt='Escuta inicial nova' />
          </Carousel>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Exames' id='exames-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image={exameResultadoAntes} alt='Resultado de exames antigo' />
            <CarouselItem image={exameResultadoDepois} alt='Resultado de exames novo' />
            <CarouselItem image={examesSolicitarComumAntes} alt='Solicitação de exame comum antigo' />
            <CarouselItem image={examesSolicitarComumDepois} alt='Solicitação de exame comum novo' />
            <CarouselItem image={examesSolicitarAltoCustoAntes} alt='Solicitação de alto custo antigo' />
            <CarouselItem image={examesSolicitarAltoCustoDepois} alt='Solicitação de alto custo novo' />
            <CarouselItem image={exameSolicitadoAntes} alt='Exame solicitado antigo' />
            <CarouselItem image={exameSolicitadoDepois} alt='Exame solicitado novo' />
            <CarouselItem image={examesHistoricoAntes} alt='Historico de exames antigo' />
            <CarouselItem image={examesHistoricoDepois} alt='Historico de exames novo' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            No histórico de solicitações de exames, é possível imprimir uma solicitação em até 30 dias e replicar todos
            os exames de uma solicitação antiga.
          </Text>
          <Text fontSize={0.875} component='li'>
            Os resultados de exames com e sem solicitação são registrados no mesmo local. A interface foi otimizada para
            inserir todos os resultados de exames no mesmo momento.
          </Text>
          <NewsGif
            src={exameRegistroResultadoGif}
            pausedSrc={exameRegistroResultadoGifPaused}
            alt='Gif da mostrando os resultados de exames'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            O histórico de resultados de exames está localizado no menu lateral e permite a comparação de vários
            resultados do mesmo exame;
          </Text>
          <NewsGif
            src={examesHistoricoRegistroGif}
            pausedSrc={examesHistoricoRegistroGifPaused}
            alt='Gif mostrando o historico de resultados de exame'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Histórico Clínico do Cidadão' id='historico-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado para facilitar a visualização dos diversos tipos de históricos e
            das principais informações indicadas pelos profissionais através de etiquetas (tags), sem ser necessário
            abrir os detalhamentos de cada histórico.
          </Text>
          <Carousel>
            <CarouselItem image={historicoAntes} alt='Histórico antigo' />
            <CarouselItem image={historicoDepois} alt='Histórico novo' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Implementação de novos filtros para a localização mais refinada dos históricos dos atendimentos.
          </Text>
          <NewsGif src={historicoFiltros} pausedSrc={historicoFiltrosPaused} alt='Gif dos filtros do histórico' />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Otimização na performance do sistema ao trazer os resultados na listagem do histórico clínico.
          </Text>
          <Text fontSize={0.875} component='li'>
            Impressão individual e em lote do histórico, facilitando sua ação.
          </Text>
          <NewsGif src={historicoImpressao} pausedSrc={historicoImpressaoPaused} alt='Gif da impressão de histórico' />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Visualização do detalhamento de cada histórico com design que facilita a leitura das informações,
            disponibilizadas na própria listagem ao expandir o item.
          </Text>
          <NewsGif
            src={historicoVisualizacao}
            pausedSrc={historicoVisualizacaoPaused}
            alt='Gif da visualização do historico'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Exclusão de um atendimento do tipo Vacinação.
          </Text>
          <NewsGif src={historicoExclusao} pausedSrc={historicoExclusaoPaused} alt='Gif da exclusão de historico' />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>
      <NewsHeadingSection title='Medicamentos' id='medicamentos-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image={medicamentosAntes} alt='Medicamentos antigo' />
            <CarouselItem image={medicamentosDepois} alt='Medicamentos novo' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Impressão pode ser realizada na mesma tela que está sendo prescrito o medicamento, sendo possível imprimir
            todos medicamentos daquela receita com um único clique.
          </Text>
          <NewsGif
            src={medicamentosImpressao}
            pausedSrc={medicamentosImpressaoPaused}
            alt='Gif da impressão de medicamentos'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Histórico das prescrições exibido na listagem em um único lugar com um design mais prático e moderno.
          </Text>
          <Text fontSize={0.875} component='li'>
            Pesquisa de um medicamento já prescrito, com opção de incluir os medicamentos de uso contínuo.{' '}
          </Text>
          <NewsGif
            src={medicamentosPesquisa}
            pausedSrc={medicamentosPesquisaPaused}
            alt='Gif da pesquisa de medicamentos'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Interrupção de um tratamento, bem como cancelar a interrupção.{' '}
          </Text>
          <NewsGif
            src={medicamentosInterrupcao}
            pausedSrc={medicamentosInterrupcaoPaused}
            alt='Gif da interrupção de um tratamento'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Replicação do receituário completo ou apenas de um medicamento prescrito em uma receita.{' '}
          </Text>
          <NewsGif
            src={medicamentosReplicar}
            pausedSrc={medicamentosReplicarPaused}
            alt='Gif da replicação de uma receita'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Pré-natal / Puerpério' id='pre-natal-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image={preNatalAntes} alt='Pre-natal nas versões antigas' />
            <CarouselItem image={preNatalDepois} alt='Pre-natal na nova versão' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Pré-natal só pode ser iniciado a partir da avaliação do SOAP. Condições de pré-natal lançadas diretamente na
            Lista de Problemas/Condições não são consideradas como Atendimento Individual com pré-natal.
          </Text>
          <NewsGif src={preNatalGif} pausedSrc={preNatalGifPaused} alt='Gif do novo pre-natal' /> <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Prontuário / Folha de Rosto' id='prontuario-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova interface! O módulo foi redesenhado e agora está ainda mais fácil de usar. Confira nas imagens abaixo
            as principais alterações:
          </Text>
          <Carousel>
            <CarouselItem image={folhaRostoAntes} alt='Folha de rosto antiga' />
            <CarouselItem image={folhaRostoDepois} alt='Folha de rosto nova' />
          </Carousel>
          <Text fontSize={0.875} component='li'>
            Através de cards, visualização de informações de Escuta Inicial/Pré-atendimento, Últimos contatos,
            Antecedentes, Medições, Vacinação, Problemas/Condições autorreferidas, Lembretes, Alergias/Reações adversas,
            Lista de problemas/condições, Resultados de exames, Medicamentos. Os cards são clicáveis e exibem mais
            informações ao serem abertos.
          </Text>
          <NewsGif
            src={folhaRostoAtendimentoGif}
            pausedSrc={folhaRostoAtendimentoGifPaused}
            alt='Gif da folha de rosto durante o atendimento'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Acesso à Folha de Rosto, Histórico e Vacinação a partir da visualização do prontuário. Na Folha de Rosto da
            visualização do prontuário há também informações do Plano, incluindo as evoluções odontológicas.
          </Text>
          <NewsGif
            src={folhaRostoProntuarioGif}
            pausedSrc={folhaRostoProntuarioGifPaused}
            alt='Gif da folha de rosto durante a visualização do prontuario'
          />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>

      <NewsHeadingSection title='Vacinação' id='vacinacao-5-0'>
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Nova funcionalidade de aprazamento automático de doses.
          </Text>
          <NewsGif
            src={vacinacaoAprazamento}
            pausedSrc={vacinacaoAprazamentoPaused}
            alt='Gif do aprazamento automatico de doses'
          />{' '}
          <br></br>
          <Text fontSize={0.875} component='li'>
            Visualização de informações da vacina na folha de rosto.
          </Text>
          <NewsImg src={vacinaFolhaRosto} alt='Imagem da visualização de informações de vacina na folha de rosto' />{' '}
          <br></br>
        </VFlow>
      </NewsHeadingSection>
    </VFlow>
  )
}
