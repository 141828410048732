import { focusBoxShadow, Heading, HFlow, Icon, Text, Theme, useStyles } from 'bold-ui'
import { AcessoLotacaoFragment, SessaoDataFragment } from 'graphql/types.generated'
import React, { useEffect, useRef } from 'react'
import { tipoAcesso } from 'types/enums'
import { capitalize, titleCase } from 'util/strConversor'

interface AcessoProps {
  acesso: SessaoDataFragment['profissional']['acessos'][0]
  onClick(acessoId: ID): void
}

export const Acesso = (props: AcessoProps) => {
  const { acesso, onClick } = props
  const handleClick = () => {
    onClick(acesso.id)
  }

  if (acesso.__typename === 'Lotacao') {
    return <LotacaoCard acesso={acesso} onClick={handleClick} />
  }

  return (
    <Card onClick={handleClick}>
      {acesso.__typename === 'AdministradorGeral' && <CardHeading>Administrador da Instalação</CardHeading>}

      {(acesso.__typename === 'AdministradorMunicipal' || acesso.__typename === 'GestorMunicipal') && (
        <CardHeading>{acesso.municipio.nome}</CardHeading>
      )}

      {acesso.__typename === 'GestorEstadual' && <CardHeading>{acesso.uf.nome}</CardHeading>}

      {acesso.__typename !== 'AdministradorGeral' && (
        <HFlow hSpacing={0.5} style={{ marginTop: '0.5rem' }}>
          <Text fontWeight='bold'>Tipo</Text>
          <Text>{tipoAcesso[acesso.tipo]}</Text>
        </HFlow>
      )}
    </Card>
  )
}

function LotacaoCard(props: AcessoProps & { acesso: AcessoLotacaoFragment }) {
  const { acesso, onClick } = props

  return (
    <Card onClick={onClick}>
      <CardHeading>{titleCase(acesso.unidadeSaude.nome)}</CardHeading>

      <HFlow hSpacing={0.5} style={{ marginTop: '0.5rem' }}>
        <Text fontWeight='bold'>CBO</Text>
        <Text>
          {capitalize(acesso.cbo.nome)} - {acesso.cbo.cbo2002}
        </Text>
      </HFlow>

      {acesso.equipe && (
        <HFlow hSpacing={0.5}>
          <Text fontWeight='bold'>Equipe</Text>
          <Text>
            {capitalize(acesso.equipe.nome)} - {acesso.equipe.ine}
          </Text>
        </HFlow>
      )}
    </Card>
  )
}

function CardHeading({ children }) {
  const { classes } = useStyles(() => ({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: '0.5rem',
    },
  }))
  return (
    <div className={classes.container}>
      <Icon icon='mapMarkerFilled' fill='primary' size={1} style={classes.icon} />
      <Heading level={3}>{children}</Heading>
    </div>
  )
}

function Card(props: React.HTMLAttributes<HTMLDivElement>) {
  const ref = useRef<HTMLDivElement>()
  const { classes } = useStyles(createStyles)

  useEffect(() => {
    const elem = ref.current
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault()
        elem.click()
      }
    }

    elem.addEventListener('keydown', handleKeyDown)
    return () => elem.removeEventListener('keydown', handleKeyDown)
  }, [ref])

  return <div ref={ref} className={classes.card} tabIndex={0} {...props} data-testid='Acesso.card' />
}

const createStyles = (theme: Theme) => ({
  card: {
    backgroundColor: theme.pallete.surface.main,
    border: '1px solid ' + theme.pallete.gray.c80,
    borderRadius: '4px',
    cursor: 'pointer',
    padding: '1rem',
    boxShadow: theme.shadows.outer['10'],
    transition: 'all .2s ease',
    '&:hover': {
      boxShadow: theme.shadows.outer['40'],
    },
    '&:focus': {
      outline: 'none',
      boxShadow: focusBoxShadow(theme),
    },
    '&:active': {
      boxShadow: theme.shadows.inner['10'],
    },
  },
})
