import {
  AgendamentoConsultaDesfechoInput,
  DesfechoInput,
  ProcedimentoAtendimentoInput,
  RacionalidadeEmSaudeEnum,
} from 'graphql/types.generated'
import { convertNovoAtendimentoModelToInput } from 'view/atendimentos/converter'

import { AgendarConsultaPanelModel } from './components/AgendarConsultaPanel'
import { ManterCidadaoLista } from './components/DesfechoAtendimentoPanel'
import { DesfechoFormModel } from './DesfechoForm'

export const convertDesfechoFormToInput = (form: DesfechoFormModel): DesfechoInput => {
  return (
    form && {
      tipoAtendimento: form.tipoAtendimento,
      atendimentoCompartilhadoId: form.atendimentoCompartilhado?.id,
      procedimentosAdministrativos: form.procedimentosAdministrativos?.map(
        (procedimento) =>
          ({
            id: procedimento.procedimento.id,
            cid10PrincipalId: procedimento.procedimento.procedimentoReferencia?.id,
            automatico: procedimento.automatico,
          } as ProcedimentoAtendimentoInput)
      ),
      fichaNotificacaoCasoSuspeito: form.fichasNotifiacaoCasoSuspeito?.map((ficha) => ficha.fichaNotificacao.id),
      racionalidadeEmSaude: form.racionalidadeEmSaude as RacionalidadeEmSaudeEnum,
      condutas: form.conduta,
      condutasOdonto: form.condutasOdonto,
      fornecimentosOdonto: form.fornecimento,
      tipoConsultaOdonto: form.tipoConsulta,
      desfechoAtendimento: form.desfechoAtendimento && {
        manterCidadaoLista: form.desfechoAtendimento.manterCidadaoLista === ManterCidadaoLista.SIM,
        atendimento:
          form.desfechoAtendimento.manterCidadaoLista === ManterCidadaoLista.SIM
            ? convertNovoAtendimentoModelToInput(form.desfechoAtendimento.atendimento)
            : undefined,
      },
      agendamentoConsulta: convertAgendarConsulta(form.agendamentoConsulta),
    }
  )
}

export const convertAgendarConsulta = (agendarConsulta: AgendarConsultaPanelModel): AgendamentoConsultaDesfechoInput =>
  agendarConsulta?.lotacao
    ? {
        lotacao: agendarConsulta.lotacao.id,
        horario: Number(agendarConsulta.horario?.inicial),
        observacoes: agendarConsulta.observacoes,
      }
    : undefined
