import { AcessoCbo } from 'graphql/types.generated'

export const NIVEL_SUPERIOR: AcessoCbo[] = [
  AcessoCbo.MEDICO,
  AcessoCbo.CIRURGIAO_DENTISTA,
  AcessoCbo.OUTRO_PROF_NIVEL_SUPERIOR,
  AcessoCbo.ENFERMEIRO,
  AcessoCbo.PSICOLOGO,
  AcessoCbo.FARMACEUTICO,
]

export const NIVEL_TECNICO: AcessoCbo[] = [AcessoCbo.AUXILIAR_OU_TECNICO_ENFERMAGEM, AcessoCbo.TSB]
