import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { parseISO } from 'date-fns'
import { SituacaoProblema } from 'graphql/types.generated'
import { reject } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'
import { dateAsYyyyMmDd, toDate } from 'util/date/formatDate'
import { v4 as uuidv4 } from 'uuid'
import { CiapCidPreNatal } from 'view/atendimentos/atendimento-individual/model'
import { CidPreNatalModel } from 'view/atendimentos/detail/soap/pre-natal/model'

import { Problema } from '../../../../aside/types/ProblemaModel'
import { convertDataProblema } from '../converter'
import { ProblemaCondicaoModel } from '../model'
import {
  isCiapW78,
  isProblemaComCiapW78,
  isProblemaCondicaoComCiapW78,
  isProblemaCondicaoDePreNatal,
} from './verifications'

export const findProblemaByCiapIdCidId = (problemasCidadao: Problema[], ciapId: ID, cid10Id: ID) =>
  (problemasCidadao ?? []).find(
    (problema) => problema && ciapId === problema.ciap?.id && cid10Id === problema.cid10?.id && !problema.descricaoOutro
  )

export const findProblemaById = (problemasCidadao: Problema[], id: ID) =>
  (problemasCidadao ?? []).find((problema) => problema.id === id)

export const createProblemaCondicaoAutomatico = (
  ciap: CiapSelectFieldModel,
  cid: CidSelectFieldModel,
  isMedico: boolean,
  situacao?: SituacaoProblema,
  incluirNaListaProblemas?: boolean,
  problemaJaExistente?: Problema,
  dataNascimentoCidadao?: string,
  dataFim?: string,
  dataAtendimento?: Instant
): ProblemaCondicaoModel => ({
  _id: uuidv4(),
  problemaId: problemaJaExistente?.id,
  ciap: ciap,
  cid: problemaJaExistente?.cid10 || isMedico ? cid : null,
  situacaoProblema: situacao,
  automatico: true,
  incluirNaListaProblemas: incluirNaListaProblemas,
  problemaCondicaoEvoluir: problemaJaExistente,
  dataInicio:
    dataNascimentoCidadao &&
    (problemaJaExistente
      ? problemaJaExistente?.ultimaEvolucao.dataInicio &&
        convertDataProblema(
          dateAsYyyyMmDd(toDate(problemaJaExistente.ultimaEvolucao.dataInicio)),
          parseISO(dataNascimentoCidadao)
        )
      : dataAtendimento &&
        convertDataProblema(dateAsYyyyMmDd(toDate(dataAtendimento)), parseISO(dataNascimentoCidadao))),
  dataFim: dataNascimentoCidadao && dataFim && convertDataProblema(dataFim, parseISO(dataNascimentoCidadao)),
})

export const addProblemaCondicaoAutomatico = (
  listaProblemasCondicoes: ProblemaCondicaoModel[],
  novoProblemaCondicao: ProblemaCondicaoModel
) => {
  const { ciap: toAddCiap, cid: toAddCid, situacaoProblema: toAddSituacao } = novoProblemaCondicao
  const isW78 = isCiapW78(toAddCiap?.codigo)
  const jaPossuiProblemaCondicao = listaProblemasCondicoes.find(
    ({ ciap, cid, situacaoProblema }) =>
      toAddCiap?.codigo === ciap?.codigo &&
      toAddCid?.codigo === cid?.codigo &&
      (!isW78 || (toAddSituacao === situacaoProblema && toAddSituacao !== SituacaoProblema.RESOLVIDO))
  )
  if (!jaPossuiProblemaCondicao) return [...listaProblemasCondicoes, novoProblemaCondicao]
  return listaProblemasCondicoes
}

interface ProblemaCondicaoToRemove {
  ciapToRemove?: string
  cidToRemove?: string
  situacaoToRemove?: SituacaoProblema
  isAutomaticoToRemove?: boolean
}

export const removeProblemaCondicao = (
  listaProblemaCondicoes: ProblemaCondicaoModel[],
  isMedico: boolean,
  problemaCondicaoToRemove: ProblemaCondicaoToRemove
) => listaProblemaCondicoes.filter((problema) => !shouldRemoveProblema(problema, isMedico, problemaCondicaoToRemove))

function shouldRemoveProblema(
  problema: ProblemaCondicaoModel,
  isMedico: boolean,
  problemaCondicaoToRemove: ProblemaCondicaoToRemove
) {
  const { ciap, cid, situacaoProblema, automatico } = problema
  const { ciapToRemove, cidToRemove, situacaoToRemove, isAutomaticoToRemove } = problemaCondicaoToRemove

  const isAutomaticoEqual = isUndefinedOrNull(isAutomaticoToRemove) || isAutomaticoToRemove === automatico
  const isSituacaoEqual = isUndefinedOrNull(situacaoToRemove) || situacaoToRemove === situacaoProblema
  const isCiapOrCidEqual = isMedico
    ? ciapToRemove === ciap?.codigo || cidToRemove === cid?.codigo
    : ciapToRemove === ciap?.codigo
  return isAutomaticoEqual && isSituacaoEqual && isCiapOrCidEqual
}

export function removeProblemasCondicoesDePreNatal(
  listaProblemaCondicoes: ProblemaCondicaoModel[],
  isContinuacaoPreNatal: boolean
) {
  return reject(listaProblemaCondicoes, (problemaCondicao) =>
    isProblemaCondicaoDePreNatal(problemaCondicao, isContinuacaoPreNatal)
  )
}

export const atualizarProblemaCondicao = (
  novoProblemaCondicao: ProblemaCondicaoModel,
  problemaJaExistente: Problema,
  dataNascimentoCidadao: LocalDate
): ProblemaCondicaoModel => ({
  _id: uuidv4(),
  problemaId: problemaJaExistente?.id,
  ciap: novoProblemaCondicao.ciap,
  cid: novoProblemaCondicao.cid || problemaJaExistente.cid10,
  situacaoProblema: novoProblemaCondicao.situacaoProblema,
  incluirNaListaProblemas: true,
  problemaCondicaoEvoluir: problemaJaExistente,
  observacao: novoProblemaCondicao.observacao,
  dataInicio:
    novoProblemaCondicao.dataInicio ??
    convertDataProblema(problemaJaExistente.ultimaEvolucao?.dataInicio, parseISO(dataNascimentoCidadao)),
  dataFim:
    novoProblemaCondicao.dataFim ??
    convertDataProblema(problemaJaExistente.ultimaEvolucao?.dataFim, parseISO(dataNascimentoCidadao)),
})

export function findProblemaCondicaoComCiapW78(problemasCondicoes?: ProblemaCondicaoModel[]) {
  return problemasCondicoes && problemasCondicoes.find(isProblemaCondicaoComCiapW78)
}

export const findProblemaComCiapW78 = (problemasCidadao?: Problema[]) =>
  problemasCidadao && problemasCidadao.find(isProblemaComCiapW78)

export const createProblemaCondicaoAutomaticoPreNatal = (
  ciapCidPreNatal: CiapCidPreNatal,
  isMedico: boolean,
  situacaoProblema: SituacaoProblema,
  cid?: CidPreNatalModel,
  problemaPreNatalJaExistente?: Problema,
  dataNascimentoCidadao?: LocalDate,
  dataFim?: LocalDate,
  dataAtendimento?: Instant
): ProblemaCondicaoModel =>
  createProblemaCondicaoAutomatico(
    ciapCidPreNatal.ciap,
    cid ?? ciapCidPreNatal.cid,
    isMedico,
    situacaoProblema,
    true,
    problemaPreNatalJaExistente,
    dataNascimentoCidadao,
    dataFim,
    dataAtendimento
  )
