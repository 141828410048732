import { isEmpty, Text, VFlow } from 'bold-ui'
import {
  AtendimentoProfissionalPreNatal,
  AtendimentoProfissionalPuericultura,
  EvolucaoObjetivo,
  ResultadoExame,
} from 'graphql/types.generated'
import React from 'react'
import { filterByRegistroAnterior } from 'view/atendimentos/detail/historico/utils/filterByRegistroAnterior'
import {
  convertToMedicaoModel,
  hasAllAttrsNull,
  hasMedicoes,
  hasMedicoesPreNatal,
  naoRegistradoNesseAtendimento,
} from 'view/atendimentos/detail/historico/utils/historicoUtils'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import HistoricoMedicoesTableBox from '../../HistoricoMedicoesTableBox'
import HistoricoResultadoExamesTable from '../../HistoricoResultadoExamesTable'
import HistoricoMedicoesPreNatalTableBox from '../pre-natal/HistoricoMedicoesPreNatalTableBox'
import HistoricoObjetivoInformacoesPuericultura from '../puericultura/HistoricoObjetivoInformacoesPuericultura'

export interface AtendimentoProfissionalPreNatalModel
  extends Pick<AtendimentoProfissionalPreNatal, 'id' | 'gravidezPlanejada' | 'movimentacaoFetal' | 'tipoEdema'> {}

export interface HistoricoObjetivoPecPanelProps {
  evolucaoObjetivo: EvolucaoObjetivo
  medicoes: HistoricoMedicaoModel[]
  examesResultados?: ResultadoExame[]
  atendProfPreNatal?: AtendimentoProfissionalPreNatalModel
  atendProfPuericultura?: AtendimentoProfissionalPuericultura
}

export default function HistoricoObjetivoPecPanel(props: HistoricoObjetivoPecPanelProps) {
  const { evolucaoObjetivo, medicoes, examesResultados, atendProfPreNatal, atendProfPuericultura } = props

  const { recentes } = filterByRegistroAnterior<HistoricoMedicaoModel>(medicoes, 'medicaoAnterior')

  const descricao = evolucaoObjetivo?.descricao?.removeTags()

  const possuiInformacoesPuericultura: boolean = atendProfPuericultura && !hasAllAttrsNull(atendProfPuericultura)

  const hasAtendProfPreNatal: boolean =
    atendProfPreNatal?.gravidezPlanejada || atendProfPreNatal?.movimentacaoFetal || !!atendProfPreNatal?.tipoEdema

  const hasPreNatalInfo = hasAtendProfPreNatal || hasMedicoesPreNatal(recentes)

  const hasObjetivo: boolean =
    !!descricao ||
    !isEmpty(examesResultados) ||
    hasMedicoes(recentes) ||
    hasPreNatalInfo ||
    possuiInformacoesPuericultura

  return (
    <>
      {hasObjetivo ? (
        <VFlow>
          {descricao && <Text dangerouslySetInnerHTML={{ __html: evolucaoObjetivo.descricao }} />}
          {hasMedicoes(recentes) && <HistoricoMedicoesTableBox sectionStyle='O' medicoes={recentes} />}
          {hasPreNatalInfo && (
            <HistoricoMedicoesPreNatalTableBox medicoes={recentes} atendProfPreNatal={atendProfPreNatal} />
          )}
          {possuiInformacoesPuericultura && (
            <HistoricoObjetivoInformacoesPuericultura
              medicoes={convertToMedicaoModel(recentes)}
              atendProfPuericultura={atendProfPuericultura}
            />
          )}
          {!isEmpty(examesResultados) && <HistoricoResultadoExamesTable examesResultados={examesResultados} />}
        </VFlow>
      ) : (
        <Text fontStyle='italic'>{naoRegistradoNesseAtendimento}</Text>
      )}
    </>
  )
}
