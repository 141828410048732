import { QueryHookOptions, useApolloClient } from '@apollo/react-hooks'
import atmosphere from 'atmosphere.js'
import { useSessaoQuery } from 'graphql/hooks.generated'
import { AcessoCbo, Cbo, Lotacao, SessaoQuery, SessaoQueryVariables } from 'graphql/types.generated'
import { useCallback, useMemo } from 'react'
import GruposCbo, { GrupoCbo } from 'types/GruposCbo'
import { Permission } from 'types/Permissions'
import { notify } from 'util/multitab'

import useLoginAnalytics from './useLoginAnalytics'
import { Authorization, isCboPermission, isPermission, isPermissionArray, isUnidadeSaudeCEO } from './useSessionUtils'

export default (options: QueryHookOptions<SessaoQuery, SessaoQueryVariables> = {}) => {
  const client = useApolloClient()

  const { data, refetch, loading } = useSessaoQuery({
    fetchPolicy: 'cache-first',
    ...options,
  })

  const resetApolloCache = useCallback(async () => {
    await client.cache.reset()
  }, [client])

  const { remove: removeLoginAnalytics } = useLoginAnalytics()

  const afterLogout = useCallback(async () => {
    await resetApolloCache()
    atmosphere.unsubscribe()
    window.localStorage.removeItem('LOGIN')
    window.localStorage.removeItem('LOGOUT')
    window.localStorage.removeItem('PERFIL_SELECIONADO')
    window.localStorage.removeItem('RESET_SESSION')
    window.localStorage.removeItem('NEWS')
    removeLoginAnalytics()
  }, [resetApolloCache, removeLoginAnalytics])

  const logoutSuccess = useCallback(async () => {
    notify('LOGOUT')
    afterLogout()
  }, [afterLogout])

  const recursosSet = useMemo(() => new Set(data?.sessao?.recursos), [data])
  const hasAuthorization = useCallback((permission: Permission) => recursosSet.has(permission.uri), [recursosSet])

  const hasCboAuth = useCallback(
    (acessos: AcessoCbo[]) => {
      if (isLotacao(data?.sessao?.acesso)) {
        const sessionCbo: Cbo = data?.sessao?.acesso?.cbo
        const groups: GrupoCbo[] = GruposCbo.filter((grupoCbo: GrupoCbo) =>
          acessos.find((acesso) => acesso === grupoCbo.grupo)
        ).map((item) => item as GrupoCbo)

        return groups.some(
          (item: GrupoCbo) =>
            item.cbos.some((cbo) => sessionCbo.cbo2002 === cbo) ||
            item.cbos.some((cbo) => sessionCbo.cbo2002.startsWith(cbo))
        )
      } else {
        return false
      }
    },
    [data]
  )

  const hasPermissionAuth = useCallback((permissions: Permission[]) => permissions.some(hasAuthorization), [
    hasAuthorization,
  ])

  const checkAuthorization = useCallback(
    (permission: Authorization) => {
      if (isPermission(permission)) {
        return hasAuthorization(permission)
      } else if (isCboPermission(permission)) {
        return hasCboAuth(permission)
      } else if (isPermissionArray(permission)) {
        return hasPermissionAuth(permission)
      }
    },
    [hasAuthorization, hasCboAuth, hasPermissionAuth]
  )

  return {
    loading,
    data: data && data.sessao,
    isUnidadeSaudeCEO: isUnidadeSaudeCEO((data.sessao?.acesso as Lotacao)?.unidadeSaude),
    resetApolloCache,
    logoutSuccess,
    afterLogout,
    refresh: () => refetch(),
    hasAuthorization,
    hasCboAuth,
    checkAuthorization,
  }
}

const isLotacao = (acesso): acesso is Lotacao => acesso.cbo
