import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import { AtendimentoIndividualViewQuery, TipoAtendimentoEnum } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'
import { v4 as uuidv4 } from 'uuid'

import { AtendimentoVacinacaoState } from '../atendimento-vacinacao/model'
import { LembreteFormModel } from '../detail/components/modals/lembretes/components/LembreteForm'
import { MedicamentoInterromperFormModel } from '../detail/components/modals/medicamentos/InterromperTratamentoModal'
import { MedicaoAnteriorFormModel } from '../detail/components/modals/medicoes/MedicaoAnteriorForm'
import { AntecedentesFormModel } from '../detail/soap/antecedentes/AntecedentesForm'
import { AvaliacaoFormModel } from '../detail/soap/avaliacao/AvaliacaoForm'
import { ProblemaCondicaoModel } from '../detail/soap/avaliacao/components/problemas-condicoes/model'
import { DesfechoFormModel } from '../detail/soap/desfecho'
import { ManterCidadaoLista } from '../detail/soap/desfecho/components/DesfechoAtendimentoPanel'
import { ObjetivoFormModel } from '../detail/soap/objetivo'
import { PlanoFormModel } from '../detail/soap/plano/PlanoForm'
import { PreNatalFormModel } from '../detail/soap/pre-natal/model'
import { SubjetivoFormModel } from '../detail/soap/subjetivo'
import { AgendamentoDia } from '../types/AgendamentoDia'

export type TipoServicoModel = AtendimentoIndividualViewQuery['atendimento']['tiposServico'][number]

export const TIPOS_ATENDIMENTO_CONCLUEM_AGENDAMENTO = [
  TipoAtendimentoEnum.CONSULTA_AGENDADA_CUIDADO_CONTINUADO,
  TipoAtendimentoEnum.CONSULTA,
]

export interface SoapState {
  openedAccordions?: (string | number)[]
  antecedentes?: AntecedentesFormModel
  subjetivo?: SubjetivoFormModel
  objetivo?: ObjetivoFormModel
  avaliacao?: AvaliacaoFormModel
  plano?: PlanoFormModel
  desfecho?: DesfechoFormModel
  imprimirAtendimento?: boolean
  lastSaved?: Date
  medicoesAnteriores?: MedicaoAnteriorFormModel[]
  medicamentosInterromper?: MedicamentoInterromperFormModel[]
  lembretes?: LembreteFormModel[]
  vacinacao?: AtendimentoVacinacaoState
  preNatal?: PreNatalFormModel
  problemasECondicoes?: ProblemaCondicaoModel[]
}

export const meta = metaPath<SoapState>()

export const initialValue = (
  procedimentoAdministrativo: ProcedimentoSelectModel,
  tipoAtendimento: TipoAtendimentoEnum,
  agendamentosDia: AgendamentoDia[],
  tiposServico: TipoServicoModel[],
  canCreateNovoAtendimento: boolean,
  isRegistroTardio: boolean
): Partial<SoapState> => ({
  openedAccordions: ['S', 'O', 'A', 'P', 'D'],
  lastSaved: new Date(),
  desfecho: {
    tipoAtendimento: tipoAtendimento,
    procedimentosAdministrativos: procedimentoAdministrativo && [
      { _id: uuidv4(), procedimento: procedimentoAdministrativo, automatico: true },
    ],
    desfechoAtendimento: {
      manterCidadaoLista:
        (agendamentosDia?.length || tiposServico?.length) && canCreateNovoAtendimento && !isRegistroTardio
          ? ManterCidadaoLista.SIM
          : ManterCidadaoLista.NAO,
      atendimento: {
        tiposServico: tiposServico?.map((t) => t.id),
      },
    },
  },
  medicoesAnteriores: [],
  lembretes: [],
})

export const initialValueObjetivo = (isAtendimentoProcedimentos: boolean): Partial<ObjetivoFormModel> => ({
  medicoes: {
    openedAccordions: isAtendimentoProcedimentos ? ['medicoes'] : [],
  },
})

export interface CiapCidPreNatal {
  ciap: CiapSelectFieldModel
  cid: CidSelectFieldModel
}
