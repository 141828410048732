import { isFunction } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'
import { MetaPath } from 'util/metaPath'
import { ErrorObject } from 'util/validation'
import { meta, SoapState } from 'view/atendimentos/atendimento-individual/model'

import { getSoapFieldLabelProperties } from '../../soapFieldsLabels'
import { SoapValidationModalErrorsItems, SoapValidationModalItemModel } from './model'

export const convertSoapErrorsToErrorItemList = (
  errors: ErrorObject<SoapState> | SoapValidationModalItemModel
): SoapValidationModalErrorsItems => {
  return Object.assign(
    {},
    ...Object.entries(errors).map(([key, value]) => {
      const sectionName = meta[key]

      const { label, extractError } = getSoapFieldLabelProperties(sectionName)

      const extractedError = extractError?.(value) ?? value

      const errorItem: SoapValidationModalItemModel =
        typeof value === 'object' && !extractError
          ? { label, fields: convertToErrorItems(value, sectionName) }
          : { label, error: extractedError }

      return { [key]: errorItem }
    })
  )
}

const convertToErrorItems = (
  errors: ErrorObject<SoapState> | SoapValidationModalItemModel,
  parentName: MetaPath<any> = null
): SoapValidationModalItemModel[] => {
  return Object.entries(errors).map(([key, value]) => {
    const fieldName = parentName ? parentName[key] : meta[key]
    const properties = getSoapFieldLabelProperties(fieldName)

    if (isUndefinedOrNull(properties)) return null

    const { label, fields, hideChildrens, hideLabelOnModal, extractError } = properties

    const showChildrens = !(isFunction(hideChildrens) ? hideChildrens(value) : hideChildrens)
    const showLabel = !(isFunction(hideLabelOnModal) ? hideLabelOnModal(value) : hideLabelOnModal)
    const extractedError = extractError?.(value) ?? value
    const convertedFields = showChildrens && !isUndefinedOrNull(fields) ? convertToErrorItems(value, fieldName) : []

    return {
      label,
      fields: convertedFields,
      error: typeof extractedError === 'string' ? extractedError : null,
      showLabelOnModal: showLabel,
    }
  })
}
