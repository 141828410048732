import { Calculation } from 'final-form-calculate'
import { TipoAtendimentoEnum, TipoConsultaOdontoEnum, TipoEncaminhamentoOdontoEnum } from 'graphql/types.generated'
import { isEmpty } from 'lodash'
import { ProcedimentosAutomaticoAtendimentoOdonto, ProcedimentosAutomaticosAtendimentoIndividual } from 'types/enums'
import { Meta, MetaRoot } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { getProcedimentosWithNewProced } from 'view/atendimentos/detail/soap/desfecho/calculator/calculatorIndividual'
import { ProcedimentoAutomatico } from 'view/atendimentos/model'

import { ProcedimentoSigtapFieldModel } from '../components/ProcedimentoSigtapField'
import { DesfechoFormModel } from '../DesfechoForm'

const calculateProcedimentoAutomaticoToAdd = (isUrgencia: boolean, isCEO: boolean) => {
  return isUrgencia
    ? isCEO
      ? ProcedimentosAutomaticoAtendimentoOdonto.ATENDIMENTO_DE_URGENCIA_EM_ATENCAO_ESPECIALIZADA
      : ProcedimentosAutomaticosAtendimentoIndividual.ATENDIMENTO_DE_URGENCIA_NA_ATENCAO_BASICA
    : isCEO
    ? ProcedimentosAutomaticoAtendimentoOdonto.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_ESPECIALIZADA
    : ProcedimentosAutomaticosAtendimentoIndividual.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_BASICA
}

const tipoConsultaCalculator = (
  meta: Meta<DesfechoFormModel> & MetaRoot<DesfechoFormModel>,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  isCEO: boolean
): Calculation => ({
  field: meta.tipoConsulta.absolutePath(),
  updates: {
    [meta.condutasOdonto.absolutePath()]: (tipoConsulta: TipoConsultaOdontoEnum, allValues: SoapState) => {
      const tipoConsultaNullOrManutencao = !tipoConsulta || tipoConsulta === 'CONSULTA_DE_MANUTENCAO'
      const condutaToRemove = tipoConsultaNullOrManutencao
        ? TipoEncaminhamentoOdontoEnum.TRATAMENTO_CONCLUIDO
        : TipoEncaminhamentoOdontoEnum.ALTA_DO_EPISODIO
      return allValues.desfecho.condutasOdonto?.filter((c: TipoEncaminhamentoOdontoEnum) => c !== condutaToRemove)
    },
    [meta.procedimentosAdministrativos.absolutePath()]: (
      tipoConsulta: TipoConsultaOdontoEnum,
      allValues: SoapState,
      prevValues: SoapState
    ) => {
      if (!isEmpty(prevValues)) {
        const isAtendimentoUrgencia = allValues.desfecho.tipoAtendimento === TipoAtendimentoEnum.URGENCIA
        const procedToAdd =
          tipoConsulta === TipoConsultaOdontoEnum.PRIMEIRA_CONSULTA_ODONTOLOGICA_PROGRAMATICA
            ? ProcedimentosAutomaticoAtendimentoOdonto.PRIMEIRA_CONSULTA_ODONTOLOGICA_PROGRAMATICA
            : calculateProcedimentoAutomaticoToAdd(isAtendimentoUrgencia, isCEO)

        const procedRef = procedimentosAutomaticos.find((p) => p.codigo === procedToAdd)
        return getProcedimentosWithNewProced(allValues.desfecho.procedimentosAdministrativos, procedRef)
      }
    },
  },
})

const calculateProcedimentosAutomaticosByTipoAtendimento = (
  allValues: SoapState,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  isCEO: boolean
): ProcedimentoSigtapFieldModel[] => {
  const procedToAdd = calculateProcedimentoAutomaticoToAdd(
    allValues.desfecho.tipoAtendimento === TipoAtendimentoEnum.URGENCIA,
    isCEO
  )
  const procedRef = procedimentosAutomaticos.find((p) => p.codigo === procedToAdd)
  return getProcedimentosWithNewProced(allValues.desfecho.procedimentosAdministrativos, procedRef)
}

const tipoAtendimentoCalculator = (
  meta: Meta<DesfechoFormModel> & MetaRoot<DesfechoFormModel>,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  isCEO: boolean
): Calculation => ({
  field: [meta.tipoAtendimento.absolutePath()],
  updates: {
    [meta.tipoConsulta.absolutePath()]: (tipoAtendimento: TipoAtendimentoEnum, allValues: SoapState) =>
      tipoAtendimento === TipoAtendimentoEnum.URGENCIA &&
      allValues.desfecho.tipoConsulta === TipoConsultaOdontoEnum.CONSULTA_DE_RETORNO
        ? undefined
        : allValues.desfecho.tipoConsulta,
    [meta.procedimentosAdministrativos.absolutePath()]: (
      tipoAtendimento: TipoAtendimentoEnum,
      allValues: SoapState,
      prevValues: SoapState
    ) => {
      if (!isEmpty(prevValues)) {
        return allValues.desfecho.tipoConsulta === TipoConsultaOdontoEnum.PRIMEIRA_CONSULTA_ODONTOLOGICA_PROGRAMATICA
          ? allValues.desfecho.procedimentosAdministrativos
          : calculateProcedimentosAutomaticosByTipoAtendimento(allValues, procedimentosAutomaticos, isCEO)
      }
      return allValues.desfecho.procedimentosAdministrativos
    },
  },
})

export const createDesfechoOdontoCalculations = (
  meta: Meta<DesfechoFormModel> & MetaRoot<DesfechoFormModel>,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  isCEO: boolean
): Calculation[] => [
  tipoConsultaCalculator(meta, procedimentosAutomaticos, isCEO),
  tipoAtendimentoCalculator(meta, procedimentosAutomaticos, isCEO),
]
