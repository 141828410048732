import { SelectField, SelectFieldProps } from 'components/form'
import { DEFAULT_SELECT_SIZE } from 'components/form/field/select/useAsyncQuerySelect'
import { useLocalTecidosMolesSelectFieldQuery } from 'graphql/hooks.generated'
import { ParteBucalEnum, TipoParteBucalEnum } from 'graphql/types.generated'
import React from 'react'

export interface LocalTecidosMolesSelectFieldModel {
  value: ParteBucalEnum
  label: string
}

export interface LocalTecidosMolesSelectFieldProps
  extends Omit<SelectFieldProps<LocalTecidosMolesSelectFieldModel>, 'label' | 'itemToString' | 'items'> {}

export default function LocalTecidosMolesSelectField(props: LocalTecidosMolesSelectFieldProps) {
  const {
    data: { partesBucais },
  } = useLocalTecidosMolesSelectFieldQuery({
    fetchPolicy: 'cache-first',
    variables: {
      input: { tiposPartesBucais: [TipoParteBucalEnum.TECIDO_MOLE], pageParams: { size: DEFAULT_SELECT_SIZE } },
    },
  })

  return (
    <SelectField<LocalTecidosMolesSelectFieldModel>
      label='Local'
      items={convertTecidoMoleQueryToModel(partesBucais?.content)}
      itemToString={(i) => i?.label}
      {...props}
    />
  )
}

const convertTecidoMoleQueryToModel = (items) =>
  items?.map((item) => ({ value: item.parteBucalEnum, label: item.descricao } as LocalTecidosMolesSelectFieldModel))
