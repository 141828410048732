/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FormControl, VFlow } from 'bold-ui'
import { CheckboxField } from 'components/form'
import { TipoEstabelecimento } from 'graphql/types.generated'

export interface TipoEstabelecimentoField {
  name: string
  required?: boolean
}

export function TipoEstabelecimentoField(props: TipoEstabelecimentoField) {
  const { name, ...checkboxProps } = props

  return (
    <FormControl label='Tipos de estabelecimento' required={checkboxProps.required}>
      <VFlow
        vSpacing={0.5}
        css={css`
          margin: 0 -0.25rem;
        `}
      >
        <CheckboxField name={name} label='Unidade Básica de Saúde' {...checkboxProps} value={TipoEstabelecimento.UBS} />
        <CheckboxField
          name={name}
          label='Centro de Especialidades Odontológicas'
          {...checkboxProps}
          value={TipoEstabelecimento.CEO}
        />
      </VFlow>
    </FormControl>
  )
}
