import { isEmpty } from 'bold-ui'
import {
  AtestadoEmBrancoPlanoInput,
  AtestadoPadraoPlanoInput,
  AtestadoPlanoInput,
  EncaminhamentoExternoInput,
  EvolucaoDenteInput,
  EvolucaoProcedimentoOdontoInput,
  ExameRequisitadoInput,
  LicencaMaternidadePlanoInput,
  MedicamentoPlanoInput,
  OdontologiaInput,
  OrientacaoPlanoInput,
  ParteBucalEnum,
  PeriodontiaInput,
  PeriogramaCompletoInput,
  PlanoInput,
  PrescricaoMedicamentoPlanoInput,
  SituacaoFaceEnum,
  SituacaoRaizEnum,
  SolicitacaoExameInput,
  TecidoMoleInput,
  TipoAtestadoEnum,
} from 'graphql/types.generated'
import { compact, concat } from 'lodash'
import { isObjectDeepEmpty } from 'util/object'

import { AtestadoPlanoModel } from './atestados/AtestadosView'
import { EncaminhamentoExternoModel } from './encaminhamento-externo/model'
import { ArcadaFieldModel } from './evolucoes-odontologicas/components/odontograma-field/arcadas-field/ArcadasField'
import { DenteFieldModel } from './evolucoes-odontologicas/components/odontograma-field/dentes-field/DentesField'
import { EvolucaoDenteSupranumerarioModel } from './evolucoes-odontologicas/components/odontograma-field/dentes-supranumerarios/DentesSupranumerariosField'
import { OdontogramaFieldModel } from './evolucoes-odontologicas/components/odontograma-field/OdontogramaField'
import { OutrosFieldModel } from './evolucoes-odontologicas/components/odontograma-field/outros-field/OutrosField'
import { OdontologiaModel } from './evolucoes-odontologicas/EvolucoesOdontologicasView'
import {
  PeriogramaCompletoCondicao,
  PeriogramaCompletoFace,
  PeriogramaCompletoMultipleBoolean,
  PeriogramaCompletoTableInputValue,
  PeriogramaCompletoTableModel,
  possuiPeriogramaLesaoDeFurca,
} from './evolucoes-odontologicas/periograma/periograma-completo/model'
import { isPreenchendoRPS } from './evolucoes-odontologicas/periograma/periograma-simplificado/PeriogramaSimplificadoField'
import { PeriodontiaModel } from './evolucoes-odontologicas/periograma/PeriogramaView'
import { TecidosMolesFieldModel } from './evolucoes-odontologicas/tecidos-moles/TecidosMolesField'
import { getDentePosicaoProtese, isCoroaDisabled } from './evolucoes-odontologicas/util'
import { ExameListSelectFieldModel } from './exames/components/ExameListSelectField'
import { SolicitacaoExameModel } from './exames/model'
import { PlanoFormModel } from './PlanoForm'
import { PrescricaoMedicamentoPlanoModel } from './prescricao-medicamento/PrescricaoMedicamentoView'

export const convertPlanoFormToInput = (form: PlanoFormModel): PlanoInput =>
  form && {
    texto: form.texto,
    ciaps: form.intervencoesProcedimentos?.ciaps?.map((ciap) => ({ id: ciap.ciap.id, observacao: ciap.observacao })),
    procedimentos: form.intervencoesProcedimentos?.procedimentos?.map((procedimento) => ({
      id: procedimento.procedimento.id,
      observacao: procedimento.observacao,
    })),
    prescricaoMedicamento:
      form?.prescricaoMedicamento && !isEmpty(form.prescricaoMedicamento.medicamentos)
        ? convertPrescricaoPlanoToInput(form.prescricaoMedicamento)
        : null,
    odontologia: form.odontologia && convertOdontologiaPlanoToInput(form.odontologia),
    atestado: form.atestado && convertAtestadoPlanoToInput(form.atestado),
    encaminhamentoExterno:
      form.encaminhamentoExterno &&
      convertEncaminhamentoPlanoToInput(form.encaminhamentoExterno.encaminhamentosRecentes),
    orientacoes: form.orientacao as OrientacaoPlanoInput[],
    solicitacoesExame: form?.solicitacoesExame && convertSolicitacoesExameToInput(form.solicitacoesExame),
  }

const convertPrescricaoPlanoToInput = (
  prescricaoMedicamento: PrescricaoMedicamentoPlanoModel
): PrescricaoMedicamentoPlanoInput => {
  return {
    medicamentos: prescricaoMedicamento.medicamentos.map(
      (medicamento) =>
        ({
          registroManual: !!medicamento.registroManual,
          medicamento: medicamento.principioAtivoCombo?.medicamento?.id,
          medicamentoText: medicamento.principioAtivoText,
          concentracao: medicamento.concentracaoText,
          viaAdministracao: medicamento.viaAdministracao?.id,
          tipoReceita: !!medicamento.registroManual
            ? medicamento.tipoReceita.tipoReceitaEnum
            : medicamento.principioAtivoCombo.principioAtivo.listaMaterial.tipoReceita,
          formaFarmaceutica: !!medicamento.registroManual
            ? medicamento.formaFarmaceutica?.id
            : medicamento.principioAtivoCombo.medicamento.formaFarmaceutica.id,

          dose: medicamento.dose,
          doseUnica: !!medicamento.doseUnica,
          usoContinuo: !!medicamento.usoContinuo,
          tipoFrequencia: !medicamento.doseUnica ? medicamento.tipoFrequencia : null,
          intervaloDose: medicamento.intervaloDose,
          frequenciaDose: medicamento.frequenciaDose,
          // valores para aba de frequencia e turno
          quantidadePeriodoFrequenciaTurno: medicamento.quantidadePeriodoFrequenciaTurno,
          unidadeMedidaTempoFrequenciaTurno: medicamento.unidadeMedidaTempoFrequenciaTurno,

          turno: medicamento.turno,
          posologia: medicamento.posologia,
          dataInicioTratamento: medicamento.dataInicioTratamento,
          dataFimTratamento: medicamento.dataFimTratamento,
          duracao: medicamento.duracao,
          escalaDuracao: medicamento.escalaDuracao,
          quantidade: medicamento.quantidade,
          recomendacoes: medicamento.recomendacoes,
          unidadeFornecimento: !!medicamento.registroManual
            ? medicamento.unidadeFornecimento
            : medicamento.principioAtivoCombo.unidadeFornecimento.nome,
        } as MedicamentoPlanoInput)
    ),
  } as PrescricaoMedicamentoPlanoInput
}

const convertOdontologiaPlanoToInput = (odontologia: OdontologiaModel): OdontologiaInput => ({
  ...convertOdontogramaPlanoToInput(odontologia.odontograma),
  tecidosMoles: odontologia?.tecidosMoles && convertTecidosMolesToInput(odontologia.tecidosMoles),
  periodontia: odontologia?.periodontia && convertPeriodontiaToInput(odontologia.periodontia),
})

const convertTecidosMolesToInput = (tecidosMoles: TecidosMolesFieldModel[]): TecidoMoleInput[] =>
  tecidosMoles.map(
    (tecidoMole) =>
      ({
        parteBucal: tecidoMole.local.value,
        cid10: tecidoMole.problema?.id,
        procedimentos: tecidoMole.procedimentos?.map(({ id }) => id),
        observacao: tecidoMole.observacao,
        caracteristicasLesao:
          tecidoMole.caracteristicasLesao &&
          [
            tecidoMole.caracteristicasLesao.base,
            tecidoMole.caracteristicasLesao.consistencia,
            tecidoMole.caracteristicasLesao.cor,
            tecidoMole.caracteristicasLesao.lesaoFundamental?.lesaoEnvolvida,
            tecidoMole.caracteristicasLesao.quantidade,
            tecidoMole.caracteristicasLesao.sintomatologia?.value !== 'outros' &&
              tecidoMole.caracteristicasLesao.sintomatologia?.value,
            tecidoMole.caracteristicasLesao.superficie,
            tecidoMole.caracteristicasLesao.tamanho,
          ].filter((item) => item),
        descricaoSintomatologia: tecidoMole.caracteristicasLesao?.sintomatologia?.descricaoOutro,
      } as TecidoMoleInput)
  )

const convertPeriodontiaToInput = (periodontia: PeriodontiaModel): PeriodontiaInput => {
  const {
    avaliacaoSextante1,
    avaliacaoSextante2,
    avaliacaoSextante3,
    avaliacaoSextante4,
    avaliacaoSextante5,
    avaliacaoSextante6,
  } = periodontia.periogramaSimplificado || {}
  const isPreenchendo = isPreenchendoRPS([
    avaliacaoSextante1,
    avaliacaoSextante2,
    avaliacaoSextante3,
    avaliacaoSextante4,
    avaliacaoSextante5,
    avaliacaoSextante6,
  ])

  return {
    evolucoes: periodontia?.evolucoes?.map((evolucao) => ({
      parteBucal: evolucao.sextante,
      procedimentos: evolucao.procedimentos?.map(({ id }) => id),
      observacao: evolucao.observacao,
    })),
    periogramaSimplificado: isPreenchendo ? periodontia.periogramaSimplificado : null,
    periogramaCompleto: !isObjectDeepEmpty(periodontia?.periogramaCompleto?.dados)
      ? convertPeriogramaCompletoToInput(periodontia.periogramaCompleto?.dados)
      : null,
    observacaoPeriogramaCompleto: periodontia?.periogramaCompleto?.observacao,
  }
}

const convertPeriogramaCompletoToInput = (periogramaCompleto: PeriogramaCompletoTableModel) =>
  Object.entries(periogramaCompleto).map(([dente, denteValues]) => {
    const values = denteValues?.values
    const faceVestibular = PeriogramaCompletoFace.VESTIBULAR
    const faceInterna = values && Object.keys(values).find((face) => face !== faceVestibular)
    const excluido = !!denteValues.ausente

    return {
      dente: dente,
      ...(!excluido && {
        profundidadeVestibular: values[faceVestibular][PeriogramaCompletoCondicao.PROF_SONDAGEM],
        profundidadeInterna: values[faceInterna][PeriogramaCompletoCondicao.PROF_SONDAGEM],
        recessaoVestibular: values[faceVestibular][PeriogramaCompletoCondicao.RECESSAO],
        recessaoInterna: values[faceInterna][PeriogramaCompletoCondicao.RECESSAO],
        mobilidade: values[faceInterna][PeriogramaCompletoCondicao.MOBILIDADE],
        ...extractPeriogramaCompletoPlacas([
          values[faceVestibular][PeriogramaCompletoCondicao.PLACA],
          values[faceInterna][PeriogramaCompletoCondicao.PLACA],
        ]),
        ...extractPeriogramaCompletoSangramento([
          values[faceVestibular][PeriogramaCompletoCondicao.SANG_SONDAGEM],
          values[faceInterna][PeriogramaCompletoCondicao.SANG_SONDAGEM],
        ]),
        lesaoDeFurca: extractPeriogramaCompletoLesaoDeFurca(
          dente as ParteBucalEnum,
          values[faceInterna][PeriogramaCompletoCondicao.LESAO_FURCA]
        ),
      }),
      excluido,
    } as PeriogramaCompletoInput
  })

const extractPeriogramaCompletoPlacas = ([valueVestibular, valueInterna]: [
  PeriogramaCompletoTableInputValue,
  PeriogramaCompletoTableInputValue
]) => {
  const typedValues = [valueVestibular, valueInterna] as [
    PeriogramaCompletoMultipleBoolean,
    PeriogramaCompletoMultipleBoolean
  ]

  return {
    placaDVestibular: typedValues?.[0]?.d || false,
    placaVVestibular: typedValues?.[0]?.v || false,
    placaMVestibular: typedValues?.[0]?.m || false,
    placaDInterna: typedValues?.[1]?.d || false,
    placaVInterna: typedValues?.[1]?.v || false,
    placaMInterna: typedValues?.[1]?.m || false,
  }
}

const extractPeriogramaCompletoSangramento = ([valueVestibular, valueInterna]: [
  PeriogramaCompletoTableInputValue,
  PeriogramaCompletoTableInputValue
]) => {
  const typedValues = [valueVestibular, valueInterna] as [
    PeriogramaCompletoMultipleBoolean,
    PeriogramaCompletoMultipleBoolean
  ]

  return {
    sangramentoDVestibular: typedValues?.[0]?.d || false,
    sangramentoVVestibular: typedValues?.[0]?.v || false,
    sangramentoMVestibular: typedValues?.[0]?.m || false,
    sangramentoDInterna: typedValues?.[1]?.d || false,
    sangramentoVInterna: typedValues?.[1]?.v || false,
    sangramentoMInterna: typedValues?.[1]?.m || false,
  }
}

const extractPeriogramaCompletoLesaoDeFurca = (dente: ParteBucalEnum, value: PeriogramaCompletoTableInputValue) =>
  possuiPeriogramaLesaoDeFurca(dente) ? value : '-'

const convertOdontogramaPlanoToInput = (odontograma: OdontogramaFieldModel): OdontologiaInput =>
  odontograma &&
  ({
    evolucoesDente:
      odontograma.dentes &&
      convertOdontogramaDentesToInput(
        odontograma.dentes,
        odontograma.proteseTotalSuperior,
        odontograma.proteseTotalInferior
      ),
    evolucoesProcedimentoOdonto: concat(
      odontograma.arcadas && convertOdontogramaArcadasToInput(odontograma.arcadas),
      odontograma.outros && convertOdontogramaOutrosToInput(odontograma.outros),
      odontograma.dentesSupranumerarios &&
        convertOdontogramaDentesSupranumerariosToInput(odontograma.dentesSupranumerarios)
    ).filter((item) => !!item),
    aparelho: odontograma.possuiAparelho,
    contencao: odontograma.possuiContencao,
    proteseTotalInferior: odontograma.proteseTotalInferior,
    proteseTotalSuperior: odontograma.proteseTotalSuperior,
  } as OdontologiaInput)

const convertOdontogramaArcadasToInput = (
  arcadas: Record<ParteBucalEnum, ArcadaFieldModel>
): EvolucaoProcedimentoOdontoInput[] =>
  Object.entries(arcadas).map(
    ([key, arcada]) =>
      arcada.procedimentos?.length > 0 &&
      ({
        parteBucal: key,
        procedimentos: arcada.procedimentos.map(({ id }) => id),
        observacao: arcada.observacoes && arcada.observacoes[0],
      } as EvolucaoProcedimentoOdontoInput)
  )

const convertOdontogramaDentesToInput = (
  dentes: Record<ParteBucalEnum, DenteFieldModel>,
  proteseTotalSuperior?: boolean,
  proteseTotalInferior?: boolean
): EvolucaoDenteInput[] =>
  compact(
    Object.entries(dentes).map(([key, dente]: [ParteBucalEnum, DenteFieldModel]) => {
      const coroaDisabled =
        isCoroaDisabled(dente) ||
        (getDentePosicaoProtese(key) === 'superior' ? proteseTotalSuperior : proteseTotalInferior)

      return (
        !isObjectDeepEmpty(dente) &&
        ({
          parteBucal: key,
          situacoesRaiz:
            dente.condicoesDente &&
            dente.condicoesDente.filter((condicao) =>
              Object.values(SituacaoRaizEnum).includes(condicao as SituacaoRaizEnum)
            ),
          situacoesFace:
            dente.condicoesDente &&
            dente.condicoesDente.filter((condicao) =>
              Object.values(SituacaoFaceEnum).includes(condicao as SituacaoFaceEnum)
            ),
          procedimentos: dente.procedimentos?.map(({ id }) => id),
          observacao: dente.observacoes && dente.observacoes[0],
          situacaoCoroaCima:
            !coroaDisabled && dente.coroa?.faceTop?.activeColor ? dente.coroa.faceTop.activeColor.value : undefined,
          situacaoCoroaEsquerda:
            !coroaDisabled && dente.coroa?.faceLeft?.activeColor ? dente.coroa.faceLeft.activeColor.value : undefined,
          situacaoCoroaDireita:
            !coroaDisabled && dente.coroa?.faceRight?.activeColor ? dente.coroa.faceRight.activeColor.value : undefined,
          situacaoCoroaBaixo:
            !coroaDisabled && dente.coroa?.faceBottom?.activeColor
              ? dente.coroa.faceBottom.activeColor.value
              : undefined,
          situacaoCoroaCentro:
            !coroaDisabled && dente.coroa?.faceCenter?.activeColor
              ? dente.coroa.faceCenter.activeColor.value
              : undefined,
        } as EvolucaoDenteInput)
      )
    })
  )

const convertOdontogramaOutrosToInput = (outros: OutrosFieldModel[]): EvolucaoProcedimentoOdontoInput[] =>
  outros.map(
    (outro) =>
      outro.procedimentos?.length > 0 &&
      ({
        parteBucal: ParteBucalEnum.OUTROS,
        descricaoLocal: outro.local,
        procedimentos: outro.procedimentos.map(({ id }) => id),
        observacao: outro.observacao,
      } as EvolucaoProcedimentoOdontoInput)
  )

const convertOdontogramaDentesSupranumerariosToInput = (
  dentesSupranumerarios: EvolucaoDenteSupranumerarioModel[]
): EvolucaoProcedimentoOdontoInput[] =>
  dentesSupranumerarios.map(
    (denteSupranumerario) =>
      denteSupranumerario.procedimentos?.length > 0 &&
      ({
        parteBucal: ParteBucalEnum.DENTE_SUPRANUMERARIO,
        descricaoLocal: denteSupranumerario.local,
        procedimentos: denteSupranumerario.procedimentos.map(({ id }) => id),
        observacao: denteSupranumerario.observacao,
      } as EvolucaoProcedimentoOdontoInput)
  )

const convertAtestadoPlanoToInput = (atestados: AtestadoPlanoModel): AtestadoPlanoInput => {
  const emBranco = atestados.atestadosRecentes?.filter((it) => it.tipo === TipoAtestadoEnum.EM_BRANCO)
  const padrao = atestados.atestadosRecentes?.filter((it) => it.tipo === TipoAtestadoEnum.PADRAO)
  const licencaMaternidade = atestados.atestadosRecentes?.filter(
    (it) => it.tipo === TipoAtestadoEnum.LICENCA_MATERNIDADE
  )
  return {
    emBranco: emBranco?.map((it) => ({ descricao: it.descricao } as AtestadoEmBrancoPlanoInput)),
    licencaMaternidade: licencaMaternidade?.map(
      (it) =>
        ({
          descricao: it.descricao,
          cpfCns: it.cpfCns,
          naoPossui: it.naoPossui,
        } as LicencaMaternidadePlanoInput)
    ),
    padrao: padrao?.map(
      (it) =>
        ({
          descricao: it.descricao,
          cpfCns: it.cpfCns,
          naoPossui: it.naoPossui,
          date: it.data,
          hora: it.hora,
          cid10: it.cid10?.id,
          dias: it.dias,
        } as AtestadoPadraoPlanoInput)
    ),
  } as AtestadoPlanoInput
}

const convertEncaminhamentoPlanoToInput = (
  encaminhamentos: EncaminhamentoExternoModel[]
): EncaminhamentoExternoInput[] =>
  encaminhamentos.map((encaminhamento) => ({
    observacoes: encaminhamento.observacoes,
    especialidadeSisreg: encaminhamento.especialidadeSisreg.id,
    motivoEncaminhamento: encaminhamento.motivoEncaminhamento,
    hipoteseDiagnosticoCid10:
      encaminhamento.cidEncaminhamento?.id || encaminhamento.ciapEncaminhamento.cid10Encaminhamento.id,
    hipoteseDiagnosticoCiap: encaminhamento.ciapEncaminhamento?.id ?? undefined,
    complemento: encaminhamento.complemento,
    classificacaoRiscoEncaminhamento: encaminhamento.classificacaoRiscoEncaminhamento,
  })) as EncaminhamentoExternoInput[]

const convertSolicitacoesExameToInput = (solicitacoesExame: SolicitacaoExameModel[]): SolicitacaoExameInput[] => {
  return solicitacoesExame.map(
    (solicitacao: SolicitacaoExameModel): SolicitacaoExameInput => {
      return {
        justificativa: solicitacao.justificativa,
        tipoExame: solicitacao.tipoExame,
        observacoes: solicitacao.observacao,
        examesRequisitados: solicitacao.examesSolicitados.map(
          (exameRequisitado: ExameListSelectFieldModel): ExameRequisitadoInput => {
            return {
              exameId: exameRequisitado.exame.id,
              observacao: exameRequisitado.observacao,
            }
          }
        ),
        cid10: solicitacao.cid10?.id,
      }
    }
  )
}
