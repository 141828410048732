/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Alert,
  Button,
  Cell,
  Grid,
  Heading,
  HFlow,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  Tag,
  Text,
  VFlow,
} from 'bold-ui'
import useSession from 'components/auth/useSession'
import { TitleGroup } from 'components/TitleGroup'
import theme from 'config/theme'
import { ReceitaMedicamento, TipoReceitaEnum, UnidadeMedidaTempoEnum } from 'graphql/types.generated'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { tipoReceitaRecord } from 'types/enums'
import { grupoCboPrescricao } from 'view/atendimentos/detail/soap/plano/acessos'
import { periodoTratamento } from 'view/atendimentos/detail/soap/plano/prescricao-medicamento/components/PeriodoTratamentoSelectField'

import { MedicamentoInterromperFormModel } from './InterromperTratamentoModal'
import MedicamentosAccordion from './MedicamentosAccordion'
import MedicamentosAccordionViewOnly from './MedicamentosAccordionViewOnly'
import MedicamentosConcluidosTable from './MedicamentosConcluidosTable'

interface MedicamentosAtivosConcluidosModalProps {
  prontuarioId: ID
  dataAtendimento: Instant
  isAtendimentoProcedimentos: boolean
  editable: boolean
}

interface AlertInlineProps {
  visible: boolean
  message: string
}

function MedicamentosAtivosConcluidosModal(props: MedicamentosAtivosConcluidosModalProps) {
  const { prontuarioId, dataAtendimento, isAtendimentoProcedimentos, editable } = props
  const [alertState, setAlertState] = useState<AlertInlineProps>({ visible: false, message: '' })
  const history = useHistory()
  const match = useRouteMatch()
  const { hasCboAuth } = useSession({ fetchPolicy: 'cache-only' })

  const podeInterromperMedicamento = !isAtendimentoProcedimentos && hasCboAuth(grupoCboPrescricao.adicionar)

  const onClose = useCallback(() => {
    return history.push(match.url.replace('/medicamentos-ativos-concluidos', ''))
  }, [history, match.url])

  useEffect(() => {
    if (alertState.visible) {
      setTimeout(() => {
        setAlertState({ visible: false, message: '' })
      }, 5000)
    }
  }, [alertState.visible])

  const renderItemHeader = useCallback(
    (interrompidosAgora?: MedicamentoInterromperFormModel[]) => (receita: ReceitaMedicamento) => {
      return (
        <HFlow
          justifyContent='space-between'
          style={css`
            width: 100%;
          `}
        >
          <VFlow vSpacing={0.2}>
            <div
              css={css`
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
              `}
            >
              <Heading level={5}>
                {`${receita.medicamento.principioAtivo} ${receita.medicamento.concentracao ?? ''}`.trim()}
              </Heading>
              {interrompidosAgora?.find((i) => i.id === receita.id) && (
                <div css={styles.interrompidoAgoraStyles}>
                  <Icon
                    icon='clockOutline'
                    size={1}
                    style={css`
                      margin-right: 0.5rem;
                    `}
                    data-testid='MedicamentoAtivosConcluidos.InterrompidoAgora'
                  />
                  Interrompido agora
                </div>
              )}
            </div>
            <div>
              {receita.usoContinuo && (
                <Tag
                  type='normal'
                  style={css`
                    margin-right: 0.5rem;
                  `}
                >
                  Uso contínuo
                </Tag>
              )}
              {receita.tipoReceita !== TipoReceitaEnum.COMUM && (
                <Tag
                  type='info'
                  style={css`
                    margin-right: 0.5rem;
                  `}
                >
                  {tipoReceitaRecord[receita.tipoReceita]}
                </Tag>
              )}
              {receita.tratamentoInterrompido && (
                <Tag type='normal' data-testid='MedicamentoAtivosConcluidos.TratamentoInterrompido'>
                  Tratamento interrompido
                </Tag>
              )}
            </div>
            <Text>{receita.posologia}</Text>
          </VFlow>
          <HFlow
            hSpacing={1}
            style={css`
              width: 100%;
              margin-right: 1rem;
            `}
            justifyContent='space-between'
          >
            <HFlow hSpacing={0.5} alignItems='center'>
              <Icon icon='calendarFilled' fill='primary' size={1} />
              <VFlow vSpacing={0.2}>
                <Text>
                  De
                  <Text fontWeight='bold'> {moment(receita.dtInicioTratamento).format('DD/MM/YYYY')}</Text>
                </Text>
                <Text>
                  até
                  <Text fontWeight='bold'>
                    {receita.atendProf?.versaoAnterior
                      ? ` ${periodoTratamento[receita.medidaTempoTratamento]}`
                      : receita.medidaTempoTratamento !== UnidadeMedidaTempoEnum.INDETERMINADO &&
                        receita.dtFimTratamento
                      ? ` ${moment(receita.dtFimTratamento).format('DD/MM/YYYY')}`
                      : ` ${periodoTratamento[receita.medidaTempoTratamento]}`}
                  </Text>
                </Text>
              </VFlow>
            </HFlow>
          </HFlow>
        </HFlow>
      )
    },
    []
  )

  const renderItemBody = useCallback((receita: ReceitaMedicamento) => {
    return (
      <VFlow
        style={css`
          padding: 1rem;
        `}
      >
        {receita.tratamentoInterrompido && (
          <VFlow vSpacing={0}>
            <Text fontWeight='bold'>Tratamento interrompido</Text>
            {receita.dtInterrupcao && (
              <Text data-testid='MedicamentoAtivosConcluidos.dtInterrupcao'>{`${moment(receita.dtInterrupcao).format(
                'DD/MM/YYYY'
              )}`}</Text>
            )}
            {receita.justificativaInterrupcao && (
              <Text data-testid='MedicamentoAtivosConcluidos.JustificativaInterrompido'>
                {receita.justificativaInterrupcao}
              </Text>
            )}
          </VFlow>
        )}
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'>Recomendações</Text>
          {receita.recomendacoes ? (
            <Text
              style={css`
                white-space: pre-wrap;
                word-break: break-all;
              `}
            >
              {receita.recomendacoes}
            </Text>
          ) : (
            <Text fontStyle='italic'>Não há recomendações para o medicamento</Text>
          )}
        </VFlow>
      </VFlow>
    )
  }, [])

  return (
    <Modal open={true} onClose={onClose}>
      <ModalBody>
        <TitleGroup
          title='Medicamentos em uso/tratamento concluído'
          style={css`
            margin-bottom: 1rem;
          `}
        />
        <Grid alignItems='flex-start' gap={0}>
          <Cell size={12}>
            {podeInterromperMedicamento && editable && (
              <Alert type='info' inline>
                Ao interromper o tratamento, a medicação será apresentada com tratamento concluído no próximo
                atendimento.
              </Alert>
            )}
            {alertState.visible && (
              <div
                css={css`
                  margin-top: 0.5rem;
                `}
              >
                <Alert type='success' inline onCloseClick={() => setAlertState({ visible: false, message: '' })}>
                  {alertState.message}
                </Alert>
              </div>
            )}
          </Cell>
          <Cell size={12}>
            <Heading level={2}>Medicamentos em uso</Heading>
            {editable ? (
              <MedicamentosAccordion
                podeInterromperMedicamento={podeInterromperMedicamento}
                setAlertState={setAlertState}
                renderItemBody={renderItemBody}
                renderItemHeader={renderItemHeader}
                prontuarioId={prontuarioId}
                dataAtendimento={dataAtendimento}
              />
            ) : (
              <MedicamentosAccordionViewOnly
                renderItemBody={renderItemBody}
                renderItemHeader={renderItemHeader}
                prontuarioId={prontuarioId}
              />
            )}
          </Cell>
          <Cell size={12}>
            <Heading
              level={2}
              style={css`
                margin-bottom: 0.5rem;
              `}
            >
              Medicamentos com tratamento concluído
            </Heading>
            <MedicamentosConcluidosTable
              prontuarioId={prontuarioId}
              dataAtendimento={dataAtendimento}
              accordionItemHeader={renderItemHeader()}
              accordionItemBody={renderItemBody}
            />
          </Cell>
        </Grid>
      </ModalBody>
      <ModalFooter style={styles.footer}>
        <HFlow justifyContent='flex-end'>
          <Button onClick={onClose} style={styles.footerButton}>
            Fechar
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}

export default React.memo(MedicamentosAtivosConcluidosModal)

const styles = {
  footer: css`
    border-top: 1px solid + ${theme.pallete.gray.c80};
  `,
  footerButton: css`
    width: 9rem;
    height: 3rem;
  `,
  interrompidoAgoraStyles: css`
    color: ${theme.pallete.primary.main};
    display: inline-flex;
    margin-left: 0.5rem;
    align-items: center;
    font-weight: normal;
  `,
}
