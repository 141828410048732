import { Text } from 'bold-ui'
import { useEvolucoesProblemaModalLazyQuery } from 'graphql/hooks.generated'
import React, { useEffect, useState } from 'react'
import { EvolucaoProblema, Problema } from 'view/atendimentos/detail/soap/aside/types/ProblemaModel'

import { MainDetailAccordion } from '../../main-detail-accordion/MainDetailAccordion'
import { BodyProblemaModal } from './BodyProblemaModal'
import { HeaderProblemaModal } from './HeaderProblemaModal'

interface AccordionProblemasModalProps {
  problemas: Problema[]
  filtro?: string
  noResultMessage?: string
}

export function AccordionProblemasModal(props: AccordionProblemasModalProps) {
  const { problemas, filtro, noResultMessage } = props

  const [problemaLoadingEvolucoesId, setProblemaLoadingEvolucoesId] = useState<string>()
  const [problemasComEvolucoes, setProblemasComEvolucoes] = useState<Problema[]>([])

  const [executeQuery, { data, loading }] = useEvolucoesProblemaModalLazyQuery({
    fetchPolicy: 'cache-and-network',
  })

  const problemaQueryResult = !isNaN(+problemaLoadingEvolucoesId) ? data?.problema : null

  const handleProblemaClick = (problemaUUID: string[]) => {
    const problemaId = problemaUUID.join()
    if (!!problemaId) {
      setProblemaLoadingEvolucoesId(problemaId)
      !isNaN(+problemaId) && executeQuery({ variables: { problemaId } })
    }
  }

  useEffect(() => {
    if (problemaQueryResult) {
      const problemaToLoadEvolucoes = problemas.find((problema) => problema.id === problemaLoadingEvolucoesId)
      const indexOfProlema = problemas.indexOf(problemaToLoadEvolucoes)

      const problemaEvolucoesLoading: Problema = {
        ...problemaToLoadEvolucoes,
        evolucoes: problemaQueryResult?.evolucoes,
      }

      const newValues = [...problemas]
      newValues[indexOfProlema] = problemaEvolucoesLoading

      setProblemasComEvolucoes(newValues)
    } else {
      setProblemasComEvolucoes(problemas)
    }
  }, [problemaLoadingEvolucoesId, problemaQueryResult, problemas])

  return (
    <>
      {problemasComEvolucoes.length > 0 ? (
        <MainDetailAccordion<EvolucaoProblema, Problema>
          loading={loading}
          items={problemasComEvolucoes}
          renderHeader={(renderProps) => <HeaderProblemaModal highlight={filtro} {...renderProps} />}
          renderDetailBody={BodyProblemaModal}
          onChange={handleProblemaClick}
        />
      ) : (
        <Text component='p'>{noResultMessage}</Text>
      )}
    </>
  )
}
