/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { SubmitButton, TextAreaField, TextField } from 'components/form'
import { FormApi } from 'final-form'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Form, FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'

import { DataProblemaField } from '../avaliacao/components/problemas-condicoes/DataProblemaField'
import { CirurgiaInternacaoModel } from './model'
import { hospitalarAntecedentesValidator } from './validator'

interface HospitalarAntecedentesPopper {
  close(): void
  onSubmit?(value: CirurgiaInternacaoModel, formApi: FormApi): void
  onChange?(value: CirurgiaInternacaoModel): void
  editingItem?: CirurgiaInternacaoModel
  dataNascimentoCidadao: LocalDate
}

const name = metaPath<CirurgiaInternacaoModel>()

export const HospitalarAntecedentesPopper = (props: HospitalarAntecedentesPopper) => {
  const { close, onSubmit, onChange, editingItem, dataNascimentoCidadao } = props
  const classes = createStyles()
  const { iniciadoEm } = useAtendimentoContext()

  const validator = hospitalarAntecedentesValidator(dataNascimentoCidadao, iniciadoEm)

  const handleSubmit = (item: CirurgiaInternacaoModel, formApi: FormApi) => {
    editingItem ? onChange({ ...item, _id: editingItem._id }) : onSubmit(item, formApi)
    close()
  }

  const renderForm = (formProps: FormRenderProps<CirurgiaInternacaoModel>) => {
    return (
      <Box style={classes.popperBox}>
        <Grid>
          <Cell size={12}>
            <TextField name={name.descricao} label='Cirurgia e/ou internação' required maxLength={200} />
          </Cell>
          <Cell size={12}>
            <DataProblemaField
              name={name.dataRealizada}
              label='Realizada em'
              dataNascimentoCidadao={dataNascimentoCidadao}
            />
          </Cell>
          <Cell size={12}>
            <TextAreaField
              name={name.observacao}
              label='Observações'
              maxLength={200}
              style={classes.observacoesField}
              placeholder='Insira as demais informações relacionadas à cirurgia e/ou internação.'
            />
          </Cell>
          <Cell size={12} alignSelf='flex-end'>
            <HFlow justifyContent='flex-end'>
              <Button size='small' onClick={close}>
                Cancelar
              </Button>
              <SubmitButton size='small' handleSubmit={formProps.handleSubmit}>
                {editingItem ? 'Salvar' : 'Adicionar'}
              </SubmitButton>
            </HFlow>
          </Cell>
        </Grid>
      </Box>
    )
  }

  return (
    <Form<CirurgiaInternacaoModel>
      render={renderForm}
      onSubmit={handleSubmit}
      initialValues={editingItem}
      validate={validator}
    />
  )
}

const createStyles = () => ({
  observacoesField: css`
    resize: none;
    height: 4rem;
  `,
  popperBox: css`
    width: 30.6rem;
  `,
})
