import { HFlow, merge, Text, TimeField } from 'bold-ui'
import { ConfiguracaoPeriodoInput } from 'graphql/types.generated'
import get from 'lodash/get'
import React, { useCallback } from 'react'
import { useField } from 'react-final-form'
import { metaPath } from 'util/metaPath'

interface DoubleTimeFieldProps {
  name: string
}

const pathPeriodo = metaPath<ConfiguracaoPeriodoInput>()

export function DoubleTimeField(props: DoubleTimeFieldProps) {
  const {
    input: { value, onChange, onBlur },
    meta,
  } = useField(props.name)

  const handleInicioChange = useCallback((v) => onChange({ ...value, horarioInicial: v }), [value, onChange])
  const handleFinalChange = useCallback((v) => onChange({ ...value, horarioFinal: v }), [value, onChange])
  const handleInicioBlur = useCallback((v) => onBlur({ ...value, horarioInicial: v }), [value, onBlur])
  const handleFinalBlur = useCallback((v) => onBlur({ ...value, horarioFinal: v }), [value, onBlur])
  const handleClearInicio = useCallback(() => onChange({ ...value, horarioInicial: null }), [value, onChange])
  const handleClearFinal = useCallback(() => onChange({ ...value, horarioFinal: null }), [value, onChange])

  const errors = merge({}, meta.error, meta.submitError)
  const erroHorarioInicio = get(errors, pathPeriodo.horarioInicial.alias)
  const erroHorarioFinal = get(errors, pathPeriodo.horarioFinal.alias)

  return (
    <>
      <HFlow>
        <TimeField
          name={`${props.name}.horarioInicial`}
          style={{ width: 80 }}
          value={value.horarioInicial}
          onChange={handleInicioChange}
          onBlur={handleInicioBlur}
          onClear={handleClearInicio}
          invalid={erroHorarioInicio}
        />
        <TimeField
          name={`${props.name}.horarioFinal`}
          style={{ width: 80 }}
          value={value.horarioFinal}
          onChange={handleFinalChange}
          onBlur={handleFinalBlur}
          onClear={handleClearFinal}
          invalid={erroHorarioFinal}
        />
      </HFlow>
      <span>
        <Text color='danger'>{erroHorarioInicio}</Text>
        {!erroHorarioInicio && <Text color='danger'>{erroHorarioFinal}</Text>}
      </span>
    </>
  )
}
