import { css } from '@emotion/core'
import { FormControl, HFlow, VFlow } from 'bold-ui'
import { CheckboxField } from 'components/form/final-form/CheckboxField'
import { ErrorField } from 'components/form/final-form/ErrorField'
import { CondutaEnum } from 'graphql/types.generated'
import React from 'react'
import { MetaPath } from 'util/metaPath'

export interface CondutaPanelProps {
  hasNotificacaoCasoSuspeito: boolean
  hasEncaminhamentoExterno: boolean
  name: MetaPath<CondutaEnum[]>
}

export function CondutaPanel(props: CondutaPanelProps) {
  const { name, hasNotificacaoCasoSuspeito, hasEncaminhamentoExterno } = props

  return (
    <FormControl label='Conduta' required={!hasNotificacaoCasoSuspeito && !hasEncaminhamentoExterno}>
      <VFlow vSpacing={0.5}>
        <ErrorField name={name} />

        <HFlow
          hSpacing={4}
          style={css`
            margin-left: -0.25rem;
          `}
        >
          <VFlow vSpacing={0.5}>
            <CheckboxField
              name={name}
              label='Retorno para consulta agendada'
              value={CondutaEnum.RETORNO_PARA_CONSULTA_AGENDADA}
            />
            <CheckboxField name={name} label='Agendamento para NASF' value={CondutaEnum.AGENDAMENTO_PARA_NASF} />
            <CheckboxField name={name} label='Agendamento para grupos' value={CondutaEnum.AGENDAMENTO_PARA_GRUPOS} />
          </VFlow>
          <VFlow vSpacing={0.5}>
            <CheckboxField
              name={name}
              label='Retorno para consulta programada / cuidado continuado'
              value={CondutaEnum.RETORNO_PARA_CUIDADO_CONTINUADO_PROGRAMADO}
            />
            <CheckboxField name={name} label='Alta do episódio' value={CondutaEnum.ALTA_DO_EPISODIO} />
          </VFlow>
        </HFlow>
      </VFlow>
    </FormControl>
  )
}
