import { MedicamentoBarraLateralModel } from '../../types/MedicamentoBarraLateralModel'
import { LIMITE_TAMANHO_EXPANDIDA } from '../../util/quebraListaBarraLateral'

export function mergeMedicamentoAtivoConcluido(
  medicamentosAtivo: MedicamentoBarraLateralModel[],
  medicamentosConcluidos: MedicamentoBarraLateralModel[]
) {
  const deepCopyMedicamentosAtivos: MedicamentoBarraLateralModel[] = [...medicamentosAtivo]
  const medicamentosAtivosExpandido: MedicamentoBarraLateralModel[] = []
  const medicamentosAtivosAccordion: MedicamentoBarraLateralModel[] = []

  const deepCopyMedicamentosConcluidos: MedicamentoBarraLateralModel[] = [...medicamentosConcluidos]
  const medicamentosConcluidosExpandido: MedicamentoBarraLateralModel[] = []
  const medicamentosConcluidosAccordion: MedicamentoBarraLateralModel[] = []

  const qtdMedicamentosAtivosOnExpandido =
    deepCopyMedicamentosAtivos.length + deepCopyMedicamentosConcluidos.length <= LIMITE_TAMANHO_EXPANDIDA
      ? LIMITE_TAMANHO_EXPANDIDA
      : LIMITE_TAMANHO_EXPANDIDA - 1

  qtdMedicamentosAtivosOnExpandido > 0 &&
    medicamentosAtivosExpandido.push(...deepCopyMedicamentosAtivos.splice(0, qtdMedicamentosAtivosOnExpandido))
  medicamentosAtivosAccordion.push(...deepCopyMedicamentosAtivos)

  const qtdMedicamentosConcluidosOnExpandido = qtdMedicamentosAtivosOnExpandido - medicamentosAtivosExpandido.length

  qtdMedicamentosConcluidosOnExpandido > 0 &&
    medicamentosConcluidosExpandido.push(
      ...deepCopyMedicamentosConcluidos.splice(0, qtdMedicamentosConcluidosOnExpandido)
    )
  medicamentosConcluidosAccordion.push(...deepCopyMedicamentosConcluidos)

  return {
    medicamentosAtivosExpandido,
    medicamentosAtivosAccordion,
    medicamentosConcluidosExpandido,
    medicamentosConcluidosAccordion,
  }
}
