import { MutableRefObject, useLayoutEffect, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export function useIsOverflowing(ref: MutableRefObject<HTMLElement>) {
  const [isOverflowing, setIsOverflowing] = useState(false)

  useLayoutEffect(() => {
    const { current } = ref

    const trigger = () => {
      const hasOverflow = current.scrollWidth > current.clientWidth
      setIsOverflowing(hasOverflow)
    }

    const observer = new ResizeObserver(trigger)
    if (current) {
      observer.observe(current)
      trigger()
    }

    return () => observer.disconnect()
  })

  return isOverflowing
}
