import { DateRange } from 'bold-ui'
import {
  AgendamentoFragment,
  JustificativaCancelamentoAgendado,
  LotacaoAgendamentoCidadaoFragment,
  SituacaoAgendadoEnum,
  UnidadeSaude,
} from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'

export interface AgendamentoCidadaoModel extends AgendamentoFragment {
  lotacaoAgendada: LotacaoAgendadaModel
  lotacaoCriadora?: LotacaoAgendamentoCidadaoFragment
  justificativaCancelamento?: JustificativaCancelamentoAgendado
}

export interface LotacaoAgendadaModel extends LotacaoAgendamentoCidadaoFragment {
  unidadeSaude: Pick<UnidadeSaude, 'id' | 'nome'>
}

export interface AgendamentosCidadaoFilterPopperModel {
  periodo: DateRange
}

export interface AgendamentosCidadaoPartialFilterModel {
  nomeProfissional?: string
  isSomenteMeus?: boolean
  isAgendamentosAnteriores?: boolean
}

export type AgendamentosCidadaoFilterModel = AgendamentosCidadaoPartialFilterModel &
  AgendamentosCidadaoFilterPopperModel

export const situacoesExibirAgendamentosCidadao: SituacaoAgendadoEnum[] = [
  SituacaoAgendadoEnum.AGENDADO,
  SituacaoAgendadoEnum.ATENDIMENTO_REALIZADO,
  SituacaoAgendadoEnum.CANCELADO,
  SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE,
  SituacaoAgendadoEnum.NAO_AGUARDOU,
  SituacaoAgendadoEnum.NAO_COMPARECEU,
]

export interface AgendamentoCidadaoPermissions {
  podeCancelarTodasEquipes: boolean
  podeCancelarAgendamento: boolean
}

export const metaAgendamentoCidadaoFilter = metaPath<AgendamentosCidadaoFilterModel>()
