import {
  Alert,
  Button,
  Cell,
  Grid,
  HFlow,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  VFlow,
} from 'bold-ui'
import { TableBox } from 'components/table/TableBox'
import { useUnificacoesBaseQuery } from 'graphql/hooks.generated'
import { StatusUnificacaoBaseEnum } from 'graphql/types.generated'
import React, { useState } from 'react'
import { formatDate } from 'util/date/formatDate'

import { AvaliarSolicitacao, ModalAvaliarSolicitacao } from './ModalAvaliarSolicitacao'
import { StatusUnificacaoBaseTag } from './StatusUnificacaoBaseTag'

export interface RecebimentoDadosViewProps {
  agendaOnlineAtiva: boolean
}

export const RecebimentoDadosView = (props: RecebimentoDadosViewProps) => {
  const { agendaOnlineAtiva } = props
  const { data, refetch } = useUnificacoesBaseQuery()
  const [unificacaoBaseId, setUnificacaoBaseId] = useState<string>()
  const [avaliarSolicitacao, setAvaliarSolicitacao] = useState<AvaliarSolicitacao>()
  const [isModalAvaliarOpen, setIsModalAvaliarOpen] = useState<boolean>()
  const handleMostrarModalNegar = (id: string) => () => {
    setIsModalAvaliarOpen(true)
    setUnificacaoBaseId(id)
    setAvaliarSolicitacao('negar')
  }
  const handleMostrarModalAceitar = (id: string) => () => {
    setIsModalAvaliarOpen(true)
    setUnificacaoBaseId(id)
    setAvaliarSolicitacao('aceitar')
  }

  return (
    <VFlow vSpacing={1}>
      <Grid gap={2}>
        <Cell size={12}>
          <Alert type='info' inline={true}>
            Para realizar a unificação de base, é necessário aceitar a solicitação. Ao iniciar o processo de unificação,
            a instalação de envio será desativada.
          </Alert>
        </Cell>
        {data.unificacoesBase?.length === 0 ? (
          <Cell size={12}>
            <Text>Nenhuma solicitação de unificação realizada.</Text>
          </Cell>
        ) : (
          <>
            <Cell size={12}>
              <TableBox>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableHeader>Instalação</TableHeader>
                      <TableHeader>Responsável</TableHeader>
                      <TableHeader>Data de solicitação</TableHeader>
                      <TableHeader>Status</TableHeader>
                      <TableHeader />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.unificacoesBase?.map((item) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell>{item.nomeInstalacao}</TableCell>
                          <TableCell />
                          {/*TODO: Responsável será preenchido após a implementação da autenticação*/}
                          <TableCell>{formatDate(item.dataSolicitacao)}</TableCell>
                          <TableCell>
                            <StatusUnificacaoBaseTag
                              status={item.status}
                              dataFimUnificacao={item.dataFimUnificacao}
                              isLoginAtivo={true}
                            />
                          </TableCell>
                          <TableCell>
                            <HFlow hSpacing={1} justifyContent='flex-end'>
                              <Button
                                disabled={item.status !== 'AGUARDANDO_AVALIACAO'}
                                onClick={handleMostrarModalNegar(item.id)}
                                size='small'
                              >
                                Negar
                              </Button>

                              {agendaOnlineAtiva && item.status === StatusUnificacaoBaseEnum.AGUARDANDO_AVALIACAO ? (
                                <Tooltip text='Não é possível aprovar uma unificação de base com a Agenda Online Habilitada. Desabilite a Agenda Online'>
                                  <Button disabled size='small'>
                                    Aceitar
                                  </Button>
                                </Tooltip>
                              ) : (
                                <Button
                                  disabled={item.status !== 'AGUARDANDO_AVALIACAO'}
                                  onClick={handleMostrarModalAceitar(item.id)}
                                  size='small'
                                >
                                  Aceitar
                                </Button>
                              )}
                            </HFlow>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableBox>

              <ModalAvaliarSolicitacao
                isModalOpen={isModalAvaliarOpen}
                setIsModalOpen={setIsModalAvaliarOpen}
                onAvaliarSolicitacao={refetch}
                avaliarSolicitacao={avaliarSolicitacao}
                unificacaoBaseId={unificacaoBaseId}
              />
            </Cell>
          </>
        )}
      </Grid>
    </VFlow>
  )
}
