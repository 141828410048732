import { Button, ButtonProps, Dropdown, DropdownProps, Icon, IconProps, Portal } from 'bold-ui'
import React, { useState } from 'react'

export interface DropdownButtonProps extends ButtonProps {
  icon?: IconProps['icon']
  children: DropdownProps['children']
}

export function DropdownButton(props: DropdownButtonProps) {
  const { icon, children, ...rest } = props

  const [buttonRef, setButtonRef] = useState<HTMLButtonElement>()

  const [open, setOpen] = useState(false)

  const handleClick = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    buttonRef?.focus()
  }

  return (
    <>
      <Button innerRef={setButtonRef} size='small' skin='ghost' onClick={handleClick} {...rest}>
        <Icon icon={icon} />
      </Button>

      <Portal>
        <Dropdown anchorRef={buttonRef} open={open} onClose={handleClose}>
          {children}
        </Dropdown>
      </Portal>
    </>
  )
}

DropdownButton.defaultProps = {
  icon: 'dots',
} as Partial<DropdownButtonProps>
