import axios, { AxiosPromise } from 'axios'
import { ImpressaoMedicamentosInput } from 'view/atendimentos/detail/soap/plano/prescricao-medicamento/impressao/ImpressaoMedicamentosInput'

export const catmatTalidomida = 'BR0272846'
export const catmatIsotretinoina = ['BR0272807', 'BR0272808', 'BR0364789']
export const catmatTretinoina = ['BR0278378', 'BR0278393', 'BR0391324']
export const catmatAcitretina = ['BR0308718', 'BR038719']

export class ImpressaoMedicamentos {
  imprimirMedicamentos(input: ImpressaoMedicamentosInput): AxiosPromise<Blob> {
    return axios.post('/api/atendimento/imprimirMedicamentos', input, { responseType: 'blob' })
  }
}
