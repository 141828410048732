/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  ExternalStyles,
  Omit,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableHeader,
  TableLoadingRow,
  TablePlaceholderRow,
  TableRow,
} from 'bold-ui'
import { yellow } from 'bold-ui/lib/styles/colors'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { useTableSort } from 'components/table/useTableSort'
import { keyframes } from 'emotion'
import { useLoteImunobiologicoQuery } from 'graphql/hooks.generated'
import { LoteImunobiologico, LoteImunobiologicoQueryInput } from 'graphql/types.generated'
import { useState } from 'react'

import LoteImunobiologicoEditInlineFormRow from './LoteImunobiologicoEditInlineFormRow'
import LoteImunobiologicoStaticRow from './LoteImunobiologicoStaticRow'
import { LoteImunobiologicoTableHeader } from './LoteImunobiologicoTableHeader'

interface LoteImunobiologicoRowProps {
  row: LoteImunobiologico
  editing: boolean
  onEditingChange(editing: boolean): void
  reload(): Promise<any>
  style?: ExternalStyles
}

function LoteImunobiologicoRow(props: LoteImunobiologicoRowProps) {
  const { row, reload, editing, onEditingChange, style } = props

  const [fadeEffect, setFadeEffect] = useState(false)

  const handleEditClick = () => onEditingChange(true)

  const handleEditChange = () => {
    onEditingChange(false)
    setFadeEffect(true)
  }

  return editing ? (
    <LoteImunobiologicoEditInlineFormRow row={row} reload={reload} onEditChange={handleEditChange} />
  ) : (
    <LoteImunobiologicoStaticRow
      row={row}
      reload={reload}
      onEditClick={handleEditClick}
      style={fadeEffect ? style : null}
    />
  )
}

export default function LoteImunobiologicoTable() {
  const classes = createStyles()

  const [filter, setFilter] = useState<Omit<LoteImunobiologicoQueryInput, 'pageParams'>>({
    mostrarLotesInativos: false,
    filtroImunoFabricante: '',
  })
  const [paging, setPaging] = useState<Pick<LoteImunobiologicoQueryInput, 'pageParams'>>({
    pageParams: {
      sort: ['imunobiologico', 'fabricanteNome', 'lote', 'validade'],
    },
  })

  const { data, refetch, loading } = useLoteImunobiologicoQuery({
    variables: {
      input: { ...filter, ...paging },
    },
  })

  const [editingRows, setEditingRows] = useState<ID[]>([])
  const handleEditingRowsChange = (row: LoteImunobiologico) => (editing: boolean) => {
    if (editing) {
      setEditingRows((rows) => [...rows, row.id])
    } else {
      setEditingRows((rows) => rows.filter((item) => item !== row.id))
    }
  }

  const isNotEmpty = () => data?.lotesImunobiologico?.content && data.lotesImunobiologico.content.length !== 0

  const handleOnPageChange = (paging: LoteImunobiologicoQueryInput) => {
    setPaging(paging)
    setEditingRows([])
  }

  const { onPageChange, onSortChange, onSizeChange, page, size, totalPages, totalElements } = usePagedTableProps({
    loading,
    onChange: handleOnPageChange,
    result: data.lotesImunobiologico,
  })

  const sort = paging.pageParams.sort
  const { getSortProps } = useTableSort({ sort, onSortChange })

  const columns = [
    { name: 'lote', header: 'Lote' },
    { name: 'fabricanteNome', header: 'Fabricante' },
    { name: 'imunobiologico', header: 'Imunobiológico' },
    { name: 'validade', header: 'Data de validade' },
  ]

  const handleFilterChange = (values: LoteImunobiologicoQueryInput) => {
    setFilter(values)
    setEditingRows([])
  }

  return (
    <TableBox
      header={<LoteImunobiologicoTableHeader initialValues={filter} onChange={handleFilterChange} reload={refetch} />}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableHeader key={col.name} style={classes.header} {...getSortProps(col.name)}>
                {col.header}
              </TableHeader>
            ))}
            <TableHeader key='actions'></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && <TableLoadingRow colSpan={6} />}
          {!loading && !isNotEmpty() && <TablePlaceholderRow colSpan={6} />}

          {isNotEmpty() &&
            data.lotesImunobiologico.content.map((row) => (
              <LoteImunobiologicoRow
                key={row.id}
                row={row}
                reload={refetch}
                editing={editingRows.indexOf(row.id) >= 0}
                onEditingChange={handleEditingRowsChange(row)}
                style={classes.fadeAnimation}
              />
            ))}
        </TableBody>
      </Table>
      <TableFooter
        page={page}
        style={classes.footer}
        pageSize={size}
        totalPages={totalPages}
        totalElements={totalElements}
        onPageChange={onPageChange}
        onSizeChange={onSizeChange}
      />
    </TableBox>
  )
}

const editAnimation = keyframes({
  from: css`
    background-color: ${yellow.c90};
  `,
})

const createStyles = () => ({
  fadeAnimation: css`
    animation: ${editAnimation} 750ms linear;
  `,
  header: css`
    white-space: nowrap;
  `,
  footer: css`
    border-top: none;
  `,
})
