import { Icon } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import { useCancelarAgendamentoMutation } from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { useCallback } from 'react'
import {
  JustificativaCancelamentoAgendaComboEnum as JustificativaEnum,
  JustificativaCancelamentoAgendaRecord,
} from 'types/enums'
import { useAgendaAuthorization } from 'view/agenda/authorization/useAgendaAuthorization'
import { EventoAgendaLotacao, TipoAgendamento } from 'view/agenda/model'

import MenuAcoesAgendamentoButton from '../MenuAcoesAgendamentoButton'
import { AcaoAgendamentoButtonProps } from '../model'
import { agendamentoRegistradoEarlierThanOneHour, createJustificativaProfissional } from '../utils'

interface CancelarAgendamentoButtonProps extends Omit<AcaoAgendamentoButtonProps, 'event'> {
  event: Pick<
    EventoAgendaLotacao,
    'id' | 'tipo' | 'nomeCidadao' | 'motivoReserva' | 'dataCriacao' | 'lotacao' | 'isAtencaoDomiciliar'
  >
  openModalCancelarAgendamento(): void
  onAction?(): void
}

export function CancelarAgendamentoButton(props: CancelarAgendamentoButtonProps) {
  const { event, disabled, tooltip, isDropdown = false, openModalCancelarAgendamento, onAction } = props
  const alert = useAlert()
  const handleException = useErrorHandler()
  const {
    data: {
      profissional: { nome: nomeProfissional },
      acesso: { id: lotacaoId },
    },
  } = useSession({ fetchPolicy: 'cache-first' })

  const { getServerTimeNow } = useServerTime()

  const [cancelarAgendamento, { loading }] = useCancelarAgendamentoMutation()

  const earlierThanAnHour = agendamentoRegistradoEarlierThanOneHour(event.dataCriacao, getServerTimeNow())

  const handleCancelarAgendamento = useCallback(
    (justificativa: JustificativaEnum, justificativaOutra?: string) => {
      cancelarAgendamento({
        variables: {
          input: {
            id: event.id,
            justificativa: JustificativaCancelamentoAgendaRecord[justificativa].justificativaCancelarAgendamentoEnum,
            justificativaOutra,
          },
        },
      })
        .then(() => {
          alert(
            'success',
            event.tipo === TipoAgendamento.CONSULTA
              ? `Agendamento do cidadão ${event.nomeCidadao.titleCase()} foi cancelado.`
              : `Reserva de horário para ${event.motivoReserva.titleCase()} foi cancelada`
          )
          onAction?.()
        })
        .catch(handleException)
    },
    [
      alert,
      cancelarAgendamento,
      event.id,
      event.motivoReserva,
      event.nomeCidadao,
      event.tipo,
      handleException,
      onAction,
    ]
  )

  const onClick = useCallback(() => {
    if (earlierThanAnHour) {
      handleCancelarAgendamento(
        JustificativaEnum.OUTRA,
        createJustificativaProfissional(nomeProfissional, getServerTimeNow())
      )
    } else if (event.tipo === 'reserva' && event.lotacao.id === lotacaoId) {
      handleCancelarAgendamento(JustificativaEnum.JUSTIFICATIVA_AUTOMATICA_PROFISSIONAL)
    } else {
      openModalCancelarAgendamento()
    }
  }, [
    earlierThanAnHour,
    event.lotacao.id,
    event.tipo,
    getServerTimeNow,
    handleCancelarAgendamento,
    lotacaoId,
    nomeProfissional,
    openModalCancelarAgendamento,
  ])

  const { hasAuthorizationCancelar } = useAgendaAuthorization(event.isAtencaoDomiciliar)

  return (
    hasAuthorizationCancelar(event.lotacao) && (
      <MenuAcoesAgendamentoButton
        text='Cancelar'
        tooltip={tooltip}
        disabled={disabled}
        loading={loading}
        isDropdown={isDropdown}
        onClick={onClick}
        icon={<Icon icon='trashOutline' />}
      />
    )
  )
}
