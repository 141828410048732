/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, colors, Grid, HeadingSection, Theme, useTheme, VFlow } from 'bold-ui'
import { NumberField } from 'components/form'
import { EdemaRadioGroupField } from 'components/form/field/EdemaRadioGroupField'
import TipoGravidezSelectField from 'components/form/field/select/TipoGravidezSelectField'
import { SimNaoRadioGroupField } from 'components/form/field/SimNaoRadioGroupField'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Fragment, useMemo } from 'react'
import { MetaPath } from 'util/metaPath'

import { DumObjetivoForm } from '../objetivo/components/DumObjetivoForm'
import { DppPaper } from './components/DppPaper'
import { RiscoGravidezMarker } from './components/RiscoGravidezMarker'
import { PreNatalFormModel, TipoPreNatal } from './model'

interface PreNatalFieldProps {
  name: MetaPath<PreNatalFormModel>
  tipoPreNatal: TipoPreNatal
  metaDum?: MetaPath<string>
}

export function PreNatalField(props: PreNatalFieldProps) {
  const { name, tipoPreNatal, metaDum } = props

  // TODO (ElDorado): Avaliar motivo da demora para adicionar condicao de pn na avaliacao #12321
  const isContinuacaoPreNatal = !!metaDum

  const theme = useTheme()
  const classes = useMemo(() => styles(theme, isContinuacaoPreNatal), [isContinuacaoPreNatal, theme])

  const {
    prontuario: { preNatalAtivo },
  } = useAtendimentoContext()

  const showGravidezPlanejada = !(isContinuacaoPreNatal && preNatalAtivo?.gravidezPlanejada !== null)

  return (
    <VFlow style={classes.container}>
      <HeadingSection level={3} title='Pré-natal, parto e nascimento' />
      <Grid gapVertical={0.5} gap={2}>
        <Cell size={4} lg={3}>
          <TipoGravidezSelectField
            name={name.tipoGravidez}
            showAllEnumValues
            initialValue={preNatalAtivo?.tipoGravidez}
          />
        </Cell>
        <Cell size={3} xs={4} sm={4} lg={3}>
          <NumberField name={name.alturaUterina} label='Altura uterina (cm)' maxLength={2} />
        </Cell>
        <Cell size={4}>
          <RiscoGravidezMarker isAltoRisco={preNatalAtivo?.altoRisco} />
        </Cell>

        <Cell>
          <EdemaRadioGroupField name={name.edema} />
        </Cell>
        <Cell>
          <SimNaoRadioGroupField name={name.movimentacaoFetal} label='Movimentação fetal' />
        </Cell>
        {showGravidezPlanejada && (
          <Cell>
            <SimNaoRadioGroupField name={name.gravidezPlanejada} label='Gravidez planejada' />
          </Cell>
        )}

        <Cell flexBasis='100%'>
          <Cell style={classes.oneElementInRow} size={5} lg={3}>
            <NumberField name={name.batimentoCardiacoFetal} label='Batimento cardíaco fetal (bpm)' maxLength={3} />
          </Cell>
        </Cell>
        {isContinuacaoPreNatal && (
          <Fragment>
            <Cell style={classes.firstDumRowCell}>
              <DumObjetivoForm name={metaDum} tipoPreNatal={tipoPreNatal} />
            </Cell>
            <Cell>
              <DppPaper />
            </Cell>
          </Fragment>
        )}
      </Grid>
    </VFlow>
  )
}

const styles = (theme: Theme, isContinuacaoPreNatal: boolean) => ({
  container: css`
    padding: 1rem;
    border: 1px ${colors.purple.c60} solid;
    border-radius: ${theme.radius.paper}px;
    margin-top: ${isContinuacaoPreNatal ? '0.5rem' : 0};
  `,
  oneElementInRow: css`
    padding: 0;
  `,
  firstDumRowCell: css`
    padding-right: 0rem;
  `,
})
