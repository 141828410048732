import ApolloClient, { ApolloQueryResult } from 'apollo-client'
import { information } from 'components/modals/information'
import { differenceInDays } from 'date-fns'
import { CidPrimeiroAtendimentoPreNatalDocument } from 'graphql/hooks.generated'
import { CidPrimeiroAtendimentoPreNatalQuery } from 'graphql/types.generated'
import { dateAsDdMmYyyy, toDate } from 'util/date/formatDate'

import {
  CIAP_GRAVIDEZ_ALTO_RISCO,
  CIAPS_ENCERRAMENTO_GESTACAO,
  CIAPS_GESTACAO_CODIGOS,
  CIAPS_PUERPERIO_CODIGOS,
  CID_GRAVIDEZ_ALTO_RISCO,
  CidPreNatalModel,
  CIDS_ENCERRAMENTO_GESTACAO,
  CIDS_GESTACAO_CODIGOS,
  FAMILIA_CID_Z34_CODIGO,
  IDADE_GESTACIONAL_MAXIMA_EM_DIAS,
  LIMITE_DIFERENCA_ENTRE_DUM_DATA_ATENDIMENTO_EM_DIAS,
} from './model'

export const getCidPrimeiroAtendimentoPreNatal = async (
  prontuarioId: ID,
  apollo: ApolloClient<object>
): Promise<CidPreNatalModel> =>
  apollo
    .query({
      query: CidPrimeiroAtendimentoPreNatalDocument,
      variables: { prontuarioId },
      fetchPolicy: 'network-only',
    })
    .then(
      (response: ApolloQueryResult<CidPrimeiroAtendimentoPreNatalQuery>) => response.data.cidPrimeiroAtendimentoPreNatal
    )

export function isCiapPuerperio(codigoCiap?: string) {
  return CIAPS_PUERPERIO_CODIGOS.has(codigoCiap)
}

export function isCidFromFamiliaZ34(codigoCid?: string) {
  return FAMILIA_CID_Z34_CODIGO.has(codigoCid)
}

export const isEncerramentoGestacao = (codigoCidOuCiap?: string): boolean =>
  CIAPS_ENCERRAMENTO_GESTACAO.has(codigoCidOuCiap) || CIDS_ENCERRAMENTO_GESTACAO.has(codigoCidOuCiap)

export function isCiapOuCidDeGestacao(codigoCiap?: string, codigoCid?: string) {
  return isCiapGestacao(codigoCiap) || isCidGestacao(codigoCid)
}

export function isCiapGestacao(codigoCiap?: string) {
  return CIAPS_GESTACAO_CODIGOS.has(codigoCiap)
}

export function isCidGestacao(codigoCid?: string) {
  return CIDS_GESTACAO_CODIGOS.has(codigoCid)
}

export function isCiapGravidezAltoRisco(codigoCiap?: string) {
  return CIAP_GRAVIDEZ_ALTO_RISCO.has(codigoCiap)
}

export function isCidGravidezAltoRisco(codigoCid?: string) {
  return CID_GRAVIDEZ_ALTO_RISCO.has(codigoCid)
}

export function isDumForaDoLimitePermitido(dataAtendimento: Instant, dum: string): boolean {
  return differenceInDays(dataAtendimento, toDate(dum)) > LIMITE_DIFERENCA_ENTRE_DUM_DATA_ATENDIMENTO_EM_DIAS
}

export const informationPreNatalQuandoExisteW78ResolvidoNaAvaliacao = () => {
  information({
    title: 'Não é possível iniciar Pré-natal.',
    body: 'Para iniciar Pré-natal, remova a condição W78 com a situação resolvida adicionada na Avaliação.',
  })()
}

export const informationW78ResolvidoNaAvaliacaoQuandoExistePreNatal = () => {
  information({
    title: 'Não é possível resolver a condição de gravidez.',
    body: 'Para resolver a condição de gravidez, remova a condição de Pré-natal.',
  })()
}

export const informationPreNatalSuperiorA336Dias = (dataInicioGestacao: Date) => {
  information({
    title: 'A gestação da cidadã está ativa desde ' + dateAsDdMmYyyy(dataInicioGestacao),
    body:
      'Não é possível dar continuidade a um pré-natal com período superior a ' +
      IDADE_GESTACIONAL_MAXIMA_EM_DIAS +
      ' dias. Para iniciar uma nova gestação, é necessário encerrar a gestação atual através da avaliação do SOAP.',
  })()
}
