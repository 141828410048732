import { Button, Cell, Grid, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { ErrorField, Form, PasswordField, SubmitButton, TextField } from 'components/form'
import { FormFooter } from 'components/form/FormFooter'
import { InfoNovaSenha } from 'components/InfoNovaSenha'
import { FORM_ERROR, FormApi } from 'final-form'
import { noop } from 'lodash'
import React, { Fragment, useMemo } from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'

import { AlterarSenhaModel } from './model'
import { alterarSenhaValidator } from './validator'

export interface AlterarSenhaFormProps {
  initialValues?: AlterarSenhaModel
  senhaAtualRequired?: boolean
  tokenRequired?: boolean
  usuarioRequired?: boolean
  cancelarLabel?: string
  salvarLabel?: string
  supressAlertError?: boolean
  nomeProfissional?: string

  onSubmit(values: AlterarSenhaModel, form?: FormApi): Promise<any>
  onSubmitSucceeded?(): any
  onCancelar(): any
}

const meta = metaPath<AlterarSenhaModel>()

export const AlterarSenhaForm = (props: AlterarSenhaFormProps) => {
  const {
    initialValues,
    usuarioRequired = false,
    senhaAtualRequired = false,
    tokenRequired = false,
    cancelarLabel = 'Cancelar',
    salvarLabel = 'Salvar',
    supressAlertError = false,
    nomeProfissional,
    onSubmit,
    onSubmitSucceeded,
    onCancelar,
  } = props

  const validator = useMemo(() => alterarSenhaValidator({ senhaAtualRequired, tokenRequired, usuarioRequired }), [
    senhaAtualRequired,
    tokenRequired,
    usuarioRequired,
  ])
  const alert = useAlert()

  const handleSubmit = async (values: AlterarSenhaModel, formApi: FormApi) => {
    await onSubmit(values, formApi)
    alert('success', `Senha ${!!nomeProfissional ? `do profissional ${nomeProfissional} ` : ''}alterada com sucesso.`)

    formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
    setTimeout(formApi.reset)

    onSubmitSucceeded?.()
  }

  const render = ({ handleSubmit }: FormRenderProps<AlterarSenhaModel>) => {
    return (
      <Fragment>
        <VFlow>
          <Grid>
            {supressAlertError && (
              <Cell size={12}>
                <ErrorField name={FORM_ERROR} inline={false} type='alert' />
              </Cell>
            )}

            {usuarioRequired && (
              <Fragment>
                <Cell xs={12} md={6}>
                  <TextField name={meta.usuario} label='Usuário' required />
                </Cell>

                <Cell md={6} />
              </Fragment>
            )}

            <Cell xs={12} md={6}>
              <InfoNovaSenha />
            </Cell>

            <Cell md={6} />

            {senhaAtualRequired && (
              <Fragment>
                <Cell xs={12} md={6}>
                  <PasswordField
                    name={meta.senhaAtual}
                    label='Senha atual'
                    placeholder='Senha'
                    maxLength={20}
                    clearable={false}
                    required
                  />
                </Cell>

                <Cell md={6} />
              </Fragment>
            )}

            <Cell xs={12} md={6}>
              <PasswordField
                name={meta.novaSenhaInput.novaSenha}
                label='Nova senha'
                placeholder='Senha'
                maxLength={20}
                clearable={false}
                required
              />
            </Cell>

            <Cell xs={12} md={6}>
              <PasswordField
                name={meta.novaSenhaInput.confirmacaoSenha}
                label='Confirmação de senha'
                placeholder='Senha'
                maxLength={20}
                clearable={false}
                required
              />
            </Cell>
          </Grid>

          <FormFooter>
            <Button onClick={onCancelar}>{cancelarLabel}</Button>
            <SubmitButton handleSubmit={handleSubmit}>{salvarLabel}</SubmitButton>
          </FormFooter>
        </VFlow>
      </Fragment>
    )
  }

  return (
    <Form<AlterarSenhaModel>
      render={render}
      onSubmit={handleSubmit}
      validate={validator}
      initialValues={initialValues}
      onSubmitFailed={supressAlertError ? noop : undefined} //Previne mostrar o alerta de validação
    />
  )
}
