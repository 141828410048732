/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  Cell,
  Grid,
  Heading,
  HeadingSection,
  HFlow,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
  Tooltip,
  VFlow,
} from 'bold-ui'
import useSession from 'components/auth/useSession'
import { Cpf } from 'components/label'
import { useEncaminhamentoByIdLazyQuery } from 'graphql/hooks.generated'
import { ClassificacaoRiscoEncaminhamentoEnum } from 'graphql/types.generated'
import { useMemo } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import { isCboMedicoOuCirurgiaoDentista } from 'util/atendimento/cboMedicoOuCirurgiaoDentista'
import { formatDate } from 'util/date/formatDate'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { grupoCboEncaminhamentoExterno } from '../../acessos'
import { downloadEncaminhamentoExterno } from '../impressao/DownloadEncaminhamentoExterno'
import { EncaminhamentoExternoModel } from '../model'
import { StatusClassificacaoRiscoEncaminhamento } from './StatusClassificacaoRiscoEncaminhamento'

interface EncaminhamentoExternoVisualizacaoModalProps {
  prontuarioId: ID
  atendimentoId?: ID
  dataReferencia: Instant
  encaminhamentosRecentes: EncaminhamentoExternoModel[]
  lotacao: LotacaoAtendimento
  atendimentoProfissionalId: ID
}

interface UrlParams {
  statusRegistro: string
  id: ID
}

const classificacaoRiscoDescricao: Record<ClassificacaoRiscoEncaminhamentoEnum, string> = {
  EMERGENCIA: 'Emergência',
  URGENCIA: 'Urgência',
  PRIORITARIO: 'Prioritário',
  ELETIVO: 'Eletivo',
}

export default function EncaminhamentoExternoVisualizacaoModal(props: EncaminhamentoExternoVisualizacaoModalProps) {
  const {
    prontuarioId,
    atendimentoId,
    dataReferencia,
    encaminhamentosRecentes,
    lotacao,
    atendimentoProfissionalId,
  } = props
  const [executeQuery, { data }] = useEncaminhamentoByIdLazyQuery()
  const { statusRegistro, id } = useParams<UrlParams>()

  const history = useHistory()
  const match = useRouteMatch()
  const { data: sessionData } = useSession()

  const handleCloseModal = () => history.push(match.url.split('/encaminhamentos-externos/visualizar')[0])
  let isRegistradoAgora: boolean
  let encaminhamentoCache: EncaminhamentoExternoModel

  useMemo(() => {
    if (statusRegistro === 'registro-anterior') executeQuery({ variables: { id } })
  }, [executeQuery, id, statusRegistro])

  if (statusRegistro === 'registro-anterior') {
    isRegistradoAgora = false
  } else if (statusRegistro === 'registrado-agora') {
    isRegistradoAgora = true
    encaminhamentoCache = encaminhamentosRecentes.find((enc) => enc._id === id)
  }

  const especialidade = isRegistradoAgora
    ? encaminhamentoCache.especialidadeSisreg.descricao
    : data?.encaminhamento.especialidadeSisreg.descricao
  const complemento = isRegistradoAgora
    ? encaminhamentoCache.complemento ?? '-'
    : data?.encaminhamento.complemento ?? '-'

  const renderCiap = isRegistradoAgora
    ? !isCboMedicoOuCirurgiaoDentista(lotacao.cbo.cbo2002)
    : data?.encaminhamento.atendimentoProfissional.lotacao.cbo.cbo2002 &&
      !isCboMedicoOuCirurgiaoDentista(data?.encaminhamento.atendimentoProfissional.lotacao.cbo.cbo2002)

  const hipoteseDiagnostica = () => {
    if (renderCiap) {
      return isRegistradoAgora
        ? `${encaminhamentoCache.ciapEncaminhamento.nome} - ${encaminhamentoCache.ciapEncaminhamento.codigo}`
        : `${data?.encaminhamento.hipoteseDiagnosticoCiap.descricao} - ${data?.encaminhamento.hipoteseDiagnosticoCiap.id}`
    } else {
      return isRegistradoAgora
        ? `${encaminhamentoCache.cidEncaminhamento.nome} - ${encaminhamentoCache.cidEncaminhamento.codigo}`
        : `${data?.encaminhamento.hipoteseDiagnosticoCid10.nome} - ${data?.encaminhamento.hipoteseDiagnosticoCid10.id}`
    }
  }

  const classificacaoRiscoEnum = isRegistradoAgora
    ? encaminhamentoCache.classificacaoRiscoEncaminhamento
    : data?.encaminhamento.classificacaoRiscoEncaminhamento
  const motivoEncaminhamento = isRegistradoAgora
    ? encaminhamentoCache.motivoEncaminhamento ?? '-'
    : data?.encaminhamento.motivoEncaminhamento ?? '-'
  const observacoes = isRegistradoAgora
    ? encaminhamentoCache.observacoes ?? '-'
    : data?.encaminhamento.observacoes ?? '-'

  const dataAtendimentoEncaminhamento = isRegistradoAgora
    ? formatDate(dataReferencia)
    : data?.encaminhamento.atendimentoProfissional.iniciadoEm &&
      formatDate(data?.encaminhamento.atendimentoProfissional.iniciadoEm)

  const cpfProfissional = isRegistradoAgora
    ? sessionData.profissional.cpf
    : data?.encaminhamento.atendimentoProfissional.lotacao.profissional.cpf
  const cnsProfissional = isRegistradoAgora
    ? sessionData.profissional.cns
    : data?.encaminhamento.atendimentoProfissional.lotacao.profissional.cns
  const nomeProfissional = isRegistradoAgora
    ? lotacao.profissional.nome
    : data?.encaminhamento.atendimentoProfissional.lotacao.profissional.nome
  const cboProfissional = isRegistradoAgora
    ? lotacao.cbo.nome
    : data?.encaminhamento.atendimentoProfissional.lotacao.cbo.nome

  const handleImpressao = () => {
    if (isRegistradoAgora) {
      downloadEncaminhamentoExterno({
        encaminhamentoAtual: encaminhamentoCache,
        atendimentoProfissionalId: atendimentoProfissionalId,
        atendimentoId: atendimentoId,
        prontuarioId: prontuarioId,
      })
    } else {
      downloadEncaminhamentoExterno({
        id: id,
        atendimentoProfissionalId: data.encaminhamento?.atendimentoProfissional?.id,
        atendimentoId: atendimentoId,
        prontuarioId: prontuarioId,
      })
    }
  }

  return (
    <Modal style={classes.modal} open onClose={handleCloseModal}>
      <ModalBody>
        <HeadingSection level={1} title='Visualizar encaminhamento' style={classes.titulo} />
        <Text>
          {dataAtendimentoEncaminhamento} | {nomeProfissional} -{' '}
          {cpfProfissional ? <Cpf value={cpfProfissional} /> : cnsProfissional} | {cboProfissional}
        </Text>
        <VFlow style={classes.content}>
          <Grid>
            <Cell size={6}>
              <Heading level={4}>Especialidade</Heading>
              <Text>{especialidade}</Text>
            </Cell>
            <Cell size={6}>
              <Heading level={4}>Complemento</Heading>
              <Text>{complemento}</Text>
            </Cell>
          </Grid>
          <Grid>
            <Cell size={6}>
              <Heading level={4}>{`Hipótese/Diagnóstico (${renderCiap ? 'CIAP2' : 'CID10'})`}</Heading>
              <Text>{hipoteseDiagnostica()}</Text>
            </Cell>
            <Cell size={6}>
              <Heading level={4}>Classificação de risco/Vulnerabilidade</Heading>
              <HFlow hSpacing={0.5} alignItems='center'>
                {classificacaoRiscoEnum && (
                  <StatusClassificacaoRiscoEncaminhamento
                    status={classificacaoRiscoEnum}
                    size={0.75}
                    showTooltip={false}
                  />
                )}
                <Text>{classificacaoRiscoDescricao[classificacaoRiscoEnum]}</Text>
              </HFlow>
            </Cell>
          </Grid>
          <Grid>
            <Cell style={classes.richText}>
              <Heading level={4}>Motivo do encaminhamento</Heading>
              <Text dangerouslySetInnerHTML={{ __html: motivoEncaminhamento }} />
            </Cell>
          </Grid>
          <Grid>
            <Cell style={classes.richText}>
              <Heading level={4}>Observações</Heading>
              <Text dangerouslySetInnerHTML={{ __html: observacoes }} />
            </Cell>
          </Grid>
        </VFlow>
      </ModalBody>
      {grupoCboEncaminhamentoExterno && (
        <ModalFooter>
          <HFlow justifyContent='flex-end'>
            <Tooltip text='Imprimir'>
              <Button size='medium' kind='primary' onClick={() => handleImpressao()}>
                <Icon icon='printerOutline' style={classes.printerIcon} />
                Imprimir
              </Button>
            </Tooltip>
          </HFlow>
        </ModalFooter>
      )}
    </Modal>
  )
}

const classes = {
  modal: css`
    width: 40rem;
  `,
  titulo: css`
    h1 {
      margin-bottom: 0;
    }
  `,
  richText: css`
    word-break: break-word;
  `,
  content: css`
    margin-top: 0.7rem;
  `,
  printerIcon: css`
    margin-right: 0.75rem;
  `,
}
