import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { SituacaoProblema } from 'graphql/types.generated'

import { Problema } from '../../../aside/types/ProblemaModel'
import { DataProblemaFieldModel } from './DataProblemaField'

export interface ProblemaCondicaoModel {
  _id: ID
  problemaId?: ID
  ciap?: CiapSelectFieldModel
  cid?: CidSelectFieldModel
  incluirNaListaProblemas?: boolean
  observacao?: string
  situacaoProblema?: SituacaoProblema
  dataInicio?: DataProblemaFieldModel
  dataFim?: DataProblemaFieldModel
  problemaCondicaoEvoluir?: Problema
  automatico?: boolean
  registradoAgora?: boolean
  avaliadoAgora?: boolean
  isAntecedentes?: boolean
}

export interface TodosProblemasCondicoesModel {
  problemasCondicoesAvaliados?: ProblemaCondicaoModel[]
  problemasCondicoesAnteriores?: ProblemaCondicaoModel[]
}

export enum CiapCidRowState {
  DEFAULT,
  OBSERVACAO,
  EDICAO,
}
