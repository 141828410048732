import { blue, orange } from 'bold-ui/lib/styles/colors'
import { Chart, ChartBody, ChartContainer, DataPoint, DateRange, SeriesType } from 'components/chart'
import moment, { Moment } from 'moment'
import React from 'react'
import { tipoGlicemiaRecord } from 'types/enums'
import { calculateIdadeGestacionalParaGraficos } from 'util/atendimento/gestante/calculateIdadeGestacional'
import { filterGestacoesPeriodo } from 'util/atendimento/gestante/filterGestacoesPeriodo'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { LinhaTooltipGrafico } from '../../../LinhaTooltipGrafico'
import { PeriodoGestacaoModel } from '../../types/PeriodoGestacaoModel'

const SERIES_COLORS = {
  Jejum: blue.c20,
  'Pré-prandial': blue.c50,
  'Pós-prandial': orange.c40,
  'Não especificado': orange.c60,
}

export interface GraficoGlicemiaProps {
  medicoesComGlicemia: HistoricoMedicaoModel[]
  dateRange: DateRange
  gestacoes: PeriodoGestacaoModel[]
}

export function GraficoGlicemiaView(props: GraficoGlicemiaProps) {
  const { medicoesComGlicemia, dateRange, gestacoes } = props

  const seriesMap = medicoesComGlicemia.reduce(
    (acc, curr) =>
      acc.set(curr.tipoGlicemia, [
        ...(acc.get(curr.tipoGlicemia) ?? []),
        { x: curr.dataMedicao, y: curr.valorGlicemia },
      ]),
    new Map<string, DataPoint<Moment>[]>()
  )
  const series = Array.from(seriesMap.entries()).map(([tipoGlicemiaDbEnum, data]) => ({
    name: tipoGlicemiaRecord[tipoGlicemiaDbEnum],
    data,
    color: SERIES_COLORS[tipoGlicemiaRecord[tipoGlicemiaDbEnum]],
  }))

  const gestacoesPeriodo = filterGestacoesPeriodo(gestacoes, dateRange.init, dateRange.end)

  return (
    <ChartContainer>
      <ChartBody height={500}>
        <Chart<Moment>
          type={SeriesType.Line}
          height={580}
          width={764}
          series={series}
          xAxis={{
            title: 'Dia',
            domain: { ...dateRange, format: 'DD' },
            tickRenderer: (props) => {
              return (
                <>
                  {props.payload.value !== dateRange.end.valueOf() && (
                    <text dy={15} {...props}>
                      {moment(props.payload.value).date()}
                    </text>
                  )}
                </>
              )
            },
          }}
          rangeAreas={gestacoesPeriodo.map((g) => ({
            name: 'Gestação',
            init: moment(g.inicio).startOf('day'),
            end: moment(g.fim ?? moment(g.inicio).add(42, 'week')).endOf('day'),
          }))}
          yAxis={{ title: 'Glicemia', unit: 'mg/dL', domain: { init: 50, end: 250, step: 10 } }}
          tooltip={{
            type: 'point',
            render: (points) => {
              if (!points?.length) return
              const momentX = moment(points[0].x)
              const { idadeGestacional, hasGestacaoPeriodo } = calculateIdadeGestacionalParaGraficos(
                gestacoesPeriodo,
                momentX
              )
              return (
                <>
                  {points?.map((p) => (
                    <div key={p.seriesName}>
                      <LinhaTooltipGrafico>{`Glicemia: ${p.y} mg/dL`}</LinhaTooltipGrafico>
                      <LinhaTooltipGrafico>{`Coleta: ${p.seriesName}`}</LinhaTooltipGrafico>
                    </div>
                  ))}
                  {hasGestacaoPeriodo && (
                    <LinhaTooltipGrafico>
                      {`IG: ${idadeGestacional} ${idadeGestacional === 1 ? 'semana' : 'semanas'}`}
                    </LinhaTooltipGrafico>
                  )}
                  <LinhaTooltipGrafico>{`Data: ${momentX.format('DD/MM/YYYY[ | ]HH:mm')}`}</LinhaTooltipGrafico>
                </>
              )
            },
          }}
        />
      </ChartBody>
    </ChartContainer>
  )
}
