import { Grid, useStyles } from 'bold-ui'
import { useServerTime } from 'hooks/useServerTime'
import moment from 'moment'
import React, { CSSProperties, useMemo } from 'react'
import { listaAtendimentoStylesRecord } from 'view/atendimentos/list/listaAtendimentoStyles'

import { ListaAtendimentoFilterModel } from './ListaAtendimentoFilter'
import { ListaAtendimentoFilterItem } from './ListaAtendimentoFilterItem'

export interface ListaAtendimentoFilterTagsProps {
  filtros: ListaAtendimentoFilterModel
  onChange(values: ListaAtendimentoFilterModel): void
}

export function ListaAtendimentoFilterTags(props: ListaAtendimentoFilterTagsProps) {
  const { classes } = useStyles(createStyles)

  const { getServerTimeNow } = useServerTime()

  const now = moment(getServerTimeNow())

  const statusAtendimentoText =
    props.filtros.statusAtendimento?.length > 0 &&
    props.filtros.statusAtendimento.map((item) => listaAtendimentoStylesRecord[item].descricao).join(', ')

  const statusAtendimento = props.filtros.statusAtendimento?.length > 0 && (
    <ListaAtendimentoFilterItem label='Status do atendimento:' value={statusAtendimentoText} />
  )

  const startDateText = useMemo(
    () =>
      moment(props.filtros.periodo.startDate).isSame(now, 'day')
        ? 'hoje'
        : moment(props.filtros.periodo.startDate).format('DD/MM/YYYY'),
    [now, props.filtros.periodo.startDate]
  )

  const endDateText = useMemo(
    () =>
      moment(props.filtros.periodo.endDate).isSame(now, 'day')
        ? 'hoje'
        : moment(props.filtros.periodo.endDate).format('DD/MM/YYYY'),
    [now, props.filtros.periodo.endDate]
  )

  const periodoStartAndEndDateIsSameAndToday = useMemo(
    () =>
      moment(props.filtros.periodo.startDate).isSame(moment(props.filtros.periodo.endDate), 'day') &&
      moment(props.filtros.periodo.startDate).isSame(now, 'day'),
    [now, props.filtros.periodo.endDate, props.filtros.periodo.startDate]
  )

  const periodoText = useMemo(
    () =>
      !periodoStartAndEndDateIsSameAndToday
        ? props.filtros?.periodo?.startDate &&
          props.filtros?.periodo?.endDate &&
          `${startDateText} até ${endDateText}`.capitalize()
        : 'Hoje',
    [endDateText, periodoStartAndEndDateIsSameAndToday, props.filtros, startDateText]
  )

  const periodo = !props.filtros.somenteNaoFinalizados &&
    props.filtros.periodo?.startDate &&
    props.filtros.periodo?.endDate && (
      <ListaAtendimentoFilterItem label='Período:' value={periodoText} style={classes.periodoColumn} />
    )

  const showSomenteNaoFinalizados = props.filtros.somenteNaoFinalizados && (
    <ListaAtendimentoFilterItem
      value='Somente atendimentos não finalizados'
      style={classes.periodoColumn}
      textStyle={{ fontWeight: 'bold' }}
    />
  )

  const tipoServicoText =
    props.filtros.tiposServico?.length > 0 &&
    props.filtros.tiposServico.map((item) => item.nome.capitalize()).join(', ')

  const tipoServico = props.filtros.tiposServico?.length > 0 && (
    <ListaAtendimentoFilterItem label='Tipo de serviço:' value={tipoServicoText} />
  )

  const equipeText = props.filtros.equipes?.length > 0 && props.filtros.equipes.map((item) => item.nome).join(', ')

  const equipe = props.filtros.equipes?.length > 0 && <ListaAtendimentoFilterItem label='Equipe:' value={equipeText} />

  const lotacoesText =
    props.filtros.lotacoes?.length > 0 && props.filtros.lotacoes.map((item) => item.profissional.nome).join(', ')

  const lotacoes = props.filtros.lotacoes?.length > 0 && (
    <ListaAtendimentoFilterItem label='Profissionais:' value={lotacoesText} />
  )

  return (
    <Grid alignItems='center' justifyContent='flex-start' gap={0.25} gapVertical={0.25} wrap={false}>
      {statusAtendimento || showSomenteNaoFinalizados || periodo || tipoServico || equipe || lotacoes ? (
        <>
          {statusAtendimento}
          {periodo}
          {showSomenteNaoFinalizados}
          {tipoServico}
          {equipe}
          {lotacoes}
        </>
      ) : (
        'Sem filtros ativos'
      )}
    </Grid>
  )
}

const createStyles = () => ({
  periodoColumn: {
    overflow: 'visible',
  } as CSSProperties,
})
