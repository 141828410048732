/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Paper, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { DateField } from 'components/form'
import { InfoIcon } from 'components/InfoIcon'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import moment from 'moment'
import { Fragment, useMemo } from 'react'
import { isUndefinedOrNull } from 'util/checks'
import { formatDate, toDate } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'

import { TipoPreNatal } from '../../pre-natal/model'

interface DumObjetivoFormProps {
  name: MetaPath<string>
  tipoPreNatal: TipoPreNatal
}

export function DumObjetivoForm(props: DumObjetivoFormProps) {
  const { name, tipoPreNatal } = props

  const {
    cidadao: { dataNascimento, ultimaDum },
    atendimentoProfissional: { iniciadoEm },
  } = useAtendimentoContext()

  const theme = useTheme()

  const styles = createStyles(theme)

  const diffDumDays = useMemo(() => moment().diff(ultimaDum?.dum, 'd'), [ultimaDum])

  const isDumRequired =
    tipoPreNatal === TipoPreNatal.PRIMEIRO_ATENDIMENTO_PRE_NATAL ||
    (tipoPreNatal === TipoPreNatal.CONTINUACAO_PRE_NATAL && isUndefinedOrNull(ultimaDum))

  return (
    <HFlow>
      <DateField
        name={name}
        label={
          <Fragment>
            DUM
            <InfoIcon
              icon='infoCircleFilled'
              text='Preencher mesmo se a cidadã estiver com dúvida.'
              style={styles.dumIcon}
            />
          </Fragment>
        }
        minDate={toDate(dataNascimento)}
        maxDate={iniciadoEm}
        required={isDumRequired}
      />
      <Paper style={styles.ultimaDumPaper}>
        <VFlow vSpacing={0.25}>
          <HFlow hSpacing={0.5}>
            <Heading level={4}>Última DUM:</Heading>
            <Text component='span'>
              {ultimaDum?.dum && `${formatDate(ultimaDum.dum)} (${'dia'.pluralizeAndConcatValue(diffDumDays)})`}
            </Text>
          </HFlow>
          <Text component='span' fontStyle='italic'>
            {ultimaDum?.registradoEm ? `Registrada em: ${formatDate(ultimaDum.registradoEm)}` : 'Não registrada'}
          </Text>
        </VFlow>
      </Paper>
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  dumIcon: css`
    padding: 0.2rem 0 0 0.2rem;
  `,
  ultimaDumPaper: css`
    border: 0;
    background: ${theme.pallete.surface.background};
    padding: 0.5rem;
    ${theme.breakpoints.up('lg')} {
      max-height: 3.75rem;
    }
    ${theme.breakpoints.down('lg')} {
      max-height: 4.5rem;
    }
  `,
})
