import { AtendimentoInput, TipoServicoPadraoEnum } from 'graphql/types.generated'
import { TipoServicoPadraoRecord } from 'types/enums'

import { NovoAtendimentoFormModel } from './list/ListaAtendimentoForm'

export const convertNovoAtendimentoModelToInput = (atendimento: NovoAtendimentoFormModel): AtendimentoInput => {
  return {
    cidadao: atendimento?.cidadao?.id,
    agendado: atendimento?.agendado,
    equipe: atendimento?.equipe?.id,
    lotacao: atendimento?.lotacao?.id,
    tiposServico: [
      ...(atendimento?.tiposServico ?? []),
      ...(atendimento?.agendado ? TipoServicoPadraoRecord[TipoServicoPadraoEnum.AGENDADO] : []),
    ],
  }
}
