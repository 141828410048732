import { FormControl, VFlow } from 'bold-ui'
import { RadioGroupField } from 'components/form'
import { TipoAtendimentoEnum } from 'graphql/types.generated'
import React from 'react'
import { Meta } from 'util/metaPath'

export interface TipoAtendimentoPanelProps {
  name: Meta<TipoAtendimentoEnum>
  isAtendimentoAgendado: boolean
  isAtendimentoOdonto: boolean
}

export const TipoAtendimentoPanel = React.memo(
  ({ name, isAtendimentoAgendado, isAtendimentoOdonto }: TipoAtendimentoPanelProps) => (
    <FormControl label='Tipo de atendimento' required>
      <VFlow vSpacing={0.5}>
        <RadioGroupField
          name={name}
          lines={isAtendimentoAgendado && !isAtendimentoOdonto ? 2 : 1}
          options={getTiposAtendimentoOptions(isAtendimentoAgendado, isAtendimentoOdonto)}
        />
      </VFlow>
    </FormControl>
  )
)

function getTiposAtendimentoOptions(isAtendimentoAgendado: boolean, isAtendimentoOdonto: boolean) {
  const options = []

  if (isAtendimentoAgendado) {
    options.push({ label: 'Consulta agendada', value: TipoAtendimentoEnum.CONSULTA })
    if (!isAtendimentoOdonto)
      options.push({
        label: 'Consulta agendada programada / Cuidado continuado',
        value: TipoAtendimentoEnum.CONSULTA_AGENDADA_CUIDADO_CONTINUADO,
      })
  }

  options.push(
    { label: 'Consulta no dia', value: TipoAtendimentoEnum.CONSULTA_NO_DIA },
    { label: 'Urgência', value: TipoAtendimentoEnum.URGENCIA }
  )

  return options
}
