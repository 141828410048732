import {
  FinalizarAtendimentoIndividualInput,
  LembreteSaveInput,
  MedicamentoInterromperInput,
  MedicoesInput,
} from 'graphql/types.generated'
import { parseNumber } from 'util/number'

import { convertRegistroVacinacaoToInput } from '../atendimento-vacinacao/registros/converter'
import { LembreteFormModel } from '../detail/components/modals/lembretes/components/LembreteForm'
import { MedicamentoInterromperFormModel } from '../detail/components/modals/medicamentos/InterromperTratamentoModal'
import { MedicaoAnteriorFormModel } from '../detail/components/modals/medicoes/MedicaoAnteriorForm'
import { convertAntecedentesFormToInput } from '../detail/soap/antecedentes/converter'
import { convertLPCFormToInput } from '../detail/soap/avaliacao/components/problemas-condicoes/converter'
import { convertAvaliacaoFormToInput } from '../detail/soap/avaliacao/converter'
import { convertDesfechoFormToInput } from '../detail/soap/desfecho'
import { convertObjetivoFormToInput } from '../detail/soap/objetivo'
import { convertPlanoFormToInput } from '../detail/soap/plano/converter'
import { convertPreNatalFormToInput } from '../detail/soap/pre-natal/converter'
import { convertSubjetivoFormToInput } from '../detail/soap/subjetivo'
import { SoapState } from './model'

export const converAtendimentoIndividualModelToInput = (
  atendimentoId: ID,
  values: SoapState
): FinalizarAtendimentoIndividualInput => ({
  id: atendimentoId,
  antecedentes: convertAntecedentesFormToInput(values.antecedentes),
  subjetivo: convertSubjetivoFormToInput(values.subjetivo),
  objetivo: convertObjetivoFormToInput(values.objetivo),
  avaliacao: convertAvaliacaoFormToInput(values.avaliacao),
  plano: convertPlanoFormToInput(values.plano),
  desfecho: convertDesfechoFormToInput(values.desfecho),
  medicoesAnteriores: values.medicoesAnteriores?.map(convertMedicaoAnterior),
  medicamentoInterromper: values.medicamentosInterromper?.map(convertMedicamentoInterrupcao),
  lembretes: values.lembretes?.map(convertLembreteToSaveInput),
  preNatal: convertPreNatalFormToInput(values.preNatal),
  imprimirAtendimento: values.imprimirAtendimento,
  registrosVacinacao: convertRegistroVacinacaoToInput(values.vacinacao?.registroVacinacao),
  problemasECondicoes: values.problemasECondicoes?.map(convertLPCFormToInput),
})

export const convertMedicaoAnterior = (medicao: MedicaoAnteriorFormModel): MedicoesInput => ({
  dataMedicao: medicao.data,
  altura: parseNumber(medicao.altura),
  perimetroCefalico: parseNumber(medicao.perimetroCefalico),
  peso: parseNumber(medicao.peso),
})

export const convertMedicamentoInterrupcao = (
  medicamento: MedicamentoInterromperFormModel
): MedicamentoInterromperInput => ({
  id: medicamento.id,
  justificativa: medicamento.justificativa,
})

const convertLembreteToSaveInput = (lembrete: LembreteFormModel): LembreteSaveInput => ({
  id: lembrete.lembreteId,
  descricao: lembrete.descricao,
  ativo: lembrete.ativo,
  visibilidadeLembrete: lembrete.visibilidadeLembrete,
})
