import { Alert, Cell, Grid, Link, Tooltip, VFlow } from 'bold-ui'
import { Form, FormRenderProps, NumberField, PasswordField, SubmitButton } from 'components/form'
import { FormState } from 'final-form'
import React, { useState } from 'react'
import { metaPath } from 'util/metaPath'
import { createValidator, ErrorObject, maxLength, required } from 'util/validation'

import ConfiguracaoRndsFormModel from './ConfiguracoesRndsRootView'
import { InformacaoContraChaveRndsComponent } from './InformacaoContraChaveRndsComponent'
import { RndsCertificadoSelectField } from './RndsCertificadoSelectField'

export interface ConfiguracaoRndsCreateViewProps {
  internetHabilitada: boolean
  handleSubmit: (values: ConfiguracaoRndsFormModel) => Promise<void>
  contraChave: string
}

export function ConfiguracaoRndsCreateView({
  internetHabilitada,
  handleSubmit,
  contraChave,
}: ConfiguracaoRndsCreateViewProps) {
  const [error, setError] = useState()
  const path = metaPath<ConfiguracaoRndsFormModel>()
  const internetMessage = 'Não pode ser habilitada sem conexão com a internet'

  const renderForm = (formProps: FormRenderProps<ConfiguracaoRndsFormModel>) => {
    return (
      <VFlow>
        {error && (
          <Alert type='danger' inline>
            {error}
          </Alert>
        )}

        <Grid alignItems='flex-start'>
          <Cell size={3}>
            <Tooltip text={!internetHabilitada && internetMessage}>
              <RndsCertificadoSelectField
                name={path.certificadoSelect}
                label='Certificado'
                required
                disabled={!internetHabilitada}
              />
            </Tooltip>
          </Cell>
          <Cell size={3}>
            <Tooltip text={!internetHabilitada && internetMessage}>
              <PasswordField name={path.senhaCertificado} label='Senha' required disabled={!internetHabilitada} />
            </Tooltip>
          </Cell>
          <Cell size={3}>
            <Tooltip text={!internetHabilitada && internetMessage}>
              <NumberField
                name={path.idSolicitante}
                label='Identificador solicitante'
                required
                disabled={!internetHabilitada}
                maxLength={20}
              />
            </Tooltip>
          </Cell>
          <Cell size={3} style={{ marginTop: '25px' }}>
            <Tooltip text={!internetHabilitada && internetMessage}>
              <SubmitButton
                handleSubmit={formProps.handleSubmit}
                data-testid='FormFooter.salvar'
                size='small'
                disabled={!internetHabilitada}
              >
                Habilitar
              </SubmitButton>
            </Tooltip>
          </Cell>
        </Grid>
      </VFlow>
    )
  }

  const validator = createValidator<ConfiguracaoRndsFormModel>(
    {
      senhaCertificado: [required, maxLength(255)],
      idSolicitante: [required, maxLength(20)],
    },
    (values, errors: ErrorObject<ConfiguracaoRndsFormModel>) => {
      if (values?.certificadoSelect?.path) {
        errors.certificadoSelect = maxLength(255)(values?.certificadoSelect?.path)
      } else {
        errors.certificadoSelect = required(values?.certificadoSelect?.path)
      }
      return errors
    }
  )

  const innerHandleSubmit = (values: ConfiguracaoRndsFormModel) => {
    setError(null)
    return handleSubmit(values)
  }

  const onSubmitFailed = (formState: FormState<ConfiguracaoRndsFormModel>) => {
    Form.defaultProps.onSubmitFailed(formState)
    setError((state) => formState.submitErrors?.error)
  }

  return (
    <VFlow>
      <InformacaoContraChaveRndsComponent contraChave={contraChave} />
      <Alert type='info' inline>
        <div>
          <b>1.</b>
          {' Faça o download do certificado do município através da área restrita do '}
          <Link href='https://servicos-datasus.saude.gov.br/' target='_blank'>
            e-Gestor AB
          </Link>
          .
        </div>
        <div>
          <b>2.</b>
          {' O administrador da instalação deve incluir o certificado (arquivo .pfx ou .p12) ' +
            'com a chave privada na pasta "chaves" no instalador do PEC (no Windows: c:Program ' +
            'Files/e-SUS/webserver/chaves e no Linux: /opt/e-SUS/webserver/chaves).'}
        </div>

        <div>
          <b>3.</b>
          {' Após incluído no servidor, selecione o certificado no campo ' +
            '"certificado" e insira a senha e o identificador solicitante para habilitar.'}
        </div>
      </Alert>
      <Form<ConfiguracaoRndsFormModel>
        render={renderForm}
        onSubmit={innerHandleSubmit}
        onSubmitFailed={onSubmitFailed}
        validate={validator}
        validateOnBlur={false}
      />
    </VFlow>
  )
}
