import { SelectField, SelectFieldProps } from 'components/form'
import { DoseImunobiologicoVacinacaoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  DoseImunobiologicoVacinacaoSelectFieldQuery,
  DoseImunobiologicoVacinacaoSelectFieldQueryVariables,
  SexoEnum,
} from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type DoseImunobiologicoVacinacaoSelectFieldModel = DoseImunobiologicoVacinacaoSelectFieldQuery['doseImunobiologicoVacinacao']['content'][0]

interface DoseImunobiologicoVacinacaoSelectFieldProps
  extends Omit<SelectFieldProps<DoseImunobiologicoVacinacaoSelectFieldModel>, 'items' | 'itemToString'> {
  sexo?: SexoEnum
  imunobiologicoId?: ID
  estrategiaId?: ID
  isRegistroAnterior?: boolean
  semRegras?: boolean
}

export function DoseImunobiologicoVacinacaoSelectField(props: DoseImunobiologicoVacinacaoSelectFieldProps) {
  const { sexo, imunobiologicoId, estrategiaId, isRegistroAnterior, semRegras, ...rest } = props

  const asyncProps = useAsyncQuerySelect<
    DoseImunobiologicoVacinacaoSelectFieldModel,
    DoseImunobiologicoVacinacaoSelectFieldQuery,
    DoseImunobiologicoVacinacaoSelectFieldQueryVariables
  >({
    query: DoseImunobiologicoVacinacaoSelectFieldDocument,
    extractItems: (data) => data?.doseImunobiologicoVacinacao?.content,
    variables: (inputQuery: string) => ({
      input: {
        query: inputQuery,
        sexo: sexo,
        imunobiologicoId: imunobiologicoId,
        estrategiaId: estrategiaId,
        isRegistroAnterior: isRegistroAnterior,
        semRegras: semRegras,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
        },
      },
    }),
  })

  const itemToString = (item: DoseImunobiologicoVacinacaoSelectFieldModel) => item?.sigla

  return (
    <SelectField<DoseImunobiologicoVacinacaoSelectFieldModel> itemToString={itemToString} {...asyncProps} {...rest} />
  )
}
