import { blue, gray, green, red, yellow } from 'bold-ui/lib/styles/colors'
import {
  Chart,
  ChartBody,
  ChartContainer,
  ChartFooter,
  DateRange,
  getDomainPoints,
  ReferenceArea,
  SeriesType,
  ValueRange,
} from 'components/chart'
import { SexoEnum } from 'graphql/types.generated'
import moment, { Duration, Moment } from 'moment'
import React from 'react'
import { ImcRange, ParametrosImc } from 'util/atendimento'
import { getParametrosParaSemanaGestacional } from 'util/atendimento/gestante/calculateEstadoNutricionalGestante'
import { calculateIdadeGestacionalParaGraficos } from 'util/atendimento/gestante/calculateIdadeGestacional'
import { filterGestacoesPeriodo } from 'util/atendimento/gestante/filterGestacoesPeriodo'
import imcAdulto from 'util/atendimento/parametros/imcAdulto.json'
import imcIdoso from 'util/atendimento/parametros/imcIdoso.json'
import { reduceMedicoesIguais } from 'util/atendimento/reduceMedicoesIguais'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { LinhaTooltipGrafico } from '../../../LinhaTooltipGrafico'
import { PeriodoGestacaoModel } from '../../types/PeriodoGestacaoModel'
import { getReferenceAreasCalculators, ReferenceAreasCalculator } from './referenceAreaCalculators'
import { TickIdade } from './TickIdade'

export interface GraficoImcProps {
  medicoes: HistoricoMedicaoModel[]
  dataNascimento: Moment
  dataRange: DateRange
  sexo: SexoEnum
  gestacoes: PeriodoGestacaoModel[]
}

export function GraficoImcView(props: GraficoImcProps) {
  const { medicoes, dataNascimento, dataRange, sexo, gestacoes } = props

  const maxIdadeNoRange = moment.duration(dataRange.end.diff(dataNascimento))
  const medicoesComImc = reduceMedicoesIguais(medicoes, 'valorImc') ?? []
  const seriesData = medicoesComImc.map((m) => ({
    x: m.dataMedicao,
    y: m.valorImc,
  }))
  const yRange = getYRange(maxIdadeNoRange)
  const gestacoesPeriodo = filterGestacoesPeriodo(gestacoes, dataRange.init, dataRange.end)

  return (
    <ChartContainer>
      <ChartBody height={500}>
        <Chart<Moment>
          type={SeriesType.Area}
          series={[{ name: 'IMC', data: seriesData }]}
          referenceAreas={getReferenceAreas(sexo, dataRange, yRange, maxIdadeNoRange, dataNascimento, gestacoesPeriodo)}
          xAxis={{
            title: 'Idade (meses completos e anos)',
            domain: dataRange,
            tickRenderer: (props) => <TickIdade {...props} dataNascimento={dataNascimento} />,
          }}
          rangeAreas={gestacoesPeriodo.map((g) => ({
            name: 'Gestação',
            init: moment(g.inicio).startOf('day'),
            end: moment(g.fim ?? moment(g.inicio).add(42, 'week')).endOf('day'),
            fillColor: 'none',
            tickColor: blue.c60,
            strokeColor: gray.c40,
          }))}
          yAxis={{ title: 'IMC', unit: 'kg/m²', domain: yRange }}
          showLegend={false}
          tooltip={{
            type: 'point',
            render: (points) => {
              if (!points || !points.length) return
              const momentX = moment(points[0].x)
              const { idadeGestacional, hasGestacaoPeriodo } = calculateIdadeGestacionalParaGraficos(gestacoes, momentX)
              return (
                <>
                  {points?.map((p) => (
                    <LinhaTooltipGrafico key={p.seriesName}>{`IMC: ${p.y} kg/m²`}</LinhaTooltipGrafico>
                  ))}
                  {hasGestacaoPeriodo && (
                    <LinhaTooltipGrafico>
                      {`IG: ${idadeGestacional} ${idadeGestacional === 1 ? 'semana' : 'semanas'}`}
                    </LinhaTooltipGrafico>
                  )}
                  <LinhaTooltipGrafico>{`Data: ${momentX.format('DD/MM/YYYY')}`}</LinhaTooltipGrafico>
                </>
              )
            },
          }}
        />
      </ChartBody>
      <ChartFooter>{getFooterText(sexo, maxIdadeNoRange, !!gestacoesPeriodo.length)}</ChartFooter>
    </ChartContainer>
  )
}

function getFooterText(sexo: SexoEnum, maxIdadeNoRange: Duration, hasGestacoesPeriodo: boolean): string {
  if (maxIdadeNoRange.years() <= 10) {
    if (sexo === SexoEnum.MASCULINO) return 'Fonte: Caderneta de Saúde da Criança Menino - 2020.'
    else if (sexo === SexoEnum.FEMININO) return `Fonte: Caderneta de Saúde da Criança Menina - 2020.`
    else return ''
  } else if (maxIdadeNoRange.years() <= 19) {
    if (sexo === SexoEnum.MASCULINO) return 'Fonte: Caderneta de Saúde do Adolescente - 2013.'
    else if (sexo === SexoEnum.FEMININO)
      return `Fonte: Caderneta de Saúde da Adolescente - 2013
      ${hasGestacoesPeriodo ? 'e Caderneta da Gestante - 2018' : ''}.`
  }
  return hasGestacoesPeriodo ? 'Fonte: Caderneta da Gestante - 2018.' : ''
}

function getYRange(maxIdadeNoRange: Duration): ValueRange {
  if (maxIdadeNoRange.years() <= 2) return { init: 5, end: 25, step: 1 }
  else if (maxIdadeNoRange.years() <= 5) return { init: 10, end: 25, step: 1 }
  else if (maxIdadeNoRange.years() <= 10) return { init: 10, end: 30, step: 1 }
  else if (maxIdadeNoRange.years() <= 19) return { init: 10, end: 40, step: 2 }
  else return { init: 10, end: 40, step: 2 }
}

function getReferenceAreas(
  sexo: SexoEnum,
  xRange: DateRange,
  yRange: ValueRange,
  idadeNoRange: Duration,
  dataNascimento: Moment,
  gestacoesNoRange: PeriodoGestacaoModel[]
): ReferenceArea<Moment>[] {
  if (idadeNoRange.years() <= 19) {
    const refAreaCalculators = getReferenceAreasCalculators(sexo, dataNascimento, xRange, 'imc')
    return [
      getReferenceAreaCriancaMagrezaAcentuada(refAreaCalculators, idadeNoRange, gestacoesNoRange),
      getReferenceAreaCriancaMagreza(refAreaCalculators, idadeNoRange, gestacoesNoRange),
      getReferenceAreaCriancaEutrofia(refAreaCalculators, idadeNoRange, gestacoesNoRange),
      getReferenceAreaCriancaSobrepeso(refAreaCalculators, idadeNoRange, gestacoesNoRange),
      getReferenceAreaCriancaObesidade(refAreaCalculators, idadeNoRange, gestacoesNoRange),
      getReferenceAreaCriancaObesidadeGrave(refAreaCalculators, idadeNoRange, gestacoesNoRange),
    ]
  } else if (idadeNoRange.years() <= 60) {
    return [
      getReferenceAreaAdultoBaixoPeso(xRange, gestacoesNoRange),
      getReferenceAreaAdultoAdequado(xRange, gestacoesNoRange),
      getReferenceAreaAdultoSobrepeso(xRange, gestacoesNoRange),
      getReferenceAreaAdultoObesidade(xRange, gestacoesNoRange),
    ]
  } else {
    return [
      getReferenceAreaIdosoBaixoPeso(xRange, gestacoesNoRange),
      getReferenceAreaIdosoAdequado(xRange, gestacoesNoRange),
      getReferenceAreaIdosoSobrepeso(xRange, gestacoesNoRange),
    ]
  }
}

function getReferenceAreaIdosoBaixoPeso(xRange: DateRange, gestacoesNoRange: PeriodoGestacaoModel[]) {
  return {
    area: getDomainPoints<Moment>(xRange).map((date) => ({
      x: date,
      upperLimit:
        getReferenceAreaUpperLimitGestanteIfInGestacao(date, gestacoesNoRange, 'baixo') ??
        getReferenceAreaUpperLimitIdoso('baixo'),
    })),
    name: 'Baixo peso',
    color: red.c90,
    strokeColor: red.c60,
    tickColor: red.c60,
  }
}

function getReferenceAreaIdosoAdequado(xRange: DateRange, gestacoesNoRange: PeriodoGestacaoModel[]) {
  return {
    area: getDomainPoints<Moment>(xRange).map((date) => ({
      x: date,
      upperLimit:
        getReferenceAreaUpperLimitGestanteIfInGestacao(date, gestacoesNoRange, 'adequado') ??
        getReferenceAreaUpperLimitIdoso('adequado'),
    })),
    name: 'Adequado ou Eutrófico',
    color: green.c90,
    strokeColor: red.c60,
    tickColor: green.c60,
  }
}

function getReferenceAreaIdosoSobrepeso(xRange: DateRange, gestacoesNoRange: PeriodoGestacaoModel[]) {
  return {
    area: getDomainPoints<Moment>(xRange).map((date) => ({
      x: date,
      upperLimit:
        getReferenceAreaUpperLimitGestanteIfInGestacao(date, gestacoesNoRange, 'sobrepeso') ??
        getReferenceAreaUpperLimitIdoso('sobrepeso'),
    })),
    name: 'Sobrepeso',
    color: red.c90,
    stroke: false,
    tickColor: red.c60,
  }
}

function getReferenceAreaAdultoBaixoPeso(xRange: DateRange, gestacoesNoRange: PeriodoGestacaoModel[]) {
  return {
    area: getDomainPoints<Moment>(xRange).map((date) => ({
      x: date,
      upperLimit:
        getReferenceAreaUpperLimitGestanteIfInGestacao(date, gestacoesNoRange, 'baixo') ??
        getReferenceAreaUpperLimitAdulto('baixo'),
    })),
    name: 'Baixo peso',
    color: red.c90,
    strokeColor: red.c60,
    tickColor: red.c60,
  }
}

function getReferenceAreaAdultoAdequado(xRange: DateRange, gestacoesNoRange: PeriodoGestacaoModel[]) {
  return {
    area: getDomainPoints<Moment>(xRange).map((date) => ({
      x: date,
      upperLimit:
        getReferenceAreaUpperLimitGestanteIfInGestacao(date, gestacoesNoRange, 'adequado') ??
        getReferenceAreaUpperLimitAdulto('adequado'),
    })),
    name: 'Adequado ou Eutrófico',
    color: green.c90,
    strokeColor: yellow.c60,
    tickColor: green.c60,
  }
}

function getReferenceAreaAdultoSobrepeso(xRange: DateRange, gestacoesNoRange: PeriodoGestacaoModel[]) {
  return {
    area: getDomainPoints<Moment>(xRange).map((date) => ({
      x: date,
      upperLimit:
        getReferenceAreaUpperLimitGestanteIfInGestacao(date, gestacoesNoRange, 'sobrepeso') ??
        getReferenceAreaUpperLimitAdulto('sobrepeso'),
    })),
    name: 'Sobrepeso',
    color: yellow.c90,
    strokeColor: red.c60,
    tickColor: yellow.c60,
  }
}

function getReferenceAreaAdultoObesidade(xRange: DateRange, gestacoesNoRange: PeriodoGestacaoModel[]) {
  return {
    area: getDomainPoints<Moment>(xRange).map((date) => ({
      x: date,
      upperLimit:
        getReferenceAreaUpperLimitGestanteIfInGestacao(date, gestacoesNoRange, 'obesidade') ??
        getReferenceAreaUpperLimitAdulto('obesidade'),
    })),
    name: 'Obesidade',
    color: red.c90,
    stroke: false,
    tickColor: red.c60,
  }
}

function getReferenceAreaCriancaMagrezaAcentuada(
  getsPercentis: ReferenceAreasCalculator[],
  idadeNoRange: Duration,
  gestacoesNoRange: PeriodoGestacaoModel[]
): ReferenceArea<Moment> {
  return {
    area: getsPercentis.map(({ date, referenceAreaCalculator }) => ({
      x: date,
      upperLimit:
        (idadeNoRange.years() > 10
          ? getReferenceAreaUpperLimitGestanteIfInGestacao(date, gestacoesNoRange, 'muitoBaixo')
          : null) ?? referenceAreaCalculator(-3),
    })),
    name: 'Magreza acentuada',
    color: gray.c90,
    strokeColor: gray.c40,
    tickColor: gray.c40,
  }
}

function getReferenceAreaCriancaMagreza(
  getsPercentis: ReferenceAreasCalculator[],
  idadeNoRange: Duration,
  gestacoesNoRange: PeriodoGestacaoModel[]
): ReferenceArea<Moment> {
  return {
    area: getsPercentis.map(({ date, referenceAreaCalculator }) => ({
      x: date,
      upperLimit:
        (idadeNoRange.years() > 10
          ? getReferenceAreaUpperLimitGestanteIfInGestacao(date, gestacoesNoRange, 'baixo')
          : null) ?? referenceAreaCalculator(-2),
    })),
    name: 'Magreza',
    color: red.c90,
    strokeColor: red.c60,
    tickColor: red.c60,
  }
}

function getReferenceAreaCriancaEutrofia(
  getsPercentis: ReferenceAreasCalculator[],
  idadeNoRange: Duration,
  gestacoesNoRange: PeriodoGestacaoModel[]
): ReferenceArea<Moment> {
  return {
    area: getsPercentis.map(({ date, referenceAreaCalculator }) => ({
      x: date,
      upperLimit:
        (idadeNoRange.years() > 10
          ? getReferenceAreaUpperLimitGestanteIfInGestacao(date, gestacoesNoRange, 'adequado')
          : null) ?? referenceAreaCalculator(1),
    })),
    name: 'Eutrofia',
    color: green.c90,
    strokeColor: yellow.c60,
    tickColor: green.c60,
  }
}

function getReferenceAreaCriancaSobrepeso(
  getsPercentis: ReferenceAreasCalculator[],
  idadeNoRange: Duration,
  gestacoesNoRange: PeriodoGestacaoModel[]
): ReferenceArea<Moment> {
  return {
    area: getsPercentis.map(({ date, referenceAreaCalculator }) => ({
      x: date,
      upperLimit:
        (idadeNoRange.years() > 10
          ? getReferenceAreaUpperLimitGestanteIfInGestacao(date, gestacoesNoRange, 'sobrepeso')
          : null) || referenceAreaCalculator(2),
    })),
    name: idadeNoRange.years() <= 5 ? 'Risco de sobrepeso' : 'Sobrepeso',
    color: yellow.c90,
    strokeColor: red.c60,
    tickColor: yellow.c60,
  }
}

function getReferenceAreaCriancaObesidade(
  getsPercentis: ReferenceAreasCalculator[],
  idadeNoRange: Duration,
  gestacoesNoRange: PeriodoGestacaoModel[]
): ReferenceArea<Moment> {
  return {
    area: getsPercentis.map(({ date, referenceAreaCalculator }) => ({
      x: date,
      upperLimit:
        (idadeNoRange.years() > 10
          ? getReferenceAreaUpperLimitGestanteIfInGestacao(date, gestacoesNoRange, 'obesidade')
          : null) ?? referenceAreaCalculator(3),
    })),
    name: idadeNoRange.years() <= 5 ? 'Sobrepeso' : 'Obesidade',
    color: red.c90,
    strokeColor: gray.c40,
    tickColor: red.c60,
  }
}

function getReferenceAreaCriancaObesidadeGrave(
  getsPercentis: ReferenceAreasCalculator[],
  idadeNoRange: Duration,
  gestacoesNoRange: PeriodoGestacaoModel[]
): ReferenceArea<Moment> {
  return {
    area: getsPercentis.map(({ date }) => ({
      x: date,
      upperLimit:
        idadeNoRange.years() > 10
          ? getReferenceAreaUpperLimitGestanteIfInGestacao(date, gestacoesNoRange, 'muitoAlto')
          : undefined,
    })),
    name: idadeNoRange.years() <= 5 ? 'Obesidade' : 'Obesidade grave',
    color: gray.c90,
    stroke: false,
    tickColor: gray.c40,
  }
}

function getSemanaGestacional(gestacao: PeriodoGestacaoModel, date: Moment) {
  return Math.min(Math.round(date.diff(moment(gestacao.inicio), 'week')), 42)
}

function getReferenceAreaUpperLimitGestanteIfInGestacao(
  date: Moment,
  gestacoes: PeriodoGestacaoModel[],
  type: keyof ParametrosImc | 'muitoBaixo' | 'muitoAlto'
): number | 'yInit' | 'yEnd' {
  const momentDate = moment(date)
  const gestacao = gestacoes.find((g) => momentDate.isBetween(g.inicio, g.fim ?? moment(g.inicio).add(42, 'week')))
  if (!gestacao) return
  const semanaGestacional = getSemanaGestacional(gestacao, momentDate)
  if (semanaGestacional >= 6 && type === 'muitoBaixo') return 'yInit'
  if (semanaGestacional >= 6 && type === 'muitoAlto') return 'yEnd'
  const range = getParametrosParaSemanaGestacional(semanaGestacional)?.[type]
  return range ? range.maximo ?? 'yEnd' : undefined
}

const getReferenceAreaUpperLimitAdulto = (type: keyof ParametrosImc) => (imcAdulto[type] as ImcRange)?.maximo

const getReferenceAreaUpperLimitIdoso = (type: keyof ParametrosImc) => (imcIdoso[type] as ImcRange)?.maximo
