/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Icon, VFlow } from 'bold-ui'
import { AlergiaSelectFieldModel } from 'components/form/field/select/AlergiaSelectField/AlergiaSelectField'
import { useState } from 'react'
import {
  AlergiasReacoesForm,
  AlergiasReacoesProps,
} from 'view/atendimentos/detail/soap/avaliacao/components/alergias-reacoes/AlergiasReacoesForm'

export interface CadastroAlergiasModalProps
  extends Pick<AlergiasReacoesProps, 'formValues' | 'cidadao' | 'dataAtendimento' | 'onSubmit'> {
  alergiasCidadao: AlergiaSelectFieldModel[]
}

export function CadastroAlergiasModal(props: CadastroAlergiasModalProps) {
  const { alergiasCidadao } = props
  const [showForm, setShowForm] = useState<boolean>(false)

  const classes = createStyles()

  return (
    <VFlow>
      <Button
        kind='primary'
        onClick={() => {
          setShowForm(!showForm)
        }}
      >
        <HFlow>
          <Icon icon={showForm ? 'angleUp' : 'angleDown'} />
          {showForm ? 'Cancelar registro' : 'Registrar alergia/reação adversa'}
        </HFlow>
      </Button>
      {showForm && (
        <div css={classes.form}>
          <AlergiasReacoesForm
            isModal
            alergiasCidadao={alergiasCidadao}
            initialValues={{ _id: null, isAvaliacao: false }}
            buttonTexts={{ cancel: 'Limpar Campos', submit: 'Registrar' }}
            {...props}
          />
        </div>
      )}
    </VFlow>
  )
}

const createStyles = () => ({
  form: css`
    margin-left: -1rem;
  `,
})
