/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Checkbox, Grid, HeadingSection, Text, Theme, useTheme } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import { Fragment } from 'react'
import { isCboMedicoOuCirurgiaoDentista } from 'util/atendimento/cboMedicoOuCirurgiaoDentista'
import { MetaPath } from 'util/metaPath'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { AtendimentoProfissional } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import EncaminhamentoExternoForm from '../EncaminhamentoExternoForm'
import EncaminhamentoExternoTable from '../list/EncaminhamentoExternoTable'
import { EncaminhamentoExternoPlanoModel } from '../model'

export interface EncaminhamentoExternoViewProps {
  name: MetaPath<EncaminhamentoExternoPlanoModel>
  prontuarioId: ID
  atendimentoId: ID
  cidadao: CidadaoAtendimento
  cboAtendProf: string
  atendimentoProfissional: AtendimentoProfissional
  isAtendimentoOdonto: boolean
}

export default function EncaminhamentoExternoView(props: EncaminhamentoExternoViewProps) {
  const {
    name,
    prontuarioId,
    atendimentoId,
    cidadao,
    cboAtendProf,
    atendimentoProfissional,
    isAtendimentoOdonto,
  } = props
  const { handleSubmit, removeItem, input } = useEditableListField({
    name: name.encaminhamentosRecentes,
  })
  const renderCiapForm = !isCboMedicoOuCirurgiaoDentista(cboAtendProf)

  const theme = useTheme()
  const classes = createStyles(theme)

  const { ENCAMINHAMENTOS_ATENDIMENTO_ENABLED } = useFlags()

  return (
    <Fragment>
      {ENCAMINHAMENTOS_ATENDIMENTO_ENABLED && (
        <Fragment>
          <Text>Encaminhamentos</Text>
          <Grid gap={1} gapVertical={0.1}>
            <Cell style={classes.cell} size={4}>
              <Checkbox style={classes.checkbox} label='CAPS' />
            </Cell>
            <Cell style={classes.cell} size={4}>
              <Checkbox style={classes.checkbox} label='Urgência' />
            </Cell>
            <Cell style={classes.cell} size={4}>
              <Checkbox style={classes.checkbox} label='Internação hospitalar' />
            </Cell>
            <Cell style={classes.cell} size={4}>
              <Checkbox style={classes.checkbox} label='Serviço de atenção domiciliar ' />
            </Cell>
            <Cell style={classes.cell} size={4}>
              <Checkbox style={classes.checkbox} label='Intersetorial' />
            </Cell>
            <Cell style={classes.cell} size={4}>
              <Checkbox style={classes.checkbox} label='Serviço especializado' />
            </Cell>
          </Grid>
        </Fragment>
      )}

      <HeadingSection level={4} title='Encaminhamentos para especialistas' vSpace={8} />
      <EncaminhamentoExternoForm
        prontuarioId={prontuarioId}
        onSubmit={handleSubmit}
        isAtendimentoOdonto={isAtendimentoOdonto}
        cidadao={cidadao}
        renderCiap={renderCiapForm}
        encaminhamentosCache={input.value}
      />
      <EncaminhamentoExternoTable
        prontuarioId={prontuarioId}
        atendimentoId={atendimentoId}
        encaminhamentosCache={input.value}
        dataReferencia={atendimentoProfissional.iniciadoEm}
        lotacao={atendimentoProfissional.lotacao}
        atendimentoProfissionalId={atendimentoProfissional.id}
        removeItem={removeItem}
      />
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  checkbox: css`
    width: 100%;
    height: 100%;
    border: 1px solid ${theme.pallete.divider};
    font-weight: bold;
    padding: 0.4rem 1.5rem;
  `,

  cell: css`
    padding: 0.3rem 0 0.3rem 1rem;
  `,
})
