import { ExternalStyles, focusBoxShadow, Theme, useStyles } from 'bold-ui'
import { css } from 'emotion'
import React, { CSSProperties } from 'react'
import { AccordionItemButton } from 'react-accessible-accordion'

export interface AccordionButtonProps {
  children?: React.ReactNode
  style?: ExternalStyles
  wrapperStyle?: ExternalStyles
  stopPropagation?: boolean
  alwaysOpen?: boolean
}

export function AccordionButton(props: AccordionButtonProps) {
  const { children, wrapperStyle, style, stopPropagation, alwaysOpen } = props
  const { classes } = useStyles(createStyles, alwaysOpen)

  return (
    <div
      className={css({ paddingBottom: '0.3rem' }, wrapperStyle)}
      onClick={stopPropagation && ((e) => e.stopPropagation())}
    >
      <AccordionItemButton className={css(classes.content, style)}>{children}</AccordionItemButton>
    </div>
  )
}

const createStyles = (theme: Theme, alwaysOpen: boolean) => ({
  content: {
    cursor: !alwaysOpen ? 'pointer' : 'default',
    borderRadius: `${theme.radius.input}px`,
    margin: '0.3rem',
    '&:hover': {
      borderColor: theme.pallete.gray.c50,
      backgroundColor: theme.pallete.surface.background,
    },
    '&:focus': !alwaysOpen && {
      outline: 'none',
      boxShadow: focusBoxShadow(theme, 'primary'),
      transitionProperty: 'box-shadow',
      transitionDuration: '.2s',
    },
    '&[aria-expanded="true"] div:first-child > svg': {
      transform: 'rotate(180deg)',
      transition: '0.4s',
    },
    '&[aria-expanded="false"] div:first-child > svg': {
      transform: 'rotate(0deg)',
      transition: '0.4s',
    },
  } as CSSProperties,
})
