import { useServerTime } from 'hooks/useServerTime'
import React, { useState } from 'react'
import { EventoAgendaLotacao } from 'view/agenda/model'
import { JustificativaVisualizarProntuarioModal } from 'view/prontuario/justificativa/modal'

import { CancelarAgendamentoButton } from './buttons/CancelarAgendamentoButton'
import { InformarFaltaCidadaoButton } from './buttons/InformarFaltaCidadaoButton'
import { InserirListaAtendimentoButton } from './buttons/InserirListaAtendimentoButton'
import { RemoverFaltaCidadaoButton } from './buttons/RemoverFaltaCidadaoButton'
import { RemoverListaAtendimentoButton } from './buttons/RemoverListaAtendimentoButton'
import { VisualizarProntuarioButton } from './buttons/VisualizarProntuarioButton'
import { getStatusAcoesConsulta } from './getStatusAcoesConsulta'
import { getStatusAcoesConsultaAD } from './getStatusAcoesConsultaAD'
import JustificativaCancelarAgendamentoModal from './JustificativaCancelarAgendamentoModal'
import MenuAcoesAgendamento from './MenuAcoesAgendamento'
import { AcaoAgendamentoButtonProps, AcaoAgendamentoEnum } from './model'

export interface AcoesAgendamentoConsultaProps {
  event: EventoAgendaLotacao
  isDropdown: boolean
}

export function AcoesAgendamentoConsulta(props: AcoesAgendamentoConsultaProps) {
  const { event, isDropdown } = props
  const { getServerTimeNow } = useServerTime()

  const [isModalProntuarioOpen, setIsModalProntuarioOpen] = useState(false)
  const [isModalJustificativaOpen, setModalJustificativaOpen] = useState(false)

  const statusAcoes = event.isAtencaoDomiciliar
    ? getStatusAcoesConsultaAD({ dataConsulta: event.start, dataAtual: getServerTimeNow() })
    : getStatusAcoesConsulta({
        situacao: event.situacao,
        situacaoAtendimento: event.atendimento?.situacao,
        possuiAtendimentoPrevio: event.atendimento?.possuiAtendimentosProfissionais,
        isForaUbs: event.isForaUbs,
        dataAtual: getServerTimeNow(),
        dataConsulta: new Date(event.start),
      })
  const statusVisualizarProntuario = statusAcoes[AcaoAgendamentoEnum.VISUALIZAR_PRONTUARIO]
  const statusCancelarAgendamento = statusAcoes[AcaoAgendamentoEnum.CANCELAR_AGENDAMENTO]

  return (
    <>
      <MenuAcoesAgendamento isDropdown={isDropdown}>
        {statusVisualizarProntuario.visible && (
          <VisualizarProntuarioButton
            {...props}
            disabled={statusVisualizarProntuario.disabled}
            tooltip={statusVisualizarProntuario.tooltip}
            isDropdown={isDropdown}
            onClick={() => setIsModalProntuarioOpen(true)}
          />
        )}
        {Object.keys(AcaoAgendamentoEnum).map(
          (acao) =>
            statusAcoes[acao].visible &&
            recordBotoesAcoes[acao] &&
            React.createElement(recordBotoesAcoes[acao], {
              ...props,
              key: acao,
              disabled: statusAcoes[acao].disabled,
              tooltip: statusAcoes[acao].tooltip,
              isDropdown,
            })
        )}
        <CancelarAgendamentoButton
          {...props}
          openModalCancelarAgendamento={() => setModalJustificativaOpen(true)}
          disabled={statusCancelarAgendamento.disabled}
          tooltip={statusCancelarAgendamento.tooltip}
        />
      </MenuAcoesAgendamento>
      <JustificativaCancelarAgendamentoModal
        event={event}
        open={isModalJustificativaOpen}
        setOpen={setModalJustificativaOpen}
      />
      <JustificativaVisualizarProntuarioModal
        prontuarioId={event.prontuarioId}
        cidadaoId={event.cidadaoId}
        isModalOpen={isModalProntuarioOpen}
        onReject={() => setIsModalProntuarioOpen(false)}
      />
    </>
  )
}

const recordBotoesAcoes: Partial<Record<AcaoAgendamentoEnum, React.ComponentType<AcaoAgendamentoButtonProps>>> = {
  INSERIR_LISTA_ATENDIMENTO: InserirListaAtendimentoButton,
  REMOVER_LISTA_ATENDIMENTO: RemoverListaAtendimentoButton,
  INFORMAR_FALTA_CIDADAO: InformarFaltaCidadaoButton,
  REMOVER_FALTA_CIDADAO: RemoverFaltaCidadaoButton,
}
