/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Cell,
  CellProps,
  CellSize,
  Grid,
  HFlow,
  SortableLabel,
  Spinner,
  Text,
  Theme,
  Tooltip,
  useTheme,
  VFlow,
} from 'bold-ui'
import { DataTable, DataTableProps, TableColumnConfig } from 'bold-ui/lib/components/Table/DataTable/DataTable'
import { Fragment, useMemo } from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

import { AccordionControls } from '../useAccordionControl'
import { AccordionDataTableRowDefault, AccordionDataTableRowDefaultProps } from './AccordionDataTableRowDefault'

// grid system
export const ACCORDION_DATA_TABLE_MAX_COLUMNS = 12

export interface TableAccordionColumnConfig<T> extends Omit<TableColumnConfig<T>, 'align' | 'style'>, CellProps {}

export interface AccordionDataTableProps<T>
  extends Omit<DataTableProps<T>, 'columns' | 'render' | 'hovered' | 'onClickRow'>,
    Partial<Pick<AccordionControls, 'isExpanded' | 'handleAccordionItemClick'>> {
  columns: TableAccordionColumnConfig<T>[]

  components: {
    AccordionPanel: React.ComponentType<{ row: T }>
    Row?: React.ComponentType<AccordionDataTableRowDefaultProps<T>>
  }

  disableRow?(row: T): boolean
  disableRowMessage?: string
}

export function AccordionDataTable<T>(props: AccordionDataTableProps<T>) {
  const {
    columns,
    disableRow,
    disableRowMessage,
    components: { AccordionPanel, Row = AccordionDataTableRowDefault },
    isExpanded,
    handleAccordionItemClick,
    ...tableProps
  } = props

  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  const isEmpty = !tableProps?.rows?.length

  return (
    <DataTable
      {...tableProps}
      columns={columns as TableColumnConfig<T>[]}
      render={({ rows, loading, getHeaderProps }) => (
        <div css={classes.table}>
          <VFlow vSpacing={0}>
            <Grid gap={0} gapVertical={0} alignItems='center' wrap={false} style={classes.header}>
              {columns.map((column, index) => {
                const { header, size, style, render, sortable, ...cellProps } = column
                const { sortDirection, onSortChange } = getHeaderProps(column as TableColumnConfig<T>)

                return (
                  <Cell
                    key={index}
                    style={[classes.headerCell, style]}
                    size={size || ((ACCORDION_DATA_TABLE_MAX_COLUMNS / columns.length) as CellSize)}
                    {...cellProps}
                  >
                    {sortable ? (
                      <SortableLabel direction={sortDirection} onChange={onSortChange}>
                        <Text fontWeight='bold'>{header}</Text>
                      </SortableLabel>
                    ) : (
                      <Text fontWeight='bold'>{header}</Text>
                    )}
                  </Cell>
                )
              })}
            </Grid>

            {loading && <AccordionDataTableLoadingRow />}

            {!loading && isEmpty && <AccordionDataTablePlaceholderRow />}

            {!loading && !isEmpty && (
              <Accordion allowZeroExpanded css={classes.accordion}>
                {rows.map((row, index) =>
                  disableRow?.(row) ? (
                    <Tooltip placement='bottom-start' text={disableRowMessage} key={index}>
                      <div css={classes.row}>
                        <Row {...{ row, columns, disabled: true }} />
                      </div>
                    </Tooltip>
                  ) : (
                    <AccordionItem
                      uuid={index.toString()}
                      key={index}
                      dangerouslySetExpanded={isExpanded?.(index.toString())}
                    >
                      <AccordionItemState>
                        {(state) => (
                          <Fragment>
                            <Tooltip placement='bottom-start' text={state.expanded ? 'Minimizar' : 'Expandir'}>
                              <AccordionItemHeading onClick={() => handleAccordionItemClick?.(index.toString())}>
                                <AccordionItemButton css={[classes.accordionItemButton, classes.row]}>
                                  <Row {...{ row, columns }} />
                                </AccordionItemButton>
                              </AccordionItemHeading>
                            </Tooltip>

                            <AccordionItemPanel css={classes.accordionItemPanel}>
                              <AccordionPanel row={row} />
                            </AccordionItemPanel>
                          </Fragment>
                        )}
                      </AccordionItemState>
                    </AccordionItem>
                  )
                )}
              </Accordion>
            )}
          </VFlow>
        </div>
      )}
    />
  )
}

export function AccordionDataTableLoadingRow() {
  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  return (
    <HFlow hSpacing={0.5} justifyContent='center' alignItems='center' style={classes.loadingRow}>
      <Spinner
        style={css`
          color: ${theme.pallete.primary.main};
        `}
      />

      <Text color='primary' fontWeight='bold'>
        Carregando resultados
      </Text>
    </HFlow>
  )
}

export function AccordionDataTablePlaceholderRow() {
  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  return <div css={classes.noResultPlaceholder}>Nenhum registro encontrado</div>
}

const styles = (theme: Theme) => ({
  table: css`
    border-radius: 2px;
    border: 1px solid ${theme.pallete.divider};
  `,
  header: css`
    padding-left: 4rem;
    border-bottom: 1px solid ${theme.pallete.divider};
  `,
  headerCell: css`
    padding: 0.5rem 1rem;
    overflow: hidden;
    &:first-of-type {
      display: flex;
      padding-left: 0;
      overflow: visible;
      &:before {
        content: '';
        margin-left: -3rem;
      }
    }
    ${theme.breakpoints.down('lg')} {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
  `,
  row: css`
    &:hover {
      background: ${theme.pallete.surface.background};
    }
  `,
  accordion: css`
    & > div {
      border-bottom: 1px solid ${theme.pallete.divider};
      &:last-child {
        border-bottom: 0;
      }
    }
  `,
  accordionItemButton: css`
    cursor: pointer;
    outline: 0;
    &[aria-expanded='true'] div:first-of-type > svg[name='accordionAngleIcon'] {
      transform: rotate(180deg);
      transition: 0.4s;
    }
    &[aria-expanded='false'] div:first-of-type > svg[name='accordionAngleIcon'] {
      transform: rotate(0deg);
      transition: 0.4s;
    }
  `,
  accordionItemPanel: css`
    border-top: 1px solid ${theme.pallete.divider};
  `,
  loadingRow: css`
    height: 2.5rem;
    background: ${theme.pallete.surface.background};
  `,
  noResultPlaceholder: css`
    color: ${theme.pallete.text.secondary};
    font-style: italic;
    text-align: center;
    padding: 0.75rem 1rem;
  `,
})
