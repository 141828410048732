/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, PagedTable, Text, Theme } from 'bold-ui'
import { blue } from 'bold-ui/lib/styles/colors'
import useSession from 'components/auth/useSession'
import { DateTime } from 'components/date'
import { PrivateRoute } from 'components/route'
import { TableBox, usePagedTableProps } from 'components/table'
import theme from 'config/theme'
import { useEncaminhamentosExternosQuery } from 'graphql/hooks.generated'
import {
  ClassificacaoRiscoEncaminhamentoEnum,
  EncaminhamentoExternoQueryInput,
  EncaminhamentosExternosQuery,
} from 'graphql/types.generated'
import { noop } from 'lodash'
import { Fragment, useState } from 'react'
import { FormSpy, FormSpyRenderProps } from 'react-final-form'
import { useRouteMatch } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { LotacaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { grupoCboEncaminhamentoExterno } from '../../acessos'
import { downloadEncaminhamentoExterno } from '../impressao/DownloadEncaminhamentoExterno'
import { EncaminhamentoExternoModel, EncaminhamentoExternoPlanoModel } from '../model'
import { mergeEncaminhamentos } from '../util/mergeEncaminhamentos'
import { EncaminhamentoExternoTableButtons } from './EncaminhamentoExternoTableButtons'
import { EncaminhamentoExternoFilter } from './EncaminhamentoExternoTableHeader'
import EncaminhamentoExternoVisualizacaoModal from './EncaminhamentoExternoVisualizacaoModal'
import { StatusClassificacaoRiscoEncaminhamento } from './StatusClassificacaoRiscoEncaminhamento'

export type EncaminhamentosModel = EncaminhamentosExternosQuery['encaminhamentos']
export type EncaminhamentoExternoTableRowModel = EncaminhamentosExternosQuery['encaminhamentos']['content'][0]

export interface EncaminhamentoExternoTableProps {
  atendimentoId: ID
  prontuarioId: ID
  dataReferencia: Instant
  lotacao: LotacaoAtendimento | LotacaoFolhaRosto
  encaminhamentosCache?: EncaminhamentoExternoPlanoModel[]
  removeItem?(itemToRemove: EncaminhamentoExternoModel): void
  readOnly?: boolean
  atendimentoProfissionalId?: ID
}

export interface EncaminhamentoExternoRowModel {
  id: ID
  classificacaoRiscoEncaminhamento: ClassificacaoRiscoEncaminhamentoEnum
  atendimentoProfissional: {
    id: ID
    iniciadoEm: Instant
    lotacao: LotacaoAtendimento | LotacaoFolhaRosto
  }
  especialidadeSisreg: {
    id: ID
    descricao: string
  }
  hipoteseDiagnostica: {
    id: ID
    nome: string
  }
  isRegistradoAgora: boolean
}

export default function EncaminhamentoExternoTable(props: EncaminhamentoExternoTableProps) {
  const {
    atendimentoId,
    prontuarioId,
    dataReferencia,
    lotacao,
    atendimentoProfissionalId,
    encaminhamentosCache = [],
    removeItem = noop,
    readOnly,
  } = props

  const match = useRouteMatch()

  const [tableState, setTableState] = useState<EncaminhamentoExternoQueryInput>({
    prontuarioId,
    pageParams: { page: 0, size: 5 },
  })

  const {
    data: { profissional },
  } = useSession()

  const {
    data: { encaminhamentos },
  } = useEncaminhamentosExternosQuery({
    variables: {
      input: {
        prontuarioId,
        query: tableState.query,
        somenteMeusEncaminhamentosExternos: tableState.somenteMeusEncaminhamentosExternos,
        especialidadeId: tableState.especialidadeId,
        periodo: tableState.periodo,
      },
    },
  })

  const renderClassificacaoAndData = (row: EncaminhamentoExternoRowModel) => {
    return (
      <HFlow>
        <StatusClassificacaoRiscoEncaminhamento status={row?.classificacaoRiscoEncaminhamento} />
        <DateTime value={row?.atendimentoProfissional?.iniciadoEm} format='DD/MM/YYYY' />
      </HFlow>
    )
  }

  const renderEspecialidade = (row: EncaminhamentoExternoRowModel) => <Text>{row?.especialidadeSisreg?.descricao}</Text>

  const renderHipoteseDiagnostica = (row: EncaminhamentoExternoRowModel) => (
    <Text>{row?.hipoteseDiagnostica?.nome}</Text>
  )

  const renderStatus = (row: EncaminhamentoExternoRowModel) => {
    return (
      row.isRegistradoAgora && (
        <HFlow alignItems='center' justifyContent='flex-end' hSpacing={0.5}>
          <Icon color={blue.c40} size={1} icon='clockOutline' />
          <span css={classes.statusText}>
            <Text
              color='primary'
              style={css`
                white-space: nowrap;
              `}
            >
              Registrado agora
            </Text>
          </span>
        </HFlow>
      )
    )
  }

  const encaminhamentosAntigos = encaminhamentos?.content ?? []
  const encaminhamentosRecentes = (encaminhamentosCache ? encaminhamentosCache : []) as EncaminhamentoExternoModel[]

  const encaminhamentosExibir: EncaminhamentoExternoRowModel[] = mergeEncaminhamentos(
    encaminhamentosAntigos,
    encaminhamentosRecentes,
    profissional.id,
    tableState,

    atendimentoProfissionalId,
    dataReferencia,
    lotacao
  )

  const handleImpressao = (value: EncaminhamentoExternoRowModel) => {
    if (value.isRegistradoAgora) {
      downloadEncaminhamentoExterno({
        encaminhamentoAtual: encaminhamentosRecentes.find((enc) => enc._id === value.id),
        atendimentoProfissionalId: atendimentoProfissionalId,
        atendimentoId,
        prontuarioId,
      })
    } else {
      downloadEncaminhamentoExterno({
        id: value.id,
        atendimentoProfissionalId: value.atendimentoProfissional?.id,
        atendimentoId,
        prontuarioId,
      })
    }
  }

  const renderButtons = (row: EncaminhamentoExternoRowModel) => (
    <EncaminhamentoExternoTableButtons
      encaminhamento={row}
      handleImpressao={handleImpressao}
      removeItem={removeItem}
      readOnly={readOnly}
    />
  )

  const { page: currentPage, size: itensPerPage } = tableState.pageParams
  const totalElements = encaminhamentosExibir?.length ?? 0
  const totalPages = Math.ceil(totalElements / itensPerPage)
  const content = encaminhamentosExibir?.slice(currentPage * itensPerPage, (currentPage + 1) * itensPerPage) ?? []
  const encaminhamentosPage = {
    content: content,
    pageInfo: {
      number: currentPage,
      size: itensPerPage,
      totalPages: totalPages,
      totalElements: totalElements,
      first: currentPage === 0,
      last: currentPage === totalPages - 1,
      numberOfElements: content.length,
    },
    paginationOptions: [5, 10, 30],
  }

  const tableProps = usePagedTableProps({
    loading: false,
    onChange: setTableState,
    result: encaminhamentosPage,
  })

  const classes = createStyles(theme)

  const modal = ({
    renderProps,
    formProps,
  }: {
    renderProps: RouteComponentProps
    formProps?: FormSpyRenderProps<SoapState>
  }) => (
    <EncaminhamentoExternoVisualizacaoModal
      {...renderProps}
      prontuarioId={prontuarioId}
      atendimentoId={atendimentoId}
      dataReferencia={dataReferencia}
      lotacao={lotacao}
      atendimentoProfissionalId={atendimentoProfissionalId}
      encaminhamentosRecentes={formProps?.values?.plano?.encaminhamentoExterno?.encaminhamentosRecentes}
    />
  )

  return (
    <Fragment>
      <TableBox
        header={
          <EncaminhamentoExternoFilter onChange={setTableState} filter={tableState} dataReferencia={dataReferencia} />
        }
      >
        <PagedTable<EncaminhamentoExternoRowModel>
          {...tableProps}
          columns={[
            {
              name: 'classificacaoAndData',
              header: 'Data',
              render: renderClassificacaoAndData,
              style: classes.classificacaoAndDataColumn,
              align: 'center',
            },
            {
              name: 'especialidade',
              header: 'Especialidade',
              render: renderEspecialidade,
              style: classes.especialidadeColumn,
            },
            {
              name: 'hipoteseDiagnostica',
              header: 'Hipótese diagnóstica',
              render: renderHipoteseDiagnostica,
              style: classes.hipoteseColumn,
            },
            { name: 'status', render: renderStatus, style: classes.adicionadoAgoraColumn },
            { name: 'buttons', render: renderButtons, style: classes.buttonsColumn },
          ]}
        />
      </TableBox>
      <PrivateRoute
        path={`${match.url}/encaminhamentos-externos/visualizar/:statusRegistro/:id`}
        permission={grupoCboEncaminhamentoExterno}
        render={(renderProps) =>
          readOnly ? (
            modal({ renderProps })
          ) : (
            <FormSpy<SoapState> subscription={{ values: true }}>
              {(formProps) => modal({ renderProps, formProps })}
            </FormSpy>
          )
        }
      />
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  classificacaoAndDataColumn: css`
    width: 12%;
    padding: 0 0.5rem;
  `,
  especialidadeColumn: css`
    width: 25%;
    ${theme.breakpoints.down('lg')} {
      word-break: break-word;
    }
  `,
  hipoteseColumn: css`
    width: 38%;
    ${theme.breakpoints.down('lg')} {
      word-break: break-word;
    }
  `,
  adicionadoAgoraColumn: css`
    width: 15%;
    padding: 0 0.5rem;
  `,
  buttonsColumn: css`
    width: 10%;
  `,
  statusText: css`
    ${theme.breakpoints.down('lg')} {
      display: none;
    }
  `,
})
