/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, ExternalStyles, Grid, HFlow, Icon, Theme } from 'bold-ui'
import { Box } from 'components/Box'
import { CheckboxField, Form, FormDebouncedValueSpy, FormRenderProps, SubmitButton, TextField } from 'components/form'
import DateRangeField from 'components/form/field/DateRangeField'
import { PopperButton, PopperControls } from 'components/popper'
import theme from 'config/theme'
import { FormState } from 'final-form'
import { EncaminhamentoExternoQueryInput } from 'graphql/types.generated'
import { useState } from 'react'
import { metaPath } from 'util/metaPath'

import { EspecialidadeSisregModel } from '../model'

interface EncaminhamentoExternoFilterProps {
  filter: EncaminhamentoExternoQueryInput
  dataReferencia: Instant
  onChange(values: EncaminhamentoExternoQueryInput): void
}

interface EncaminhamentoExternoFilterModel
  extends Omit<EncaminhamentoExternoQueryInput, 'prontuarioId' | 'especialidadeId'> {
  especialidade: EspecialidadeSisregModel
}

const name = metaPath<EncaminhamentoExternoFilterModel>()

export const EncaminhamentoExternoFilter = ({ filter, onChange, dataReferencia }: EncaminhamentoExternoFilterProps) => {
  const handleSubmit = ({ especialidade, periodo }: EncaminhamentoExternoFilterModel) => {
    onChange({ ...filter, especialidadeId: especialidade?.id, periodo })
    setActiveFilter(periodo?.endDate || periodo?.startDate ? true : false)
  }

  const [activeFilter, setActiveFilter] = useState<boolean>(false)

  const renderPopper = ({ close }: PopperControls) => {
    const submitAndClose = (values: EncaminhamentoExternoFilterModel) => {
      handleSubmit(values)
      close()
    }

    return (
      <Form<EncaminhamentoExternoFilterModel>
        onSubmit={submitAndClose}
        render={(formProps: FormRenderProps<EncaminhamentoExternoFilterModel>) => {
          return (
            <Box>
              <Grid>
                <Cell size={12}>
                  <DateRangeField
                    name={name.periodo}
                    label='Período'
                    maxDate={new Date(dataReferencia)}
                    initialValue={filter.periodo}
                  />
                </Cell>
                <Cell size={12} alignSelf='flex-end'>
                  <HFlow justifyContent='flex-end'>
                    <Button size='small' onClick={close}>
                      Fechar
                    </Button>
                    <SubmitButton size='small' handleSubmit={formProps.handleSubmit}>
                      Filtrar
                    </SubmitButton>
                  </HFlow>
                </Cell>
              </Grid>
            </Box>
          )
        }}
      />
    )
  }

  const handleDebounceChange = (formState: FormState<EncaminhamentoExternoFilterModel>) => {
    onChange({ ...filter, ...formState.values, query: formState.values.query })
  }

  const classes = createStyles(theme)

  const renderForm = () => {
    return (
      <HFlow alignItems='center' justifyContent='space-between'>
        <FormDebouncedValueSpy onChange={handleDebounceChange} />
        <HFlow>
          <TextField
            name={name.query}
            placeholder='Pesquise por profissional ou especialidade'
            icon='zoomOutline'
            style={classes.searchField}
          />
          <CheckboxField name={name.somenteMeusEncaminhamentosExternos} label='Ver somente os meus encaminhamentos' />
        </HFlow>
        <PopperButton kind='primary' size='small' placement='bottom-end' renderPopper={renderPopper}>
          <Icon icon='filterOutline' />
          Filtros {activeFilter ? '(1)' : ''}
        </PopperButton>
      </HFlow>
    )
  }

  return <Form<EncaminhamentoExternoFilterProps> render={renderForm} />
}

const createStyles = (theme: Theme) => ({
  searchField: css`
    ${theme.breakpoints.up('lg')} {
      width: 18.6rem;
    }
  ` as ExternalStyles,
})
