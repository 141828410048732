import { Omit } from 'bold-ui'
import { SelectFieldProps } from 'components/form'
import { CidadaoSelectFieldItem } from 'components/form/field/select/CidadaoSelectField/CidadaoSelectFieldItem'
import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { SelectFieldWithAddButton } from 'components/form/final-form/SelectField/SelectFieldWithAddButton'
import { CidadaoListaAdSelectFieldDocument } from 'graphql/hooks.generated'
import { CidadaoListaAdSelectFieldQuery, CidadaoListaAdSelectFieldQueryVariables } from 'graphql/types.generated'
import { useRouter } from 'hooks/useRouter'
import React from 'react'

export interface CidadaoListaADSelectFieldProps
  extends Omit<SelectFieldProps<CidadaoListaAdSelectFieldModel>, 'items' | 'itemToString'> {
  ativo?: boolean
  obito?: boolean
  resetFieldState(): void
}

export type CidadaoListaAdSelectFieldModel = CidadaoListaAdSelectFieldQuery['cidadaos']['content'][0]

export function CidadaoListaADSelectField(props: CidadaoListaADSelectFieldProps) {
  const { ativo, obito, resetFieldState, ...rest } = props
  const { history } = useRouter()

  const asyncProps = useAsyncQuerySelect<
    CidadaoListaAdSelectFieldModel,
    CidadaoListaAdSelectFieldQuery,
    CidadaoListaAdSelectFieldQueryVariables
  >({
    query: CidadaoListaAdSelectFieldDocument,
    extractItems: (data) => data?.cidadaos?.content,
    variables: (inputString) => ({
      input: {
        query: inputString,
        ativo: ativo,
        obito: obito,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (item: CidadaoListaAdSelectFieldModel) => item?.nomeSocial || item?.nome

  const renderItem = (item: CidadaoListaAdSelectFieldModel) => {
    return item && <CidadaoSelectFieldItem item={item} />
  }

  const renderSelectedCustomText = (item: CidadaoListaAdSelectFieldModel) => {
    return `Em AD, sob a responsabilidade da equipe: ${item?.equipeAtendimentoAd?.ine} - ${item?.equipeAtendimentoAd?.nome}`
  }

  const isEmAtendimentoAd = (item: CidadaoListaAdSelectFieldModel) => {
    return item.equipeAtendimentoAd?.id !== undefined
  }

  const onClickCadastrarButton = () => {
    resetFieldState()
    return history.push('/cidadao?callbackUrl=/ad/lista/cadastro')
  }

  return (
    <SelectFieldWithAddButton<CidadaoListaAdSelectFieldModel>
      itemToString={itemToString}
      renderItem={renderItem}
      itemIsEqual={isEmAtendimentoAd}
      customText={renderSelectedCustomText}
      menuMinWidth={500}
      popperProps={{ modifiers: [{ name: 'preventOverflow', enabled: false }] }}
      addText='Cadastre um cidadão somente se não o encontrar na busca.'
      addButtonText='Cadastrar cidadão'
      onClickAddButton={onClickCadastrarButton}
      {...asyncProps}
      {...rest}
    />
  )
}
