import { LotacaoAgendamento } from './model'

export function lotacaoAgendamentoToString(lotacao: LotacaoAgendamento): string {
  if (lotacao) {
    const textoEquipe = lotacao.equipe ? ` | Equipe ${lotacao.equipe.ine} - ${lotacao.equipe.nome}` : ''
    return `${lotacao.profissional.nome} | ${lotacao.cbo.nome.capitalize()}${textoEquipe}`
  } else {
    return ''
  }
}
