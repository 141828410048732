import ApolloClient from 'apollo-client'
import { Calculation } from 'final-form-calculate'
import { SexoEnum, TipoAtendimentoProfissional } from 'graphql/types.generated'
import { Meta, MetaArray, MetaRoot } from 'util/metaPath'
import { meta as atendMeta } from 'view/atendimentos/atendimento-individual/model'
import { createDesfechoIndividualCalculations } from 'view/atendimentos/detail/soap/desfecho/calculator/calculatorIndividual'
import { createDesfechoOdontoCalculations } from 'view/atendimentos/detail/soap/desfecho/calculator/calculatorOdonto'
import { ProcedimentoAutomatico } from 'view/atendimentos/model'

import { ProblemaCondicaoModel } from '../avaliacao/components/problemas-condicoes/model'
import { createAgendarConsultaCalculations } from './calculator/calculatorAgendarConsulta'
import { createNovoAtendimentoCalculations } from './calculator/calculatorNovoAtendimento'
import { DesfechoFormModel } from './DesfechoForm'

export const createDesfechoCalculations = (
  meta: Meta<DesfechoFormModel> & MetaRoot<DesfechoFormModel>,
  metaProblemasCondicoes: MetaArray<ProblemaCondicaoModel>,
  apollo: ApolloClient<object>,
  isMedico: boolean,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  tipoAtendProf: TipoAtendimentoProfissional,
  hasPermissionPreNatal: boolean,
  idadeCidadaoEmAnos: number,
  desejaInformarIdentidadeGenero: boolean,
  sexo: SexoEnum,
  isGestante: boolean,
  isCEO?: boolean
): Calculation[] => {
  const calculatorByTipoAtendProf: Calculation[] =
    tipoAtendProf === TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO
      ? createDesfechoOdontoCalculations(meta, procedimentosAutomaticos, isCEO)
      : createDesfechoIndividualCalculations(
          meta,
          metaProblemasCondicoes,
          procedimentosAutomaticos,
          isMedico,
          hasPermissionPreNatal,
          idadeCidadaoEmAnos,
          desejaInformarIdentidadeGenero,
          sexo,
          isGestante
        )
  return [
    ...createNovoAtendimentoCalculations(meta.desfechoAtendimento.atendimento, apollo),
    ...createAgendarConsultaCalculations(atendMeta.desfecho.agendamentoConsulta),
    ...calculatorByTipoAtendProf,
  ]
}
