import { VFlow } from 'bold-ui'
import { RichTextField } from 'components/form'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { MotivoConsultaField, MotivoConsultaFieldModel } from './components/MotivoConsultaField'

export interface SubjetivoFormModel {
  texto: string
  motivos: MotivoConsultaFieldModel[]
}

export interface SubjetivoFormProps {
  name: MetaPath<SubjetivoFormModel>
  cidadao: CidadaoAtendimento
  isAtendimentoOdonto: boolean
}

export function SubjetivoForm(props: SubjetivoFormProps) {
  const { name, cidadao, isAtendimentoOdonto } = props

  return (
    <VFlow>
      <RichTextField
        name={name.texto}
        maxLength={4000}
        placeholder='Insira as impressões subjetivas do profissional e as expressadas pelo cidadão.'
      />

      {!isAtendimentoOdonto && <MotivoConsultaField name={name.motivos} cidadao={cidadao} />}
    </VFlow>
  )
}
