/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { isEmpty, Modal } from 'bold-ui'
import { resolveName } from 'components/form/final-form/useField'
import { confirm } from 'components/modals/confirm'
import theme from 'config/theme'
import { SexoEnum } from 'graphql/types.generated'
import { useCallback, useMemo, useRef, useState } from 'react'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { useHistory, useRouteMatch } from 'react-router'
import { MetaPath } from 'util/metaPath'

import { MedicamentoFormModel } from '../MedicamentoFormModel'
import { PrescricaoMedicamentoPlanoModel } from '../PrescricaoMedicamentoView'
import AdicionarPrescricaoFieldArray from './AdicionarPrescricaoFieldArray'

export interface AdicionarPrescricaoModalProps {
  name: MetaPath<PrescricaoMedicamentoPlanoModel>
  atendimentoId: ID
  medicamentosToReplicate: MedicamentoFormModel[]
  cidadaoDataNascimento: LocalDate
  cidadaoSexo: SexoEnum
  onChangeMedicamentosToReplicate(medicamentos: MedicamentoFormModel[]): void
  onClearMedicamentosToReplicate(): void
}

export default function AdicionarPrescricaoModal(props: AdicionarPrescricaoModalProps) {
  const {
    name,
    medicamentosToReplicate,
    atendimentoId,
    cidadaoDataNascimento,
    cidadaoSexo,
    onChangeMedicamentosToReplicate,
    onClearMedicamentosToReplicate,
  } = props
  const history = useHistory()
  const match = useRouteMatch()
  const prescricaoModalRef = useRef<HTMLDivElement>()
  const [medicamentoToEdit, setMedicamentoToEdit] = useState<MedicamentoFormModel>(
    medicamentosToReplicate ? medicamentosToReplicate[0] : null
  )

  const scrollModal = () => prescricaoModalRef.current?.scrollTo(0, 0)

  const onCloseModal = useCallback(() => {
    history.push(match.url.replace('/prescricao-medicamentos/adicionar', ''))
    setTimeout(onClearMedicamentosToReplicate)
  }, [history, match.url, onClearMedicamentosToReplicate])

  const handleOnCloseModal = useCallback(() => {
    if (medicamentosToReplicate && !isEmpty(medicamentosToReplicate)) {
      confirm({
        type: 'primary',
        title: 'Deseja fechar a prescrição de medicamentos?',
        body:
          'Ao fechar a prescrição sem revisar e salvar todos os medicamentos, os medicamentos que não foram salvos serão perdidos.',
        onConfirm: () => {
          medicamentoToEdit && setMedicamentoToEdit(null)
          onCloseModal()
        },
        confirmLabel: 'Continuar',
        cancelLabel: 'Cancelar',
        depthLevel: 2,
        manageOverflow: false,
      })()
    } else {
      onCloseModal()
    }
  }, [medicamentoToEdit, medicamentosToReplicate, onCloseModal])

  const renderFieldArray = useCallback(
    (fieldArrayRenderProps: FieldArrayRenderProps<MedicamentoFormModel, any>) => {
      return (
        <AdicionarPrescricaoFieldArray
          onChangeMedicamentosToReplicate={onChangeMedicamentosToReplicate}
          onClearMedicamentosToReplicate={onClearMedicamentosToReplicate}
          onCloseModal={handleOnCloseModal}
          setMedicamentoToEdit={setMedicamentoToEdit}
          medicamentoToEdit={medicamentoToEdit}
          medicamentosToReplicate={medicamentosToReplicate}
          atendimentoId={atendimentoId}
          cidadaoSexo={cidadaoSexo}
          cidadaoDataNascimento={cidadaoDataNascimento}
          scrollModal={scrollModal}
          {...fieldArrayRenderProps}
        />
      )
    },
    [
      atendimentoId,
      cidadaoDataNascimento,
      cidadaoSexo,
      handleOnCloseModal,
      medicamentoToEdit,
      medicamentosToReplicate,
      onChangeMedicamentosToReplicate,
      onClearMedicamentosToReplicate,
    ]
  )

  return useMemo(
    () => (
      <Modal
        open={true}
        containerRef={prescricaoModalRef}
        onClose={handleOnCloseModal}
        closeOnBackdropClick={false}
        style={styles.mainContainer}
      >
        <FieldArray name={resolveName(name.medicamentos)} render={renderFieldArray} />
      </Modal>
    ),
    [handleOnCloseModal, name.medicamentos, renderFieldArray]
  )
}

const styles = {
  mainContainer: css`
    background-color: ${theme.pallete.surface.background};
    min-width: 64rem;
    max-height: 95vh;
    position: relative;
  `,
}
