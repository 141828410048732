import api from 'api'
import { generatePdf } from 'util/generatePdf'

export const downloadRelatorioAd = (formData: FormData) => {
  return api.atencaoDomiciliar
    .gerarRelatorioAd(formData)
    .then((response) => {
      generatePdf(new Blob([response.data], { type: 'application/pdf' }), 'Relatório de Atenção domiciliar')
      return response
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('loadend', (e) => {
          reject((e.srcElement as any).result)
        })
        reader.readAsText(error.response.data)
      })
    })
}
