/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow } from 'bold-ui'
import useFirebase from 'components/firebase/useFirebase'
import { DateField } from 'components/form'
import { resolveName } from 'components/form/final-form/useField'
import { format } from 'date-fns'
import { useCallback, useState } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'

import {
  ReplicateDatesModel,
  ResultadoExameComSolicitacaoModel,
  ResultadoExameEspecificoModel,
  ResultadosExamesSolicitadosModel,
} from '../../model'

interface ReplicarDatasSolicitacaoFieldProps {
  name: MetaPath<ReplicateDatesModel>
  nameFieldToChange: MetaPath<ResultadosExamesSolicitadosModel>
  resultadosIds: ID[]
  minDate: Date
  maxDate: Date
  changeValue(name: MetaPath<any>, prevValue: any): void
}

export const ReplicarDatasSolicitacaoField = (props: ReplicarDatasSolicitacaoFieldProps) => {
  const { name, nameFieldToChange, resultadosIds, minDate, maxDate, changeValue } = props
  const { analytics } = useFirebase()

  const {
    input: { value: fieldValue },
  } = useField(resolveName(name), { subscription: { value: true } })

  const [prevValueState, setPrevValueState] = useState<ReplicateDatesModel>(fieldValue)

  const handleChangeRealizado = (value: Date) => {
    const dateSelected = value ? format(value, 'yyyy-MM-dd') : undefined
    analytics.logEvent('replicar_data_exm_realizado')

    replicate(
      dateSelected,
      prevValueState?.dataRealizado,
      (prevExameValue) => prevExameValue?.dataRealizado,
      (prevExameValue, newDate) => ({
        ...prevExameValue,
        dataRealizado: newDate,
      })
    )

    setPrevValueState((prev) => ({
      ...prev,
      dataRealizado: dateSelected,
    }))
  }

  const handleChangeResultado = (value: Date) => {
    const dateSelected = value ? format(value, 'yyyy-MM-dd') : undefined
    analytics.logEvent('replicar_data_exm_resultado')

    replicate(
      dateSelected,
      prevValueState?.dataResultado,
      (prevExameValue) => prevExameValue?.dataResultado,
      (prevExameValue, newDate) => ({
        ...prevExameValue,
        dataResultado: newDate,
      })
    )

    setPrevValueState((prev) => ({
      ...prev,
      dataResultado: dateSelected,
    }))
  }

  const replicate = useCallback(
    (
      dateSelected: LocalDate,
      prevDateSelected: LocalDate,
      extractPrevDate: (prevExameValue: ResultadoExameComSolicitacaoModel & ResultadoExameEspecificoModel) => LocalDate,
      mountNewResultadoExameValue: (
        prevExameValue: ResultadoExameComSolicitacaoModel & ResultadoExameEspecificoModel,
        newDate: LocalDate
      ) => ResultadoExameComSolicitacaoModel & ResultadoExameEspecificoModel
    ) => {
      changeValue(
        nameFieldToChange,
        (prevValues: ResultadosExamesSolicitadosModel): ResultadosExamesSolicitadosModel => {
          const newValues = Object.fromEntries(
            resultadosIds.map((id) => {
              const prevExameValue = extractResultadoExameValue(id, prevValues)
              const prevDateValue = extractPrevDate(prevExameValue)

              const isNullOrUndefined = prevDateValue === undefined || prevDateValue === null

              const newDateValue =
                prevDateSelected === prevDateValue || isNullOrUndefined ? dateSelected : prevDateValue

              return [id, mountNewResultadoExameValue(prevExameValue, newDateValue)]
            })
          )

          return { ...prevValues, ...newValues }
        }
      )
    },
    [changeValue, nameFieldToChange, resultadosIds]
  )

  return (
    <HFlow>
      <DateField
        name={name.dataRealizado}
        label='Exames realizados em'
        onChange={handleChangeRealizado}
        minDate={minDate}
        maxDate={maxDate}
        inline
        style={css`
          width: 9.5rem;
        `}
      />

      <DateField
        name={name.dataResultado}
        label='Resultados em'
        onChange={handleChangeResultado}
        minDate={minDate}
        maxDate={maxDate}
        inline
        style={css`
          width: 9.5rem;
        `}
      />
    </HFlow>
  )
}

const extractResultadoExameValue = (id: ID, values: ResultadosExamesSolicitadosModel) => {
  return values?.[id]
}
