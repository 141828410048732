import { isUndefinedOrNull } from './checks'

/**
 * Verifica se todos os valores do objeto estão nulos, vazios ou falsos em busca profunda
 * @param obj Objeto a ser verificado
 */
export function isObjectDeepEmpty(obj: Object): boolean {
  return !obj || (typeof obj === 'object' && !Object.values(obj).some((x) => !isObjectDeepEmpty(x)))
}

/**
 * Verifica se todos os valores do objeto estão nulos ou vazios
 * @param obj Objeto a ser verificado
 */
export function isObjectDeepNullOrUndefined(obj: Object): boolean {
  return isUndefinedOrNull(obj) || (typeof obj === 'object' && Object.values(obj).every(isObjectDeepNullOrUndefined))
}
