import React from 'react'

import { CondutaOdontoPanel, CondutaOdontoPanelProps } from './components/CondutaOdontoPanel'
import { CondutaPanel, CondutaPanelProps } from './components/CondutaPanel'
import { DesfechoFormProps } from './DesfechoForm'

interface DesfechoCondutaPanelProps
  extends Pick<DesfechoFormProps, 'isAtendimentoOdonto' | 'isAtendimentoProcedimentos' | 'name'>,
    Omit<CondutaPanelProps, 'name'>,
    Omit<CondutaOdontoPanelProps, 'name'> {}

export function DesfechoCondutaPanel(props: DesfechoCondutaPanelProps) {
  const {
    isAtendimentoProcedimentos,
    isAtendimentoOdonto,
    tipoConsulta,
    hasEncaminhamentoExterno,
    hasNotificacaoCasoSuspeito,
    name,
  } = props

  if (isAtendimentoOdonto)
    return (
      <CondutaOdontoPanel
        name={name.condutasOdonto}
        tipoConsulta={tipoConsulta}
        hasEncaminhamentoExterno={hasEncaminhamentoExterno}
      />
    )

  if (!isAtendimentoProcedimentos)
    return (
      <CondutaPanel
        name={name.conduta}
        hasNotificacaoCasoSuspeito={hasNotificacaoCasoSuspeito}
        hasEncaminhamentoExterno={hasEncaminhamentoExterno}
      />
    )

  return null
}
