import { AcessoCbo, Lotacao } from 'graphql/types.generated'
import { Permission } from 'types/Permissions'

interface UnidadeSaude {
  subtipo?: {
    tipoUnidadeSaude: {
      id: ID
    }
    codigoMs: string
  }
}

export type Authorization = Permission | Permission[] | AcessoCbo[]

export const isPermission = (permission): permission is Permission => permission.uri

export const isPermissionArray = (permission): permission is Permission[] => permission[0]?.uri

export const isCboPermission = (permission): permission is AcessoCbo[] => !!AcessoCbo[permission[0]]

export const isLotacao = (acesso): acesso is Lotacao => acesso.__typename === 'Lotacao'

export const isUnidadeSaudeCEO = (unidadeSaude: UnidadeSaude) => {
  const unidadeSaudeIdCEO = '9'
  const codigosMsCEO = ['006', '007', '008']

  return (
    unidadeSaude &&
    unidadeSaude.subtipo?.tipoUnidadeSaude?.id === unidadeSaudeIdCEO &&
    codigosMsCEO.includes(unidadeSaude.subtipo?.codigoMs)
  )
}
