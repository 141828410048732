import { HFlow, Tag, Text, useStyles, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { SituacaoAgendadoEnum, StatusAtendimento } from 'graphql/types.generated'
import moment from 'moment'
import React from 'react'
import { humanizeAge } from 'util/date/humanize-age'
import { AtendimentoModel } from 'view/atendimentos/list/ListaAtendimentoView'

import AtendimentoListingItem from '../components/AtendimentoListingItem'
import { AtendimentoTags } from './AtendimentoTags'
import ListaAtendimentoItemActions from './ListaAtendimentoItemActions'
import { listaAtendimentoStylesRecord } from './listaAtendimentoStyles'
import { createItemStyles } from './listaUtils'

export interface ListaAtendimentoItemProps {
  atend: AtendimentoModel
}

export function ListaAtendimentoItem(props: ListaAtendimentoItemProps) {
  const { atend } = props
  const { classes } = useStyles(createItemStyles)
  const statusAtendimento = listaAtendimentoStylesRecord[atend.statusAtendimento]
  const shouldNotShowTagAgendamento = !!(
    atend.agendado?.situacao !== SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE &&
    atend.statusAtendimento !== StatusAtendimento.ATENDIMENTO_REALIZADO
  )

  const renderTime = (
    <VFlow vSpacing={0}>
      {!atend.finalizado && (
        <div>
          <Tag icon='exclamationTriangleFilled' style={classes.naoFinalizadoTag}>
            {`Não finalizado - ${moment(atend.iniciadoEm).format('DD/MM/YYYY')}`}
          </Tag>
        </div>
      )}
      <HFlow style={classes.hFlow}>
        <VFlow vSpacing={0}>
          <DateTime
            style={{ lineHeight: '1.35rem', fontWeight: 'bold' }}
            value={atend.ultimaAlteracaoStatusEm}
            format={
              atend.statusAtendimento === StatusAtendimento.NAO_AGUARDOU ||
              atend.statusAtendimento === StatusAtendimento.ATENDIMENTO_REALIZADO
                ? 'DD/MM/YYYY [-] HH:mm'
                : 'HH:mm'
            }
          />

          <Text>{statusAtendimento.descricao}</Text>
        </VFlow>

        <VFlow vSpacing={0}>
          <span className={classes.nomeCidadao}>{`${atend.cidadao.nomeSocial || atend.cidadao.nome} `}</span>
          <span className={classes.idadeCidadao}>
            {atend.cidadao.dataNascimento ? humanizeAge(atend.cidadao.dataNascimento) : '-'}
          </span>
        </VFlow>
      </HFlow>
    </VFlow>
  )

  const renderTags = (
    <AtendimentoTags
      tiposServico={atend.tiposServico}
      classificacaoRisco={atend.classificacaoRisco}
      horaAgendamento={shouldNotShowTagAgendamento ? null : atend.agendado?.horarioInicial}
      nomeProfissional={shouldNotShowTagAgendamento ? null : atend.agendado?.lotacaoAgendada?.profissional?.nome}
    />
  )

  const renderLotacao = (
    <>
      {atend.lotacao && (
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'>{atend.lotacao.profissional.nome}</Text>
        </VFlow>
      )}
      {!atend.lotacao && atend.equipe && (
        <VFlow vSpacing={0}>
          <Text fontWeight='bold'> {atend.equipe.nome} </Text>
          <Text>
            <b>Área </b> {atend.equipe.area}
          </Text>
        </VFlow>
      )}
    </>
  )

  const renderActions = (
    <ListaAtendimentoItemActions
      actions={atend.actions}
      atendId={atend.id}
      cidadaoId={atend.cidadao.id}
      prontuarioId={atend.prontuarioId}
      lotacaoId={atend.lotacao?.id}
      statusAtendimento={atend.statusAtendimento}
    />
  )

  return (
    <AtendimentoListingItem
      status={statusAtendimento}
      time={renderTime}
      tags={renderTags}
      lotacao={renderLotacao}
      actions={renderActions}
    />
  )
}
