import { Button, Cell, Grid, HFlow, Icon, TableFooter, Text, Tooltip, useTheme, VFlow } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { AccordionControls } from 'components/accordion/useAccordionControl'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import { usePrinter } from 'components/print'
import { PrinterType } from 'components/print/model'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import { useAuditaImpressaoOrientacaoMutation, useOrientacoesQuery } from 'graphql/hooks.generated'
import { TextFilterType } from 'hooks/filter/model'
import { useFilter } from 'hooks/filter/useFilter'
import { noop } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { formatDate } from 'util/date/formatDate'
import { metaPath } from 'util/metaPath'
import { LotacaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { grupoCboOrientacoes } from '../../acessos'
import { OrientacaoFilterModel, OrientacaoFormModel, OrientacaoRowModel } from '../types/OrientacoesModel'
import { mergeSortOrientacoes } from '../util/mergeSortOrientacoes'
import { ImpressaoOrientacoes } from './ImpressaoOrientacoes'
import { OrientacoesAccordionPanel } from './OrientacoesAccordionPanel'
import { OrientacoesTableFilter } from './OrientacoesTableFilter'
import { OrientacoesTableRow } from './OrientacoesTableRow'

const metaRow = metaPath<OrientacaoRowModel>()
const metaFilter = metaPath<OrientacaoFilterModel>()

const filterTextTypeConfig: TextFilterType = {
  filterField: metaFilter.texto,
  searchFields: [
    metaRow.descricao,
    metaRow.atendimentoProfissional.lotacao.cbo.nome,
    metaRow.atendimentoProfissional.lotacao.profissional.nome,
  ],
  removeTagsOnFilter: true,
}

const handleFilterBySomenteMeus = (doFilter: boolean, profissionalId: ID) => (
  orientacaoRowModel: OrientacaoRowModel
) => {
  return doFilter ? orientacaoRowModel.atendimentoProfissional.lotacao.profissional.id === profissionalId : true
}

interface OrientacoesTableProps extends AccordionControls {
  atendimentoId: ID
  prontuarioId: ID
  cidadao: CidadaoAtendimento
  dataReferencia: Instant
  lotacao: LotacaoAtendimento | LotacaoFolhaRosto
  atendimentoProfissionalId?: ID
  idOrientacaoEmEdicao?: ID
  orientacoes?: OrientacaoFormModel[]
  update?(index: number, value: OrientacaoFormModel): void
  remove?(index: number): void
  onEditClick?(cacheId: ID): void
  readOnly?: boolean
}

export function OrientacoesTable(props: OrientacoesTableProps) {
  const {
    atendimentoId,
    prontuarioId,
    cidadao,
    dataReferencia,
    lotacao,
    atendimentoProfissionalId,
    orientacoes = [],
    idOrientacaoEmEdicao = null,
    onEditClick = noop,
    update = noop,
    remove = noop,
    resetExpandedItems,
    readOnly,
    ...accordionProps
  } = props

  const { data, loading } = useOrientacoesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      orientacaoQueryInput: {
        prontuarioId,
      },
    },
  })

  const { hasCboAuth } = useSession({ fetchPolicy: 'cache-only' })

  /**
   * Removemos o CheckPermission e comecamos a usar o hook useSession porque ele estava causando um warning no console
   * A issue #8503 contem mais detalhes sobre o warning
   */
  const hasPermissionAdicionarOrientacoes = hasCboAuth(grupoCboOrientacoes.adicionar)

  const [sort, setSort] = useState<string[]>(['-data'])

  const isEditing = useCallback((cacheId: ID): boolean => cacheId !== undefined && cacheId === idOrientacaoEmEdicao, [
    idOrientacaoEmEdicao,
  ])

  const { printPDF } = usePrinter()
  const handleRejection = useErrorHandler()

  const todasOrientacoes = useMemo(
    () =>
      mergeSortOrientacoes(
        data.orientacoes ?? [],
        orientacoes ?? [],
        sort,
        dataReferencia,
        atendimentoProfissionalId,
        lotacao
      ),
    [atendimentoProfissionalId, data.orientacoes, dataReferencia, lotacao, orientacoes, sort]
  )

  const theme = useTheme()

  const handleEditClick = (e: Event, cacheId: ID) => {
    e.stopPropagation()
    onEditClick(cacheId)
  }

  const component = useMemo(
    () => ({
      AccordionPanel: OrientacoesAccordionPanel,
      Row: (props) => (
        <OrientacoesTableRow
          isEditing={isEditing(props.row.cacheId)}
          onUpdate={update}
          removeOrientacaoEmEdicao={() => onEditClick(null)}
          {...props}
        />
      ),
    }),
    [isEditing, onEditClick, update]
  )

  const [auditImpressao] = useAuditaImpressaoOrientacaoMutation()

  const [filter, setFilter] = useState<OrientacaoFilterModel>({ texto: '', somenteMeus: false })

  const orientacoesFiltradas = useFilter<OrientacaoRowModel, OrientacaoFilterModel>({
    items: todasOrientacoes,
    filter: filter,
    filtersType: [filterTextTypeConfig],
    customFilters: [handleFilterBySomenteMeus(filter.somenteMeus, lotacao.profissional.id)],
  })

  const handleRemoveClick = (e: Event, cacheId: ID) => {
    e.stopPropagation()
    remove(cacheId)
  }

  const { paginatedItems, tableProps } = usePagination<OrientacaoRowModel>({
    items: orientacoesFiltradas,
    onChange: resetExpandedItems,
  })

  const handlePrint = (e: Event, values: OrientacaoRowModel) => {
    e.stopPropagation()

    const infoProfissional: PrinterType = {
      acesso: values.atendimentoProfissional.lotacao,
      profissional: values.atendimentoProfissional.lotacao.profissional,
    }

    const docBody = ImpressaoOrientacoes({
      ...values,
      cidadao,
    })

    printPDF(
      {
        docBody,
        documentTitle: 'ImpressaoOrientação-' + (cidadao.nomeSocial || cidadao.nome),
        session: infoProfissional,
      },
      handleRejection
    )
    auditImpressao({ variables: { prontuarioId, atendimentoId, orientacaoId: values.id } })
  }

  return (
    <TableBox header={<OrientacoesTableFilter onChangeFilter={setFilter} currentFilter={filter} />}>
      <AccordionDataTable<OrientacaoRowModel>
        {...accordionProps}
        loading={loading}
        rows={paginatedItems}
        columns={[
          {
            name: 'data',
            header: 'Data',
            render: (item: OrientacaoRowModel) => formatDate(item.atendimentoProfissional.iniciadoEm),
            size: 2,
            sortable: true,
          },
          {
            name: 'profissional',
            header: 'Profissional',
            render: (item: OrientacaoRowModel) => (
              <VFlow vSpacing={0}>
                {item.atendimentoProfissional.lotacao.profissional.nome}
                {item.atendimentoProfissional.lotacao.cbo.nome}
              </VFlow>
            ),
            size: 4,
            sortable: true,
          },
          {
            name: 'descricao',
            header: 'Orientação',
            render: (item: OrientacaoRowModel) => {
              const itemFromDatabase: boolean = !!item.id
              return (
                <Grid gap={1} gapVertical={0} alignItems='center' wrap={false}>
                  <Cell size={8}>
                    <Text>{item.descricao.removeTags().truncate(100)}</Text>
                  </Cell>
                  <Cell size={4}>
                    <HFlow hSpacing={0.5} alignItems='center' justifyContent='flex-end'>
                      {!itemFromDatabase && (
                        <Tooltip text='Registrado agora'>
                          <Icon icon='clockOutline' color={theme.pallete.primary.c40} size={1} />
                        </Tooltip>
                      )}
                      <HFlow hSpacing={0} alignItems='center' justifyContent='flex-end'>
                        <Tooltip text='Imprimir'>
                          <Button
                            size='small'
                            kind='normal'
                            skin='ghost'
                            onClick={(e) => {
                              handlePrint(e, item)
                            }}
                          >
                            <Icon icon='printerOutline' />
                          </Button>
                        </Tooltip>
                        {hasPermissionAdicionarOrientacoes && !readOnly && (
                          <>
                            <Tooltip
                              text={
                                itemFromDatabase
                                  ? 'Não é permitido alterar uma orientação cadastrada em outro atendimento.'
                                  : 'Alterar'
                              }
                            >
                              <Button
                                size='small'
                                kind='normal'
                                skin='ghost'
                                onClick={(e) => {
                                  handleEditClick(e, item.cacheId)
                                }}
                                disabled={itemFromDatabase}
                              >
                                <Icon icon='penOutline' />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              text={
                                itemFromDatabase
                                  ? 'Não é permitido excluir uma orientação cadastrada em outro atendimento.'
                                  : 'Excluir'
                              }
                            >
                              <Button
                                size='small'
                                kind='normal'
                                skin='ghost'
                                onClick={(e) => {
                                  handleRemoveClick(e, item.cacheId)
                                }}
                                disabled={itemFromDatabase}
                              >
                                <Icon icon='trashOutline' />
                              </Button>
                            </Tooltip>
                          </>
                        )}
                      </HFlow>
                    </HFlow>
                  </Cell>
                </Grid>
              )
            },
            size: 6,
          },
        ]}
        components={component}
        sort={sort}
        onSortChange={setSort}
        disableRow={(row) => isEditing(row.cacheId)}
      />
      <TableFooter {...tableProps} />
    </TableBox>
  )
}
