import { isBetween } from 'components/agenda/utils'
import { SimNaoEnum } from 'components/form/field/SimNaoRadioGroupField'
import { addDays, format, parseISO, toDate } from 'date-fns'
import { AcompanhamentoPreNatalQuery, AtendimentoProfissional, ResultadoExame } from 'graphql/types.generated'
import { orderBy } from 'lodash'
import { tipoEdemaRecord } from 'types/enums'
import { OrigemDum } from 'view/atendimentos/detail/components/modals/medicoes/model'
import {
  AtendimentoProfUltimaGestacao,
  HistoricoPreNatalModel,
  MedicoesPreNatal,
} from 'view/atendimentos/detail/components/modals/types/HistoricoPreNatalModel'
import {
  ResultadoExameEspecificoModel,
  ResultadosExamesModel,
} from 'view/atendimentos/detail/soap/objetivo/resultados-exames/model'
import {
  MEDICOES_PRE_NATAL,
  PreNatalFormModel,
  QUANTIDADE_DIAS_FIM_PRIMEIRO_TRIMESTRE_GESTACAO,
} from 'view/atendimentos/detail/soap/pre-natal/model'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

function compareAscDataExame(exameLeft: ResultadoExame, exameRight: ResultadoExame) {
  const dateLeft = toDate(exameLeft.dataRealizacao)
  const dateRight = toDate(exameRight.dataRealizacao)

  const diff = dateLeft.getTime() - dateRight.getTime()
  const idDiff = exameRight.id ? (exameLeft.id ? exameLeft.id > exameRight.id : true) : false

  if (diff < 0) {
    return -1
  } else if (diff > 0) {
    return 1
  } else {
    return idDiff ? 1 : -1
  }
}

function getListaEcoOrdenadaDesc(
  resultadosExamesAnteriores: ResultadoExame[],
  dum: Date,
  resultadosExamesAtendimentoAtual?: ResultadoExameEspecificoModel[],
  hasDadoPreNatalPreenchido?: boolean
): [ResultadoExame[], boolean, boolean] {
  let listaEcoOrdenadaDesc: ResultadoExame[] = []
  resultadosExamesAnteriores?.forEach((exame) => {
    if (exame.especifico?.igSemanas || exame.especifico?.igDias || exame.especifico?.dpp) {
      listaEcoOrdenadaDesc.push(exame)
    }
  })
  let hasdppEcoAtendimento = false
  let hasExamesPreNatalAtendimento = false

  if (hasDadoPreNatalPreenchido && dum) {
    resultadosExamesAtendimentoAtual?.forEach((resultadoExame) => {
      if (
        parseISO(resultadoExame?.dataRealizado) >= dum &&
        (resultadoExame?.resultado?.dpp || resultadoExame?.resultado?.idadeGestacional)
      ) {
        listaEcoOrdenadaDesc.push({
          dataRealizacao: resultadoExame.dataRealizado,
          especifico: {
            dpp: resultadoExame.resultado.dpp,
            igDias:
              resultadoExame.resultado.idadeGestacional.dias && Number(resultadoExame.resultado.idadeGestacional.dias),
            igSemanas:
              resultadoExame.resultado.idadeGestacional.semanas &&
              Number(resultadoExame.resultado.idadeGestacional.semanas),
          },
        } as ResultadoExame)
        hasdppEcoAtendimento = resultadoExame?.resultado?.dpp && true
        hasExamesPreNatalAtendimento = true
      }
    })
  }

  return [
    listaEcoOrdenadaDesc.sort((a, b) => compareAscDataExame(b, a)),
    hasExamesPreNatalAtendimento,
    hasdppEcoAtendimento,
  ]
}

function getInicioGestacaoEDataProvavelParto(dum: Date, listaEcoOrdenadaDesc: ResultadoExame[]): [Date, Date, boolean] {
  if (dum) {
    const dataLimite = addDays(dum, QUANTIDADE_DIAS_FIM_PRIMEIRO_TRIMESTRE_GESTACAO)
    for (let i = 0; i < listaEcoOrdenadaDesc?.length ?? 0; i++) {
      const dataRealizacaoExame = parseISO(listaEcoOrdenadaDesc[i].dataRealizacao)
      if (dum <= dataRealizacaoExame && dataRealizacaoExame <= dataLimite) {
        if (listaEcoOrdenadaDesc[i].especifico?.igSemanas) {
          const idadeGestacionalNaDataDaRealizacaoExame =
            listaEcoOrdenadaDesc[i].especifico.igSemanas * 7 + (listaEcoOrdenadaDesc[i].especifico.igDias ?? 0)
          const dataInicioGestacao = addDays(dataRealizacaoExame, -idadeGestacionalNaDataDaRealizacaoExame)
          const dataProvavelParto = listaEcoOrdenadaDesc[i].especifico.dpp
            ? parseISO(listaEcoOrdenadaDesc[i].especifico.dpp)
            : null
          return [dataInicioGestacao, dataProvavelParto, true]
        }
        return [dum, listaEcoOrdenadaDesc[i].especifico.dpp, false]
      }
    }
  }
  return [dum, null, false]
}

function getDumAtual(valoresComMedicaoAtual: AtendimentoProfissional[]): Date {
  for (let i = valoresComMedicaoAtual?.length - 1 ?? 0; i >= 0; i--) {
    if (valoresComMedicaoAtual[i]?.medicoes[0]?.dum) {
      return parseISO(valoresComMedicaoAtual[i].medicoes[0]?.dum)
    }
  }
}

function defineDumComStatus(valoresComMedicaoAtual: AtendimentoProfissional[], dumAtendimentoAtual?: string) {
  const dumPersistida = getDumAtual(valoresComMedicaoAtual)
  const dumFinal = dumAtendimentoAtual ? parseISO(dumAtendimentoAtual) : dumPersistida

  let origemDum: OrigemDum = OrigemDum.SEM_DUM
  if (dumAtendimentoAtual && dumPersistida) {
    origemDum = OrigemDum.AMBOS
  } else if (dumAtendimentoAtual) {
    origemDum = OrigemDum.ATENDIMENTO_ATUAL
  } else if (dumPersistida) {
    origemDum = OrigemDum.ATENDIMENTO_ANTERIOR
  }

  return { dum: dumFinal, origemDum }
}

export function convertDadosPreNatal(
  data: AcompanhamentoPreNatalQuery,
  readOnly?: boolean,
  preNatalAtendimentoAtual?: PreNatalFormModel,
  resultadosExamesAtendimentoAtual?: ResultadosExamesModel,
  dumAtendimentoAtual?: string,
  dataAtendimento?: Instant,
  resultadosExamesAnteriores?: ResultadoExame[]
): HistoricoPreNatalModel {
  let medicoes: MedicoesPreNatal[] = []

  const hasDadoPreNatalPreenchido = !readOnly && typeof preNatalAtendimentoAtual !== 'undefined'
  const hasMedicoesCacheValues = !readOnly && MEDICOES_PRE_NATAL.some((item) => preNatalAtendimentoAtual?.[item])

  const valoresComMedicaoAtual = data?.atendimentosProfUltimaGestacao?.filter((atendProf) =>
    atendProf?.medicoes?.filter((medicao) => !medicao.medicaoAnterior)
  )

  const { dum, origemDum } = defineDumComStatus(
    valoresComMedicaoAtual as AtendimentoProfissional[],
    dumAtendimentoAtual
  )

  const ultimoDiaDoPrimeiroTrimestreGestacao = addDays(dum, QUANTIDADE_DIAS_FIM_PRIMEIRO_TRIMESTRE_GESTACAO)

  const resultadosExamesAnterioresPrimeiroTrimestre = resultadosExamesAnteriores?.filter((exame) =>
    isBetween(parseISO(exame.dataRealizacao), {
      start: dum,
      end: ultimoDiaDoPrimeiroTrimestreGestacao,
    })
  )
  const resultadosExamesAtendimentoAtualPrimeiroTrimestre = Object.values(
    resultadosExamesAtendimentoAtual?.resultadosSemSolicitacao ?? {}
  )?.filter((exame) =>
    isBetween(parseISO(exame.dataRealizado), {
      start: dum,
      end: ultimoDiaDoPrimeiroTrimestreGestacao,
    })
  )

  const [listaEcoOrdenadaDesc, hasExamesPreNatalAtendimento, hasdppEcoAtendimento] = getListaEcoOrdenadaDesc(
    resultadosExamesAnterioresPrimeiroTrimestre,
    dum,
    resultadosExamesAtendimentoAtualPrimeiroTrimestre,
    hasDadoPreNatalPreenchido
  )

  const [dataInicioGestacao, dataProvavelParto, isEcografico] = getInicioGestacaoEDataProvavelParto(
    dum,
    listaEcoOrdenadaDesc
  )

  valoresComMedicaoAtual?.forEach((valores, index) => {
    medicoes.push({
      numeroConsulta: index + 1,
      dataDaMedicao: format(valores?.medicoes[0]?.dataMedicao, 'yyyy-MM-dd'),
      movimentacaoFetal: valores?.atendimentoProfissionalPreNatal?.movimentacaoFetal,
      edema: tipoEdemaRecord[valores?.atendimentoProfissionalPreNatal?.tipoEdema],
      batimentoCardiacoFetal: valores?.medicoes[0]?.valorBatimentoCardiacoFetal,
      alturaUterina: valores?.medicoes[0]?.valorAlturaUterina,
      isRegistradoAgora: false,
    })
  })

  hasMedicoesCacheValues &&
    medicoes.push({
      numeroConsulta: medicoes.length + 1,
      dataDaMedicao: format(dataAtendimento, 'yyyy-MM-dd'),
      movimentacaoFetal:
        preNatalAtendimentoAtual?.movimentacaoFetal && preNatalAtendimentoAtual.movimentacaoFetal === SimNaoEnum.SIM,
      edema: tipoEdemaRecord[preNatalAtendimentoAtual?.edema],
      batimentoCardiacoFetal: preNatalAtendimentoAtual?.batimentoCardiacoFetal?.toString(),
      alturaUterina: preNatalAtendimentoAtual?.alturaUterina?.toString(),
      isRegistradoAgora: true,
    })

  medicoes = orderBy(medicoes, 'numeroConsulta', 'desc')
  return {
    medicoesPreNatal: medicoes,
    dataProvavelPartoEcografica: dataProvavelParto,
    dum: dum,
    dataInicioGestacao,
    isEcografico,
    hasExamesPreNatalAtendimento,
    hasdppEcoAtendimento,
    origemDum,
  } as HistoricoPreNatalModel
}

export function convertToGrafico(valores: MedicoesPreNatal[]): HistoricoMedicaoModel[] {
  return valores.map((it) => ({
    dataMedicao: parseISO(it.dataDaMedicao),
    valorAlturaUterina: parseInt(it.alturaUterina, 10),
  }))
}

export function getAltoRisco(atendimentos?: AtendimentoProfUltimaGestacao[]) {
  return atendimentos?.some((atendProf) => atendProf.atendimentoProfissionalPreNatal?.preNatal?.altoRisco)
}
