import { useAlergiasCardQuery } from 'graphql/hooks.generated'
import { GrauCertezaAlergia, TipoAtendimentoProfissional } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React, { useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router'
import { MetaArray } from 'util/metaPath'
import { ListaAlergiasModal } from 'view/atendimentos/detail/components/modals/alergias/ListaAlergiasModal'

import { AlergiaReacaoModel } from '../../avaliacao/components/alergias-reacoes/model'
import { ProntuarioCard } from '../ProntuarioCard'
import { AlergiasCard } from './AlergiasCard'
import formatAlergias from './formatAlergias'

export interface AlergiasSectionProps {
  fieldName?: MetaArray<AlergiaReacaoModel>
  prontuarioId: ID
  alergiasAtendimentoAtual?: AlergiaReacaoModel[]
  expanded?: boolean
}

export function AlergiasSection({
  prontuarioId,
  alergiasAtendimentoAtual,
  fieldName: name,
  expanded = true,
}: AlergiasSectionProps) {
  const history = useHistory()
  const match = useRouteMatch()
  const [canRender, setCanRender] = useState(false)
  const modalUrl = `${match.path}/lista-alergias`

  const handleClick = () => history.push(modalUrl)

  const { data, loading } = useAlergiasCardQuery({
    variables: {
      filtro: {
        prontuarioId: prontuarioId,
        grausCerteza: [GrauCertezaAlergia.CONFIRMADO, GrauCertezaAlergia.SUSPEITO, GrauCertezaAlergia.NAO_INFORMADO],
      },
      prontuarioId: prontuarioId,
    },
  })

  const todasAlergias = formatAlergias(data?.alergias?.content || [], alergiasAtendimentoAtual)
  const alergiasExpandido = todasAlergias.getAlergiasExpandido()
  const alergiasAccordion = todasAlergias.getAlergiasAccordion()

  useEffect(() => {
    setCanRender(alergiasExpandido.length !== 0)
  }, [alergiasExpandido])

  const atendimento = useAtendimentoContext()
  const { cidadao, atendimentoProfissional } = atendimento || {}
  const canEdit = atendimento && name
  const tooltip = todasAlergias?.getAlergias()?.length
    ? `${canRender ? 'ver' : ''}${canRender && canEdit ? ' ou ' : ''}${
        canEdit ? 'adicionar' : ''
      } alergias/reações adversas`.capitalize()
    : 'Nenhuma alergia/reação adversa registrada'

  return (
    <>
      <Route
        path={modalUrl}
        render={(renderProps) =>
          canEdit ? (
            <ListaAlergiasModal
              {...renderProps}
              editable
              fieldName={name}
              prontuarioId={prontuarioId}
              alergiasAtendimentoAtual={alergiasAtendimentoAtual}
              cidadao={cidadao}
              dataAtendimento={Number(atendimentoProfissional.iniciadoEm)}
              isAtendimentoProcedimentos={
                atendimentoProfissional.tipo === TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS
              }
            />
          ) : (
            <ListaAlergiasModal
              {...renderProps}
              prontuarioId={prontuarioId}
              alergiasAtendimentoAtual={alergiasAtendimentoAtual}
              cidadao={cidadao}
            />
          )
        }
      />
      <ProntuarioCard title='Alergias/Reações adversas' tooltip={tooltip} onClick={handleClick}>
        <AlergiasCard
          alergiasExpandido={alergiasExpandido}
          alergiasAccordion={alergiasAccordion}
          loading={loading}
          canRender={canRender}
          expanded={expanded}
        />
      </ProntuarioCard>
    </>
  )
}
