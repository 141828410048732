import api from 'api'
import { ImpressaoFichaSinanInput } from 'api/FichaSinan'
import { downloadFile } from 'util/downloadFile'

export const downloadFichaSinan = (input: ImpressaoFichaSinanInput) => {
  return api.fichaSinan
    .gerarFichaSinan(input)
    .then((response) => {
      downloadFile(new Blob([response.data]), 'FichaNotificacaoCasoSuspeito_' + new Date().getTime() + '.pdf')
      return response
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('loadend', (e) => {
          reject((e.srcElement as any).result)
        })
        reader.readAsText(error.response.data)
      })
    })
}
