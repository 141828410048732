/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HeadingSection, TabItem, Tabs, VFlow } from 'bold-ui'
import { ErrorField } from 'components/form'
import { toDate } from 'date-fns'
import { Fragment, useMemo, useState } from 'react'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'

import { OdontogramaFieldModel } from './components/odontograma-field/OdontogramaField'
import { OdontogramaFieldWrapper } from './components/odontograma-field/OdontogramaFieldWrapper'
import PeriodontiaView, { PeriodontiaModel } from './periograma/PeriogramaView'
import TecidosMolesField, { TecidosMolesFieldModel } from './tecidos-moles/TecidosMolesField'

export interface OdontologiaModel {
  odontograma?: OdontogramaFieldModel
  tecidosMoles?: TecidosMolesFieldModel[]
  periodontia?: PeriodontiaModel
  limiteProcedimentos?: string
}

interface EvolucoesOdontologicasViewProps {
  name: MetaPath<OdontologiaModel>
  prontuarioId: ID
  cidadaoDataNascimento: LocalDate
  dataInicioAtendimento: Instant
}

enum ActiveTab {
  ODONTOGRAMA,
  TECIDOS_MOLES,
  PERIODONTIA,
}

export default function EvolucoesOdontologicasView(props: EvolucoesOdontologicasViewProps) {
  const { name, prontuarioId, cidadaoDataNascimento, dataInicioAtendimento } = props
  const [activeTab, setActiveTab] = useState<ActiveTab>(ActiveTab.ODONTOGRAMA)
  const dataAtendimentoAsLocalDate = useMemo(() => dateAsYyyyMmDd(toDate(dataInicioAtendimento)), [
    dataInicioAtendimento,
  ])

  return (
    <VFlow>
      <HeadingSection level={3} title='Evoluções odontológicas'>
        <ErrorField
          name={name.limiteProcedimentos}
          style={css`
            margin-bottom: 0.5rem;
            margin-top: -0.5rem;
          `}
        />
        <Fragment>
          <Tabs>
            <TabItem active={activeTab === ActiveTab.ODONTOGRAMA} onClick={() => setActiveTab(ActiveTab.ODONTOGRAMA)}>
              Odontograma
            </TabItem>

            <TabItem
              active={activeTab === ActiveTab.TECIDOS_MOLES}
              onClick={() => setActiveTab(ActiveTab.TECIDOS_MOLES)}
            >
              Tecidos moles e duros
            </TabItem>

            <TabItem active={activeTab === ActiveTab.PERIODONTIA} onClick={() => setActiveTab(ActiveTab.PERIODONTIA)}>
              Periodontia
            </TabItem>
          </Tabs>

          <div
            css={css`
              padding: 1rem 0;
            `}
          >
            {activeTab === ActiveTab.ODONTOGRAMA && (
              <OdontogramaFieldWrapper
                name={name.odontograma}
                prontuarioId={prontuarioId}
                cidadaoDataNascimento={cidadaoDataNascimento}
                dataInicioAtendimento={dataAtendimentoAsLocalDate}
              />
            )}

            {activeTab === ActiveTab.TECIDOS_MOLES && (
              <TecidosMolesField
                name={name.tecidosMoles}
                cidadaoDataNascimento={cidadaoDataNascimento}
                dataAtendimento={dataAtendimentoAsLocalDate}
              />
            )}

            {activeTab === ActiveTab.PERIODONTIA && (
              <PeriodontiaView
                name={name.periodontia}
                prontuarioId={prontuarioId}
                cidadaoDataNascimento={cidadaoDataNascimento}
                dataAtendimento={dataAtendimentoAsLocalDate}
              />
            )}
          </div>
        </Fragment>
      </HeadingSection>
    </VFlow>
  )
}
