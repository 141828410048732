/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { PageParams } from 'api/schema'
import { Button, HFlow, Icon } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { FormState } from 'final-form'
import { useHistory, useRouteMatch } from 'react-router'
import { metaPath } from 'util/metaPath'

import { grupoCboListaProblemasCondicoes } from '../../components/modals/lista-problemas/acessos'

interface AntecedentesProblemasResolvidosTableFilterProps {
  onChange(values: string): void
}

export interface AntecedentesProblemasResolvidosPageFilter {
  pageParams?: PageParams
  query?: string
}

const name = metaPath<AntecedentesProblemasResolvidosPageFilter>()

export const AntecedentesProblemasResolvidosTableFilter = (props: AntecedentesProblemasResolvidosTableFilterProps) => {
  const { onChange } = props
  const classes = createStyles()
  const match = useRouteMatch()
  const history = useHistory()

  const handleDebounceChange = (formState: FormState<AntecedentesProblemasResolvidosPageFilter>) => {
    onChange(formState.values.query)
  }

  const handleClick = () => history.push(`${match.url}/antecedentes/lista-problemas-condicoes`)

  const renderForm = () => {
    return (
      <HFlow alignItems='center' justifyContent='space-between'>
        <FormDebouncedValueSpy onChange={handleDebounceChange} />
        <TextField
          name={name.query}
          placeholder='Pesquise por Problema/Condição resolvida'
          icon='zoomOutline'
          style={classes.searchField}
        />
        <CheckPermission permission={grupoCboListaProblemasCondicoes.adicionar}>
          <Button kind='primary' size='small' onClick={handleClick}>
            <Icon icon='plus' style={classes.buttonIcon} />
            Adicionar antecedente resolvido
          </Button>
        </CheckPermission>
      </HFlow>
    )
  }

  return <Form<AntecedentesProblemasResolvidosPageFilter> render={renderForm} />
}

const createStyles = () => ({
  searchField: css`
    width: 20rem;
  `,
  buttonIcon: css`
    margin-right: 0.5rem;
  `,
})
