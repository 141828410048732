import { Cell, FormLabel, TabItem, Tabs, VFlow } from 'bold-ui'
import { ErrorField } from 'components/form'
import { usePecField } from 'components/form/final-form/useField'
import { TipoFrequencia, TurnoEnum, UnidadeMedidaTempoEnum } from 'graphql/types.generated'
import React, { useCallback, useMemo } from 'react'
import { metaPath } from 'util/metaPath'

import TipoFrequenciaContent from '../components/TipoFrequenciaContent'
import { MedicamentoFormModel } from '../MedicamentoFormModel'

const path = metaPath<MedicamentoFormModel>()

export interface FrequenciaTabsProps {
  doseUnica: boolean
  onChange(name: string, value?: any): void
}

export default function FrequenciaTabs({ doseUnica, onChange }: FrequenciaTabsProps) {
  const {
    input: { value, onChange: onChangeTab },
  } = usePecField({ name: path.tipoFrequencia })

  const setValues = useCallback(
    (tipoFrequencia: TipoFrequencia) => {
      const isFrequenciaOuTurnoSemDoseUnica =
        (tipoFrequencia === TipoFrequencia.FREQUENCIA || tipoFrequencia === TipoFrequencia.TURNO) && !doseUnica

      onChange(path.intervaloDose.alias, null)
      onChange(path.frequenciaDose.alias, null)
      onChange(path.turno.alias, tipoFrequencia === TipoFrequencia.TURNO ? TurnoEnum.MANHA : null)
      onChange(path.quantidadePeriodoFrequenciaTurno.alias, isFrequenciaOuTurnoSemDoseUnica ? 1 : null)
      onChange(
        path.unidadeMedidaTempoFrequenciaTurno.alias,
        isFrequenciaOuTurnoSemDoseUnica ? UnidadeMedidaTempoEnum.DIAS : null
      )
    },
    [doseUnica, onChange]
  )

  const handleOnChange = useCallback(
    (tipoFrequencia: TipoFrequencia) => {
      onChangeTab(tipoFrequencia)
      onChange && setValues(tipoFrequencia)
    },
    [onChange, onChangeTab, setValues]
  )

  return useMemo(
    () => (
      <>
        <Cell size={12}>
          <VFlow vSpacing={0.5}>
            <FormLabel label='Frequência da dose' required={!doseUnica} />
            <Tabs>
              <TabItem
                active={value === TipoFrequencia.INTERVALO}
                onClick={() => handleOnChange(TipoFrequencia.INTERVALO)}
                data-testid='IntervaloTab'
              >
                Intervalo
              </TabItem>
              <TabItem
                active={value === TipoFrequencia.FREQUENCIA}
                onClick={() => handleOnChange(TipoFrequencia.FREQUENCIA)}
                data-testid='FrequenciaTab'
              >
                Frequência
              </TabItem>
              <TabItem
                active={value === TipoFrequencia.TURNO}
                onClick={() => handleOnChange(TipoFrequencia.TURNO)}
                data-testid='TurnoTab'
              >
                Turno
              </TabItem>
            </Tabs>
          </VFlow>
        </Cell>
        <Cell size={12} data-testid='AlertDuracaoTratamento'>
          <ErrorField name={path.frequenciaDuracao} ignoreTouchedAndDirty type='alert' />
        </Cell>
        <Cell size={12} data-testid='FrequenciaDose'>
          <TipoFrequenciaContent tipoFrequencia={value} path={path} doseUnica={doseUnica} />
        </Cell>
      </>
    ),
    [doseUnica, value, handleOnChange]
  )
}
