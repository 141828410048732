import { Text, TextColor } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { Agendado, OrigemAgendamentoEnum } from 'graphql/types.generated'
import React, { Fragment } from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'

import { LotacaoAgendamento } from '../model'
import { lotacaoAgendamentoToString } from '../util'

export interface AgendamentoCriacaoProps extends Pick<Agendado, 'dataCriacao' | 'origem'> {
  lotacaoCriadora?: LotacaoAgendamento
  color?: TextColor
}

export function AgendamentoCriacao(props: AgendamentoCriacaoProps) {
  const { color, dataCriacao, lotacaoCriadora, origem } = props

  const isAgendadoPeloCidadao = origem === OrigemAgendamentoEnum.MEUDIGISUS

  return (
    <HLabel
      title={`Adicionado ${dataCriacao ? `em ${formatDateAndHoursMinutes(dataCriacao)}` : ''} por:`}
      color={color}
    >
      {isAgendadoPeloCidadao ? (
        <Fragment>
          Cidadão via{' '}
          <Text fontStyle='italic' color={color}>
            Conecte SUS Cidadão
          </Text>
        </Fragment>
      ) : (
        lotacaoAgendamentoToString(lotacaoCriadora)
      )}
    </HLabel>
  )
}
