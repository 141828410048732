/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, HFlow, InfoLabel, Text } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { ButtonLink } from 'components/route'
import { Antecedente } from 'graphql/types.generated'
import { Fragment } from 'react'
import { useRouteMatch } from 'react-router'
import { tipoGravidezRecord, tipoPartoRecord } from 'types/enums'

import { PrenatalPartoNascimentoModel } from '../../objetivo/puericultura/model'
import { idadeGestacionalToString } from './util/convertToLabel'

interface PreNatalPartoNascimentoAcompanhamentoPuericulturaProps {
  closeModal(): void
  preNatalPartoNascimento?: Antecedente
  prenatalPartoNascimentoAtendimento: PrenatalPartoNascimentoModel
  isAntecedentes: Boolean
}

export function PreNatalPartoNascimentoAcompanhamentoPuericultura(
  props: PreNatalPartoNascimentoAcompanhamentoPuericulturaProps
) {
  const { closeModal, preNatalPartoNascimento, prenatalPartoNascimentoAtendimento, isAntecedentes } = props
  const match = useRouteMatch()

  return (
    <Fragment>
      <HFlow
        justifyContent='space-between'
        style={css`
          margin-top: 1rem;
        `}
      >
        <Heading level={2}>Pré-natal, parto e nascimento</Heading>
        {!isAntecedentes && (
          <ButtonLink
            to={`${match.url}/historicoMedicoes`}
            onClick={closeModal}
            type='button'
            skin='outline'
            kind='primary'
            size='small'
          >
            Visualizar medições
          </ButtonLink>
        )}
      </HFlow>
      <Grid
        style={css`
          margin-top: 0.5rem;
          margin-bottom: 1rem;
        `}
      >
        <Cell size={3}>
          <InfoLabel title='Tipo de gravidez' placeholder='—'>
            {
              tipoGravidezRecord[
                prenatalPartoNascimentoAtendimento?.tipoGravidez?.value || preNatalPartoNascimento?.tipoGravidez
              ]
            }
          </InfoLabel>
        </Cell>
        <Cell size={3}>
          <InfoLabel title='Tipo de parto' placeholder='—'>
            {
              tipoPartoRecord[
                prenatalPartoNascimentoAtendimento?.tipoParto?.value || preNatalPartoNascimento?.tipoParto
              ]
            }
          </InfoLabel>
        </Cell>
        <Cell size={3}>
          <InfoLabel title='Idade gestacional (IG)' placeholder='—'>
            {idadeGestacionalToString(
              prenatalPartoNascimentoAtendimento?.idadeGestacional?.semanas,
              prenatalPartoNascimentoAtendimento?.idadeGestacional?.dias
            ) ||
              idadeGestacionalToString(
                preNatalPartoNascimento?.idadeGestacionalSemanas,
                preNatalPartoNascimento?.idadeGestacionalDias
              )}
          </InfoLabel>
        </Cell>
        <Cell size={3} />
        <Cell
          size={12}
          style={css`
            margin-bottom: -1rem;
          `}
        >
          <Text fontWeight='bold'>Apgar</Text>
        </Cell>
        <Cell size={3}>
          <HLabel title='1º min: '>
            {prenatalPartoNascimentoAtendimento?.apgar?.apgarUm || preNatalPartoNascimento?.apgarUm}
          </HLabel>
        </Cell>
        <Cell size={3}>
          <HLabel title='5º min: '>
            {prenatalPartoNascimentoAtendimento?.apgar?.apgarCinco || preNatalPartoNascimento?.apgarCinco}
          </HLabel>
        </Cell>
        <Cell size={3}>
          <HLabel title='10º min: '>
            {prenatalPartoNascimentoAtendimento?.apgar?.apgarDez || preNatalPartoNascimento?.apgarDez}
          </HLabel>
        </Cell>
        <Cell size={3} />
      </Grid>
    </Fragment>
  )
}
