import { Button, Cell, Grid, HFlow, Icon, Tooltip, useStyles } from 'bold-ui'
import useFirebase from 'components/firebase/useFirebase'
import { Form, FormDebouncedValueSpy, SwitchField, TextField } from 'components/form'
import { PopperButton, PopperControls } from 'components/popper'
import { FormState } from 'final-form'
import { isEmpty, isEqual } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { createFilterStyles } from '../listaUtils'
import { ListaAtendimentoFilterPopperForm, ListaAtendimentoFilterPopperModel } from './ListaAtendimentoFilterPopperForm'
import { ListaAtendimentoFilterTags } from './ListaAtendimentoFilterTags'
import { ListaAtendimentoSortDropdown } from './ListaAtendimentoSortDropdown'

export interface ListaAtendimentoFilterProps {
  filter: ListaAtendimentoFilterModel
  filterBackup: ListaAtendimentoFilterPopperModel
  filterDefault: ListaAtendimentoFilterModel
  onChangeFilter(values: ListaAtendimentoFilterModel): void
  onClear(): void
  updateFilterBackup(values: ListaAtendimentoFilterPopperModel): void
  deleteFilterBackup(): void
}

export type ListaAtendimentoFilterModel = ListaAtendimentoInlineFilterModel & ListaAtendimentoFilterPopperModel

export interface ListaAtendimentoInlineFilterModel {
  query?: string
  somenteMeusAtendimentos?: boolean
  registroTardio?: boolean
}

const mountInitialValues = (values: ListaAtendimentoFilterPopperModel): ListaAtendimentoFilterModel => {
  return {
    ...values,
    periodo: values.somenteNaoFinalizados
      ? {
          startDate: undefined,
          endDate: undefined,
        }
      : {
          startDate: values.periodo?.startDate ? moment(values.periodo.startDate).toDate() : undefined,
          endDate: values.periodo?.endDate ? moment(values.periodo.endDate).toDate() : undefined,
        },
  }
}

export function ListaAtendimentoFilter(props: ListaAtendimentoFilterProps) {
  const { filter, filterDefault, filterBackup, onChangeFilter, updateFilterBackup, deleteFilterBackup, onClear } = props
  const { query, somenteMeusAtendimentos, ...restFilter } = filter
  const { analytics } = useFirebase()

  const [countFiltrosAplicados, setCountFiltrosAplicados] = useState(0)
  const [isFilterEqual, setIsFilterEqual] = useState(false)

  const { classes } = useStyles(createFilterStyles)

  const initialValues = mountInitialValues(isEmpty(filterBackup) ? restFilter : filterBackup)

  const defaultPeriodo = useMemo(() => filterDefault.periodo, [filterDefault.periodo])

  const handleOnClear = () => {
    onClear()
    analytics.logEvent('voltar_filtro_padrao_LA')
  }

  const handleDebounceChange = (formState: FormState<ListaAtendimentoInlineFilterModel>) => {
    onChangeFilter({ ...filter, ...formState.values, query: formState.values.query })
  }

  const handlePopperFormSubmit = useCallback(
    (values: ListaAtendimentoFilterPopperModel) => {
      deleteFilterBackup()
      onChangeFilter({ ...filter, ...values })
    },
    [deleteFilterBackup, filter, onChangeFilter]
  )

  const renderFilterForm = () => {
    return (
      <>
        <FormDebouncedValueSpy onChange={handleDebounceChange} />
        <Grid alignItems='center'>
          <Cell size={5} xl={4} style={classes.cell1}>
            <TextField
              name='query'
              placeholder='Pesquise por nome, CPF, CNS ou data de nascimento'
              icon='zoomOutline'
            />
          </Cell>
          <Cell size={7} xl={8} style={classes.cell2}>
            <HFlow style={classes.hFlow}>
              <SwitchField
                style={{
                  display: 'grid',
                  gridAutoFlow: 'column',
                  whiteSpace: 'nowrap',
                  justifyContent: 'start',
                  maxWidth: '16.5rem',
                }}
                name='somenteMeusAtendimentos'
                label='Ver somente os meus atendimentos'
              />
              <ListaAtendimentoSortDropdown filter={filter} onChange={onChangeFilter} />
            </HFlow>
          </Cell>
        </Grid>
      </>
    )
  }

  const renderPopper = (ctrl: PopperControls) => (
    <ListaAtendimentoFilterPopperForm
      defaultPeriodo={defaultPeriodo}
      initialValues={initialValues}
      onSubmit={handlePopperFormSubmit}
      onFechar={ctrl.close}
      onClear={onClear}
      onChangeFilter={updateFilterBackup}
      isFilterEqual={isFilterEqual}
    />
  )

  useEffect(() => {
    const start =
      filter.periodo?.startDate && moment(filter.periodo.startDate).isSame(filterDefault.periodo.startDate, 'day')
    const end = filter.periodo?.endDate && moment(filter.periodo.endDate).isSame(filterDefault.periodo.endDate, 'day')

    const rest = isEqual(
      {
        ...filter,
        statusAtendimento: (filter?.statusAtendimento || []).sort(),
        periodo: undefined,
        query: filter.query || undefined,
      } as ListaAtendimentoFilterModel,
      {
        ...filterDefault,
        statusAtendimento: filterDefault.statusAtendimento?.sort(),
        periodo: undefined,
        query: undefined,
      }
    )

    setIsFilterEqual(start && end && rest)
  }, [filter, filterDefault])

  useEffect(() => {
    setCountFiltrosAplicados(
      (filter?.statusAtendimento?.length || 0) +
        (filter?.tiposServico?.length || 0) +
        (filter?.equipes?.length || 0) +
        (filter?.lotacoes?.length || 0) +
        (filter?.somenteNaoFinalizados ? 1 : 0) +
        (filter?.periodo?.endDate && filter?.periodo?.startDate ? 1 : 0)
    )
  }, [filter])

  return (
    <Grid gap={0.5} gapVertical={0.5}>
      <Cell size={12}>
        <HFlow style={classes.hFlow}>
          <Form<ListaAtendimentoInlineFilterModel>
            onSubmit={onChangeFilter}
            render={renderFilterForm}
            initialValues={{ query, somenteMeusAtendimentos }}
          />
          <PopperButton
            kind='primary'
            size='small'
            skin='default'
            placement='bottom-end'
            renderPopper={renderPopper}
            onClose={deleteFilterBackup}
          >
            <Icon icon='filterOutline' />
            {'Filtros' + (countFiltrosAplicados > 0 ? ` (${countFiltrosAplicados})` : '')}
          </PopperButton>
        </HFlow>
      </Cell>

      <Cell size={12}>
        <HFlow alignItems='center' justifyContent='space-between' style={{ gridAutoColumns: 'minmax(0, auto)' }}>
          <ListaAtendimentoFilterTags filtros={filter} onChange={onChangeFilter} />

          <Tooltip text='Redefinir filtros para o padrão'>
            <Button kind='primary' skin='outline' size='small' disabled={isFilterEqual} onClick={handleOnClear}>
              <Icon style={{ paddingRight: '0.5rem' }} icon='redo' />
              Voltar para padrão
            </Button>
          </Tooltip>
        </HFlow>
      </Cell>
    </Grid>
  )
}
