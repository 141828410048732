import { Cell, Grid } from 'bold-ui'
import {
  CnsField,
  ConselhoClasseSelectField,
  CpfField,
  DateField,
  SexoSelectField,
  TelefoneField,
  TextField,
  UfSelectField,
} from 'components/form'
import { resolveValue } from 'components/form/final-form/useField'
import { useServerTime } from 'hooks/useServerTime'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { MetaPath, MetaRoot } from 'util/metaPath'
import {
  cns,
  cpf,
  createValidator,
  dataNasc,
  email,
  ErrorObject,
  maxLength,
  nome,
  required,
  telefone,
} from 'util/validation'

import ProfissionalFormModel from './ProfissionalFormModel'

export const createDadosValidator = (possuiLotacaoAtiva: boolean) =>
  createValidator<ProfissionalFormModel>(
    {
      nome: [required, nome],
      cpf: [required, cpf],
      cns: [cns],
      email: [email, maxLength(255)],
      telefone: [telefone],
      dataNascimento: [dataNasc],
    },
    (values) => {
      const errors: ErrorObject<ProfissionalFormModel> = {}

      if (possuiLotacaoAtiva && !errors.cns) {
        errors.cns = required(values.cns)
      }

      if (values && values.conselhoClasse) {
        errors.numeroConselhoClasse = required(values.numeroConselhoClasse)
        errors.ufEmissoraConselhoClasse = required(values.ufEmissoraConselhoClasse)
      }

      return errors
    }
  )

export interface ProfissionalDadosFieldGroupProps {
  name: MetaPath<ProfissionalFormModel> | MetaRoot<ProfissionalFormModel>
  edit?: boolean
  possuiLotacaoAtiva?: boolean
  formProps: FormRenderProps<any>
}

export function ProfissionalDadosFieldGroup(props: ProfissionalDadosFieldGroupProps) {
  const { name, edit, possuiLotacaoAtiva, formProps } = props
  const values = resolveValue(formProps.values, name) || ({} as ProfissionalFormModel)
  const { getServerTimeNow } = useServerTime()
  const now = getServerTimeNow()

  const clearConselhoClasse = () => {
    formProps.form.change(name.ufEmissoraConselhoClasse.absolutePath(), null)
    formProps.form.change(name.numeroConselhoClasse.absolutePath(), null)
  }

  return (
    <Grid wrap>
      <Cell size={6}>
        <TextField name={name.nome} label='Nome completo' required />
      </Cell>
      <Cell size={6} />
      <Cell size={3}>
        <CpfField name={name.cpf} label='CPF' required disabled={edit} />
      </Cell>
      <Cell size={3}>
        <CnsField name={name.cns} label='CNS' required={possuiLotacaoAtiva} clearable={false} />
      </Cell>
      <Cell size={6} />
      <Cell size={3}>
        <DateField name={name.dataNascimento} maxDate={now} label='Data de nascimento' />
      </Cell>
      <Cell size={3}>
        <SexoSelectField name={name.sexo} label='Sexo' />
      </Cell>
      <Cell size={6} />
      <Cell size={3}>
        <ConselhoClasseSelectField
          name={name.conselhoClasse}
          label='Conselho de classe'
          onChange={clearConselhoClasse}
        />
      </Cell>
      <Cell size={3}>
        <UfSelectField
          name={name.ufEmissoraConselhoClasse}
          label='Estado emissor'
          disabled={!values.conselhoClasse}
          required={!!values.conselhoClasse}
        />
      </Cell>
      <Cell size={3}>
        <TextField
          name={name.numeroConselhoClasse}
          label='Registro no conselho de classe'
          disabled={!values.conselhoClasse}
          required={!!values.conselhoClasse}
          maxLength={10}
        />
      </Cell>
      <Cell size={6}>
        <TextField name={name.email} label='E-mail' lowercase />
      </Cell>
      <Cell size={2}>
        <TelefoneField name={name.telefone} label='Telefone' />
      </Cell>
    </Grid>
  )
}
