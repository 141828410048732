/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { colors, Theme, useTheme, VFlow } from 'bold-ui'
import { ModalBody } from 'components/modals/ModalBody'
import { ErrorObject } from 'util/validation'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { convertSoapErrorsToErrorItemList } from './converter'
import { SoapValidationSectionError } from './SoapValidationSectionError'

interface SoapValidationErrorsModalProps {
  errors: ErrorObject<SoapState>
}

export const SoapValidationErrorsModal = (props: SoapValidationErrorsModalProps) => {
  const { errors } = props

  const theme = useTheme()
  const classes = createStyles(theme)

  const { subjetivo, antecedentes, objetivo, avaliacao, plano, desfecho, preNatal } = convertSoapErrorsToErrorItemList(
    errors
  )

  return (
    <ModalBody
      type='danger'
      icon='banOutline'
      iconSize={4}
      title='Para finalizar o atendimento é necessário corrigir o(s) registro(s):'
      titleLevel={1}
      style={classes.modalBody}
    >
      <VFlow>
        <SoapValidationSectionError section={antecedentes} />
        <SoapValidationSectionError section={subjetivo} color={colors.pink.c40} />
        <SoapValidationSectionError section={objetivo} color={colors.blue.c50} />
        <SoapValidationSectionError section={preNatal} color={colors.purple.c60} />
        <SoapValidationSectionError section={avaliacao} color={colors.yellow.c40} />
        <SoapValidationSectionError section={plano} color={colors.green.c40} />
        <SoapValidationSectionError section={desfecho} />
      </VFlow>
    </ModalBody>
  )
}

const createStyles = (theme: Theme) => ({
  modalBody: css`
    width: 40rem;

    ${theme.breakpoints.down('lg')} {
      width: 32.5rem;
    }
  `,
})
