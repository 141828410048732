/**
 * Retorna uma representação de strings a partir de uma
 * lista de strings no formato 'palavra', 'palavra1 e palavra2'
 * ou 'palavra1, ..., palavraN-1 e palavraN'
 *
 * @param strings uma lista de strings a serem agropadas
 */

export const stringGrouper = (...strings: string[]): string | null => {
  const filtered: string[] = strings.filter((i) => i != null)
  if (filtered.length <= 0) return null
  if (filtered.length === 1) return filtered[0]
  return [filtered.splice(0, filtered.length - 1).join(', '), ...filtered].join(' e ')
}
