/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Cell,
  DataTable,
  Grid,
  Heading,
  HFlow,
  isEmpty,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  VFlow,
} from 'bold-ui'
import { HLabel } from 'components/HLabel'
import {
  AtencaoDomiciliarCidadao,
  AtencaoDomiciliarCondutaDesfecho,
  AtendimentoProfissionalAtencaoDomiciliar,
  HistoricoAtendimentoDomiciliarAdQuery,
} from 'graphql/types.generated'
import { partition } from 'lodash'
import { Fragment } from 'react'
import { formatDate } from 'util/date/formatDate'
import { stringGrouper } from 'util/string/string-grouper'
import { descriptions as condutaDescription } from 'view/ad/lista/selectFields/AtencaoDomiciliarCondutaDesfechoSelectField'
import { descriptions as origemDescription } from 'view/ad/lista/selectFields/AtencaoDomiciliarOrigemSelectField'
import { historicoSubsectionStyles, modalidadeCorRecord } from 'view/atendimentos/detail/historico/model/historicoModel'

import HistoricoDetailTable from '../../../../table/HistoricoDetailTable'

type AtendimentoProcedimento = HistoricoAtendimentoDomiciliarAdQuery['historicoAtendimentoPec']['procedimentosAtendimento'][0]

const colunasTabelaAcoes = [
  {
    name: 'procedimentos',
    header: 'Procedimentos',
    render: (item: AtendimentoProcedimento) =>
      item.procedimento && (
        <Text>
          {item.procedimento.descricao} - {item.procedimento.codigo}
        </Text>
      ),
    style: css`
      width: 50%;
      padding: 0.5rem 1rem;
    `,
  },
  {
    name: 'cid10',
    header: 'CID 10',
    render: (item: AtendimentoProcedimento) =>
      item.cid10Principal ? (
        <Text>
          {item.cid10Principal.nome} - {item.cid10Principal.codigo}
        </Text>
      ) : (
        <Text>-</Text>
      ),
  },
]

interface HistoricoAtendimentoDomiciliarDadosSectionPanelProps {
  atendimentoProfissionalAD: AtendimentoProfissionalAtencaoDomiciliar
  procedimentos: AtendimentoProcedimento[]
  cidadaoAD: Partial<AtencaoDomiciliarCidadao>
}

export default function HistoricoAtendimentoDomiciliarDadosSectionPanel(
  props: HistoricoAtendimentoDomiciliarDadosSectionPanelProps
) {
  const { atendimentoProfissionalAD, procedimentos, cidadaoAD } = props

  const [condicoesAtivas, condicoesInativas] = partition(
    atendimentoProfissionalAD.condicoesAvaliadasAD,
    (resposta) => resposta.situacaoAtiva
  )

  return (
    <Fragment>
      <VFlow vSpacing={1}>
        <Table hovered>
          <TableHead>
            <TableRow>
              <TableHeader>
                <Grid>
                  <Cell size={3}>
                    <Text fontWeight='bold'>Modalidade</Text>
                  </Cell>
                  <Cell size={3}>
                    <Text fontWeight='bold'>Procedência</Text>
                  </Cell>
                  <Cell size={6}>
                    <Text fontWeight='bold'>CID 10</Text>
                  </Cell>
                </Grid>
              </TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Grid
                  style={css`
                    padding: 0.5rem;
                  `}
                >
                  <Cell size={3}>
                    <Text
                      fontWeight='bold'
                      style={css`
                        color: ${modalidadeCorRecord[atendimentoProfissionalAD.modalidade]?.cor.c40};
                      `}
                    >
                      {modalidadeCorRecord[atendimentoProfissionalAD.modalidade]?.descricao}
                    </Text>
                  </Cell>
                  <Cell size={3}>{origemDescription[atendimentoProfissionalAD.origem]}</Cell>
                  <Cell size={6}>
                    <VFlow vSpacing={0.5}>
                      <Text>
                        {`${atendimentoProfissionalAD.cid10Principal.nome} -
                        ${atendimentoProfissionalAD.cid10Principal.codigo}`}
                      </Text>
                      <VFlow vSpacing={0}>
                        {atendimentoProfissionalAD.cid10Secundario1 && (
                          <HLabel title='Secundário 1'>
                            {`${atendimentoProfissionalAD.cid10Secundario1.nome} -
                        ${atendimentoProfissionalAD.cid10Secundario1.codigo}`}
                          </HLabel>
                        )}
                        {atendimentoProfissionalAD.cid10Secundario2 && (
                          <HLabel title='Secundário 2'>
                            {`${atendimentoProfissionalAD.cid10Secundario2.nome} -
                        ${atendimentoProfissionalAD.cid10Secundario2.codigo}`}
                          </HLabel>
                        )}
                      </VFlow>
                    </VFlow>
                  </Cell>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {!isEmpty(atendimentoProfissionalAD.condicoesAvaliadasAD) && (
          <HistoricoDetailTable title='Condições avaliadas' sectionStyle='D'>
            <VFlow vSpacing={0.5}>
              {!isEmpty(condicoesAtivas) && (
                <VFlow vSpacing={0}>
                  <Text fontWeight='bold' color='success'>
                    Ativos
                  </Text>
                  <Text>{stringGrouper(...condicoesAtivas.map((resposta) => resposta.questao.descricao))}</Text>
                </VFlow>
              )}
              {!isEmpty(condicoesInativas) && (
                <VFlow vSpacing={0}>
                  <Text fontWeight='bold' color='danger'>
                    Inativos
                  </Text>
                  <Text>{stringGrouper(...condicoesInativas.map((resposta) => resposta.questao.descricao))}</Text>
                </VFlow>
              )}
            </VFlow>
          </HistoricoDetailTable>
        )}
        <VFlow vSpacing={0.5}>
          <Heading level={3} style={historicoSubsectionStyles.dadosAtencaoDomiciliar}>
            Conduta/desfecho
          </Heading>
          <HFlow hSpacing={0.25}>
            <Text>{condutaDescription[atendimentoProfissionalAD.conduta]}</Text>
            {atendimentoProfissionalAD.conduta === AtencaoDomiciliarCondutaDesfecho.OBITO && (
              <Text>
                {formatDate(cidadaoAD.dataObito)} - {cidadaoAD.numeroDocumentoObito}
              </Text>
            )}
          </HFlow>
        </VFlow>
        <VFlow vSpacing={0.5}>
          <Heading level={3} style={historicoSubsectionStyles.dadosAtencaoDomiciliar}>
            Ações
          </Heading>
          <DataTable<AtendimentoProcedimento> rows={procedimentos} columns={colunasTabelaAcoes} />
        </VFlow>
      </VFlow>
    </Fragment>
  )
}
