import React, { useContext, useMemo } from 'react'

import { AtendimentoContextModel, AtendimentoModel } from './model'

interface AtendimentoContextProps {
  value: AtendimentoModel
  children: React.ReactNode
}

const convertAtendimentoModelToContext = (atendimento: AtendimentoModel): AtendimentoContextModel => ({
  ...atendimento,
  iniciadoEm: new Date(atendimento.iniciadoEm),
  atendimentoProfissional: {
    ...atendimento.atendimentoProfissional,
    iniciadoEm: new Date(atendimento.atendimentoProfissional.iniciadoEm),
  },
  cidadao: {
    ...atendimento.cidadao,
    desejaInformarIdentidadeGenero: atendimento.cidadao.desejaInformarIdentidadeGenero === true,
  },
})

export const AtendimentoContext = React.createContext<AtendimentoContextModel>(undefined)

export function AtendimentoProvider(props: AtendimentoContextProps) {
  const { value, children } = props

  const convertedValue = useMemo(() => convertAtendimentoModelToContext(value), [value])

  return <AtendimentoContext.Provider value={convertedValue}>{children}</AtendimentoContext.Provider>
}

export const useAtendimentoContext = () => useContext(AtendimentoContext)
