import { HFlow, Text } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { ImunobiologicoComboDocument } from 'graphql/hooks.generated'
import { ImunobiologicoComboQuery, ImunobiologicoComboQueryVariables, SexoEnum } from 'graphql/types.generated'
import React from 'react'

export type ImunobiologicoSelectModel = ImunobiologicoComboQuery['imunobiologicos']['content'][0]

export interface ImunobiologicoSelectFieldProps
  extends Omit<SelectFieldProps<ImunobiologicoSelectModel>, 'items' | 'itemToString'> {
  semRegras?: boolean
  sexo?: SexoEnum
  isRegistroAnterior?: boolean
  outrosImunobiologicos?: boolean
}

export function ImunobiologicoSelectField(props: ImunobiologicoSelectFieldProps) {
  const { semRegras, sexo, isRegistroAnterior, outrosImunobiologicos, ...rest } = props

  const asyncProps = useAsyncQuerySelect<
    ImunobiologicoSelectModel,
    ImunobiologicoComboQuery,
    ImunobiologicoComboQueryVariables
  >({
    query: ImunobiologicoComboDocument,
    extractItems: (data) => data?.imunobiologicos?.content,
    variables: (inputQuery: string) => ({
      input: {
        query: inputQuery,
        semRegras: semRegras,
        sexo: sexo,
        isRegistroAnterior: isRegistroAnterior,
        outrosImunobiologicos: outrosImunobiologicos,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          size: 10,
        },
      },
    }),
  })

  const itemToString = (item: ImunobiologicoSelectModel) => (item ? `${item.sigla} - ${item.nome}` : undefined)

  const renderItem = (item: ImunobiologicoSelectModel) => (
    <>
      <Text fontWeight='bold'>{item.sigla}</Text>
      <HFlow hSpacing={0.5}>
        <Text> {item.nome}</Text>
      </HFlow>
    </>
  )

  return (
    <SelectField<ImunobiologicoSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      {...asyncProps}
      {...rest}
    />
  )
}
