import { SelectField, SelectFieldProps } from 'components/form'
import { useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { RndsCertificadoSelectDocument } from 'graphql/hooks.generated'
import { RndsCertificadoSelectQuery, RndsCertificadoSelectQueryVariables } from 'graphql/types.generated'
import React from 'react'

export type RndsCertificadoSelectModel = RndsCertificadoSelectQuery['rndsCertificados']['content'][0]

export interface RndsCertificadoSelectFieldProps
  extends Omit<SelectFieldProps<RndsCertificadoSelectModel>, 'items' | 'itemToString'> {}

export function RndsCertificadoSelectField(props: RndsCertificadoSelectFieldProps) {
  const asyncProps = useAsyncQuerySelect<
    RndsCertificadoSelectModel,
    RndsCertificadoSelectQuery,
    RndsCertificadoSelectQueryVariables
  >({
    query: RndsCertificadoSelectDocument,
    extractItems: (data) => data && data.rndsCertificados && data.rndsCertificados.content,
    variables: (inputQuery: string) => ({
      input: {
        query: inputQuery,
      },
    }),
  })

  const itemToString = (option: RndsCertificadoSelectModel) => option && option.nome

  return <SelectField<RndsCertificadoSelectModel> itemToString={itemToString} {...asyncProps} {...props} />
}
