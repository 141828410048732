/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Text, Theme, useTheme, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { isBefore } from 'date-fns'
import { SituacaoAgendadoEnum } from 'graphql/types.generated'
import { useState } from 'react'
import { CancelarAgendamentoButton } from 'view/agenda/components/acoes-agendamento/buttons/CancelarAgendamentoButton'
import JustificativaCancelarAgendamentoModal from 'view/agenda/components/acoes-agendamento/JustificativaCancelarAgendamentoModal'
import { excluirAgendamentoTooltip } from 'view/agenda/components/acoes-agendamento/utils'
import { AgendamentoFooter, AgendamentoFooterProps } from 'view/agenda/components/AgendamentoFooter'
import { SituacaoAgendamentoBar } from 'view/agenda/components/SituacaoAgendamentoBar'

import { AgendamentoCidadaoModel, AgendamentoCidadaoPermissions, LotacaoAgendadaModel } from '../model'
import { AgendamentoCidadaoHeader } from './components/AgendamentoCidadaoHeader'
import { AgendamentoCidadaoLotacaoAgendada } from './components/AgendamentoCidadaoLotacaoAgendada'
import { convertAgendamentoCidadao } from './converter'
import { AgendamentoCidadaoStatus } from './model'

interface AgendamentosCidadaoListingItemProps extends Pick<AgendamentoFooterProps, 'isAgendaOnlineAtiva'> {
  agendamento: AgendamentoCidadaoModel
  permissions: AgendamentoCidadaoPermissions
  profissionalId: ID
  equipeId: ID
  ubsId: ID
  serverTime: Date
  onCancelar?: () => void
}

export function AgendamentosCidadaoListingItem(props: AgendamentosCidadaoListingItemProps) {
  const {
    agendamento,
    permissions: { podeCancelarTodasEquipes, podeCancelarAgendamento },
    profissionalId,
    equipeId,
    ubsId,
    serverTime,
    isAgendaOnlineAtiva,
    onCancelar,
  } = props

  const theme = useTheme()

  const [isModalJustificativaOpen, setModalJustificativaOpen] = useState(false)
  const styles = createStyles(theme)

  const isPassado = isBefore(agendamento.horarioInicial, serverTime)
  const { isMesmaUbs, isOutroProfissionalDeOutraEquipe } = getStatusAgendamentoCidadao(
    agendamento.lotacaoAgendada,
    profissionalId,
    equipeId,
    ubsId
  )
  const isAtencaoDomiciliar = agendamento.isAtencaoDomiciliar
  const showCancelar = agendamento.situacao === SituacaoAgendadoEnum.AGENDADO && podeCancelarAgendamento && isMesmaUbs
  const isCancelarDisabled =
    isPassado || isAtencaoDomiciliar || (isOutroProfissionalDeOutraEquipe && !podeCancelarTodasEquipes)

  const agendaEvent = convertAgendamentoCidadao(agendamento)

  return (
    <HFlow hSpacing={0} style={styles.wrapper} justifyContent='space-between'>
      <JustificativaCancelarAgendamentoModal
        onAction={onCancelar}
        event={agendaEvent}
        open={isModalJustificativaOpen}
        setOpen={setModalJustificativaOpen}
      />
      <HFlow hSpacing={0}>
        <SituacaoAgendamentoBar situacao={agendamento.situacao} />
        <VFlow vSpacing={0.5} style={styles.item}>
          <AgendamentoCidadaoHeader agendamento={agendamento} />
          <AgendamentoCidadaoLotacaoAgendada lotacaoAgendada={agendamento.lotacaoAgendada} />
          {agendamento.observacao && (
            <HLabel title='Observações:'>
              <Text fontStyle='italic'>{agendamento.observacao}</Text>
            </HLabel>
          )}
          <AgendamentoFooter
            color='secondary'
            lotacaoCriadora={agendamento.lotacaoCriadora}
            dataCriacao={agendamento.dataCriacao}
            origem={agendamento.origem}
            situacaoSincronizacao={agendamento.situacaoSincronizacao}
            isAgendaOnlineAtiva={isAgendaOnlineAtiva}
            justificativaCancelamento={agendamento.justificativaCancelamento}
          />
        </VFlow>
      </HFlow>
      <VFlow style={styles.cancelarButton}>
        {showCancelar && (
          <CancelarAgendamentoButton
            onAction={onCancelar}
            event={agendaEvent}
            openModalCancelarAgendamento={() => setModalJustificativaOpen(true)}
            disabled={isCancelarDisabled}
            tooltip={excluirAgendamentoTooltip(isPassado, isOutroProfissionalDeOutraEquipe, isAtencaoDomiciliar)}
          />
        )}
      </VFlow>
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  wrapper: css`
    background-color: ${theme.pallete.surface.main};
    border: 1px ${theme.pallete.divider} solid;
    border-radius: 2px;
  `,
  item: css`
    padding: 0.5rem 1rem;
  `,
  cancelarButton: css`
    margin-right: 0.5rem;
    margin-top: 0.5rem;
  `,
})

function getStatusAgendamentoCidadao(
  lotacaoAgendada: LotacaoAgendadaModel,
  profissionalId: ID,
  equipeId: ID,
  ubsId: ID
): AgendamentoCidadaoStatus {
  const isMesmaUbs = lotacaoAgendada.unidadeSaude.id === ubsId
  const isOutroProfissionalDeOutraEquipe =
    lotacaoAgendada.profissional.id !== profissionalId &&
    lotacaoAgendada.equipe !== null &&
    lotacaoAgendada.equipe.id !== equipeId
  return { isMesmaUbs, isOutroProfissionalDeOutraEquipe }
}
