import useSession from 'components/auth/useSession'
import { PageLoading } from 'components/loading'
import { useAtendimentoViewQuery } from 'graphql/hooks.generated'
import { StatusAtendimento, TipoAtendimentoProfissional } from 'graphql/types.generated'
import React from 'react'
import { useParams, useRouteMatch } from 'react-router'
import { Redirect } from 'react-router-dom'

import { urlFromRegistroTardio } from '../../registro-tardio/RegistroTardioRootView'
import { AtendimentoIndividualView } from '../atendimento-individual/AtendimentoIndividualView'
interface AtendimentoViewParams {
  atendimentoId: string
}

export function AtendimentoView() {
  const { atendimentoId } = useParams<AtendimentoViewParams>()
  const { data, loading } = useAtendimentoViewQuery({ variables: { id: atendimentoId } })
  const { data: sessao } = useSession()
  const { url } = useRouteMatch()

  const fromRegistroTardio = urlFromRegistroTardio(url)
  const moduloInvalido =
    (fromRegistroTardio && !data.atendimento?.registroTardio) ||
    (!fromRegistroTardio && data.atendimento?.registroTardio)

  const statusInvalido =
    data?.atendimento?.statusAtendimento === StatusAtendimento.ATENDIMENTO_REALIZADO ||
    data?.atendimento?.statusAtendimento === StatusAtendimento.NAO_AGUARDOU

  const tipoInvalido =
    data?.atendimento?.atendimentoProfissional?.tipo === TipoAtendimentoProfissional.ESCUTA_INICIAL ||
    data?.atendimento?.atendimentoProfissional?.tipo === TipoAtendimentoProfissional.VACINACAO

  if (loading) {
    return <PageLoading message='Carregando atendimento...' />
  } else if (statusInvalido || tipoInvalido || !data?.atendimento?.atendimentoProfissional || moduloInvalido) {
    return <Redirect to='/404' />
  } else if (data?.atendimento?.atendimentoProfissional?.lotacao.id !== sessao?.acesso.id) {
    return <Redirect to='/403' />
  } else {
    return <AtendimentoIndividualView atendimentoId={atendimentoId} />
  }
}
