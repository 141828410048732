/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ButtonGroup, HeadingSection, HFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { usePecField } from 'components/form/final-form/useField'
import { confirm } from 'components/modals/confirm'
import { formatISO } from 'date-fns'
import { usePuericulturaQuery } from 'graphql/hooks.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Fragment } from 'react'
import { useField } from 'react-final-form'
import { calculateAge } from 'util/date/calculateAge'
import { isCidadaoPuericultura } from 'util/isCidadaoPuericultura'
import { MetaPath } from 'util/metaPath'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { ProblemaCondicaoModel } from '../../avaliacao/components/problemas-condicoes/model'
import {
  informationPreNatalQuandoExisteDesfecho,
  informationPreNatalQuandoExistePuericultura,
  informationPuericulturaQuandoExisteDesfecho,
  informationPuericulturaQuandoExistePreNatal,
} from '../../avaliacao/components/problemas-condicoes/utils/messages'
import { hasProblemaCondicaoDePreNatal } from '../../avaliacao/components/problemas-condicoes/utils/verifications'
import { IDADE_GESTACIONAL_MAXIMA_EM_DIAS, MetasPreNatal, TipoPreNatal } from '../../pre-natal/model'
import { PreNatalField } from '../../pre-natal/PreNatalField'
import { PreNatalObjetivoButton } from '../../pre-natal/PreNatalObjetivoButton'
import {
  informationPreNatalQuandoExisteW78ResolvidoNaAvaliacao,
  informationPreNatalSuperiorA336Dias,
} from '../../pre-natal/util'
import { grupoCboPuericultura } from '../acessos'
import { initialValuesPuericulturaModel, PuericulturaModel } from '../puericultura/model'
import { PuericulturaButton } from '../puericultura/PuericulturaButton'
import PuericulturaView from '../puericultura/PuericulturaView'

export enum FormAtivoObjetivoEnum {
  PUERICULTURA,
  PRE_NATAL,
}

export interface SwitchButtonObjetivoFormModel {
  formAtivo: FormAtivoObjetivoEnum
  puericultura: PuericulturaModel
}

export interface SwitchButtonObjetivoFormProps {
  name: MetaPath<SwitchButtonObjetivoFormModel>
  metaProblemasCondicoes: MetaPath<ProblemaCondicaoModel[]>
  metasPreNatal: Pick<MetasPreNatal, 'metaDum' | 'metaPreNatal'>
  cidadao: CidadaoAtendimento
  prontuarioId: ID
  dataAtendimento: Instant
  tipoPreNatal: TipoPreNatal
}

export function SwitchButtonObjetivoForm(props: SwitchButtonObjetivoFormProps) {
  const {
    name,
    cidadao,
    prontuarioId,
    dataAtendimento,
    metasPreNatal: { metaPreNatal, metaDum },
    tipoPreNatal,
    metaProblemasCondicoes,
  } = props

  const {
    cidadao: { isGestante, dataInicioGestacao, idadeGestacional },
    permissoes: { hasPermissionPreNatal },
  } = useAtendimentoContext()

  const idadeCidadao = calculateAge(dataAtendimento, cidadao.dataNascimento).years

  const { hasCboAuth } = useSession({ fetchPolicy: 'cache-only' })

  const acessoPreNatal = isGestante && hasPermissionPreNatal
  const acessoCboPuericultura = hasCboAuth(grupoCboPuericultura)

  const acessoPuericultura = acessoCboPuericultura && isCidadaoPuericultura(idadeCidadao)

  const {
    input: { value: puericultura, onChange: updatePuericultura },
  } = useField<PuericulturaModel>(name.puericultura.absolutePath())

  const { loading: loadingPuericultura } = usePuericulturaQuery({
    variables: { prontuarioId, dataReferencia: formatISO(dataAtendimento, { representation: 'date' }) },
    skip: !acessoPuericultura || (!!puericultura && puericultura?.dataNascimentoReferencia === cidadao.dataNascimento),
    onCompleted: (data) =>
      data && updatePuericultura(initialValuesPuericulturaModel(data, cidadao.dataNascimento, dataAtendimento)),
  })

  const {
    input: { value: formAtivo, onChange: updateFormAtivo, onBlur: onBlurFormAtivo },
  } = useField<FormAtivoObjetivoEnum>(name.formAtivo.absolutePath())

  const {
    input: { value: problemasCondicoes },
  } = useField<ProblemaCondicaoModel[]>(metaProblemasCondicoes.absolutePath(), { subscription: { value: true } })

  const {
    tools: { resetToUndefined: resetPreNatal },
  } = usePecField({ name: metaPreNatal.absolutePath(), subscription: { value: true } })

  const {
    tools: { resetToUndefined: resetDum },
  } = usePecField({ name: metaDum.absolutePath() })

  const setFormAtivo = (stateButton: FormAtivoObjetivoEnum) => {
    updateFormAtivo(stateButton)
    onBlurFormAtivo()
  }

  const setNullIfNewFormAtivoIsEqual = (newFormAtivo: FormAtivoObjetivoEnum) =>
    newFormAtivo === formAtivo ? null : newFormAtivo

  const handleClickPuericultura = (stateButton: FormAtivoObjetivoEnum) => {
    confirm({
      title: 'Deseja descartar os dados de Puericultura?',
      body: 'As alterações realizadas serão perdidas.',
      cancelLabel: 'Não, manter dados',
      confirmLabel: 'Sim, descartar',
      onConfirm: () => {
        setFormAtivo(setNullIfNewFormAtivoIsEqual(stateButton))
      },
    })()
  }

  const isBotaoPreNatalHabilitado = formAtivo === FormAtivoObjetivoEnum.PRE_NATAL
  const isBotaoPuericulturaHabilitado = formAtivo === FormAtivoObjetivoEnum.PUERICULTURA

  const handleDesabilitarPreNatal = (stateButton: FormAtivoObjetivoEnum) => {
    confirm({
      title: 'Deseja descartar os dados de Pré-natal?',
      body: `Os campos de Pré-natal serão desabilitados, os dados contidos nos mesmos serão perdidos e os problemas e/ou condições de gestação serão excluídos da Avaliação.`,
      cancelLabel: 'Não, manter dados',
      confirmLabel: 'Sim, descartar',
      onConfirm: () => {
        setFormAtivo(setNullIfNewFormAtivoIsEqual(stateButton))
        resetDum()
        resetPreNatal()
      },
    })()
  }

  const handleHabilitarPreNatal = () => {
    if (idadeGestacional > IDADE_GESTACIONAL_MAXIMA_EM_DIAS) {
      informationPreNatalSuperiorA336Dias(dataInicioGestacao)
      return
    }
    switch (tipoPreNatal) {
      case TipoPreNatal.W78_RESOLVIDO_NA_AVALIACAO: {
        informationPreNatalQuandoExisteW78ResolvidoNaAvaliacao()
        break
      }
      case TipoPreNatal.ENCERRAMENTO_GESTACAO: {
        informationPreNatalQuandoExisteDesfecho()
        break
      }
      default: {
        setFormAtivo(setNullIfNewFormAtivoIsEqual(FormAtivoObjetivoEnum.PRE_NATAL))
        break
      }
    }
  }

  const handleClick = (stateButton: FormAtivoObjetivoEnum) => () => {
    if (isBotaoPreNatalHabilitado) {
      if (stateButton === FormAtivoObjetivoEnum.PUERICULTURA) {
        return informationPuericulturaQuandoExistePreNatal()
      } else {
        return handleDesabilitarPreNatal(stateButton)
      }
    } else if (isBotaoPuericulturaHabilitado) {
      if (stateButton === FormAtivoObjetivoEnum.PRE_NATAL) {
        return informationPreNatalQuandoExistePuericultura()
      } else {
        return handleClickPuericultura(stateButton)
      }
    }
    if (stateButton === FormAtivoObjetivoEnum.PRE_NATAL) {
      return handleHabilitarPreNatal()
    } else if (stateButton === FormAtivoObjetivoEnum.PUERICULTURA)
      if (tipoPreNatal === TipoPreNatal.ENCERRAMENTO_GESTACAO) {
        return informationPuericulturaQuandoExisteDesfecho()
      } else if (hasProblemaCondicaoDePreNatal(problemasCondicoes, isGestante)) {
        return informationPuericulturaQuandoExistePreNatal()
      }
    return setFormAtivo(setNullIfNewFormAtivoIsEqual(stateButton))
  }

  if (!acessoPuericultura && !acessoPreNatal) {
    setFormAtivo(null) // resolve alguns problemas de troca de data nascimento
    return null
  }

  return (
    <Fragment>
      <HeadingSection level={5} title='Habilitar campos de' />
      <ButtonGroup>
        <HFlow hSpacing={1} justifyContent='flex-start'>
          {acessoPreNatal && (
            <PreNatalObjetivoButton
              isSelecionado={isBotaoPreNatalHabilitado}
              handleClick={handleClick(FormAtivoObjetivoEnum.PRE_NATAL)}
            />
          )}
          {acessoPuericultura && (
            <PuericulturaButton
              puericulturaAtivo={isBotaoPuericulturaHabilitado}
              handleClick={handleClick(FormAtivoObjetivoEnum.PUERICULTURA)}
            />
          )}
        </HFlow>
      </ButtonGroup>

      {isBotaoPuericulturaHabilitado && acessoPuericultura && (
        <PuericulturaView name={name.puericultura} cidadao={cidadao} loading={loadingPuericultura} />
      )}

      {isBotaoPreNatalHabilitado && acessoPreNatal && (
        <PreNatalField name={metaPreNatal} tipoPreNatal={tipoPreNatal} metaDum={metaDum} />
      )}
    </Fragment>
  )
}
