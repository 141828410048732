import { isEmpty, isEqual } from 'bold-ui'
import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { parseISO } from 'date-fns'
import createDecorator from 'final-form-calculate'
import { SituacaoProblema } from 'graphql/types.generated'
import { metaPath } from 'util/metaPath'
import { CiapCidPreNatal } from 'view/atendimentos/atendimento-individual/model'

import { dateAsYyyyMmDd } from '../../../../../../../util/date/formatDate'
import { Problema } from '../../../aside/types/ProblemaModel'
import { isCiapGestacao, isCidFromFamiliaZ34, isCidGestacao, isEncerramentoGestacao } from '../../../pre-natal/util'
import { convertDataProblema, convertProblemaCondicao } from './converter'
import { ProblemaCondicaoModel } from './model'
import {
  atualizarProblemaCondicao,
  findProblemaByCiapIdCidId,
  findProblemaById,
  findProblemaComCiapW78,
} from './utils/operations'
import {
  isEvolucao,
  isProblemaComCiapW78,
  isProblemaCondicaoComCiapW78,
  isProblemaCondicaoEncerraGestacao,
  isProblemaCondicaoGravidezAltoRisco,
} from './utils/verifications'

const meta = metaPath<{ [x: string]: ProblemaCondicaoModel }>()

export const createProblemaCondicoesCalculator = (
  problemasCidadao: Problema[],
  dataNascimentoCidadao: LocalDate,
  ciapCidPreNatal: CiapCidPreNatal,
  somenteCiap: boolean,
  prefix: string,
  dataAtendimento: LocalDate,
  isAdicionandoNaLPC: boolean,
  isAntecedentes: boolean
) =>
  createDecorator(
    {
      field: [meta[prefix].problemaCondicaoEvoluir.absolutePath()],
      updates: (value: Problema) => {
        const hasDescricaoOutro = !isEmpty(value?.descricaoOutro)

        const problema = hasDescricaoOutro
          ? findProblemaById(problemasCidadao, value?.id)
          : findProblemaByCiapIdCidId(problemasCidadao, value?.ciap?.id, value?.cid10?.id)

        const devePreencherCidComCidZ34 = !somenteCiap && isProblemaComCiapW78(problema) && isEmpty(problema?.cid10)

        return {
          [prefix]: {
            ...convertProblemaCondicao(problema, dataNascimentoCidadao),
            problemaCondicaoEvoluir: value,
            ...(devePreencherCidComCidZ34 && { cid: ciapCidPreNatal.cid }),
          },
        }
      },
    },
    {
      field: [meta[prefix].situacaoProblema.absolutePath()],
      updates: (value: SituacaoProblema, _, allValues: { [x: string]: ProblemaCondicaoModel }) =>
        value === SituacaoProblema.ATIVO
          ? {
              [prefix]: {
                ...allValues[prefix],
                dataFim: undefined,
              },
            }
          : {
              [prefix]: {
                ...allValues[prefix],
              },
            },
    },
    {
      field: [meta[prefix].incluirNaListaProblemas.absolutePath()],
      updates: (_, __, allValues: { [x: string]: ProblemaCondicaoModel }) => {
        const problemaEncontrado = findProblemaByCiapIdCidId(
          problemasCidadao,
          allValues[prefix]?.ciap?.id,
          allValues[prefix]?.cid?.id
        )

        const problema = problemaEncontrado
          ? {
              ...convertProblemaCondicao(
                problemaEncontrado,
                dataNascimentoCidadao,
                prefix === allValues[prefix]?._id ? allValues[prefix] : undefined
              ),
              incluirNaListaProblemas: !!problemaEncontrado,
              problemaCondicaoEvoluir: problemaEncontrado,
              _id: allValues[prefix]._id,
            }
          : { ...allValues[prefix] }

        return !allValues[prefix]?.incluirNaListaProblemas
          ? {
              [prefix]: {
                ...allValues[prefix],
                situacaoProblema: undefined,
                dataInicio: undefined,
                dataFim: undefined,
                observacao: undefined,
              },
            }
          : { [prefix]: problema }
      },
    },
    {
      field: [meta[prefix].ciap.absolutePath(), meta[prefix].cid.absolutePath()],
      updates: (
        value: CiapSelectFieldModel | CidSelectFieldModel,
        _,
        allValues: { [innerPrefix: string]: ProblemaCondicaoModel },
        prevValues: { [innerPrefix: string]: ProblemaCondicaoModel }
      ) => {
        const problema = allValues[prefix]
        const prevProblema = prevValues[prefix]
        if (deveLimparOndeTinhaW78(problema, prevProblema)) {
          return handleAlteracaoOndeTinhaCiapW78(prefix, problema)
        } else if (isEncerramentoGestacao(value?.codigo)) {
          return {
            [prefix]: {
              ...problema,
              dataInicio: problema.problemaId
                ? problema.dataInicio
                : convertDataProblema(dateAsYyyyMmDd(parseISO(dataAtendimento)), parseISO(dataNascimentoCidadao)),
              incluirNaListaProblemas: true,
            },
          }
        } else if (isCiapGestacao(value?.codigo)) {
          const problemaCondicao = handleAdicaoCiapGestacao(prefix, problema, somenteCiap, ciapCidPreNatal)
          return handleAtualizacaoProblemaExistente(
            prefix,
            problemasCidadao,
            problemaCondicao[prefix],
            dataNascimentoCidadao,
            problemaCondicao[prefix].problemaId ? problemaCondicao[prefix].dataInicio?.data : dataAtendimento,
            isAdicionandoNaLPC,
            isAntecedentes
          )
        } else if (isCidGestacao(value?.codigo)) {
          const problemaCondicao = handleAdicaoCidGestacao(prefix, problema, ciapCidPreNatal)
          return handleAtualizacaoProblemaExistente(
            prefix,
            problemasCidadao,
            problemaCondicao[prefix],
            dataNascimentoCidadao,
            problemaCondicao[prefix].problemaId ? problemaCondicao[prefix].dataInicio?.data : dataAtendimento,
            isAdicionandoNaLPC,
            isAntecedentes
          )
        } else if (deveLimparCamposPreenchidosAutomaticamente(problema, prevProblema, somenteCiap)) {
          return limparCamposPreenchidosAutomaticamente(prefix, problema)
        } else {
          return handleDefault(prefix, problema)
        }
      },
      isEqual: (a, b) => isEqual(a, b),
    }
  )

function handleAdicaoCiapGestacao(
  prefix: string,
  problema: ProblemaCondicaoModel,
  somenteCiap: boolean,
  ciapCidPreNatal: CiapCidPreNatal
) {
  return {
    [prefix]: {
      ...problema,
      ...(!somenteCiap &&
        isProblemaCondicaoComCiapW78(problema) &&
        !isCidFromFamiliaZ34(problema.cid?.codigo) && {
          cid: {
            ...ciapCidPreNatal.cid,
          },
        }),
      incluirNaListaProblemas: true,
    },
  }
}

function handleAdicaoCidGestacao(prefix: string, problema: ProblemaCondicaoModel, ciapCidPreNatal: CiapCidPreNatal) {
  return {
    [prefix]: {
      ...problema,
      ...(isCidFromFamiliaZ34(problema.cid?.codigo) && {
        ciap: {
          ...ciapCidPreNatal.ciap,
        },
      }),
      incluirNaListaProblemas: true,
    },
  }
}

function handleDefault(prefix: string, problema: ProblemaCondicaoModel) {
  return {
    [prefix]: {
      ...problema,
    },
  }
}

function handleAtualizacaoProblemaExistente(
  prefix: string,
  problemasCidadao: Problema[],
  problemaCondicao: ProblemaCondicaoModel,
  dataNascimentoCidadao: string,
  dataInicio: LocalDate,
  isAdicionandoNaLPC: boolean,
  isAntecedentes: boolean
): { [innerPrefix: string]: ProblemaCondicaoModel } {
  const problemaJaExistente = findProblemaComCiapW78(problemasCidadao)
  const isCiapW78 = isProblemaCondicaoComCiapW78(problemaCondicao)
  const situacaoProblema =
    isAntecedentes && isAdicionandoNaLPC
      ? SituacaoProblema.RESOLVIDO
      : !isAdicionandoNaLPC && isCiapW78 && SituacaoProblema.ATIVO
  return !isAdicionandoNaLPC && problemaJaExistente && isCiapW78
    ? {
        [prefix]: {
          ...atualizarProblemaCondicao(problemaCondicao, problemaJaExistente, dataNascimentoCidadao),
        },
      }
    : {
        [prefix]: {
          ...problemaCondicao,
          dataInicio: convertDataProblema(dataInicio, parseISO(dataNascimentoCidadao)),
          situacaoProblema: situacaoProblema ? situacaoProblema : problemaCondicao.situacaoProblema,
        },
      }
}

function deveLimparCamposPreenchidosAutomaticamente(
  problema: ProblemaCondicaoModel,
  prevProblema: ProblemaCondicaoModel,
  somenteCiap: boolean
) {
  const codigoCiap = problema?.ciap?.codigo
  const prevCodigoCiap = prevProblema?.ciap?.codigo

  let wasEncerrarGestacao = isEncerramentoGestacao(prevCodigoCiap)
  let isNotEncerramentoGestacao = !isEncerramentoGestacao(codigoCiap)

  let wasCiapOrCidGestacao = isCiapGestacao(prevCodigoCiap)
  let isNotCiapOrCidGestacao = !isCiapGestacao(codigoCiap)

  if (!somenteCiap) {
    const codigoCid = problema?.cid?.codigo
    const prevCodigoCid = prevProblema?.cid?.codigo

    wasEncerrarGestacao = wasEncerrarGestacao || isEncerramentoGestacao(prevCodigoCid)
    isNotEncerramentoGestacao = isNotEncerramentoGestacao && !isEncerramentoGestacao(codigoCid)

    wasCiapOrCidGestacao = wasCiapOrCidGestacao || isCidGestacao(prevCodigoCid)
    isNotCiapOrCidGestacao = isNotCiapOrCidGestacao && !isCidGestacao(codigoCid)
  }

  return (
    (wasEncerrarGestacao || wasCiapOrCidGestacao) &&
    !isEvolucao(problema) &&
    isNotEncerramentoGestacao &&
    isNotCiapOrCidGestacao
  )
}

function limparCamposPreenchidosAutomaticamente(prefix: string, problema: ProblemaCondicaoModel) {
  return {
    [prefix]: {
      _id: null,
      problemaId: null,
      ciap: problema?.ciap,
      cid: problema?.cid,
      incluirNaListaProblemas: null,
      observacao: null,
      situacaoProblema: null,
      dataInicio: null,
      dataFim: null,
      problemaCondicaoEvoluir: null,
      automatico: null,
    },
  }
}

function deveLimparOndeTinhaW78(problema: ProblemaCondicaoModel, prevProblema: ProblemaCondicaoModel): boolean {
  return !isEvolucao(problema) && !isProblemaCondicaoComCiapW78(problema) && isProblemaCondicaoComCiapW78(prevProblema)
}

function handleAlteracaoOndeTinhaCiapW78(prefix: string, problema: ProblemaCondicaoModel) {
  const isProblemaAltoRiscoOuEncerraGestacao =
    isProblemaCondicaoGravidezAltoRisco(problema) || isProblemaCondicaoEncerraGestacao(problema)
  return !isProblemaAltoRiscoOuEncerraGestacao
    ? {
        [prefix]: {
          ...problema,
          cid: null,
          incluirNaListaProblemas: null,
          observacao: null,
          situacaoProblema: null,
          dataInicio: null,
          dataFim: null,
          automatico: null,
        },
      }
    : {
        [prefix]: {
          ...problema,
          cid: null,
        },
      }
}
