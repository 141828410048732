import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { Ciap, Cid10, SituacaoProblema } from 'graphql/types.generated'
import { useMemo } from 'react'

import { ProblemaCondicaoModel } from '../../avaliacao/components/problemas-condicoes/model'
import { EvolucaoProblema, Problema } from '../types/ProblemaModel'

export default (
  problemas: Problema[],
  problemasAvaliacao: ProblemaCondicaoModel[],
  problemasLPC: ProblemaCondicaoModel[]
) =>
  useMemo(() => {
    const getProblemasAtivosLatentesAntigos = () => filterAndSortProblemas(problemas || [], [], [])
    const getProblemasAtivosLatentes = () =>
      filterAndSortProblemas(problemas || [], problemasAvaliacao || [], problemasLPC || [])
    const getProblemasResolvidos = () =>
      filterAndSortProblemas(problemas || [], problemasAvaliacao || [], problemasLPC || [], true)

    const gruposProblemas = breakProblemas(getProblemasAtivosLatentes())
    const getProblemasExpandido = () => gruposProblemas.problemasExpandido
    const getProblemasAccordion = () => gruposProblemas.problemasAccordion

    return {
      getProblemasExpandido,
      getProblemasAccordion,
      getProblemasAtivosLatentes,
      getProblemasResolvidos,
      getProblemasAtivosLatentesAntigos,
    }
  }, [problemas, problemasAvaliacao, problemasLPC])

/**
 *
 * @param {Problema[]} problemas lista de todos os problemas
 * @param {ProblemaCondicaoModel[]} problemasAvaliados lista de problemas avaliados no atendimento atual
 * @param {boolean} isResolvido se true retornará os problemas RESOLVIDOS, senão retornará os problemas ATIVOS e LATENTES
 * @returns {Problema[]} lista de problemas avaliados, ativos, latentes e resolvidos ordenados de forma decrescente em relação a data da última atualização
 */
export const filterAndSortProblemas = (
  problemas: Problema[],
  problemasAvaliados: ProblemaCondicaoModel[],
  problemasLpc: ProblemaCondicaoModel[],
  isResolvido: boolean = false
): Problema[] =>
  problemas
    .concat(extractNovosProblemas(problemasAvaliados, convertAvaliacaoToProblema))
    .map((problema) => mergeProblemas(problema, problemasAvaliados, convertAvaliacaoToProblema))
    .concat(extractNovosProblemas(problemasLpc, convertLPCToProblema))
    .map((problema) => mergeProblemas(problema, problemasLpc, convertLPCToProblema))
    .filter(
      (problema) =>
        problema &&
        (isResolvido
          ? problema.situacao === SituacaoProblema.RESOLVIDO
          : problema.situacao !== SituacaoProblema.RESOLVIDO)
    )
    .sort((a, b) => (b.ultimaEvolucao.atualizadoEm ?? Date.now()) - (a.ultimaEvolucao.atualizadoEm ?? Date.now()))

/**
 * Se o total de problemas for menor ou igual a 6, todas devem ser apresentadas no grupo expandido e o accordion não deve ser exibido
 * Se o total de problemas for 6 ou +, no grupo de expandidos deve possuir apenas 5 problemas e o restante deve ficar dentro do accordion
 * @param {Problema[]} problemasAtivosLatentes lista de problemas ativos e latentes
 * @returns {{problemasExpandido: Problema[]; problemasAccordion: Problema[]}} listas de problemas a serem apresentados no card e no accordion
 */
export const breakProblemas = (problemasAtivosLatentes: Problema[]) => {
  const totalProblemas = problemasAtivosLatentes.length
  const problemasExpandido: Problema[] = []
  const problemasAccordion: Problema[] = []

  totalProblemas > 6 && problemasAccordion.push(...problemasAtivosLatentes.splice(5, totalProblemas - 1))
  problemasExpandido.push(...problemasAtivosLatentes)

  return { problemasExpandido, problemasAccordion }
}

const mergeProblemas = (
  problema: Problema,
  problemaCondicaoList: ProblemaCondicaoModel[],
  convertToProblema: (avaliacao: ProblemaCondicaoModel) => Problema
): Problema => {
  const problemaAvaliado = problemaCondicaoList.find(
    (pa) => pa.incluirNaListaProblemas && pa.problemaId && pa.problemaId === problema.id
  )
  return problemaAvaliado
    ? {
        ...convertToProblema(problemaAvaliado),
        evolucoes: [convertAvaliacaoToEvolucao(problemaAvaliado), ...(problema.evolucoes ?? [])],
      }
    : problema
}

/**
 *
 * @param {EvolucaoProblema} avaliacao
 */
const convertAvaliacaoToEvolucao = (avaliacao: ProblemaCondicaoModel): EvolucaoProblema => ({
  id: avaliacao._id,
  situacao: avaliacao.situacaoProblema,
  dataInicio: avaliacao.dataInicio?.data,
  dataFim: avaliacao.dataFim?.data,
  observacao: avaliacao.observacao,
  possuiCiap: !!avaliacao.ciap,
  possuiCid: !!avaliacao.cid,
})

const extractNovosProblemas = (
  problemas: ProblemaCondicaoModel[],
  mapFunction: (avaliacao: ProblemaCondicaoModel) => Problema
): Problema[] =>
  problemas.filter((p) => (p.incluirNaListaProblemas && !p.problemaId) || !!p.registradoAgora).map(mapFunction)

function convertAvaliacaoToProblema(avaliacao: ProblemaCondicaoModel): Problema {
  const evolucao = convertAvaliacaoToEvolucao(avaliacao)
  return {
    ...convertProblemaCondicaoToProblema(avaliacao),
    evolucoes: [evolucao],
    ultimaEvolucao: evolucao,
    avaliadoAgora: true,
    registradoAgora: avaliacao.registradoAgora,
  }
}

function convertLPCToProblema(lpc: ProblemaCondicaoModel): Problema {
  const evolucao = convertAvaliacaoToEvolucao(lpc)
  return {
    ...convertProblemaCondicaoToProblema(lpc),
    evolucoes: [evolucao],
    ultimaEvolucao: evolucao,
    avaliadoAgora: false,
    registradoAgora: true,
  }
}

function convertProblemaCondicaoToProblema(problemaCondicao: ProblemaCondicaoModel): Problema {
  return {
    id: problemaCondicao.problemaId ?? problemaCondicao._id,
    ciap: problemaCondicao.ciap && convertCiap(problemaCondicao.ciap),
    cid10: problemaCondicao.cid && convertCid(problemaCondicao.cid),
    descricaoOutro: problemaCondicao.problemaCondicaoEvoluir?.descricaoOutro,
    situacao: problemaCondicao.situacaoProblema,
    automatico: problemaCondicao.automatico,
  }
}

function convertCiap(ciap: CiapSelectFieldModel): Ciap {
  return {
    id: ciap.id,
    codigo: ciap.codigo,
    descricao: ciap.nome,
  }
}

function convertCid(cid: CidSelectFieldModel): Cid10 {
  return {
    id: cid.id,
    codigo: cid.codigo,
    nome: cid.nome,
  }
}
