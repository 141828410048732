/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HeadingSection, HFlow, Modal, ModalBody, ModalFooter } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Form, FormRenderProps, RichTextField, SubmitButton, TextField } from 'components/form'
import { CiapSelectField } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectField } from 'components/form/field/select/CidSelectField/CidSelectField'
import EspecialidadeSisregSelectField from 'components/form/field/select/EspecialidadeSisregSelectField/EspecialidadeSisregSelectField'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import { SexoEnum } from 'graphql/types.generated'
import { useCallback, useMemo } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router'
import { isCboMedicoOuCirurgiaoDentista } from 'util/atendimento/cboMedicoOuCirurgiaoDentista'
import { MetaArray, metaPath } from 'util/metaPath'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'

import { grupoCboEncaminhamentoExterno } from '../../acessos'
import { ClassificacaoRiscoEncaminhamentoGroupButton } from '../ClassificacaoRiscoEncaminhamentoGroupButton'
import { EncaminhamentoExternoModel } from '../model'
import { encaminhamentoExternoFormValidator } from '../util/encaminhamentoExternoFormValidator'

interface UrlParams {
  id: ID
}

interface EncaminhamentoExternoEdicaoModalProps {
  encaminhamentosRecentes: EncaminhamentoExternoModel[]
  cboCodigoAtendimento: string
  isAtendimentoOdonto: boolean
  cidadaoSexo: SexoEnum
  name: MetaArray<EncaminhamentoExternoModel>
}

const removeNonBreakingSpace = (value: string) => value.replace(/&nbsp;/g, '')
const path = metaPath<EncaminhamentoExternoModel>()

function getEncaminhamento(id: ID, encaminhamentos: EncaminhamentoExternoModel[]): EncaminhamentoExternoModel {
  return encaminhamentos.find((enc) => enc._id === id)
}

export default function EncaminhamentoExternoEdicaoModal(props: EncaminhamentoExternoEdicaoModalProps) {
  const { encaminhamentosRecentes, cboCodigoAtendimento, isAtendimentoOdonto, cidadaoSexo, name } = props
  const { id } = useParams<UrlParams>()
  const history = useHistory()
  const match = useRouteMatch()
  const alert = useAlert()

  const handleCloseModal = (dirty: boolean) => {
    if (dirty) {
      confirm({
        title: 'Deseja sair sem salvar?',
        body: 'As alterações realizadas serão perdidas.',
        confirmLabel: 'Sim, sair da tela',
        cancelLabel: 'Não, continuar aqui',
        onConfirm: () => {
          alert('success', 'Encaminhamento cancelado com sucesso')
          history.push(match.url.split('/encaminhamentos-externos/editar')[0])
        },
      })()
    } else {
      alert('success', 'Encaminhamento cancelado com sucesso')
      history.push(match.url.split('/encaminhamentos-externos/editar')[0])
    }
  }

  const initialValues: EncaminhamentoExternoModel = useMemo(() => getEncaminhamento(id, encaminhamentosRecentes), [
    id,
    encaminhamentosRecentes,
  ])
  const renderCiap = !isCboMedicoOuCirurgiaoDentista(cboCodigoAtendimento)

  const { handleRowChanged } = useEditableListField({
    name,
  })

  const handleEdit = (values: EncaminhamentoExternoModel, formApi: FormApi<EncaminhamentoExternoModel>) => {
    handleRowChanged(values)
    formApi.getRegisteredFields().forEach((item) => formApi.resetFieldState(item))
    alert('success', 'Encaminhamento editado com sucesso')
    history.push(match.url.split('/encaminhamentos-externos/editar')[0])
  }

  const renderModal = (formProps: FormRenderProps<EncaminhamentoExternoModel>) => {
    return (
      <Modal style={classes.modal} onClose={() => handleCloseModal(formProps.dirty)} open>
        <ModalBody>
          <HeadingSection level={1} title='Editar encaminhamento' />
          <Grid>
            <Cell size={6}>
              <EspecialidadeSisregSelectField
                name={path.especialidadeSisreg}
                label='Especialidade'
                isAtendimentoOdonto={isAtendimentoOdonto}
                required
              />
            </Cell>
            <Cell size={6}>
              <TextField name={path.complemento} label='Complemento' maxLength={100} parse={removeNonBreakingSpace} />
            </Cell>
            <Cell size={6}>
              {renderCiap ? (
                <CiapSelectField
                  name={path.ciapEncaminhamento}
                  label='Hipótese/Diagnóstico (CIAP2)'
                  ciapComCid10Encaminhamento
                  sexo={cidadaoSexo}
                  required
                />
              ) : (
                <CidSelectField
                  name={path.cidEncaminhamento}
                  label='Hipótese/Diagnóstico (CID10)'
                  renderGrupoCiapRelacionada={false}
                  sexo={cidadaoSexo}
                  required
                />
              )}
            </Cell>
            <Cell size={12}>
              <ClassificacaoRiscoEncaminhamentoGroupButton name={path.classificacaoRiscoEncaminhamento} />
            </Cell>
            <Cell size={12}>
              <RichTextField
                name={path.motivoEncaminhamento}
                label='Motivo do encaminhamento'
                maxLength={1000}
                parse={removeNonBreakingSpace}
                placeholder='Insira o motivo do encaminhamento'
              />
            </Cell>
            <Cell size={12}>
              <RichTextField
                name={path.observacoes}
                label='Observações'
                maxLength={300}
                parse={removeNonBreakingSpace}
                placeholder='Insira outras observações'
              />
            </Cell>
          </Grid>
        </ModalBody>
        {grupoCboEncaminhamentoExterno && (
          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              <Button style={classes.button} onClick={() => handleCloseModal(formProps.dirty)}>
                Cancelar
              </Button>
              <SubmitButton style={classes.button} kind='primary' handleSubmit={formProps.handleSubmit}>
                Salvar
              </SubmitButton>
            </HFlow>
          </ModalFooter>
        )}
      </Modal>
    )
  }

  const validator = useCallback(
    encaminhamentoExternoFormValidator(renderCiap, encaminhamentosRecentes, initialValues),
    [renderCiap, encaminhamentosRecentes, initialValues]
  )

  return (
    <Form<EncaminhamentoExternoModel>
      initialValues={initialValues}
      onSubmit={handleEdit}
      render={renderModal}
      validate={validator}
    />
  )
}

const classes = {
  modal: css`
    width: 47rem;
  `,
  button: css`
    width: 9.8rem;
  `,
  titulo: css`
    h1 {
      margin-bottom: 0;
    }
  `,
  content: css`
    margin-top: 0.7rem;
  `,
  printerIcon: css`
    margin-right: 0.75rem;
  `,
}
