/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, DropdownDivider, DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DropdownButton } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import useFirebase from 'components/firebase/useFirebase'
import { confirm } from 'components/modals/confirm'
import {
  useAtenderMutation,
  useCancelarAtendimentoMutation,
  useExcluirAtendimentoMutation,
  useSalvarJustificativaAutomaticaVisualizarProntuarioMutation,
  useUltimoAtendimentoProfissionalCidadaoLazyQuery,
} from 'graphql/hooks.generated'
import { StatusAtendimento } from 'graphql/types.generated'
import { Fragment, useState } from 'react'
import { useHistory } from 'react-router'
import { ListaAtendimentoItemActionsProps } from 'view/atendimentos/list/ListaAtendimentoItemActions'
import { useJustificativaVerProntuarioId } from 'view/prontuario/justificativa/useJustificativaVerProntuarioId'

import { RegistroTardioAtendimentoPosteriorModal } from './components/modal/RegistroTardioAtendimentoPosteriorModal'
import { REGISTRO_TARDIO_NEXT_PATH, REGISTRO_TARDIO_PATH } from './RegistroTardioRootView'

interface ListaRegistroTardioItemActionsProps extends Omit<ListaAtendimentoItemActionsProps, 'lotacaoId'> {
  dataInicioAtendimento: Date
}

export default function ListaRegistroTardioItemActions(props: ListaRegistroTardioItemActionsProps) {
  const { actions, atendId, cidadaoId, prontuarioId, dataInicioAtendimento, statusAtendimento } = props
  const pathCidadao = `/cidadao/${cidadaoId}`
  const { analytics } = useFirebase()
  const history = useHistory()
  const alert = useAlert()
  const [atender] = useAtenderMutation()
  const handleRejection = useErrorHandler()
  const [modalAtendimentoPosteriorOpen, setModalAtendimentoPosteriorOpen] = useState(false)
  const [, setJustificativaVerProntuarioId] = useJustificativaVerProntuarioId(pathCidadao, cidadaoId)
  const [
    salvarJustificativaAutomaticaVisualizarProntuario,
  ] = useSalvarJustificativaAutomaticaVisualizarProntuarioMutation()
  const [excluir] = useExcluirAtendimentoMutation()
  const [executeUltimoAtendimentoProfissionalCidadaoQuery, { data }] = useUltimoAtendimentoProfissionalCidadaoLazyQuery(
    {
      onCompleted: (data) =>
        handleOnClickAtender(data.ultimoAtendimentoProfissionalCidadao?.finalizadoEm > dataInicioAtendimento),
    }
  )
  const [cancelar] = useCancelarAtendimentoMutation()

  const onAtenderClick = () => {
    return executeUltimoAtendimentoProfissionalCidadaoQuery({ variables: { cidadaoId } })
  }

  const handleOnClickAtender = (isDataAnterior: boolean) => {
    if (isDataAnterior) {
      setModalAtendimentoPosteriorOpen(true)
      if (statusAtendimento === StatusAtendimento.EM_ATENDIMENTO) {
        return cancelar({ variables: { atendimentoId: atendId } }).catch(handleRejection)
      }
    } else {
      return atender({ variables: { atendimento: atendId } })
        .then((res) => {
          history.push(`${REGISTRO_TARDIO_PATH}/${atendId}`)
          return res
        })
        .catch(handleRejection)
    }
  }

  const onVisualizarProntuarioClick = () => {
    return salvarJustificativaAutomaticaVisualizarProntuario({
      variables: { prontuarioId },
    })
      .then(({ data: { salvarJustificativaAutomaticaVisualizarProntuario: justificativaId } }) => {
        setJustificativaVerProntuarioId(justificativaId)
        history.push(`${pathCidadao}/folha-rosto`)
      })
      .catch(handleRejection)
  }

  const onEditarClick = () => {
    return history.push(`${REGISTRO_TARDIO_NEXT_PATH}/editar/${atendId}`)
  }

  const onExcluirClick = () => {
    confirm({
      title: 'Deseja realmente excluir o registro de atendimento?',
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        excluir({ variables: { atendimento: atendId } })
          .then(() => {
            alert('success', 'Registro excluído com sucesso.')
            analytics.logEvent('excluir_cidadao_LRT')
          })
          .catch(handleRejection)
      },
    })()
  }

  const onCancelarRegistroClick = () => {
    confirm({
      title: 'Cancelar registro de atendimento?',
      body: 'O prazo de atendimento foi excedido para este registro. Deseja cancelar o registro?',
      confirmLabel: 'Cancelar registro',
      cancelLabel: 'Voltar',
      type: 'danger',
      onConfirm: () => {
        cancelar({ variables: { atendimentoId: atendId } }).catch(handleRejection)
      },
    })()
  }

  return (
    <Fragment>
      {modalAtendimentoPosteriorOpen && (
        <RegistroTardioAtendimentoPosteriorModal
          open={modalAtendimentoPosteriorOpen}
          onClose={() => setModalAtendimentoPosteriorOpen(false)}
          atendimento={data?.ultimoAtendimentoProfissionalCidadao}
        />
      )}
      <HFlow
        hSpacing={0.25}
        style={css`
          margin-right: 0.5rem;
          margin-left: auto;
        `}
      >
        {actions.cancelarRegistro && (
          <Tooltip text={actions.cancelarRegistro.hint}>
            <Button
              size='small'
              kind='normal'
              skin='ghost'
              onClick={onCancelarRegistroClick}
              disabled={!actions.cancelarRegistro.enabled}
            >
              <Icon icon='userTimes' />
            </Button>
          </Tooltip>
        )}

        {actions.atender && (
          <Tooltip text={actions.atender.hint}>
            <Button
              size='small'
              kind='normal'
              skin='ghost'
              onClick={onAtenderClick}
              disabled={!actions.atender.enabled}
            >
              <Icon icon='multipleUsersOutline' />
            </Button>
          </Tooltip>
        )}

        <Tooltip text='Mais opções'>
          <DropdownButton icon='dots'>
            {actions.visualizarProntuario && (
              <DropdownItem disabled={!actions.visualizarProntuario.enabled} onClick={onVisualizarProntuarioClick}>
                Visualizar prontuário
              </DropdownItem>
            )}

            {actions.editar && (
              <Tooltip text={actions.editar?.hint}>
                <DropdownItem disabled={!actions.editar.enabled} onClick={onEditarClick}>
                  Editar
                </DropdownItem>
              </Tooltip>
            )}

            {actions.excluir && (
              <Fragment>
                <DropdownDivider />
                <Tooltip text={actions.excluir?.hint}>
                  <DropdownItem type='danger' disabled={!actions.excluir.enabled} onClick={onExcluirClick}>
                    <HFlow alignItems='center' hSpacing={0.5}>
                      <Icon icon='trashOutline' />
                      Excluir
                    </HFlow>
                  </DropdownItem>
                </Tooltip>
              </Fragment>
            )}
          </DropdownButton>
        </Tooltip>
      </HFlow>
    </Fragment>
  )
}
