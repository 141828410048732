import { EncaminhamentoInternoInput, EscutaInicialInput, ProcedimentoAtendimentoInput } from 'graphql/types.generated'

import { convertAgendarConsulta } from '../detail/soap/desfecho'
import { ManterCidadaoLista } from '../detail/soap/desfecho/components/DesfechoAtendimentoPanel'
import { convertMedicoesPanelToInput } from '../detail/soap/objetivo'
import { EscutaInicialState } from './model'

export const convertEscutaInicialModelToInput = (
  atendimentoId: ID,
  values: EscutaInicialState,
  isPreAtendimento: boolean
): EscutaInicialInput => ({
  id: atendimentoId,
  ciapId: values.motivoConsulta.ciap.id,
  motivoConsulta: values.motivoConsulta.texto,
  medicoes: convertMedicoesPanelToInput(values.medicoes),
  desfecho: {
    manterCidadaoLista: isPreAtendimento || values.desfecho.manterCidadaoLista === ManterCidadaoLista.SIM,
    atendimento: convertAtendimentoDesfecho(values, isPreAtendimento),
  },
  classificacaoRiscoEnum: !isPreAtendimento ? values.classificacaoRisco : undefined,
  procedimentos: values.procedimentos?.map(
    (procedimento) =>
      ({
        id: procedimento.procedimento.id,
        cid10PrincipalId: procedimento.procedimento.procedimentoReferencia?.id,
        automatico: procedimento.automatico,
      } as ProcedimentoAtendimentoInput)
  ),
  agendamentoConsulta: !isPreAtendimento ? convertAgendarConsulta(values.agendamentoConsulta) : undefined,
  imprimirEscuta: values.imprimirEscutaInicial,
})

const convertAtendimentoDesfecho = (
  values: EscutaInicialState,
  isPreAtendimento: boolean
): EncaminhamentoInternoInput =>
  isPreAtendimento || values.desfecho.manterCidadaoLista === ManterCidadaoLista.SIM
    ? {
        equipe: values.desfecho.atendimento?.equipe?.id,
        lotacao: values.desfecho.atendimento?.lotacao?.id,
        tiposServico: values.desfecho.atendimento?.tiposServico,
      }
    : undefined
