import useSession from 'components/auth/useSession'

import { isLotacao } from './useSessionUtils'

export const useAcessoLotacao = () => {
  const { data } = useSession()

  if (isLotacao(data.acesso)) {
    return data.acesso
  }

  return null
}
