import { formatISO, isBefore, parseISO } from 'date-fns'
import {
  EstrategiaVacinacaoEnum,
  FaixaEtariaVacinacao,
  GrupoAlvoVacinacaoEnum,
  RegistroAplicado,
} from 'graphql/types.generated'
import { Age, calculateAge } from 'util/date/calculateAge'

import { ESTRATEGIAS_GRUPO_ATENDIMENTO_REQUIRED, RegistroVacinacaoFormModel, StatusDoseEnum } from './model'
import { VacinacaoRegistrosAplicadosFilterInput } from './validator'

export function getStatusDoseVacinacao(
  dataNascimento: LocalDate,
  dataAtendimento: Instant,
  diasInicioFaixaEtaria: number,
  diasFimFaixaEtaria: number,
  isAplicada: boolean
): StatusDoseEnum {
  if (isAplicada) {
    return StatusDoseEnum.APLICADA
  }

  const age = calculateAge(dataNascimento, dataAtendimento)
  const idadeAtualEmDias = ageInDays(age)

  if (idadeAtualEmDias < diasInicioFaixaEtaria) {
    return StatusDoseEnum.FUTURA
  } else if (idadeAtualEmDias > diasFimFaixaEtaria) {
    return StatusDoseEnum.ATRASADA
  }
  return StatusDoseEnum.DISPONIVEL
}

export const isBeforeDataAprazamento = (dataAprazamento: string, dataMinimaAprazamento: Date) => {
  return isBefore(parseISO(dataAprazamento), parseISO(formatISO(dataMinimaAprazamento, { representation: 'date' })))
}

export function getGrupoAlvoVacinacao(dataNascimento: LocalDate, dataAtendimento: Instant): GrupoAlvoVacinacaoEnum {
  const idade = calculateAge(dataNascimento, dataAtendimento).years
  if (idade >= 60) {
    return GrupoAlvoVacinacaoEnum.IDOSO
  } else if (idade >= 20) {
    return GrupoAlvoVacinacaoEnum.ADULTO
  } else if (idade >= 10) {
    return GrupoAlvoVacinacaoEnum.ADOLESCENTE
  } else {
    return GrupoAlvoVacinacaoEnum.CRIANCAS
  }
}

export function getDescricaoTituloCalendario(grupoAlvo: GrupoAlvoVacinacaoEnum): string {
  switch (grupoAlvo) {
    case GrupoAlvoVacinacaoEnum.CRIANCAS:
      return ' da criança'
    case GrupoAlvoVacinacaoEnum.ADOLESCENTE:
      return ' do adolescente'
    case GrupoAlvoVacinacaoEnum.ADULTO:
      return ' do adulto'
    case GrupoAlvoVacinacaoEnum.IDOSO:
      return ' do idoso'
  }
}

export const getRegistroExistente = (
  input: VacinacaoRegistrosAplicadosFilterInput
): RegistroAplicado | RegistroVacinacaoFormModel => {
  let registro: RegistroAplicado | RegistroVacinacaoFormModel

  if (input.filterDuasListas) {
    registro =
      input.registrosAplicados.find((registro) => getFilterConditions(registro, input)) ||
      input.registrosAplicadosAtendimento.find((registro) => getFilterConditions(registro, input))
  } else {
    if (input?.registrosAplicados) {
      registro = input.registrosAplicados.find((registro) => getFilterConditions(registro, input))
    } else {
      registro = input.registrosAplicadosAtendimento.find((registro) => getFilterConditions(registro, input))
    }
  }
  return registro
}

const getFilterConditions = (registro: any, input: VacinacaoRegistrosAplicadosFilterInput) => {
  if (registro?.imunobiologicoId) {
    if (input.imunobiologicoId !== registro.imunobiologicoId) return false
  } else {
    if (input.imunobiologicoId !== registro?.outrosImunosImunobiologico?.id) return false
  }

  if (!!input.doseId) {
    if (registro.doseId) {
      if (input?.doseId !== registro.doseId) return false
    } else {
      if (input.doseId !== registro?.outrosImunosDose?.id) return false
    }
  }

  if (isRegistroVacinacaoFormModel(registro)) {
    if (!!input.tipoRegistro && input.tipoRegistro !== registro.tipoRegistroVacinacao) return false

    if (!!registro.isRegistroAnterior !== !!input?.isRegistroAnterior) return false

    if (!!input.idUnicoRegistro && registro.idUnicoRegistro !== undefined) return false
  } else {
    if (!input?.isAprazamento && !!registro.dataAprazamento) return false
  }

  return true
}

export function ageInDays(age: Age) {
  const daysInMonth = 31
  return age.years * (12 * daysInMonth) + age.months * daysInMonth + age.days
}

const isRegistroVacinacaoFormModel = (registro: any): registro is RegistroVacinacaoFormModel =>
  registro.dataRegistro !== undefined

export const getFaixaEtariaRecomendada = (
  faixasEtarias: FaixaEtariaVacinacao[],
  dataNascimento: LocalDate,
  dataAtendimento: Instant,
  isAplicada: boolean,
  ageInDays: number
): FaixaEtariaVacinacao => {
  if (faixasEtarias.length === 1) {
    return faixasEtarias[0]
  } else if (faixasEtarias.length === 2) {
    /* 
     Atualmente na tb_regra_vacinal_estrategia não existe um cenário onde um dado conjunto de imunobiológico/dose/estratégia possua mais de duas 
     faixas etárias recomendadas (que já é um cenário bem específico), para evitar uma complexidade para algo pequeno, foi realizado a manipulação manual da 
     lista de faixas etárias.
    */
    const statusDoseFaixaUm = getStatusDoseVacinacao(
      dataNascimento,
      dataAtendimento,
      faixasEtarias[0].diasInicio,
      faixasEtarias[0].diasFim,
      isAplicada
    )

    const statusDoseFaixaDois = getStatusDoseVacinacao(
      dataNascimento,
      dataAtendimento,
      faixasEtarias[1].diasInicio,
      faixasEtarias[1].diasFim,
      isAplicada
    )

    /* Caso uma das faixas esteja Disponível, a faixa etária não precisa ser validada*/
    if (statusDoseFaixaUm === StatusDoseEnum.DISPONIVEL || statusDoseFaixaDois === StatusDoseEnum.DISPONIVEL) {
      return undefined
    }
    /* Ordem de prioridade: Atrasada > Futura > (Atrasada && Atrasada) = (Futura && Futura) (em ambas os casos é selecionado a faixa mais próxima da idade do cidadão)
     */
    if (statusDoseFaixaUm === StatusDoseEnum.ATRASADA && statusDoseFaixaDois === StatusDoseEnum.FUTURA)
      return faixasEtarias[0]
    if (statusDoseFaixaUm === StatusDoseEnum.FUTURA && statusDoseFaixaDois === StatusDoseEnum.ATRASADA)
      return faixasEtarias[1]
    if (statusDoseFaixaUm === StatusDoseEnum.ATRASADA && statusDoseFaixaDois === StatusDoseEnum.ATRASADA) {
      return faixasEtarias[0].diasFim - ageInDays > faixasEtarias[1].diasFim - ageInDays
        ? faixasEtarias[0]
        : faixasEtarias[1]
    }
    if (statusDoseFaixaUm === StatusDoseEnum.FUTURA && statusDoseFaixaDois === StatusDoseEnum.FUTURA) {
      return faixasEtarias[0].diasInicio - ageInDays < faixasEtarias[1].diasInicio - ageInDays
        ? faixasEtarias[0]
        : faixasEtarias[1]
    }
  }

  return faixasEtarias[0]
}

export const estrategiaWithGrupoAtendimento = (estrategiaEnum: EstrategiaVacinacaoEnum) =>
  ESTRATEGIAS_GRUPO_ATENDIMENTO_REQUIRED.some((item) => item === estrategiaEnum)
