import { DateRange, DateRangePicker } from 'bold-ui'
import { MonthRangePicker, ReferenceMonthRange } from 'bold-ui/lib/components/MonthRangePicker/MonthRangePicker'
import React from 'react'

interface RangeFieldProps {
  option: string
  dateRangeError: string
  dateRangeInput: DateRange
  disabled: boolean
  handleDateRangeChange: (range: DateRange) => void
}

function toMonthRange(range: DateRange): ReferenceMonthRange {
  let start = null
  let end = null
  if (range.startDate != null) {
    start = { month: range.startDate.getMonth(), year: range.startDate.getFullYear() }
  }
  if (range.endDate != null) {
    end = { month: range.endDate.getMonth(), year: range.endDate.getFullYear() }
  }
  return { start, end }
}

function lastMonth() {
  const today = new Date()
  if (today.getMonth() === 0) {
    return { month: 11, year: today.getFullYear() - 1 }
  }
  return { month: today.getMonth() - 1, year: today.getFullYear() }
}

export function RangeField(props: RangeFieldProps) {
  const { option, dateRangeError, handleDateRangeChange, dateRangeInput, disabled } = props

  if (option === 'DIA') {
    return (
      <DateRangePicker
        clearable={true}
        error={dateRangeError}
        name='outro'
        onChange={handleDateRangeChange}
        value={dateRangeInput}
        disabled={disabled}
        maxDate={new Date()}
        style={{ maxWidth: '400px' }}
      />
    )
  }

  if (option === 'SEMANA') {
    return (
      <DateRangePicker
        clearable={true}
        error={dateRangeError}
        name='outro'
        onChange={handleDateRangeChange}
        value={dateRangeInput}
        disabled={disabled}
        maxDate={new Date()}
        style={{ maxWidth: '400px' }}
        onlyWeeks
      />
    )
  }

  if (option === 'MES') {
    return (
      <MonthRangePicker
        clearable={true}
        error={dateRangeError}
        name='outro'
        onChange={handleDateRangeChange}
        value={toMonthRange(dateRangeInput)}
        disabled={disabled}
        maxMonth={lastMonth()}
        style={{ maxWidth: '400px' }}
      />
    )
  }
}
