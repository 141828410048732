import { Cell, Grid, VFlow } from 'bold-ui'
import {
  RacionalidadeEmSaudeSelectField,
  RacionalidadeEmSaudeSelectModel,
} from 'components/form/field/select/RacionalidadeEmSaudeSelectField'
import { resolveValue } from 'components/form/final-form/useField'
import {
  CondutaEnum,
  FornecimentoOdontoEnum,
  TipoAtendimentoEnum,
  TipoConsultaOdontoEnum,
  TipoEncaminhamentoOdontoEnum,
} from 'graphql/types.generated'
import React from 'react'
import { useFormState } from 'react-final-form'
import { dateAsYyyyMmDd, toDate } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'
import { AgendamentoDia } from 'view/atendimentos/types/AgendamentoDia'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { LotacaoSelectField, LotacaoSelectFieldModel } from '../../../../profissional/lotacao/LotacaoSelectField'
import { AgendarConsultaPanel, AgendarConsultaPanelModel } from './components/AgendarConsultaPanel'
import { DesfechoAtendimentoFormModel, DesfechoAtendimentoPanel } from './components/DesfechoAtendimentoPanel'
import { FornecimentoPanel } from './components/FornecimentoPanel'
import {
  NotificacaoCasoSuspeitoField,
  NotificacaoCasoSuspeitoFieldModel,
} from './components/NotificacaoCasoSuspeitoField'
import { ProcedimentoSigtapField, ProcedimentoSigtapFieldModel } from './components/ProcedimentoSigtapField'
import { TipoAtendimentoPanel } from './components/TipoAtendimentoPanel'
import { TipoConsultaPanel } from './components/TipoConsultaPanel'
import { DesfechoCondutaPanel } from './DesfechoCondutaPanel'

export interface DesfechoFormModel {
  tipoAtendimento?: TipoAtendimentoEnum
  tipoConsulta?: TipoConsultaOdontoEnum
  atendimentoCompartilhado?: LotacaoSelectFieldModel
  fornecimento?: FornecimentoOdontoEnum[]
  procedimentosAdministrativos?: Array<ProcedimentoSigtapFieldModel>
  fichasNotifiacaoCasoSuspeito?: Array<NotificacaoCasoSuspeitoFieldModel>
  racionalidadeEmSaude?: RacionalidadeEmSaudeSelectModel
  conduta?: CondutaEnum[]
  condutasOdonto?: TipoEncaminhamentoOdontoEnum[]
  desfechoAtendimento?: DesfechoAtendimentoFormModel
  agendamentoConsulta?: AgendarConsultaPanelModel
}

export interface DesfechoFormProps {
  name: MetaPath<DesfechoFormModel>
  profissionalId?: ID
  dataAtendimento: Instant
  agendamentoAtendimentoId?: ID
  concluiAgendamento: boolean
  cidadao: CidadaoAtendimento
  agendamentosDia?: AgendamentoDia[]
  hasEncaminhamentoExterno: boolean
  isAtendimentoProcedimentos: boolean
  isAtendimentoOdonto: boolean
  registroTardio: boolean
}

export function DesfechoForm(props: DesfechoFormProps) {
  const {
    cidadao,
    agendamentoAtendimentoId,
    concluiAgendamento,
    profissionalId,
    name,
    dataAtendimento,
    agendamentosDia,
    hasEncaminhamentoExterno,
    isAtendimentoProcedimentos,
    isAtendimentoOdonto,
    registroTardio,
  } = props

  const { values: formValues } = useFormState({ subscription: { values: true } })

  const values = resolveValue(formValues, name)

  const isAtendimentoAgendado = !!agendamentoAtendimentoId
  const agendamentosMostrar = concluiAgendamento
    ? agendamentosDia.filter((agendamento) => agendamento.id !== agendamentoAtendimentoId)
    : agendamentosDia

  return (
    <>
      <Grid gapVertical={1}>
        {!isAtendimentoProcedimentos && (
          <Cell size={12}>
            <TipoAtendimentoPanel
              name={name.tipoAtendimento}
              isAtendimentoAgendado={isAtendimentoAgendado}
              isAtendimentoOdonto={isAtendimentoOdonto}
            />
          </Cell>
        )}
        {isAtendimentoOdonto && (
          <Cell size={12}>
            <TipoConsultaPanel
              name={name.tipoConsulta}
              isDemandaEspontanea={!concluiAgendamento}
              isUrgencia={values.tipoAtendimento === TipoAtendimentoEnum.URGENCIA}
            />
          </Cell>
        )}
        {!isAtendimentoProcedimentos && (
          <Cell size={8}>
            <LotacaoSelectField
              excludeProfissional={profissionalId}
              name={name.atendimentoCompartilhado}
              label='Atendimento compartilhado'
              loadItemsOnOpen={false}
            />
          </Cell>
        )}
        {isAtendimentoOdonto && (
          <Cell size={8}>
            <FornecimentoPanel name={name.fornecimento} />
          </Cell>
        )}
        <Cell size={12}>
          <ProcedimentoSigtapField
            name={name.procedimentosAdministrativos}
            cidadao={cidadao}
            dataAtendimento={dateAsYyyyMmDd(toDate(dataAtendimento))}
          />
        </Cell>
        <Cell size={12}>
          <NotificacaoCasoSuspeitoField
            name={name.fichasNotifiacaoCasoSuspeito}
            cidadaoId={cidadao?.id}
            dataAtendimento={dataAtendimento}
          />
        </Cell>
        <Cell size={8}>
          <RacionalidadeEmSaudeSelectField
            name={name.racionalidadeEmSaude}
            label='Racionalidade em saúde (Exceto alopatia/convencional)'
          />
        </Cell>
        <Cell size={12}>
          <DesfechoCondutaPanel
            name={name}
            hasEncaminhamentoExterno={hasEncaminhamentoExterno}
            hasNotificacaoCasoSuspeito={!isEmpty(values?.fichasNotifiacaoCasoSuspeito)}
            tipoConsulta={values.tipoConsulta}
            isAtendimentoOdonto={isAtendimentoOdonto}
            isAtendimentoProcedimentos={isAtendimentoProcedimentos}
          />
        </Cell>
        {!registroTardio && (
          <Cell size={12}>
            <VFlow>
              <DesfechoAtendimentoPanel name={name.desfechoAtendimento} agendamentosDia={agendamentosMostrar} />
              <AgendarConsultaPanel name={name.agendamentoConsulta} />
            </VFlow>
          </Cell>
        )}
      </Grid>
    </>
  )
}
