/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow } from 'bold-ui'
import { Box } from 'components/Box'
import theme from 'config/theme'
import React from 'react'

interface ImpressaoBoxItemProps {
  children: React.ReactNode
}

export default function ImpressaoBoxItem({ children }: ImpressaoBoxItemProps) {
  return (
    <Box style={styles.boxMedicamentos}>
      <HFlow
        style={css`
          display: flex;
          word-break: break-word;
        `}
      >
        {children}
      </HFlow>
    </Box>
  )
}

const styles = {
  boxMedicamentos: css`
    border: 1px solid;
    border-radius: 2px;
    border-color: ${theme.pallete.gray.c60};
    padding: 0.75rem 1.25rem;
  `,
}
