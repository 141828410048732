import { Button, Cell, Grid, Heading, Icon, Tooltip, VFlow } from 'bold-ui'
import { Form, FormRenderProps } from 'components/form'
import { CiapSelectField, CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { HLabel } from 'components/HLabel'
import { FormApi } from 'final-form'
import React, { useState } from 'react'
import { MetaArray, metaPath } from 'util/metaPath'
import { createValidator, maxLength } from 'util/validation'
import { isEmpty } from 'util/validation/Util'
import { EditableList, EditableRow, useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { Observacao } from 'view/atendimentos/detail/components/Observacao'
import { ObservacaoFormGrid } from 'view/atendimentos/detail/components/ObservacaoFormGrid'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

export interface MotivoConsultaFieldModel {
  _id: ID
  ciap: CiapSelectFieldModel
  observacao?: string
}

export interface MotivoConsultaFieldProps<T> {
  name: MetaArray<MotivoConsultaFieldModel>
  cidadao?: CidadaoAtendimento
}

const meta = metaPath<MotivoConsultaFieldModel>()

export function MotivoConsultaField<T = any>(props: MotivoConsultaFieldProps<T>) {
  const { name, cidadao } = props

  const {
    handleSubmit,
    handleRowChanged,
    removeItem,
    input: { value },
  } = useEditableListField({
    name,
  })

  const ciapIsEqual = (ciap: CiapSelectFieldModel) => {
    return (value || []).find((o2) => ciap.id === o2.ciap.id)
  }

  const renderForm = (props: FormRenderProps<MotivoConsultaFieldModel>) => {
    const handleChange = (newValue: CiapSelectFieldModel) => {
      newValue && props.form.submit()
    }

    return (
      <CiapSelectField
        name={meta.ciap}
        label='Motivo da consulta (CIAP 2)'
        onChange={handleChange}
        itemIsEqual={ciapIsEqual}
        sexo={isEmpty(cidadao?.identidadeGeneroDbEnum) ? cidadao?.sexo : undefined}
        excluirCIAPsDAB={true}
        menuMinWidth={620}
      />
    )
  }

  return (
    <Grid gapVertical={0.5}>
      <Cell size={8}>
        <Form<MotivoConsultaFieldModel> render={renderForm} onSubmit={handleSubmit} {...props} />
      </Cell>

      {value?.length > 0 && (
        <Cell size={12}>
          <EditableList>
            {value.map((item) => (
              <CiapRow key={item._id} model={item} onChange={handleRowChanged} onRemove={removeItem} />
            ))}
          </EditableList>
        </Cell>
      )}
    </Grid>
  )
}

interface CiapRowProps {
  model: MotivoConsultaFieldModel
  onChange(values: MotivoConsultaFieldModel): void
  onRemove(itemToRemove: MotivoConsultaFieldModel): void
}

const validator = createValidator<MotivoConsultaFieldModel>({
  observacao: [maxLength(200)],
})

function CiapRow(props: CiapRowProps) {
  const { model, onChange, onRemove } = props
  const [editing, setEditing] = useState(false)

  const toggleEditing = () => setEditing((oldState) => !oldState)

  const handleRemove = () => onRemove(model)

  const handleSubmit = (value: MotivoConsultaFieldModel, formApi: FormApi) => {
    onChange(value)
    setTimeout(formApi.reset)
    toggleEditing()
  }

  const renderForm = (formRenderProps: FormRenderProps<MotivoConsultaFieldModel>) => (
    <VFlow vSpacing={0.5} style={{ padding: '1rem 0' }}>
      <Heading level={4}>Adicionar observação</Heading>
      <HLabel title='CIAP 2:'>{model.ciap.nome}</HLabel>
      <ObservacaoFormGrid name={meta.observacao} onCancel={toggleEditing} onSubmit={formRenderProps.handleSubmit} />
    </VFlow>
  )
  return (
    <EditableRow editing={editing}>
      {!editing && (
        <>
          <Grid style={{ margin: 0 }}>
            <Cell size={9} alignSelf='center'>{`${model.ciap.nome} - ${model.ciap.codigo}`}</Cell>
            <Cell size={3} alignSelf='center' style={{ padding: '0.25rem 0', textAlign: 'right' }}>
              <Tooltip text={model.observacao ? 'Editar observação' : 'Adicionar observação'}>
                <Button size='small' skin='ghost' onClick={toggleEditing}>
                  <Icon icon='chatOutline' />
                </Button>
              </Tooltip>
              <Tooltip text='Excluir'>
                <Button size='small' skin='ghost' onClick={handleRemove}>
                  <Icon icon='trashOutline' />
                </Button>
              </Tooltip>
            </Cell>
          </Grid>
          <Observacao observacao={model.observacao} />
        </>
      )}
      {editing && (
        <Form<MotivoConsultaFieldModel>
          render={renderForm}
          initialValues={model}
          onSubmit={handleSubmit}
          validate={validator}
          initialValuesEqual={(oldInitial, newInitial) => oldInitial._id === newInitial._id}
        />
      )}
    </EditableRow>
  )
}
