import { TipoAtendimentoProfissional } from 'graphql/types.generated'

export const atendimentoName = (tipo: TipoAtendimentoProfissional): string => {
  switch (tipo) {
    case TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO:
      return 'Atendimento odontológico'
    case TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS:
      return 'Atendimento de procedimentos'
    default:
      return 'Atendimento individual'
  }
}
