import { FormControl } from 'bold-ui'
import { SelectField, SelectFieldProps, selectFieldStatus } from 'components/form'
import { ParteBucalEnum } from 'graphql/types.generated'
import React from 'react'

import { getParteBucal } from '../../../util'

export type ReplicarItemSelectFieldModel = {
  title: string
  value: ParteBucalEnum
}

export interface ReplicarItemSelectFieldProps
  extends Omit<SelectFieldProps<ReplicarItemSelectFieldModel>, 'itemToString' | 'itemIsEqual' | 'multiple'> {
  label: string
  itemsDisabled?: ParteBucalEnum[]
}

export default function ReplicarItemSelectField(props: ReplicarItemSelectFieldProps) {
  const { name, label, items, itemsDisabled, ...rest } = props

  const itemIsDisabled = (item: ReplicarItemSelectFieldModel) => itemsDisabled?.includes(item.value)

  return (
    <FormControl label={label}>
      <SelectField<ReplicarItemSelectFieldModel>
        name={name}
        multiple
        items={items}
        itemToString={(item) => item && getParteBucal(item.value).title.replace(' - ', ' ')}
        itemIsEqual={(a, b) => a.value === b.value}
        itemIsDisabled={itemIsDisabled}
        customText={() => 'Já possui dados'}
        selectedItemStyle={selectFieldStatus.INFO}
        {...rest}
      />
    </FormControl>
  )
}

export const convertParteBucalEnumToReplicarItem = (partesBucal: ParteBucalEnum[]) =>
  partesBucal?.map(
    (parteBucal) =>
      ({
        title: getParteBucal(parteBucal).title,
        value: parteBucal,
      } as ReplicarItemSelectFieldModel)
  )

export const convertReplicarItemToParteBucalEnum = (replicarItems: ReplicarItemSelectFieldModel[]) =>
  replicarItems && replicarItems.map((item) => item.value)
