import { Text, VFlow } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import { HLabel } from 'components/HLabel'
import { SubstanciaAlergiaSelectFieldDocument } from 'graphql/hooks.generated'
import {
  CategoriaSubstanciaAlergia,
  SubstanciaAlergiaSelectFieldQuery,
  SubstanciaAlergiaSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_SIZE, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type SubstanciasCbaraSelectFieldModel = SubstanciaAlergiaSelectFieldQuery['substanciasCbaraAlergia']['content'][0]

export interface SubstanciasCbaraSelectFieldProps
  extends Omit<SelectFieldProps<SubstanciasCbaraSelectFieldModel>, 'items' | 'itemToString'> {
  categoria?: CategoriaSubstanciaAlergia
}

const itemToString = (item: SubstanciasCbaraSelectFieldModel) => item?.nome

const renderItem = (item: SubstanciasCbaraSelectFieldModel) => (
  <VFlow vSpacing={0}>
    <Text fontWeight='bold'>{item.nome}</Text>
    {item.sinonimos && <HLabel title='Sinônimos:'>{item.sinonimos}</HLabel>}
  </VFlow>
)

export function SubstanciasCbaraSelectField(props: SubstanciasCbaraSelectFieldProps) {
  const { categoria, ...rest } = props

  const { ...asyncProps } = useAsyncQuerySelect<
    SubstanciasCbaraSelectFieldModel,
    SubstanciaAlergiaSelectFieldQuery,
    SubstanciaAlergiaSelectFieldQueryVariables
  >({
    query: SubstanciaAlergiaSelectFieldDocument,
    extractItems: (data) => data?.substanciasCbaraAlergia?.content,
    variables: (inputQuery: string): SubstanciaAlergiaSelectFieldQueryVariables => ({
      filtro: {
        query: inputQuery,
        categoria: categoria,
        pageParams: {
          size: DEFAULT_SELECT_SIZE,
          sort: ['nome'],
        },
      },
    }),
  })

  return (
    <SelectField<SubstanciasCbaraSelectFieldModel>
      renderItem={renderItem}
      itemToString={itemToString}
      {...asyncProps}
      {...rest}
    />
  )
}
