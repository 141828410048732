import useFirebase from 'components/firebase/useFirebase'
import { subDays } from 'date-fns'
import { useMedicamentoUsoBarraLateralQuery, usePossuiTratamentoConcluidoQuery } from 'graphql/hooks.generated'
import React, { useEffect, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router'
import MedicamentosAtivosConcluidosModal from 'view/atendimentos/detail/components/modals/medicamentos/MedicamentosAtivosConcluidosModal'

import { ProntuarioCard } from '../ProntuarioCard'
import { MedicamentosCard } from './MedicamentosCard'

export interface MedicamentosSectionProps {
  prontuarioId: ID
  dataReferencia: Instant
  isCidadaoIdoso: boolean
  mostrarInativos?: boolean
  editable?: boolean
  isAtendimentoProcedimentos?: boolean
}

enum medicamentosTooltipEnum {
  VER_TODOS = 'Ver medicamentos',
  NENHUM_REGISTRADO = 'Nenhum medicamento registrado',
  VER_TRATAMENTO_CONCLUIDO = 'Ver medicamentos com tratamento concluído',
}

const DIAS_MOSTRAR_INATIVOS = 90

export function MedicamentosSection(props: MedicamentosSectionProps) {
  const {
    prontuarioId,
    isCidadaoIdoso,
    dataReferencia,
    mostrarInativos = false,
    editable = false,
    isAtendimentoProcedimentos = false,
  } = props

  const { analytics } = useFirebase()
  const history = useHistory()
  const match = useRouteMatch()
  const [canRender, setCanRender] = useState(false)
  const [tooltip, setTooltip] = useState(medicamentosTooltipEnum.NENHUM_REGISTRADO)

  const modalUrl = `${match.path}/medicamentos-ativos-concluidos`

  const handleClick = () => {
    history.push({
      pathname: modalUrl,
      state: {
        prontuarioId: prontuarioId,
        dataAtendimento: dataReferencia,
      },
    })
    analytics.logEvent('open_modal_medicamentos_ativos_concluidos')
  }

  const {
    data: { listaMedicamento: listaMedicamentosAtivos },
    loading: loadingAtivos,
  } = useMedicamentoUsoBarraLateralQuery({
    variables: {
      input: {
        prontuarioId,
        dataAtendimento: dataReferencia,
        usoContinuo: false,
        ativo: true,
        pageParams: { unpaged: true },
      },
    },
  })

  const dataDesdePrescricao = Number(subDays(dataReferencia, DIAS_MOSTRAR_INATIVOS))

  const {
    data: { listaMedicamento: listaMedicamentosInativos },
    loading: loadingInativos,
  } = useMedicamentoUsoBarraLateralQuery({
    variables: {
      input: {
        prontuarioId,
        dataAtendimento: dataReferencia,
        usoContinuo: false,
        ativo: false,
        dataMinimaFimTratamento: dataDesdePrescricao,
      },
    },
    skip: !mostrarInativos,
  })

  const {
    data: { possuiTratamentoConcluido },
    loading: loadingTratamentoConcluido,
  } = usePossuiTratamentoConcluidoQuery({
    variables: { input: { prontuarioId, dataAtendimento: dataReferencia } },
    skip: listaMedicamentosAtivos?.content.length !== 0,
  })

  const existemMedicamentos =
    listaMedicamentosAtivos?.content?.length > 0 || listaMedicamentosInativos?.content?.length > 0

  useEffect(() => {
    setCanRender(existemMedicamentos)
    const tooltip = possuiTratamentoConcluido
      ? medicamentosTooltipEnum.VER_TRATAMENTO_CONCLUIDO
      : existemMedicamentos
      ? medicamentosTooltipEnum.VER_TODOS
      : medicamentosTooltipEnum.NENHUM_REGISTRADO
    setTooltip(tooltip)
  }, [canRender, possuiTratamentoConcluido, existemMedicamentos])

  return (
    <>
      <Route
        path={modalUrl}
        render={() => (
          <MedicamentosAtivosConcluidosModal
            editable={editable}
            prontuarioId={prontuarioId}
            dataAtendimento={dataReferencia}
            isAtendimentoProcedimentos={isAtendimentoProcedimentos}
          />
        )}
      />
      <ProntuarioCard
        title={mostrarInativos ? 'Medicamentos' : 'Medicamentos em uso'}
        tooltip={tooltip}
        onClick={handleClick}
      >
        <MedicamentosCard
          listaMedicamentosAtivos={listaMedicamentosAtivos?.content}
          listaMedicamentosInativos={listaMedicamentosInativos?.content}
          loading={loadingAtivos || loadingInativos}
          loadingTratamentoConcluido={loadingTratamentoConcluido}
          isCidadaoIdoso={isCidadaoIdoso}
          canRender={canRender}
          mostrarInativos={mostrarInativos}
        />
      </ProntuarioCard>
    </>
  )
}
