import { Alert, Button, HeadingSection, Text, VFlow } from 'bold-ui'
import {
  EnderecoFieldGroup,
  Form,
  FormFooter,
  FormPrompt,
  FormRenderProps,
  validateEnderecoCompleto as validateEndereco,
} from 'components/form'
import { RacaCorSelectModel } from 'components/form/field/select/RacaCorSelectField'
import { SimNaoEnum } from 'components/form/field/SimNaoRadioGroupField'
import { resolveValue } from 'components/form/final-form/useField'
import { confirm } from 'components/modals/confirm'
import { AnyObject } from 'final-form'
import createCalculator from 'final-form-calculate'
import { useHasCidadaoAgendamentoFuturoQuery, useSalvarCidadaoMutation } from 'graphql/hooks.generated'
import { NacionalidadeEnum, RacaCorDbEnum } from 'graphql/types.generated'
import qs from 'qs'
import React, { useMemo } from 'react'
import { FormSpy } from 'react-final-form'
import { useHistory, useLocation } from 'react-router'
import { metaPath } from 'util/metaPath'
import { createValidator, ErrorObject, required, ValidateFunction } from 'util/validation'

import CidadaoFormModel, { ContatosFormModel } from '../types/CidadaoFormModel'
import convertModelToInput from '../types/convertModelToInput'
import { useNavigateToCidadaoCallbackUrl } from '../useNavigateToCidadaoCallbackUrl'
import { CidadaoDadosFieldGroup, validator as validateCidadao } from './CidadaoDadosFieldGroup'
import { CompartilhamentoProntuarioFieldGroup } from './CompartilhamentoProntuarioFieldGroup'
import { ContatosFieldGroup, validate as validateContatos } from './ContatosFieldGroup'
import { enderecoCalculator } from './EnderecoCalculator'
import {
  InformacoesComplementaresFieldGroup,
  validate as validateInformacoesComplementares,
} from './InformacoesComplementaresFieldGroup'
import { UrlParams } from './ModalStatusSincronizacaoCadsus'
import { VincularCidadaoComponentRoot } from './vinculacao/VincularCidadaoComponentRoot'

export interface CidadaoFormProps {
  initialValues: CidadaoFormModel
}

export const validator = createValidator<CidadaoFormModel>(
  {
    contato: validateContatos,
    endereco: validateEndereco,
    informacoesComplementares: validateInformacoesComplementares,
  },
  (values: CidadaoFormModel, errors: ErrorObject<CidadaoFormModel>) => {
    const errorsCidadao: ValidateFunction<CidadaoFormModel> = validateCidadao
    const errorsValidate = errorsCidadao(values, errors)

    if (
      NacionalidadeEnum.ESTRANGEIRA !== values.nacionalidade &&
      !Boolean(
        values.contato?.telefoneResidencial || values.contato?.telefoneCelular || values.contato?.telefoneContato
      )
    ) {
      const contatoError = errors.contato as ErrorObject<ContatosFormModel>
      errors.contato = {
        ...contatoError,
        telefones: required(
          values?.contato?.telefoneResidencial || values.contato?.telefoneCelular || values.contato?.telefoneContato
        ),
      }
    }
    return errorsValidate
  }
)

const path = metaPath<CidadaoFormModel>()

const cidadaoCalculator = createCalculator(
  {
    field: path.racaCor.absolutePath(),
    updates: {
      [path.etnia.absolutePath()]: (value: RacaCorSelectModel, values: CidadaoFormModel) => {
        return value?.value !== RacaCorDbEnum.INDIGENA ? null : values.etnia
      },
    },
  },
  {
    field: path.nacionalidade.absolutePath(),
    updates: {
      [path.municipioNascimento.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        return value !== NacionalidadeEnum.BRASILEIRA ? null : values.municipioNascimento
      },
      [path.portariaNaturalizacao.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        return value !== NacionalidadeEnum.NATURALIZADA ? null : values.portariaNaturalizacao
      },
      [path.dataNaturalizacao.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        return value !== NacionalidadeEnum.NATURALIZADA ? null : values.dataNaturalizacao
      },
      [path.paisNascimento.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        if (value === NacionalidadeEnum.ESTRANGEIRA) {
          return values.paisNascimento
        } else {
          return null
        }
      },
      [path.dataEntradaPais.absolutePath()]: (value: NacionalidadeEnum, values: CidadaoFormModel) => {
        return value !== NacionalidadeEnum.ESTRANGEIRA ? null : values.dataEntradaPais
      },
    },
  },
  {
    field: path.cidadaoFaleceu.absolutePath(),
    updates: {
      [path.dataObito.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.dataObito
      },
      [path.numeroDeclaracaoObito.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return !value ? null : values.numeroDeclaracaoObito
      },
      [path.vinculacao.absolutePath()]: (value: boolean, values: CidadaoFormModel) => {
        return value ? null : values.vinculacao
      },
    },
  }
)

const infoComplementaresCalculator = createCalculator(
  {
    field: path.informacoesComplementares.desejaInformarOrientacaoSexual.absolutePath(),
    updates: {
      [path.informacoesComplementares.orientacaoSexual.absolutePath()]: (
        value: SimNaoEnum,
        values: CidadaoFormModel
      ) => {
        const infoComplementares = resolveValue(values, path.informacoesComplementares)
        return SimNaoEnum.NAO === value ? null : infoComplementares?.orientacaoSexual
      },
    },
  },
  {
    field: path.informacoesComplementares.desejaInformarIdentidadeGenero.absolutePath(),
    updates: {
      [path.informacoesComplementares.identidadeGenero.absolutePath()]: (
        value: SimNaoEnum,
        values: CidadaoFormModel
      ) => {
        const infoComplementares = resolveValue(values, path.informacoesComplementares)
        return SimNaoEnum.NAO === value ? null : infoComplementares?.identidadeGenero
      },
    },
  }
)

export function CidadaoDadosForm(props: CidadaoFormProps) {
  const history = useHistory()
  const location = useLocation()
  const [save] = useSalvarCidadaoMutation()
  const enderecoDecorator = useMemo(() => enderecoCalculator(path.endereco), [])

  const urlParams: UrlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const { refetch } = useHasCidadaoAgendamentoFuturoQuery({ skip: true })
  const handleOnCancelarClicked = () => {
    sessionStorage.removeItem('cidadaoCadsus')

    switch (urlParams?.callbackUrl) {
      case 'lista-atendimento/atendimento-vacinacao':
        history.push(`/${urlParams.callbackUrl}/${urlParams.callbackParams}`)
        break
      case 'visualizar-escuta':
      case 'escuta-inicial':
        history.push(`/lista-atendimento/${urlParams.callbackUrl}/${urlParams.callbackParams}/cadastro-cidadao`)
        break
      default:
        history.push('/cidadao' + history.location.search)
        break
    }
  }

  function buildMessage(values: CidadaoFormModel, hasCidadaoObitoAgendamentoFuturo: boolean = false) {
    let listItem: string[] = []

    if (!values.vinculacao && !values.cidadaoFaleceu) {
      listItem.push(
        'O cidadão não está vinculado a nenhuma equipe. O cadastro permanecerá sem vínculo com uma equipe responsável.'
      )
    }
    if (!values.cpf && !values.cns) {
      listItem.push(
        'Cidadão sem CPF ou CNS registrado. Este cadastro não será considerado elegível para contabilização nos indicadores de saúde do município.'
      )
    }
    if (
      !values.vinculacao &&
      values.cidadaoFaleceu &&
      props.initialValues?.vinculacao &&
      !props.initialValues?.cidadaoFaleceu
    ) {
      listItem.push('Ao informar óbito do cidadão, ele perderá o vínculo com a sua equipe responsável.')
    }
    if (hasCidadaoObitoAgendamentoFuturo) {
      listItem.push('Ao informar óbito do cidadão, os agendamentos futuros serão cancelados.')
    }
    return listItem
  }
  const navigateToCidadaoCallbackUrl = useNavigateToCidadaoCallbackUrl()
  const handleOnConcluir = (cidadaoId: string) => {
    sessionStorage.removeItem('cidadaoCadsus')
    if (urlParams.callbackUrl) {
      switch (urlParams.callbackUrl) {
        case 'cadastro-cidadao':
          history.push(`/lista-atendimento/atendimento/${urlParams.callbackParams}/${urlParams.callbackUrl}`)
          break
        case 'lista-atendimento/atendimento':
        case 'lista-atendimento/atendimento-vacinacao':
          history.push(`/${urlParams.callbackUrl}/${urlParams.callbackParams}`)
          break
        case 'visualizar-escuta':
        case 'escuta-inicial':
          history.push(`/lista-atendimento/${urlParams.callbackUrl}/${urlParams.callbackParams}/cadastro-cidadao`)
          break
        default:
          navigateToCidadaoCallbackUrl(cidadaoId)
          break
      }
    } else {
      history.push(`/cidadao/${cidadaoId}`)
    }
  }

  const onHandleSubmit = (values: CidadaoFormModel) =>
    save({ variables: { input: convertModelToInput(values) } }).then((result) => {
      setTimeout(() => handleOnConcluir(result.data.salvarCidadao.id))
    })

  const dialog = (listItem: string[], handleSubmit: () => Promise<AnyObject>) => {
    confirm({
      title: 'Deseja salvar o cadastro do cidadão?',
      body: (
        <>
          <VFlow>
            <Text>
              <ul>
                {listItem.map((value, idx) => {
                  return <li key={idx}>{value}</li>
                })}
                <li>
                  <strong>Atenção: </strong>Temporariamente, o PEC não está enviando ou atualizando os dados no CADSUS.
                  Apenas a busca dos dados no CADSUS se mantém.
                </li>
              </ul>
            </Text>
          </VFlow>
        </>
      ),
      cancelLabel: 'Cancelar',
      confirmLabel: 'Salvar',
      onConfirm: () => {
        return handleSubmit()
      },
    })()
  }

  const salvar = (formProps: FormRenderProps<CidadaoFormModel>) => () => {
    const { values, handleSubmit } = formProps
    const processAgendamentoFuturo = () => {
      return ({ data }) => {
        const listItem = buildMessage(values, data.hasAgendamentoFuturo)
        dialog(listItem, handleSubmit)
      }
    }

    if (values.id && values.cidadaoFaleceu && values.dataObito) {
      return refetch({
        input: { id: values.id, dataReferencia: values.dataObito },
      }).then(processAgendamentoFuturo())
    } else {
      const listItem = buildMessage(values)
      dialog(listItem, handleSubmit)
    }
  }

  const renderForm = (formProps: FormRenderProps<CidadaoFormModel>) => {
    return (
      <form onSubmit={formProps.handleSubmit} noValidate>
        <FormPrompt />
        <VFlow vSpacing={2}>
          <VFlow>
            <Alert type='info' inline>
              O módulo tem como objetivo atualizar ou registrar os dados cadastrais do cidadão no sistema. Esses dados
              também podem ser provenientes das informações da Ficha de Cadastro Individual ou Ficha de Avaliação de
              Elegibilidade após seu processamento.
            </Alert>
            <Alert type='warning' inline>
              Temporariamente, o PEC não está enviando ou atualizando os dados no CADSUS. Apenas a busca dos dados no
              CADSUS se mantém.
            </Alert>
          </VFlow>

          <HeadingSection level={2} title='Dados pessoais'>
            <CidadaoDadosFieldGroup name={path} formProps={formProps} />
          </HeadingSection>
          <HeadingSection level={2} title='Contatos'>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => {
                const contatoObrigatorio = values.nacionalidade !== NacionalidadeEnum.ESTRANGEIRA
                return <ContatosFieldGroup name={path.contato} formProps={formProps} required={contatoObrigatorio} />
              }}
            </FormSpy>
          </HeadingSection>
          <HeadingSection level={2} title='Equipe responsável pelo cidadão'>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <VincularCidadaoComponentRoot
                  name={path.vinculacao}
                  values={values}
                  formProps={formProps}
                  cnsCidadao={values.cns}
                  cpfCidadao={values.cpf}
                  uuidFicha={values.uuidFicha}
                  faleceu={values.cidadaoFaleceu}
                />
              )}
            </FormSpy>
          </HeadingSection>
          <HeadingSection level={2} title='Endereço'>
            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <EnderecoFieldGroup
                  name={path.endereco}
                  formProps={formProps}
                  formModelValue={values}
                  renderPaisAreaMicroArea
                  renderTipoLogradouro
                />
              )}
            </FormSpy>
          </HeadingSection>
          <HeadingSection level={2} title='Informações complementares'>
            <InformacoesComplementaresFieldGroup name={path.informacoesComplementares} formProps={formProps} />
          </HeadingSection>

          <HeadingSection level={2} title='Compartilhamento de prontuário'>
            <CompartilhamentoProntuarioFieldGroup name={path} />
          </HeadingSection>

          <FormFooter>
            <Button onClick={handleOnCancelarClicked || history.goBack} data-testid='FormFooter.cancelar'>
              Cancelar
            </Button>
            <Button kind='primary' onClick={salvar(formProps)} data-testid='FormFooter.salvar'>
              Salvar
            </Button>
          </FormFooter>
        </VFlow>
      </form>
    )
  }
  return (
    <Form<CidadaoFormModel>
      decorators={[cidadaoCalculator, enderecoDecorator, infoComplementaresCalculator]}
      validate={validator}
      render={renderForm}
      onSubmit={onHandleSubmit}
      keepDirtyOnReinitialize={true}
      {...props}
    />
  )
}
