import useSession from 'components/auth/useSession'
import { subDays } from 'date-fns'
import { OrdemListaAtendimentoEnum, StatusAtendimento } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import Permissions from 'types/Permissions'

import { AtendimentosFilterModel } from '../../atendimentos/list/ListaAtendimentoView'

export const REGISTRO_TARDIO_DEFAULT_DATE_DIFF = 7

export function useListaRegistroTardioFilterDefault(): AtendimentosFilterModel {
  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow()

  const { hasAuthorization } = useSession()
  const lotacaoPodeAtender = hasAuthorization(Permissions.visualizarListaDeAtendimento.registrarAtendimento)

  return {
    filter: {
      periodo: {
        startDate: subDays(serverTime, REGISTRO_TARDIO_DEFAULT_DATE_DIFF),
        endDate: serverTime,
      },
      ordem: OrdemListaAtendimentoEnum.ORDEM_CHEGADA_DECRESCENTE,
      statusAtendimento: [StatusAtendimento.AGUARDANDO_ATENDIMENTO, StatusAtendimento.EM_ATENDIMENTO],
      lotacoes: [],
      somenteMeusAtendimentos: lotacaoPodeAtender,
    },
    lastUpdate: serverTime,
    lastDailyUpdate: serverTime,
    pageParams: {},
  }
}
