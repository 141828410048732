/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading } from 'bold-ui'
import { isEmpty } from 'lodash'
import { Fragment } from 'react'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { AvaliacoesPresentesDescription } from '../../objetivo/puericultura/components/AvaliacoesPresentesDescription'
import PuericulturaAlert from '../../objetivo/puericultura/components/PuericulturaAlert'
import MarcosDesenvolvimentoView from '../../objetivo/puericultura/marcos/MarcosDesenvolvimentoView'
import { PuericulturaModel } from '../../objetivo/puericultura/model'
import { MarcoFormSectionAccordionAcompanhamento } from './components/MarcoFormSectionAccordionAcompanhamento'
import { AcompanhamentoPuericulturaModel } from './model'

interface DesenvolvimentoCriancaAcompanhamentoPuericulturaProps {
  acompanhamentoPuericultura: AcompanhamentoPuericulturaModel
  atendimentoPuericultura: PuericulturaModel
  dataAtendimento: Instant
  cidadao: Pick<CidadaoAtendimento, 'dataNascimento' | 'sexo'>
}

export function DesenvolvimentoCriancaAcompanhamentoPuericultura(
  props: DesenvolvimentoCriancaAcompanhamentoPuericulturaProps
) {
  const { acompanhamentoPuericultura, atendimentoPuericultura, dataAtendimento, cidadao } = props

  const dataReferenciaAlerta = !isEmpty(atendimentoPuericultura)
    ? dataAtendimento
    : acompanhamentoPuericultura.ultimoAtendimentoPuericultura?.dataInicio

  const { alteracoesFenotipicas, fatoresRisco, marcosDesenvolvimento, medicaoAnteriorPerimetroCefalico } =
    atendimentoPuericultura || acompanhamentoPuericultura

  return (
    <Fragment>
      <Heading
        level={2}
        style={css`
          margin-top: 1rem;
        `}
      >
        Desenvolvimento da criança
      </Heading>
      <Grid
        style={css`
          margin-top: 0.5rem;
        `}
      >
        <Cell size={12}>
          <PuericulturaAlert
            alteracoesFenotipicas={alteracoesFenotipicas.alteracoesFenotipicas}
            fatoresRisco={fatoresRisco.fatoresRisco}
            marcosDesenvolvimento={marcosDesenvolvimento}
            medicaoAnteriorPerimetroCefalico={medicaoAnteriorPerimetroCefalico}
            cidadao={cidadao}
            dataReferencia={dataReferenciaAlerta}
          />
        </Cell>
        <Cell size={12}>
          <AvaliacoesPresentesDescription
            title='Alterações fenotípicas:'
            placeholder='Nenhuma alteração fenotípica presente'
            avaliacoes={alteracoesFenotipicas.alteracoesFenotipicas}
          />
        </Cell>
        <Cell size={12}>
          <AvaliacoesPresentesDescription
            title='Fatores de risco:'
            placeholder='Nenhum fator de risco presente'
            avaliacoes={fatoresRisco.fatoresRisco}
          />
        </Cell>
        <Cell size={12}>
          <MarcosDesenvolvimentoView>
            <MarcoFormSectionAccordionAcompanhamento marcosRecord={marcosDesenvolvimento.marcosRecord} />
          </MarcosDesenvolvimentoView>
        </Cell>
      </Grid>
    </Fragment>
  )
}
