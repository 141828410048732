import { ResultadoExameEspecializadoInput } from 'graphql/types.generated'
import { parseNumber } from 'util/validation'

import { ResultadoExameEspecificoModel } from '../../model'
import { getResultadoExameEspecificoProperties } from '../../util'

export const convertResultadoExameMarcadoresBioquimicosToInput = (
  value: ResultadoExameEspecificoModel
): ResultadoExameEspecializadoInput => {
  const { resultado, exame } = value

  const { relatedDbEnum } = getResultadoExameEspecificoProperties(exame)

  return {
    procedimento: relatedDbEnum,
    valor: parseNumber(resultado),
  }
}

export const convertResultadoExamePrenatalToInput = (
  value: ResultadoExameEspecificoModel
): ResultadoExameEspecializadoInput => {
  const { resultado, exame } = value

  const { dpp, idadeGestacional } = resultado

  const { semanas, dias } = idadeGestacional || {}

  const { relatedDbEnum } = getResultadoExameEspecificoProperties(exame)

  return {
    procedimento: relatedDbEnum,
    dpp,
    igDias: dias,
    igSemanas: semanas,
  }
}

export const convertResultadoExamePuericulturaToInput = (
  value: ResultadoExameEspecificoModel
): ResultadoExameEspecializadoInput => {
  const { resultado, exame } = value

  const { relatedDbEnum } = getResultadoExameEspecificoProperties(exame)

  return {
    procedimento: relatedDbEnum,
    fichaComplementarPuericultura: resultado,
  }
}
