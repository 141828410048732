import { information } from 'components/modals/information'
import { formatDate } from 'util/date/formatDate'
import { LIMITE_DIFERENCA_ENTRE_DUM_DATA_ATENDIMENTO_EM_DIAS } from 'view/atendimentos/detail/soap/pre-natal/model'

import { ProblemaCondicaoModel } from '../model'

export const cidCiapString = (model: ProblemaCondicaoModel) => {
  const ciap = model.ciap ? ` ${model.ciap.codigo} (CIAP2) ` : ''
  const cid = model.cid ? ` ${model.cid.codigo} (CID10) ` : ''

  return `${ciap}${ciap && cid && '-'}${cid}`
}

export const informationPreNatalQuandoExisteDesfecho = () => {
  information({
    title: 'Não é possível registrar Pré-natal em atendimentos com desfecho de gravidez.',
    body: 'Para registrar Pré-natal remova a condição de desfecho de gravidez.',
  })()
}

export const informationPreNatalQuandoExistePuericultura = () => {
  information({
    title: 'Não é possível registrar Pré-natal em atendimentos de Puericultura.',
    body: 'Para registrar Pré-natal desmarque o botão de Puericultura.',
  })()
}

export const informationDesfechoQuandoExistePuericultura = () => {
  information({
    title: 'Não é possível registrar desfecho de gravidez em atendimentos de Puericultura.',
    body: 'Para registrar desfecho de gravidez desmarque o botão de Puericultura.',
  })()
}

export const informationDesfechoQuandoExistePreNatal = () => {
  information({
    title: 'Não é possível registrar desfecho de gravidez em atendimentos de Pré-natal.',
    body: 'Para registrar desfecho de gravidez remova a condição de Pré-natal.',
  })()
}

export const informationPuericulturaQuandoExistePreNatal = () => {
  information({
    title: 'Não é possível registrar Puericultura em atendimentos de Pré-natal.',
    body: 'Para registrar Puericultura remova a condição de Pré-natal.',
  })()
}

export const informationPuericulturaQuandoExisteDesfecho = () => {
  information({
    title: 'Não é possível registrar Puericultura em atendimentos com desfecho de gravidez.',
    body: 'Para registrar Puericultura remova a condição de desfecho de gravidez.',
  })()
}

export const informationDumForaDoLimitePermitido = (dum: string) => {
  information({
    title: 'Não é possível realizar atendimento de pré-natal.',
    body: `A DUM informada (${formatDate(
      dum
    )}) é anterior a ${LIMITE_DIFERENCA_ENTRE_DUM_DATA_ATENDIMENTO_EM_DIAS} dias em relação a data do atendimento. Para iniciar um pré-natal, a DUM não pode ser anterior a este período.`,
  })()
}
