/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { AccordionCompositeItem } from 'components/accordion/AccordionCompositeItem'
import { ButtonLink, PrivateRoute } from 'components/route'
import theme from 'config/theme'
import { SexoEnum, TipoReceitaEnum, UnidadeMedidaTempoEnum } from 'graphql/types.generated'
import moment from 'moment'
import { Switch, useRouteMatch } from 'react-router'
import { tipoReceitaRecord } from 'types/enums'

import { grupoCboPrescricao } from '../../acessos'
import { MedicamentoFormModel } from '../MedicamentoFormModel'
import ImpressaoPrescricaoModal from '../modal/ImpressaoPrescricaoModal'
import {
  prescricaoAtendBodyConverter,
  prescricaoAtendHeaderConverter,
  PrescricaoAtendimentoBodyModel,
} from '../utils/prescricaoConverter'
import DisponibilidadeHorus from './DisponibilidadeHorus'

interface AccordionPrescricaoProps {
  medicamentos: MedicamentoFormModel[]
  atendimentoId: ID
  cidadaoDataNascimento: LocalDate
  cidadaoSexo: SexoEnum
}

export default function AccordionPrescricao({
  medicamentos,
  atendimentoId,
  cidadaoDataNascimento,
  cidadaoSexo,
}: AccordionPrescricaoProps) {
  const match = useRouteMatch()

  const renderHeader = (title: string) => {
    return (
      <HFlow
        style={css`
          width: 100%;
        `}
      >
        <Heading level={5} style={styles.heading}>
          {title}
        </Heading>
        <div css={styles.adicionadoAgora}>
          <Icon
            icon='clockOutline'
            size={1}
            style={css`
              margin-right: 0.5rem;
            `}
          />
          Adicionado agora
        </div>
      </HFlow>
    )
  }

  const renderBody = (prescricaoAtendimento: PrescricaoAtendimentoBodyModel[]) => {
    return prescricaoAtendimento.map((medicamento, index) => {
      return (
        <HFlow
          key={index}
          justifyContent='space-between'
          alignItems='center'
          style={css`
            padding: 1rem 4.5rem 1rem 1rem;
          `}
        >
          <VFlow
            style={css`
              width: 100%;
            `}
          >
            <div>
              <HFlow alignItems='center' hSpacing={0.5}>
                <Heading
                  level={5}
                  style={css`
                    word-break: break-word;
                  `}
                >
                  {`${medicamento.principioAtivo} ${medicamento.concentracao ?? ''}`.trim()}
                  {medicamento.usoContinuo && (
                    <Tag
                      type='normal'
                      style={css`
                        margin-left: 0.5rem;
                      `}
                    >
                      Uso contínuo
                    </Tag>
                  )}
                  {medicamento.tipoReceita !== TipoReceitaEnum.COMUM && (
                    <Tag
                      type='info'
                      style={css`
                        margin-left: 0.5rem;
                      `}
                    >
                      {tipoReceitaRecord[medicamento.tipoReceita]}
                    </Tag>
                  )}
                </Heading>
              </HFlow>
              <Text>{`${medicamento.posologia} | ${medicamento.viaAdministracao.nome} | ${medicamento.formaFarmaceutica}`}</Text>
            </div>
            <div>
              <Heading level={5}>Recomendações</Heading>
              {medicamento.recomendacoes ? (
                <Text
                  style={css`
                    overflow-wrap: anywhere;
                  `}
                >
                  {medicamento.recomendacoes}
                </Text>
              ) : (
                <Text fontStyle='italic'>Não há recomendações para o medicamento</Text>
              )}
            </div>
          </VFlow>
          <VFlow vSpacing={0}>
            <Heading level={5}>{`Período: ${moment(medicamento.dataInicioTratamento).format('DD/MM/YYYY')} 
                - ${
                  medicamento.escalaDuracao !== UnidadeMedidaTempoEnum.INDETERMINADO
                    ? moment(medicamento.dataFimTratamento).format('DD/MM/YYYY')
                    : 'Indeterminado'
                }
              `}</Heading>
            <Text>
              <DisponibilidadeHorus disponibilidade={medicamento.disponibilidade} />
            </Text>
          </VFlow>
        </HFlow>
      )
    })
  }

  const renderHeaderButtons = () => {
    return (
      <HFlow hSpacing={0} alignItems='center'>
        <Switch>
          <PrivateRoute
            path={`${match.url}/prescricao-medicamentos/imprimir`}
            permission={grupoCboPrescricao.adicionar}
          >
            <ImpressaoPrescricaoModal
              medicamentos={medicamentos}
              atendimentoId={atendimentoId}
              cidadaoDataNascimento={cidadaoDataNascimento}
              cidadaoSexo={cidadaoSexo}
            />
          </PrivateRoute>
        </Switch>
        <Tooltip text='Imprimir'>
          <ButtonLink size='small' skin='ghost' to={`${match.url}/prescricao-medicamentos/imprimir`}>
            <Icon icon='printerOutline' />
          </ButtonLink>
        </Tooltip>
      </HFlow>
    )
  }

  return (
    <AccordionCompositeItem
      header={renderHeader(prescricaoAtendHeaderConverter(medicamentos))}
      headerButtons={renderHeaderButtons()}
      body={renderBody(prescricaoAtendBodyConverter(medicamentos))}
    />
  )
}

const styles = {
  heading: css`
    display: flex;
    align-items: center;
  `,
  adicionadoAgora: css`
    color: ${theme.pallete.primary.main};
    display: flex;
    align-items: center;
  `,
}
