import { AvaliacaoAlergiaInput, AvaliacaoInput, AvaliacaoProblemaInput } from 'graphql/types.generated'

import { convertEncerrarGestacaoFormToInput } from '../pre-natal/converter'
import { AvaliacaoFormModel } from './AvaliacaoForm'
import { ProblemaCondicaoModel } from './components/problemas-condicoes/model'

export const convertAvaliacaoFormToInput = (form?: AvaliacaoFormModel): AvaliacaoInput =>
  form
    ? {
        texto: form.texto,
        problemasCondicoesAvaliadas: [
          ...(form.problemasECondicoes
            ? form.problemasECondicoes.map((condicao) => convertProblemaCondicaoModelToInput(condicao))
            : []),
        ],
        alergiasAvaliadas: form.alergias?.map(
          (alergia) =>
            ({
              substanciaId:
                alergia.substanciaCbara?.id ??
                alergia.substanciaImunobiologico?.id ??
                alergia.substanciaMedicamento?.medicamento?.id,
              categoriaSubstancia: alergia.categoriaSubstancia,
              tipoReacao: alergia.tipoReacao,
              alergiaId: alergia.alergiaId,
              criticidade: alergia.criticidade,
              dataInicio: alergia.dataInicio?.data,
              grauCerteza: alergia.grauCerteza,
              manifestacoesIds: alergia.manifestacoes?.map((m) => m.id),
              observacao: alergia.observacao,
              isAvaliacao: alergia.isAvaliacao,
            } as AvaliacaoAlergiaInput)
        ),
        vigilanciaSaudeBucal:
          form.tiposVigilancia && form.necessidadeProtese
            ? {
                necessidadeProtese: form.necessidadeProtese && JSON.parse(form.necessidadeProtese),
                tiposVigilancia: form.tiposVigilancia,
              }
            : null,
        encerrarGestacao: convertEncerrarGestacaoFormToInput(form.encerrarGestacao),
      }
    : null

const convertProblemaCondicaoModelToInput = (condicao: ProblemaCondicaoModel): AvaliacaoProblemaInput => ({
  ciapId: condicao.ciap?.id,
  cidId: condicao.cid?.id,
  incluirListaProblemas: condicao.incluirNaListaProblemas || condicao.registradoAgora,
  situacao: condicao.situacaoProblema,
  dataInicio: condicao.dataInicio?.data,
  dataFim: condicao.dataFim?.data,
  observacao: condicao.observacao,
  id: condicao.problemaCondicaoEvoluir?.id,
})
