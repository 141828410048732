import { LotacaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { OrientacaoFormModel, OrientacaoRowModel } from '../types/OrientacoesModel'
import { sortOrientacoes } from './sortOrientacoes'

export function mergeSortOrientacoes(
  orientacoesDatabase: OrientacaoRowModel[],
  orientacoesCache: OrientacaoFormModel[],
  sort: string[],
  dataReferencia: Instant,
  atendimentoProfissionalId: ID,
  lotacao: LotacaoAtendimento | LotacaoFolhaRosto
): OrientacaoRowModel[] {
  const orientacoesCacheTransformadas = orientacoesCache.map((value, index) =>
    createOrientacaoFromCache(value, index, dataReferencia, atendimentoProfissionalId, lotacao)
  )
  const orientacoesDatabaseOrdenadas = sortOrientacoes(orientacoesDatabase, sort)
  return [...orientacoesCacheTransformadas, ...orientacoesDatabaseOrdenadas]
}

function createOrientacaoFromCache(
  orientacaoCache: OrientacaoFormModel,
  index: number,
  dataReferencia: Instant,
  atendimentoProfissionalId: ID,
  lotacao: LotacaoAtendimento | LotacaoFolhaRosto
): OrientacaoRowModel {
  return {
    cacheId: index,
    descricao: orientacaoCache.descricao,
    atendimentoProfissional: {
      id: atendimentoProfissionalId,
      iniciadoEm: dataReferencia,
      lotacao,
    },
  }
}
