import { IdentidadeGeneroEnum, SexoEnum } from 'graphql/types.generated'
import { EstadoNutricional } from 'types/enums'
import { sexoByIdentidadeGenero } from 'util/sexoByIdentidadeGenero'
import { PeriodoGestacaoModel } from 'view/atendimentos/detail/components/modals/types/PeriodoGestacaoModel'

import { getEstadoNutricionalGestante } from './gestante/calculateEstadoNutricionalGestante'
import { calculateIdadeGestacionalParaGraficos } from './gestante/calculateIdadeGestacional'
import imcAdulto from './parametros/imcAdulto.json'
import imcIdoso from './parametros/imcIdoso.json'
import { createParameters, Percentil } from './percentis/calculatePercentil'

export interface ImcRange {
  minimo?: number
  maximo?: number
}

export interface ParametrosImc {
  baixo: ImcRange
  adequado: ImcRange
  sobrepeso: ImcRange
  obesidade?: ImcRange
}

export function estadoNutricional(
  imc: number,
  sexo: SexoEnum,
  idade: number,
  gestacoes?: PeriodoGestacaoModel[],
  identidadeGenero?: IdentidadeGeneroEnum
) {
  const sexoCidadao = sexoByIdentidadeGenero(sexo, identidadeGenero)
  const { idadeGestacional, hasGestacaoPeriodo } = calculateIdadeGestacionalParaGraficos(gestacoes || [])

  if (hasGestacaoPeriodo) {
    return estadoNutricionalGestante(imc, idadeGestacional)
  } else {
    if (idade >= 60) {
      return estadoNutricionalIdoso(imc)
    } else if (idade >= 19 && idade < 60) {
      return estadoNutricionalAdulto(imc)
    } else if (idade >= 0) {
      return estadoNutricionalCrianca(imc, sexoCidadao, idade)
    }
  }
}

function estadoNutricionalIdoso(imc: number) {
  if (imc > imcIdoso.sobrepeso.minimo) {
    return EstadoNutricional.SOBREPESO.DANGER
  } else if (imc >= imcIdoso.adequado.minimo) {
    return EstadoNutricional.ADEQUADO
  } else {
    return EstadoNutricional.BAIXO_PESO
  }
}

function estadoNutricionalAdulto(imc: number) {
  if (imc >= imcAdulto.obesidade.minimo) {
    return EstadoNutricional.OBESIDADE.DANGER
  } else if (imc >= imcAdulto.sobrepeso.minimo) {
    return EstadoNutricional.SOBREPESO.WARNING
  } else if (imc >= imcAdulto.adequado.minimo) {
    return EstadoNutricional.ADEQUADO
  } else {
    return EstadoNutricional.BAIXO_PESO
  }
}

function estadoNutricionalCrianca(imc: number, sexo: SexoEnum, idade: number) {
  const sexoValue = sexo === SexoEnum.MASCULINO ? SexoEnum.MASCULINO : SexoEnum.FEMININO
  const parameters = createParameters(sexoValue, idade, 'imc')
  const ZscoreFinal = parameters.calcularZscoreFinal(imc)

  if (ZscoreFinal < Percentil.sd3neg) {
    return EstadoNutricional.MAGREZA_ACENTUADA
  }
  if (ZscoreFinal >= Percentil.sd3neg && ZscoreFinal < Percentil.sd2neg) {
    return EstadoNutricional.MAGREZA
  }
  if (idade <= 5) {
    if (ZscoreFinal >= Percentil.sd2neg && ZscoreFinal <= Percentil.sd1pos) {
      return EstadoNutricional.EUTROFICO
    }
    if (ZscoreFinal > Percentil.sd1pos && ZscoreFinal <= Percentil.sd2pos) {
      return EstadoNutricional.RISCO_SOBREPESO
    }
    if (ZscoreFinal > Percentil.sd2pos && ZscoreFinal <= Percentil.sd3pos) {
      return EstadoNutricional.SOBREPESO.WARNING
    }
    if (ZscoreFinal >= Percentil.sd3pos) {
      return EstadoNutricional.OBESIDADE.DANGER
    }
  } else {
    if (ZscoreFinal >= Percentil.sd2neg && ZscoreFinal < Percentil.sd1pos) {
      return EstadoNutricional.EUTROFICO
    }
    if (ZscoreFinal >= Percentil.sd1pos && ZscoreFinal < Percentil.sd2pos) {
      return EstadoNutricional.SOBREPESO.WARNING
    }
    if (ZscoreFinal >= Percentil.sd2pos && ZscoreFinal <= Percentil.sd3pos) {
      return EstadoNutricional.OBESIDADE.WARNING
    }
    if (ZscoreFinal >= Percentil.sd3pos) {
      return EstadoNutricional.OBESIDADE_GRAVE
    }
  }
}

function estadoNutricionalGestante(imc: number, idadeGestacional: number) {
  return idadeGestacional >= 6 ? getEstadoNutricionalGestante(imc, idadeGestacional) : estadoNutricionalAdulto(imc)
}
