import { StatusPedidoEnvioEnum, StatusUnificacaoBaseEnum } from 'graphql/types.generated'

type Status = StatusUnificacaoBaseEnum | StatusPedidoEnvioEnum

export const aguardandoAprovacaoList: Status[] = [
  StatusUnificacaoBaseEnum.AGUARDANDO_AVALIACAO,
  StatusPedidoEnvioEnum.AGUARDANDO_APROVACAO,
]

export const aguardandoUnificacaoList: Status[] = [
  StatusPedidoEnvioEnum.APROVADO,
  StatusUnificacaoBaseEnum.AGUARDANDO_ENVIO_BASE,
]

export const unificandoBaseList: Status[] = [
  StatusUnificacaoBaseEnum.UNIFICANDO_BASES,
  StatusUnificacaoBaseEnum.AGUARDANDO_FINALIZACAO,
  StatusPedidoEnvioEnum.ENVIANDO_PROFISSIONAIS,
  StatusPedidoEnvioEnum.ENVIANDO_CIDADAOS,
  StatusPedidoEnvioEnum.ENVIANDO_CDS,
  StatusPedidoEnvioEnum.FINALIZANDO_UNIFICACAO_BASE,
]

export const baseUnificadaList: Status[] = [
  StatusUnificacaoBaseEnum.BASE_UNIFICADA,
  StatusPedidoEnvioEnum.ENVIO_COMPLETO,
]

export const solicitacaoCanceladaList: Status[] = [StatusUnificacaoBaseEnum.CANCELADA, StatusPedidoEnvioEnum.CANCELADO]

export const unificacaoNegadaList: Status[] = [StatusUnificacaoBaseEnum.REPROVADA, StatusPedidoEnvioEnum.RECUSADO]

export const erroVersaoList: Status[] = [StatusUnificacaoBaseEnum.ERRO_VERSAO, StatusPedidoEnvioEnum.ERRO_VERSAO]

export const erroUnificacaoList: Status[] = [
  StatusUnificacaoBaseEnum.ERRO,
  StatusPedidoEnvioEnum.ERRO_CIDADAOS,
  StatusPedidoEnvioEnum.ERRO_PROFISSIONAIS,
  StatusPedidoEnvioEnum.ERRO_FINALIZACAO,
]

export const unificacaoAtivaList: Status[] = [
  StatusUnificacaoBaseEnum.AGUARDANDO_AVALIACAO,
  StatusUnificacaoBaseEnum.AGUARDANDO_ENVIO_BASE,
  StatusUnificacaoBaseEnum.UNIFICANDO_BASES,
  StatusUnificacaoBaseEnum.ERRO,
]

export const unificacaoAtivaAgendaOnlineList: Status[] = [
  StatusUnificacaoBaseEnum.AGUARDANDO_ENVIO_BASE,
  StatusUnificacaoBaseEnum.UNIFICANDO_BASES,
  StatusUnificacaoBaseEnum.ERRO,
]

export const statusCancelaveis: Status[] = [StatusPedidoEnvioEnum.AGUARDANDO_APROVACAO, StatusPedidoEnvioEnum.APROVADO]
