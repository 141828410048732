/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid } from 'bold-ui'
import { FormSectionAccordion } from 'components/accordion/FormSectionAccordion'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacao } from 'components/auth/useAcessoLotacao'
import {
  DateField,
  ErrorField,
  HorarioAgendaSelectField,
  HorarioAgendaSelectFieldModel,
  TextAreaField,
} from 'components/form'
import { resolveValue } from 'components/form/final-form/useField'
import { addMonths, parseISO } from 'date-fns'
import { useServerTime } from 'hooks/useServerTime'
import { useFormState } from 'react-final-form'
import Permissions from 'types/Permissions'
import { isDataAgendamentoInvalida } from 'util/atendimento/isDataAgendamentoInvalida'
import { MetaPath } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'
import { LotacaoSelectField, LotacaoSelectFieldModel } from 'view/profissional/lotacao/LotacaoSelectField'

export interface AgendarConsultaPanelModel {
  openedAccordions?: (string | number)[]
  lotacao?: LotacaoSelectFieldModel
  data?: LocalDate
  horario?: HorarioAgendaSelectFieldModel
  observacoes?: string
}

export interface AgendarConsultaPanelProps {
  name: MetaPath<AgendarConsultaPanelModel>
  hasPainelLateral?: boolean
}

export function AgendarConsultaPanel(props: AgendarConsultaPanelProps) {
  const { name, hasPainelLateral = true } = props
  const { unidadeSaude } = useAcessoLotacao()
  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow()

  const { values: formValues } = useFormState({ subscription: { values: true } })

  const values = resolveValue(formValues, name) || {}

  const dataAgendamentoEhInvalida = isDataAgendamentoInvalida(values?.data, serverTime)
  const lotacaoPreenchida = !isEmpty(values.lotacao)

  return (
    <CheckPermission permission={Permissions.visualizarAgenda.agendar}>
      <FormSectionAccordion name={name.openedAccordions} title='Agendar consulta' uuid='agendarConsulta'>
        <Grid
          style={css`
            padding: 1rem;
          `}
        >
          <Cell md={6} lg={hasPainelLateral ? 6 : 4}>
            <LotacaoSelectField
              name={name.lotacao}
              label='Profissional'
              unidadeSaudeId={unidadeSaude.id}
              loadItemsOnOpen={false}
              possuiAgendaCadastrada
            />
          </Cell>
          <Cell md={3} lg={hasPainelLateral ? 3 : 2}>
            <DateField
              name={name.data}
              label='Data do agendamento'
              required={lotacaoPreenchida}
              disabled={!lotacaoPreenchida}
              minDate={serverTime}
              maxDate={addMonths(serverTime, 6)}
            />
          </Cell>
          <Cell md={3} lg={hasPainelLateral ? 3 : 2}>
            <HorarioAgendaSelectField
              name={name.horario}
              lotacaoId={values?.lotacao?.id}
              disabled={dataAgendamentoEhInvalida}
              required={!dataAgendamentoEhInvalida}
              labels={{ inicial: 'Horário do agendamento' }}
              dia={values.data ? parseISO(values.data) : undefined}
            />
          </Cell>
          <Cell size={12}>
            <TextAreaField label='Observações' name={name.observacoes} maxLength={200} />
          </Cell>
          <Cell size={4} alignSelf='flex-end'>
            <ErrorField name={name} type='alert' ignoreObjectError />
          </Cell>
        </Grid>
      </FormSectionAccordion>
    </CheckPermission>
  )
}
