import { Cell, Grid, HFlow, Icon } from 'bold-ui'
import { CheckboxField, Form, FormDebouncedValueSpy, FormRenderProps, TextField } from 'components/form'
import { PopperButton, PopperControls } from 'components/popper'
import { FormState } from 'final-form'
import createDecorator from 'final-form-calculate'
import React, { useMemo } from 'react'

import { AgendamentosCidadaoFilterModel, metaAgendamentoCidadaoFilter } from '../model'
import { AgendamentosCidadaoFilterPopper } from './AgendamentosCidadaoFilterPopper'
import { calculatorFiltroAgenda } from './calculator'

interface AgendamentosCidadaoFilterProps {
  today: Date
  filter: AgendamentosCidadaoFilterModel
  onChange(values: AgendamentosCidadaoFilterModel): void
}

export function AgendamentosCidadaoFilter(props: AgendamentosCidadaoFilterProps) {
  const { filter, today, onChange } = props

  const todayEpoch = Number(today)
  const decorator = useMemo(() => [createDecorator(...calculatorFiltroAgenda(new Date(todayEpoch)))], [todayEpoch])

  const handleChange = ({ values }: FormState<AgendamentosCidadaoFilterModel>) => {
    onChange(values)
  }

  const renderForm = ({ handleSubmit }: FormRenderProps<AgendamentosCidadaoFilterModel>) => {
    const renderPopper = (ctrl: PopperControls) => (
      <AgendamentosCidadaoFilterPopper onSubmit={handleSubmit} filter={filter} {...ctrl} />
    )
    return (
      <>
        <FormDebouncedValueSpy onChange={handleChange} />
        <Grid alignItems='flex-start'>
          <Cell size={4}>
            <TextField
              name={metaAgendamentoCidadaoFilter.nomeProfissional}
              placeholder='Pesquise por profissional'
              icon='zoomOutline'
            />
          </Cell>
          <Cell>
            <CheckboxField
              name={metaAgendamentoCidadaoFilter.isAgendamentosAnteriores}
              label='Ver agendamentos anteriores'
            />
          </Cell>
          <Cell>
            <CheckboxField name={metaAgendamentoCidadaoFilter.isSomenteMeus} label='Ver somente meus agendamentos' />
          </Cell>
        </Grid>
        <PopperButton kind='primary' placement='bottom-end' size='small' renderPopper={renderPopper}>
          <Icon icon='filterOutline' />
          Filtro
        </PopperButton>
      </>
    )
  }
  return (
    <HFlow alignItems='center' justifyContent='space-between' style={{ gridTemplateColumns: '1fr' }}>
      <Form<AgendamentosCidadaoFilterModel> render={renderForm} decorators={decorator} onSubmit={onChange} />
    </HFlow>
  )
}
