import { TipoGravidezSelectFieldModel } from 'components/form/field/select/TipoGravidezSelectField'
import { SimNaoEnum } from 'components/form/field/SimNaoRadioGroupField'
import { ConfirmConfig } from 'components/modals/confirm'
import { CidPrimeiroAtendimentoPreNatalQuery, TipoEdemaEnum } from 'graphql/types.generated'
import { MetaPath } from 'util/metaPath'

import { HistoricoPreNatalModel, MedicoesPreNatal } from '../../components/modals/types/HistoricoPreNatalModel'
import { ProblemaCondicaoModel } from '../avaliacao/components/problemas-condicoes/model'
import { cidCiapString } from '../avaliacao/components/problemas-condicoes/utils/messages'

export const CIAP_W78_CODIGO = 'W78'

export const CID_Z34_CODIGO = 'Z34'

export const FAMILIA_CID_Z34_CODIGO: Set<string> = new Set([CID_Z34_CODIGO, 'Z340', 'Z348', 'Z349'])

export const CIAPS_PUERPERIO_CODIGOS: Set<string> = new Set(['W90', 'W91', 'W92', 'W93'])

export const CIAPS_ENCERRAMENTO_GESTACAO: Set<string> = new Set(['W82', 'W83', ...CIAPS_PUERPERIO_CODIGOS])

export const CIDS_ENCERRAMENTO_GESTACAO: Set<string> = new Set([
  'O02',
  'O03',
  'O05',
  'O06',
  'O04',
  'Z303',
  'O80',
  'Z370',
  'Z379',
  'Z38',
  'Z39',
  'Z371',
  'O42',
  'O45',
  'O60',
  'O61',
  'O62',
  'O63',
  'O64',
  'O65',
  'O66',
  'O67',
  'O68',
  'O69',
  'O70',
  'O71',
  'O73',
  'O750',
  'O751',
  'O754',
  'O755',
  'O756',
  'O757',
  'O758',
  'O759',
  'O81',
  'O82',
  'O83',
  'O84',
  'Z372',
  'Z373',
  'Z374',
  'Z375',
  'Z376',
  'Z377',
])

export const CIAP_GRAVIDEZ_ALTO_RISCO = new Set(['W71', 'W72', 'W73', 'W75', 'W76', 'W79', 'W80', 'W81', 'W84', 'W85'])

export const CID_GRAVIDEZ_ALTO_RISCO = new Set([
  'Z35',
  'Z350',
  'Z351',
  'Z352',
  'Z353',
  'Z354',
  'Z355',
  'Z356',
  'Z357',
  'Z358',
  'Z359',
])

export const CIAPS_GESTACAO_CODIGOS: Set<String> = new Set([...CIAP_GRAVIDEZ_ALTO_RISCO, CIAP_W78_CODIGO])

export const CIDS_GESTACAO_CODIGOS: Set<String> = new Set([...FAMILIA_CID_Z34_CODIGO, ...CID_GRAVIDEZ_ALTO_RISCO])

export const IDADE_GESTACIONAL_MAXIMA_EM_DIAS = 336
export const LIMITE_DIFERENCA_ENTRE_DUM_DATA_ATENDIMENTO_EM_DIAS = 336
export const QUANTIDADE_DIAS_FIM_PRIMEIRO_TRIMESTRE_GESTACAO = 97

export interface PreNatalFormModel {
  tipoGravidez: TipoGravidezSelectFieldModel
  alturaUterina: number
  edema: TipoEdemaEnum
  movimentacaoFetal: SimNaoEnum
  gravidezPlanejada: SimNaoEnum
  batimentoCardiacoFetal: number
}

export interface EncerrarGestacaoFormModel {
  tipoGravidez: TipoGravidezSelectFieldModel
  dataDesfecho: string
}

export enum TipoPreNatal {
  PRIMEIRO_ATENDIMENTO_PRE_NATAL,
  CONTINUACAO_PRE_NATAL,
  ENCERRAMENTO_GESTACAO,
  W78_RESOLVIDO_NA_AVALIACAO,
}

export interface MetasPreNatal {
  metaPreNatal: MetaPath<PreNatalFormModel>
  metaDum: MetaPath<string>
  metaEncerrarGestacao: MetaPath<EncerrarGestacaoFormModel>
}

export interface PreNatalCardContentModel extends HistoricoPreNatalModel {
  quantidadeConsultas: number
  dataUltimaConsulta: LocalDate
}

export const modalMessageDiscardPreNatalData = (
  problemaPreNatal: ProblemaCondicaoModel
): Pick<ConfirmConfig, 'title' | 'body'> => ({
  title: 'Deseja descartar os dados de Pré-natal?',
  body: `Os campos de Pré-natal serão desabilitados, os dados contidos nos mesmos serão perdidos e a ${cidCiapString(
    problemaPreNatal
  )} será excluída da Avaliação.`,
})

export const MEDICOES_PRE_NATAL: (keyof MedicoesPreNatal)[] = [
  'movimentacaoFetal',
  'edema',
  'batimentoCardiacoFetal',
  'alturaUterina',
]

export type CidPreNatalModel = CidPrimeiroAtendimentoPreNatalQuery['cidPrimeiroAtendimentoPreNatal']
