import { formatNumberRemovingDots, getNumberPrecision } from 'util/number'

import { ValidationMessages } from './index'

export const messages: ValidationMessages = {
  arrayMaxLength: (value, ...args) => `Deve possuir no máximo ${args[0]} itens.`,
  beforeEqual130Years: (value, ...args) => `Deve ser anterior ou igual a 130 anos.`,
  beforeEqualToday: (value, ...args) => `Deve ser anterior ou igual à data atual.`,
  afterEqualToday: (value, ...args) => `Deve ser posterior ou igual à data atual.`,
  beforeEqualTo: (value, ...args) => `Deve ser anterior ou igual à ${args[0]}.`,
  afterEqualTo: (value, ...args) => `Deve ser posterior ou igual à ${args[0]}.`,
  beforeTo: (value, ...args) => `Deve ser anterior à ${args[0]}.`,
  afterTo: (value, ...args) => `Deve ser posterior à ${args[0]}.`,
  afterBirthdate: (value, ...args) => `Deve ser posterior à data de nascimento.`,
  empty: (value, ...args) => `Não deve ser preenchido.`,
  invalid: (value, ...args) => `Campo inválido.`,
  length: (value, ...args) => `Deve possuir ${args[0]} caracteres.`,
  minLength: (value, ...args) => `Deve possuir ao menos ${args[0]} caracteres.`,
  maxLength: (value, ...args) => `Deve possuir no máximo ${args[0]} caracteres.`,
  match: (value, ...args) => `Um ou mais caracteres informados não são permitidos para esse campo.`,
  microarea: (value, ...args) => 'Deve ter valor entre 01 e 99.',
  nome: (value, ...args) => `Informe nome e sobrenome.`,
  simpleName: (value, ...args) => `Deve conter apenas letras (acentuadas ou não).`,
  nomeOneLetter: (value, ...args) => `Nome não pode ter somente uma letra.`,
  nomeTwoLetters: (value, ...args) => `Nome e sobrenome não podem ter apenas duas letras.`,
  range: (value, ...args) =>
    `Deve ter valor entre ${formatNumberToValidationMessage(args[0])} e ${formatNumberToValidationMessage(args[1])}.`,
  minRange: (value, ...args) => `Deve ser maior ou igual a ${formatNumberToValidationMessage(args[0])}.`,
  maxRange: (value, ...args) => `Deve ser menor ou igual a ${formatNumberToValidationMessage(args[0])}.`,
  required: (value, ...args) => `Preenchimento obrigatório.`,
  senha: (value, ...args) => `Deve possuir ao menos uma letra e um número.`,
  oneRequired: (value, ...args) => `Pelo menos um dos campos é de preenchimento obrigatório.`,
  nonWhitespaces: (value, ...args) => `Não deve conter espaços.`,
}

const formatNumberToValidationMessage = (value: number): string => {
  const precision = getNumberPrecision(value)
  return formatNumberRemovingDots(value, precision)
}
