import { Button, Cell, Grid, Heading, Icon, Tag, Tooltip, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { ProcedimentoSelectField, ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import { Form, FormRenderProps } from 'components/form/final-form/Form'
import { getFieldError } from 'components/form/final-form/util'
import { HLabel } from 'components/HLabel'
import { FormApi } from 'final-form'
import { GrupoProcedimentoEnum, TipoProcedimentoEnum } from 'graphql/types.generated'
import React, { useEffect, useMemo, useState } from 'react'
import metaPath, { MetaArray } from 'util/metaPath/metaPath'
import { createValidator, maxLength } from 'util/validation'
import { isEmpty } from 'util/validation/Util'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { EditableList, EditableRow, useEditableListField } from '../../../components/EditableList'
import { Observacao } from '../../../components/Observacao'
import { ObservacaoFormGrid } from '../../../components/ObservacaoFormGrid'
import { showCodigoProcedimento } from '../../objetivo/resultados-exames/util'
import { hasMudancaProcedimentosAutomaticos } from '../utils/planoUtils'

export interface ProcedimentoPlanoModel {
  _id: ID
  procedimento: ProcedimentoSelectModel
  observacao?: string
  automatico?: boolean
}

const meta = metaPath<ProcedimentoPlanoModel>()

export interface ProcedimentoPlanoProps {
  name: MetaArray<ProcedimentoPlanoModel>
  cidadao?: CidadaoAtendimento
  filtrarProcedimentosOdonto?: boolean
  dataAtendimento: LocalDate
  onAutomaticChange?(): void
}

const validator = createValidator<ProcedimentoPlanoModel>({
  observacao: [maxLength(200)],
})

const path = metaPath<ProcedimentoPlanoModel>()

export function ProcedimentoPlanoField(props: ProcedimentoPlanoProps) {
  const { name, cidadao, filtrarProcedimentosOdonto, dataAtendimento, onAutomaticChange } = props
  const { data } = useSession()

  const {
    handleRowChanged,
    handleSubmit,
    meta,
    input: { value },
    removeItem,
  } = useEditableListField({ name })

  const listaProcedimentos = value || []
  const [listaProcedimentosAnterior, setListaProcedimentosAnterior] = useState(listaProcedimentos)

  const hasMudancaProcedAutomaticos = useMemo(
    () => hasMudancaProcedimentosAutomaticos(listaProcedimentos, listaProcedimentosAnterior),
    [listaProcedimentos, listaProcedimentosAnterior]
  )

  useEffect(() => {
    if (hasMudancaProcedAutomaticos) {
      onAutomaticChange()
      setListaProcedimentosAnterior(listaProcedimentos)
    }
  }, [listaProcedimentos, hasMudancaProcedAutomaticos, onAutomaticChange])

  const procedimentoIsEqual = (procedimento: ProcedimentoSelectModel) => {
    return (value || []).find((o2) => procedimento.id === o2.procedimento.id)
  }

  const renderForm = (formProps: FormRenderProps<ProcedimentoPlanoModel>) => {
    const handleChange = (newValue: ProcedimentoSelectModel) => {
      newValue && !procedimentoIsEqual(newValue) && formProps.form.submit()
    }

    return (
      <ProcedimentoSelectField
        label='SIGTAP'
        onChange={handleChange}
        name={path.procedimento}
        itemIsEqual={procedimentoIsEqual}
        filtroPadrao={true}
        tipoProcedimento={TipoProcedimentoEnum.CLINICO}
        gruposProcedimento={[
          GrupoProcedimentoEnum.ACOES_PROMOCAO_PREVENCAO_SAUDE,
          GrupoProcedimentoEnum.FINALIDADE_DIAGNOSTICA,
          GrupoProcedimentoEnum.CLINICOS,
          GrupoProcedimentoEnum.CIRURGICO,
        ]}
        sexo={isEmpty(cidadao?.identidadeGeneroDbEnum) ? cidadao?.sexo : undefined}
        dataNascimento={cidadao?.dataNascimento}
        dataAtendimento={dataAtendimento}
        cbo={data.acesso.__typename === 'Lotacao' && data.acesso.cbo.id}
        filtrarProcedimentosOdonto={filtrarProcedimentosOdonto}
        loadItemsOnOpen={false}
        error={getFieldError(meta)}
      />
    )
  }

  return (
    <Grid>
      <Cell size={8}>
        <Form<ProcedimentoPlanoModel> render={renderForm} validate={validator} onSubmit={handleSubmit} />
      </Cell>
      {value?.length > 0 && (
        <Cell size={12}>
          <EditableList>
            {value.map((item) => (
              <ProcedimentoRow key={item._id} model={item} onChange={handleRowChanged} onRemove={removeItem} />
            ))}
          </EditableList>
        </Cell>
      )}
    </Grid>
  )
}

interface ProcedimentoRowProps {
  model: ProcedimentoPlanoModel

  onChange(values: ProcedimentoPlanoModel): void

  onRemove(itemToRemove: ProcedimentoPlanoModel): void
}

function ProcedimentoRow(props: ProcedimentoRowProps) {
  const { model, onChange, onRemove } = props
  const [editing, setEditing] = useState(false)

  const toggleEditing = () => setEditing((oldState) => !oldState)

  const handleRemove = () => onRemove(model)

  const handleSubmit = (value: ProcedimentoPlanoModel, formApi: FormApi) => {
    onChange(value)
    setTimeout(formApi.reset)
    toggleEditing()
  }

  const renderForm = (formRenderProps: FormRenderProps<ProcedimentoPlanoModel>) => (
    <VFlow vSpacing={0.5} style={{ padding: '1rem 0' }}>
      <Heading level={4}>Adicionar observação</Heading>
      <HLabel title='SIGTAP:'>{model.procedimento.descricao}</HLabel>
      <ObservacaoFormGrid name={meta.observacao} onCancel={toggleEditing} onSubmit={formRenderProps.handleSubmit} />
    </VFlow>
  )

  return (
    <EditableRow editing={editing}>
      {!editing && (
        <>
          <Grid style={{ margin: 0 }}>
            <Cell size={9} alignSelf='center'>
              {`${model.procedimento.descricao} - ${showCodigoProcedimento(model.procedimento.codigo)}`}
              {model.automatico && <Tag style={{ marginLeft: '0.5rem' }}>Adicionado automaticamente</Tag>}
            </Cell>
            <Cell size={3} alignSelf='center' style={{ padding: '0.25rem 0', textAlign: 'right' }}>
              <Tooltip text={model.observacao ? 'Editar observação' : 'Adicionar observação'}>
                <Button size='small' skin='ghost' onClick={toggleEditing}>
                  <Icon icon='chatOutline' />
                </Button>
              </Tooltip>
              <Tooltip text='Excluir'>
                <Button size='small' skin='ghost' onClick={handleRemove}>
                  <Icon icon='trashOutline' />
                </Button>
              </Tooltip>
            </Cell>
          </Grid>
          <Observacao observacao={model.observacao} />
        </>
      )}
      {editing && (
        <Form<ProcedimentoPlanoModel>
          render={renderForm}
          initialValues={model}
          onSubmit={handleSubmit}
          validate={validator}
          initialValuesEqual={(oldInitial, newInitial) => oldInitial._id === newInitial._id}
        />
      )}
    </EditableRow>
  )
}
