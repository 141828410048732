import { Icon, Theme, useStyles } from 'bold-ui'
import { css } from 'emotion'
import React, { CSSProperties, useEffect, useState } from 'react'

interface CorouselProps {
  children: any[]
}

export const Carousel = (props: CorouselProps) => {
  const [currentItem, setCurrentItem] = useState(0)

  const handleRightClick = () => {
    const nextItem = currentItem + 1 > props.children.length - 1 ? 0 : currentItem + 1
    setCurrentItem(nextItem)
  }

  const handleLeftClick = () => {
    const nextItem = currentItem - 1 < 0 ? props.children.length - 1 : currentItem - 1
    setCurrentItem(nextItem)
  }

  const { classes } = useStyles(createStyles)

  return (
    <div className={classes.carousel}>
      <span className={classes.arrow}>
        <Icon icon='angleLeft' onClick={handleLeftClick} />
      </span>
      <CarouselContent current={currentItem} children={props.children} />
      <span className={css(classes.arrow, classes.arrowRight)}>
        <Icon icon='angleRight' onClick={handleRightClick} />
      </span>
    </div>
  )
}

interface CarouselContentProps {
  current: number
  children: any[]
}

const CarouselContent = (props: CarouselContentProps) => {
  const { children, current } = props
  const [localCurrent, setLocalCurrent] = useState(null)
  const { classes } = useStyles(createStyles)

  useEffect(() => {
    if (current !== localCurrent) {
      setLocalCurrent(current)
    }
  }, [current, localCurrent])

  return (
    <div className={classes.carouselContent}>
      <div
        className={css(
          classes.carouselImg,
          current !== localCurrent ? classes.inactiveChildren : classes.activeChildren
        )}
      >
        {localCurrent !== null && children[localCurrent]}
      </div>
      <div>
        {children.map((value, index) => (
          <svg key={`carousel-index-${index}`} width={16} height={16}>
            <circle cx={8} cy={8} r={4} className={index === current ? classes.activeIndex : classes.index} />
          </svg>
        ))}
      </div>
    </div>
  )
}

interface CarouselItemProps {
  image: string
  alt: string
}

export const CarouselItem = (props: CarouselItemProps) => {
  const { classes } = useStyles(createStyles)
  return (
    <div>
      <img src={props.image} alt={props.alt} className={classes.img} />
    </div>
  )
}

export const createStyles = (theme: Theme) => ({
  carousel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '1rem',
    marginBottom: '1rem',
    maxWidth: '100%',
  } as CSSProperties,
  carouselContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '100%',
  } as CSSProperties,
  carouselImg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '767px',
    height: '500px',
    maxWidth: '100%',
    padding: '0 20px 0 20px',
  } as CSSProperties,
  img: {
    maxWidth: '100%',
    maxHeight: '500px',
    border: 'solid 1px',
    opacity: 1,
    transition: 'opacity .75s',
    imageRendering: '-webkit-optimize-contrast',
  } as CSSProperties,
  index: {
    fill: theme.pallete.gray.c70,
  } as CSSProperties,
  activeIndex: {
    fill: theme.pallete.gray.c40,
  } as CSSProperties,
  activeChildren: {
    opacity: 1,
    transition: 'opacity .75s',
  } as CSSProperties,
  inactiveChildren: {
    opacity: 0,
  } as CSSProperties,
  arrow: {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    background: theme.pallete.gray.c40,
    color: theme.pallete.gray.c100,
    ':hover': {
      cursor: 'pointer',
    },
  } as CSSProperties,
  arrowRight: {
    svg: { marginLeft: '2px' },
  } as CSSProperties,
})
