import { green } from 'bold-ui/lib/styles/colors'
import React from 'react'
import { MetaPath } from 'util/metaPath'
import { AtendimentoProfissional } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { SoapSection } from '../SoapSection'
import { PlanoForm, PlanoFormModel } from './PlanoForm'

export interface PlanoSectionProps {
  cidadao: CidadaoAtendimento
  prontuarioId: ID
  name: MetaPath<PlanoFormModel>
  isAtendimentoOdonto: boolean
  isAtendimentoProcedimentos: boolean
  atendimentoId: ID
  dataAtendimento: Instant
  atendimentoProfissional: AtendimentoProfissional
  registroTardio: boolean
}

export function PlanoSection(props: PlanoSectionProps) {
  return (
    <SoapSection initial='P' title='Plano' color={green}>
      <PlanoForm {...props} />
    </SoapSection>
  )
}
