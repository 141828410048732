/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DropdownButton } from 'components/dropdown'
import { confirm } from 'components/modals/confirm'
import { ButtonLink } from 'components/route'
import { useHistory, useRouteMatch } from 'react-router'

import { EncaminhamentoExternoModel } from '../model'
import { EncaminhamentoExternoRowModel } from './EncaminhamentoExternoTable'

interface EncaminhamentoExternoTableButtonsProps {
  encaminhamento: EncaminhamentoExternoRowModel
  handleImpressao(values: EncaminhamentoExternoRowModel): void
  removeItem(itemToRemove: EncaminhamentoExternoModel): void
  readOnly: boolean
}

export function EncaminhamentoExternoTableButtons(props: EncaminhamentoExternoTableButtonsProps) {
  const { encaminhamento, handleImpressao, removeItem, readOnly } = props
  const alert = useAlert()

  const history = useHistory()
  const match = useRouteMatch()

  const handleExcluir = () => {
    return confirm({
      title: 'Deseja excluir o encaminhamento?',
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        removeItem({ _id: encaminhamento.id } as EncaminhamentoExternoModel)
        alert('success', 'Encaminhamento excluído com sucesso')
      },
    })()
  }

  const handleEditModal = () => {
    history.push(match.url + `/encaminhamentos-externos/editar/${encaminhamento.id}`)
  }

  return (
    <HFlow justifyContent='flex-end' hSpacing={0}>
      <Tooltip text='Visualizar'>
        <ButtonLink
          size='small'
          skin='ghost'
          to={`${match.url}/encaminhamentos-externos/visualizar/${
            encaminhamento.isRegistradoAgora
              ? `registrado-agora/${encaminhamento.id}`
              : `registro-anterior/${encaminhamento.id}`
          }`}
        >
          <Icon icon='zoomOutline' />
        </ButtonLink>
      </Tooltip>
      <Tooltip text='Imprimir'>
        <Button size='small' skin='ghost' onClick={() => handleImpressao(encaminhamento)}>
          <Icon icon='printerOutline' />
        </Button>
      </Tooltip>
      {!readOnly && (
        <DropdownButton>
          <Tooltip text={!encaminhamento.isRegistradoAgora && 'Não é possível editar encaminhamentos passados'}>
            <DropdownItem
              disabled={!encaminhamento.isRegistradoAgora}
              onClick={handleEditModal}
              style={classes.editarButton}
            >
              Editar
            </DropdownItem>
          </Tooltip>
          <Tooltip text={!encaminhamento.isRegistradoAgora && 'Não é possível excluir encaminhamentos passados'}>
            <DropdownItem onClick={handleExcluir} type='danger' disabled={!encaminhamento.isRegistradoAgora}>
              <HFlow alignItems='center' hSpacing={0.5}>
                <Icon icon='trashOutline' />
                Excluir
              </HFlow>
            </DropdownItem>
          </Tooltip>
        </DropdownButton>
      )}
    </HFlow>
  )
}

const classes = {
  editarButton: {
    height: '2rem',
  },
}
