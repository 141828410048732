import { Breadcrumb } from 'components/breadcrumb'
import { PrivateRoute } from 'components/route'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'
import { useRouteMatch } from 'react-router'
import { Switch } from 'react-router-dom'
import Permissions from 'types/Permissions'
import { grupoCboAtendimento } from 'view/cidadao/acessos'

import { AtendimentoView } from '../atendimentos/detail/AtendimentoView'
import { EditarRegistroTardioModalForm } from './components/modal/EditarRegistroTardioModalForm'
import ListaRegistroTardioView from './ListaRegistroTardioView'
import RegistroTardioEsusView from './RegistroTardioEsusView'

export const REGISTRO_TARDIO_PATH = '/registroTardio'
export const REGISTRO_TARDIO_NEXT_PATH = '/registroTardioNext'

export function RegistroTardioRootView() {
  const match = useRouteMatch()
  const { LISTA_REGISTRO_TARDIO_NOVA_ENABLED } = useFlags()

  return (
    <>
      <Breadcrumb title='Registro tardio de atendimento' />
      <Switch>
        <PrivateRoute
          path={`${match.url}/editar/:atendimentoId`}
          component={EditarRegistroTardioModalForm}
          permission={Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir}
        />
        <PrivateRoute
          path={`${match.url}/:atendimentoId`}
          component={AtendimentoView}
          permission={Permissions.visualizarListaDeAtendimento.registrarAtendimento}
          cbos={grupoCboAtendimento}
        />
        {LISTA_REGISTRO_TARDIO_NOVA_ENABLED && (
          <PrivateRoute
            path={REGISTRO_TARDIO_NEXT_PATH}
            component={ListaRegistroTardioView}
            permission={Permissions.visualizarListaDeAtendimento}
          />
        )}
        <PrivateRoute
          path={REGISTRO_TARDIO_PATH}
          component={RegistroTardioEsusView}
          permission={Permissions.visualizarListaDeAtendimento}
        />
      </Switch>
    </>
  )
}

export const urlFromRegistroTardio = (url: string): boolean =>
  url.includes(REGISTRO_TARDIO_PATH) || url.includes(REGISTRO_TARDIO_NEXT_PATH)
