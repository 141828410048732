import { Theme } from 'bold-ui'
import { orange } from 'bold-ui/lib/styles/colors'
import { CSSProperties } from 'react'

export const createItemStyles = (theme: Theme) => ({
  hFlow: {
    alignItems: 'center',
    gridAutoColumns: '9.5rem auto',
  } as CSSProperties,
  nomeCidadao: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    lineHeight: '1.5',
    fontFamily: theme.typography.fontFamily,
  } as CSSProperties,
  idadeCidadao: {
    fontSize: '13px',
    lineHeight: '1.5',
    fontFamily: theme.typography.fontFamily,
    color: theme.pallete.gray.c20,
  } as CSSProperties,
  profissionalNome: {
    fontWeight: 'bold',
  } as CSSProperties,

  naoFinalizadoTag: {
    backgroundColor: orange.c90,
    color: orange.c40,
  },
})

export const createFilterStyles = (theme: Theme) => ({
  hFlow: {
    gridTemplateColumns: '1fr',
  } as CSSProperties,
  cell1: {
    [theme.breakpoints.down('lg')]: {
      width: '39.666%',
    },
  },
  cell2: {
    [theme.breakpoints.down('lg')]: {
      width: '60.333%',
    },
  },
})
