/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Icon } from 'bold-ui'
import { turquoise } from 'bold-ui/lib/styles/colors'
import { handleError } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { formatISO } from 'date-fns'
import { useAcompanhamentoPuericulturaCardQuery } from 'graphql/hooks.generated'
import { Fragment, useState } from 'react'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { PuericulturaModel } from '../../objetivo/puericultura/model'
import { ProntuarioCard } from '../ProntuarioCard'
import { AcompanhamentoPuericulturaCard } from './AcompanhamentoPuericulturaCard'
import { AcompanhamentoPuericulturaModal } from './AcompanhamentoPuericulturaModal'
import { convertAcompanhamentoPuericulturaToModel } from './convert'
import { downloadAcompanhamentoPuericultura } from './downloadAcompanhamentoPuericultura'

interface AcompanhamentoPuericulturaSectionProps {
  prontuarioId: ID
  atendimentoId?: ID
  vacinacaoEmDia?: boolean
  puericultura?: PuericulturaModel
  dataReferencia: Instant
  cidadao: Pick<CidadaoAtendimento, 'dataNascimento' | 'sexo'>
}

export function AcompanhamentoPuericulturaSection(props: AcompanhamentoPuericulturaSectionProps) {
  const { prontuarioId, vacinacaoEmDia, puericultura, dataReferencia: dataAtendimento, cidadao, atendimentoId } = props
  const [openModal, setOpenModal] = useState(false)

  const { data, loading } = useAcompanhamentoPuericulturaCardQuery({
    variables: { prontuarioId, dataReferencia: formatISO(dataAtendimento, { representation: 'date' }) },
  })

  const acompanhamentoPuericultura = !loading && convertAcompanhamentoPuericulturaToModel(data, cidadao.dataNascimento)

  if (!acompanhamentoPuericultura) return null

  return (
    <Fragment>
      <ProntuarioCard
        title='Acompanhamento da criança'
        titleBackgroundColor={turquoise.c90}
        onClick={() => setOpenModal(true)}
        tooltip='Ver acompanhamento da criança'
        content={
          <Button
            size='small'
            skin='ghost'
            style={css`
              padding: 0rem 0.5rem;
            `}
            onClick={(event) => {
              event.stopPropagation()
              handlePrint(prontuarioId, atendimentoId)
            }}
          >
            <Icon icon='printerOutline' />
          </Button>
        }
      >
        <AcompanhamentoPuericulturaCard
          dataAtendimento={dataAtendimento}
          acompanhamentoPuericultura={acompanhamentoPuericultura}
          loading={loading}
          vacinacaoEmDiaAtendimento={vacinacaoEmDia}
          puericultura={puericultura}
          cidadao={cidadao}
        />
      </ProntuarioCard>

      {openModal && !loading && (
        <AcompanhamentoPuericulturaModal
          closeModal={() => setOpenModal(false)}
          vacinacaoEmDiaAtendimento={vacinacaoEmDia}
          atendimentoPuericultura={puericultura}
          acompanhamentoPuericultura={acompanhamentoPuericultura}
          dataAtendimento={dataAtendimento}
          cidadao={cidadao}
          prontuarioId={prontuarioId}
          atendimentoId={atendimentoId}
        />
      )}
    </Fragment>
  )
}

export const handlePrint = (prontuarioId: ID, atendimentoId?: ID) => {
  if (atendimentoId) {
    confirm({
      title: 'Imprimir acompanhamento?',
      body:
        'Apenas dados de atendimentos anteriores serão impressos no acompanhamento. Para a impressão dos dados do atendimento atual, imprima o atendimento ao finalizar.',
      cancelLabel: 'Cancelar',
      confirmLabel: 'Imprimir',
      onConfirm: () => downloadAcompanhamentoPuericultura({ prontuarioId, atendimentoId }).catch(handleError),
    })()
  } else {
    downloadAcompanhamentoPuericultura({ prontuarioId }).catch(handleError)
  }
}
