import { AcessoCbo } from 'graphql/types.generated'
import { useCallback } from 'react'
import { Permission } from 'types/Permissions'

import useSession from './useSession'
import { Authorization } from './useSessionUtils'

interface CboAndResourceAuthorization {
  hasAnyCbo: AcessoCbo[][]
  hasAnyPermission: Permission[]
}

interface ResourceAuthorization {
  permission: Authorization
}

type CheckPermissionProps = (ResourceAuthorization | CboAndResourceAuthorization) & { children: any }

const CheckPermission = (props: CheckPermissionProps) => {
  const { checkAuthorization } = useSession({ fetchPolicy: 'cache-only' })

  const hasAuthorization = useCallback(
    (props: CheckPermissionProps) => {
      if (isCboAndResourceAuthorization(props)) {
        return props.hasAnyPermission.some(checkAuthorization) && props.hasAnyCbo.some(checkAuthorization)
      } else {
        return checkAuthorization(props.permission)
      }
    },
    [checkAuthorization]
  )

  return hasAuthorization(props) ? props.children : null
}

export default CheckPermission

const isCboAndResourceAuthorization = (x: any): x is CboAndResourceAuthorization => x.hasAnyCbo
