/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Grid, HFlow, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { CheckboxField, DateField, Form, FormRenderProps, NumberField, SubmitButton, TimeField } from 'components/form'
import { CidSelectField } from 'components/form/field/select/CidSelectField/CidSelectField'
import { resolveValue } from 'components/form/final-form/useField'
import { confirm } from 'components/modals/confirm'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { TipoAtestadoEnum } from 'graphql/types.generated'
import moment from 'moment'
import { Fragment } from 'react'
import { metaPath } from 'util/metaPath'
import { beforeEqualToday, cns, cpf, createValidator, ErrorObject, hora, required } from 'util/validation'

import AtestadoModel, { CidadaoAtestado } from '../components/AtestadoModel'
import AtestadoPadraoTextBox, { getAtestadoPadraoText } from './AtestadoPadraoTextBox'

export const name = metaPath<AtestadoModel>()

export interface AtestadoPadraoFormProps {
  cidadao: CidadaoAtestado
  dataAtendimento: Instant
  onSubmit(values: AtestadoModel): void
}

export const cpfCnsDecorator = createDecorator({
  field: name.naoPossui.absolutePath(),
  updates: {
    [name.cpfCns.absolutePath()]: (value: boolean, allValues: AtestadoModel) => {
      if (value) return (allValues.cpfCns = '')
      else return allValues.cpfCns
    },
  },
})

export const validate = (possuiCpfCns: string, dataAtendimento: number) =>
  createValidator<AtestadoModel>(
    {
      data: [required, beforeEqualToday],
      hora: [required, hora],
      dias: [required],
    },
    (values: AtestadoModel, errors: ErrorObject<AtestadoModel>) => {
      if (!errors.dias && values.dias <= 0) {
        errors.dias = 'Deve ter valor entre 1 e 999.'
      }

      const minDate = moment(dataAtendimento).subtract(90, 'd').format('YYYY-MM-DD')
      if (!errors.data && moment(values.data).isBefore(minDate)) {
        errors.data = 'Limite de 90 dias anteriores à data do atendimento.'
      } else if (moment(values.data).isAfter(moment(dataAtendimento))) {
        errors.data = 'Deve ser anterior ou igual à data do atendimento.'
      }

      if (!errors.cpfCns && !values.naoPossui && !possuiCpfCns) {
        errors.cpfCns = required(values.cpfCns)

        const [lengthCpf, cpfRule] = cpf
        const [lengthCns, cnsRule] = cns

        if (values?.cpfCns) {
          if (lengthCpf && cpfRule(values.cpfCns) && lengthCns && cnsRule(values.cpfCns)) {
            errors.cpfCns = 'CPF/CNS inválido.'
          }
        }
      }

      return errors
    }
  )

export default function AtestadoPadraoForm(props: AtestadoPadraoFormProps) {
  const { cidadao, dataAtendimento, onSubmit } = props

  const { data } = useSession({ fetchPolicy: 'cache-only' })
  const nomeUnidadeSaude = data.acesso.__typename === 'Lotacao' && data.acesso.unidadeSaude.nome

  const possuiCpfCns = cidadao.cpf || cidadao.cns
  const dataAtendimentoFormatted = moment(dataAtendimento).format('YYYY-MM-DD')
  const horaAtendimentoFormatted = moment(dataAtendimento).format('HH:mm')

  const alert = useAlert()

  const handleSubmit = (values: AtestadoModel, formApi: FormApi) => {
    onSubmit({ ...values, tipo: TipoAtestadoEnum.PADRAO })
    setTimeout(formApi.reset)
    formApi.getRegisteredFields().forEach((item) => formApi.resetFieldState(item))
    alert('success', 'Atestado salvo com sucesso.')
  }

  const handleCancel = (formProps: FormRenderProps<AtestadoModel>) => {
    if (formProps.dirty) {
      confirm({
        title: 'Deseja cancelar a inclusão?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar inclusão',
        confirmLabel: 'Sim, cancelar inclusão',
        onConfirm: () => {
          alert('success', 'Cadastro de atestado cancelado com sucesso.')
          setTimeout(() => formProps.form.reset())
        },
      })()
    } else {
      alert('success', 'Cadastro de atestado cancelado com sucesso.')
    }
    formProps.form.getRegisteredFields().forEach((item) => formProps.form.resetFieldState(item))
  }

  const renderForm = (formProps: FormRenderProps<AtestadoModel>) => {
    const values = resolveValue(formProps.values, name) || ({} as AtestadoModel)
    values.descricao = getAtestadoPadraoText({ cidadao, values, nomeUnidadeSaude })

    return (
      <Fragment>
        {!possuiCpfCns && (
          <Cell size={8}>
            <Grid gap={1}>
              <Cell size={4}>
                <NumberField
                  name={name.cpfCns}
                  label='CPF/CNS'
                  disabled={values.naoPossui}
                  required={!values.naoPossui}
                  maxLength={15}
                />
              </Cell>
              <Cell>
                <HFlow
                  style={css`
                    margin-top: 1.75rem;
                    font-style: h5;
                  `}
                >
                  <CheckboxField name={name.naoPossui} label='Não possui' />
                </HFlow>
              </Cell>
            </Grid>
          </Cell>
        )}
        <Cell md={4} lg={3}>
          <DateField
            name={name.data.absolutePath()}
            label='Data'
            required
            initialValue={dataAtendimentoFormatted}
            minDate={moment(dataAtendimento).subtract(90, 'd').toDate()}
            maxDate={moment(dataAtendimento).toDate()}
          />
        </Cell>
        <Cell size={2}>
          <TimeField
            name={name.hora}
            label='Hora'
            placeholder='hh:mm'
            required
            initialValue={horaAtendimentoFormatted}
          />
        </Cell>
        <Cell size={2}>
          <NumberField name={name.dias} label='Dias' required maxLength={3} />
        </Cell>
        <Cell size={4}>
          <CidSelectField name={name.cid10} label='CID10' renderGrupoCiapRelacionada={false} />
        </Cell>
        <Cell size={12}>
          <VFlow>
            <AtestadoPadraoTextBox values={values} nomeUnidadeSaude={nomeUnidadeSaude} cidadao={cidadao} />
            <HFlow justifyContent='flex-end'>
              <Button size='small' onClick={() => handleCancel(formProps)}>
                Cancelar
              </Button>
              <SubmitButton
                throttleSubmit
                type='button'
                size='small'
                kind='primary'
                handleSubmit={formProps.handleSubmit}
              >
                Salvar atestado
              </SubmitButton>
            </HFlow>
          </VFlow>
        </Cell>
      </Fragment>
    )
  }

  return (
    <Form<AtestadoModel>
      render={renderForm}
      onSubmit={handleSubmit}
      validate={validate(possuiCpfCns, dataAtendimento)}
      decorators={[cpfCnsDecorator]}
    />
  )
}
