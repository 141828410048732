import { Cell, Grid, PagedTable, TabItem, Tabs } from 'bold-ui'
import { ChartMonthSelector, DateRange } from 'components/chart'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { PageParams } from 'graphql/types.generated'
import moment from 'moment'
import React, { useState } from 'react'
import { formatNumber } from 'util/number'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { PeriodoGestacaoModel } from '../types/PeriodoGestacaoModel'
import { GraficoPressaoArterial } from './graficos-sinais-vitais/GraficoPressaoArterial'
import { renderDataHoraMedicao, renderMedicao } from './renderMedicao'

enum ChartTab {
  PressaoArterial,
}

const MEDICOES_SINAIS_VITAIS: any[] = [
  'valorPressaoArterial',
  'valorFrequenciaRespiratoria',
  'valorFrequenciaCardiaca',
  'valorSaturacaoO2',
  'valorTemperatura',
]

export interface HistoricoMedicoesSinaisVitaisViewProps {
  medicoes: HistoricoMedicaoModel[]
  gestacoes: PeriodoGestacaoModel[]
}

export function HistoricoMedicoesSinaisVitaisView(props: HistoricoMedicoesSinaisVitaisViewProps) {
  const { medicoes, gestacoes } = props

  const medicoesComSinaisVitais = (medicoes ?? []).filter((med) => MEDICOES_SINAIS_VITAIS.find((e) => med[e]))

  const [tableState, setTableState] = useState<{ pageParams?: PageParams }>({ pageParams: { page: 0, size: 5 } })
  const [currentChartTab, setCurrentChartTab] = useState(ChartTab.PressaoArterial)
  const [dateRange, setDateRange] = useState<DateRange>({
    init: moment().startOf('month'),
    end: moment().endOf('month'),
  })

  const { page: currentPage, size: itensPerPage } = tableState.pageParams
  const totalElements = medicoesComSinaisVitais?.length ?? 0
  const totalPages = Math.ceil(totalElements / itensPerPage)
  const content = medicoesComSinaisVitais?.slice(currentPage * itensPerPage, (currentPage + 1) * itensPerPage) ?? []
  const medicoesPage = {
    content: content,
    pageInfo: {
      number: currentPage,
      size: itensPerPage,
      totalPages: totalPages,
      totalElements: medicoesComSinaisVitais.length,
      first: currentPage === 0,
      last: currentPage === totalPages - 1,
      numberOfElements: content.length,
    },
  }

  const tableProps = usePagedTableProps({
    loading: false,
    onChange: setTableState,
    result: medicoesPage,
  })

  return (
    <Grid>
      <Cell size={12}>
        <TableBox>
          <PagedTable
            {...tableProps}
            columns={[
              {
                header: 'Data da medição',
                name: 'dataMedicao',
                render: renderDataHoraMedicao,
              },
              {
                header: 'Pressão arterial (mmHg)',
                name: 'valorPressaoArterial',
                render: renderMedicao((med) => med.valorPressaoArterial),
              },
              {
                header: 'Freq. respiratória (mpm)',
                name: 'valorFrequenciaRespiratoria',
                render: renderMedicao((med) => med.valorFrequenciaRespiratoria),
              },
              {
                header: 'Freq. cardíaca (bpm)',
                name: 'valorFrequenciaCardiaca',
                render: renderMedicao((med) => med.valorFrequenciaCardiaca),
              },
              {
                header: 'Saturação de O2 (%)',
                name: 'valorSaturacaoO2',
                render: renderMedicao((med) => med.valorSaturacaoO2),
                style: { width: '8.5rem' },
              },
              {
                header: 'Temperatura (°C)',
                name: 'valorTemperatura',
                render: renderMedicao((med) => formatNumber(med.valorTemperatura, 1)),
              },
            ]}
          />
        </TableBox>
      </Cell>
      <Cell size={12}>
        <Tabs>
          <TabItem
            active={currentChartTab === ChartTab.PressaoArterial}
            onClick={() => setCurrentChartTab(ChartTab.PressaoArterial)}
          >
            Pressão arterial
          </TabItem>
          <ChartMonthSelector onChange={setDateRange} />
        </Tabs>
      </Cell>
      <Cell size={12}>
        {currentChartTab === ChartTab.PressaoArterial && (
          <GraficoPressaoArterial medicoes={medicoesComSinaisVitais} gestacoes={gestacoes} dateRange={dateRange} />
        )}
      </Cell>
    </Grid>
  )
}
