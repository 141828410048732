/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Heading, Text } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { AlergiaSelectFieldModel } from 'components/form/field/select/AlergiaSelectField/AlergiaSelectField'
import { useCallback } from 'react'
import { Alergia } from 'view/atendimentos/detail/soap/aside/types/AlergiaModel'
import { grupoCboAlergias } from 'view/atendimentos/detail/soap/avaliacao/acessos'
import { AlergiaReacaoModel } from 'view/atendimentos/detail/soap/avaliacao/components/alergias-reacoes/model'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { useEditableListField } from '../../EditableList'
import { AccordionAlergiasModal } from './AccordionAlergiasModal'
import { CadastroAlergiasModal } from './CadastroAlergiasModal'
import { EditableListaAlergiasModalProps } from './ListaAlergiasModal'

const MENSAGEM_NENHUMA_ALERGIA = 'Nenhuma alergia ou reação adversa registrada.'

export interface EditableListaAlergiasModalBodyProps extends EditableListaAlergiasModalProps {
  todasAlergias: Alergia[]
  alergiasCidadao: AlergiaSelectFieldModel[]
}

export function EditableListaAlergiasModalBody({
  fieldName,
  alergiasAtendimentoAtual,
  todasAlergias,
  cidadao,
  alergiasCidadao,
  ...cadastroModalProps
}: EditableListaAlergiasModalBodyProps) {
  const {
    handleSubmit,
    input: { value },
    removeItem,
    handleRowChanged,
  } = useEditableListField({
    name: fieldName,
  })

  const findFormModel = useCallback(
    (alergiaId: ID) =>
      alergiasAtendimentoAtual.find(
        (alergia: AlergiaReacaoModel) => alergia.alergiaId === alergiaId || alergia._id === alergiaId
      ),
    [alergiasAtendimentoAtual]
  )

  return (
    <Grid>
      <Cell size={12}>
        <Heading
          level={1}
          style={css`
            margin-bottom: 1rem;
          `}
        >
          Alergias/Reações adversas
        </Heading>
        <CheckPermission permission={grupoCboAlergias.adicionarEditarExcluir}>
          <CadastroAlergiasModal
            onSubmit={handleSubmit}
            formValues={value}
            cidadao={cidadao}
            alergiasCidadao={alergiasCidadao}
            {...cadastroModalProps}
          />
        </CheckPermission>
      </Cell>
      <Cell size={12}>
        {todasAlergias.length === 0 ? (
          <Text component='p'>{MENSAGEM_NENHUMA_ALERGIA}</Text>
        ) : (
          <AccordionAlergiasModal
            alergias={todasAlergias}
            cidadao={cidadao}
            showAlteracaoExclusao
            onRemove={removeItem}
            onEdit={handleRowChanged}
            findFormModel={findFormModel}
          />
        )}
      </Cell>
    </Grid>
  )
}

export interface ReadOnlyListaAlergiasModalBodyProps {
  todasAlergias: Alergia[]
  cidadao: CidadaoAtendimento
}

export function ReadOnlyListaAlergiasModalBody({ todasAlergias, cidadao }: ReadOnlyListaAlergiasModalBodyProps) {
  return (
    <Grid>
      <Cell size={12}>
        <Heading
          level={1}
          style={css`
            margin-bottom: 1rem;
          `}
        >
          Alergias/Reações adversas
        </Heading>
      </Cell>
      <Cell size={12}>
        {todasAlergias.length === 0 ? (
          <Text component='p'>{MENSAGEM_NENHUMA_ALERGIA}</Text>
        ) : (
          <AccordionAlergiasModal alergias={todasAlergias} cidadao={cidadao} showAlteracaoExclusao={false} />
        )}
      </Cell>
    </Grid>
  )
}
