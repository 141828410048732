import ReactPDF, { Document, Page } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'

import { PrinterType } from '../model'
import { PrintBody } from '../PrintBody'
import { PrintFooter } from '../PrintFooter'
import { PrintHeader } from '../PrintHeader'
import { usePrinterStyles } from '../usePrinterStyles'
import { convertDadosProfissionalPrint } from './convert'

export interface PecPrintPageLayoutProps {
  serverTime: Date
  documentTitle?: string
  pageProps?: ReactPDF.PageProps
  bodyStyles?: ReactPDF.Style
  showSignature?: boolean
  session?: PrinterType
  children?: React.ReactNode
  nameFooter?: string
}

export const PecPrintPageLayout = (props: PecPrintPageLayoutProps) => {
  const { serverTime, children, documentTitle, pageProps, bodyStyles, showSignature, session, nameFooter } = props
  const { config } = usePrinterStyles()
  const today = serverTime && moment(serverTime)

  const {
    nomeProfissional,
    nomeCbo,
    nomeUnidadeSaude,
    nomeMunicipio,
    nomeMunicipioUf,
    conselhoClasse,
  } = convertDadosProfissionalPrint(session)

  return (
    <Document title={documentTitle} author={nomeProfissional} creator='PEC eSUS-APS'>
      <Page style={{ ...config.page }} size='A4' {...pageProps}>
        <PrintHeader ufNome={nomeMunicipioUf} municipio={nomeMunicipio} unidadeSaude={nomeUnidadeSaude} />
        <PrintBody
          styles={bodyStyles}
          showSignature={showSignature ?? true}
          nomeProfissional={nomeProfissional}
          cboProfissional={nomeCbo}
          conselhoClasse={conselhoClasse}
          data={today.format('DD [de] MMMM [de] YYYY').toLowerCase()}
          municipio={nomeMunicipio}
          uf={nomeMunicipioUf}
        >
          {children}
        </PrintBody>
        <PrintFooter
          data={today.format('DD/MM/YYYY')}
          hora={today.format('HH:mm')}
          nomeProfissional={nameFooter ?? nomeProfissional}
        />
      </Page>
    </Document>
  )
}
