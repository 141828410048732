/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FormControl, HFlow, VFlow } from 'bold-ui'
import { ErrorField, RadioField } from 'components/form'
import { SituacaoProblema } from 'graphql/types.generated'
import { MetaPath } from 'util/metaPath'

import { ProblemasCondicoesFormProps } from './ProblemasCondicoesForm'
import { isSituacaoProblemaResolvidoDesabilitadaPreNatal } from './utils/verifications'

interface SituacaoProblemaFieldProps
  extends Pick<ProblemasCondicoesFormProps, 'isAntecedentes' | 'isLPC' | 'tipoPreNatal' | 'edicao'> {
  name: MetaPath<SituacaoProblema>
  isCiapW78: boolean
  isEvolucao: boolean
}

export function SituacaoProblemaField(props: SituacaoProblemaFieldProps) {
  const { name, isCiapW78, isEvolucao, isAntecedentes = false, edicao = false, isLPC = false, tipoPreNatal } = props

  return (
    <FormControl label='Situação' required={!isAntecedentes}>
      <VFlow vSpacing={0.5}>
        <HFlow
          style={css`
            margin-left: -0.5rem;
            padding-top: 0.5rem;
          `}
        >
          <RadioField name={name} disabled={isAntecedentes} label='Ativo' value={SituacaoProblema.ATIVO} />
          <RadioField
            name={name}
            disabled={isAntecedentes || isCiapW78}
            label='Latente'
            value={SituacaoProblema.LATENTE}
          />
          <RadioField
            name={name}
            disabled={
              isAntecedentes ||
              isSituacaoProblemaResolvidoDesabilitadaPreNatal(isCiapW78, isEvolucao, edicao, isLPC, tipoPreNatal)
            }
            label='Resolvido'
            value={SituacaoProblema.RESOLVIDO}
          />
        </HFlow>
        <ErrorField name={name} />
      </VFlow>
    </FormControl>
  )
}
