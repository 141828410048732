import { createValidator, maxLength, required, richTextMaxLength } from 'util/validation'

import { medicoesPanelValidator } from '../components/MedicoesPanel/validator'
import { agendarConsultaValidator } from '../detail/soap/desfecho/validator/validatorAgendamento'
import { EscutaInicialState } from './model'
import { MotivoConsultaFormModel } from './motivo-consulta/MotivoConsultaForm'

export const escutaInicialValidator = (isPreAtendimento: Boolean, getServerTimeNow: () => Date) =>
  createValidator<EscutaInicialState>(
    {
      motivoConsulta: motivoConsultaValidator,
      medicoes: medicoesPanelValidator,
      agendamentoConsulta: agendarConsultaValidator(getServerTimeNow),
    },
    (values: EscutaInicialState, errors) => {
      if (!isPreAtendimento) errors.classificacaoRisco = required(values.classificacaoRisco)
      if (maxLength(20)(values.procedimentos))
        errors.procedimentos = 'O número máximo de procedimentos em um atendimento é 20'

      return errors
    }
  )

const motivoConsultaValidator = createValidator<MotivoConsultaFormModel>({
  ciap: [required],
  texto: [richTextMaxLength(4000)],
})
