import { createValidator, oneRequired, richTextMaxLength } from 'util/validation'

import { IntervencoesProcedimentosPlanoModel } from '../components/IntervencoesProcedimentosView'
import { PlanoFormModel } from '../PlanoForm'

const intervencoesProcedimentosPlanoValidator = createValidator<IntervencoesProcedimentosPlanoModel>(
  {},
  (values, error) => {
    error.error = oneRequired([values?.ciaps, values?.procedimentos])
    return error
  }
)

export const planoProcedimentosValidator = () =>
  createValidator<PlanoFormModel>({
    texto: [richTextMaxLength(4000)],
    intervencoesProcedimentos: intervencoesProcedimentosPlanoValidator,
  })
