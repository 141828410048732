import { AntecedenteCiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/AntecedenteCiapSelectField'
import { itemsPuericultura as tipoGravidezItems } from 'components/form/field/select/TipoGravidezSelectField'
import { items as tipoPartoItems } from 'components/form/field/select/TipoPartoSelectField'
import { Antecedente, AntecedentesFamiliaresCiap, IdentidadeGeneroEnum, SexoEnum } from 'graphql/types.generated'
import { parseNumber } from 'util/number'
import { v4 as uuidv4 } from 'uuid'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { DataProblemaFieldModel } from '../avaliacao/components/problemas-condicoes/DataProblemaField'
import { AntecedentesFormModel, FormAtivoAntecedentesEnum } from './AntecedentesForm'
import { InformacoesObstetricasPanelModel } from './InformacoesObstetricasPanel'
import { PuericulturaPanelModel } from './PuericulturaPanel'

export const initialValueAntecedentes = (data: Antecedente): AntecedentesFormModel => ({
  formAtivo: FormAtivoAntecedentesEnum.PESSOAL,
  pessoal: {
    texto: data?.observacoes,
    informacoesObstetricas: {
      gestaPrevias: data?.gestacoesPrevias,
      abortos: data?.qtAbortos,
      partoMenosDeUmAno: data?.partoMenosDeUmAno,
      partos: data?.qtPartos,
      partosVaginais: data?.partosVaginais,
      partosCesareas: data?.qtCesareas,
      partosDomiciliares: data?.partosDomiciliares,
      nascidosVivos: data?.nascidosVivos,
      natimortos: data?.natimortos,
      recemNascidoAbaixo: data?.recemNascidosAbaixo,
      recemNascidoAcima: data?.recemNascidosAcima,
      filhosVivos: data?.filhosVivos,
      obitoAntesPrimeiraSemana: data?.obitoAntesPrimeiraSemana,
      obitoAposPrimeiraSemana: data?.obitoAposPrimeiraSemana,
      dataAtualizacao: data?.dataUltimaAtualizacaoGestacional,
    },
    puericultura: {
      tipoGravidez: tipoGravidezItems.find((item) => item.value === data?.tipoGravidez),
      tipoParto: tipoPartoItems.find((item) => item.value === data?.tipoParto),
      dataAtualizacao: data?.dataUltimaAtualizacaoPuericultura,
      idadeGestacional: {
        dias: parseNumber(data?.idadeGestacionalDias),
        semanas: parseNumber(data?.idadeGestacionalSemanas),
      },
      apgar: {
        apgarUm: parseNumber(data?.apgarUm),
        apgarCinco: parseNumber(data?.apgarCinco),
        apgarDez: parseNumber(data?.apgarDez),
      },
      antropometriaAoNascer: {
        peso: data?.peso?.replace('.', ','),
        comprimento: data?.altura?.replace('.', ','),
        perimetroCefalico: data?.perimetroCefalico?.replace('.', ','),
      },
    },
    cirurgiasInternacoes: data?.cirurgiasInternacoes?.map(
      (cirurgiaInternacao) =>
        ({
          _id: uuidv4(),
          idCirurgiaInternacao: cirurgiaInternacao.id,
          observacao: cirurgiaInternacao.observacoes,
          dataRealizada: {
            data: cirurgiaInternacao.dataCirurgiaInternacao,
          },
          ...cirurgiaInternacao,
        } as CirurgiaInternacaoModel)
    ),
  },
  familiar: {
    ciaps: data?.antecedentesFamiliaresCiap?.map((item: AntecedentesFamiliaresCiap) => {
      return {
        _id: uuidv4(),
        ciap: {
          id: item.idCiap,
          codigo: item.codigoCiap,
          nome: item.descricao,
        },
        observacao: item.observacao,
        atendimentoPrevio: true,
      }
    }),
  },
})

export interface CirurgiaInternacaoModel {
  _id: ID
  idCirurgiaInternacao?: ID
  descricao: string
  observacao?: string
  dataRealizada?: DataProblemaFieldModel
}

export interface CirurgiaInternacaoItem extends CirurgiaInternacaoModel {
  hasError?: Boolean
}

export interface PessoalAntecedentesModel {
  texto?: string
  puericultura?: AntecedentePuericulturaModel
  informacoesObstetricas?: InformacoesObstetricasPanelModel
  cirurgiasInternacoes?: CirurgiaInternacaoModel[]
}

export interface AntecedentePuericulturaModel extends PuericulturaPanelModel {
  dataAtualizacao?: Instant
}

export interface HospitalarAntecedentesFilterModel {
  texto: string
}

export interface AntecedentesFamiliaresCiapFormModel {
  _id: ID
  ciap?: AntecedenteCiapSelectFieldModel
  observacao?: string

  //Necessário pois os antecedentes familiares são uma RL sem ID no banco
  atendimentoPrevio?: boolean
}

export interface FamiliarAntecedentesModel {
  ciaps?: AntecedentesFamiliaresCiapFormModel[]
}

export const defaultItensAntecedenteCiap = (
  cidadao: CidadaoAtendimento
): { itens: AntecedenteCiapSelectFieldModel[]; ids: string[] } => {
  const masculinos = [
    { id: '178', codigo: 'K86', nome: 'HIPERTENSÃO SEM COMPLICAÇÕES' },
    { id: '476', codigo: 'T89', nome: 'DIABETES INSULINO-DEPENDENTE' },
    { id: '477', codigo: 'T90', nome: 'DIABETES NÃO INSULINO-DEPENDENTE' },
  ]

  const femininos = [
    { id: '640', codigo: 'W84', nome: 'GRAVIDEZ DE ALTO RISCO' },
    { id: '633', codigo: 'W76', nome: 'MALFORMAÇÃO CONGÊNITA QUE COMPLICA A GRAVIDEZ' },
  ]

  if (
    (cidadao.sexo === SexoEnum.FEMININO || cidadao.identidadeGeneroDbEnum === IdentidadeGeneroEnum.HOMEM_TRANS) &&
    cidadao.idadeEmAnos > 5
  ) {
    return { itens: femininos.concat(masculinos), ids: ['178', '476', '477', '633', '640'] }
  } else {
    return { itens: masculinos, ids: ['178', '476', '477'] }
  }
}
