import { Theme, useCss } from 'bold-ui'
import { useAcessoLotacao } from 'components/auth/useAcessoLotacao'
import useSession from 'components/auth/useSession'
import { Breadcrumb } from 'components/breadcrumb'
import { parseDateFromLocalStorage } from 'components/form'
import { PageLoading } from 'components/loading'
import { PecSwitch, PrivateRoute } from 'components/route'
import { differenceInYears, parseISO, toDate } from 'date-fns'
import {
  useAcompanhamentoPreNatalCardQuery,
  useAgendamentosDiaQuery,
  useAtendimentoIndividualViewQuery,
  useCiapCidAtendimentoQuery,
  useLoadAntecedentesQuery,
  useProblemasModalQuery,
  useProcedimentosAutomaticosQuery,
} from 'graphql/hooks.generated'
import {
  IdentidadeGeneroEnum,
  SexoEnum,
  SituacaoAgendadoEnum,
  SituacaoProblema,
  TipoAtendimentoEnum,
  TipoAtendimentoProfissional,
} from 'graphql/types.generated'
import { AtendimentoProvider } from 'hooks/atendimento-context/useAtendimentoContext'
import useMeasure from 'hooks/useMeasure'
import { useLocalStorage } from 'hooks/useStorage'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Redirect, Route, useRouteMatch } from 'react-router'
import {
  ProcedimentosAutomaticoAtendimentoOdonto,
  ProcedimentosAutomaticosAtendimentoIndividual,
  tipoAtendimentoToTipoServicoMap,
} from 'types/enums'
import { isCboMedico, isCboMedicoOuCirurgiaoDentista } from 'util/atendimento/cboMedicoOuCirurgiaoDentista'
import { calculateIdadeGestacional } from 'util/atendimento/gestante/calculateIdadeGestacional'
import { isUndefinedOrNull } from 'util/checks'
import { calculateAge } from 'util/date/calculateAge'
import { isEmpty } from 'util/validation/Util'

import Permissions from '../../../types/Permissions'
import { initialValue as initialValueVacinacao } from '../atendimento-vacinacao/model'
import { grupoCboHistorico } from '../detail/acessos'
import { AgendamentosCidadaoAtendimentoView } from '../detail/agendamentos/AgendamentosCidadaoAtendimentoView'
import { atendimentoName } from '../detail/atendimentoName'
import { CidadaoDetailView } from '../detail/cadastro-cidadao/CidadaoDetailView'
import { FolhaRostoView } from '../detail/folha-rosto/FolhaRostoView'
import { AtendimentoHeader } from '../detail/header/AtendimentoHeader'
import { HistoricoAtendimentoView } from '../detail/historico/HistoricoAtendimentoView'
import { initialValueAntecedentes } from '../detail/soap/antecedentes/model'
import { convertDadosPreNatal, getAltoRisco } from '../detail/soap/aside/pre-natal/util/AcompanhamentoPreNatalUtils'
import { procedimentosUnicosOdontologia } from '../detail/soap/plano/evolucoes-odontologicas/util'
import { grupoCboPreNatal } from '../detail/soap/pre-natal/acessos'
import { CIAP_W78_CODIGO, CID_Z34_CODIGO } from '../detail/soap/pre-natal/model'
import { SoapView } from '../detail/soap/SoapView'
import { VacinacaoView } from '../detail/vacinacao/VacinacaoView'
import { CidadaoAtendimento } from '../types/CidadaoAtendimento'
import {
  initialValue,
  initialValueObjetivo,
  SoapState,
  TIPOS_ATENDIMENTO_CONCLUEM_AGENDAMENTO,
  TipoServicoModel,
} from './model'

interface AtendimentoIndividualViewProps {
  atendimentoId: ID
}

export function AtendimentoIndividualView(props: AtendimentoIndividualViewProps) {
  const { atendimentoId } = props
  const { data: sessao, hasAuthorization, isUnidadeSaudeCEO, hasCboAuth } = useSession()
  const { unidadeSaude, id: lotacaoAcessoId } = useAcessoLotacao()
  const match = useRouteMatch()
  const [headerRef, { height: headerHeight }] = useMeasure()

  const {
    data: { atendimento },
    loading: loadingAtendimento,
  } = useAtendimentoIndividualViewQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      atendimentoId,
    },
  })

  const {
    data: { procedimentosAutomaticos, registroAvaliacaoPuericultura },
    loading: loadingProcedimentosAutomaticos,
  } = useProcedimentosAutomaticosQuery({
    fetchPolicy: 'cache-first',
  })

  const { agendado, cidadao, tiposServico, atendimentoProfissional, prontuario, registroTardio } = atendimento || {}

  const {
    data: { antecedente },
    loading: loadingAntecedente,
  } = useLoadAntecedentesQuery({
    variables: {
      id: prontuario?.id,
    },
    skip: !prontuario?.id,
  })

  const {
    data: { ciaps, cids },
    loading: loadingCiapCid,
  } = useCiapCidAtendimentoQuery({
    fetchPolicy: 'cache-first',
    variables: {
      inputCiap: {
        query: CIAP_W78_CODIGO,
      },
      inputCid10: {
        query: CID_Z34_CODIGO,
      },
    },
  })

  const {
    loading: loadingProblemasCidadao,
    data: { problemas: problemasAtivosELatentes },
  } = useProblemasModalQuery({
    variables: {
      filtro: {
        prontuarioId: prontuario?.id,
        situacoes: [SituacaoProblema.ATIVO, SituacaoProblema.LATENTE],
      },
    },
    skip: !prontuario?.id,
  })

  const { loading: loadingAcompanhamentoPreNatalCard, data: dataPreNatalCard } = useAcompanhamentoPreNatalCardQuery({
    variables: { input: prontuario?.id },
    skip: !prontuario?.id,
  })

  const atendProfIniciadoEm: Instant = atendimentoProfissional?.iniciadoEm

  const { dataInicioGestacao } = !loadingAcompanhamentoPreNatalCard && convertDadosPreNatal(dataPreNatalCard)
  const isPreNatalAltoRisco =
    !loadingAcompanhamentoPreNatalCard && getAltoRisco(dataPreNatalCard.atendimentosProfUltimaGestacao)

  const hasPermissionPreNatal = hasCboAuth(grupoCboPreNatal)
  const { emDias: idadeGestacional } = useMemo(
    () => calculateIdadeGestacional(dataInicioGestacao, atendProfIniciadoEm),
    [atendProfIniciadoEm, dataInicioGestacao]
  )

  const cidZ34 = useMemo(() => cids?.content?.find((cid) => cid.codigo === CID_Z34_CODIGO), [cids])
  const ciapW78 = useMemo(() => ciaps?.content?.find((ciap) => ciap.codigo === CIAP_W78_CODIGO), [ciaps])
  const ciapCidPreNatal = useMemo(
    () => ({
      ciap: ciapW78,
      cid: cidZ34,
    }),
    [ciapW78, cidZ34]
  )

  const idadeCidadaoEmAnos = useMemo(
    () => cidadao?.dataNascimento && calculateAge(cidadao.dataNascimento, atendProfIniciadoEm).years,
    [atendProfIniciadoEm, cidadao]
  )

  const informarDum: boolean =
    atendProfIniciadoEm &&
    shouldInformDum(cidadao.sexo, cidadao.identidadeGeneroDbEnum, cidadao.dataNascimento, toDate(atendProfIniciadoEm))

  const cidadaoAtendimento: CidadaoAtendimento = { ...cidadao, idadeEmAnos: idadeCidadaoEmAnos, informarDum }

  const today = moment().startOf('day').toDate()
  const { data: agendamentosDiaQueryResults, loading: loadingAgendamento } = useAgendamentosDiaQuery({
    variables: {
      input: {
        cidadaoId: cidadao?.id,
        dataAgendadoInicio: today,
        dataAgendadoFim: today,
        situacao: [SituacaoAgendadoEnum.AGENDADO, SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE],
        unidadeSaudeId: unidadeSaude.id,
        isForaDaUbs: false,
        isOrigemAtencaoDomiciliar: false,
      },
    },
    skip: !cidadao?.id,
  })

  const agendamentosDia = agendamentosDiaQueryResults?.agendados?.content?.filter(
    (agendado) => agendado.id !== atendimento.agendado?.id || agendado?.lotacaoAgendada?.id !== sessao.acesso.id
  )

  const isMesmaLotacaoAgendamento = agendado?.lotacaoAgendada?.id === sessao.acesso.id
  const isMedico = isCboMedico(sessao.acesso.__typename === 'Lotacao' && sessao.acesso.cbo.cbo2002)
  const isAtendimentoOdonto = atendimentoProfissional?.tipo === TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO
  const isAtendimentoProcedimentos =
    atendimentoProfissional?.tipo === TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS
  const isGestante = !isEmpty(prontuario?.preNatalAtivo)
  const somenteCiap = !isCboMedicoOuCirurgiaoDentista(
    sessao.acesso.__typename === 'Lotacao' && sessao.acesso.cbo.cbo2002
  )

  const isLoading =
    loadingAtendimento ||
    loadingAgendamento ||
    loadingAntecedente ||
    loadingProcedimentosAutomaticos ||
    loadingCiapCid ||
    loadingProblemasCidadao ||
    loadingAcompanhamentoPreNatalCard

  const nomeAtendimento = atendimentoName(atendimentoProfissional?.tipo)

  const [cacheState, setCacheState, deleteCacheState] = useLocalStorage<SoapState>(
    `${sessao.acesso.id}/atendimento/individual/${atendimentoId}`,
    undefined,
    cacheParser
  )
  const [initialStateLoaded, setInitialStateLoaded] = useState(!!cacheState)
  const hasEncaminhamentoExterno = !isEmpty(cacheState?.plano?.encaminhamentoExterno?.encaminhamentosRecentes)
  const qtdTotalProcedimentos =
    (cacheState?.plano?.intervencoesProcedimentos?.procedimentos?.length ?? 0) +
    (cacheState?.desfecho?.procedimentosAdministrativos?.length ?? 0) +
    (procedimentosUnicosOdontologia(cacheState?.plano?.odontologia).length ?? 0)
  const concluiAgendamento =
    agendado &&
    (TIPOS_ATENDIMENTO_CONCLUEM_AGENDAMENTO.includes(cacheState?.desfecho?.tipoAtendimento) ||
      (isAtendimentoProcedimentos && agendado.lotacaoAgendada.id === lotacaoAcessoId))

  const canAccess = hasAuthorization(Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir)

  useEffect(() => {
    if (!isLoading) {
      const agendadoSelecionado = cacheState?.desfecho?.desfechoAtendimento?.atendimento?.agendado
      if (agendadoSelecionado && !agendamentosDia?.find((agendamentos) => agendamentos.id === agendadoSelecionado)) {
        cacheState.desfecho.desfechoAtendimento.atendimento.agendado = undefined
      }

      if (!initialStateLoaded) {
        const initialProced = isMedico
          ? ProcedimentosAutomaticosAtendimentoIndividual.CONSULTA_MEDICA_EM_ATENCAO_BASICA
          : isUnidadeSaudeCEO
          ? ProcedimentosAutomaticoAtendimentoOdonto.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_ESPECIALIZADA
          : ProcedimentosAutomaticosAtendimentoIndividual.CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_BASICA
        const proced = procedimentosAutomaticos?.find((proced) => proced.codigo === initialProced)

        const tipoServicoFiltrado = filterTiposServico(tiposServico, atendimentoProfissional?.tipo)
        const tipoAtendimento = getTipoAtendimentoInitialValue(
          isAtendimentoProcedimentos,
          !!atendimento?.agendado,
          isMesmaLotacaoAgendamento
        )

        setCacheState({
          ...initialValue(proced, tipoAtendimento, agendamentosDia, tipoServicoFiltrado, canAccess, registroTardio),
          objetivo: initialValueObjetivo(isAtendimentoProcedimentos),
          vacinacao: initialValueVacinacao(prontuario?.puerpera, isGestante, tiposServico, canAccess, agendamentosDia),
          antecedentes: initialValueAntecedentes(antecedente),
        })
        setInitialStateLoaded(true)
      }
    }
  }, [
    agendamentosDia,
    atendimento,
    cacheState,
    canAccess,
    initialStateLoaded,
    isAtendimentoProcedimentos,
    isLoading,
    isMedico,
    isUnidadeSaudeCEO,
    procedimentosAutomaticos,
    prontuario,
    sessao.acesso.id,
    setCacheState,
    tiposServico,
    atendimentoProfissional,
    antecedente,
    isGestante,
    isAtendimentoOdonto,
    isMesmaLotacaoAgendamento,
    registroTardio,
  ])

  if (isLoading || !initialStateLoaded) {
    return (
      <>
        {atendimentoProfissional?.tipo && <Breadcrumb title={nomeAtendimento} />}
        <PageLoading message='Carregando dados do atendimento individual...' />
      </>
    )
  }

  const renderFolhaRostoView = () => (
    <FolhaRostoView
      prontuarioId={prontuario.id}
      isGestante={isGestante}
      gestacoes={prontuario?.gestacoes || []}
      clearCache={deleteCacheState}
      registroTardio={registroTardio}
      dataReferencia={atendimentoProfissional?.iniciadoEm}
      atendimentoId={atendimentoId}
      cidadao={cidadaoAtendimento}
      lotacao={atendimentoProfissional?.lotacao}
      isAtendimentoAgendado={!!atendimento.agendado}
      tipoAtendimentoProfissional={atendimentoProfissional?.tipo}
    />
  )

  const renderSoapView = () => (
    <SoapView
      atendimentoId={atendimentoId}
      prontuarioId={prontuario?.id}
      cidadao={cidadaoAtendimento}
      dataAtendimento={atendimentoProfissional?.iniciadoEm}
      procedimentosAutomaticos={procedimentosAutomaticos || []}
      isMedico={isMedico}
      agendamentoAtendimentoId={agendado?.id}
      concluiAgendamento={concluiAgendamento}
      hasEncaminhamentoExterno={hasEncaminhamentoExterno}
      updateCache={setCacheState}
      cacheState={cacheState}
      clearCache={deleteCacheState}
      tiposServico={tiposServico}
      agendamentosDia={agendamentosDia}
      isAtendimentoOdonto={isAtendimentoOdonto}
      isAtendimentoProcedimentos={isAtendimentoProcedimentos}
      isGestante={isGestante}
      atendimentoProfissional={atendimentoProfissional}
      registroTardio={registroTardio}
      registroAvaliacaoPuericultura={registroAvaliacaoPuericultura}
      nomeAtendimento={nomeAtendimento}
      ciapCidPreNatal={ciapCidPreNatal}
      somenteCiap={somenteCiap}
      problemasAtivosELatentes={problemasAtivosELatentes?.content}
      gestacoes={prontuario?.gestacoes || []}
      headerHeight={headerHeight}
      qtdTotalProcedimentos={qtdTotalProcedimentos}
    />
  )

  const renderAtendimentoHistoricoView = () => (
    <HistoricoAtendimentoView
      atendimentoId={atendimentoId}
      prontuarioId={prontuario.id}
      cidadao={cidadao}
      unidadeSaudeCnes={unidadeSaude.cnes}
      tipoAtendimentoProfissional={atendimentoProfissional?.tipo}
      registroTardio={registroTardio}
      clearCache={deleteCacheState}
    />
  )

  const renderCidadaoDetail = () => (
    <CidadaoDetailView
      atendimentoId={atendimentoId}
      cidadaoId={cidadao?.id}
      tipoAtendimentoProfissional={atendimentoProfissional?.tipo}
      clearCache={deleteCacheState}
      registroTardio={registroTardio}
      callbackUrl={match.url}
    />
  )

  const renderVacinacao = () => (
    <VacinacaoView
      atendimentoId={atendimentoId}
      cidadao={cidadaoAtendimento}
      gestante={isGestante}
      gestacoes={prontuario?.gestacoes || []}
      clearCache={deleteCacheState}
      dataAtendimento={atendimentoProfissional.iniciadoEm}
      prontuarioId={prontuario.id}
      agendamentosDia={agendamentosDia}
      cacheState={cacheState}
      updateCache={setCacheState}
      isAtendimentoVacinacao={false}
    />
  )

  const renderAgendamentos = () => (
    <AgendamentosCidadaoAtendimentoView
      atendimentoId={atendimentoId}
      tipoAtendimentoProfissional={atendimentoProfissional.tipo}
      clearCache={deleteCacheState}
      registroTardio={registroTardio}
    />
  )

  const atendimentoProviderValue = {
    ...atendimento,
    cidadao: {
      ...cidadaoAtendimento,
      isGestante,
      dataInicioGestacao,
      idadeGestacional,
    },
    prontuario: {
      ...prontuario,
      preNatalAtivo: {
        ...prontuario.preNatalAtivo,
        altoRisco: isPreNatalAltoRisco,
      },
    },
    permissoes: { hasPermissionPreNatal, somenteCiap },
  }

  return (
    <AtendimentoProvider value={atendimentoProviderValue}>
      {atendimentoProfissional?.tipo && <Breadcrumb title={nomeAtendimento} />}
      <AtendimentoHeader
        ref={headerRef}
        cidadaoId={cidadao?.id}
        cnsProfissional={atendimento?.atendimentoProfissional.lotacao.profissional.cns}
        cnsCidadao={cidadao?.cns}
        cnes={atendimento?.atendimentoProfissional.lotacao.unidadeSaude.cnes}
        municipioId={atendimento?.atendimentoProfissional.lotacao.municipio.id}
        dataAtendimento={atendimento?.atendimentoProfissional.iniciadoEm}
      />
      <PecSwitch>
        <Redirect exact path={match.url} to={`${match.url}/folha-rosto`} />
        <Route path={`${match.url}/folha-rosto`} render={renderFolhaRostoView} />
        <PrivateRoute
          path={`${match.url}/historico`}
          render={renderAtendimentoHistoricoView}
          permission={grupoCboHistorico.visualizar}
        />
        <Route path={`${match.url}/soap`} render={renderSoapView} />
        <Route path={`${match.url}/cadastro-cidadao`} render={renderCidadaoDetail} />
        <Route path={`${match.url}/vacinacao`} render={renderVacinacao} />
        <Route path={`${match.path}/agendamentos/:cidadaoId`} render={renderAgendamentos} />
      </PecSwitch>
      {/* TODO
       * Necessário ajustar o botão "Finalizar atendimento individual",
       * para utilizar o componente aqui.
       * Enquanto isso, é colocado em cada aba.
       * <AtendimentoFooter atendimentoId={atendimentoId} clearCache={deleteCacheState} />
       * */}
    </AtendimentoProvider>
  )
}

// copiado do bold pra memoizar o retorno, mas deve ser corrigido lá depois
export type StyleFactory<Classes extends string> = (
  theme: Theme,
  ...args: any[]
) => { readonly [key in Classes]: React.CSSProperties }

export type ClassNames<Classes extends string> = { readonly [key in Classes]: string }

const emptyStylesFactory: StyleFactory<any> = () => ({})

export const useLocalStyles = <Classes extends string>(
  factory: StyleFactory<Classes> = emptyStylesFactory,
  ...args: any[]
) => {
  const { css, theme } = useCss()

  return useMemo(() => {
    const map = factory(theme, ...args)

    const classes = Object.keys(map).reduce(
      (all, className) => ({
        ...all,
        [className]: css(map[className]),
      }),
      {} as ClassNames<Classes>
    )

    return { classes, css, theme }
  }, [args, css, factory, theme])
}

const cacheParser = (cacheState: SoapState): SoapState =>
  cacheState
    ? {
        ...cacheState,
        desfecho: {
          ...cacheState.desfecho,
          agendamentoConsulta: cacheState.desfecho?.agendamentoConsulta
            ? {
                ...cacheState.desfecho?.agendamentoConsulta,
                horario: parseDateFromLocalStorage(cacheState.desfecho?.agendamentoConsulta?.horario),
              }
            : undefined,
        },
      }
    : undefined

const filterTiposServico = (
  tiposServico: TipoServicoModel[],
  tipoAtendProf: TipoAtendimentoProfissional
): TipoServicoModel[] =>
  tiposServico?.filter((item) => !tipoAtendimentoToTipoServicoMap[tipoAtendProf].includes(item.id))

const IDADE_MINIMA_EM_ANOS_DUM: number = 9

const shouldInformDum = (
  sexo: SexoEnum,
  identidadeGenero: IdentidadeGeneroEnum,
  dataNascimento: LocalDate,
  dataReferencia: Date
): boolean =>
  (sexo === SexoEnum.FEMININO || !isUndefinedOrNull(identidadeGenero)) &&
  differenceInYears(dataReferencia, parseISO(dataNascimento)) >= IDADE_MINIMA_EM_ANOS_DUM

function getTipoAtendimentoInitialValue(
  isAtendimentoProcedimentos: boolean,
  isAgendado: boolean,
  isMesmaLotacaoAgendamento: boolean
) {
  if (!isAtendimentoProcedimentos) {
    if (!isAgendado) return TipoAtendimentoEnum.CONSULTA_NO_DIA
    else if (isMesmaLotacaoAgendamento) return TipoAtendimentoEnum.CONSULTA
  }

  return undefined
}
