import { Omit, Select, SelectProps } from 'bold-ui'
import { ProcedimentoSelectFieldEmptyItem } from 'components/form/field/select/ProcedimentoSelectField/ProcedimentoSelectFieldEmptyItem'
import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from 'components/form/field/select/useAsyncQuerySelect'
import { ProcedimentoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  GrupoProcedimentoEnum,
  IdentidadeGeneroEnum,
  ProcedimentoSelectFieldQuery,
  ProcedimentoSelectFieldQueryVariables,
  SexoEnum,
  TipoExameEnum,
  TipoProcedimentoEnum,
} from 'graphql/types.generated'
import React from 'react'

import { ProcedimentoSelectModel } from '../../form/field/select/ProcedimentoSelectField'
import { ProcedimentoSelectFieldItem } from '../../form/field/select/ProcedimentoSelectField/ProcedimentoSelectFieldItem'

export interface ProcedimentoSelectProps extends Omit<SelectProps<ProcedimentoSelectModel>, 'items' | 'itemToString'> {
  tipoExame?: TipoExameEnum
  tipoProcedimento?: TipoProcedimentoEnum
  gruposProcedimento?: GrupoProcedimentoEnum[]
  filtroPadrao?: boolean
  identidadeGeneroDbEnum?: IdentidadeGeneroEnum
  sexo?: SexoEnum
  dataNascimento?: LocalDate
  dataAtendimento: LocalDate
  cbo?: string
  filtrarProcedimentosOdonto?: boolean
  loadItemsOnOpen?: boolean
}

export function ProcedimentoSelect(props: ProcedimentoSelectProps) {
  const {
    loadItemsOnOpen = true,
    tipoExame,
    tipoProcedimento,
    gruposProcedimento,
    filtroPadrao,
    identidadeGeneroDbEnum,
    sexo,
    dataNascimento,
    dataAtendimento,
    cbo,
    filtrarProcedimentosOdonto,
    components,
    ...rest
  } = props

  const asyncProps = useAsyncQuerySelect<
    ProcedimentoSelectModel,
    ProcedimentoSelectFieldQuery,
    ProcedimentoSelectFieldQueryVariables
  >({
    query: ProcedimentoSelectFieldDocument,
    extractItems: (data) => data?.procedimentos?.content,
    variables: (inputString) => ({
      input: {
        query: inputString,
        pageParams: DEFAULT_SELECT_PAGE_PARAM,
        filtro: {
          tipoExame,
          tipoProcedimento,
          gruposProcedimento,
          filtroPadrao,
          sexo: identidadeGeneroDbEnum ? undefined : sexo,
          dataNascimento,
          dataAtendimento,
          cbo,
          filtrarProcedimentosOdonto,
        },
      },
    }),
    skip: (inputString) => (!loadItemsOnOpen ? inputString?.trim()?.length < 2 : undefined),
    debounceTime: 500,
  })

  const { skiping, ...restAsyncProps } = asyncProps

  return (
    <Select<ProcedimentoSelectModel>
      itemToString={itemToString}
      renderItem={ProcedimentoSelectFieldItem}
      components={{
        EmptyItem: () => ProcedimentoSelectFieldEmptyItem(skiping, loadItemsOnOpen),
        ...components,
      }}
      {...restAsyncProps}
      {...rest}
    />
  )
}

const itemToString = (item: ProcedimentoSelectModel) => item?.descricao
