import { isAfter } from 'date-fns'
import { TipoFrequencia, UnidadeMedidaTempoEnum } from 'graphql/types.generated'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import {
  afterEqualTo,
  composeRules,
  createValidator,
  ErrorObject,
  maxLength,
  minLength,
  minRange,
  required,
} from 'util/validation'

import { MedicamentoFormModel } from '../MedicamentoFormModel'
import { calculateDataFimTratamento, duracaoFrequencia, duracaoMenorQueFrequencia } from './prescricaoUtils'

export const prescricaoFormValidator = (dataAtendimento: Date) =>
  createValidator<MedicamentoFormModel>(
    {
      viaAdministracao: [required],
      principioAtivoText: [maxLength(200)],
      dose: [required, minLength(1), maxLength(50)],
      concentracaoText: [maxLength(100)],
      dataInicioTratamento: [required],
      quantidade: [required, minLength(1), maxLength(3), minRange(1)],
      duracao: [minRange(1)],
    },
    (values: MedicamentoFormModel, errors: ErrorObject<MedicamentoFormModel>) => {
      if (!values) {
        return errors
      }

      if (values.dataInicioTratamento) {
        errors.dataInicioTratamento = afterEqualTo(
          values.dataInicioTratamento,
          dateAsYyyyMmDd(dataAtendimento),
          'data do atendimento'
        )
      }

      if (values.registroManual) {
        errors.principioAtivoText = composeRules([required, maxLength(200)])(values.principioAtivoText)
        errors.formaFarmaceutica = required(values.formaFarmaceutica)
        errors.tipoReceita = required(values.tipoReceita)
        errors.unidadeFornecimento = composeRules([required, minLength(2), maxLength(100)])(values.unidadeFornecimento)
      } else {
        !values.principioAtivoCombo && (errors.principioAtivoCombo = required(values.principioAtivoCombo))
      }

      if (UnidadeMedidaTempoEnum.INDETERMINADO !== values.escalaDuracao && !values.duracao) {
        errors.duracao = required(values.duracao)
      }

      if (!values.doseUnica) {
        if (TipoFrequencia.INTERVALO === values.tipoFrequencia) {
          errors.intervaloDose = minRange(1)(values.intervaloDose)

          const intervaloIsAfterDuracao = isAfter(
            calculateDataFimTratamento(
              values.intervaloDose,
              values.dataInicioTratamento,
              values.unidadeMedidaTempoFrequenciaTurno
            ),
            calculateDataFimTratamento(values.duracao, values.dataInicioTratamento, values.escalaDuracao)
          )
          if (intervaloIsAfterDuracao) {
            if (values.duracao) {
              errors.intervaloDose = duracaoMenorQueFrequencia(intervaloIsAfterDuracao)(values.intervaloDose)
              errors.duracao = duracaoMenorQueFrequencia(intervaloIsAfterDuracao)(values.duracao)
              errors.frequenciaDuracao = duracaoFrequencia(
                values.intervaloDose,
                values.dataInicioTratamento,
                values.unidadeMedidaTempoFrequenciaTurno,
                values.escalaDuracao
              )(values.duracao)
            }
          } else if (!values.intervaloDose) {
            errors.intervaloDose = required(values.intervaloDose)
          }
        } else {
          if (values.tipoFrequencia === TipoFrequencia.FREQUENCIA) {
            errors.frequenciaDose = composeRules([required, minRange(1)])(values.frequenciaDose)
          }
          errors.quantidadePeriodoFrequenciaTurno = minRange(1)(values.quantidadePeriodoFrequenciaTurno)
          const frequenciaIsAfterDuracao = isAfter(
            calculateDataFimTratamento(
              values.quantidadePeriodoFrequenciaTurno,
              values.dataInicioTratamento,
              values.unidadeMedidaTempoFrequenciaTurno
            ),
            calculateDataFimTratamento(values.duracao, values.dataInicioTratamento, values.escalaDuracao)
          )
          if (frequenciaIsAfterDuracao) {
            if (values.quantidadePeriodoFrequenciaTurno && values.duracao) {
              errors.quantidadePeriodoFrequenciaTurno = duracaoMenorQueFrequencia(frequenciaIsAfterDuracao)(
                values.quantidadePeriodoFrequenciaTurno
              )
              errors.duracao = duracaoMenorQueFrequencia(frequenciaIsAfterDuracao)(values.duracao)
              errors.frequenciaDuracao = duracaoFrequencia(
                values.quantidadePeriodoFrequenciaTurno,
                values.dataInicioTratamento,
                values.unidadeMedidaTempoFrequenciaTurno,
                values.escalaDuracao
              )(values.duracao)
            }
          } else {
            errors.quantidadePeriodoFrequenciaTurno = composeRules([required, minRange(1)])(
              values.quantidadePeriodoFrequenciaTurno
            )
            errors.unidadeMedidaTempoFrequenciaTurno = required(values.unidadeMedidaTempoFrequenciaTurno)
          }
        }
      }
      return errors
    }
  )
