/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Checkbox, HFlow, Icon, InfoLabel, isEmpty, Tag, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import useFirebase from 'components/firebase/useFirebase'
import { HLabel } from 'components/HLabel'
import { confirm } from 'components/modals/confirm'
import { useHistoricoRetificaAtendimentoMutationMutation } from 'graphql/hooks.generated'
import { TipoAtendimentoHistoricoClinico, TipoAtendimentoProfissional } from 'graphql/types.generated'
import { useMemo } from 'react'
import { AccordionItemButton } from 'react-accessible-accordion'
import { stringGrouper } from 'util/string/string-grouper'
import { downloadAtendimentoIndividual } from 'view/atendimentos/detail/components/downloadAtendimentoIndividual'
import { downloadAtendimentoVacinacao } from 'view/atendimentos/detail/components/vacinacao/downloadAtendimentoVacinacao'

import { useHistoricoPrint } from '../../../hooks/useHistoricoPrint'
import {
  CidadaoHistorico,
  HistoricoAtendimentoModel,
  SubtipoAtendimentoTag,
  tipoAtendimentoHistoricoData,
} from '../../../model/historicoModel'
import { hasSomeSubmodulesPlano, historicoPrintEnable, horaOrTurnoByOrigem } from '../../../utils/historicoUtils'
import HistoricoHeaderEllipsis from '../../HistoricoHeaderEllipsis'
import { useImpressaoEscutaInicialHistorico } from '../detail/escuta-inicial/ImpressaoEscutaInicialHistorico'
import HistoricoAccordionHeaderTitleTags from './title/HistoricoAccordionHeaderTitleTags'
import HistoricoAccordionHeaderTitleText from './title/HistoricoAccordionHeaderTitleText'
import { HistoricoDadosClinicos } from './title/HistoricoDadosClinicos'

export interface HistoricoAccordionHeaderProps {
  historicoAtendimento: HistoricoAtendimentoModel
  subtipoAtendimento: SubtipoAtendimentoTag
  refetchOnListagem?(): void
  cidadao: CidadaoHistorico

  checked?: boolean
  onSelectToPrint?(checked: boolean, historico: HistoricoAtendimentoModel): void
  historicoPrintProps: ReturnType<typeof useHistoricoPrint>
}

export default function HistoricoAccordionHeader(props: HistoricoAccordionHeaderProps) {
  const {
    historicoAtendimento,
    subtipoAtendimento,
    cidadao,
    refetchOnListagem,
    checked,
    onSelectToPrint,
    historicoPrintProps,
  } = props
  const [retificaAtendimento] = useHistoricoRetificaAtendimentoMutationMutation()
  const { analytics } = useFirebase()
  const alert = useAlert()
  const theme = useTheme()
  const { downloadEscutaInicial } = useImpressaoEscutaInicialHistorico(historicoAtendimento.idAtendRecente)
  const handleRejection = useErrorHandler()

  const isAtendimentoVacinacao =
    historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.VACINACAO ||
    historicoAtendimento.tipoApresentacao === TipoAtendimentoHistoricoClinico.VACINACAO

  const isEscutaInicial =
    historicoAtendimento.tipoAtendProf === TipoAtendimentoProfissional.ESCUTA_INICIAL ||
    historicoAtendimento.tipoApresentacao === TipoAtendimentoHistoricoClinico.ESCUTA_INICIAL

  const {
    data: { profissional },
  } = useSession()

  const hasCondicoesAvaliadas =
    (historicoAtendimento.tipoApresentacao === TipoAtendimentoHistoricoClinico.ATENDIMENTO_DOMICILIAR ||
      historicoAtendimento.tipoApresentacao === TipoAtendimentoHistoricoClinico.AVALIACAO_ELEGIBILIDADE) &&
    !isEmpty(historicoAtendimento.condicoesAvaliadas)
  const hasExamesRealizados = !isEmpty(historicoAtendimento.examesRealizadosZika)
  const horaOuTurnoAtendimento = horaOrTurnoByOrigem(historicoAtendimento)

  const tipoAtendimentoData =
    tipoAtendimentoHistoricoData[
      (historicoAtendimento.tipoApresentacao ?? historicoAtendimento.tipoAtendProf).toString()
    ]

  const atendimentoPrintEnable = historicoPrintEnable(historicoAtendimento)

  const classes = useMemo(() => createStyles(theme, tipoAtendimentoData.barColor), [theme, tipoAtendimentoData])

  const printHistorico = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    isEscutaInicial
      ? downloadEscutaInicial()
      : isAtendimentoVacinacao
      ? downloadAtendimentoVacinacao({
          atendimentoProfissionalId: historicoAtendimento.idAtendRecente,
          historicoCabecalhoId: historicoAtendimento.idAtendProcessado,
        })
      : downloadAtendimentoIndividual({
          atendimentoProfissionalId: historicoAtendimento.idAtendRecente,
          historicoCabecalhoId: historicoAtendimento.idAtendProcessado,
        })
    analytics.logEvent('click_button_historico_impressao_atendimento')
  }

  const renderBodyRetificaAtendimentoConfirmModal = () => (
    <VFlow>
      {`Todos os registros de vacinação deste atendimento serão excluídos do PEC e-SUS APS e
      ${historicoAtendimento.isAtendRecente ? 'não serão enviados para a base nacional' : 'da base nacional'}.`}
      <InfoLabel title='Registros que serão removidos:'>
        <Text>{historicoAtendimento.dadosClinicos}</Text>
      </InfoLabel>
      {!historicoAtendimento.isAtendRecente &&
        'Os registros removidos poderão ser visualizados na Trilha de Auditoria do PEC e-SUS APS.'}
    </VFlow>
  )

  const handleOnRetificaAtendimento = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    confirm({
      title: `Deseja excluir o atendimento de vacinação do cidadão ${(
        cidadao.nomeSocial ?? cidadao.nome
      ).titleCase()}?`,
      body: renderBodyRetificaAtendimentoConfirmModal(),
      cancelLabel: 'Cancelar',
      confirmLabel: 'Excluir atendimento',
      type: 'danger',
      onConfirm: () => {
        retificaAtendimento({ variables: { id: historicoAtendimento.idAtendRecente } })
          .then(() => {
            alert(
              'success',
              'Atendimento de vacinação excluído com sucesso. As alterações estarão disponíveis na RNDS em torno de 15 dias após o envio para o Centralizador Nacional'
            )
            refetchOnListagem()
            historicoPrintProps.remove(historicoAtendimento)
          })
          .catch(handleRejection)
      },
    })()
  }

  const handleChangePrintCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectToPrint(e.target.checked, historicoAtendimento)
  }

  return (
    <AccordionItemButton css={classes.accordionButton}>
      <div css={classes.barColor} />
      <div css={classes.accordionButtonContent}>
        <HFlow>
          <div
            css={css`
              padding-top: 0.25rem;
            `}
          >
            <Tooltip
              text={
                !atendimentoPrintEnable
                  ? isEscutaInicial
                    ? 'Este tipo de atendimento só permite impressão individual'
                    : 'Atendimento não permite impressão'
                  : 'Imprimir'
              }
            >
              <div onClick={(e) => e.stopPropagation()}>
                {/* TODO (Foundation): Remover condição de disabled para escuta */}
                <Checkbox
                  checked={checked}
                  onChange={handleChangePrintCheckbox}
                  disabled={!atendimentoPrintEnable || isEscutaInicial}
                />
              </div>
            </Tooltip>
          </div>
          <VFlow vSpacing={0.5}>
            <HFlow
              style={css`
                display: inline-flex;
                flex-wrap: wrap;
              `}
              alignItems='center'
              hSpacing={0.5}
            >
              <HistoricoAccordionHeaderTitleText
                tipoAtendimentoData={tipoAtendimentoData}
                horaOuTurnoAtendimento={horaOuTurnoAtendimento}
              />
              <HistoricoAccordionHeaderTitleTags
                historicoAtendimento={historicoAtendimento}
                subtipoAtendimento={subtipoAtendimento}
              />
            </HFlow>
            <Text>
              {`${
                historicoAtendimento.profissional?.nome ?? `CNS: ${historicoAtendimento.cnsProfissional}`
              } - ${historicoAtendimento.cbo.nome.capitalize()} - ${historicoAtendimento.cbo.cbo2002} ${
                historicoAtendimento.equipe
                  ? `| Equipe ${historicoAtendimento.equipe?.ine} - ${historicoAtendimento.equipe?.nome}`
                  : ''
              }`}
            </Text>

            {hasCondicoesAvaliadas && (
              <HistoricoHeaderEllipsis tooltipText='Mais condições avaliadas'>
                <HLabel title='Condições avaliadas:'>
                  {historicoAtendimento?.condicoesAvaliadas &&
                    stringGrouper(...historicoAtendimento.condicoesAvaliadas)}
                </HLabel>
              </HistoricoHeaderEllipsis>
            )}
            {historicoAtendimento.dadosClinicos && (
              <HistoricoDadosClinicos dadosClinicos={historicoAtendimento.dadosClinicos} />
            )}
            {hasSomeSubmodulesPlano(historicoAtendimento) && (
              <HFlow style={classes.planoTagsWrapper} hSpacing={0.5}>
                {historicoAtendimento.hasPrescricaoMedicamento && <Tag type='normal'>Prescrição de medicamento</Tag>}
                {historicoAtendimento.hasAlergia && <Tag type='normal'>Alergia</Tag>}
                {historicoAtendimento.hasAtestado && <Tag type='normal'>Atestado</Tag>}
                {historicoAtendimento.hasOrientacao && <Tag type='normal'>Orientação</Tag>}
                {historicoAtendimento.hasLembrete && <Tag type='normal'>Lembrete</Tag>}
                {historicoAtendimento.hasSolicitacaoExame && <Tag type='normal'>Solicitação de exame</Tag>}
                {historicoAtendimento.hasResultadoExame && <Tag type='normal'>Resultado de exame</Tag>}
                {historicoAtendimento.hasEncaminhamento && <Tag type='normal'>Encaminhamento</Tag>}
                {historicoAtendimento.hasProcedimentoClinico && <Tag type='normal'>Procedimento clínico</Tag>}
              </HFlow>
            )}
            {isAtendimentoVacinacao && (
              <HFlow hSpacing={0.5}>
                {historicoAtendimento.condicoesVacinacao?.isViajante && <Tag type='normal'>Viajante</Tag>}
                {historicoAtendimento.condicoesVacinacao?.isGestante && <Tag type='normal'>Gestante</Tag>}
                {historicoAtendimento.condicoesVacinacao?.isPuerpera && <Tag type='normal'>Puérpera</Tag>}
                {historicoAtendimento.condicoesVacinacao?.isComunicanteHanseniase && (
                  <Tag type='normal'>Comunicante de hanseníase</Tag>
                )}
              </HFlow>
            )}
            {hasExamesRealizados && (
              <HistoricoHeaderEllipsis tooltipText='Mais exames avaliados'>
                <HLabel title='Exames realizados:'>
                  {historicoAtendimento?.examesRealizadosZika &&
                    stringGrouper(...historicoAtendimento.examesRealizadosZika)}
                </HLabel>
              </HistoricoHeaderEllipsis>
            )}
          </VFlow>
        </HFlow>
        <HFlow>
          {historicoAtendimento.isCancelado && (
            <div css={classes.marcadoParaExclusao}>
              <Icon
                icon='clockOutline'
                size={1}
                style={css`
                  margin-right: 0.5rem;
                `}
              />
              Marcado para exclusão
            </div>
          )}
          {isAtendimentoVacinacao && (
            <Tooltip text={!historicoAtendimento.isCancelado && 'Excluir atendimento'}>
              <span>
                <Button
                  size='small'
                  skin='ghost'
                  disabled={
                    historicoAtendimento.profissional?.id !== profissional.id ||
                    !historicoAtendimento.idAtendRecente ||
                    historicoAtendimento.isCancelado
                  }
                  onClick={handleOnRetificaAtendimento}
                >
                  <Icon icon='trashOutline' size={1.5} />
                </Button>
              </span>
            </Tooltip>
          )}
          {isEscutaInicial && (
            <Tooltip text='Imprimir'>
              <span>
                <Button size='small' skin='ghost' onClick={printHistorico}>
                  <Icon icon='printerOutline' size={1.5} />
                </Button>
              </span>
            </Tooltip>
          )}
          <div
            css={css`
              padding-top: 0.25rem;
            `}
          >
            <Icon name='accordionAngleIcon' icon='angleDown' size={1.5} />
          </div>
        </HFlow>
      </div>
    </AccordionItemButton>
  )
}

const createStyles = (theme: Theme, barColor: string) => {
  return {
    accordionButton: css`
      display: flex;
      cursor: pointer;
      border-radius: 2px;
      border: 0px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      &:hover {
        background-color: ${theme.pallete.gray.c90};
      }
      &[aria-expanded='true'] div:first-of-type {
        border-bottom-left-radius: 2.5px;
      }
      &[aria-expanded='true'] {
        border-bottom: 1px solid ${theme.pallete.gray.c80};
      }
      &[aria-expanded='false'] {
        border-bottom: 0px;
      }
      &[aria-expanded='true'] svg[name='accordionAngleIcon'],
      &[aria-selected='true'] svg[name='accordionAngleIcon'] {
        transform: rotate(180deg);
        transition: 0.4s;
      }
      &[aria-expanded='false'] svg[name='accordionAngleIcon'] {
        transform: rotate(0deg);
        transition: 0.4s;
      }
    `,
    marcadoParaExclusao: css`
      color: ${theme.pallete.status.danger.c50};
      display: flex;
      padding-top: 0.5rem;
    `,
    barColor: css`
      width: 0.5rem;
      background-color: ${barColor};
      border-top-left-radius: 1px;
      border-bottom-left-radius: 1px;
    `,
    accordionButtonContent: css`
      justify-content: space-between;
      background-color: ${theme.pallete.surface.background};
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      padding: 0.5rem;
      display: flex;
      width: 100%;
    `,
    planoTagsWrapper: css`
      display: flex;
      flex-wrap: wrap;
    `,
  }
}
