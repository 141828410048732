import { Omit, Text } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import LabelItemInativo from 'components/LabelItemInativo'
import { PerfilSelectFieldDocument } from 'graphql/hooks.generated'
import { PerfilSelectFieldQuery, PerfilSelectFieldQueryVariables, TipoAcesso } from 'graphql/types.generated'
import React from 'react'

import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type PerfilSelectModel = PerfilSelectFieldQuery['perfis']['content'][0]

export interface PerfilSelectFieldProps
  extends Omit<SelectFieldProps<PerfilSelectModel>, 'items' | 'itemToString' | 'itemIsEqual'> {
  tipoAcesso: TipoAcesso
  mostrarInativos?: boolean
}

export function PerfilSelectField(props: PerfilSelectFieldProps) {
  const { mostrarInativos, tipoAcesso, ...rest } = props

  const { loading, ...asyncProps } = useAsyncQuerySelect<
    PerfilSelectModel,
    PerfilSelectFieldQuery,
    PerfilSelectFieldQueryVariables
  >({
    query: PerfilSelectFieldDocument,
    extractItems: (data) => data && data.perfis && data.perfis.content,
    variables: (inputQuery: string) => ({
      input: {
        mostrarInativos,
        tipoAcesso,
        query: inputQuery,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
    }),
  })

  const itemToString = (op: PerfilSelectModel) => op && (op.ativo ? op.nome : op.nome + ' [Inativo]')
  const renderItem = (op: PerfilSelectModel) => (
    <>
      <Text>
        {op.nome}
        <LabelItemInativo ativo={op.ativo} />
      </Text>
    </>
  )

  return (
    <SelectField<PerfilSelectModel>
      renderItem={renderItem}
      itemToString={itemToString}
      icon={null}
      {...asyncProps}
      {...rest}
    />
  )
}
