/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, Tooltip, useTheme, VFlow } from 'bold-ui'
import { resolveName } from 'components/form/final-form/useField'
import { getFormGroupErrors } from 'components/form/final-form/util'
import { FormApi } from 'final-form'
import { IdentidadeGeneroEnum, Procedimento, SexoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { omit } from 'lodash'
import { useCallback } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'
import { v4 as uuidv4 } from 'uuid'

import {
  ResultadoExameEspecificoModel,
  ResultadoExameSemSolicitacaoModel,
  ResultadosExamesNaoSolicitadosModel,
} from '../../model'
import { getCardResultadoExameStatus } from '../components/CardResultadoExameContainer'
import { ExamesSelect } from '../components/ExamesSelect'
import { ResultadoExameSemSolicitacaoField } from './ResultadoExameSemSolicitacaoField'

interface ExamesSemSolicitacaoFieldProps {
  name: MetaPath<ResultadosExamesNaoSolicitadosModel>
  cidadaoSexo: SexoEnum
  cidadaoIdentidadeGeneroEnum: IdentidadeGeneroEnum
  cidadaoDataNascimento: LocalDate
  formApi: FormApi
  changeValue(name: MetaPath<any>, prevValue: any): void
}

export const ExamesSemSolicitacaoField = (props: ExamesSemSolicitacaoFieldProps) => {
  const { name, cidadaoSexo, cidadaoIdentidadeGeneroEnum, cidadaoDataNascimento, formApi, changeValue } = props

  const theme = useTheme()
  const { getServerTimeNow } = useServerTime()

  const {
    input: { value },
  } = useField<ResultadosExamesNaoSolicitadosModel>(resolveName(name), { subscription: { value: true } })

  const items = Object.entries(value).sort(([, a], [, b]) => b.createdAt - a.createdAt)

  const push = useCallback(
    (item: Procedimento) => {
      changeValue(name, (prevValue) => ({
        ...prevValue,
        [uuidv4()]: {
          exame: item,
          createdAt: getServerTimeNow().valueOf(),
        } as ResultadoExameSemSolicitacaoModel & ResultadoExameEspecificoModel,
      }))
    },
    [changeValue, getServerTimeNow, name]
  )

  const remove = useCallback(
    (id: string) => {
      changeValue(name, (prevValue) => omit(prevValue, id))
    },
    [changeValue, name]
  )

  return (
    <VFlow vSpacing={0.25}>
      <HFlow hSpacing={0.25} alignItems='center'>
        <Heading level={5}>Adicionar exame sem solicitação</Heading>

        <Tooltip text='Insira exames NÃO solicitados nesta instalação do e-SUS APS PEC' placement='right'>
          <Icon
            icon='infoCircleFilled'
            fill='normal'
            size={1}
            style={css`
              &:hover {
                fill: ${theme.pallete.primary.main};
              }
            `}
          />
        </Tooltip>
      </HFlow>

      <VFlow>
        <ExamesSelect
          sexo={cidadaoSexo}
          identidadeGeneroDbEnum={cidadaoIdentidadeGeneroEnum}
          dataNascimento={cidadaoDataNascimento}
          placeholder='Pesquise por exame para inserir o resultado'
          onChange={(item) => push(item)}
        />

        {items?.map(([id, item]: [string, ResultadoExameSemSolicitacaoModel & ResultadoExameEspecificoModel]) => {
          const itemName = name[id]
          const itemValue = value?.[id]
          const status = getCardResultadoExameStatus(
            itemValue,
            !isObjectDeepEmpty(getFormGroupErrors(itemName, formApi))
          )
          const ignoringTouchedAndDirtyStatus = getCardResultadoExameStatus(
            itemValue,
            !isObjectDeepEmpty(getFormGroupErrors(itemName, formApi, true))
          )

          return (
            <ResultadoExameSemSolicitacaoField
              key={id}
              name={itemName}
              exame={item.exame}
              value={itemValue}
              onRemove={() => remove(id)}
              status={status}
              ignoringTouchedAndDirtyStatus={ignoringTouchedAndDirtyStatus}
            />
          )
        })}
      </VFlow>
    </VFlow>
  )
}
