/** @jsx jsx */
import { css, SerializedStyles } from '@emotion/core'
import { blue, gray, green, pink, purple } from 'bold-ui/lib/styles/colors'
import { StatusAtendimento } from 'graphql/types.generated'

export interface StatusAtendimentoType {
  styleBox: SerializedStyles
  styleBorder: SerializedStyles
  descricao: string
}

export const statusAtendimentoStyles = {
  aguardandoAtendimentoBox: css`
    background-image: linear-gradient(to top, ${green.c50}, ${green.c70});
  `,
  aguardandoAtendimentoBorder: css`
    border-bottom-color: ${green.c50};
    border-bottom-width: 2px;
  `,
  emAtendimentoBox: css`
    background-image: linear-gradient(to top, ${purple.c20}, ${pink.c30});
  `,
  emAtendimentoBorder: css`
    border-bottom-color: ${purple.c20};
    border-bottom-width: 2px;
  `,
  emEscutaInicialBox: css`
    background-image: linear-gradient(to top, ${pink.c40}, ${pink.c60});
  `,
  emEscutaInicialBorder: css`
    border-bottom-color: ${pink.c40};
    border-bottom-width: 2px;
  `,
  atendimentoRealizadoBox: css`
    background-image: linear-gradient(to top, ${blue.c20}, ${blue.c40});
  `,
  atendimentoRealizadoBorder: css`
    border-bottom-color: ${blue.c20};
    border-bottom-width: 2px;
  `,
  naoAguardouBox: css`
    background-image: linear-gradient(to top, ${gray.c70}, ${gray.c80});
  `,
  naoAguardouBorder: css`
    border-bottom-color: ${gray.c70};
    border-bottom-width: 2px;
  `,
}

export const listaAtendimentoStylesRecord: Record<StatusAtendimento, StatusAtendimentoType> = {
  [StatusAtendimento.AGUARDANDO_ATENDIMENTO]: {
    styleBox: statusAtendimentoStyles.aguardandoAtendimentoBox,
    styleBorder: statusAtendimentoStyles.aguardandoAtendimentoBorder,
    descricao: 'Aguardando atendimento',
  },
  [StatusAtendimento.ATENDIMENTO_REALIZADO]: {
    styleBox: statusAtendimentoStyles.atendimentoRealizadoBox,
    styleBorder: statusAtendimentoStyles.atendimentoRealizadoBorder,
    descricao: 'Atendimento realizado',
  },
  [StatusAtendimento.EM_ATENDIMENTO]: {
    styleBox: statusAtendimentoStyles.emAtendimentoBox,
    styleBorder: statusAtendimentoStyles.emAtendimentoBorder,
    descricao: 'Em atendimento',
  },
  [StatusAtendimento.EM_ESCUTA_INICIAL]: {
    styleBox: statusAtendimentoStyles.emEscutaInicialBox,
    styleBorder: statusAtendimentoStyles.emEscutaInicialBorder,
    descricao: 'Em escuta inicial',
  },
  [StatusAtendimento.NAO_AGUARDOU]: {
    styleBox: statusAtendimentoStyles.naoAguardouBox,
    styleBorder: statusAtendimentoStyles.naoAguardouBorder,
    descricao: 'Não aguardou',
  },
}
