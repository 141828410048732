import { HFlow, VFlow } from 'bold-ui'
import { DateField, ErrorField } from 'components/form'
import { SemanasDiasField } from 'components/form/field/SemanasDiasField'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import { ResultadoEspecificoPrenatal } from '../../model'

interface ResultadoExamePrenatalFieldProps {
  name: MetaPath<ResultadoEspecificoPrenatal>
  dataSolicitacao?: Date
}

export const ResultadoExamePrenatalField = (props: ResultadoExamePrenatalFieldProps) => {
  const { name, dataSolicitacao } = props

  return (
    <VFlow vSpacing={0.5}>
      <HFlow hSpacing={1.5}>
        <SemanasDiasField name={name.idadeGestacional} label='Idade gestacional eco' />

        <DateField name={name.dpp} label='DPP eco' minDate={dataSolicitacao} />
      </HFlow>

      <ErrorField name={name.error} />
    </VFlow>
  )
}
