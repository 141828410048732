import React, { Dispatch } from 'react'
import { TipoAtendimentoFooter } from 'view/atendimentos/model'

import { AtendimentoFooter } from '../AtendimentoFooter'
import { CidadaoDetailContent } from './CidadaoDetailContent'

interface CidadaoDetailViewProps {
  atendimentoId: ID
  cidadaoId: ID
  tipoAtendimentoProfissional?: TipoAtendimentoFooter
  registroTardio: boolean
  clearCache: Dispatch<void>
  callbackUrl?: string
}

export function CidadaoDetailView(props: CidadaoDetailViewProps) {
  const { atendimentoId, callbackUrl, cidadaoId, clearCache, tipoAtendimentoProfissional, registroTardio } = props

  return (
    <>
      <CidadaoDetailContent atendimentoId={atendimentoId} cidadaoId={cidadaoId} callbackUrl={callbackUrl} />
      <AtendimentoFooter
        atendimentoId={atendimentoId}
        tipoAtendimentoProfissional={tipoAtendimentoProfissional}
        clearCache={clearCache}
        registroTardio={registroTardio}
      />
    </>
  )
}
