import { differenceInDays } from 'date-fns'
import moment, { Moment } from 'moment'
import { PeriodoGestacaoModel } from 'view/atendimentos/detail/components/modals/types/PeriodoGestacaoModel'

export function calculateIdadeGestacionalParaGraficos(
  gestacoesPeriodo: PeriodoGestacaoModel[],
  data: Moment = moment()
): { idadeGestacional: number; hasGestacaoPeriodo: boolean } {
  const gestacao = gestacoesPeriodo.find((g) =>
    /**
     * granularity?: null //void - not to affect the comparison between dates
     * inclusivity?: '[]' //fully inclusive - to include the 42nd week fully
     */
    data.isBetween(moment(g.inicio), g.fim ? moment(g.fim) : moment(g.inicio).add(42, 'w'), null, '[]')
  )
  return { idadeGestacional: Math.floor(data.diff(moment(gestacao?.inicio), 'w')), hasGestacaoPeriodo: !!gestacao }
}

interface IdadeGestacional {
  emSemanas: {
    semanas: number
    dias: number
  }
  emDias: number
  hasIdadeGestacional: boolean
}

export function calculateIdadeGestacional(dataInicioGestacao: Date, dataConsulta: Date | number): IdadeGestacional {
  if (dataInicioGestacao) {
    const igTotal = differenceInDays(dataConsulta, dataInicioGestacao)
    const semanas = Math.trunc(igTotal / 7)
    const dias = igTotal % 7
    return { emSemanas: { semanas, dias }, emDias: igTotal, hasIdadeGestacional: true }
  }
  return { emSemanas: { semanas: null, dias: null }, emDias: null, hasIdadeGestacional: false }
}
