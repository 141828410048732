/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Heading, HFlow, Icon, isEmpty, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { resolveName } from 'components/form/final-form/useField'
import { differenceInMonths } from 'date-fns'
import { useGruposExamesQuery } from 'graphql/hooks.generated'
import { GrupoExame, IdentidadeGeneroEnum, Procedimento, SexoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { isEqual, isNil } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useField, useForm } from 'react-final-form'
import { toDate } from 'util/date/formatDate'
import { metaPath } from 'util/metaPath'
import { v4 as uuidv4 } from 'uuid'

import { SolicitacaoExameModel } from '../../model'
import { ExameListSelectFieldModel } from '../ExameListSelectField'
import { GrupoExamePopperButton } from './GrupoExamePopperButton'

const name = metaPath<SolicitacaoExameModel>().examesSolicitados

interface GrupoExamesSidebarProps {
  dataNascimento: LocalDate
  sexo: SexoEnum
  identidadeGenero: IdentidadeGeneroEnum
}

export const GrupoExamesSidebar = (props: GrupoExamesSidebarProps) => {
  const { dataNascimento, sexo, identidadeGenero } = props

  const theme = useTheme()
  const classes = styles(theme)

  const { getServerTimeNow } = useServerTime()
  const idadeEmMeses = differenceInMonths(getServerTimeNow(), toDate(dataNascimento))

  const sexoCidadao = isEmpty(identidadeGenero) ? sexo : null

  const {
    data: { gruposExame },
  } = useGruposExamesQuery({
    variables: {
      input: { sexo: sexoCidadao, dataNascimento, pageParams: { size: 2000 } },
    },
  })

  const items = useMemo(() => orderAndFilter(gruposExame?.content, idadeEmMeses, sexoCidadao), [
    gruposExame,
    idadeEmMeses,
    sexoCidadao,
  ])

  const {
    input: { value },
  } = useField(resolveName(name), { subscription: { value: true } })

  const {
    mutators: { changeValue },
  } = useForm()

  const addItem = useCallback(
    (values) => {
      changeValue(name, (prevValue) => [...(prevValue || []), { ...values, _id: uuidv4() }])
    },
    [changeValue]
  )

  const removeItem = useCallback(
    (values) => {
      changeValue(name, (prevValue) => (prevValue || []).filter((item) => item._id !== values._id))
    },
    [changeValue]
  )

  const checkAllItems = useCallback(
    (exames: Procedimento[], isAllSelected: boolean) => {
      changeValue(name, (prevValues: ExameListSelectFieldModel[]): ExameListSelectFieldModel[] => {
        if (isAllSelected) {
          const newValuesIds = exames ? exames.map((exame) => exame.id) : []

          return (prevValues || []).filter((valueItem) => !newValuesIds.includes(valueItem.exame.id))
        } else {
          const prevValuesIds = prevValues ? prevValues.map(({ exame }) => exame.id) : []
          const newValues = exames.filter((exame) => !prevValuesIds.includes(exame.id))

          return [...(prevValues || []), ...newValues.map((exame) => ({ exame, _id: uuidv4() }))]
        }
      })
    },
    [changeValue]
  )

  return (
    <div css={classes.sidebar}>
      <VFlow vSpacing={0.5}>
        <HFlow hSpacing={0.25} alignItems='center'>
          <Heading level={5}>Grupo de exames</Heading>

          <Tooltip text='Os grupos de exames podem ser adicionados pelo Administrador municipal.' placement='top'>
            <Icon icon='infoCircleFilled' fill='normal' size={1} style={classes.tooltipIcon} />
          </Tooltip>
        </HFlow>

        {items.map((grupoExame) => (
          <GrupoExamePopperButton
            key={grupoExame.id}
            grupoExame={grupoExame}
            examesSelecionados={value}
            addItem={addItem}
            removeItem={removeItem}
            checkAllItems={checkAllItems}
          />
        ))}
      </VFlow>
    </div>
  )
}

const styles = (theme: Theme) => ({
  sidebar: css`
    width: 17rem;
    background: ${theme.pallete.gray.c90};
    padding: 1rem;
    padding-top: 5.75rem;
    border-right: 1px solid ${theme.pallete.gray.c80};
  `,
  tooltipIcon: css`
    &: hover {
      fill: ${theme.pallete.primary.main};
    }
  `,
})

const orderAndFilter = (gruposExames: GrupoExame[], idadeEmMeses: number, sexoCidadao: SexoEnum): GrupoExame[] => {
  if (!gruposExames) return []

  return gruposExames
    .filter((grupoExame) => idadeEmMeses >= grupoExame.idadeMinima && idadeEmMeses <= grupoExame.idadeMaxima)
    .map((grupoExame) => ({
      ...grupoExame,
      procedimentos: grupoExame.procedimentos
        .filter(
          (procedimento) =>
            idadeEmMeses >= procedimento.idadeMinima &&
            idadeEmMeses <= procedimento.idadeMaxima &&
            (isNil(sexoCidadao) ||
              isEqual(SexoEnum.AMBOS, procedimento.sexo) ||
              isEqual(sexoCidadao, procedimento.sexo))
        )
        .sort((a, b) => a.descricao.localeCompare(b.descricao)),
    }))
    .filter((grupoExame) => !isEmpty(grupoExame.procedimentos))
    .sort((a, b) => a.nome.localeCompare(b.nome))
}
