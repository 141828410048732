import { Button } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { useAcessoConectesusRndsMutation } from 'graphql/hooks.generated'
import React from 'react'

interface ButtonAcessoRndsProps {
  cnsProfissional: string
  cnsCidadao: string
  cnes: string
  municipioId: Number
  rndsAtiva: Boolean
  conexaoAtiva: Boolean
}

export const ButtonAcessoRnds = (props: ButtonAcessoRndsProps) => {
  const { cnsProfissional, cnes, cnsCidadao, municipioId, rndsAtiva, conexaoAtiva } = props
  const [gerarLinkAcessoMutation] = useAcessoConectesusRndsMutation()
  const handleRejection = useErrorHandler()
  const onClickRnds = () => {
    gerarLinkAcessoMutation({
      variables: {
        input: {
          cnes: cnes,
          cnsCidadao: cnsCidadao,
          cnsProfissional: cnsProfissional,
          municipio: municipioId,
        },
      },
    })
      .then((result) => {
        window.location.href = result.data.acessoConecteSus
      })
      .catch(handleRejection)
  }

  return (
    <>
      {cnsCidadao && rndsAtiva && conexaoAtiva && (
        <Button kind='primary' size='medium' onClick={onClickRnds}>
          Acessar RNDS
        </Button>
      )}
    </>
  )
}
