import { Button, DropdownDivider, DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacao } from 'components/auth/useAcessoLotacao'
import { DropdownButton } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import useFirebase from 'components/firebase/useFirebase'
import { confirm } from 'components/modals/confirm'
import {
  useAtenderMutation,
  useCancelarAusenciaMutation,
  useExcluirAtendimentoMutation,
  useInformarAusenciaMutation,
  useRealizarAtendimentoVacinacaoMutation,
  useRealizarEscutaInicialMutation,
  useSalvarJustificativaAutomaticaVisualizarEscutaInicialMutation,
  useSalvarJustificativaAutomaticaVisualizarProntuarioMutation,
} from 'graphql/hooks.generated'
import { AtendimentoActions, StatusAtendimento } from 'graphql/types.generated'
import React from 'react'
import { useHistory } from 'react-router'
import Permissions from 'types/Permissions'
import { useJustificativaVerProntuarioId } from 'view/prontuario/justificativa/useJustificativaVerProntuarioId'

export interface ListaAtendimentoItemActionsProps {
  actions: AtendimentoActions
  lotacaoId: ID
  atendId: ID
  cidadaoId: ID
  prontuarioId: ID
  statusAtendimento: StatusAtendimento
}

export default function ListaAtendimentoItemActions(props: ListaAtendimentoItemActionsProps) {
  const { actions, atendId, lotacaoId, cidadaoId, prontuarioId, statusAtendimento } = props
  const pathCidadao = `/cidadao/${cidadaoId}`
  const { id: idAcesso } = useAcessoLotacao()
  const { analytics } = useFirebase()
  const history = useHistory()
  const alert = useAlert()
  const [atender] = useAtenderMutation()
  const [realizarEscutaInicial] = useRealizarEscutaInicialMutation()
  const [vacinacao] = useRealizarAtendimentoVacinacaoMutation()
  const handleRejection = useErrorHandler()
  const [, setJustificativaVerProntuarioId] = useJustificativaVerProntuarioId(pathCidadao, cidadaoId)
  const [
    salvarJustificativaAutomaticaVisualizarProntuario,
  ] = useSalvarJustificativaAutomaticaVisualizarProntuarioMutation()
  const [
    salvarJustificativaAutomaticaVisualizarEscutaInicial,
  ] = useSalvarJustificativaAutomaticaVisualizarEscutaInicialMutation()
  const [informarAusencia] = useInformarAusenciaMutation()
  const [cancelarAusencia] = useCancelarAusenciaMutation()
  const [excluir] = useExcluirAtendimentoMutation()

  const onAtenderClick = () => {
    return atender({ variables: { atendimento: atendId } })
      .then((res) => {
        history.push(`/lista-atendimento/atendimento/${atendId}`)

        return res
      })
      .catch(handleRejection)
  }

  const onAtenderVacinacao = () => {
    return vacinacao({ variables: { atendimento: atendId } })
      .then(() => {
        history.push(`/lista-atendimento/atendimento-vacinacao/${atendId}`)
      })
      .catch(handleRejection)
  }

  const onEscutaInicialClick = () => {
    analytics.logEvent('realizar_escuta_inicial_EI')
    return realizarEscutaInicial({ variables: { atendimento: atendId } })
      .then((res) => {
        history.push(`/lista-atendimento/escuta-inicial/${atendId}`)
        return res
      })
      .catch(handleRejection)
  }

  const onVisualizarProntuarioClick = () => {
    return salvarJustificativaAutomaticaVisualizarProntuario({
      variables: { prontuarioId },
    })
      .then(({ data: { salvarJustificativaAutomaticaVisualizarProntuario: justificativaId } }) => {
        setJustificativaVerProntuarioId(justificativaId)
        history.push(`${pathCidadao}/folha-rosto`)
      })
      .catch(handleRejection)
  }

  const onInformarAusencia = () => {
    return informarAusencia({ variables: { atendimento: atendId } })
      .then((res) => {
        alert('success', 'Ausência do cidadão informada.')
      })
      .catch(handleRejection)
  }

  const onCancelarAusencia = () => {
    return cancelarAusencia({ variables: { atendimento: atendId } })
      .then((res) => {
        alert('success', 'Cidadão foi reinserido com sucesso.')
      })
      .catch(handleRejection)
  }

  const onGerarDeclaracao = () => {
    return history.push(`/lista-atendimento/declaracaoComparecimento/${atendId}?callbackUrl=lista-atendimento`)
  }

  const onVisualizarAtendimentosDia = () => {
    return history.push(`/lista-atendimento/atendimentoDoDia/${atendId}`)
  }

  const onVisualizarEscutaInicialClick = () => {
    analytics.logEvent('visualizar_escuta_inicial_EI')
    return salvarJustificativaAutomaticaVisualizarEscutaInicial({
      variables: { prontuarioId },
    })
      .then(() => {
        history.push(`/lista-atendimento/visualizar-escuta/${atendId}`)
      })
      .catch(handleRejection)
  }

  const onEditarClick = () => {
    return history.push(`/lista-atendimento/editar/${atendId}`)
  }

  const onExcluirClick = () => {
    confirm({
      title: 'Deseja realmente excluir o registro de atendimento?',
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        excluir({ variables: { atendimento: atendId } })
          .then((res) => {
            alert('success', 'Registro excluído com sucesso.')
            analytics.logEvent('excluir_cidadao_LA')
          })
          .catch(handleRejection)
      },
    })()
  }

  return (
    <HFlow hSpacing={0.25} style={{ marginRight: '0.5rem', marginLeft: 'auto' }}>
      {actions.escutaInicial ? (
        <CheckPermission
          permission={Permissions.visualizarListaDeAtendimento.visualizarEscutaInicial.realizarEscutaInicial}
        >
          <Tooltip text={actions.escutaInicial?.hint}>
            <Button
              size='small'
              kind='normal'
              skin='ghost'
              onClick={onEscutaInicialClick}
              disabled={!actions.escutaInicial?.enabled}
            >
              <Icon icon='nurseOutline' />
            </Button>
          </Tooltip>
        </CheckPermission>
      ) : (
        <CheckPermission permission={Permissions.visualizarListaDeAtendimento.visualizarEscutaInicial}>
          <Tooltip text={actions.visualizarEscutaInicial?.hint}>
            <Button
              size='small'
              kind='normal'
              skin='ghost'
              onClick={onVisualizarEscutaInicialClick}
              disabled={!actions.visualizarEscutaInicial?.enabled}
            >
              <Icon icon='nurseVisualizationOutline' />
            </Button>
          </Tooltip>
        </CheckPermission>
      )}

      {actions.vacinacao && (
        <Tooltip text={actions.vacinacao.hint}>
          <Button
            size='small'
            kind='normal'
            skin='ghost'
            onClick={onAtenderVacinacao}
            disabled={!actions.vacinacao.enabled}
          >
            <Icon icon='needleOutline' />
          </Button>
        </Tooltip>
      )}

      {!actions.vacinacao && actions.atender && (
        <Tooltip text={actions.atender.hint}>
          <Button size='small' kind='normal' skin='ghost' onClick={onAtenderClick} disabled={!actions.atender.enabled}>
            <Icon icon='multipleUsersOutline' />
          </Button>
        </Tooltip>
      )}

      <Tooltip text='Mais opções'>
        <DropdownButton icon='dots'>
          {actions.vacinacao && actions.atender && (
            <Tooltip text={!actions.atender.enabled && actions.atender?.hint}>
              <DropdownItem onClick={onAtenderClick} disabled={!actions.atender.enabled}>
                {statusAtendimento === 'EM_ATENDIMENTO' && !actions.vacinacao.enabled && lotacaoId === idAcesso
                  ? 'Continuar atendimento'
                  : 'Realizar atendimento'}
              </DropdownItem>
            </Tooltip>
          )}

          <CheckPermission permission={Permissions.visualizarListaDeAtendimento.informarQueCidadaoNaoAguardou}>
            {actions.cancelarAusencia ? (
              <Tooltip text={actions.cancelarAusencia?.hint}>
                <DropdownItem disabled={!actions.cancelarAusencia?.enabled} onClick={onCancelarAusencia}>
                  Cidadão retornou
                </DropdownItem>
              </Tooltip>
            ) : (
              <Tooltip text={actions.informarAusencia?.hint}>
                <DropdownItem disabled={!actions.informarAusencia?.enabled} onClick={onInformarAusencia}>
                  Cidadão não aguardou
                </DropdownItem>
              </Tooltip>
            )}
          </CheckPermission>

          {actions.declaracaoComparecimento && (
            <DropdownItem disabled={!actions.declaracaoComparecimento.enabled} onClick={onGerarDeclaracao}>
              Gerar declaração de comparecimento
            </DropdownItem>
          )}

          {actions.visualizarProntuario && (
            <DropdownItem disabled={!actions.visualizarProntuario.enabled} onClick={onVisualizarProntuarioClick}>
              Visualizar prontuário
            </DropdownItem>
          )}

          {actions.atendimentosDia && (
            <Tooltip text={actions.atendimentosDia?.hint}>
              <DropdownItem disabled={!actions.atendimentosDia.enabled} onClick={onVisualizarAtendimentosDia}>
                Visualizar atendimentos do dia
              </DropdownItem>
            </Tooltip>
          )}

          {actions.editar && (
            <Tooltip text={actions.editar?.hint}>
              <DropdownItem disabled={!actions.editar.enabled} onClick={onEditarClick}>
                Editar
              </DropdownItem>
            </Tooltip>
          )}

          {actions.excluir && (
            <>
              <DropdownDivider />

              <Tooltip text={actions.excluir?.hint}>
                <DropdownItem type='danger' disabled={!actions.excluir.enabled} onClick={onExcluirClick}>
                  <HFlow alignItems='center' hSpacing={0.5}>
                    <Icon icon='trashOutline' />
                    Excluir
                  </HFlow>
                </DropdownItem>
              </Tooltip>
            </>
          )}
        </DropdownButton>
      </Tooltip>
    </HFlow>
  )
}
