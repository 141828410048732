import { Chart, ChartBody, ChartContainer, DateRange, SeriesType } from 'components/chart'
import moment, { Moment } from 'moment'
import React from 'react'
import { calculateIdadeGestacionalParaGraficos } from 'util/atendimento/gestante/calculateIdadeGestacional'
import { filterGestacoesPeriodo } from 'util/atendimento/gestante/filterGestacoesPeriodo'
import { parseNumber } from 'util/number'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { LinhaTooltipGrafico } from '../../../LinhaTooltipGrafico'
import { PeriodoGestacaoModel } from '../../types/PeriodoGestacaoModel'

export interface GraficoPressaoArterialProps {
  medicoes: HistoricoMedicaoModel[]
  gestacoes: PeriodoGestacaoModel[]
  dateRange: DateRange
}

export function GraficoPressaoArterial(props: GraficoPressaoArterialProps) {
  const { dateRange, medicoes, gestacoes } = props

  const medicoesComPressaoArterial = medicoes?.filter((m) => m.valorPressaoArterial) ?? []

  const seriesDataSistolica = medicoesComPressaoArterial.map((m) => ({
    x: m.dataMedicao,
    y: parseNumber(m.valorPressaoArterial.split('/')[0]),
  }))

  const seriesDataDiastolica = medicoesComPressaoArterial.map((m) => ({
    x: m.dataMedicao,
    y: parseNumber(m.valorPressaoArterial.split('/')[1]),
  }))

  const gestacoesPeriodo = filterGestacoesPeriodo(gestacoes, dateRange.init, dateRange.end)

  return (
    <ChartContainer>
      <ChartBody height={500}>
        <Chart<Moment>
          type={SeriesType.Line}
          height={600}
          width={750}
          series={[
            { name: 'Pressão sistólica', data: seriesDataSistolica },
            { name: 'Pressão diastólica', data: seriesDataDiastolica, dot: 'square' },
          ]}
          rangeAreas={gestacoesPeriodo.map((g) => ({
            name: 'Gestação',
            init: moment(g.inicio).startOf('day'),
            end: moment(g.fim ?? moment(g.inicio).add(42, 'week')).endOf('day'),
          }))}
          xAxis={{
            title: 'Dia',
            domain: { ...dateRange, format: 'DD' },
            tickRenderer: (props) => {
              return (
                <>
                  {props.payload.value !== dateRange.end.valueOf() && (
                    <text dy={15} {...props}>
                      {moment(props.payload.value).date()}
                    </text>
                  )}
                </>
              )
            },
          }}
          yAxis={{ title: 'Pressão', unit: 'mmHg', domain: { init: 50, end: 250, step: 10 } }}
          tooltip={{
            type: 'line',
            render: (points) => {
              if (!points || !points.length) return
              const momentX = moment(points[0].x)
              const { idadeGestacional, hasGestacaoPeriodo } = calculateIdadeGestacionalParaGraficos(
                gestacoesPeriodo,
                momentX
              )
              return (
                <>
                  {points?.map((p) => (
                    <LinhaTooltipGrafico key={p.y}>
                      {`${p.seriesName === 'Pressão sistólica' ? 'PAS' : 'PAD'}: ${p.y} mmHg`}
                    </LinhaTooltipGrafico>
                  ))}
                  {hasGestacaoPeriodo && (
                    <LinhaTooltipGrafico>
                      {`IG: ${idadeGestacional} ${idadeGestacional === 1 ? 'semana' : 'semanas'}`}
                    </LinhaTooltipGrafico>
                  )}
                  <LinhaTooltipGrafico>{`Data: ${momentX.format('DD/MM/YYYY[ | ]HH:mm')}`}</LinhaTooltipGrafico>
                </>
              )
            },
          }}
        />
      </ChartBody>
    </ChartContainer>
  )
}
