/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Cell, colors, Grid, HeadingSection, Theme, useTheme, VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { Fragment } from 'react'
import { useField } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { AlteracaoFenotipicaView } from './alteracao-fenotipica/AlteracaoFenotipicaView'
import PuericulturaAlert from './components/PuericulturaAlert'
import { FatoresRiscoView } from './fatores-risco/FatoresRiscoView'
import { MarcoFormSectionAccordion } from './marcos/MarcoFormSectionAccordion'
import MarcosDesenvolvimentoView from './marcos/MarcosDesenvolvimentoView'
import { PuericulturaModel } from './model'
import TipoAleitamentoField from './prenatal-parto-nascimento/components/TipoAleitamentoField'
import PrenatalPartoNascimento from './prenatal-parto-nascimento/PrenatalPartoNascimento'

export interface PuericulturaViewProps {
  name: MetaPath<PuericulturaModel>
  cidadao: CidadaoAtendimento
  loading: boolean
}

const LIMITE_IDADE_AVALIACAO_DESENVOLVIMENTO = 10

export default function PuericulturaView(props: PuericulturaViewProps) {
  const { name, cidadao, loading } = props
  const theme = useTheme()
  const classes = createStyles(theme)

  const {
    atendimentoProfissional: { iniciadoEm: dataAtendimento },
    cidadao: { idadeEmAnos },
  } = useAtendimentoContext()

  const acessoCriancaAte2Anos = idadeEmAnos < 2
  const acessoAvaliarDesenvolvimento = idadeEmAnos < LIMITE_IDADE_AVALIACAO_DESENVOLVIMENTO

  const {
    input: {
      value: {
        hasPuericultura,
        alteracoesFenotipicas,
        marcosDesenvolvimento,
        fatoresRisco,
        medicaoAnteriorPerimetroCefalico,
      },
    },
  } = useField<PuericulturaModel>(name.absolutePath())

  if (loading) return <LoadingIndicator />

  return (
    <VFlow style={classes.container}>
      <PrenatalPartoNascimento name={name.prenatalPartoNascimento} hasAtendimentoPuericultura={hasPuericultura} />
      {acessoCriancaAte2Anos && <TipoAleitamentoField name={name.tipoAleitamento} />}
      {acessoAvaliarDesenvolvimento ? (
        <Grid style={classes.border}>
          <Cell size={12}>
            <Fragment>
              <HeadingSection level={3} title='Desenvolvimento da criança' />
              <VFlow>
                <PuericulturaAlert
                  alteracoesFenotipicas={alteracoesFenotipicas?.alteracoesFenotipicas}
                  fatoresRisco={fatoresRisco?.fatoresRisco}
                  marcosDesenvolvimento={marcosDesenvolvimento}
                  medicaoAnteriorPerimetroCefalico={medicaoAnteriorPerimetroCefalico}
                  cidadao={cidadao}
                  dataReferencia={dataAtendimento.getTime()}
                />
                <AlteracaoFenotipicaView name={name.alteracoesFenotipicas} />
                <FatoresRiscoView name={name.fatoresRisco} />
                <MarcosDesenvolvimentoView>
                  <MarcoFormSectionAccordion name={name.marcosDesenvolvimento} />
                </MarcosDesenvolvimentoView>
              </VFlow>
            </Fragment>
          </Cell>
        </Grid>
      ) : (
        <Alert type='info' inline>
          Após os 10 anos de idade, as informações de desenvolvimento da criança permanecem somente no acompanhamento da
          criança.
        </Alert>
      )}
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  border: css`
    border-top: 1px solid ${theme.pallete.divider};
  `,
  container: css`
    margin-top: 0.5rem;
    padding: 1rem;
    border: 1px ${colors.turquoise.c60} solid;
    border-radius: ${theme.radius.input}px;
  `,
})
