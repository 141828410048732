import { Cell, Grid, PagedTable } from 'bold-ui'
import { ChartMonthSelector, DateRange } from 'components/chart'
import { TableBox } from 'components/table/TableBox'
import { usePagedTableProps } from 'components/table/usePagedTableProps'
import { PageParams } from 'graphql/types.generated'
import moment from 'moment'
import React, { useState } from 'react'
import { tipoGlicemiaRecord } from 'types/enums'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/HistoricoMedicaoModel'

import { PeriodoGestacaoModel } from '../types/PeriodoGestacaoModel'
import { GraficoGlicemiaView } from './graficos-glicemia/GraficoGlicemiaView'
import { renderDataHoraMedicao, renderMedicao } from './renderMedicao'

const MEDICOES_GLICEMIA: any[] = ['valorGlicemia']

export interface HistoricoMedicoesGlicemiaViewProps {
  medicoes: HistoricoMedicaoModel[]
  gestacoes: PeriodoGestacaoModel[]
}

export function HistoricoMedicoesGlicemiaView(props: HistoricoMedicoesGlicemiaViewProps) {
  const { medicoes, gestacoes } = props

  const [tableState, setTableState] = useState<{ pageParams?: PageParams }>({ pageParams: { page: 0, size: 5 } })
  const [dateRange, setDateRange] = useState<DateRange>({
    init: moment().startOf('month').startOf('day'),
    end: moment().endOf('month'),
  })

  const medicoesComGlicemia = (medicoes ?? []).filter((med) => MEDICOES_GLICEMIA.find((e) => med[e]))
  const { page: currentPage, size: itensPerPage } = tableState.pageParams
  const totalElements = medicoesComGlicemia?.length ?? 0
  const totalPages = Math.ceil(totalElements / itensPerPage)
  const content = medicoesComGlicemia?.slice(currentPage * itensPerPage, (currentPage + 1) * itensPerPage) ?? []
  const medicoesPage = {
    content: content,
    pageInfo: {
      number: currentPage,
      size: itensPerPage,
      totalPages: totalPages,
      totalElements: medicoesComGlicemia.length,
      first: currentPage === 0,
      last: currentPage === totalPages - 1,
      numberOfElements: content.length,
    },
  }

  const tableProps = usePagedTableProps({
    loading: false,
    onChange: setTableState,
    result: medicoesPage,
  })

  return (
    <Grid>
      <Cell size={12}>
        <TableBox>
          <PagedTable<HistoricoMedicaoModel>
            {...tableProps}
            columns={[
              {
                header: 'Data da medição',
                name: 'dataMedicao',
                render: renderDataHoraMedicao,
              },
              {
                header: 'Glicemia capilar (mg/dL)',
                name: 'valorGlicemia',
                render: renderMedicao((med) => med.valorGlicemia),
              },
              {
                header: 'Momento da coleta',
                name: 'tipoGlicemia',
                render: renderMedicao((med) => tipoGlicemiaRecord[med.tipoGlicemia]),
              },
            ]}
          />
        </TableBox>
      </Cell>

      <Cell size={12}>
        <ChartMonthSelector onChange={setDateRange} />
      </Cell>

      <Cell size={12}>
        <GraficoGlicemiaView {...{ medicoesComGlicemia, dateRange, gestacoes }} />
      </Cell>
    </Grid>
  )
}
