import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import { SolicitacaoExame } from 'graphql/types.generated'
import { LotacaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { SolicitacaoExameQueryModel } from '../../objetivo/resultados-exames/model'
import { ItemHistoricoSolicitacaoExameModel, SolicitacaoExameMergedModel, SolicitacaoExameModel } from './model'

export const convertSolicitacaoFromQueryToSolicitacaoWithInfo = (
  solicitacoesExame: SolicitacaoExameQueryModel[]
): ItemHistoricoSolicitacaoExameModel[] => {
  return !!solicitacoesExame
    ? solicitacoesExame.map((solicitacao: SolicitacaoExame) => {
        return {
          id: solicitacao.id,
          solicitacao: {
            examesSolicitados: solicitacao.exames,
            justificativa: solicitacao.justificativa,
            tipoExame: solicitacao.tipoExame,
            cid10: solicitacao.cid10 as CidSelectFieldModel,
            observacao: solicitacao.observacoes,
          } as SolicitacaoExameMergedModel,
          data: solicitacao.data,
          atendimentoProfissionalId: solicitacao.atendimentoProfissional.id,
          lotacao: solicitacao.atendimentoProfissional.lotacao,
          adicionadoAgora: false,
          title: solicitacao.exames.map((exame) => exame.exame.descricao.capitalize()).join(' | '),
          codigos: solicitacao.exames.map((exame) => exame.exame.codigo).join(' '),
        }
      })
    : []
}

export const convertSolicitacaoFromAtendToSolicitacaoWithInfo = (
  solicitacoesExame: SolicitacaoExameModel[],
  {
    dataReferencia,
    atendimentoProfissionalId,
    lotacao,
  }: { dataReferencia: Instant; atendimentoProfissionalId: ID; lotacao: LotacaoAtendimento | LotacaoFolhaRosto }
): ItemHistoricoSolicitacaoExameModel[] => {
  return !!solicitacoesExame
    ? solicitacoesExame.map((solicitacao: SolicitacaoExameModel) => {
        return {
          id: solicitacao._id,
          solicitacao: {
            ...solicitacao,
            examesSolicitados: solicitacao.examesSolicitados.map((exameSolicitado) => {
              return {
                ...exameSolicitado,
                id: exameSolicitado._id,
                conferido: false,
              }
            }),
          },
          atendimentoProfissionalId,
          lotacao,
          data: dataReferencia,
          adicionadoAgora: true,
          title: solicitacao.examesSolicitados.map((exame) => exame.exame.descricao.capitalize()).join(' | '),
          codigos: solicitacao.examesSolicitados.map((exame) => exame.exame.codigo).join(' '),
        }
      })
    : []
}
