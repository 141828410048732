/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, FormControl, VFlow } from 'bold-ui'
import { Form, FormRenderProps, TextAreaField } from 'components/form'
import {
  ProcedimentoOdontoSelectField,
  ProcedimentoOdontoSelectModel,
} from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { ParteBucalEnum } from 'graphql/types.generated'
import { useEffect, useState } from 'react'
import { metaPath } from 'util/metaPath'

import { MAX_LENGTH_OBS_ODONTO } from '../../../model'
import { getDentes, getDentesComDados, getParteBucal, isDenteDeciduo, possuiLesaoDeFurca } from '../../../util'
import CondicoesDenteField, { CondicoesDenteFieldModel } from '../components/CondicoesDenteField'
import OdontogramaPopper, { OdontogramaPopperProps } from '../components/OdontogramaPopper'
import ReplicarItemSelectField, {
  convertParteBucalEnumToReplicarItem,
  ReplicarItemSelectFieldModel,
} from '../components/ReplicarItemSelectField'
import { condicoesDeLesaoDeFurca } from '../model'
import { DenteFieldModel } from './DentesField'

export interface DenteFormPopperProps extends Omit<OdontogramaPopperProps, 'title' | 'onClose'> {
  parteBucalEnum: ParteBucalEnum
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
  dentesValues: Record<ParteBucalEnum, DenteFieldModel>
  protese?: boolean
  initialValues?: DenteFieldModel
  proteseTotalSuperior?: boolean
  proteseTotalInferior?: boolean
  onSubmit?(values: DenteFieldModel, form: FormApi<DenteFormModel>): void
}

export interface DenteFormModel extends DenteFieldModel {
  replicarItems?: ReplicarItemSelectFieldModel[]
}

const meta = metaPath<DenteFormModel>()

const denteFormDecorator = createDecorator(
  {
    field: meta.procedimentos.absolutePath(),
    updates: {
      [meta.observacoes.absolutePath()]: (value: ProcedimentoOdontoSelectModel[], allValues: DenteFormModel) => {
        if (value?.length > 0) return allValues.observacoes
      },
    },
  },
  {
    field: meta.condicoesDente.absolutePath(),
    updates: {
      [meta.replicarItems.absolutePath()]: (value: CondicoesDenteFieldModel[], allValues: DenteFormModel) => {
        if (value?.length > 0) return allValues.replicarItems
      },
    },
  }
)

export default function DenteFormPopper(props: DenteFormPopperProps) {
  const {
    parteBucalEnum,
    initialValues,
    cidadaoDataNascimento,
    dataAtendimento,
    protese,
    dentesValues,
    proteseTotalSuperior,
    proteseTotalInferior,
    onSubmit,
    ...popperProps
  } = props

  const [replicarItems, setReplicarItems] = useState<ReplicarItemSelectFieldModel[]>()
  const dente = getParteBucal(parteBucalEnum)

  useEffect(() => {
    setReplicarItems(
      convertParteBucalEnumToReplicarItem(getDentes()).filter(
        (item) => item.value !== parteBucalEnum && isDenteDeciduo(item.value) === isDenteDeciduo(parteBucalEnum)
      )
    )
  }, [parteBucalEnum])

  const renderForm = (formProps: FormRenderProps<DenteFormModel>) => {
    const temProcedimentos = !!formProps?.values?.procedimentos?.length
    const temCondicoes = !!formProps?.values?.condicoesDente?.length
    const itemsReplicarNaoPossuemLesaoDeFurca = formProps.values?.replicarItems
      ?.map((item) => !possuiLesaoDeFurca(item.value) && item.value)
      .filter((i) => !!i)

    return (
      <OdontogramaPopper title={`Dente - ${dente.number}`} onClose={formProps.handleSubmit} {...popperProps}>
        <VFlow>
          <CondicoesDenteField name={meta.condicoesDente} parteBucalEnum={parteBucalEnum} protese={protese} />

          <ReplicarItemSelectField
            label='Replicar problemas/condições para outros dentes'
            name={meta.replicarItems}
            items={replicarItems}
            itemsDisabled={getDentesComDados(dentesValues, proteseTotalSuperior, proteseTotalInferior)}
            disabled={!temCondicoes}
          />

          <VFlow vSpacing={0.5}>
            <ProcedimentoOdontoSelectField
              name={meta.procedimentos}
              label='Procedimentos (Evolução odontológica)'
              multiple
              dataNascimento={cidadaoDataNascimento}
              dataAtendimento={dataAtendimento}
              parteBucal={parteBucalEnum}
            />

            {condicoesDeLesaoDeFurca.some((item) => formProps.values?.condicoesDente?.includes(item)) &&
              !!itemsReplicarNaoPossuemLesaoDeFurca?.length && (
                <Alert
                  inline
                  type='info'
                  styles={{
                    wrapper: css`
                      width: 100%;
                    `,
                  }}
                >
                  Lesão de furca não será replicada para dente(s){' '}
                  {itemsReplicarNaoPossuemLesaoDeFurca
                    .map((item) => getParteBucal(item).number)
                    .sort((a, b) => (a > b ? 1 : -1))
                    .join(', ')}
                </Alert>
              )}
          </VFlow>

          <FormControl label='Observações dos procedimentos'>
            <TextAreaField
              name={meta.observacoes[0]}
              maxLength={MAX_LENGTH_OBS_ODONTO}
              placeholder='Insira informações adicionais sobre os procedimentos realizados'
              style={{ resize: 'none' }}
              disabled={!temProcedimentos}
            />
          </FormControl>
        </VFlow>
      </OdontogramaPopper>
    )
  }

  return (
    <Form<DenteFormModel>
      render={renderForm}
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={[denteFormDecorator]}
    />
  )
}
