/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { focusBoxShadow, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import useFirebase from 'components/firebase/useFirebase'
import { format } from 'date-fns'
import {
  OrigemAtendimento,
  SubtipoAtendimentoHistoricoClinico,
  TipoAtendimentoHistoricoClinico,
} from 'graphql/types.generated'
import { useMemo } from 'react'
import { AccordionItem, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion'

import { useHistoricoPrint } from '../../hooks/useHistoricoPrint'
import {
  accordionBloqueadoMensagem,
  CidadaoHistorico,
  HistoricoAtendimentoModel,
  IdadeOrigemAtendimento,
  tipoAtendimentoHistoricoData,
} from '../../model/historicoModel'
import HistoricoDetalhesPanel from './detail/HistoricoDetalhesPanel'
import HistoricoAccordionHeader from './header/HistoricoAccordionHeader'

export interface HistoricoAccordionProps {
  historicoAtendimento: HistoricoAtendimentoModel
  cidadao: CidadaoHistorico
  refetchOnListagem(): void

  checked: boolean
  onSelectToPrint?(checked: boolean, historico: HistoricoAtendimentoModel): void
  historicoPrintProps: ReturnType<typeof useHistoricoPrint>
}

export default function HistoricoAccordion(props: HistoricoAccordionProps) {
  const { historicoAtendimento, cidadao, refetchOnListagem, checked, onSelectToPrint, historicoPrintProps } = props
  const { analytics } = useFirebase()
  const theme = useTheme()
  const dataAtendimento = format(historicoAtendimento.dataAtendimento, 'yyyy-MM-dd')
  const tipoAtendimentoEnum = historicoAtendimento.tipoApresentacao ?? historicoAtendimento.tipoAtendProf
  const tipoAtendimentoData = tipoAtendimentoHistoricoData[tipoAtendimentoEnum.toString()]
  const classes = useMemo(() => styles(theme), [theme])
  const origemAtendimento: OrigemAtendimento = historicoAtendimento.origemAtendimento
  const idadeOrigemAtendimento: IdadeOrigemAtendimento = {
    dataAtendimento,
    dataNascimentoCidadao: cidadao.dataNascimento,
    origemAtendimento,
  }

  const subtipoAtendimento = tipoAtendimentoData?.tags?.find(
    (subtipo) => subtipo.id === historicoAtendimento.coSubtipoAtendimento
  )

  const isVisitaAusenteOuRecusada =
    tipoAtendimentoEnum === TipoAtendimentoHistoricoClinico.VISITA_DOMICILIAR &&
    (subtipoAtendimento.nome === SubtipoAtendimentoHistoricoClinico.CIDADAO_AUSENTE ||
      subtipoAtendimento.nome === SubtipoAtendimentoHistoricoClinico.VISITA_RECUSADA)

  return (
    <AccordionItem css={classes.accordionItem} dangerouslySetExpanded={isVisitaAusenteOuRecusada ? false : undefined}>
      <Tooltip
        text={
          isVisitaAusenteOuRecusada &&
          `${
            accordionBloqueadoMensagem[subtipoAtendimento.nome]
          } no momento da visita. Não há detalhes a serem exibidos`
        }
      >
        <span>
          <HistoricoAccordionHeader
            historicoAtendimento={historicoAtendimento}
            subtipoAtendimento={subtipoAtendimento}
            refetchOnListagem={refetchOnListagem}
            cidadao={cidadao}
            checked={checked}
            onSelectToPrint={onSelectToPrint}
            historicoPrintProps={historicoPrintProps}
          />
        </span>
      </Tooltip>
      <AccordionItemState>
        {({ expanded }) => {
          if (expanded) {
            analytics.logEvent('expand_accordion_historico')
            return (
              <AccordionItemPanel css={classes.accordionItemPanel}>
                <VFlow
                  vSpacing={1}
                  style={css`
                    padding: 1rem;
                    overflow-wrap: break-word;
                  `}
                >
                  <HistoricoDetalhesPanel
                    historicoAtendimento={historicoAtendimento}
                    idadeOrigemAtendimento={idadeOrigemAtendimento}
                    dataNascimento={cidadao.dataNascimento}
                  />
                </VFlow>
              </AccordionItemPanel>
            )
          }
          return null
        }}
      </AccordionItemState>
    </AccordionItem>
  )
}

const styles = (theme: Theme) => {
  return {
    accordionItem: css`
      border: 1px solid ${theme.pallete.gray.c60};
      border-radius: 2px;
      &:focus-within {
        outline: none;
        box-shadow: ${focusBoxShadow(theme, 'primary')};
        transition-property: box-shadow;
        transition-duration: 0.2s;
      }
    `,
    accordionItemPanel: css`
      flex: 1;
      background-color: ${theme.pallete.surface.main};
    `,
  }
}
