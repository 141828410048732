/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Heading, HFlow, Icon, TableFooter, Text, Tooltip, VFlow } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { useAlert } from 'components/alert'
import { DateTime } from 'components/date'
import { confirm } from 'components/modals/confirm'
import { ButtonLink } from 'components/route'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import { useHistoricoTecidosMolesQuery } from 'graphql/hooks.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { TextFilterType } from 'hooks/filter/model'
import { useFilter } from 'hooks/filter/useFilter'
import { isEmpty } from 'lodash'
import { Fragment, useState } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router'
import { metaPath } from 'util/metaPath'

import { showDescricaoCodigoProcedimento } from '../util'
import { CaracteristicasLesaoPanel } from './components/caracteristicas-lesao/CaracteristicasLesaoPanel'
import EditarTecidosMolesFormModal from './EditarTecidosMolesFormModal'
import { TecidosMolesTableItemModel, URL_EDITAR_TECIDOS_MOLES } from './model'
import { TecidosMolesFieldModel } from './TecidosMolesField'
import { TecidosMolesTableHeader } from './TecidosMolesTableHeader'
import TecidosMolesTableRow from './TecidosMolesTableRow'
import { joinTecidosMoles } from './util'

interface TecidosMolesTableProps {
  valoresAtuais?: TecidosMolesFieldModel[]
  cidadaoDataNascimento?: LocalDate
  dataAtendimento: LocalDate
  updateItem?(item: TecidosMolesFieldModel): void
  removeItem?(item: TecidosMolesFieldModel): void
}

interface TecidosMolesTableFilterModel {
  query: string
}

export function TecidosMolesTable(props: TecidosMolesTableProps) {
  const { valoresAtuais = [], cidadaoDataNascimento, dataAtendimento, updateItem, removeItem } = props

  const alert = useAlert()
  const match = useRouteMatch()
  const history = useHistory()

  const [filter, setFilter] = useState<TecidosMolesTableFilterModel>()

  const {
    prontuario: { id: prontuarioId },
    atendimentoProfissional: { iniciadoEm, lotacao },
  } = useAtendimentoContext()

  const {
    data: { tecidosMoles },
    loading,
  } = useHistoricoTecidosMolesQuery({
    variables: { input: { prontuarioId } },
  })

  const joinedItems = joinTecidosMoles(tecidosMoles?.content, valoresAtuais, lotacao, iniciadoEm.valueOf())

  const filteredItems = useFilter<TecidosMolesTableItemModel, TecidosMolesTableFilterModel>({
    items: joinedItems,
    filter,
    filtersType: [filterTextTypeConfig],
  })

  const { paginatedItems, tableProps } = usePagination<TecidosMolesTableItemModel>({ items: filteredItems })

  const renderActions = (item: TecidosMolesTableItemModel) =>
    item.isRegistradoAgora && (
      <HFlow hSpacing={0.5} justifyContent='flex-end'>
        <Tooltip text='Editar'>
          <ButtonLink
            size='small'
            skin='ghost'
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
            to={`${match.url}${URL_EDITAR_TECIDOS_MOLES}${item.cacheId}`}
          >
            <Icon icon='penOutline' />
          </ButtonLink>
        </Tooltip>

        <Tooltip text='Excluir'>
          <Button
            size='small'
            skin='ghost'
            onKeyDown={(e) => e.stopPropagation()}
            onClick={(e) => {
              e.stopPropagation()
              handleRemove(item.cacheId)
            }}
          >
            <Icon icon='trashOutline' />
          </Button>
        </Tooltip>
      </HFlow>
    )

  const onChangeFilter = (query: string) => setFilter({ query })

  const handleRemove = (id: ID) =>
    confirm({
      title: 'Deseja excluir o registro odontológico?',
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () => {
        const itemToRemove = valoresAtuais?.find((outro) => outro._id === id)
        removeItem(itemToRemove)
        alert('success', 'Registro odontológico excluído com sucesso')
      },
    })()

  return (
    (loading || !isEmpty(paginatedItems) || !!filter?.query) && (
      <Fragment>
        <VFlow vSpacing={0.5}>
          <Heading level={4}>Histórico de evoluções</Heading>

          <TableBox header={<TecidosMolesTableHeader onChangeFilter={onChangeFilter} />}>
            <AccordionDataTable<TecidosMolesTableItemModel>
              loading={loading}
              columns={[
                {
                  name: 'data',
                  header: 'Data',
                  render: renderData,
                  size: 2,
                  flexBasis: 112,
                },
                {
                  name: 'local',
                  header: 'Local',
                  render: (item) => <Text>{item.parteBucal}</Text>,
                  size: 2,
                },
                {
                  name: 'problemas',
                  header: 'CID 10',
                  render: (item) => <Text>{item.cid10 ? `${item.cid10.nome} - ${item.cid10.codigo}` : '-'}</Text>,
                  size: 3,
                  flexGrow: 1,
                },
                {
                  name: 'procedimentos',
                  header: 'Procedimentos',
                  render: renderProcedimentos,
                  size: 3,
                  flexGrow: 1,
                },
                {
                  name: 'actions',
                  render: renderActions,
                  size: 2,
                },
              ]}
              rows={paginatedItems}
              components={{
                Row: TecidosMolesTableRow,
                AccordionPanel: ({ row: { caracteristicasLesao, descricaoSintomatologia } }) => (
                  <CaracteristicasLesaoPanel
                    items={caracteristicasLesao}
                    descricaoSintomatologia={descricaoSintomatologia}
                  />
                ),
              }}
              disableRow={(row) => isEmpty(row.caracteristicasLesao)}
              disableRowMessage='Não foram inseridas características da lesão nesse registro'
            />
            <TableFooter {...tableProps} style={styles.tableFooter} />
          </TableBox>
        </VFlow>

        <Route path={`${match.url}${URL_EDITAR_TECIDOS_MOLES}:id`}>
          <EditarTecidosMolesFormModal
            evolucoesAtuais={valoresAtuais}
            cidadaoDataNascimento={cidadaoDataNascimento}
            dataAtendimento={dataAtendimento}
            onSubmit={updateItem}
            onClose={() => {
              history.push(match.url.split(URL_EDITAR_TECIDOS_MOLES)[0])
            }}
          />
        </Route>
      </Fragment>
    )
  )
}

const metaRow = metaPath<TecidosMolesTableItemModel>()
const metaFilter = metaPath<TecidosMolesTableFilterModel>()

const filterTextTypeConfig: TextFilterType = {
  filterField: metaFilter.query,
  searchFields: [
    metaRow.parteBucal,
    metaRow.cid10.nome,
    metaRow.cid10.codigo,
    metaRow.lotacao.profissional.nome,
    metaRow.lotacao.cbo.nome,
    metaRow.searchProcedimentos,
  ],
  removeTagsOnFilter: true,
}

const styles = {
  tableFooter: css`
    border-top: 0;
  `,
}

const renderData = (item: TecidosMolesTableItemModel) => (
  <HFlow hSpacing={0.25}>
    <DateTime value={item.lastUpdate} format='DD/MM/YYYY' />

    {item.isRegistradoAgora && (
      <Tooltip text='Adicionado agora'>
        <Icon size={1} icon='clockOutline' fill='primary' />
      </Tooltip>
    )}
  </HFlow>
)

const renderProcedimentos = (item: TecidosMolesTableItemModel) =>
  !!item.procedimentos?.length ? (
    <VFlow vSpacing={0}>
      {item.procedimentos.map((proced) => (
        <Text key={proced.id}>{showDescricaoCodigoProcedimento(proced)}</Text>
      ))}
    </VFlow>
  ) : (
    <Text>-</Text>
  )
