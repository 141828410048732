import {
  AntecedenteCirurgiaInternacaoInput,
  AntecedentesInput,
  FamiliarAntecedentesInput,
  InformacoesObstetricasInput,
  PessoalAntecedentesInput,
  Problema,
} from 'graphql/types.generated'
import { parseNumber } from 'util/number'
import { v4 as uuidv4 } from 'uuid'

import { ProblemaCondicaoModel } from '../avaliacao/components/problemas-condicoes/model'
import { convertPrenatalPartoNascimento } from '../objetivo/puericultura/converter'
import { AntecedentesFormModel } from './AntecedentesForm'
import { InformacoesObstetricasPanelModel } from './InformacoesObstetricasPanel'
import { CirurgiaInternacaoModel, FamiliarAntecedentesModel, PessoalAntecedentesModel } from './model'

export const convertAntecedentesFormToInput = (form: AntecedentesFormModel): AntecedentesInput =>
  form && {
    pessoal: convertPessoalFormToInput(form.pessoal),
    familiar: convertFamiliarToInput(form.familiar),
  }

const convertPessoalFormToInput = (form: PessoalAntecedentesModel): PessoalAntecedentesInput =>
  form && {
    texto: form.texto,
    puericultura: convertPrenatalPartoNascimento(form.puericultura),
    informacoesObstetricas: convertInformacoesObstetricasFormToInput(form.informacoesObstetricas),
    cirurgiasInternacoes: convertCirurgiaInternacaoFormToInput(form.cirurgiasInternacoes),
  }

const convertInformacoesObstetricasFormToInput = (
  form: InformacoesObstetricasPanelModel
): InformacoesObstetricasInput =>
  form && {
    gestaPrevias: parseNumber(form.gestaPrevias),
    abortos: parseNumber(form.abortos),
    partoMenosDeUmAno: form.partoMenosDeUmAno,
    partos: parseNumber(form.partos),
    partosVaginais: parseNumber(form.partosVaginais),
    partosCesareas: parseNumber(form.partosCesareas),
    partosDomiciliares: parseNumber(form.partosDomiciliares),
    nascidosVivos: parseNumber(form.nascidosVivos),
    natimortos: parseNumber(form.natimortos),
    recemNascidoAbaixo: parseNumber(form.recemNascidoAbaixo),
    recemNascidoAcima: parseNumber(form.recemNascidoAcima),
    filhosVivos: parseNumber(form.filhosVivos),
    obitoAntesPrimeiraSemana: parseNumber(form.obitoAntesPrimeiraSemana),
    obitoAposPrimeiraSemana: parseNumber(form.obitoAposPrimeiraSemana),
  }

const convertCirurgiaInternacaoFormToInput = (
  form: CirurgiaInternacaoModel[]
): AntecedenteCirurgiaInternacaoInput[] => {
  return (
    form?.map((item) => ({
      idCirurgiaInternacao: item.idCirurgiaInternacao,
      descricao: item.descricao,
      dataRealizada: item.dataRealizada?.data,
      observacao: item.observacao,
    })) || []
  )
}

const convertFamiliarToInput = (form: FamiliarAntecedentesModel): FamiliarAntecedentesInput =>
  form && {
    ciaps: form.ciaps?.map((item) => ({
      ciapId: item.ciap.id,
      observacao: item.observacao,
    })),
  }

export const convertProblemaToModel = (problemas: Problema[]): ProblemaCondicaoModel[] => {
  return problemas
    ? problemas.map((item) => ({
        _id: uuidv4(),
        ciap: item.ciap
          ? {
              id: item.ciap.id,
              codigo: item.ciap.codigo,
              nome: item.ciap.descricao,
            }
          : null,
        cid: item.cid10,
        dataInicio: { data: item.ultimaEvolucao.dataInicio },
        dataFim: { data: item.ultimaEvolucao.dataFim },
      }))
    : []
}
