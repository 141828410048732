/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core'
import { Tag, Text, Theme, useTheme } from 'bold-ui'
import { useMemo } from 'react'

export type StatusDesenvolvimentoAlertType = 'S1' | 'S2' | 'S3' | 'S4'

export interface StatusDesenvolvimentoAlertProps {
  type: StatusDesenvolvimentoAlertType
  title: string
  messages?: string
  isAcompanhamentoCard?: boolean
}

export default function StatusDesenvolvimentoAlert(props: StatusDesenvolvimentoAlertProps) {
  const { type, title, messages, isAcompanhamentoCard } = props
  const theme = useTheme()
  const classes = useMemo(() => createStyles(theme, isAcompanhamentoCard), [theme, isAcompanhamentoCard])

  return (
    <Tag style={[classes.wrapper, classes[type]]} icon='infoCircleFilled'>
      <div css={css([classes.content])}>
        {isAcompanhamentoCard ? (
          `${title}.`
        ) : (
          <Text style={[classes[type]]}>
            <Text fontWeight='bold' color='inherit'>
              {title}
              {type === 'S3' ? '.' : ': '}
            </Text>
            {messages}
          </Text>
        )}
      </div>
    </Tag>
  )
}

const createStyles = (
  theme: Theme,
  isAcompanhamento: boolean
): { [key in string | StatusDesenvolvimentoAlertType]: SerializedStyles } => ({
  wrapper: css`
    padding: 0.375rem 0.5rem;
    min-height: 2rem;
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    display: ${isAcompanhamento ? 'flex' : 'inline-flex'};
    align-items: center;
    justify-content: center;
    line-height: 1.25rem;
    white-space: normal;
  `,
  container: css`
    display: flex;
    align-items: center;
    flex: 1;
  `,
  icon: css`
    margin-right: 0.5rem;
  `,
  content: css`
    flex: 1;
    font-weight: normal;
  `,
  S1: css`
    background: ${theme.pallete.status.danger.background};
    color: ${theme.pallete.status.danger.main};
    border-color: ${theme.pallete.status.danger.main};
  `,
  S2: css`
    background: ${theme.pallete.status.alert.background};
    color: ${theme.pallete.status.alert.main};
    border-color: ${theme.pallete.status.alert.main};
  `,
  S3: css`
    background: ${theme.pallete.status.success.background};
    color: ${theme.pallete.status.success.main};
    border-color: ${theme.pallete.status.success.main};
  `,
  S4: css`
    background: ${theme.pallete.status.info.background};
    color: ${theme.pallete.status.info.main};
    border-color: ${theme.pallete.status.info.main};
  `,
})
