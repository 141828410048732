/* eslint-disable no-console */
import useLoginAnalytics from 'components/auth/useLoginAnalytics'
import useSession from 'components/auth/useSession'
import useFirebase from 'components/firebase/useFirebase'
import { PecSwitch } from 'components/route'
import { ServerTimeProvider } from 'hooks/useServerTime'
import React, { useEffect } from 'react'
import { Route } from 'react-router'
import { ForcarAlterarSenhaView } from 'view/alterar-senha/AlterarSenhaView'
import { AutorizarMunicipioRootView } from 'view/autorizacao/AutorizarMunicipioRootView'
import { EscolhaModuloAcessoRootView } from 'view/EscolhaModuloAcessoRootView'
import { SelecaoAcessoLoginView } from 'view/login/SelecaoAcessoView/SelecaoAcessoView'
import { TermosView } from 'view/termos-uso/TermosView'

import { LoggedRootView } from '../LoggedRootView'

export function PrivateRootView() {
  const { data, refresh } = useSession()
  const { analytics } = useFirebase()
  const escolhaModulo = () => <EscolhaModuloAcessoRootView refresh={refresh} />
  const autorizarMunicipioView = () => <AutorizarMunicipioRootView acessoId={data.acesso && data.acesso.id} />

  const { log } = useLoginAnalytics()

  useEffect(() => log(data?.sessionProvider))

  useEffect(() => {
    if (data?.acesso) {
      analytics.setUserId(data.profissional.usuario.hashId)
      analytics.setUserProperties({
        Acesso: data.acesso.tipo,
        Perfis: data.acesso.perfis?.map((p) => p.nome).join(', '),
        CBO: data.acesso.__typename === 'Lotacao' && data.acesso.cbo.nome,
        Versão: process.env.REACT_APP_VERSION,
        Município: data.acesso.municipio?.nome,
      })
    }
  }, [analytics, data])

  return (
    <ServerTimeProvider>
      <PecSwitch>
        {!data.profissional.usuario.aceitouTermosUso && (
          // Usuário precisa aceitar os termos de uso
          <Route component={TermosView} />
        )}

        {data.profissional.usuario.trocarSenha && (
          // Usuário precisa alterar senha
          <Route component={ForcarAlterarSenhaView} />
        )}

        {!data.acesso && (
          // Usuário precisa selecionar acesso
          <Route component={SelecaoAcessoLoginView} />
        )}

        {data.acesso &&
          data.acesso.__typename === 'AdministradorMunicipal' &&
          (!data.acesso.autorizado || !data.acesso.habilitado) && <Route component={autorizarMunicipioView} />}

        {data.acesso && !data.acesso.moduloInicial && (
          // Usuário precisa escolher seu modulo Inicial
          <Route component={escolhaModulo} />
        )}

        <Route component={LoggedRootView} />
      </PecSwitch>
    </ServerTimeProvider>
  )
}
