import { Button, Heading, HFlow, Icon, Modal, ModalBody, ModalFooter, Spinner, Text, useTheme, VFlow } from 'bold-ui'
import { Cpf } from 'components/label'
import { SincronizacaoCadsusResultEnum } from 'graphql/types.generated'
import React from 'react'

export interface ModalBuscaInformacoesContentState {
  cns?: string
  statusSincronizacao?: SincronizacaoCadsusResultEnum
  errors?: string[]
  cpf?: string
  isLoading?: boolean
}

interface ModalBuscaInformacoesCadsusProps {
  isModalOpen: boolean
  result: ModalBuscaInformacoesContentState
  setModalState(isOpen: boolean): void
  isLoading: boolean
}

interface ErroBuscaInformacoesBodyProps {
  errors: string[]
}

interface ErroBuscaInformacoesDadosDuplicadosProps {
  cns: string
  cpf: string
  result: SincronizacaoCadsusResultEnum
}

export function ModalBuscaInformacoesCadsus(props: ModalBuscaInformacoesCadsusProps) {
  const { result } = props

  const handleOnCloseModal = () => props.setModalState(false)

  let isQueryComplete = !props.isLoading

  return (
    <Modal
      open={props.isModalOpen}
      onClose={handleOnCloseModal}
      size='small'
      closeOnBackdropClick={false}
      hasCloseIcon={false}
    >
      <div tabIndex={1} style={{ outline: '0px solid transparent' }}>
        {props.isLoading && <AguardandoBuscaCadsusBody />}
        {isQueryComplete &&
          (result?.statusSincronizacao === SincronizacaoCadsusResultEnum.ERRO ||
            result?.statusSincronizacao === SincronizacaoCadsusResultEnum.LENTO) && (
            <ErroBuscaInformacoesBody errors={result.errors} />
          )}
        {isQueryComplete &&
          (result.statusSincronizacao === SincronizacaoCadsusResultEnum.DUPLICIDADE_CNS_ATIVOS ||
            result.statusSincronizacao === SincronizacaoCadsusResultEnum.DUPLICIDADE_CNS_INATIVOS ||
            result.statusSincronizacao === SincronizacaoCadsusResultEnum.DUPLICIDADE_CPF_ATIVOS ||
            result.statusSincronizacao === SincronizacaoCadsusResultEnum.POSSUI_PRONTUARIO ||
            result.statusSincronizacao === SincronizacaoCadsusResultEnum.DUPLICIDADE_CPF_INATIVOS) && (
            <ErroBuscaInformacoesDadosDuplicados
              cns={result?.cns}
              cpf={result?.cpf}
              result={result?.statusSincronizacao}
            />
          )}
        {isQueryComplete && result && (
          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              <Button onClick={handleOnCloseModal} kind='primary'>
                Concluir
              </Button>
            </HFlow>
          </ModalFooter>
        )}
      </div>
    </Modal>
  )
}

export function AguardandoBuscaCadsusBody() {
  const theme = useTheme()
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Heading level={2}>
              <Spinner size={2} borderWidth={3} style={{ color: theme.pallete.primary.main, marginRight: 6 }} />
              Atualizando dados do cidadão
            </Heading>
          </HFlow>
          <Text>Estamos verificando dados mais recentes do cidadão, na base nacional (CADSUS).</Text>
        </VFlow>
      </ModalBody>
    </>
  )
}

function ErroBuscaInformacoesBody(props: ErroBuscaInformacoesBodyProps) {
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='exclamationTriangleOutline' fill='alert' size={4} />
            <Heading level={2}>
              Não foi possível verificar dados mais recentes do cidadão, pelo CNS, na Base nacional (CADSUS)
            </Heading>
          </HFlow>
          {props.errors && <Text>{props.errors[0]}</Text>}
        </VFlow>
      </ModalBody>
    </>
  )
}

function ErroBuscaInformacoesDadosDuplicados(props: ErroBuscaInformacoesDadosDuplicadosProps) {
  return (
    <>
      <ModalBody>
        <VFlow>
          <HFlow>
            <Icon icon='exclamationTriangleOutline' fill='alert' size={4} />
            {props.result === SincronizacaoCadsusResultEnum.DUPLICIDADE_CNS_ATIVOS ||
            props.result === SincronizacaoCadsusResultEnum.DUPLICIDADE_CNS_INATIVOS ||
            props.result === SincronizacaoCadsusResultEnum.POSSUI_PRONTUARIO ? (
              <Heading level={2}>CNS não atualizado</Heading>
            ) : (
              <Heading level={2}>CPF não atualizado</Heading>
            )}
          </HFlow>

          {props.result === SincronizacaoCadsusResultEnum.DUPLICIDADE_CNS_ATIVOS ||
          props.result === SincronizacaoCadsusResultEnum.POSSUI_PRONTUARIO ||
          props.result === SincronizacaoCadsusResultEnum.DUPLICIDADE_CNS_INATIVOS ? (
            <Text>
              Identificamos que o cidadão possui um CNS higienizado na base nacional (CADSUS) de número {props.cns}.
              Existe um outro cadastro na base local do sistema utilizando o mesmo CNS ou o cidadão já possui prontuário
              no sistema.
            </Text>
          ) : (
            <Text>
              O CPF de número <Cpf value={props.cpf} /> está cadastrado no CADSUS e existe um outro cadastro na base
              local com o mesmo CPF.
            </Text>
          )}
        </VFlow>
      </ModalBody>
    </>
  )
}
