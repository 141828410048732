import { resolveName } from 'components/form/final-form/useField'
import { getIn } from 'final-form'
import { MetaPath } from 'util/metaPath'

import { Label, LabelMap } from './model'

const resolveLabelName = <FieldModel>(fieldName: MetaPath<FieldModel>) =>
  resolveName(fieldName).replaceAll('.', '.fields.')

export const getFieldLabelProperties = <FieldModel, FormModel>(
  fieldName: MetaPath<FieldModel>,
  fieldsLabels: LabelMap<FormModel>
): Label<FieldModel> => {
  const resolvedName = resolveLabelName<FieldModel>(fieldName)
  return getIn(fieldsLabels, resolvedName)
}
