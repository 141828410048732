/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Theme, useTheme, VFlow } from 'bold-ui'
import { TimeInterval, Weekday } from 'components/agenda'
import { AgendaToolbar } from 'components/agenda/AgendaToolbar'
import { AlertServerTimezone } from 'components/AlertServerTimezone'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacao } from 'components/auth/useAcessoLotacao'
import { PageContent } from 'components/layout/PageContent'
import { LoadingIndicator } from 'components/loading'
import { parse } from 'date-fns'
import format from 'date-fns/format'
import {
  useAgendamentosDiaLotacaoQuery,
  useConfiguracaoHorariosAgendaLotacaoQuery,
  useFechamentoAgendaDiaQuery,
  useLotacaoAgendaSelectFieldQuery,
  useLotacaoConfiguracaoAgendaQuery,
} from 'graphql/hooks.generated'
import { LotacaoAgendaSelectFieldQueryVariables, SituacaoAgendadoEnum } from 'graphql/types.generated'
import useAtmosphere from 'hooks/useAtmosphere'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import Permissions from 'types/Permissions'

import { AgendaLotacao } from './AgendaLotacao'
import { AgendaCidadaoForm } from './cidadao/AgendaCidadaoForm'
import { CalendarAgendaLotacao } from './components/calendario/CalendarAgendaLotacao'
import { convertConfiguracaoAgendaOnline, convertConfiguracaoDiasSemana } from './converter'
import { LotacaoAgendaSection } from './LotacaoAgendaSection'
import { LotacaoAgendaModel } from './model'

const DATE_PARAM_FORMAT = 'ddMMyyyy'

const situacoesExibir = [
  SituacaoAgendadoEnum.AGENDADO,
  SituacaoAgendadoEnum.CIDADAO_PRESENTE_NA_UNIDADE,
  SituacaoAgendadoEnum.NAO_COMPARECEU,
  SituacaoAgendadoEnum.ATENDIMENTO_REALIZADO,
  SituacaoAgendadoEnum.NAO_AGUARDOU,
]

export interface AgendaViewProps {
  atencaoDomiciliar?: boolean
}

interface AgendaViewParams {
  lotacaoId: string
  date: string
}

export function AgendaView({ atencaoDomiciliar: isAD = false }: AgendaViewProps) {
  const theme = useTheme()
  const styles = createStyles(theme)
  const formAgendamentoOpen = useRef(false)

  const {
    lotacaoAgenda,
    currentDate,
    setLotacaoCombo,
    setCurrentDate,
    loading,
    refetchLotacaoConfiguracaoAgenda,
  } = useAgendaViewParams(isAD)

  const lotacaoAgendaId = lotacaoAgenda?.id

  const { refetch: updateEventos, data: { agendados } = {} } = useAgendamentosDiaLotacaoQuery({
    variables: {
      input: {
        lotacaoId: lotacaoAgendaId,
        dataAgendadoInicio: currentDate,
        dataAgendadoFim: currentDate,
        situacao: situacoesExibir,
      },
    },
    skip: !lotacaoAgendaId,
  })
  const agendadosContent = agendados?.content

  const {
    data: {
      lotacao: {
        configuracaoAgenda: { duracaoAgendamento, configuracaoHorarioAgenda: { configuracaoDiasSemana } = {} } = {},
        gradeConfiguracaoAgendaOnline: {
          configuracoesSemana: configuracaoDiasSemanaAgendaOnline,
          diasLiberacaoReserva: diasLiberacaoReservaAgendaOnline,
        } = {},
      } = {},
      conexao: { agendaOnline: { ativa: isAgendaOnlineAtiva } } = { agendaOnline: { ativa: false } },
    },
    loading: loadingConfiguracoes,
    refetch: refetchConfiguracaoHorarios,
  } = useConfiguracaoHorariosAgendaLotacaoQuery({
    variables: { lotacaoId: lotacaoAgendaId, isAtencaoDomiciliar: isAD, isAgendaAtiva: lotacaoAgenda?.ativo },
    skip: !lotacaoAgendaId,
  })

  const {
    loading: loadingFechamentosDia,
    data: { fechamentoDia },
    refetch: refetchFechamentoDia,
  } = useFechamentoAgendaDiaQuery({
    variables: { input: { lotacaoId: lotacaoAgendaId, dia: currentDate } },
    skip: !lotacaoAgendaId,
  })

  const currentDateMillis = Number(currentDate)
  const refetchInfos = useCallback(
    debounce(() => {
      if (lotacaoAgendaId) {
        const internalCurrentDate = new Date(currentDateMillis)

        refetchConfiguracaoHorarios({
          lotacaoId: lotacaoAgendaId,
          isAtencaoDomiciliar: isAD,
          isAgendaAtiva: lotacaoAgenda?.ativo,
        })
        refetchFechamentoDia({ input: { lotacaoId: lotacaoAgendaId, dia: internalCurrentDate } })
        updateEventos({
          input: {
            lotacaoId: lotacaoAgendaId,
            dataAgendadoInicio: internalCurrentDate,
            dataAgendadoFim: internalCurrentDate,
            situacao: situacoesExibir,
          },
        })
        refetchLotacaoConfiguracaoAgenda({ id: lotacaoAgendaId })
      }
    }, 500),
    [
      currentDateMillis,
      isAD,
      lotacaoAgendaId,
      refetchConfiguracaoHorarios,
      refetchFechamentoDia,
      refetchLotacaoConfiguracaoAgenda,
      updateEventos,
    ]
  )

  useAtmosphere<number>({
    topic: `agendamento/${lotacaoAgendaId}`,
    onMessage: () => {
      if (!formAgendamentoOpen.current) {
        refetchInfos()
      }
    },
  })

  const availableTimes = useMemo(() => convertConfiguracaoDiasSemana(configuracaoDiasSemana), [configuracaoDiasSemana])
  const mapReservasAgendaOnline = useMemo(() => convertConfiguracaoAgendaOnline(configuracaoDiasSemanaAgendaOnline), [
    configuracaoDiasSemanaAgendaOnline,
  ])

  return (
    <PageContent fluid>
      <Grid
        wrap={false}
        style={css`
          padding: 0.5rem 2.5rem;
          height: 100%;
        `}
      >
        <Cell style={styles.lane}>
          <VFlow
            style={css`
              height: 100%;
            `}
          >
            <CalendarAgendaLotacao
              visibleDate={currentDate}
              onChange={setCurrentDate}
              availableWeekdays={getAvailableWeekdays(availableTimes)}
              shouldEnableControls={!lotacaoAgenda?.ativo || !lotacaoAgenda.hasConfiguracaoAgenda}
            />
            <CheckPermission permission={Permissions.visualizarAgenda}>
              <AgendaCidadaoForm />
            </CheckPermission>
          </VFlow>
        </Cell>
        <Cell flexGrow={1} style={css(styles.lane, styles.agenda)}>
          <VFlow style={styles.agendaVFlow}>
            <AlertServerTimezone
              styles={{
                wrapper: css`
                  margin-top: 0.5rem;
                `,
              }}
            />
            <AgendaToolbar
              date={currentDate}
              availableTimes={availableTimes}
              onNavigate={(_, date) => setCurrentDate(date)}
            />

            <LotacaoAgendaSection
              atencaoDomiciliar={isAD}
              initialValue={lotacaoAgenda}
              onChange={(lotacao) => setLotacaoCombo(lotacao?.id)}
              impressaoAgenda={{
                areThereEventos: !!agendadosContent?.length,
                date: currentDate,
              }}
            />

            {loadingConfiguracoes || loadingFechamentosDia || loading ? (
              <div
                css={css`
                  margin-top: 1rem;
                  margin-bottom: 1rem;
                  text-align: center;
                `}
              >
                <LoadingIndicator />
              </div>
            ) : (
              <AgendaLotacao
                date={currentDate.getTime()}
                lotacao={lotacaoAgenda}
                availableTimes={availableTimes}
                diasLiberacaoReservaAgendaOnline={diasLiberacaoReservaAgendaOnline}
                isAgendaOnlineAtiva={isAgendaOnlineAtiva}
                reservasAgendaOnline={mapReservasAgendaOnline}
                stepInMinutes={duracaoAgendamento}
                onNavigate={setCurrentDate}
                atencaoDomiciliar={isAD}
                agendamentos={agendadosContent}
                fechamentoDia={fechamentoDia}
                refetchInfos={refetchInfos}
                formOpenRef={formAgendamentoOpen}
              />
            )}
          </VFlow>
        </Cell>
      </Grid>
    </PageContent>
  )
}

function useLotacaoAgendaPadrao(
  skip: boolean
): {
  lotacao?: LotacaoAgendaModel
  loading: boolean
} {
  const lotacaoAcesso = useAcessoLotacao()

  const {
    data: { hasConfiguracaoAgenda },
    loading: loadingConfigs,
  } = useLotacaoConfiguracaoAgendaQuery({
    variables: {
      id: lotacaoAcesso.id,
    },
    skip,
    fetchPolicy: 'cache-first',
  })

  const { data: lotacaoQueryResult, loading } = useLotacaoAgendaSelectFieldQuery({
    variables: { id: lotacaoAcesso.id },
    skip: !hasConfiguracaoAgenda,
    fetchPolicy: 'cache-first',
  })

  return useMemo(
    () => ({
      lotacao: hasConfiguracaoAgenda ? lotacaoQueryResult?.lotacao : undefined,
      loading: loadingConfigs || loading,
    }),
    [hasConfiguracaoAgenda, loading, loadingConfigs, lotacaoQueryResult]
  )
}

function useAgendaRouteParams(): {
  lotacao?: LotacaoAgendaModel
  date?: Date
  loading: boolean
  refetchLotacaoConfiguracaoAgenda(variables?: LotacaoAgendaSelectFieldQueryVariables): void
} {
  const { lotacaoId, date } = useParams<AgendaViewParams>()

  const {
    data: lotacaoQueryResult,
    loading,
    refetch: refetchLotacaoConfiguracaoAgenda,
  } = useLotacaoAgendaSelectFieldQuery({
    variables: { id: lotacaoId },
    skip: !lotacaoId,
  })

  return {
    lotacao: lotacaoId ? lotacaoQueryResult?.lotacao : undefined,
    date: date ? parse(date, DATE_PARAM_FORMAT, new Date()) : undefined,
    loading,
    refetchLotacaoConfiguracaoAgenda,
  }
}

function useAgendaViewParams(isAtencaoDomiciliar: boolean) {
  const history = useHistory()
  const baseUrl = isAtencaoDomiciliar ? '/ad/agenda' : '/agenda'
  const lotacaoPadraoLoaded = useRef(false)

  const { lotacao: lotacaoAgendaPadrao, loading: loadingLotacaoPadrao } = useLotacaoAgendaPadrao(
    lotacaoPadraoLoaded.current
  )

  const {
    lotacao: lotacaoAgendaRoute,
    date: currentDate = new Date(),
    loading,
    refetchLotacaoConfiguracaoAgenda,
  } = useAgendaRouteParams()
  const currentDateParam = currentDate && format(currentDate, DATE_PARAM_FORMAT)
  const lotacaoAgendaPadraoId = lotacaoAgendaPadrao?.id
  const lotacaoAgendaRouteId = lotacaoAgendaRoute?.id

  const setCurrentDate = useCallback(
    (date: Date) =>
      lotacaoAgendaRouteId && date
        ? history.push(
            `${baseUrl}/${lotacaoAgendaRouteId}/${format(date, DATE_PARAM_FORMAT)}${history.location.search}`
          )
        : history.push(`${baseUrl}${history.location.search}`),
    [baseUrl, history, lotacaoAgendaRouteId]
  )

  const setLotacaoCombo = useCallback(
    (lotacaoId: ID) =>
      lotacaoId && currentDateParam
        ? history.push(`${baseUrl}/${lotacaoId}/${currentDateParam}${history.location.search}`)
        : history.push(`${baseUrl}${history.location.search}`),
    [baseUrl, currentDateParam, history]
  )

  useEffect(() => {
    if (
      !lotacaoPadraoLoaded.current &&
      !loadingLotacaoPadrao &&
      !loading &&
      lotacaoAgendaPadraoId !== lotacaoAgendaRouteId
    ) {
      lotacaoAgendaPadraoId && !lotacaoAgendaRouteId && setLotacaoCombo(lotacaoAgendaPadraoId)
      lotacaoPadraoLoaded.current = true
    }
  }, [loading, loadingLotacaoPadrao, lotacaoAgendaRouteId, lotacaoAgendaPadraoId, setLotacaoCombo])

  return {
    lotacaoAgenda: lotacaoAgendaRoute,
    currentDate,
    setLotacaoCombo,
    setCurrentDate,
    loading: loadingLotacaoPadrao || loading,
    refetchLotacaoConfiguracaoAgenda,
  }
}

const getAvailableWeekdays = (availableTimes: Record<Weekday, TimeInterval[]>): Set<Weekday> =>
  availableTimes
    ? new Set<Weekday>(
        Object.keys(Weekday)
          .filter((weekday) => !!availableTimes[weekday]?.length)
          .map((key) => Number(key))
      )
    : new Set<Weekday>()

const createStyles = (theme: Theme) => ({
  infos: css`
    margin-left: 1rem;
    grid-template-columns: 1.5rem fit-content(70%);
  `,
  lane: css`
    margin-top: 0rem;
    padding-top: 0rem;
    padding-bottom: 0;
    height: 100%;
  `,

  agenda: css`
    background-color: ${theme.pallete.surface.main};
    border-left: 1px solid ${theme.pallete.divider};
    border-right: 1px solid ${theme.pallete.divider};
    border-bottom: 1px solid ${theme.pallete.divider};
    border-top: 1px solid ${theme.pallete.divider};

    ${theme.breakpoints.up('lg')} {
      margin-right: 2.5rem;
    }
  `,

  agendaVFlow: css`
    height: 100%;
    & > div:last-child {
      min-height: 0;
      height: 100%;
    }
  `,
})
