/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ApolloError } from 'apollo-client'
import { Alert, Button, Cell, Grid, HFlow, Text } from 'bold-ui'
import { useAlert } from 'components/alert'
import CheckPermission from 'components/auth/CheckPermission'
import { handleValidationError } from 'components/error'
import {
  CheckboxField,
  CidadaoAtendimentoSelectField,
  DateField,
  ErrorField,
  Form,
  FormRenderProps,
  HorarioAgendaSelectField,
  LocalAtendimentoSelectField,
  TelefoneField,
  TextAreaField,
  TextField,
} from 'components/form'
import { CidadaoAtendimentoAdSelectField } from 'components/form/field/select/CidadaoAtendimentoSelectField/CidadaoAtendimentoADSelectField'
import { InfoIcon } from 'components/InfoIcon'
import { addMonths, formatISO, parseISO } from 'date-fns'
import { FORM_ERROR, FormApi } from 'final-form'
import {
  useAtualizarContatoCidadaoMutation,
  useCidadaoQuery,
  useSalvarAgendamentoConsultaMutation,
} from 'graphql/hooks.generated'
import { noop } from 'lodash'
import qs from 'qs'
import React, { Fragment, useMemo } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router'
import Permissions from 'types/Permissions'
import { metaPath } from 'util/metaPath'

import { convertConsultaModelToInput, convertConsultaModelToUpdateContatoCidadaoInput } from '../converter'
import { useVerificarAgendamentosConflitantes } from '../hooks/useVerificarAgendamentosConflitantes'
import { AgendamentoConsultaModel, LotacaoAgendaModel } from '../model'
import { agendamentoConsultaDecorator } from './calculator'
import { agendamentoConsultaValidator } from './validator'

export const meta = metaPath<AgendamentoConsultaModel>()

export interface AgendamentoReservaFormProps {
  lotacao: LotacaoAgendaModel
  isAtencaoDomiciliar?: boolean
  onSubmit?(): void
  onClose(): void
  getServerTimeNow(): Date
  start?: number
  end?: number
}

interface QueryParams {
  cidadaoId: ID
}

export const AgendamentoConsultaForm = (props: AgendamentoReservaFormProps) => {
  const { onClose, onSubmit, lotacao, start, end, getServerTimeNow, isAtencaoDomiciliar: isAD = false } = props
  const alert = useAlert()
  const serverTime = getServerTimeNow()
  const match = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const [salvarAgendamento] = useSalvarAgendamentoConsultaMutation()
  const [atualizarContatoCidadao] = useAtualizarContatoCidadaoMutation()
  const { loading, verificarAgendamentosConflitantes } = useVerificarAgendamentosConflitantes()

  const queryParams: QueryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const {
    data: { cidadao: cidadaoParam },
  } = useCidadaoQuery({
    variables: { id: queryParams.cidadaoId },
    skip: !queryParams?.cidadaoId,
    fetchPolicy: 'cache-first',
  })

  const initialValues = useMemo(
    () => ({
      horario: { inicial: new Date(start), final: new Date(end) },
      data: start ? formatISO(start) : undefined,
      cidadao: cidadaoParam,
    }),
    [cidadaoParam, end, start]
  )

  const updateContato = (values: AgendamentoConsultaModel) => {
    const telefoneCelularAlterado = values.cidadao?.contato.telefoneCelular !== values.telefoneCelular
    const emailAlterado = values.cidadao?.contato.email !== values.email

    if (telefoneCelularAlterado || emailAlterado)
      return atualizarContatoCidadao({ variables: { input: convertConsultaModelToUpdateContatoCidadaoInput(values) } })
  }

  const save = (values: AgendamentoConsultaModel, formApi: FormApi) =>
    salvarAgendamento({ variables: { input: convertConsultaModelToInput(values, lotacao.id) } }).then(() => {
      alert('success', 'Agendamento de consulta cadastrado com sucesso.')
      history.replace({ search: undefined })
      onSubmit?.()
      onClose()
      setTimeout(formApi.reset)
    })

  const handleFormSubmit = (values: AgendamentoConsultaModel, formApi: FormApi) => {
    verificarAgendamentosConflitantes(
      values.cidadao.id,
      lotacao.id,
      Number(values.horario.inicial),
      values.cidadao.nomeSocial ?? values.cidadao.nome
    )
      .then((confirmed) => confirmed && updateContato(values))
      .then((result) => (result ? result && save(values, formApi) : save(values, formApi)))
      .catch((apolloError: ApolloError) => {
        //  TODO: (RNG) Quando eh clicado em qualquer parte da tela o popper eh fechado e portanto nao temos mais o form, verificar se iremos trabalhar para conseguir manter o mesmo aberto para poder apresentar os erros em cada field
        const error = buildAgendamentosConflitantesErrorMessage(apolloError)
        alert('danger', error)
      })
  }

  const handleCancelar = () => {
    history.replace({ search: undefined })
    onClose()
  }

  const renderForm = (formProps: FormRenderProps<Partial<AgendamentoConsultaModel>>) => {
    const { handleSubmit, values } = formProps

    return (
      <React.Fragment>
        <div
          css={css`
            min-height: 23rem;
          `}
        >
          <Grid>
            <Cell size={12}>
              <ErrorField name={FORM_ERROR} inline={false} type='alert' />
            </Cell>
            {values.isForaUbs && (
              <Cell size={12}>
                <Alert type='info' inline>
                  Consultas realizadas fora da UBS são adicionadas automaticamente na lista de registro tardio de
                  atendimento no dia agendado.
                </Alert>
              </Cell>
            )}

            <Cell size={3}>
              <DateField
                name={meta.data}
                label='Data'
                minDate={serverTime}
                maxDate={addMonths(serverTime, 6)}
                clearable
                required
              />
            </Cell>

            <Cell size={3}>
              <HorarioAgendaSelectField
                name={meta.horario}
                lotacaoId={lotacao.id}
                dia={values.data ? parseISO(values.data) : undefined}
                disabled={!values.data}
                labels={{ inicial: 'Horário' }}
                atencaoDomiciliar={isAD}
                clearable
                required
              />
            </Cell>

            <Cell size={3} />

            {!isAD ? (
              <Fragment>
                <Cell size={4}>
                  <CheckboxField
                    name={meta.isForaUbs}
                    label='Atendimento fora da UBS'
                    style={css`
                      margin-top: 2rem;
                    `}
                  />
                </Cell>

                <Cell size={5}>
                  {values.isForaUbs && <LocalAtendimentoSelectField name={meta.localAtendimento} required excludeUbs />}
                </Cell>
              </Fragment>
            ) : (
              <Cell size={9} />
            )}

            <Cell size={12} />

            <Cell size={6}>
              {isAD ? (
                <CidadaoAtendimentoAdSelectField
                  name={meta.cidadao}
                  ativo={true}
                  obito={false}
                  equipeResponsavelId={lotacao.equipe?.id}
                  label='Cidadão'
                  required
                />
              ) : (
                <CidadaoAtendimentoSelectField
                  name={meta.cidadao}
                  ativo={true}
                  obito={false}
                  retrieveContato
                  label='Cidadão'
                  verificarPresenteNaListaAtendimento={false}
                  addCidadaoCallbackUrl={match.url}
                  required
                />
              )}
            </Cell>
            <Cell size={6} />
            <CheckPermission permission={Permissions.gestaoDeCadastrosDeCidadao.visualizarCidadao.cadastrarEEditar}>
              <Cell size={12}>
                <Text fontWeight='bold'>Dados de contato do cidadão</Text>
                <InfoIcon
                  icon='infoCircleFilled'
                  text='Verifique os dados de contato do cidadão durante o agendamento.'
                  style={styles.infoIcon}
                />
              </Cell>
              <Cell size={6}>
                <TextField
                  lowercase
                  name={meta.email}
                  label='E-mail'
                  maxLength={50}
                  required={!!values.cidadao?.contato.email}
                  disabled={!values.cidadao}
                />
              </Cell>
              <Cell size={6}>
                <TelefoneField
                  name={meta.telefoneCelular}
                  label='Telefone celular'
                  required={!!values.cidadao?.contato.telefoneCelular}
                  disabled={!values.cidadao}
                  // Foi definido assim pois a máscara só funciona com o campo 'sujo'
                  initialValue='0000000000'
                />
              </Cell>
            </CheckPermission>
            <Cell size={12}>
              <TextAreaField label='Observações' name={meta.observacoes} maxLength={200} />
            </Cell>
            <Cell size={4} />
          </Grid>
        </div>
        <HFlow justifyContent='flex-end'>
          <Button kind='normal' onClick={handleCancelar} size='medium'>
            Cancelar
          </Button>
          <Button kind='primary' onClick={handleSubmit} size='medium' loading={loading}>
            Salvar
          </Button>
        </HFlow>
      </React.Fragment>
    )
  }

  return (
    <Form<Partial<AgendamentoConsultaModel>>
      initialValues={initialValues}
      render={renderForm}
      onSubmit={handleFormSubmit}
      onSubmitFailed={noop} //Previne mostrar alerta de validação fora do popup
      validate={agendamentoConsultaValidator(serverTime)}
      decorators={agendamentoConsultaDecorator}
    />
  )
}

const buildAgendamentosConflitantesErrorMessage = (apolloError: ApolloError) => {
  const errors = handleValidationError(apolloError)
  return Object.keys(errors)
    .map((key) => errors[key])
    .join('. ')
}

const styles = {
  infoIcon: css`
    padding: 0.2rem 0 0 0.2rem;
  `,
}
