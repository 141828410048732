import { Theme, useStyles } from 'bold-ui'
import { useScrollPosition } from 'bold-ui/lib/hooks'
import useFirebase from 'components/firebase/useFirebase'
import { useFlags } from 'config/useFlagsContext'
import React, { CSSProperties, Ref, useEffect, useRef, useState } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

import { SideMenu } from './SideMenu'
import { getSideMenuItems } from './SiteMenuItems'

export const SIDE_MENU_WIDTH = 85
export const SIDE_MENU_EXPANDED_WIDTH = 320
export const SIDE_MENU_TRANSITION_DELAY = 300 // milliseconds

export interface SideMenuNavProps {
  open: boolean
  searchInputRef?: Ref<HTMLInputElement>
  onClose(): void
}

export function SideMenuNav(props: SideMenuNavProps) {
  const { open, searchInputRef, onClose } = props
  const [mouseIn, setMouseIn] = useState(false)
  const { analytics } = useFirebase()

  const handleMouseEnter = () => {
    setMouseIn(true)
    analytics.logEvent('side_menu_hover')
  }
  const handleMouseLeave = () => setMouseIn(false)

  const [focusIn, setFocusIn] = useState(false)
  const handleFocus = () => setFocusIn(true)
  const handleBlur = () => setFocusIn(false)

  // se a largura da Side é maior que SIDE_MENU_WIDTH, apos o transition-delay
  const [isVisualExpanded, setIsVisualExpanded] = useState(false)

  const [expanded, setExpanded] = useState<boolean>()
  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      setIsVisualExpanded(entries[0].contentRect.width > SIDE_MENU_WIDTH)
    })

    if (ref?.current) {
      observer.observe(ref?.current)
    }

    return () => observer.disconnect()
  }, [])

  useEffect(() => setExpanded(mouseIn || focusIn), [mouseIn, focusIn])

  const handleNavigate = () => {
    onClose()
  }

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const targetNode = e.target as Node
      if (open && !ref.current.contains(targetNode)) {
        onClose()
      }
    }

    window.addEventListener('click', handleClickOutside)
    return () => window.removeEventListener('click', handleClickOutside)
  }, [open, onClose])

  const scroll = useScrollPosition()
  const { classes } = useStyles(createStyles, expanded, open, isVisualExpanded)
  const { LISTA_REGISTRO_TARDIO_NOVA_ENABLED } = useFlags()

  return (
    <div
      ref={ref}
      className={classes.wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={{ top: Math.max(137 - scroll.scrollY, 0) }}
    >
      <SideMenu
        items={getSideMenuItems(LISTA_REGISTRO_TARDIO_NOVA_ENABLED)}
        expanded={expanded || open}
        visualExpanded={isVisualExpanded}
        searchInputRef={searchInputRef}
        onNavigate={handleNavigate}
      />
    </div>
  )
}

const createStyles = (theme: Theme, expanded: boolean, open: boolean, visualExpanded: boolean) => ({
  wrapper: {
    position: 'fixed',
    zIndex: 10,
    bottom: 0,
    overflow: 'auto',
    boxShadow: (expanded || open) && visualExpanded && theme.shadows.outer[40],

    nav: {
      width: !open && 0,
      [theme.breakpoints.up('lg')]: {
        width: expanded ? SIDE_MENU_EXPANDED_WIDTH : SIDE_MENU_WIDTH,
      },
    },
  } as CSSProperties,
})
