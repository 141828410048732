/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Icon, useTheme } from 'bold-ui'

export default function MedicamentoImpressoInfoLabel() {
  const theme = useTheme()

  return (
    <HFlow
      hSpacing={0.5}
      alignItems='center'
      style={css`
        color: ${theme.pallete.primary.main};
      `}
    >
      <Icon icon='infoCircleOutline' size={1} />
      Impresso
    </HFlow>
  )
}
