import { resolveValue } from 'components/form/final-form/useField'
import { Calculation } from 'final-form-calculate'
import { SituacaoProblema } from 'graphql/types.generated'
import { isAfter, isAfterOrEqual, isBeforeOrEqual } from 'util/date/compare'
import { toDate } from 'util/date/formatDate'
import { MetaPath } from 'util/metaPath'
import { CiapCidPreNatal, meta, SoapState } from 'view/atendimentos/atendimento-individual/model'

import { Problema } from '../aside/types/ProblemaModel'
import { AvaliacaoFormModel } from '../avaliacao/AvaliacaoForm'
import { ProblemaCondicaoModel } from '../avaliacao/components/problemas-condicoes/model'
import {
  addProblemaCondicaoAutomatico,
  createProblemaCondicaoAutomaticoPreNatal,
  findProblemaComCiapW78,
  removeProblemaCondicao,
} from '../avaliacao/components/problemas-condicoes/utils/operations'
import {
  hasProblemaComCiapW78Ativo,
  hasProblemaCondicaoComCiapW78,
  hasProblemaCondicaoDePreNatal,
  hasProblemaCondicaoNaoEvolucaoGravidezAltoRiscoAtivoOuLatente,
} from '../avaliacao/components/problemas-condicoes/utils/verifications'
import { ObjetivoFormModel } from '../objetivo'
import { FormAtivoObjetivoEnum } from '../objetivo/components/SwitchButtonObjetivoForm'

export const createPreNatalObjetivoCalculations = (
  objetivo: MetaPath<ObjetivoFormModel>,
  avaliacao: MetaPath<AvaliacaoFormModel>,
  isGestante: boolean,
  hasPermissionPreNatal: boolean
): Calculation[] => [
  {
    field: avaliacao.problemasECondicoes.absolutePath(),
    updates: {
      [objetivo.atendimentosEspecificos.formAtivo.absolutePath()]: (
        problemasCondicoes: ProblemaCondicaoModel[],
        allValues: SoapState
      ) => {
        const formAtivo = resolveValue(allValues, objetivo.atendimentosEspecificos.formAtivo)
        if (hasPermissionPreNatal) {
          return isGestante && hasProblemaCondicaoDePreNatal(problemasCondicoes, isGestante)
            ? FormAtivoObjetivoEnum.PRE_NATAL
            : formAtivo === FormAtivoObjetivoEnum.PRE_NATAL
            ? null
            : formAtivo
        } else {
          return formAtivo
        }
      },
    },
  },
]

export const createPreNatalAvaliacaoCalculations = (
  avaliacao: MetaPath<AvaliacaoFormModel>,
  isMedico: boolean,
  dataNascimentoCidadao: LocalDate,
  dataAtendimento: Instant,
  ciapCidPreNatal: CiapCidPreNatal,
  isContinuacaoPreNatal: boolean,
  hasPermissionPreNatal: boolean,
  problemasCidadao?: Problema[]
): Calculation[] =>
  hasPermissionPreNatal
    ? [
        createEncerrarGestacaoDataDesfechoCalculation(
          avaliacao,
          isMedico,
          dataNascimentoCidadao,
          dataAtendimento,
          ciapCidPreNatal,
          problemasCidadao
        ),
        createPreNatalProblemasCondicoesAnterioresCalculation(
          avaliacao,
          isMedico,
          dataNascimentoCidadao,
          dataAtendimento,
          ciapCidPreNatal,
          !isContinuacaoPreNatal,
          problemasCidadao
        ),
      ]
    : []

const createPreNatalProblemasCondicoesAnterioresCalculation = (
  avaliacao: MetaPath<AvaliacaoFormModel>,
  isMedico: boolean,
  dataNascimentoCidadao: LocalDate,
  dataAtendimento: Instant,
  ciapCidPreNatal: CiapCidPreNatal,
  isCidadaSemGestacaoAtiva: boolean,
  problemasCidadao?: Problema[]
): Calculation => ({
  field: avaliacao.problemasECondicoes.absolutePath(),
  updates: {
    [meta.problemasECondicoes.absolutePath()]: (
      problemasCondicoes: ProblemaCondicaoModel[],
      allValues: SoapState,
      prevValues: SoapState
    ) => {
      let problemasCondicoesAnteriores =
        resolveValue<ProblemaCondicaoModel[]>(allValues, meta.problemasECondicoes) ?? []
      const problemasCondicoesValoresAntigos =
        resolveValue<ProblemaCondicaoModel[]>(prevValues, avaliacao.problemasECondicoes) ?? []

      const hasProblemaNaoEvolucaoGravidezAltoRisco = hasProblemaCondicaoNaoEvolucaoGravidezAltoRiscoAtivoOuLatente(
        problemasCondicoes
      )

      const deveAdicionarW78NosProblemasCondicoesAnteriores =
        hasProblemaNaoEvolucaoGravidezAltoRisco &&
        !hasProblemaCondicaoComCiapW78(problemasCondicoesAnteriores) &&
        !hasProblemaComCiapW78Ativo(problemasCidadao)

      const hasProblemaW78 =
        hasProblemaCondicaoComCiapW78(problemasCondicoesValoresAntigos) &&
        hasProblemaCondicaoComCiapW78(problemasCondicoes)

      if (isCidadaSemGestacaoAtiva && !hasProblemaW78) {
        if (deveAdicionarW78NosProblemasCondicoesAnteriores) {
          return addProblemaCondicaoAutomatico(
            problemasCondicoesAnteriores,
            createProblemaCondicaoAutomaticoPreNatal(
              ciapCidPreNatal,
              isMedico,
              SituacaoProblema.ATIVO,
              null,
              null,
              dataNascimentoCidadao,
              null,
              dataAtendimento
            )
          )
        } else {
          const isProblemaNaoEvolucaoGravidezAltoRiscoExcluido =
            !hasProblemaNaoEvolucaoGravidezAltoRisco &&
            hasProblemaCondicaoNaoEvolucaoGravidezAltoRiscoAtivoOuLatente(problemasCondicoesValoresAntigos)

          if (isProblemaNaoEvolucaoGravidezAltoRiscoExcluido) {
            return removeProblemaCondicao(problemasCondicoesAnteriores, isMedico, {
              ciapToRemove: ciapCidPreNatal.ciap?.codigo,
              cidToRemove: ciapCidPreNatal.cid?.codigo,
            })
          }
        }
      }
      return problemasCondicoesAnteriores
    },
  },
})

const createEncerrarGestacaoDataDesfechoCalculation = (
  avaliacao: MetaPath<AvaliacaoFormModel>,
  isMedico: boolean,
  dataNascimentoCidadao: LocalDate,
  dataAtendimento: Instant,
  ciapCidPreNatal: CiapCidPreNatal,
  problemasCidadao?: Problema[]
): Calculation => ({
  field: avaliacao.encerrarGestacao.dataDesfecho.absolutePath(),
  updates: {
    [meta.problemasECondicoes.absolutePath()]: (
      dataDesfecho: LocalDate,
      allValues: SoapState,
      prevValues: SoapState
    ) => {
      let listaProblemasCondicoesAnteriores =
        resolveValue<ProblemaCondicaoModel[]>(allValues, meta.problemasECondicoes) ?? []
      const dataDesfechoAnterior = resolveValue<string>(prevValues, meta.avaliacao.encerrarGestacao.dataDesfecho)

      const problemaPreNatalJaExistente = findProblemaComCiapW78(problemasCidadao)

      if (dataDesfecho !== dataDesfechoAnterior) {
        listaProblemasCondicoesAnteriores = handleApagarDesfecho(
          listaProblemasCondicoesAnteriores,
          isMedico,
          ciapCidPreNatal
        )
      }
      if (
        dataDesfecho &&
        isDataDesfechoValid(
          dataDesfecho,
          dataNascimentoCidadao,
          dataAtendimento,
          problemaPreNatalJaExistente?.ultimaEvolucao?.dataInicio
        )
      ) {
        return handleAdicionarDesfecho(
          listaProblemasCondicoesAnteriores,
          isMedico,
          dataNascimentoCidadao,
          dataDesfecho,
          ciapCidPreNatal,
          problemaPreNatalJaExistente
        )
      }
      return listaProblemasCondicoesAnteriores
    },
  },
})

const handleAdicionarDesfecho = (
  listaProblemasCondicoesAnteriores: ProblemaCondicaoModel[],
  isMedico: boolean,
  dataNascimentoCidadao: LocalDate,
  dataDesfecho: LocalDate,
  ciapCidPreNatal: CiapCidPreNatal,
  problemaPreNatalJaExistente: Problema
) =>
  addProblemaCondicaoAutomatico(
    listaProblemasCondicoesAnteriores,
    createProblemaCondicaoAutomaticoPreNatal(
      ciapCidPreNatal,
      isMedico,
      SituacaoProblema.RESOLVIDO,
      problemaPreNatalJaExistente?.cid10,
      problemaPreNatalJaExistente,
      dataNascimentoCidadao,
      dataDesfecho
    )
  )

const handleApagarDesfecho = (
  listaProblemasCondicoesAnteriores: ProblemaCondicaoModel[],
  isMedico: boolean,
  ciapCidPreNatal: CiapCidPreNatal
) =>
  removeProblemaCondicao(listaProblemasCondicoesAnteriores, isMedico, {
    ciapToRemove: ciapCidPreNatal.ciap?.codigo,
    situacaoToRemove: SituacaoProblema.RESOLVIDO,
    isAutomaticoToRemove: true,
  })

const isDataDesfechoValid = (
  dataDesfecho: string,
  dataNascimentoCidadao: string,
  dataAtendimento: Instant,
  dataInicioGestacao?: string
): boolean => {
  const dataDesfechoAsDate = toDate(dataDesfecho)
  return (
    isAfter(dataDesfecho, dataNascimentoCidadao) &&
    isBeforeOrEqual(dataDesfechoAsDate, dataAtendimento) &&
    ((dataInicioGestacao && isAfterOrEqual(dataDesfechoAsDate, toDate(dataInicioGestacao))) ?? true)
  )
}
