import { Form, FormProps, FormRenderProps, parse } from 'components/form'
import { FormApi } from 'final-form'
import { TipoFrequencia, UnidadeMedidaTempoEnum } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React, { useCallback, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { MedicamentoFormModel } from '../MedicamentoFormModel'
import { prescricaoFormValidator as validator } from '../utils/prescricaoFormValidator'
import AdicionarMedicamentoFormPanel from './AdicionarMedicamentoFormPanel'

export interface PrescricaoMedicamentoFormProps extends FormProps<MedicamentoFormModel> {
  isReplicar: boolean
  medicamentoToEdit: MedicamentoFormModel
  onClearEditedMedicamento(newEditedValue: MedicamentoFormModel): void
  onVerifyMedicamentoDuplicated(medicamento: MedicamentoFormModel): boolean
  scrollModal(): void
}

export default function PrescricaoMedicamentoForm(props: PrescricaoMedicamentoFormProps) {
  const {
    onSubmit,
    isReplicar,
    medicamentoToEdit,
    onClearEditedMedicamento,
    onVerifyMedicamentoDuplicated,
    scrollModal,
  } = props

  const {
    atendimentoProfissional: { iniciadoEm: dataAtendimento },
  } = useAtendimentoContext()

  const defaultValue: Partial<MedicamentoFormModel> = {
    id: uuidv4(),
    dataInicioTratamento: parse(dataAtendimento),
    escalaDuracao: UnidadeMedidaTempoEnum.DIAS,
    tipoFrequencia: TipoFrequencia.INTERVALO,
  }

  const handleOnSubmit = useCallback(
    (medicamentoToAdd: MedicamentoFormModel, formApi: FormApi) => {
      onSubmit(medicamentoToAdd, formApi)
      formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
      setTimeout(formApi.reset)
      onClearEditedMedicamento(null)
    },
    [onClearEditedMedicamento, onSubmit]
  )

  const render = useCallback(
    (renderProps: FormRenderProps<MedicamentoFormModel>) => {
      return (
        <AdicionarMedicamentoFormPanel
          medicamentoToEdit={medicamentoToEdit}
          onClearEditedMedicamento={onClearEditedMedicamento}
          isEdit={!!medicamentoToEdit}
          isReplicar={isReplicar}
          onVerifyMedicamentoDuplicated={onVerifyMedicamentoDuplicated}
          {...renderProps}
        />
      )
    },
    [isReplicar, medicamentoToEdit, onClearEditedMedicamento, onVerifyMedicamentoDuplicated]
  )

  return useMemo(
    () => (
      <Form<MedicamentoFormModel>
        render={render}
        initialValues={medicamentoToEdit || defaultValue}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          },
        }}
        validate={validator(dataAtendimento)}
        onSubmit={handleOnSubmit}
        onSubmitSucceeded={scrollModal}
        onSubmitFailed={scrollModal}
      />
    ),
    [dataAtendimento, defaultValue, handleOnSubmit, medicamentoToEdit, render, scrollModal]
  )
}
