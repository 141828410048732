import { Text, TextColor, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { JustificativaCancelamentoAgendado } from 'graphql/types.generated'
import React from 'react'
import { formatDateAndHoursMinutes } from 'util/date/formatDate'

interface AgendamentoCidadaoJustificativaCancelamentoProps {
  justificativa: JustificativaCancelamentoAgendado
  color?: TextColor
}

export function AgendamentoCidadaoJustificativaCancelamento(props: AgendamentoCidadaoJustificativaCancelamentoProps) {
  const {
    justificativa: { justificativa, data, lotacao },
    color,
  } = props

  return (
    <VFlow vSpacing={0.25}>
      <HLabel title='Justificativa cancelamento:' color={color}>
        <Text fontStyle='italic' color={color}>
          {justificativa}
        </Text>
      </HLabel>
      {lotacao && (
        <HLabel title={`Cancelado em ${formatDateAndHoursMinutes(data)} por:`} color={color}>
          {lotacao.profissional.nome} - {lotacao.cbo.nome.capitalize()}
        </HLabel>
      )}
    </VFlow>
  )
}
