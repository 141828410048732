import { Alert, DateRange, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import useFirebase from 'components/firebase/useFirebase'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { PivotTable } from 'components/pivot-table/PivotTable'
import { useTreeBuilder } from 'components/pivot-table/useTreeBuilder'
import React, { useCallback, useEffect, useState } from 'react'

import { BiTableWrapper } from '../components/BiTableWrapper'
import { atendimentoProfissionalKeyMapping } from './atendimentoKeyMapping'
import { AtendimentoProfissional } from './AtendimentoProfissional'

const operations = {
  build: '/bi/atendimento-profissional/build',
  meta: '/bi/atendimento-profissional/meta',
}

const NAME = 'Relatório gerencial de atendimentos'

export function AtendimentosProfissionalView() {
  const { meta, setDateFilter, setCiapCidFilter, ...rest } = useTreeBuilder<AtendimentoProfissional>({
    keyMapping: atendimentoProfissionalKeyMapping,
    operations: operations,
  })

  const { analytics } = useFirebase()

  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()

  const loading = meta?.isEmpty === undefined || meta?.keyValues === undefined || meta?.numberKeys === undefined

  const handleRangeChange = useCallback(
    (dateRange: DateRange, periodUnit: string, filtroCiapCid: Set<string>) => {
      setDateFilter(dateRange, periodUnit)
      setCiapCidFilter(filtroCiapCid)
      setDateRangeFilter(dateRange)
    },
    [setCiapCidFilter, setDateFilter]
  )

  useEffect(() => {
    analytics.logEvent('relatorios_bi_selecionou_relatório', {
      relatorio: NAME,
    })
  }, [analytics])

  return (
    <>
      <Breadcrumb title={NAME} />

      <PageHeaderSection title={NAME} />

      <PageContent type='filled'>
        <VFlow>
          <Alert inline type='info'>
            O relatório gerencial de atendimentos apresenta a contagem dos atendimentos individuais e odontológicos
            realizados. A origem dos atendimentos deste relatório são o CDS e o PEC. Os atendimentos do tipo "Escuta
            inicial / Orientação" não serão apresentados.
          </Alert>
          <BiTableWrapper
            loading={loading}
            isEmpty={meta?.isEmpty}
            handleRangeSelect={handleRangeChange}
            relatorioAtendimentos
          >
            <PivotTable<AtendimentoProfissional>
              title={NAME}
              meta={meta}
              keyMapping={atendimentoProfissionalKeyMapping}
              {...rest}
              dateRangeFilter={dateRangeFilter}
            />
          </BiTableWrapper>
        </VFlow>
      </PageContent>
    </>
  )
}
