import { Cell, Grid } from 'bold-ui'
import { CboSelectField, CboSelectModel, CheckboxField, Form, FormDebouncedValueSpy, TextField } from 'components/form'
import { FormState } from 'final-form'
import { ProfissionaisQueryInput } from 'graphql/types.generated'
import React from 'react'
import { FormRenderProps } from 'react-final-form'
import { metaPath } from 'util/metaPath'

export interface ProfissionalTableFilterProps {
  initialValues?: ProfissionaisQueryInput
  onChange(values: ProfissionaisQueryInput): void
}

const meta = metaPath<ProfissionalTableFilterModel>()

interface ProfissionalTableFilterModel {
  query?: string
  mostrarSemLotacaoAtiva?: boolean
  cbo?: CboSelectModel
}

export class ProfissionalTableFilter extends React.Component<ProfissionalTableFilterProps> {
  render() {
    return (
      <Form<ProfissionalTableFilterModel>
        onSubmit={this.props.onChange}
        render={this.renderForm}
        initialValues={this.props.initialValues}
      />
    )
  }

  private handleChange = (formState: FormState<ProfissionalTableFilterModel>) => {
    this.props.onChange({
      query: formState.values.query,
      mostrarSemLotacaoAtiva: formState.values.mostrarSemLotacaoAtiva,
      cboId: formState.values.cbo?.id,
    })
  }

  private renderForm = (props: FormRenderProps) => {
    return (
      <>
        <FormDebouncedValueSpy onChange={this.handleChange} />

        <Grid alignItems='center' gap={1}>
          <Cell size={4}>
            <TextField name={meta.query} placeholder='Pesquise pelo nome, CNS ou CPF' icon='zoomOutline' />
          </Cell>
          <Cell size={3}>
            <CboSelectField name={meta.cbo} placeholder={`Todas as CBO's`} mostrarIndisponivelLotacao={false} />
          </Cell>
          <Cell size={4}>
            <CheckboxField name={meta.mostrarSemLotacaoAtiva} label='Mostrar profissionais sem lotação ativa' />
          </Cell>
        </Grid>
      </>
    )
  }
}
