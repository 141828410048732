export function isCboMedico(codigoCbo: string): boolean {
  return CBO_MEDICO.find((cbo) => codigoCbo.startsWith(cbo))?.length > 0
}

export function isCboCirurgiaoDentista(codigoCbo: string): boolean {
  return CBO_CIRURGIAO_DENTISTA.find((cbo) => codigoCbo.startsWith(cbo))?.length > 0
}

export function isCboMedicoOuCirurgiaoDentista(codigoCbo: string): boolean {
  return isCboMedico(codigoCbo) || isCboCirurgiaoDentista(codigoCbo)
}

const CBO_MEDICO = ['2251', '2252', '2253', '2231F9']
const CBO_CIRURGIAO_DENTISTA = ['2232']
