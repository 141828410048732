import { DateRange } from 'bold-ui'
import { isAfter, isBefore } from 'date-fns'
import moment from 'moment'
import { isBeforeOrEqual } from 'util/date/compare'
import { dateAsDdMmYyyy, toDate } from 'util/date/formatDate'

import { msg } from '..'

const asFormat = (val) => moment(val).format('YYYY-MM-DD')

export const beforeEqual130Years = (value: string): string => {
  if (!value) {
    return null
  }
  const today = moment().toDate()
  const minDate = new Date(today.getFullYear() - 130, today.getMonth(), today.getDate())
  const minDateFormatted = asFormat(minDate)
  if (minDateFormatted > value) {
    return msg('beforeEqual130Years', value)
  }
  return null
}

export const beforeEqualToday = (value: string): string => {
  if (!value) {
    return null
  }
  const maxDateFormatted = asFormat(moment().toDate())

  if (maxDateFormatted < value) {
    return msg('beforeEqualToday', value)
  }
  return null
}

export const periodBeforeEqualToday = (value: DateRange): string => {
  if (!value?.startDate && !value?.endDate) {
    return null
  }

  const hasError = (date: LocalDate | Date): boolean => {
    if (!date) {
      return null
    }
    const maxDateFormatted = asFormat(moment().toDate())
    const dateString = moment(date).format('YYYY-MM-DD')
    return maxDateFormatted < dateString
  }

  const hasErrorStartDate = hasError(value.startDate)
  const hasErrorEndDate = hasError(value.endDate)

  if (hasErrorStartDate && hasErrorEndDate) {
    return `Datas devem ser anteriores ou iguais à data atual.`
  } else if (hasErrorStartDate) {
    return `Data início ${msg('beforeEqualToday', value.startDate).toLowerCase()}`
  } else if (hasErrorEndDate) {
    return `Data fim ${msg('beforeEqualToday', value.endDate).toLowerCase()}`
  }
  return null
}

export const afterEqualToday = (value: string): string => {
  if (!value) {
    return null
  }
  const maxDateFormatted = asFormat(moment().toDate())
  if (maxDateFormatted > value) {
    return msg('afterEqualToday', value)
  }
  return null
}

export const afterEqualDate = (reference: Date) => (value: string): string => {
  if (!value) {
    return null
  }
  const maxDateFormatted = asFormat(reference)
  if (maxDateFormatted > value) {
    return msg('afterEqualTo', reference, dateAsDdMmYyyy(reference))
  }
  return null
}

export const dataNasc = [beforeEqual130Years, beforeEqualToday]

export const beforeEqualTo = (value: string, dateToCompare: string, text: string): string => {
  if (!value) {
    return null
  }

  const valueFormatted = toDate(value)
  const dateToCompareFormatted = toDate(dateToCompare)

  if (isAfter(valueFormatted, dateToCompareFormatted)) {
    return msg('beforeEqualTo', valueFormatted, text)
  }

  return null
}

export const afterEqualTo = (value: string, dateToCompare: string, text: string): string => {
  if (!value) {
    return null
  }

  const valueFormatted = toDate(value)
  const dateToCompareFormatted = toDate(dateToCompare)

  if (isBefore(valueFormatted, dateToCompareFormatted)) {
    return msg('afterEqualTo', valueFormatted, text)
  }

  return null
}

export const afterBirthdate = (value: string, birthDate: string): string => {
  if (!value) {
    return null
  }

  const valueFormatted = toDate(value)
  const birthDateFormatted = toDate(birthDate)

  if (isBefore(valueFormatted, birthDateFormatted)) {
    return msg('afterBirthdate', value)
  }

  return null
}

export const afterBirthdateAndBeforeEqualToAtendimentoDate = (
  value: string,
  birthDate: string,
  atendimentoDate: Instant
): string => {
  if (!value) return null

  const valueFormatted = toDate(value)
  const birthDateFormatted = toDate(birthDate)

  if (isBeforeOrEqual(valueFormatted, birthDateFormatted)) {
    return msg('afterBirthdate', value)
  }

  if (isAfter(valueFormatted, atendimentoDate)) {
    return msg('beforeEqualTo', valueFormatted, 'data do atendimento')
  }

  return null
}
