import { useFlagsQuery } from 'graphql/hooks.generated'
import React, { useContext } from 'react'

interface FlagsContextModel {
  UNIFICACAO_BASE_ENABLED: Boolean
  LISTA_REGISTRO_TARDIO_NOVA_ENABLED: Boolean
  ENCAMINHAMENTOS_ATENDIMENTO_ENABLED: Boolean
}

interface FlagsProviderProps {
  children: React.ReactNode
}

export const FlagsContext = React.createContext<FlagsContextModel>(undefined)

export function FlagsProvider({ children }: FlagsProviderProps) {
  const {
    data: { info: { flags } = {} },
  } = useFlagsQuery({
    fetchPolicy: 'cache-first',
  })

  return <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
}

export const useFlags = () => useContext(FlagsContext)
