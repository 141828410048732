import { Calculation } from 'final-form-calculate'
import { MetaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'
import { AtendimentoVacinacaoState } from 'view/atendimentos/atendimento-vacinacao/model'
import { EscutaInicialState } from 'view/atendimentos/escuta-inicial/model'

import { AgendarConsultaPanelModel } from '../components/AgendarConsultaPanel'

export const createAgendarConsultaCalculations = (name: MetaPath<AgendarConsultaPanelModel>): Calculation[] => [
  {
    field: name.lotacao.absolutePath(),
    updates: {
      [name.data.absolutePath()]: (
        lotacaoValue: string,
        allValues: SoapState | EscutaInicialState | AtendimentoVacinacaoState
      ) =>
        lotacaoValue &&
        (isSoapState(allValues)
          ? allValues.desfecho.agendamentoConsulta.data
          : isEscutaState(allValues)
          ? allValues.agendamentoConsulta.data
          : isVacinacaoState(allValues) && allValues.desfechoVacinacao.agendamentoConsulta.data),
    },
  },
  {
    field: name.data.absolutePath(),
    updates: {
      [name.horario.absolutePath()]: (
        dataValue: string,
        allValues: SoapState | EscutaInicialState | AtendimentoVacinacaoState
      ) =>
        dataValue &&
        (isSoapState(allValues)
          ? allValues.desfecho.agendamentoConsulta.horario
          : isEscutaState(allValues)
          ? allValues.agendamentoConsulta.horario
          : isVacinacaoState(allValues) && allValues.desfechoVacinacao.agendamentoConsulta.horario),
    },
  },
]

const isSoapState = (value: any): value is SoapState => !!value?.desfecho?.agendamentoConsulta
const isEscutaState = (value: any): value is EscutaInicialState => !!value?.agendamentoConsulta
const isVacinacaoState = (value: any): value is AtendimentoVacinacaoState =>
  !!value?.desfechoVacinacao?.agendamentoConsulta
