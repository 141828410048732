/** @jsx jsx */
import { jsx } from '@emotion/core'
import { DateRange, Grid } from 'bold-ui'
import { CboSelectModel, EquipeSelectModel, ProfissionalSelectModel } from 'components/form'
import { CiapSelectFieldModel } from 'components/form/field/select/CiapSelectField/CiapSelectField'
import { CidSelectFieldModel } from 'components/form/field/select/CidSelectField/CidSelectField'
import {
  SubtipoAtendimentoSelectModel,
  SubtipoRecord,
} from 'components/form/field/select/SubtipoAtendimentoSelectField/SubtipoAtendimentoSelectField'
import { format } from 'date-fns'

import { HistoricoAtendimentoAdvancedFilterFormModel } from '../../model/historicoFilterFormModel'
import HistoricoAtendimentoFilterItem from './HistoricoAtendimentoFilterItem'

export interface HistoricoAtendimentoActiveFiltersProps {
  filters: HistoricoAtendimentoAdvancedFilterFormModel
}

const periodoText = ({ startDate, endDate }: DateRange) => {
  return startDate && endDate && `(${format(startDate, 'dd/MM/yyyy')} até ${format(endDate, 'dd/MM/yyyy')})`
}

const subTiposText = (subtipos: SubtipoAtendimentoSelectModel[]) =>
  subtipos.map((subtipo) => SubtipoRecord[subtipo]).join(', ')

const cbosText = (cbos: CboSelectModel[]) => cbos.map((cbo) => cbo.nome).join(', ')

const equipeText = (equipes: EquipeSelectModel[]) => equipes.map((equipe) => equipe.nome).join(', ')

const profissionaisText = (profissionais: ProfissionalSelectModel[]) =>
  profissionais.map((profissional) => profissional.nome).join(', ')

const ciapsText = (ciaps: CiapSelectFieldModel[]) => ciaps.map((ciap) => ciap.nome).join(', ')

const cidsText = (cids: CidSelectFieldModel[]) => cids.map((cid) => cid.nome).join(', ')

export default function HistoricoAtendimentoActiveFilters({ filters }: HistoricoAtendimentoActiveFiltersProps) {
  const { periodo, subtiposAtendimento, cbos, equipes, profissionais, ciaps2, cids10 } = filters

  return (
    <Grid alignItems='center' justifyContent='flex-start' gap={0.25} gapVertical={0.25} wrap={false}>
      {periodo?.startDate && periodo?.endDate && (
        <HistoricoAtendimentoFilterItem label='Período' text={periodoText(periodo)} filters={filters} />
      )}
      {subtiposAtendimento && subtiposAtendimento.length !== 0 && (
        <HistoricoAtendimentoFilterItem
          label='Subtipo do atendimento'
          text={subTiposText(subtiposAtendimento)}
          filters={filters}
        />
      )}
      {cbos && cbos.length !== 0 && (
        <HistoricoAtendimentoFilterItem label='CBO' text={cbosText(filters.cbos)} filters={filters} />
      )}
      {equipes && equipes.length !== 0 && (
        <HistoricoAtendimentoFilterItem label='Equipe' text={equipeText(equipes)} filters={filters} />
      )}
      {profissionais && profissionais.length !== 0 && (
        <HistoricoAtendimentoFilterItem
          label='Profissional'
          text={profissionaisText(profissionais)}
          filters={filters}
        />
      )}
      {ciaps2 && ciaps2.length !== 0 && (
        <HistoricoAtendimentoFilterItem label='CIAP2' text={ciapsText(ciaps2)} filters={filters} />
      )}
      {cids10 && cids10.length !== 0 && (
        <HistoricoAtendimentoFilterItem label='CID10' text={cidsText(cids10)} filters={filters} />
      )}
    </Grid>
  )
}
