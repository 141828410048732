import ApolloClient from 'apollo-client'
import { resolveValue } from 'components/form/final-form/useField'
import { Calculation } from 'final-form-calculate'
import { SituacaoProblema } from 'graphql/types.generated'
import { MetaPath } from 'util/metaPath'
import { CiapCidPreNatal, SoapState } from 'view/atendimentos/atendimento-individual/model'
import { RegistroAvaliacaoPuericultura } from 'view/atendimentos/model'

import { Problema } from '../aside/types/ProblemaModel'
import { ObjetivoFormModel } from '../objetivo'
import { FormAtivoObjetivoEnum } from '../objetivo/components/SwitchButtonObjetivoForm'
import { createPreNatalAvaliacaoCalculations } from '../pre-natal/calculator'
import { CidPreNatalModel } from '../pre-natal/model'
import { getCidPrimeiroAtendimentoPreNatal } from '../pre-natal/util'
import { AvaliacaoFormModel } from './AvaliacaoForm'
import { ProblemaCondicaoModel } from './components/problemas-condicoes/model'
import {
  addProblemaCondicaoAutomatico,
  createProblemaCondicaoAutomatico,
  createProblemaCondicaoAutomaticoPreNatal,
  findProblemaByCiapIdCidId,
  removeProblemaCondicao,
  removeProblemasCondicoesDePreNatal,
} from './components/problemas-condicoes/utils/operations'
import { hasProblemaCondicaoDePreNatal } from './components/problemas-condicoes/utils/verifications'

export const createAvaliacaoCalculations = (
  objetivo: MetaPath<ObjetivoFormModel>,
  avaliacao: MetaPath<AvaliacaoFormModel>,
  problemaCondicaoPuericultura: RegistroAvaliacaoPuericultura,
  isMedico: boolean,
  apollo: ApolloClient<object>,
  prontuarioId: ID,
  ciapCidPreNatal: CiapCidPreNatal,
  dataNascimentoCidadao: LocalDate,
  dataAtendimento: Instant,
  hasPermissionPreNatal: boolean,
  isContinuacaoPreNatal: boolean,
  problemasCidadao?: Problema[]
): Calculation[] => [
  ...createPreNatalAvaliacaoCalculations(
    avaliacao,
    isMedico,
    dataNascimentoCidadao,
    dataAtendimento,
    ciapCidPreNatal,
    isContinuacaoPreNatal,
    hasPermissionPreNatal,
    problemasCidadao
  ),
  {
    field: objetivo.atendimentosEspecificos.formAtivo.absolutePath(),
    updates: {
      [avaliacao.problemasECondicoes.absolutePath()]: async (
        formAtivo: FormAtivoObjetivoEnum,
        allValues: SoapState,
        prevValues: SoapState
      ) => {
        let listaProblemasCondicoes =
          resolveValue<ProblemaCondicaoModel[]>(allValues, avaliacao.problemasECondicoes) ?? []
        const formEstavaAtivo = resolveValue<FormAtivoObjetivoEnum>(
          prevValues,
          objetivo.atendimentosEspecificos.formAtivo
        )

        let cidPrimeiroAtendimentoPreNatal = null
        if (formAtivo === FormAtivoObjetivoEnum.PRE_NATAL || formEstavaAtivo === FormAtivoObjetivoEnum.PRE_NATAL)
          cidPrimeiroAtendimentoPreNatal = await getCidPrimeiroAtendimentoPreNatal(prontuarioId, apollo)

        if (formAtivo !== null && formAtivo !== undefined) {
          listaProblemasCondicoes = handleFormAtivo(
            formAtivo,
            listaProblemasCondicoes,
            isContinuacaoPreNatal,
            problemaCondicaoPuericultura,
            isMedico,
            cidPrimeiroAtendimentoPreNatal,
            ciapCidPreNatal,
            dataNascimentoCidadao,
            problemasCidadao
          )
        }

        if (formEstavaAtivo !== null && formEstavaAtivo !== undefined) {
          listaProblemasCondicoes = handleFormEstavaAtivo(
            formEstavaAtivo,
            isMedico,
            problemaCondicaoPuericultura,
            listaProblemasCondicoes,
            isContinuacaoPreNatal
          )
        }

        return listaProblemasCondicoes
      },
    },
  },
]

const handleFormEstavaAtivo = (
  formEstavaAtivo: FormAtivoObjetivoEnum,
  isMedico: boolean,
  problemaCondicaoPuericultura: RegistroAvaliacaoPuericultura,
  listaProblemasCondicoes: ProblemaCondicaoModel[],
  isContinuacaoPreNatal: boolean
): ProblemaCondicaoModel[] => {
  switch (formEstavaAtivo) {
    case FormAtivoObjetivoEnum.PUERICULTURA: {
      return removeProblemaCondicao(listaProblemasCondicoes, isMedico, {
        ciapToRemove: problemaCondicaoPuericultura.ciap?.codigo,
        cidToRemove: problemaCondicaoPuericultura.cid10?.codigo,
      })
    }
    case FormAtivoObjetivoEnum.PRE_NATAL: {
      return removeProblemasCondicoesDePreNatal(listaProblemasCondicoes, isContinuacaoPreNatal)
    }
    default:
      return listaProblemasCondicoes
  }
}

const handleFormAtivo = (
  formAtivo: FormAtivoObjetivoEnum,
  listaProblemasCondicoes: ProblemaCondicaoModel[],
  isGestante: boolean,
  problemaCondicaoPuericultura: RegistroAvaliacaoPuericultura,
  isMedico: boolean,
  cidPrimeiroAtendimento: CidPreNatalModel,
  ciapCidPreNatal: CiapCidPreNatal,
  dataNascimentoCidadao: LocalDate,
  problemasCidadao?: Problema[]
): ProblemaCondicaoModel[] => {
  switch (formAtivo) {
    case FormAtivoObjetivoEnum.PRE_NATAL: {
      if (hasProblemaCondicaoDePreNatal(listaProblemasCondicoes, isGestante)) {
        return listaProblemasCondicoes
      } else {
        const problemaPreNatalJaExistente = findProblemaByCiapIdCidId(
          problemasCidadao,
          ciapCidPreNatal.ciap?.id,
          cidPrimeiroAtendimento?.id
        )
        return addProblemaCondicaoAutomatico(
          listaProblemasCondicoes,
          createProblemaCondicaoAutomaticoPreNatal(
            ciapCidPreNatal,
            isMedico,
            SituacaoProblema.ATIVO,
            cidPrimeiroAtendimento,
            problemaPreNatalJaExistente,
            dataNascimentoCidadao
          )
        )
      }
    }
    case FormAtivoObjetivoEnum.PUERICULTURA: {
      return addProblemaCondicaoAutomatico(
        listaProblemasCondicoes,
        createProblemaCondicaoAutomaticoPuericultura(problemaCondicaoPuericultura, isMedico)
      )
    }
    default:
      return listaProblemasCondicoes
  }
}

const createProblemaCondicaoAutomaticoPuericultura = (
  problemaCondicaoPuericultura: RegistroAvaliacaoPuericultura,
  isMedico: boolean
): ProblemaCondicaoModel =>
  createProblemaCondicaoAutomatico(problemaCondicaoPuericultura.ciap, problemaCondicaoPuericultura.cid10, isMedico)
