import { VFlow } from 'bold-ui'
import { Agendado, JustificativaCancelamentoAgendado } from 'graphql/types.generated'
import React from 'react'

import { AgendamentoCidadaoJustificativaCancelamento } from '../cidadao/list/components/AgendamentoCidadaoJustificativaCancelamento'
import { situacaoSincronizacaoAgendaOnlineRecord } from '../model'
import { AgendamentoCriacao, AgendamentoCriacaoProps } from './AgendamentoCriacao'
import { StatusSincronizacaoAgendaOnline } from './listagem/StatusSincronizacaoAgendaOnline'

export interface AgendamentoFooterProps extends AgendamentoCriacaoProps, Pick<Agendado, 'situacaoSincronizacao'> {
  isAgendaOnlineAtiva: boolean
  justificativaCancelamento?: JustificativaCancelamentoAgendado
}

export function AgendamentoFooter(props: AgendamentoFooterProps) {
  const { situacaoSincronizacao, isAgendaOnlineAtiva, justificativaCancelamento, color, ...agendamentoCriacao } = props

  const { _, ...syncStatusProps } = situacaoSincronizacaoAgendaOnlineRecord[situacaoSincronizacao] ?? {}

  return (
    <VFlow vSpacing={0.25}>
      <AgendamentoCriacao {...agendamentoCriacao} color={color} />
      {justificativaCancelamento && (
        <AgendamentoCidadaoJustificativaCancelamento justificativa={justificativaCancelamento} color={color} />
      )}
      {isAgendaOnlineAtiva && syncStatusProps?.text && (
        <StatusSincronizacaoAgendaOnline {...syncStatusProps} expanded />
      )}
    </VFlow>
  )
}
