import { VFlow } from 'bold-ui'
import React from 'react'

import { IdadeOrigemAtendimento, InformacoesAdministrativas } from '../../../model/historicoModel'
import HistoricoIdadeOrigemAtendimento from './HistoricoIdadeOrigemAtendimento'
import HistoricoInfoAdminPanel from './HistoricoInfoAdminPanel'

interface HistoricoGapPanelProps {
  idadeOrigemAtendimento?: IdadeOrigemAtendimento
  informacoesAdministrativas: InformacoesAdministrativas
  children: React.ReactNode
}

export default function HistoricoGapPanel({
  idadeOrigemAtendimento,
  children,
  informacoesAdministrativas,
}: HistoricoGapPanelProps) {
  return (
    <VFlow>
      {idadeOrigemAtendimento && <HistoricoIdadeOrigemAtendimento idadeOrigemAtendimento={idadeOrigemAtendimento} />}
      {children}
      <HistoricoInfoAdminPanel content={informacoesAdministrativas} />
    </VFlow>
  )
}
