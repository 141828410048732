import 'moment/locale/pt-br'

import axios from 'axios'
import { Grid, Input } from 'bold-ui'
import { alert } from 'components/alert'
import { handleError, handleValidationError } from 'components/error'
import { Form } from 'components/form'
import { FormState } from 'final-form'
import moment from 'moment'
import qs from 'qs'

export * from './history'

export default () => {
  moment.updateLocale('pt-BR')

  axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params, { skipNulls: true, allowDots: true, encode: false, arrayFormat: 'repeat' })
  }

  Input.defaultProps.autoComplete = 'off'

  Form.defaultProps.onSubmitFailed = (state: FormState<any>) => {
    alert('danger', state.submitError || 'Existem campos preenchidos de forma incorreta')
  }

  Form.defaultProps.transformResult = (result) => {
    if (!result) {
      return result
    }

    if (isPromise(result)) {
      return result
        .then((res) => Promise.resolve())
        .catch(handleValidationError)
        .catch(handleError)
    } else {
      return result
    }
  }

  Grid.defaultProps = {
    ...Grid.defaultProps,
    gap: {
      xs: 1,
      sm: 1,
      md: 1,
      lg: 2,
      xl: 2,
    },
  }
}

const isPromise = (arg: any): arg is Promise<any> => {
  return arg.catch !== undefined
}
