import { Alert, HFlow, Text, VFlow } from 'bold-ui'
import { DateTime } from 'components/date'
import { Interpolation } from 'emotion'
import { ConfiguracaoRndsHabilitadoQuery } from 'graphql/types.generated'
import React from 'react'

export function ConfiguracaoRndsDadosView({
  admMunicipal: {
    profissional: { nome },
  },
  dataHabilitacao,
  dataInclusao,
  dataValidadeCertificado,
  error,
}: ConfiguracaoRndsHabilitadoQuery['configuracaoRndsHabilitado']) {
  const wrapperStyles: Interpolation = {
    border: 'none',
    borderRadius: '0',
    background: 'none',
    padding: '0px',
    minHeight: '0px',
  }

  return (
    <VFlow>
      <HFlow alignItems='center' style={{ display: 'flex' }}>
        <Alert type='info' inline>
          Ao desabilitar o certificado digital a rede nacional de dados não será apresentada no sistema.
        </Alert>
        <Text style={{ flexGrow: 1, textAlign: 'right' }}>
          {`Habilitado por ${nome} em `}
          <DateTime value={dataHabilitacao} />
        </Text>
      </HFlow>
      {error && (
        <Alert type='danger' inline>
          {error}
        </Alert>
      )}
      <Text>
        {`Certificado incluído no servidor em `}
        <DateTime value={dataInclusao} />
      </Text>
      <Alert
        type='warning'
        inline
        styles={{
          wrapper: wrapperStyles,
        }}
      >
        {`Certificado válido até `}
        <DateTime value={dataValidadeCertificado} />
      </Alert>
    </VFlow>
  )
}
