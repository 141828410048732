import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { MetaPath } from 'util/metaPath'
import { meta } from 'view/atendimentos/atendimento-individual/model'

import { AntecedentesFormModel } from '../antecedentes/AntecedentesForm'
import { AvaliacaoFormModel } from '../avaliacao/AvaliacaoForm'
import { DesfechoFormModel } from '../desfecho'
import { ObjetivoFormModel } from '../objetivo'
import { PlanoFormModel } from '../plano/PlanoForm'
import { SubjetivoFormModel } from '../subjetivo/SubjetivoForm'

export type SoapFormModel =
  | AntecedentesFormModel
  | SubjetivoFormModel
  | ObjetivoFormModel
  | AvaliacaoFormModel
  | PlanoFormModel
  | DesfechoFormModel

interface SoapConfig {
  /**
   * Secoes do SOAP que nao devem aparecer
   */
  hiddenSections: MetaPath<SoapFormModel>[]
}

export const soapConfig: Partial<Record<TipoAtendimentoProfissional, SoapConfig>> = {
  [TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS]: {
    hiddenSections: [meta.avaliacao],
  },
}
