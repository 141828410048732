import { Alert, VFlow } from 'bold-ui'
import { ProcedimentoOdontoSelectModel } from 'components/form/field/select/ProcedimentoOdontoSelectField'
import { resolveName, UseFieldProps } from 'components/form/final-form/useField'
import { FormApi } from 'final-form'
import { ParteBucalEnum, SituacaoCoroaEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import React, { useEffect, useState } from 'react'
import { Field, FieldRenderProps, useForm } from 'react-final-form'
import { MetaPath } from 'util/metaPath'
import { isObjectDeepEmpty } from 'util/object'

import { getDentePosicaoProtese, getParteBucal, isCoroaDisabled, possuiLesaoDeFurca } from '../../../util'
import { CoroaFaceEnum } from '../../odontograma/Coroa'
import { Odontograma } from '../../odontograma/Odontograma'
import { CoroaFaceModel, FillColor } from '../../odontograma/types/Coroa'
import { CondicoesDenteFieldModel } from '../components/CondicoesDenteField'
import { convertDentesFieldModelToDentesModel } from '../converter'
import { condicoesDeLesaoDeFurca, OdontogramaSetActiveRef } from '../model'
import { OdontogramaFieldModel } from '../OdontogramaField'
import DenteFormPopper, { DenteFormModel } from './DenteFormPopper'
import { CoroaFieldModel } from './types'

export interface DenteFieldModel {
  condicoesDente: CondicoesDenteFieldModel
  procedimentos?: ProcedimentoOdontoSelectModel[]
  observacoes: string[]
  coroa?: CoroaFieldModel<SituacaoCoroaEnum>
  lastUpdate: Instant
}

interface DentesFieldProps extends UseFieldProps {
  name: MetaPath<Record<ParteBucalEnum, DenteFieldModel>>
  fillColors: FillColor<SituacaoCoroaEnum>[]
  cidadaoDataNascimento: LocalDate
  dataAtendimento: LocalDate
  showDeciduos: boolean
  activeRef: { ref: SVGSVGElement | HTMLButtonElement }
  activeDente: ParteBucalEnum
  proteseTotalSuperior: boolean
  proteseTotalInferior: boolean
  initialValue?: OdontogramaFieldModel['dentes']
  setActiveDente(dente: ParteBucalEnum): void
  setActiveRef: OdontogramaSetActiveRef
}

export const getCoroasDisableds = (values: [ParteBucalEnum, DenteFieldModel][]) => {
  return values.map(([key, dente]) => isCoroaDisabled(dente) && key).filter((i) => !!i)
}

export default function DentesField(props: DentesFieldProps) {
  const {
    name,
    fillColors,
    proteseTotalSuperior,
    proteseTotalInferior,
    showDeciduos,
    initialValue,
    cidadaoDataNascimento,
    dataAtendimento,
    activeDente,
    activeRef,
    setActiveDente,
    setActiveRef,
  } = props

  const [popperOpen, setPopperOpen] = useState(false)
  const [prevActive, setPrevActive] = useState<ParteBucalEnum>()

  const { getServerTimeNow } = useServerTime()

  const {
    mutators: { changeValue },
  } = useForm()

  const updateCoroaFace = (face: CoroaFaceModel) => {
    if (face?.activeColor) {
      const index =
        fillColors.indexOf(
          fillColors.find((color) => {
            return color.id === face.activeColor.id
          })
        ) + 1

      return { ...face, activeColor: fillColors[index] }
    } else {
      return { ...face, activeColor: fillColors[0] }
    }
  }

  useEffect(() => {
    if (activeDente && activeRef) {
      setPopperOpen(true)
      if (prevActive !== activeDente) setPrevActive(activeDente)
    }
  }, [activeDente, activeRef, prevActive])

  const renderField = (fieldProps: FieldRenderProps<OdontogramaFieldModel['dentes']>) => {
    const {
      input: { value },
      meta: { error },
    } = fieldProps

    const fieldValues = Object.entries(value) as [ParteBucalEnum, DenteFieldModel][]
    const disabledCoroas = getCoroasDisableds(fieldValues)

    const handleChange = (newValues: [ParteBucalEnum, DenteFieldModel][]) => {
      const o = Object.fromEntries(new Map([...fieldValues, ...newValues] as [ParteBucalEnum, DenteFieldModel][]))
      changeValue(name, o)
    }

    const getDenteValue = (dente: ParteBucalEnum) => {
      const o = Object.entries(value).find(([key]) => key === dente)
      return o && o[1]
    }

    const onClickCoroa = (dente: ParteBucalEnum, face: CoroaFaceModel, faceEnum: CoroaFaceEnum) => {
      const denteValue = getDenteValue(dente)
      const newFace = updateCoroaFace(face)

      handleChange([
        [
          dente,
          {
            ...denteValue,
            coroa: {
              faceTop: faceEnum === CoroaFaceEnum.FaceTop ? newFace : denteValue?.coroa?.faceTop,
              faceLeft: faceEnum === CoroaFaceEnum.FaceLeft ? newFace : denteValue?.coroa?.faceLeft,
              faceRight: faceEnum === CoroaFaceEnum.FaceRight ? newFace : denteValue?.coroa?.faceRight,
              faceBottom: faceEnum === CoroaFaceEnum.FaceBottom ? newFace : denteValue?.coroa?.faceBottom,
              faceCenter: faceEnum === CoroaFaceEnum.FaceCenter ? newFace : denteValue?.coroa?.faceCenter,
            } as CoroaFieldModel<SituacaoCoroaEnum>,
          } as DenteFieldModel,
        ],
      ])
    }

    const handleSubmit = (formValues: DenteFormModel, formApi: FormApi<DenteFormModel>) => {
      if (prevActive === activeDente) {
        const { replicarItems, ...values } = formValues

        if (values && formApi.getState().dirty) {
          handleChange([
            ...((replicarItems &&
              replicarItems.map(
                (replicarItem) =>
                  [
                    replicarItem.value,
                    {
                      ...value[replicarItem.value],
                      condicoesDente: possuiLesaoDeFurca(replicarItem.value)
                        ? values.condicoesDente
                        : values.condicoesDente.filter((item) => !condicoesDeLesaoDeFurca.includes(item)),
                    },
                  ] as [ParteBucalEnum, DenteFieldModel]
              )) ||
              []),
            [activeDente, { ...values, lastUpdate: getServerTimeNow().valueOf() }],
          ])
        }

        setPopperOpen(false)
        setActiveDente(undefined)
        setPrevActive(null)
      }
    }

    const dentesWithErrors = error && Object.keys(error).map((dente: ParteBucalEnum) => getParteBucal(dente).number)

    return (
      <VFlow>
        {!isObjectDeepEmpty(error) && (
          <Alert type='danger' inline>
            {dentesWithErrors.length > 1
              ? 'Os campos "Problemas/condições" dos dentes'
              : 'O campo "Problemas/condições" do dente'}{' '}
            {dentesWithErrors.join(', ')} {dentesWithErrors.length > 1 ? 'estão' : 'está'} com opções inválidas.
          </Alert>
        )}

        <Odontograma
          activeDente={activeDente}
          showDeciduos={showDeciduos}
          fillColors={fillColors}
          error={error}
          disabledCoroas={disabledCoroas}
          value={{
            proteseTotalSuperior: proteseTotalSuperior,
            proteseTotalInferior: proteseTotalInferior,
            dentes: convertDentesFieldModelToDentesModel(value),
          }}
          onClickDente={setActiveDente}
          onClickCoroa={onClickCoroa}
          setActiveRef={setActiveRef}
        />

        {activeDente && activeRef?.ref && (
          <DenteFormPopper
            open={popperOpen}
            placement='right'
            cidadaoDataNascimento={cidadaoDataNascimento}
            dataAtendimento={dataAtendimento}
            dentesValues={value}
            initialValues={value[activeDente]}
            anchorRef={activeRef?.ref}
            parteBucalEnum={activeDente}
            protese={getDentePosicaoProtese(activeDente) === 'superior' ? proteseTotalSuperior : proteseTotalInferior}
            proteseTotalSuperior={proteseTotalSuperior}
            proteseTotalInferior={proteseTotalInferior}
            onSubmit={handleSubmit}
          />
        )}
      </VFlow>
    )
  }

  return (
    <Field
      name={resolveName(name)}
      render={renderField}
      initialValue={initialValue}
      subscription={{ value: true, error: true }}
    />
  )
}
