/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import {
  Button,
  ExternalStyles,
  HFlow,
  Icon,
  TableCell,
  TableRow,
  Tag,
  Text,
  TextColor,
  Theme,
  Tooltip,
  useTheme,
} from 'bold-ui'
import { DateTime } from 'components/date'
import { LoteImunobiologico } from 'graphql/types.generated'
import moment from 'moment'
import { Fragment, ReactNode } from 'react'

import { LoteImunobiologicoTableDropDown } from './LoteImunobiologicoTableDropdown'

interface LoteImunobiologicoStaticRowProps {
  row: LoteImunobiologico
  reload(): Promise<any>
  onEditClick(): void
  style?: ExternalStyles
}

export default function LoteImunobiologicoStaticRow(props: LoteImunobiologicoStaticRowProps) {
  const { row, reload, onEditClick, style } = props

  const theme = useTheme()
  const classes = createStyles(theme)

  const renderLote = (
    <HFlow hSpacing={0.5} alignItems='center'>
      <Text>{row.lote}</Text>
      {!row.ativo && <Tag>Inativo</Tag>}
    </HFlow>
  )

  const renderFabricante = <Text>{row.fabricante.nome}</Text>
  const renderImunobiologico = (
    <Text>
      {row.imunobiologico.sigla} - {row.imunobiologico.nome}
    </Text>
  )

  const renderValidade = () => {
    let icon: ReactNode
    let color: TextColor

    if (moment().isSameOrBefore(row.validade, 'date')) {
      icon = <Icon style={classes.successIcon} icon='checkCircleOutline' />
      color = 'success'
    } else {
      icon = <Icon style={classes.errorIcon} icon='banOutline' />
      color = 'danger'
    }

    return (
      <HFlow hSpacing={0.2} alignItems='center'>
        {icon}
        <Text color={color}>
          <DateTime format='DD/MM/YYYY' value={row.validade} />
        </Text>
      </HFlow>
    )
  }

  const renderButtons = (
    <Fragment>
      <Tooltip text='Editar'>
        <Button size='small' skin='ghost' title='Editar' onClick={onEditClick}>
          <Icon icon='penOutline' />
        </Button>
      </Tooltip>
      <LoteImunobiologicoTableDropDown loteImunoItem={row} reload={reload} />
    </Fragment>
  )

  return (
    <TableRow style={style}>
      <TableCell>{renderLote}</TableCell>
      <TableCell>{renderFabricante}</TableCell>
      <TableCell>{renderImunobiologico}</TableCell>
      <TableCell>{renderValidade()}</TableCell>
      <TableCell style={classes.tableCell}>{renderButtons}</TableCell>
    </TableRow>
  )
}

const createStyles = (theme: Theme) => ({
  successIcon: css`
    font-size: 1rem;
    color: ${theme.pallete.status.success.main};
  `,
  errorIcon: css`
    font-size: 1rem;
    color: ${theme.pallete.status.danger.main};
  `,
  tableCell: css`
    white-space: nowrap;
  `,
})
