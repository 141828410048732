/* eslint-disable no-extend-native */
export {}

declare global {
  interface Array<T> {
    /**
     * Function to return the last element of the array
     *
     * @returns T
     */
    last(): T
  }
}

Array.prototype.last = function <T>(): T {
  const length = this.length
  if (length > 0) {
    return this[length - 1]
  } else return null
}
