import { Cell, Grid, HFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { RichTextField } from 'components/form'
import { RadioGroupField } from 'components/form/field/RadioGroupField'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React, { useMemo } from 'react'
import { MetaPath } from 'util/metaPath'

import { MedicoesPanel, MedicoesPanelModel } from '../../../components/MedicoesPanel/MedicoesPanel'
import { PeriodoGestacaoModel } from '../../components/modals/types/PeriodoGestacaoModel'
import { PreNatalFormModel, TipoPreNatal } from '../pre-natal/model'
import { grupoCboMedicoes, grupoCboResultadosExames } from './acessos'
import { DumObjetivoForm } from './components/DumObjetivoForm'
import { NecessidadesEspeciaisField } from './components/NecessidadesEspeciaisField'
import {
  SwitchButtonObjetivoForm,
  SwitchButtonObjetivoFormModel,
  SwitchButtonObjetivoFormProps,
} from './components/SwitchButtonObjetivoForm'
import { ResultadosExamesModel } from './resultados-exames/model'
import { ResultadosExamesRootView } from './resultados-exames/ResultadosExamesRootView'

export interface ObjetivoFormModel {
  texto?: string
  dum?: string
  vacinacaoEmDia?: boolean
  medicoes?: MedicoesPanelModel
  necessidadesEspeciais?: boolean
  resultadosExames?: ResultadosExamesModel
  atendimentosEspecificos?: SwitchButtonObjetivoFormModel
}

export interface ObjetivoFormProps extends Omit<SwitchButtonObjetivoFormProps, 'name' | 'metasPreNatal'> {
  name: MetaPath<ObjetivoFormModel>
  isAtendimentoOdonto: boolean
  isAtendimentoProcedimentos: boolean
  metaPreNatal: MetaPath<PreNatalFormModel>
  gestacoes: PeriodoGestacaoModel[]
}

function ObjetivoForm(props: ObjetivoFormProps) {
  const {
    name,
    cidadao,
    dataAtendimento,
    isAtendimentoOdonto,
    prontuarioId,
    isAtendimentoProcedimentos,
    metaPreNatal,
    tipoPreNatal,
    metaProblemasCondicoes,
    gestacoes,
  } = props

  const {
    cidadao: { isGestante },
    atendimentoProfissional: { iniciadoEm },
  } = useAtendimentoContext()

  const showDum = useMemo(() => {
    return (
      !isAtendimentoProcedimentos &&
      cidadao.informarDum &&
      (!isGestante || tipoPreNatal === TipoPreNatal.PRIMEIRO_ATENDIMENTO_PRE_NATAL)
    )
  }, [cidadao.informarDum, isAtendimentoProcedimentos, isGestante, tipoPreNatal])

  return (
    <Grid>
      <Cell size={12}>
        <RichTextField
          name={name.texto}
          maxLength={4000}
          placeholder='Insira as demais informações relacionadas ao exame físico.'
        />
      </Cell>

      <Cell size={12} alignSelf='flex-start'>
        <SwitchButtonObjetivoForm
          name={name.atendimentosEspecificos}
          metasPreNatal={{
            metaPreNatal,
            metaDum: name.dum,
          }}
          cidadao={cidadao}
          prontuarioId={prontuarioId}
          dataAtendimento={dataAtendimento}
          tipoPreNatal={tipoPreNatal}
          metaProblemasCondicoes={metaProblemasCondicoes}
        />
      </Cell>

      {showDum && (
        <Cell size={8}>
          <DumObjetivoForm name={name.dum} tipoPreNatal={tipoPreNatal} />
        </Cell>
      )}
      <CheckPermission permission={grupoCboMedicoes.adicionar}>
        <Cell size={12}>
          <MedicoesPanel name={name.medicoes} dataAtendimento={iniciadoEm} cidadao={cidadao} gestacoes={gestacoes} />
        </Cell>
      </CheckPermission>

      <Cell size={12}>
        <HFlow hSpacing={1}>
          <RadioGroupField
            name={name.vacinacaoEmDia}
            label='Vacinação em dia?'
            options={[
              { value: 'true', label: 'Sim' },
              { value: 'false', label: 'Não' },
            ]}
            clearable={true}
          />
        </HFlow>
      </Cell>

      {isAtendimentoOdonto && (
        <Cell size={12}>
          <NecessidadesEspeciaisField name={name.necessidadesEspeciais} label='Paciente com necessidades especiais' />
        </Cell>
      )}
      {!isAtendimentoProcedimentos && (
        <CheckPermission permission={grupoCboResultadosExames.adicionar}>
          <Cell size={12}>
            <ResultadosExamesRootView
              name={name.resultadosExames}
              prontuarioId={prontuarioId}
              cidadaoSexo={cidadao.sexo}
              cidadaoDataNascimento={cidadao.dataNascimento}
              cidadaoIdentidadeGeneroDbEnum={cidadao.identidadeGeneroDbEnum}
            />
          </Cell>
        </CheckPermission>
      )}
    </Grid>
  )
}

export default React.memo(ObjetivoForm)
