import { HFlow, Text, VFlow } from 'bold-ui'
import React from 'react'

export function NewsModalBodyLinks() {
  return (
    <HFlow hSpacing={2}>
      <VFlow vSpacing={0.2}>
        <Text component='li'>Acompanhamentos</Text>
        <Text component='li'>Alergias</Text>
        <Text component='li'>Antecedentes</Text>
        <Text component='li'>Atendimentos</Text>
        <Text component='li'>Atendimento Odontológico</Text>
      </VFlow>
      <VFlow vSpacing={0.2}>
        <Text component='li'>Atestados</Text>
        <Text component='li'>Encaminhamentos e Orientações</Text>
        <Text component='li'>Escuta inicial / Pré-atendimento</Text>
        <Text component='li'>Exames</Text>
        <Text component='li'>Histórico</Text>
      </VFlow>
      <VFlow vSpacing={0.2}>
        <Text component='li'>Medicamentos</Text>
        <Text component='li'>Pré-natal / Puerpério</Text>
        <Text component='li'>Prontuário / Folha de Rosto</Text>
        <Text component='li'>Vacinação</Text>
      </VFlow>
    </HFlow>
  )
}
