import api from 'api'
import { ImpressaoAcompanhamentoPuericulturaInput } from 'api/impressaoAcompanhamentoPuericultura'
import { generatePdf } from 'util/generatePdf'

export const downloadAcompanhamentoPuericultura = async (input: ImpressaoAcompanhamentoPuericulturaInput) => {
  return await api.acompanhamentoPuericultura
    .imprimirAcompanhamentoPuericultura(input)
    .then((response) => {
      generatePdf(
        new Blob([response.data], { type: 'application/pdf' }),
        `AcompanhamentoPuericultura_${new Date().getTime()}.pdf`
      )
      return response
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('loadend', (e) => {
          reject((e.srcElement as any).result)
        })
        reader.readAsText(error.response.data)
      })
    })
}
