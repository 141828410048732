import {
  Dropdown,
  DropdownDivider,
  DropdownItem,
  focusBoxShadow,
  Heading,
  HFlow,
  Icon,
  Text,
  Theme,
  Tooltip,
  useStyles,
  VFlow,
} from 'bold-ui'
import { LogoutLinkWrapper } from 'components/auth/LogoutLinkWrapper'
import useSession from 'components/auth/useSession'
import { useErrorHandler } from 'components/error'
import useFirebase from 'components/firebase/useFirebase'
import { useExibirNotificacaoNovidadesMutation } from 'graphql/hooks.generated'
import React, { CSSProperties, useState } from 'react'
import { Link } from 'react-router-dom'
import { tipoAcesso } from 'types/enums'

import { ReactComponent as AngleDownAlert } from '../../images/novidades/icons/angle_down_alert.svg'
import { ReactComponent as AngleUpAlert } from '../../images/novidades/icons/angle_up_alert.svg'
import { ReactComponent as NotificationCircle } from '../../images/novidades/icons/notification_circle.svg'

export function UserMenu() {
  const { classes, css, theme } = useStyles(createStyles)

  const [targetRef, setTargetRef] = useState<HTMLDivElement>()

  const session = useSession({ fetchPolicy: 'cache-only' })

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const handleClose = () => setDropdownOpen(false)
  const handleClick = () => setDropdownOpen((state) => !state)
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      targetRef.click()
      e.preventDefault()
    }
  }

  const nomeProfissional = session.data.profissional.nome
  const nomeCbo = session.data.acesso.__typename === 'Lotacao' && session.data.acesso.cbo?.nome
  const nomeUnidade = session.data.acesso.__typename === 'Lotacao' && session.data.acesso.unidadeSaude.nome
  const nomeEquipe = session.data.acesso.__typename === 'Lotacao' && session.data.acesso.equipe?.nome
  const tipoDoAcesso = session.data.acesso.__typename !== 'Lotacao' && tipoAcesso[session.data.acesso.tipo]
  const notificaNovidades = session.data.profissional.usuario.notificaNovidadesVersao
  const { analytics } = useFirebase()
  const handleUserMenuClick = (opcao) => {
    analytics.logEvent('user_menu_click', { modulo_selecionado: opcao })
  }
  const handleRejection = useErrorHandler()

  const handleOnNovidadesDaVersaoClicked = () => {
    exibeNotificacaoMutation()
      .then(() => handleUserMenuClick('Novidades da versão'))
      .catch(handleRejection)
  }

  const [exibeNotificacaoMutation] = useExibirNotificacaoNovidadesMutation()

  return (
    <>
      <div
        ref={setTargetRef}
        className={css(classes.user)}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        aria-label='Menu do usuário'
        role='button'
      >
        <div style={{ maxWidth: 'auto', minWidth: '10.3125rem' }}>
          <Tooltip text={nomeProfissional}>
            <Text component='p' className={css(classes.ellipsis)}>
              {nomeProfissional}
            </Text>
          </Tooltip>
          <HFlow hSpacing={0.2} justifyContent='flex-end'>
            <Tooltip text={nomeUnidade || tipoDoAcesso}>
              <Text
                component='p'
                className={css(classes.ellipsis, classes.acesso)}
                style={{ maxWidth: nomeEquipe && '13.125rem' }}
              >
                {nomeUnidade}
                {tipoDoAcesso}
              </Text>
            </Tooltip>
            {nomeEquipe && (
              <>
                <Text component='span' className={css(classes.ellipsis, classes.acesso)}>
                  {'|'}
                </Text>
                <Tooltip text={nomeEquipe}>
                  <Text
                    component='p'
                    className={css(classes.ellipsis, classes.acesso)}
                    style={{ maxWidth: '11.875rem' }}
                  >
                    {nomeEquipe}
                  </Text>
                </Tooltip>
              </>
            )}
          </HFlow>
        </div>
        <Text component='span' className={css(notificaNovidades ? classes.indicatorNotification : classes.indicator)}>
          {notificaNovidades ? (
            dropdownOpen ? (
              <AngleUpAlert />
            ) : (
              <AngleDownAlert />
            )
          ) : dropdownOpen ? (
            <Icon icon='angleUp' />
          ) : (
            <Icon icon='angleDown' />
          )}
        </Text>
      </div>

      <Dropdown
        anchorRef={targetRef}
        open={dropdownOpen}
        onClose={handleClose}
        popperProps={{ placement: 'bottom-end' }}
        style={{ minWidth: targetRef?.clientWidth, width: '15.3125rem', padding: '0 0 calc(0.5rem - 1px) 0' }}
      >
        {session.data.profissional.acessos && session.data.profissional.acessos.length > 1 && (
          <>
            <div style={{ padding: 'calc(0.5rem - 2px) 1rem', backgroundColor: `${theme.pallete.surface.background}` }}>
              <VFlow vSpacing={0}>
                <HFlow hSpacing={0.5}>
                  <Icon icon='mapMarkerFilled' size={1} style={{ color: `${theme.pallete.primary.main}` }} />
                  <Heading level={4}>Acesso</Heading>
                </HFlow>
                {nomeCbo && (
                  <Text component='p' className={classes.info}>
                    {nomeCbo}
                  </Text>
                )}
                {nomeEquipe && (
                  <Text component='p' className={classes.info}>
                    {nomeEquipe}
                  </Text>
                )}
                <Text component='p' className={classes.info}>
                  {nomeUnidade || tipoDoAcesso}
                </Text>
              </VFlow>
            </div>
            <DropdownItem component={Link} to='/selecaoAcesso' data-testid='UserMenu.selecaoAcesso'>
              Trocar acesso
            </DropdownItem>
            <DropdownDivider />
          </>
        )}

        <DropdownItem
          component={Link}
          to='/alterarSenha'
          data-testid='UserMenu.alterarSenha'
          onClick={() => handleUserMenuClick('Alterar senha')}
        >
          Alterar senha do e-SUS APS PEC
        </DropdownItem>
        <DropdownItem
          component={Link}
          to='/linksUteis'
          data-testid='UserMenu.liksUteis'
          onClick={() => handleUserMenuClick('Links úteis')}
        >
          Links úteis
        </DropdownItem>
        <DropdownItem
          component={Link}
          to='/novidades'
          data-testid='UserMenu.novidadesVersao'
          onClick={() => handleOnNovidadesDaVersaoClicked()}
        >
          <HFlow hSpacing={0.5} alignItems='center'>
            {notificaNovidades && <NotificationCircle />}
            Novidades da versão
          </HFlow>
        </DropdownItem>
        <DropdownItem
          component={Link}
          to='/sobre'
          data-testid='UserMenu.sobre'
          onClick={() => handleUserMenuClick('Sobre')}
        >
          Sobre
        </DropdownItem>
        <DropdownItem
          component={Link}
          to='/termosUso'
          data-testid='UserMenu.termoUso'
          onClick={() => handleUserMenuClick('Termos de uso')}
        >
          Termos de uso
        </DropdownItem>
        <DropdownItem
          component={Link}
          to='/escolhaModulo'
          data-testid='UserMenu.escolhaModulo'
          onClick={() => handleUserMenuClick('Alterar módulo inicial')}
        >
          Alterar módulo inicial
        </DropdownItem>
        <DropdownDivider />
        <LogoutLinkWrapper>
          <DropdownItem
            data-testid='UserMenu.sair'
            style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem', display: 'flex', alignItems: 'center' }}
          >
            <Icon icon='openDoor' style={{ marginRight: '0.5rem' }} /> Sair
          </DropdownItem>
        </LogoutLinkWrapper>
      </Dropdown>
    </>
  )
}

const createStyles = (theme: Theme) => ({
  user: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.75rem 0.5rem 0.75rem 2rem',
    transition: '.2s all',
    '&:hover': {
      background: theme.pallete.primary.c20,
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 0,
      borderRadius: theme.radius.button,
      boxShadow: focusBoxShadow(theme, 'primary', 'inset'),
    },
  } as CSSProperties,
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.pallete.gray.c100,
    textAlign: 'right',
  } as CSSProperties,
  acesso: {
    fontWeight: 'bold',
  } as CSSProperties,
  info: {
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  } as CSSProperties,
  indicator: {
    marginLeft: '1rem',
    color: theme.pallete.gray.c100,
  } as CSSProperties,
  indicatorNotification: {
    marginLeft: '1rem',
    color: theme.pallete.gray.c100,
    'svg > path:not(:last-child)': { fill: theme.pallete.gray.c100 },
  } as CSSProperties,
})
