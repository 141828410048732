import { Cell, Grid, Heading, InfoLabel, Text, VFlow } from 'bold-ui'
import React from 'react'

import { SoapValidationModalItemModel } from './model'

interface SoapValidationFieldErrorProps {
  item: SoapValidationModalItemModel
  expanded: boolean
}

export const SoapValidationFieldError = (props: SoapValidationFieldErrorProps) => {
  const { item, expanded } = props

  if (!item.label && !item.error && !item.fields) return null

  const isFieldsExpanded = item.fields?.length === 1

  return (
    <>
      {item.fields?.length > 0 ? (
        <Cell size={12}>
          <VFlow vSpacing={0.5}>
            {item.showLabelOnModal && <Heading level={4}>{item.label}</Heading>}

            {item.error && <Text>{item.error}</Text>}

            <Grid gapVertical={0.5}>
              {item.fields.map((field) => (
                <SoapValidationFieldError key={field.label} item={field} expanded={isFieldsExpanded} />
              ))}
            </Grid>
          </VFlow>
        </Cell>
      ) : (
        <Cell size={expanded ? 12 : 6}>
          <InfoLabel title={item.showLabelOnModal ? item.label : ''}>{item.error}</InfoLabel>
        </Cell>
      )}
    </>
  )
}
