import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { createValidator, richTextMaxLength } from 'util/validation'

import { odontogramaValidator } from './evolucoes-odontologicas/components/odontograma-field/validator'
import { OdontologiaModel } from './evolucoes-odontologicas/EvolucoesOdontologicasView'
import { periogramaCompletoValidator } from './evolucoes-odontologicas/periograma/periograma-completo/periograma-completo-table-field/validator'
import { periogramaSimplificadoValidator } from './evolucoes-odontologicas/periograma/periograma-simplificado/validator'
import { PeriodontiaModel } from './evolucoes-odontologicas/periograma/PeriogramaView'
import { PlanoFormModel } from './PlanoForm'
import { planoProcedimentosValidator } from './validator/validatorProcedimentos'

const planoIndividualValidator = () =>
  createValidator<PlanoFormModel>({
    texto: [richTextMaxLength(4000)],
    odontologia: createValidator<OdontologiaModel>({
      periodontia: createValidator<PeriodontiaModel>({
        periogramaSimplificado: periogramaSimplificadoValidator,
        periogramaCompleto: periogramaCompletoValidator,
      }),
      odontograma: odontogramaValidator,
    }),
  })

export const planoValidator = (tipoAtendProf: TipoAtendimentoProfissional) => {
  switch (tipoAtendProf) {
    case TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS: {
      return planoProcedimentosValidator()
    }
    default: {
      return planoIndividualValidator()
    }
  }
}
