import api from 'api'
import { TipoExameEnum } from 'graphql/types.generated'
import { downloadFile } from 'util/downloadFile'

export interface SolicitacaoExamesProcedimento {
  idExame: ID
  observacoes?: string
}

export interface SolicitacaoExameReport {
  examesRequisitados: SolicitacaoExamesProcedimento[]
  justificativa: string
  observacoes?: string
  cid10?: ID
  tipoExame: TipoExameEnum
}

export interface ImpressaoSolicitacaoExamesInput {
  id?: ID
  idAtendimentoProfissional: ID
  prontuarioId: ID
  atendimentoId?: ID
  idCidadao: ID
  solicitacao?: SolicitacaoExameReport
}

export const downloadSolicitacaoExames = async (value: ImpressaoSolicitacaoExamesInput[], onSuccess?: () => void) => {
  return await api.solicitacaoExames
    .imprimirSolicitacaoExames({ solicitacoes: value })
    .then((response) => {
      onSuccess?.()
      downloadFile(new Blob([response.data]), 'Imprimir solicitação de exames.pdf')
      return response
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('loadend', (e) => {
          reject((e.srcElement as any).result)
        })
        reader.readAsText(error.response.data)
      })
    })
}
