import { gray, green, red, yellow } from 'bold-ui/lib/styles/colors'
import { EvolucoesProblemaModalQuery, SituacaoProblema } from 'graphql/types.generated'

import { ProblemaCondicaoModel } from '../../avaliacao/components/problemas-condicoes/model'

type AtendimentoProfissionalModel = EvolucoesProblemaModalQuery['problema']['evolucoes'][0]['atendimentoProfissional']

export interface CiapModel {
  id: string
  codigo: string
  descricao: string
}

export interface Problema extends Pick<ProblemaCondicaoModel, 'automatico'> {
  id: ID
  situacao?: SituacaoProblema
  ciap?: CiapModel
  cid10?: {
    id: string
    codigo: string
    nome: string
  }
  descricaoOutro?: string
  evolucoes?: EvolucaoProblema[]
  ultimaEvolucao?: EvolucaoProblema
  avaliadoAgora?: boolean
  registradoAgora?: boolean
}

export interface EvolucaoProblema {
  id: ID
  dataInicio?: LocalDate
  dataFim?: LocalDate
  situacao?: SituacaoProblema
  observacao?: string
  atualizadoEm?: Instant
  atendimentoProfissional?: AtendimentoProfissionalModel
  possuiCid?: boolean
  possuiCiap?: boolean
  problema?: Problema
}

export const situacaoProblemaRecord: Record<
  SituacaoProblema,
  { sigla?: string; descricao?: string; color?: string; background?: string }
> = {
  [SituacaoProblema.ATIVO]: {
    sigla: 'A',
    descricao: 'Ativo',
    color: gray.c100,
    background: red.c50,
  },
  [SituacaoProblema.LATENTE]: {
    sigla: 'L',
    descricao: 'Latente',
    color: gray.c10,
    background: yellow.c80,
  },
  [SituacaoProblema.RESOLVIDO]: {
    sigla: 'R',
    descricao: 'Resolvido',
    color: gray.c100,
    background: green.c40,
  },
}
