import { PrivateRoute } from 'components/route'
import React from 'react'
import { FormSpy } from 'react-final-form'
import { Route, Switch, useRouteMatch } from 'react-router'
import { meta, SoapState } from 'view/atendimentos/atendimento-individual/model'

import { ListaProblemasModal } from '../components/modals/lista-problemas/ListaProblemasModal'
import { grupoCboEncaminhamentoExterno } from './plano/acessos'
import EncaminhamentoExternoEdicaoModal from './plano/encaminhamento-externo/list/EncaminhamentoExternoEdicaoModal'
import { SoapFormProps } from './SoapView'

interface SoapRootViewProps
  extends Pick<
    SoapFormProps,
    | 'prontuarioId'
    | 'cidadao'
    | 'atendimentoProfissional'
    | 'dataAtendimento'
    | 'isAtendimentoOdonto'
    | 'somenteCiap'
    | 'ciapCidPreNatal'
  > {}

export function SoapRootView(props: SoapRootViewProps) {
  const { prontuarioId, cidadao, atendimentoProfissional, isAtendimentoOdonto, ciapCidPreNatal, somenteCiap } = props
  const match = useRouteMatch()

  return (
    <Switch>
      <Route
        path={`${match.url}/antecedentes/lista-problemas-condicoes`}
        render={(renderProps) => (
          <FormSpy<SoapState> subscription={{ values: true }}>
            {({ values: formValues }) => (
              <ListaProblemasModal
                {...renderProps}
                name={meta.problemasECondicoes}
                prontuarioId={prontuarioId}
                dataAtendimento={atendimentoProfissional.iniciadoEm}
                problemasAvaliacao={formValues?.avaliacao?.problemasECondicoes}
                problemasLPC={formValues?.problemasECondicoes}
                ciapCidPreNatal={ciapCidPreNatal}
                somenteCiap={somenteCiap}
                cidadao={cidadao}
              />
            )}
          </FormSpy>
        )}
      />

      <PrivateRoute
        path={`${match.url}/encaminhamentos-externos/editar/:id`}
        permission={grupoCboEncaminhamentoExterno}
        render={(renderProps) => (
          <FormSpy<SoapState> subscription={{ values: true }}>
            {(props) => (
              <EncaminhamentoExternoEdicaoModal
                {...renderProps}
                name={meta.plano.encaminhamentoExterno.encaminhamentosRecentes}
                cboCodigoAtendimento={atendimentoProfissional.lotacao.cbo.cbo2002}
                isAtendimentoOdonto={isAtendimentoOdonto}
                cidadaoSexo={cidadao.sexo}
                encaminhamentosRecentes={props.values?.plano?.encaminhamentoExterno?.encaminhamentosRecentes}
              />
            )}
          </FormSpy>
        )}
      />
    </Switch>
  )
}
