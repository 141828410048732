import { MedicoesInput, ObjetivoInput, ResultadoExameInput } from 'graphql/types.generated'
import { parseNumber } from 'util/number'
import { MedicoesPanelModel } from 'view/atendimentos/components/MedicoesPanel/MedicoesPanel'

import { FormAtivoObjetivoEnum } from './components/SwitchButtonObjetivoForm'
import { ObjetivoFormModel } from './ObjetivoForm'
import { convertPuericulturaToInput } from './puericultura/converter'
import {
  ResultadoExameComSolicitacaoModel,
  ResultadoExameEspecificoModel,
  ResultadoExameSemSolicitacaoModel,
  ResultadosExamesModel,
} from './resultados-exames/model'
import { getResultadoExameEspecificoConverterToInput, isExameEspecifico, removePrefix } from './resultados-exames/util'

export const convertObjetivoFormToInput = (form: ObjetivoFormModel): ObjetivoInput =>
  form && {
    texto: form.texto,
    medicoes: convertMedicoesToInput(form),
    necessidadesEspeciais: form.necessidadesEspeciais,
    puericultura:
      form.atendimentosEspecificos?.formAtivo === FormAtivoObjetivoEnum.PUERICULTURA
        ? convertPuericulturaToInput(form.atendimentosEspecificos.puericultura)
        : undefined,
    resultadosExame: form?.resultadosExames && convertResultadosExamesToInput(form.resultadosExames),
  }

export const convertMedicoesToInput = (form: ObjetivoFormModel): MedicoesInput => {
  const { openedAccordions, ...rest } = form.medicoes
  return {
    dum: form.dum,
    vacinacaoEmDia: form.vacinacaoEmDia,
    ...convertMedicoesPanelToInput(rest),
  }
}
export const convertMedicoesPanelToInput = (medicoes: MedicoesPanelModel): MedicoesInput => ({
  altura: parseNumber(medicoes?.altura),
  circunferenciaAbdominal: parseNumber(medicoes?.circunferenciaAbdominal),
  frequenciaCardiaca: parseNumber(medicoes?.frequenciaCardiaca),
  frequenciaRespiratoria: parseNumber(medicoes?.frequenciaRespiratoria),
  perimetroCefalico: parseNumber(medicoes?.perimetroCefalico),
  perimetroPanturrilha: parseNumber(medicoes?.perimetroPanturrilha),
  peso: parseNumber(medicoes?.peso),
  pressaoArterialDiastolica: parseNumber(medicoes?.pressaoArterialDiastolica),
  pressaoArterialSistolica: parseNumber(medicoes?.pressaoArterialSistolica),
  saturacaoO2: parseNumber(medicoes?.saturacaoO2),
  glicemia: parseNumber(medicoes?.glicemia),
  temperatura: parseNumber(medicoes?.temperatura),
  tipoGlicemia: medicoes?.tipoGlicemia,
})

type ConverterResultadoExameType = ResultadoExameComSolicitacaoModel &
  ResultadoExameSemSolicitacaoModel &
  ResultadoExameEspecificoModel

const convertResultadosExamesToInput = (values: ResultadosExamesModel): ResultadoExameInput[] => {
  const { resultadosComSolicitacao, resultadosSemSolicitacao } = values

  const resultadosComSolicitacaoInput = resultadosComSolicitacao
    ? Object.entries(resultadosComSolicitacao).map(([id, value]) =>
        convertResultadoExameToInput(value as ConverterResultadoExameType, id)
      )
    : []

  const resultadosSemSolicitacaoInput =
    Object.values(resultadosSemSolicitacao ?? {}).map((value) => convertResultadoExameToInput(value)) ?? []

  return [...resultadosComSolicitacaoInput, ...resultadosSemSolicitacaoInput]
}

const convertResultadoExameToInput = (value: ConverterResultadoExameType, id?: ID): ResultadoExameInput => {
  const { dataRealizado, dataResultado, dataSolicitado, descricao, exame = undefined, resultado } = value

  const isSemSolicitacao = !id
  const isEspecifico = isExameEspecifico(exame)

  const converter = getResultadoExameEspecificoConverterToInput(exame)

  return {
    id: !isSemSolicitacao ? removePrefix(id) : undefined,
    exame: isSemSolicitacao ? exame.id : undefined,
    dataResultado,
    dataRealizacao: dataRealizado,
    dataSolicitacao: dataSolicitado,
    resultado: isEspecifico ? descricao : resultado,
    especifico: isEspecifico ? converter(value) : undefined,
  }
}
