import { ConselhoClasseImpressao, PrinterType } from '../model'

export const convertDadosProfissionalPrint = (session: PrinterType) => {
  return {
    nomeProfissional: session.profissional.nome,
    nomeCbo: session.acesso.__typename === 'Lotacao' && session.acesso.cbo?.nome,
    nomeUnidadeSaude: session.acesso.__typename === 'Lotacao' && session.acesso.unidadeSaude.nome,
    nomeMunicipio: session.acesso.municipio.nome,
    nomeMunicipioUf: session.acesso.municipio.uf.sigla,
    conselhoClasse:
      session.acesso.__typename === 'Lotacao' &&
      ({
        numero: session.profissional?.numeroConselhoClasse,
        sigla: session.profissional.conselhoClasse?.sigla,
        uf: {
          sigla: session.profissional.ufEmissoraConselhoClasse?.sigla,
        },
      } as ConselhoClasseImpressao),
  }
}
