/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { DataTable, Text, VFlow } from 'bold-ui'
import { AntecedenteCirurgiasInternacoes } from 'graphql/types.generated'
import { formatDate } from 'util/date/formatDate'
import { humanizeAge } from 'util/date/humanize-age'
import { Observacao } from 'view/atendimentos/detail/components/Observacao'

interface HistoricoAntecedentesCirurgiasInternacoesTableProps {
  cirurgiasInternacoes: AntecedenteCirurgiasInternacoes[]
  dataNascimento: LocalDate
}

export function HistoricoAntecedentesCirurgiasInternacoesTable(
  props: HistoricoAntecedentesCirurgiasInternacoesTableProps
) {
  const { cirurgiasInternacoes, dataNascimento } = props
  const classes = createStyles()

  const renderCirurgiaInternacao = (item: AntecedenteCirurgiasInternacoes) => {
    return (
      <div css={item.observacoes && classes.padding}>
        {item.descricao}
        <Observacao observacao={item.observacoes} />
      </div>
    )
  }

  const renderData = (item: AntecedenteCirurgiasInternacoes) => {
    return (
      <Text style={classes.padding}>
        {item.dataCirurgiaInternacao
          ? formatDate(item.dataCirurgiaInternacao) + ' | ' + humanizeAge(dataNascimento, item.dataCirurgiaInternacao)
          : '-'}
      </Text>
    )
  }

  return (
    <VFlow vSpacing={0.5}>
      <Text fontWeight='bold'>Antecedentes hospitalares</Text>
      <DataTable<AntecedenteCirurgiasInternacoes>
        rows={cirurgiasInternacoes}
        columns={[
          {
            name: 'cirurgiaInternacao',
            header: 'Cirurgia e/ou internação',
            render: renderCirurgiaInternacao,
            style: classes.columnWidth,
          },
          {
            name: 'dataRealizada',
            header: 'Data | Idade',
            render: renderData,
          },
        ]}
      />
    </VFlow>
  )
}

const createStyles = () => ({
  padding: css`
    padding-top: 0.7rem;
    padding-bottom: 0.2rem;
  `,
  columnWidth: css`
    width: 50%;
  `,
})
