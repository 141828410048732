import { VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Breadcrumb } from 'components/breadcrumb'
import { useErrorHandler } from 'components/error'
import { PageContent } from 'components/layout/PageContent'
import { confirm } from 'components/modals/confirm'
import { PageHeader } from 'components/PageHeader'
import { useAlterarLotacaoMutation, useLotacaoEditViewQuery } from 'graphql/hooks.generated'
import { LotacaoEditViewQuery } from 'graphql/types.generated'
import * as React from 'react'
import { Redirect, useHistory, useRouteMatch } from 'react-router'

import convertModelToInput from './convertModelToInput'
import { LotacaoForm, LotacaoFormModel } from './LotacaoForm'

export interface UrlParams {
  profissionalId: ID
  lotacaoId: ID
}
export const LotacaoAlterarView = () => {
  const alert = useAlert()
  const { params } = useRouteMatch<UrlParams>()
  const history = useHistory()
  const handleRejection = useErrorHandler()

  const {
    data: { lotacao },
  } = useLotacaoEditViewQuery({
    variables: { id: params.lotacaoId },
  })

  const [alterarLotacao] = useAlterarLotacaoMutation()
  const handleSalvarLotacao = (values: LotacaoFormModel) => {
    const input = convertModelToInput(values)
    const perfis = values.perfis.map((p) => p.nome).join(', ')
    const perfisAD = perfis.includes(' AD')
    const perfilCoordenadorAD = perfis.includes('COORDENADOR AD')

    if (perfisAD && !input.equipe && !perfilCoordenadorAD) {
      confirm({
        title: 'Esta lotação não está associada a nenhuma equipe de atenção domiciliar.',
        body:
          'Para que os recursos de atenção domiciliar sejam acessados é necessário que a lotação esteja vinculada a um perfil AD e uma equipe de atenção domiciliar.',
        type: 'danger',
        onConfirm: () => {
          handleAlterarLotacao(values)
        },
      })()
    } else {
      handleAlterarLotacao(values)
    }
  }

  const handleAlterarLotacao = (values: LotacaoFormModel) => {
    alterarLotacao({
      variables: {
        input: {
          ativo: values.ativo,
          perfis: values.perfis && values.perfis.map((p) => p.id),
          atualizaPerfil: values.atualizaPerfil,
          id: params.lotacaoId,
        },
      },
    })
      .finally(handleSubmitSuccess)
      .catch(handleRejection)
  }

  if (!lotacao) {
    return null
  }

  if (!lotacao.actions.permissaoEdicao.enabled) {
    return <Redirect to='/403' />
  }

  const handleSubmitSuccess = () => {
    alert('success', 'Lotação alterada com sucesso.')
    history.push(`/profissional/${params.profissionalId}/dados`)
  }

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Visualização' to={`/profissional/${params.profissionalId}/dados`} />
      <Breadcrumb title='Edição de lotação' />

      <VFlow vSpacing={1.5}>
        <PageHeader title='Edição de lotação' subtitle={lotacao && lotacao.profissional && lotacao.profissional.nome} />
        <LotacaoForm
          initialValues={lotacao && initialValues(lotacao, params.profissionalId)}
          onSubmit={handleSalvarLotacao}
          disableFields={true}
          // TODO: Verificar se a lotação possui atendimento ou agenda cadastrada
        />
      </VFlow>
    </PageContent>
  )
}
const initialValues = (lotacao: LotacaoEditViewQuery['lotacao'], profissional: ID) => ({
  profissional,
  ativo: lotacao.ativo,
  cbo: lotacao.cbo,
  equipe: lotacao.equipe,
  perfis: lotacao.perfis,
  unidadeSaude: lotacao.unidadeSaude,
  atualizaPerfil: lotacao.atualizaPerfil,
})
