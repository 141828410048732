import { Button, Heading, HFlow, Icon, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { Box } from 'components/Box'
import { useErrorHandler } from 'components/error'
import useFirebase from 'components/firebase/useFirebase'
import { LoadingIndicator } from 'components/loading'
import { confirm } from 'components/modals/confirm'
import theme from 'config/theme'
import {
  useConfiguracaoRndsHabilitadoQuery,
  useContraChaveAtivacaoMunicipioQuery,
  useExcluirConfiguracaoRndsMutation,
  useSalvarConfiguracaoRndsMutation,
} from 'graphql/hooks.generated'
import React, { useEffect, useState } from 'react'

import { ConfiguracaoRndsCreateView } from './ConfiguracaoRndsCreateView'
import { ConfiguracaoRndsDadosView } from './ConfiguracaoRndsDadosView'
import { RndsCertificadoSelectModel } from './RndsCertificadoSelectField'

export default interface ConfiguracaoRndsFormModel {
  certificadoSelect: RndsCertificadoSelectModel
  senhaCertificado: string
  idSolicitante: string
  error: string
}

export function ConfiguracoesRndsRootView() {
  const { data, refetch, loading } = useConfiguracaoRndsHabilitadoQuery()
  const { data: dataContraChave, loading: loadinContraChave } = useContraChaveAtivacaoMunicipioQuery()
  const [configuracaoRnds, setConfiguracaoRnds] = useState(data.configuracaoRndsHabilitado)
  const [save] = useSalvarConfiguracaoRndsMutation()
  const [excluirConfiguracaoRnds] = useExcluirConfiguracaoRndsMutation()
  const handleRejection = useErrorHandler()
  const alert = useAlert()
  const { analytics } = useFirebase()

  useEffect(() => setConfiguracaoRnds(data.configuracaoRndsHabilitado), [data, setConfiguracaoRnds])

  const handleSubmit = ({
    senhaCertificado,
    idSolicitante,
    certificadoSelect: { path: pathCertificado },
  }: ConfiguracaoRndsFormModel) => {
    return save({
      variables: {
        configuracaoRndsInput: {
          senhaCertificado,
          pathCertificado,
          idSolicitante,
        },
      },
    }).then(({ data: { salvarConfiguracaoRnds } }) => {
      setConfiguracaoRnds(salvarConfiguracaoRnds)
      analytics.logEvent('habilita_rnds')
      alert('success', 'Certificado habilitado com sucesso.')
    })
  }

  const handleExcluir = () => {
    confirm({
      title: `Deseja desabilitar o RNDS?`,
      confirmLabel: 'Desabilitar',
      type: 'danger',
      onConfirm: () => {
        return excluirConfiguracaoRnds({ variables: { configuracaoRndsId: configuracaoRnds.id } })
          .then(() => {
            alert('success', 'Configuração RNDS desabilidata.')
            analytics.logEvent('desabilita_rnds')
          })
          .then(() => refetch())
          .then((value) => setConfiguracaoRnds(value.data.configuracaoRndsHabilitado))
          .catch(handleRejection)
      },
    })()
  }

  const iconToolTipText =
    'A Rede Nacional de Dados em Saúde (RNDS) é uma plataforma nacional de integração de dados' +
    ' em saúde. Ao habilitar o certificado digital a rede nacional de dados será apresentada no sistema.'

  const iconStyle = {
    display: 'block',
    '&:hover': {
      color: theme.pallete.primary.main,
    },
  }

  return (
    <Box>
      <VFlow>
        <HFlow alignItems='center' style={{ position: 'relative' }}>
          <Heading level={2} style={{ display: 'inline' }}>
            Rede Nacional de Dados em Saúde - RNDS
          </Heading>
          <Tooltip text={iconToolTipText} placement='auto'>
            <Icon style={iconStyle} icon='infoCircleFilled' size={1} />
          </Tooltip>
          {configuracaoRnds && (
            <Button kind='primary' size='small' style={{ position: 'absolute', right: '0px' }} onClick={handleExcluir}>
              Desabilitar
            </Button>
          )}
        </HFlow>
        {loading || loadinContraChave ? (
          <LoadingIndicator />
        ) : configuracaoRnds ? (
          <ConfiguracaoRndsDadosView {...configuracaoRnds} />
        ) : (
          <ConfiguracaoRndsCreateView
            internetHabilitada={data.conexao?.internet?.habilitado}
            handleSubmit={handleSubmit}
            contraChave={dataContraChave?.contraChaveAtivacaoMunicipio}
          />
        )}
      </VFlow>
    </Box>
  )
}
