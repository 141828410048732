export const NEWS_ITEMS = [
  {
    title: 'Estabilização 5.0',
    to: '#versao-5-0-estabilizacao',
    hashChildren: [
      '#estabilizacao-5-0-21',
      '#estabilizacao-5-0-20',
      '#estabilizacao-5-0-19',
      '#estabilizacao-5-0-18',
      '#estabilizacao-5-0-17',
      '#estabilizacao-5-0-16',
      '#estabilizacao-5-0-15',
      '#estabilizacao-5-0-14',
      '#estabilizacao-5-0-13',
      '#estabilizacao-5-0-12',
      '#estabilizacao-5-0-11',
      '#estabilizacao-5-0-10',
      '#estabilizacao-5-0-9',
      '#estabilizacao-5-0-8',
    ],
    children: [
      {
        title: '5.0.21',
        to: '#estabilizacao-5-0-21',
      },
      {
        title: '5.0.20',
        to: '#estabilizacao-5-0-20',
      },
      {
        title: '5.0.19',
        to: '#estabilizacao-5-0-19',
      },
      {
        title: '5.0.18',
        to: '#estabilizacao-5-0-18',
      },
      {
        title: '5.0.17',
        to: '#estabilizacao-5-0-17',
      },
      {
        title: '5.0.16',
        to: '#estabilizacao-5-0-16',
      },
      {
        title: '5.0.15',
        to: '#estabilizacao-5-0-15',
      },
      {
        title: '5.0.14',
        to: '#estabilizacao-5-0-14',
      },
      {
        title: '5.0.13',
        to: '#estabilizacao-5-0-13',
      },
      {
        title: '5.0.12',
        to: '#estabilizacao-5-0-12',
      },
      {
        title: '5.0.11',
        to: '#estabilizacao-5-0-11',
      },
      {
        title: '5.0.10',
        to: '#estabilizacao-5-0-10',
      },
      {
        title: '5.0.9',
        to: '#estabilizacao-5-0-9',
      },
      {
        title: '5.0.8',
        to: '#estabilizacao-5-0-8',
      },
    ],
  },
  {
    title: 'Versão 5.0',
    to: '#versao-5-0',
    hashChildren: [
      '#alergias-5-0',
      '#atendimentos-5-0',
      '#atendimento-odonto-5-0',
      '#atestados-5-0',
      '#encaminhamento-orientacoes-5-0',
      '#escuta-inicial-5-0',
      '#exames-5-0',
      '#historico-5-0',
      '#medicamentos-5-0',
      '#pre-natal-5-0',
      '#prontuario-5-0',
      '#antecedentes-5-0',
      '#vacinacao-5-0',
      '#acompanhamentos-5-0',
    ],
    children: [
      {
        title: 'Acompanhamentos',
        to: '#acompanhamentos-5-0',
      },
      {
        title: 'Alergias',
        to: '#alergias-5-0',
      },
      {
        title: 'Antecedentes',
        to: '#antecedentes-5-0',
      },
      {
        title: 'Atendimentos',
        to: '#atendimentos-5-0',
      },
      {
        title: 'Atendimento Odontológico',
        to: '#atendimento-odonto-5-0',
      },
      {
        title: 'Atestados',
        to: '#atestados-5-0',
      },
      {
        title: 'Encaminhamentos e Orientações',
        to: '#encaminhamento-orientacoes-5-0',
      },
      {
        title: 'Escuta inicial / Pré-atendimento',
        to: '#escuta-inicial-5-0',
      },
      {
        title: 'Exames',
        to: '#exames-5-0',
      },
      {
        title: 'Histórico Clínico do Cidadão',
        to: '#historico-5-0',
      },
      {
        title: 'Medicamentos',
        to: '#medicamentos-5-0',
      },
      {
        title: 'Pré-natal / Puerpério',
        to: '#pre-natal-5-0',
      },
      {
        title: 'Prontuário / Folha de Rosto',
        to: '#prontuario-5-0',
      },
      {
        title: 'Vacinação',
        to: '#vacinacao-5-0',
      },
    ],
  },
  {
    title: 'Versão 4.5',
    to: '#versao-4-5',
    hashChildren: ['#agenda-4-5', '#agendamentos-cidacao-4-5', '#atendimento-vacinacao-4-5'],
    children: [
      {
        title: 'Agenda',
        to: '#agenda-4-5',
      },
      {
        title: 'Agendamentos do cidadão',
        to: '#agendamentos-cidacao-4-5',
      },
      {
        title: 'Atendimento de vacinação',
        to: '#atendimento-vacinacao-4-5',
      },
    ],
  },
]
