import { HeadingSection } from 'bold-ui'
import { Box } from 'components/Box'
import { useErrorHandler } from 'components/error'
import { Form, FormRenderProps } from 'components/form/final-form/Form'
import { usePrinter } from 'components/print'
import { useCidadaoDeclaracaoComparecimentoQuery } from 'graphql/hooks.generated'
import { useServerTime } from 'hooks/useServerTime'
import moment from 'moment'
import React from 'react'
import {
  DeclaracaoComparecimentoModel,
  DeclaracaoComparecimentoView,
  horarioEntradaCalculator,
  horarioSaidaCalculator,
  Periodo,
  validateDeclaracaoComparecimento,
} from 'view/atendimentos/list/actions/declaracao-comparecimento/DeclaracaoComparecimentoView'
import { DeclaracaoComparecimento } from 'view/atendimentos/list/actions/declaracao-comparecimento/ImpressaoDeclaracaoComparecimento'

export interface DeclaracaoComparecimentoModalFormProps {
  atendId?: ID
}

export const DeclaracaoComparecimentoFormView = ({ atendId }: DeclaracaoComparecimentoModalFormProps) => {
  const { getServerTimeNow } = useServerTime()

  const handleRejection = useErrorHandler()
  const { printPDF } = usePrinter()

  const {
    data: { atendimento },
  } = useCidadaoDeclaracaoComparecimentoQuery({ variables: { id: atendId } })

  const handleSubmit = (values: DeclaracaoComparecimentoModel) => {
    const docBody = DeclaracaoComparecimento({
      ...values,
      iniciadoEm: moment(values.iniciadoEm).format('DD/MM/YYYY'),
      dataPorExtenso: moment(getServerTimeNow()).format('DD [de] MMMM [de] YYYY').toLowerCase(),
    })

    printPDF(
      {
        docBody,
        documentTitle: 'DeclaracaoComparecimento-' + (values.cidadao.nomeSocial || values.cidadao.nome),
      },
      handleRejection
    )
  }

  const renderForm = (formProps: FormRenderProps<DeclaracaoComparecimentoModel>) => {
    return (
      <>
        <HeadingSection level={4} title='Declaração de comparecimento' vSpace={8} />
        <Box style={{ border: 'none', width: '34.75rem', padding: '0' }}>
          <DeclaracaoComparecimentoView onSubmit={formProps.handleSubmit} {...atendimento} />
        </Box>
      </>
    )
  }

  return (
    <Form<DeclaracaoComparecimentoModel>
      render={renderForm}
      onSubmit={handleSubmit}
      initialValues={{ ...atendimento, periodo: Periodo.MATUTINO }}
      validate={validateDeclaracaoComparecimento}
      decorators={[horarioEntradaCalculator(atendimento?.iniciadoEm), horarioSaidaCalculator()]}
    />
  )
}
