import { HFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import React from 'react'
import { OrigemAtendimentoRecord } from 'types/enums'
import { humanizeAge } from 'util/date/humanize-age'
import { humanizeDate } from 'util/date/humanize-date'

import { IdadeOrigemAtendimento } from '../../../model/historicoModel'

interface HistoricoIdadeOrigemAtendimentoProps {
  idadeOrigemAtendimento: IdadeOrigemAtendimento
}

export default function HistoricoIdadeOrigemAtendimento(props: HistoricoIdadeOrigemAtendimentoProps) {
  const { idadeOrigemAtendimento } = props

  const ocorreuEm = humanizeDate(idadeOrigemAtendimento.dataAtendimento)
  const titleAtendimentoOcorreu = ocorreuEm === 'Hoje' ? 'Atendimento ocorreu' : 'Atendimento ocorreu há'

  return (
    <HFlow hSpacing={0.25}>
      <HLabel title={`${titleAtendimentoOcorreu}:`}>{ocorreuEm}</HLabel>|
      <HLabel title='Registros com origem:'>{OrigemAtendimentoRecord[idadeOrigemAtendimento.origemAtendimento]}</HLabel>
      |
      <HLabel title='Idade do paciente no dia:'>
        {humanizeAge(idadeOrigemAtendimento.dataNascimentoCidadao, idadeOrigemAtendimento.dataAtendimento)}
      </HLabel>
    </HFlow>
  )
}
