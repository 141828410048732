import { ApolloQueryResult, FetchPolicy } from 'apollo-client'
import { SelectDownshiftRenderProps } from 'bold-ui'
import { useErrorHandler } from 'components/error'
import { DocumentNode } from 'graphql'
import { useApolloClient } from 'graphql/hooks'
import { PageParams } from 'graphql/types.generated'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'

export const DEFAULT_SELECT_SIZE = 50

export const DEFAULT_SELECT_PAGE_PARAM: Readonly<PageParams> = {
  size: DEFAULT_SELECT_SIZE,
  fetchPageInfo: false,
}

interface UseAsyncQuerySelectProps<OptionType, TData, TVariables> {
  query: DocumentNode

  variables(inputQuery: string): TVariables

  extractItems(data: TData): OptionType[]

  skip?(inputQuery: string): boolean

  fetchPolicy?: FetchPolicy
  debounceTime?: number
}

export function useAsyncQuerySelect<TOption, TData, TVariables>(
  props: UseAsyncQuerySelectProps<TOption, TData, TVariables>
) {
  const { query, variables, extractItems, skip, fetchPolicy, debounceTime } = props

  const apollo = useApolloClient()
  const handleRejection = useErrorHandler()
  const [data, setData] = useState<TData>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSkiping, setIsSkiping] = useState(false)
  const items = useMemo(() => extractItems(data), [data, extractItems])

  const refetch = useCallback(
    (queryVariables: TVariables, inputString?: string) => {
      if (!skip || !skip(inputString || '')) {
        setIsSkiping(false)
        setIsLoading(true)

        return apollo
          .query<TData, TVariables>({
            query,
            variables: queryVariables,
            fetchPolicy: fetchPolicy || 'network-only',
          })
          .then((res: ApolloQueryResult<TData>) => {
            setData(res.data)
            return res
          })
          .catch(handleRejection)
          .finally(() => setIsLoading(false))
      } else {
        setData(null)
        setIsSkiping(true)
        setIsLoading(false)
      }
    },
    [apollo, fetchPolicy, handleRejection, query, skip]
  )

  const debouncedRefetch = useMemo(() => debounce(refetch, debounceTime || 350), [refetch, debounceTime])

  const handleFilterChange = useCallback(
    (inputString: string, downshift: SelectDownshiftRenderProps<TOption>) => {
      //Carrega somente quando o dropdown está aberto
      if (downshift.isOpen) {
        if (inputString) {
          return debouncedRefetch(variables(inputString), inputString)
        } else {
          // Carrega imediatamente (sem debounce) caso filtro seja vazio
          return refetch(variables(inputString), inputString)
        }
      }
    },
    [debouncedRefetch, refetch, variables]
  )

  useEffect(() => {
    if (isSkiping) {
      debouncedRefetch.cancel()
    }
  }, [debouncedRefetch, isSkiping])

  const handleFocus = useCallback(() => setData(null), [])

  return {
    items,
    loading: isLoading,
    skiping: isSkiping || undefined,
    onFilterChange: handleFilterChange,
    onFocus: handleFocus,
  }
}
