import { alert } from 'components/alert'
import { FORM_ERROR } from 'final-form'
import { ErrorObject } from 'util/validation'

export const handleError = (error: any) => {
  if (error?.graphQLErrors) {
    error.graphQLErrors.forEach(onGraphQLError)
  }

  if (error?.networkError) {
    onNetworkError(error.networkError)
  }

  if (!error?.graphQLErrors && !error?.networkError) {
    onGenericError(error)
  }
}

export const handleValidationError = (error: any) => {
  if (error?.graphQLErrors?.[0]) {
    const firstError = error.graphQLErrors[0]

    if (isValidationError(firstError)) {
      const validationError = getValidationError(firstError)

      if (typeof validationError === 'string') {
        return { [FORM_ERROR]: validationError }
      } else {
        return validationError
      }
    }
  }

  throw error
}

export const isValidationError = (error: any) => {
  return error && error.extensions && error.extensions.validationError
}

export const getValidationError = (graphQLError: any): ErrorObject<any> | string | null => {
  return graphQLError.extensions.validationError
}

export const getValidationErrorMessage = (graphQLError: any): string | object | null => {
  const validationError = getValidationError(graphQLError)
  return typeof validationError === 'string' ? validationError : graphQLError.message
}

const onGraphQLError = (error: any) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('[GraphQL error]', error)
  }

  if (isValidationError(error)) {
    alert('danger', getValidationErrorMessage(error))
  } else if (error.message) {
    alert('danger', error.message)
  } else {
    alert('danger', 'Ocorreu um erro inesperado')
    throw error
  }
}

const onNetworkError = (error: any) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('[Network error]', error)
  }

  alert('danger', 'Não foi possível se conectar ao servidor. Cheque sua conexão e tente novamente.')
}

const onGenericError = (error: any) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('[Error]', error)
  }

  alert('danger', error?.response?.data ?? error?.message ?? error)
}
