/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, DateRange, Grid, HFlow, Icon, Radio, Text, Tooltip, useTheme, VFlow } from 'bold-ui'
import { Form } from 'components/form'
import {
  CiapCidSelectField,
  CiapCidSelectModel,
} from 'components/form/field/select/CiapCidSelectField/CiapCidSelectField'
import {
  GrupoCondicaoSelectField,
  GrupoCondicaoSelectModel,
} from 'components/form/field/select/GrupoCondicaoSelectField'
import { PageLoading } from 'components/loading'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'

import { RangeField } from './RangeField'

export interface BiTableWrapperProps {
  loading: boolean
  isEmpty: boolean
  children: ReactElement
  relatorioAtendimentos?: boolean
  handleRangeSelect: (dateRange: DateRange, periodUnit: string, filtroCiapCid: Set<string>) => void
}

export type periodUnit = 'DIA' | 'SEMANA' | 'MES'

export function BiTableWrapper(props: BiTableWrapperProps) {
  const { loading, isEmpty, children, relatorioAtendimentos, handleRangeSelect } = props

  const DIA = 'DIA',
    SEMANA = 'SEMANA',
    MES = 'MES'

  const [periodoSelecionado, setPeriodoSelecionado] = useState<number>(1)
  const [filtroCiapCid, setFiltroCiapCid] = useState<CiapCidSelectModel & CiapCidSelectModel[]>(null)
  const [filtroCiapCondicaoChange, setFiltroCiapCondicaoChange] = useState<boolean>(false)
  const [updateTable, setUpdateTable] = useState<boolean>(false)
  const [filtroGrupoCondicao, setFiltroGrupoCondicao] = useState<GrupoCondicaoSelectModel & GrupoCondicaoSelectModel[]>(
    null
  )

  const [dateRangeError, setDateRangeError] = useState<string>('')
  const [unidadePeriodoSelecionada, setUnidadePeriodo] = useState<periodUnit>(MES)
  const [dateRangeInput, setDateRangeInput] = useState<DateRange>({
    startDate: ranges[unidadePeriodoSelecionada][1].value,
    endDate: endDates[unidadePeriodoSelecionada],
  })

  const theme = useTheme()

  const hasDate = dateRangeInput.startDate != null && dateRangeInput.endDate != null

  const handleOnUnidadeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const unidadePeriodo = event.currentTarget.value as periodUnit
    setUnidadePeriodo(unidadePeriodo)
    setPeriodoSelecionado(1)
    setDateRangeInput({
      startDate: ranges[unidadePeriodo][1].value,
      endDate: endDates[unidadePeriodo],
    })
    setDateRangeError('')
  }

  const handleOnPeriodoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.currentTarget.value)
    setDateRangeError('')
    if (value !== 0) {
      const range = {
        startDate: ranges[unidadePeriodoSelecionada][value].value,
        endDate: endDates[unidadePeriodoSelecionada],
      }
      setDateRangeInput(range)
      handleRangeSelect(range, unidadePeriodoSelecionada, cidCiapCondicoes(filtroCiapCid, filtroGrupoCondicao))
    } else if (dateRangeInput?.startDate && dateRangeInput?.endDate) {
      handleDateRangeChange(dateRangeInput)
    }
    setPeriodoSelecionado(value)
  }

  const handleDateRangeChange = (range: DateRange) => {
    if (range.startDate && range.endDate) {
      const tempStartDate = new Date(range.startDate)
      let msgError = ''
      if (unidadePeriodoSelecionada === 'SEMANA') {
        tempStartDate.setDate(range.startDate.getDate() + 7 * 52)
        msgError = 'O período não deve ser superior a 52 semanas. Selecione outro período'
      } else {
        tempStartDate.setFullYear(range.startDate.getFullYear() + 1)
        msgError = 'O Período não deve ser superior à 1 ano, selecione outro período.'
      }
      if (tempStartDate.getTime() < range.endDate.getTime()) {
        setDateRangeError(msgError)
      } else {
        setDateRangeError('')
        handleRangeSelect(range, unidadePeriodoSelecionada, cidCiapCondicoes(filtroCiapCid, filtroGrupoCondicao))
      }
    } else {
      setDateRangeError('')
    }
    setDateRangeInput(range)
  }

  const handleCiapCidChange = (ciapCid: CiapCidSelectModel & CiapCidSelectModel[]) => {
    setFiltroCiapCid(ciapCid)
    setFiltroCiapCondicaoChange(true)

    if (hasDate && ((filtroCiapCid && ciapCid.length < filtroCiapCid.length) || ciapCid.length === 0)) {
      handleRangeSelect(dateRangeInput, unidadePeriodoSelecionada, cidCiapCondicoes(ciapCid, filtroGrupoCondicao))
    }
  }
  const handleGrupoCondicaoChange = (grupoCondicao: GrupoCondicaoSelectModel & GrupoCondicaoSelectModel[]) => {
    setFiltroGrupoCondicao(grupoCondicao)
    setFiltroCiapCondicaoChange(true)
    if (
      hasDate &&
      ((filtroGrupoCondicao && grupoCondicao.length < filtroGrupoCondicao.length) || grupoCondicao.length === 0)
    ) {
      handleRangeSelect(dateRangeInput, unidadePeriodoSelecionada, cidCiapCondicoes(filtroCiapCid, grupoCondicao))
    }
  }

  const manipCiap = (ciapCid: CiapCidSelectModel[]) => {
    if (ciapCid != null) {
      return new Set<string>(
        ciapCid.map((value: CiapCidSelectModel) => {
          return value.__typename + '-' + value.codigo
        })
      )
    } else {
      return new Set<string>()
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const manipCondicao = (grupoCondicao: GrupoCondicaoSelectModel[]): Set<string> => {
    if (grupoCondicao != null) {
      let ciapCid = new Set<string>()
      grupoCondicao.forEach((element) => {
        ciapCid = new Set<string>([...ciapCid.values(), ...manipCiap(element.ciaps).values()])
        ciapCid = new Set<string>([...ciapCid.values(), ...manipCiap(element.cids).values()])
      })
      return ciapCid
    } else {
      return new Set<string>()
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cidCiapCondicoes = (
    filtroCiapCid: CiapCidSelectModel & CiapCidSelectModel[],
    filtroGrupoCondicao: GrupoCondicaoSelectModel & GrupoCondicaoSelectModel[]
  ) => {
    return new Set<string>([...manipCiap(filtroCiapCid).values(), ...manipCondicao(filtroGrupoCondicao).values()])
  }

  useEffect(() => {
    handleRangeSelect(
      {
        startDate: ranges[unidadePeriodoSelecionada][1].value,
        endDate: endDates[unidadePeriodoSelecionada],
      },
      unidadePeriodoSelecionada,
      cidCiapCondicoes(filtroCiapCid, filtroGrupoCondicao)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleRangeSelect, unidadePeriodoSelecionada])

  useEffect(() => {
    if (updateTable && hasDate) {
      handleRangeSelect(dateRangeInput, unidadePeriodoSelecionada, cidCiapCondicoes(filtroCiapCid, filtroGrupoCondicao))
      setUpdateTable(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTable])

  const handleBlurCiapCid = () => {
    if (filtroCiapCondicaoChange && hasDate) {
      handleRangeSelect(dateRangeInput, unidadePeriodoSelecionada, cidCiapCondicoes(filtroCiapCid, filtroGrupoCondicao))
      setFiltroCiapCondicaoChange(false)
    }
  }

  return (
    <VFlow
      style={css`
        color: ${theme.pallete.gray.c20};
      `}
    >
      <HFlow hSpacing={0.3}>
        <Text variant='h5'>Período</Text>
        <Tooltip
          text={`Selecione o tipo de período desejado: Mensal, Semanal ou Personalizado (dias). Depois, selecione o período com as opções predefinidas ou marcando manualmente nos campos de início e fim.`}
          style={css`
            background: ${theme.pallete.gray.c20};
          `}
        >
          <Icon icon='infoCircleFilled' size={1} />
        </Tooltip>
      </HFlow>
      <Grid wrap>
        <HFlow>
          <Cell sm={6} xs={12}>
            <Radio
              name='unidade'
              label='Mensal'
              value={MES}
              checked={MES === unidadePeriodoSelecionada}
              onChange={handleOnUnidadeChange}
            />
          </Cell>
          <Cell sm={6} xs={12}>
            <Radio
              name='unidade'
              label='Semanal'
              value={SEMANA}
              checked={SEMANA === unidadePeriodoSelecionada}
              onChange={handleOnUnidadeChange}
            />
          </Cell>
          <Cell sm={12} xs={12}>
            <Radio
              name='unidade'
              label='Personalizado'
              value={DIA}
              checked={DIA === unidadePeriodoSelecionada}
              onChange={handleOnUnidadeChange}
            />
          </Cell>
        </HFlow>
      </Grid>
      <Grid wrap>
        <Cell sm={6}>
          <div
            css={css`
              border: 1px solid ${theme.pallete.gray.c80};
              padding: 0.5rem 0.5rem;
              width: 520px;
            `}
          >
            <HFlow>
              <Radio
                name='periodo'
                label={ranges[unidadePeriodoSelecionada][1].label}
                value={1}
                checked={1 === periodoSelecionado}
                onChange={handleOnPeriodoChange}
              />
              <Radio
                name='periodo'
                label={ranges[unidadePeriodoSelecionada][2].label}
                value={2}
                checked={2 === periodoSelecionado}
                onChange={handleOnPeriodoChange}
              />
              <Radio
                name='periodo'
                label={ranges[unidadePeriodoSelecionada][3].label}
                value={3}
                checked={3 === periodoSelecionado}
                onChange={handleOnPeriodoChange}
              />
            </HFlow>
          </div>
        </Cell>
        <Cell sm={6} xs={6}>
          <div
            css={css`
              padding: 0.2rem;
            `}
          >
            <HFlow>
              <Radio
                name='periodo'
                label='Outro'
                value={0}
                checked={0 === periodoSelecionado}
                onChange={handleOnPeriodoChange}
              />
              <RangeField
                option={unidadePeriodoSelecionada}
                dateRangeError={dateRangeError}
                dateRangeInput={dateRangeInput}
                disabled={0 !== periodoSelecionado}
                handleDateRangeChange={handleDateRangeChange}
              />
            </HFlow>
          </div>
        </Cell>
      </Grid>
      {relatorioAtendimentos ? (
        <Grid alignItems='stretch' direction='row'>
          <Cell size={6}>
            <Form
              render={() => {
                return (
                  <CiapCidSelectField
                    label='CIAP2 e CID10'
                    name='CiapCid'
                    multiple
                    placeholder='Selecione outros CIAP2 e CID10'
                    onChange={handleCiapCidChange}
                    value={filtroCiapCid}
                    onBlur={handleBlurCiapCid}
                  />
                )
              }}
            ></Form>
          </Cell>

          <Cell size={6}>
            <Form
              render={() => {
                return (
                  <GrupoCondicaoSelectField
                    label='Grupos de condições prioritários'
                    name='Condicoes'
                    multiple
                    placeholder='Selecione grupos de condições prioritários'
                    onChange={handleGrupoCondicaoChange}
                    value={filtroGrupoCondicao}
                    onBlur={handleBlurCiapCid}
                  />
                )
              }}
            ></Form>
          </Cell>
        </Grid>
      ) : null}
      {periodoSelecionado !== null &&
        (loading ? (
          <div
            css={css`
              position: absolute;
              left: 50vw;
              top: 60vh;
            `}
          >
            <PageLoading message='Carregando' />
          </div>
        ) : dateRangeError !== '' ? (
          <Text></Text>
        ) : isEmpty ? (
          <Text>Nenhum dado para o período</Text>
        ) : (
          children
        ))}
    </VFlow>
  )
}

const ranges = {
  DIA: {
    1: {
      value: moment().subtract(30, 'days').toDate(),
      label: 'Últimos 30 dias',
    },
    2: {
      value: moment().subtract(90, 'days').toDate(),
      label: 'Últimos 90 dias',
    },
    3: {
      value: moment().subtract(180, 'days').toDate(),
      label: 'Últimos 180 dias',
    },
  },
  SEMANA: {
    1: {
      value: moment().subtract(1, 'week').subtract(moment().weekday(), 'days').toDate(),
      label: 'Última semana',
    },
    2: {
      value: moment().subtract(2, 'weeks').subtract(moment().weekday(), 'days').toDate(),
      label: 'Últimas 2 semanas',
    },
    3: {
      value: moment().subtract(4, 'weeks').subtract(moment().weekday(), 'days').toDate(),
      label: 'Últimas 4 semanas',
    },
  },
  MES: {
    1: {
      value: moment()
        .subtract(1, 'month')
        .subtract(moment().date() - 1, 'days')
        .toDate(),
      label: 'Último mês',
    },
    2: {
      value: moment()
        .subtract(3, 'months')
        .subtract(moment().date() - 1, 'days')
        .toDate(),
      label: 'Últimos 3 meses',
    },
    3: {
      value: moment()
        .subtract(6, 'months')
        .subtract(moment().date() - 1, 'days')
        .toDate(),
      label: 'Últimos 6 meses',
    },
  },
}

const endDates = {
  DIA: moment().toDate(),
  SEMANA: moment()
    .subtract(moment().weekday() + 1, 'days')
    .toDate(),
  MES: moment().subtract(moment().date(), 'days').toDate(),
}
