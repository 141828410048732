import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { createValidator, ErrorObject, required } from 'util/validation'
import { isEmpty } from 'util/validation/Util'

import { DesfechoFormModel } from './DesfechoForm'
import { agendarConsultaValidator } from './validator/validatorAgendamento'
import { desfechoOdontoValidator } from './validator/validatorOdonto'

export const desfechoIndividualValidator = (hasEncaminhamentoExterno: boolean, getServerTimeNow: () => Date) =>
  createValidator<DesfechoFormModel>(
    {
      tipoAtendimento: [required],
      agendamentoConsulta: agendarConsultaValidator(getServerTimeNow),
    },
    (values: DesfechoFormModel, errors: ErrorObject<DesfechoFormModel>) => {
      if (isEmpty(values.fichasNotifiacaoCasoSuspeito) && !hasEncaminhamentoExterno) {
        errors.conduta = required(values.conduta)
      }
      return errors
    }
  )

export const desfechoValidator = (
  tipoAtendProf: TipoAtendimentoProfissional,
  isDemandaEspontanea: boolean,
  hasEncaminhamentoExterno: boolean,
  getServerTimeNow: () => Date
) => {
  switch (tipoAtendProf) {
    case TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO: {
      return desfechoOdontoValidator(isDemandaEspontanea, hasEncaminhamentoExterno)
    }
    case TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS: {
      return null
    }
    default: {
      return desfechoIndividualValidator(hasEncaminhamentoExterno, getServerTimeNow)
    }
  }
}
