import { Alert, DateRange, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import useFirebase from 'components/firebase/useFirebase'
import { PageContent } from 'components/layout/PageContent'
import { PageHeaderSection } from 'components/PageHeaderSection'
import { PivotTable } from 'components/pivot-table/PivotTable'
import { useTreeBuilder } from 'components/pivot-table/useTreeBuilder'
import { useAbsenteismosLazyQuery } from 'graphql/hooks.generated'
import React, { useCallback, useEffect, useState } from 'react'

import { BiTableWrapper } from '../components/BiTableWrapper'
import { Absenteismo } from './Absenteismo'
import { absenteismoKeyMapping } from './absenteismoKeyMapping'

const NAME = 'Relatório gerencial de absenteísmo'

export function AbsenteismoView() {
  const [getAbsenteismos, { loading, data }] = useAbsenteismosLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const { analytics } = useFirebase()

  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()

  const handleRangeChange = useCallback(
    (dateRange: DateRange) => {
      setDateRangeFilter(dateRange)
      getAbsenteismos({
        variables: {
          input: { dataInicio: dateRange.startDate, dataFim: dateRange.endDate },
        },
      })
    },
    [getAbsenteismos]
  )

  const isEmpty = !data?.absenteismos || data?.absenteismos.length === 0

  useEffect(() => {
    analytics.logEvent('relatorios_bi_selecionou_relatório', {
      relatorio: NAME,
    })
  }, [analytics])

  return (
    <>
      <Breadcrumb title={NAME} />

      <PageHeaderSection title={NAME} />

      <PageContent type='filled'>
        <VFlow>
          <Alert inline type='info'>
            O relatório de absenteísmo apresenta a contagem dos agendamentos em que o cidadão não compareceu ou não
            aguardou.
          </Alert>

          <BiTableWrapper loading={loading} isEmpty={isEmpty} handleRangeSelect={handleRangeChange}>
            <AbsenteismoTable data={data?.absenteismos} dateRangeFilter={dateRangeFilter} />
          </BiTableWrapper>
        </VFlow>
      </PageContent>
    </>
  )
}

function AbsenteismoTable(props: { data: Absenteismo[]; dateRangeFilter: DateRange }) {
  const { data, dateRangeFilter } = props
  const { meta, ...rest } = useTreeBuilder<Absenteismo>({
    keyMapping: absenteismoKeyMapping,
    data: data,
  })

  if (meta?.keyValues && meta?.numberKeys) {
    return (
      <PivotTable<Absenteismo>
        meta={meta}
        keyMapping={absenteismoKeyMapping}
        title={NAME}
        dateRangeFilter={dateRangeFilter}
        {...rest}
      />
    )
  } else {
    return null
  }
}
