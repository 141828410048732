import { DropdownItem, HFlow, Icon, Tooltip } from 'bold-ui'
import { useAlert } from 'components/alert'
import { DropdownButton } from 'components/dropdown'
import { useErrorHandler } from 'components/error'
import { confirm } from 'components/modals/confirm'
import { useExcluirLoteImunobiologicoMutation, useSetStatusLoteImunobiologicoMutation } from 'graphql/hooks.generated'
import { LoteImunobiologico } from 'graphql/types.generated'
import React from 'react'

interface LoteImunobiologicoTableDropDownProps {
  loteImunoItem: LoteImunobiologico
  reload(): Promise<any>
}

export function LoteImunobiologicoTableDropDown(props: LoteImunobiologicoTableDropDownProps) {
  const { loteImunoItem, reload } = props
  const alert = useAlert()
  const handleRejection = useErrorHandler()

  const [excluir] = useExcluirLoteImunobiologicoMutation({ variables: { idLote: loteImunoItem.id } })
  const [ativarInativar] = useSetStatusLoteImunobiologicoMutation({
    variables: {
      input: {
        lote: loteImunoItem.lote,
        idImunobiologico: loteImunoItem.imunobiologico?.id,
        validade: loteImunoItem.validade,
        id: loteImunoItem.id,
        idFabricante: loteImunoItem.fabricante?.id,
        nomeFabricante: loteImunoItem.fabricante?.nome,
      },
    },
  })

  const handleExcluirClick = (excluirLote) => {
    return confirm({
      title: `Deseja excluir o lote de imunobiológico ${loteImunoItem.lote}?`,
      type: 'danger',
      confirmLabel: 'Excluir',
      onConfirm: () =>
        excluirLote()
          .then(() => {
            alert('success', `Lote de imunobiológico ${loteImunoItem.lote} excluído com sucesso.`)
            reload()
          })
          .catch(handleRejection),
    })
  }

  const handleAtivarClick = () => {
    confirm({
      confirmLabel: 'Ativar',
      type: 'primary',
      title: `Deseja ativar o lote de imunobiológico ${loteImunoItem.lote}?`,
      onConfirm: () =>
        ativarInativar({
          variables: {
            input: {
              lote: loteImunoItem.lote,
              idImunobiologico: loteImunoItem.imunobiologico?.id,
              validade: loteImunoItem.validade,
              id: loteImunoItem.id,
              idFabricante: loteImunoItem.fabricante?.id,
              nomeFabricante: loteImunoItem.fabricante?.nome,
            },
            ativado: true,
          },
        })
          .then(() => {
            alert('success', `Lote de imunobiológico ${loteImunoItem.lote} ativado com sucesso.`)
            reload()
          })
          .catch(() => {
            alert('danger', 'Não foi possivel reativar o lote, cadastre-o novamente.')
          }),
    })()
  }

  const handleInativarClick = () => {
    confirm({
      confirmLabel: 'Inativar',
      type: 'primary',
      title: `Deseja inativar o lote de imunobiológico ${loteImunoItem.lote}?`,
      onConfirm: () =>
        ativarInativar({
          variables: {
            input: {
              lote: loteImunoItem.lote,
              idImunobiologico: loteImunoItem.imunobiologico?.id,
              validade: loteImunoItem.validade,
              id: loteImunoItem.id,
              idFabricante: loteImunoItem.fabricante?.id,
              nomeFabricante: loteImunoItem.fabricante?.nome,
            },
            ativado: false,
          },
        })
          .then(() => {
            alert('success', `Lote de imunobiológico ${loteImunoItem.lote} inativado com sucesso.`)
            reload()
          })
          .catch(() => {
            alert('danger', 'Não foi possivel inativar o lote.')
          }),
    })()
  }

  return (
    <Tooltip text='Mais opções'>
      <DropdownButton>
        {loteImunoItem.ativo ? (
          <DropdownItem onClick={handleInativarClick}>Inativar</DropdownItem>
        ) : (
          <DropdownItem onClick={handleAtivarClick}>Ativar</DropdownItem>
        )}
        <DropdownItem type='danger' onClick={handleExcluirClick(excluir)}>
          <HFlow alignItems='center' hSpacing={0.5}>
            <Icon icon='trashOutline' />
            Excluir
          </HFlow>
        </DropdownItem>
      </DropdownButton>
    </Tooltip>
  )
}
