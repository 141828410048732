import { Button, HeadingSection, HFlow, Modal, ModalBody, ModalFooter } from 'bold-ui'
import { useAlert } from 'components/alert'
import { RichTextField, SubmitButton } from 'components/form'
import { confirm } from 'components/modals/confirm'
import React from 'react'
import { Form, FormRenderProps } from 'react-final-form'

import AtestadoModel from '../components/AtestadoModel'
import { name, validate } from './AtestadoEmBrancoForm'

interface AtestadoEmBrancoEditModal {
  onSubmit(values: AtestadoModel): void
  handleModalClose(): void
  initialValues?: AtestadoModel
}

export default function AtestadoEmBrancoFormModal(props: AtestadoEmBrancoEditModal) {
  const { onSubmit, handleModalClose, initialValues } = props

  const alert = useAlert()

  const handleSubmit = (values: AtestadoModel) => {
    onSubmit(values)
    handleModalClose()
    alert('success', 'Atestado salvo com sucesso')
  }

  const handleCancel = (dirty: boolean) => {
    if (dirty) {
      confirm({
        title: 'Deseja cancelar a edição?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, continuar edição',
        confirmLabel: 'Sim, cancelar edição',
        onConfirm: () => {
          alert('success', 'Edição de atestado cancelado com sucesso.')
          handleModalClose()
        },
      })()
    } else {
      alert('success', 'Edição de atestado cancelado com sucesso.')
      handleModalClose()
    }
  }

  return (
    <Form<AtestadoModel>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      render={(formProps: FormRenderProps<AtestadoModel>) => (
        <Modal open onClose={() => handleCancel(formProps.dirty)}>
          <ModalBody>
            <HeadingSection level={1} title='Editar atestado' />
            <RichTextField
              name={name.descricao}
              maxLength={4000}
              placeholder='Insira o conteúdo do atestado'
              height={10}
              label='Conteúdo'
              required
            />
          </ModalBody>
          <ModalFooter>
            <HFlow justifyContent='flex-end'>
              <Button onClick={() => handleCancel(formProps.dirty)}>Cancelar</Button>
              <SubmitButton title='Salvar' kind='primary' handleSubmit={formProps.handleSubmit}>
                Salvar
              </SubmitButton>
            </HFlow>
          </ModalFooter>
        </Modal>
      )}
    />
  )
}
