interface EscutaInicialMessages {
  successAlert: string
  submitButton: string
  cancelButton: string
  cancelTitle: string
  procedimentosClinicos: string
}

export const escutaMessages: EscutaInicialMessages = {
  successAlert: 'Escuta inicial finalizada com sucesso',
  cancelButton: 'Cancelar escuta inicial',
  submitButton: 'Finalizar escuta inicial',
  cancelTitle: 'Deseja cancelar a escuta inicial?',
  procedimentosClinicos: 'Finalizar a escuta inicial sem o registro de procedimentos clínicos?',
}

export const preAtendimentoMessages: EscutaInicialMessages = {
  successAlert: 'Pré-atendimento finalizado com sucesso',
  cancelButton: 'Cancelar pré-atendimento',
  submitButton: 'Finalizar pré-atendimento',
  cancelTitle: 'Deseja cancelar o pré-atendimento?',
  procedimentosClinicos: 'Finalizar o pré-atendimento sem o registro de procedimentos clínicos?',
}
