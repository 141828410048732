import { ButtonProps, IconProps, modal } from 'bold-ui'
import React from 'react'

import { ConfirmConfig } from '../confirm'
import { ModalBody } from '../ModalBody'

const defaultConfig: ConfirmConfig = {
  body: null,
  title: 'Informação',
  type: 'primary',
}

const icons: { [key in ButtonProps['kind']]: IconProps['icon'] } = {
  normal: 'infoCircleOutline',
  primary: 'infoCircleOutline',
  danger: 'exclamationTriangleOutline',
}

export const information = (config: ConfirmConfig) => {
  const { title, body, type, onClose, depthLevel, manageOverflow } = {
    ...defaultConfig,
    ...config,
  }

  return modal({
    size: 'small',
    onClose,
    render: () => (
      <ModalBody title={title} icon={icons[type]} type={type}>
        {body}
      </ModalBody>
    ),
    depthLevel,
    manageOverflow,
  })
}
