import React from 'react'
import { MetaPath } from 'util/metaPath'
import { AgendamentoDia } from 'view/atendimentos/types/AgendamentoDia'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { SoapSection } from '../SoapSection'
import { DesfechoForm, DesfechoFormModel } from './DesfechoForm'

export interface DesfechoSectionProps {
  name: MetaPath<DesfechoFormModel>
  profissionalId?: ID
  agendamentoAtendimentoId?: ID
  concluiAgendamento: boolean
  dataAtendimento: Instant
  cidadao: CidadaoAtendimento
  agendamentosDia: AgendamentoDia[]
  hasEncaminhamentoExterno: boolean
  isAtendimentoProcedimentos: boolean
  isAtendimentoOdonto: boolean
  registroTardio: boolean
}

export function DesfechoSection(props: DesfechoSectionProps) {
  return (
    <SoapSection initialIcon='checkCircleFilled' title='Finalização do atendimento'>
      <DesfechoForm {...props} />
    </SoapSection>
  )
}
