import React from 'react'

import { AccordionCardSection } from '../components/AccordionCardSection'
import { ContentCardSection } from '../components/ContentCardSection'
import { Alergia } from '../types/AlergiaModel'
import { AlergiasListCard } from './AlergiasListCard'

export interface AlergiasCardProps {
  canRender: boolean
  loading: boolean
  alergiasExpandido: Alergia[]
  alergiasAccordion: Alergia[]
  expanded?: boolean
}

export function AlergiasCard(props: AlergiasCardProps) {
  const { canRender, loading, alergiasExpandido, alergiasAccordion, expanded = true } = props

  return (
    <>
      <ContentCardSection isLoading={loading} messageLoading='Carregando dados das alergias' canRender={canRender}>
        <AlergiasListCard alergias={alergiasExpandido} />
      </ContentCardSection>
      {alergiasAccordion.length > 0 && (
        <AccordionCardSection
          id='AlergiasCard'
          title={{
            closed: `Mostrar + ${alergiasAccordion.length} alergias`,
            open: `Esconder alergias`,
          }}
          expanded={expanded}
        >
          <AlergiasListCard alergias={alergiasAccordion} />
        </AccordionCardSection>
      )}
    </>
  )
}
