import { ImpressaoAtendimentoInput } from 'api/ImpressaoAtendimentoIndividual'
import { Alert, Button, HFlow, modal, Theme } from 'bold-ui'
import { useAlert } from 'components/alert'
import useSession from 'components/auth/useSession'
import { DateTime } from 'components/date'
import { handleValidationError, useErrorHandler } from 'components/error'
import useFirebase from 'components/firebase/useFirebase'
import { CheckboxField, Form, FormRenderProps } from 'components/form'
import changeValue from 'components/form/mutators/changeValue'
import { PageContent } from 'components/layout/PageContent'
import { confirm } from 'components/modals/confirm'
import { differenceInYears, parseISO } from 'date-fns'
import { Decorator, FormState, Subscription } from 'final-form'
import arrayMutators from 'final-form-arrays'
import { useApolloClient } from 'graphql/hooks'
import { useCancelarAtendimentoMutation, useFinalizarAtendimentoIndividualMutation } from 'graphql/hooks.generated'
import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useServerTime } from 'hooks/useServerTime'
import React, { CSSProperties, Dispatch, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { FormSpy } from 'react-final-form'
import { useHistory } from 'react-router'
import {
  CidadaoCalculator,
  createAtendimentoIndividualCalculator,
} from 'view/atendimentos/atendimento-individual/calculator'
import { converAtendimentoIndividualModelToInput } from 'view/atendimentos/atendimento-individual/converter'
import { logEventFinalizarAtendimento } from 'view/atendimentos/atendimento-individual/logFinalizarAtendimento'
import { CiapCidPreNatal, meta, SoapState, TipoServicoModel } from 'view/atendimentos/atendimento-individual/model'
import { atendimentoIndividualValidator } from 'view/atendimentos/atendimento-individual/validator'
import { validateCpfCns } from 'view/atendimentos/atendimento-vacinacao/validator'
import { ProcedimentoAutomatico, RegistroAvaliacaoPuericultura } from 'view/atendimentos/model'
import { AgendamentoDia } from 'view/atendimentos/types/AgendamentoDia'
import { AtendimentoProfissional } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'
import { REGISTRO_TARDIO_PATH } from 'view/registro-tardio/RegistroTardioRootView'

import { useVerificarAgendamentosConflitantes } from '../../../agenda/hooks/useVerificarAgendamentosConflitantes'
import { useLocalStyles } from '../../atendimento-individual/AtendimentoIndividualView'
import { downloadAtendimentoIndividual } from '../components/downloadAtendimentoIndividual'
import { PeriodoGestacaoModel } from '../components/modals/types/PeriodoGestacaoModel'
import { createVacinacaoErrorModal } from '../vacinacao/VacinacaoCalendarioView'
import { Problema } from './aside/types/ProblemaModel'
import { SoapValidationErrorsModal } from './components/soap-validation-errors-modal/SoapValidationErrorsModal'
import { grupoCboProcedimentosAutomaticos } from './plano/acessos'
import { HasAccessProcedimentos } from './plano/calculator'
import { SoapPage } from './SoapPage'
import { SoapRootView } from './SoapRootView'
import { handleKeyDown } from './util'

const subscription: Subscription = {} /* evita problemas de performance */

const createDecorators = (
  apollo,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  registroAvaliacaoPuericultura: RegistroAvaliacaoPuericultura,
  isMedico: boolean,
  tipoAtendProf: TipoAtendimentoProfissional,
  prontuarioId: ID,
  ciapCidPreNatal: CiapCidPreNatal,
  dataAtendimento: Instant,
  hasPermissionPreNatal: boolean,
  cidadao: CidadaoCalculator,
  hasAccessProcedimentos: HasAccessProcedimentos,
  isCEO?: boolean
): Decorator[] => [
  createAtendimentoIndividualCalculator(
    apollo,
    procedimentosAutomaticos,
    registroAvaliacaoPuericultura,
    isMedico,
    tipoAtendProf,
    prontuarioId,
    ciapCidPreNatal,
    dataAtendimento,
    hasPermissionPreNatal,
    cidadao,
    hasAccessProcedimentos,
    isCEO
  ),
]

export interface SoapViewProps {
  atendimentoId: ID
  prontuarioId: ID
  cidadao: CidadaoAtendimento
  registroTardio: boolean
  tiposServico: TipoServicoModel[]
  dataAtendimento: Instant
  procedimentosAutomaticos?: ProcedimentoAutomatico[]
  registroAvaliacaoPuericultura: RegistroAvaliacaoPuericultura
  isMedico: boolean
  agendamentoAtendimentoId: ID
  concluiAgendamento: boolean
  isAtendimentoOdonto: boolean
  isAtendimentoProcedimentos: boolean
  hasEncaminhamentoExterno: boolean
  agendamentosDia: AgendamentoDia[]
  cacheState: SoapState
  atendimentoProfissional: AtendimentoProfissional
  nomeAtendimento: string
  ciapCidPreNatal: CiapCidPreNatal
  updateCache: Dispatch<SoapState>
  clearCache: Dispatch<void>
  somenteCiap: boolean
  isGestante?: boolean
  gestacoes: PeriodoGestacaoModel[]
  problemasAtivosELatentes?: Problema[]
  headerHeight: number
  qtdTotalProcedimentos: number
}

export function SoapView(props: SoapViewProps) {
  const { cacheState } = props
  const [initialValues] = useState(cacheState) // usado pra setar os valores iniciais do Form apenas na primeira vez que renderizar
  return (
    <div>
      <SoapForm {...props} initialValues={initialValues} />
    </div>
  )
}

export interface SoapFormProps extends SoapViewProps {
  initialValues: SoapState
}

const SoapForm = memo((props: SoapFormProps) => {
  const {
    atendimentoId,
    prontuarioId,
    cidadao,
    registroTardio,
    agendamentosDia,
    dataAtendimento,
    procedimentosAutomaticos,
    registroAvaliacaoPuericultura,
    initialValues,
    agendamentoAtendimentoId,
    concluiAgendamento,
    isMedico,
    hasEncaminhamentoExterno,
    isAtendimentoOdonto,
    isAtendimentoProcedimentos,
    isGestante,
    atendimentoProfissional,
    updateCache,
    clearCache,
    nomeAtendimento,
    ciapCidPreNatal,
    problemasAtivosELatentes,
    somenteCiap,
    gestacoes,
    headerHeight,
    qtdTotalProcedimentos,
  } = props

  const { classes } = useLocalStyles(createStyles)
  const history = useHistory()
  const [finalizar] = useFinalizarAtendimentoIndividualMutation()
  const [cancelar] = useCancelarAtendimentoMutation()
  const { verificarAgendamentosConflitantes } = useVerificarAgendamentosConflitantes()
  const handleRejection = useErrorHandler()
  const { data, isUnidadeSaudeCEO } = useSession()
  const apollo = useApolloClient()
  const { getServerTimeNow } = useServerTime()
  const idadeCidadao = differenceInYears(dataAtendimento, parseISO(cidadao.dataNascimento))
  const tipoAtendProf = atendimentoProfissional?.tipo
  const { analytics } = useFirebase()

  const {
    permissoes: { hasPermissionPreNatal },
  } = useAtendimentoContext()
  const { hasCboAuth } = useSession({ fetchPolicy: 'cache-only' })

  const hasAccessProcedimentos: HasAccessProcedimentos = useMemo(
    () => ({
      desenvolvimentoCrianca: hasCboAuth(grupoCboProcedimentosAutomaticos.desenvolvimentoCrianca),
      crescimentoCrianca: hasCboAuth(grupoCboProcedimentosAutomaticos.crescimentoCrianca),
      glicemiaCapilar: hasCboAuth(grupoCboProcedimentosAutomaticos.glicemiaCapilar),
    }),
    [hasCboAuth]
  )

  const decorators = useMemo(
    () =>
      createDecorators(
        apollo,
        procedimentosAutomaticos,
        registroAvaliacaoPuericultura,
        isMedico,
        tipoAtendProf,
        prontuarioId,
        ciapCidPreNatal,
        dataAtendimento,
        hasPermissionPreNatal,
        {
          dataNascimento: cidadao.dataNascimento,
          problemasAtivosELatentes,
          isGestante,
          idadeEmAnos: cidadao.idadeEmAnos,
          desejaInformarIdentidadeGenero: cidadao.desejaInformarIdentidadeGenero,
          sexo: cidadao.sexo,
        },
        hasAccessProcedimentos,
        isUnidadeSaudeCEO
      ),
    [
      apollo,
      ciapCidPreNatal,
      cidadao.dataNascimento,
      cidadao.desejaInformarIdentidadeGenero,
      cidadao.idadeEmAnos,
      cidadao.sexo,
      dataAtendimento,
      hasAccessProcedimentos,
      hasPermissionPreNatal,
      isUnidadeSaudeCEO,
      isGestante,
      isMedico,
      problemasAtivosELatentes,
      procedimentosAutomaticos,
      prontuarioId,
      registroAvaliacaoPuericultura,
      tipoAtendProf,
    ]
  )
  const validators = useMemo(
    () =>
      atendimentoIndividualValidator(
        { ...cidadao, isGestante },
        dataAtendimento,
        tipoAtendProf,
        problemasAtivosELatentes,
        !concluiAgendamento,
        hasEncaminhamentoExterno,
        hasPermissionPreNatal,
        getServerTimeNow,
        qtdTotalProcedimentos
      ),
    [
      cidadao,
      isGestante,
      dataAtendimento,
      tipoAtendProf,
      problemasAtivosELatentes,
      concluiAgendamento,
      hasEncaminhamentoExterno,
      hasPermissionPreNatal,
      getServerTimeNow,
      qtdTotalProcedimentos,
    ]
  )

  const alert = useAlert()

  const nomeAtendimentoLowerCase = nomeAtendimento.toLocaleLowerCase()

  const printItem = (itemToPrint: ImpressaoAtendimentoInput) => {
    downloadAtendimentoIndividual(itemToPrint)
  }

  const [formInitialized, setFormInitialized] = useState(false)
  useEffect(() => {
    setFormInitialized(true)
  }, [])

  const handleFormChange = useCallback(
    (values: SoapState) => {
      /* Avoids the error of updating a component while rendering a different component. */
      if (formInitialized) {
        values.lastSaved = new Date()
        updateCache(values)
      }
    },
    [formInitialized, updateCache]
  )

  const handleCancelar = useCallback(
    () =>
      confirm({
        title: `Deseja cancelar o ${nomeAtendimentoLowerCase}?`,
        body: 'As alterações realizadas serão perdidas.',
        confirmLabel: 'Sim',
        cancelLabel: 'Não',
        onConfirm: () => {
          cancelar({ variables: { atendimentoId } })
            .then(() => {
              analytics.logEvent('cancelar_atendimento', { Tipo_de_atendimento: nomeAtendimento })
              clearCache()
              history.push(registroTardio ? REGISTRO_TARDIO_PATH : '/lista-atendimento')
            })
            .catch(handleRejection)
        },
      })(),
    [
      nomeAtendimentoLowerCase,
      cancelar,
      atendimentoId,
      handleRejection,
      analytics,
      nomeAtendimento,
      clearCache,
      history,
      registroTardio,
    ]
  )

  const handleClickAtualizarCadastroCidadao = useCallback(
    () =>
      history.push(
        `/cidadao/${cidadao.id}/edit?callbackUrl=lista-atendimento/atendimento&callbackParams=${atendimentoId}`
      ),
    [atendimentoId, cidadao.id, history]
  )

  const handleFinalizar = useCallback(
    async (values: SoapState) => {
      const confirmouHorariosConflitantes = await verificarAgendamentosConflitantes(
        cidadao.id,
        values.desfecho?.agendamentoConsulta?.lotacao?.id,
        Number(values.desfecho?.agendamentoConsulta?.horario?.inicial),
        cidadao.nomeSocial ?? cidadao.nome
      )

      if (!confirmouHorariosConflitantes) return

      const errorCpfCnsRequired =
        values.vacinacao?.registroVacinacao?.length &&
        validateCpfCns(cidadao.cns || cidadao.cpf, handleClickAtualizarCadastroCidadao, false)

      if (errorCpfCnsRequired?.hasError) {
        createVacinacaoErrorModal(errorCpfCnsRequired)
      } else {
        return finalizar({
          variables: {
            input: converAtendimentoIndividualModelToInput(atendimentoId, values),
          },
        })
          .then((response) => {
            values.imprimirAtendimento &&
              printItem({
                atendimentoProfissionalId: response.data.finalizarAtendimentoIndividual.id,
              })

            logEventFinalizarAtendimento(
              analytics.logEvent,
              nomeAtendimento,
              values,
              cidadao.idadeEmAnos,
              isGestante,
              initialValues.antecedentes?.pessoal
            )

            alert('success', `${nomeAtendimento} finalizado com sucesso.`)
            clearCache()
            history.push(registroTardio ? REGISTRO_TARDIO_PATH : '/lista-atendimento')
          })
          .catch((error) =>
            // eslint-disable-next-line no-console
            console.error(
              `Erro ao finalizar atendimento ${tipoAtendProf}`,
              JSON.stringify(handleValidationError(error))
            )
          )
      }
    },
    [
      verificarAgendamentosConflitantes,
      cidadao.id,
      cidadao.nomeSocial,
      cidadao.nome,
      cidadao.cns,
      cidadao.cpf,
      cidadao.idadeEmAnos,
      handleClickAtualizarCadastroCidadao,
      finalizar,
      atendimentoId,
      analytics.logEvent,
      nomeAtendimento,
      isGestante,
      initialValues.antecedentes,
      alert,
      clearCache,
      history,
      registroTardio,
      tipoAtendProf,
    ]
  )

  const handleSubmitFailed = (formState: FormState<SoapState>) => {
    const { errors, hasValidationErrors } = formState

    if (hasValidationErrors) {
      modal({
        size: 'auto',
        depthLevel: 5,
        render: () => <SoapValidationErrorsModal errors={errors} />,
      })()
    }
  }

  const handleOnFocus = useCallback(() => {
    const activeElement = document.activeElement as HTMLElement
    if (activeElement) {
      const activeElementTop = activeElement.getBoundingClientRect().top
      activeElementTop < 0 && window.scrollTo(0, activeElement.parentElement.offsetTop - 160)
    }
  }, [])

  const renderForm = useCallback(
    (formProps: FormRenderProps<SoapState>): JSX.Element => {
      const { handleSubmit } = formProps

      return (
        <>
          <form
            noValidate
            onKeyDown={handleKeyDown}
            onFocus={handleOnFocus}
            style={{ display: 'flex', flexDirection: 'column', minHeight: '60vh' }}
          >
            <SoapPage
              atendimentoProfissional={atendimentoProfissional}
              dataAtualizacaoObstetricosAntecedentes={
                props.cacheState?.antecedentes?.pessoal?.informacoesObstetricas?.dataAtualizacao
              }
              dataAtualizacaoPuericulturaAntecedentes={
                props.cacheState?.antecedentes?.pessoal?.puericultura?.dataAtualizacao
              }
              isAtendimentoOdonto={isAtendimentoOdonto}
              cidadao={cidadao}
              dataAtendimento={dataAtendimento}
              prontuarioId={prontuarioId}
              isAtendimentoProcedimentos={isAtendimentoProcedimentos}
              ciapCidPreNatal={ciapCidPreNatal}
              acesso={data.acesso}
              agendamentoAtendimentoId={agendamentoAtendimentoId}
              concluiAgendamento={concluiAgendamento}
              agendamentosDia={agendamentosDia}
              hasEncaminhamentoExterno={hasEncaminhamentoExterno}
              idadeCidadao={idadeCidadao}
              atendimentoId={atendimentoId}
              handleFormChange={handleFormChange}
              profissionalId={data.profissional.id}
              isGestante={isGestante}
              somenteCiap={somenteCiap}
              gestacoes={gestacoes}
              problemasAtivosELatentes={problemasAtivosELatentes}
              headerHeight={headerHeight}
            />
            <div className={classes.actions}>
              <PageContent type='filled' fluid>
                <HFlow justifyContent='space-between'>
                  <FormSpy<SoapState> subscription={{ dirty: true, active: true, values: true }}>
                    {(props) => (
                      <Alert inline type='success' styles={{ wrapper: classes.draftAlertWrapper }}>
                        Rascunho salvo automaticamente às <DateTime format='HH:mm' value={props.values.lastSaved} />
                      </Alert>
                    )}
                  </FormSpy>
                  <HFlow>
                    <CheckboxField
                      name={meta.imprimirAtendimento}
                      label='Imprimir atendimento ao finalizar'
                      style={classes.print}
                      onClick={() => analytics.logEvent('click_imprimir_finalizar')}
                    />
                    <Button kind='normal' onClick={handleCancelar}>
                      Cancelar {nomeAtendimentoLowerCase}
                    </Button>
                    <Button kind='primary' onClick={handleSubmit}>
                      Finalizar {nomeAtendimentoLowerCase}
                    </Button>
                  </HFlow>
                </HFlow>
              </PageContent>
            </div>
          </form>
          <SoapRootView
            atendimentoProfissional={atendimentoProfissional}
            cidadao={cidadao}
            dataAtendimento={dataAtendimento}
            isAtendimentoOdonto={isAtendimentoOdonto}
            prontuarioId={prontuarioId}
            ciapCidPreNatal={ciapCidPreNatal}
            somenteCiap={somenteCiap}
          />
        </>
      )
    },
    [
      handleOnFocus,
      analytics,
      atendimentoProfissional,
      props.cacheState,
      isAtendimentoOdonto,
      cidadao,
      dataAtendimento,
      prontuarioId,
      isAtendimentoProcedimentos,
      ciapCidPreNatal,
      data.acesso,
      data.profissional.id,
      agendamentoAtendimentoId,
      concluiAgendamento,
      agendamentosDia,
      hasEncaminhamentoExterno,
      idadeCidadao,
      atendimentoId,
      handleFormChange,
      isGestante,
      somenteCiap,
      gestacoes,
      problemasAtivosELatentes,
      classes.actions,
      classes.print,
      classes.draftAlertWrapper,
      handleCancelar,
      nomeAtendimentoLowerCase,
      headerHeight,
    ]
  )

  return (
    <Form<SoapState>
      render={renderForm}
      initialValues={initialValues}
      decorators={decorators}
      validateOnBlur
      validate={validators}
      subscription={subscription}
      onSubmit={handleFinalizar}
      onSubmitFailed={handleSubmitFailed}
      mutators={{
        ...arrayMutators,
        changeValue,
      }}
    />
  )
})

const createStyles = (theme: Theme) => ({
  print: {
    border: `0.063rem solid ${theme.pallete.gray.c80}`,
    borderRadius: '0.125rem',
    padding: '0.75rem 1rem',
  },
  actions: {
    borderTop: `1px solid ${theme.pallete.divider}`,
    height: '6.125rem',
  } as CSSProperties,
  draftAlertWrapper: {
    backgroundColor: 'transparent',
    borderWidth: 0,
  },
})
