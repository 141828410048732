import ApolloClient from 'apollo-client'
import createDecorator from 'final-form-calculate'
import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { CidadaoAtendimentoContextModel } from 'hooks/atendimento-context/model'

import { Problema } from '../detail/soap/aside/types/ProblemaModel'
import { createAvaliacaoCalculations } from '../detail/soap/avaliacao/calculator'
import { createDesfechoCalculations } from '../detail/soap/desfecho/calculator'
import { createObjetivoCalculations } from '../detail/soap/objetivo/calculator'
import { createPlanoCalculations, HasAccessProcedimentos } from '../detail/soap/plano/calculator'
import { ProcedimentoAutomatico, RegistroAvaliacaoPuericultura } from '../model'
import { CiapCidPreNatal, meta } from './model'

export interface CidadaoCalculator
  extends Pick<
    CidadaoAtendimentoContextModel,
    'idadeEmAnos' | 'dataNascimento' | 'isGestante' | 'desejaInformarIdentidadeGenero' | 'sexo'
  > {
  problemasAtivosELatentes: Problema[]
}

export const createAtendimentoIndividualCalculator = (
  apollo: ApolloClient<object>,
  procedimentosAutomaticos: ProcedimentoAutomatico[],
  registroAvaliacaoPuericultura: RegistroAvaliacaoPuericultura,
  isMedico: boolean,
  tipoAtendProf: TipoAtendimentoProfissional,
  prontuarioId: ID,
  ciapCidPreNatal: CiapCidPreNatal,
  dataAtendimento: Instant,
  hasPermissionPreNatal: boolean,
  cidadao: CidadaoCalculator,
  hasAccessProcedimentos: HasAccessProcedimentos,
  isCEO?: boolean
) =>
  createDecorator(
    ...createObjetivoCalculations(meta.objetivo, meta.avaliacao, cidadao.isGestante, hasPermissionPreNatal),
    ...createAvaliacaoCalculations(
      meta.objetivo,
      meta.avaliacao,
      registroAvaliacaoPuericultura,
      isMedico,
      apollo,
      prontuarioId,
      ciapCidPreNatal,
      cidadao.dataNascimento,
      dataAtendimento,
      hasPermissionPreNatal,
      cidadao.isGestante,
      cidadao.problemasAtivosELatentes
    ),
    ...createPlanoCalculations(
      meta.plano,
      meta.objetivo.atendimentosEspecificos.formAtivo,
      meta.objetivo.medicoes,
      meta.objetivo.atendimentosEspecificos.puericultura,
      procedimentosAutomaticos,
      cidadao.idadeEmAnos,
      hasAccessProcedimentos
    ),
    ...createDesfechoCalculations(
      meta.desfecho,
      meta.avaliacao.problemasECondicoes,
      apollo,
      isMedico,
      procedimentosAutomaticos,
      tipoAtendProf,
      hasPermissionPreNatal,
      cidadao.idadeEmAnos,
      cidadao.desejaInformarIdentidadeGenero,
      cidadao.sexo,
      cidadao.isGestante,
      isCEO
    )
  )
