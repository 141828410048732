import { Omit } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { SideMenuItemConfig } from 'components/layout/SideMenu/SideMenu'
import { getSideMenuItems } from 'components/layout/SideMenu/SiteMenuItems'
import { useFlags } from 'config/useFlagsContext'
import { partition } from 'lodash'
import React from 'react'

import { SelectField, SelectFieldProps } from '../../final-form/SelectField'

type ItemType = Pick<SideMenuItemConfig, 'title' | 'to' | 'permission'>

export interface ModuloSelectFieldProps extends Omit<SelectFieldProps<ItemType>, 'items' | 'itemToString'> {}

export const ModuloSelectField = (props: ModuloSelectFieldProps) => {
  const { hasAuthorization } = useSession()
  const { LISTA_REGISTRO_TARDIO_NOVA_ENABLED } = useFlags()
  const authorizedItems: ItemType[] = getItems(LISTA_REGISTRO_TARDIO_NOVA_ENABLED).filter((item) =>
    hasAuthorization(item.permission)
  )

  const itemToString = (item: ItemType) => item && item.title

  return <SelectField<ItemType> clearable={false} items={authorizedItems} itemToString={itemToString} {...props} />
}

export const createItems = (menuItems: SideMenuItemConfig[]): ItemType[] => {
  const createdItems: ItemType[] = []

  const [itemsWithoutChildren, itemsWithChildren] = partition(menuItems, (item) => !item.children)

  itemsWithoutChildren.forEach((item) => {
    createdItems.push({ title: item.title, permission: item.permission, to: item.to })
  })

  itemsWithChildren.forEach((item) => {
    item.children.forEach((child) => {
      const groupName = child.group
      child.items.forEach((innerItem) => {
        if (innerItem) {
          const title = groupName ? `${item.title}/${groupName}/${innerItem.title}` : `${item.title}/${innerItem.title}`
          createdItems.push({ title, permission: innerItem.permission, to: innerItem.to })
        }
      })
    })
  })

  return createdItems
}

export const getItems = (isListaRegistroTardioNova: Boolean) => {
  return createItems(getSideMenuItems(isListaRegistroTardioNova))
}
