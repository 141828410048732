/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, Checkbox, Grid, Icon, TableFooter, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import CheckPermission from 'components/auth/CheckPermission'
import { useLembretesModalQuery } from 'graphql/hooks.generated'
import { isEqual } from 'lodash'
import { useMemo, useState } from 'react'
import { MetaPath } from 'util/metaPath'
import { LembreteModel, visibilidadeLembrete } from 'view/atendimentos/detail/soap/aside/types/LembreteModel'
import { grupoCboLembretes } from 'view/atendimentos/detail/soap/plano/acessos'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { useAccordionControl } from '../../../../../../components/accordion/useAccordionControl'
import { usePagination } from '../../../../../../components/table/usePagination'
import { useEditableListField } from '../../EditableList'
import { LembreteFormModel } from './components/LembreteForm'
import LembretesAccordionPanel from './LembretesAccordionPanel'
import { LembreteTableRow } from './LembreteTableRow'
import { mergeSortLembretes } from './mergeSortLembretes'

interface EditableListaLembretesModalProps {
  prontuarioId: ID
  name: MetaPath<LembreteFormModel>
  lotacao: LotacaoAtendimento
  lembretesCache?: LembreteFormModel[]
}

export function EditableListaLembretesModal(props: EditableListaLembretesModalProps) {
  const { prontuarioId, name, lotacao, lembretesCache } = props

  const [lembretesEmEdicao, setLembretesEmEdicao] = useState<Array<LembreteModel>>([])

  const { resetExpandedItems, ...accordionProps } = useAccordionControl({})

  const isEditing = (lembrete: LembreteModel): boolean =>
    !!lembretesEmEdicao.find((l) => l.id === lembrete.id && l._id === lembrete._id)

  const handleEditClick = (event: Event, lembrete: LembreteModel) => {
    event.stopPropagation()
    if (!isEditing(lembrete)) setLembretesEmEdicao([...lembretesEmEdicao, lembrete])
  }

  const removeLembreteEmEdicao = (lembrete: LembreteModel) => {
    setLembretesEmEdicao(lembretesEmEdicao.filter((l) => !isEqual(l, lembrete)))
  }

  const { handleSubmit, handleRowChanged, removeItem } = useEditableListField({ name })

  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  const [somenteMeus, setSomenteMeus] = useState<boolean>(false)

  const { data, loading } = useLembretesModalQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      lembretesQueryInput: {
        prontuarioId,
        somenteMeus,
      },
    },
  })

  const lembretesCacheDatabase = useMemo(
    () => mergeSortLembretes(data.lembretes ?? [], lembretesCache ?? [], lotacao),
    [lotacao, data.lembretes, lembretesCache]
  )

  const { paginatedItems, tableProps } = usePagination<LembreteModel>({
    items: lembretesCacheDatabase,
    onChange: resetExpandedItems,
  })

  const handleDelete = (e: Event, lembreteId: ID) => {
    e.stopPropagation()
    const lembrete: LembreteFormModel = {
      _id: lembreteId,
      visibilidadeLembrete: undefined,
      descricao: undefined,
      ativo: undefined,
    }
    lembreteId && removeItem(lembrete)
    resetExpandedItems()
  }

  const renderAtivo = (row: LembreteModel) => (
    <Grid gap={0} gapVertical={0} alignItems='center' wrap={false}>
      <Cell size={6}>{row.historicoLembrete[0].ativo ? 'Sim' : 'Não'}</Cell>
      {renderButtons(row)}
    </Grid>
  )

  const renderButtons = (row: LembreteModel) => (
    <CheckPermission permission={grupoCboLembretes.adicionar}>
      <Cell size={4}>
        <Tooltip text='Alterar'>
          <Button size='small' kind='normal' skin='ghost' onClick={(e) => handleEditClick(e, row)}>
            <Icon name='accordionEditIcon' icon='penOutline' />
          </Button>
        </Tooltip>
      </Cell>
      <Cell size={3}>
        <Tooltip text={row._id ? 'Excluir' : 'Não é permitido excluir um lembrete cadastrado em outro atendimento.'}>
          <Button size='small' kind='normal' skin='ghost' onClick={(e) => handleDelete(e, row._id)} disabled={!row._id}>
            <Icon name='accordionDeleteIcon' icon='trashOutline' />
          </Button>
        </Tooltip>
      </Cell>
    </CheckPermission>
  )

  return (
    <Grid style={classes.grid}>
      <Cell size={12}>
        <VFlow style={classes.checkboxContainer}>
          <Checkbox
            label='Ver somente os lembretes criados por mim'
            onChange={() => {
              setSomenteMeus(!somenteMeus)
            }}
          />
        </VFlow>
      </Cell>
      <Cell size={12}>
        <AccordionDataTable<LembreteModel>
          {...accordionProps}
          columns={[
            {
              name: 'descricao',
              header: 'Descrição',
              render: (item) => <Text>{item.historicoLembrete[0].descricao.removeTags().truncate(100)}</Text>,
              size: 6,
            },
            {
              name: 'recente',
              header: '',
              render: (item) =>
                item._id && (
                  <Tooltip text='Registrado agora'>
                    <Icon icon='clockOutline' color={theme.pallete.primary.c40} size={1} />
                  </Tooltip>
                ),
              size: 1,
            },
            {
              name: 'visibilidade',
              header: 'Visibilidade',
              render: (item) => visibilidadeLembrete[item.historicoLembrete[0].visibilidadeLembrete],
              size: 2,
            },
            {
              name: 'ativo',
              header: 'Ativo',
              render: renderAtivo,
              size: 3,
            },
          ]}
          rows={paginatedItems}
          components={{
            AccordionPanel: LembretesAccordionPanel,
            Row: (props) => (
              <LembreteTableRow
                isEditing={isEditing(props.row)}
                onRowChanged={handleRowChanged}
                onAddRow={handleSubmit}
                removeLembreteEmEdicao={removeLembreteEmEdicao}
                {...props}
              />
            ),
          }}
          disableRow={isEditing}
          loading={loading}
        />
        <TableFooter {...tableProps} style={classes.footer} />
      </Cell>
    </Grid>
  )
}

interface ReadOnlyListaLembretesModalProps {
  prontuarioId: ID
  lotacao: LotacaoAtendimento
}

export function ReadOnlyListaLembretesModal(props: ReadOnlyListaLembretesModalProps) {
  const { prontuarioId, lotacao } = props

  const { resetExpandedItems, ...accordionProps } = useAccordionControl({})

  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  const [somenteMeus, setSomenteMeus] = useState<boolean>(false)

  const { data, loading } = useLembretesModalQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      lembretesQueryInput: {
        prontuarioId,
        somenteMeus,
      },
    },
  })

  const lembretesCacheDatabase = useMemo(() => mergeSortLembretes(data.lembretes ?? [], [], lotacao), [
    lotacao,
    data.lembretes,
  ])

  const { paginatedItems, tableProps } = usePagination<LembreteModel>({
    items: lembretesCacheDatabase,
    onChange: resetExpandedItems,
  })

  const renderAtivoLeitura = (row: LembreteModel) => (
    <Grid gap={0} gapVertical={0} alignItems='center' wrap={false}>
      <Cell size={6}>{row.historicoLembrete[0].ativo ? 'Sim' : 'Não'}</Cell>
    </Grid>
  )

  return (
    <Grid style={classes.grid}>
      <Cell size={12}>
        <VFlow style={classes.checkboxContainer}>
          <Checkbox
            label='Ver somente os lembretes criados por mim'
            onChange={() => {
              setSomenteMeus(!somenteMeus)
            }}
          />
        </VFlow>
      </Cell>
      <Cell size={12}>
        <AccordionDataTable<LembreteModel>
          {...accordionProps}
          columns={[
            {
              name: 'descricao',
              header: 'Descrição',
              render: (item) => <Text>{item.historicoLembrete[0].descricao.removeTags().truncate(100)}</Text>,
              size: 6,
            },
            {
              name: 'recente',
              header: '',
              render: (item) =>
                item._id && (
                  <Tooltip text='Registrado agora'>
                    <Icon icon='clockOutline' color={theme.pallete.primary.c40} size={1} />
                  </Tooltip>
                ),
              size: 1,
            },
            {
              name: 'visibilidade',
              header: 'Visibilidade',
              render: (item) => visibilidadeLembrete[item.historicoLembrete[0].visibilidadeLembrete],
              size: 2,
            },
            {
              name: 'ativo',
              header: 'Ativo',
              render: renderAtivoLeitura,
              size: 3,
            },
          ]}
          rows={paginatedItems}
          components={{
            AccordionPanel: LembretesAccordionPanel,
            Row: (props) => <LembreteTableRow {...props} />,
          }}
          loading={loading}
        />
        <TableFooter {...tableProps} style={classes.footer} />
      </Cell>
    </Grid>
  )
}

const styles = (theme: Theme) => ({
  footer: css`
    border-top: 0rem;
    margin-top: -0.1rem;
    padding-top: 0.1rem;
  `,
  checkboxContainer: css`
    border: 1px solid ${theme.pallete.divider};
    margin-bottom: -1.05rem;
    padding: 0.6rem 0.9rem;
  `,
  checkbox: css`
    margin: 0.5rem;
  `,
  grid: css`
    margin-top: 0.5rem;
  `,
})
