/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, Text } from 'bold-ui'
import { Fragment } from 'react'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { AlergiaReacaoRow } from './AlergiaReacaoRow'
import { AlergiaReacaoModel } from './model'

export interface AlergiasReacoesTableProps {
  alergias: AlergiaReacaoModel[]
  cidadao: CidadaoAtendimento

  handleEditItem(values: AlergiaReacaoModel): void
  handleRemoveItem(item: AlergiaReacaoModel): void
}

export function AlergiasReacoesTable(props: AlergiasReacoesTableProps) {
  const { alergias, cidadao, handleRemoveItem, handleEditItem } = props

  return (
    alergias?.length > 0 && (
      <Fragment>
        <Grid
          style={css`
            margin: 0;
            padding: 0 1rem;
          `}
        >
          <Cell size={4}>
            <Text fontWeight='bold'>Categoria do agente causador</Text>
          </Cell>
          <Cell size={5}>
            <Text fontWeight='bold'>Agente ou substância específica</Text>
          </Cell>
        </Grid>
        {alergias.map(
          (item) =>
            item.isAvaliacao && (
              <AlergiaReacaoRow
                key={item._id}
                model={item}
                onChange={handleEditItem}
                onRemove={handleRemoveItem}
                cidadao={cidadao}
              />
            )
        )}
      </Fragment>
    )
  )
}
