import { EncaminhamentoExterno, EncaminhamentoExternoQueryInput } from 'graphql/types.generated'
import moment from 'moment'
import { LotacaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { EncaminhamentoExternoRowModel, EncaminhamentoExternoTableRowModel } from '../list/EncaminhamentoExternoTable'
import { EncaminhamentoExternoModel } from '../model'

export function mergeEncaminhamentos(
  encaminhamentosDatabase: EncaminhamentoExternoTableRowModel[],
  encaminhamentosCache: EncaminhamentoExternoModel[],
  profissionalId: ID,
  tableState: EncaminhamentoExternoQueryInput,
  dataReferencia: Instant,
  atendimentoProfissionalId: ID,
  lotacao: LotacaoAtendimento | LotacaoFolhaRosto
): EncaminhamentoExternoRowModel[] {
  let encaminhamentosCacheConvertidos =
    encaminhamentosCache
      ?.map((value, id) => createEncaminhamentosFromCache(value, atendimentoProfissionalId, dataReferencia, lotacao))
      .filter(filterEncaminhamentosCache(tableState, profissionalId))
      .reverse() ?? []
  let encaminhamentosDatabaseConvertidos = encaminhamentosDatabase.map(createEncaminhamentosFromDatabase) ?? []
  return [...encaminhamentosCacheConvertidos, ...encaminhamentosDatabaseConvertidos]
}

const filterEncaminhamentosCache = (filter: EncaminhamentoExternoQueryInput, profissionalId: ID) => (
  value: EncaminhamentoExternoRowModel
) => {
  const queryNoAccents =
    filter.query !== undefined &&
    filter?.query
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()

  const searchTextFilter = () =>
    !filter.query ||
    value?.atendimentoProfissional?.lotacao.profissional.nome.toLowerCase().includes(queryNoAccents) ||
    value?.especialidadeSisreg?.descricao.toLowerCase().includes(queryNoAccents)

  const checkFilter = () =>
    !filter.somenteMeusEncaminhamentosExternos ||
    value?.atendimentoProfissional?.lotacao.profissional.id === profissionalId

  const periodFilter = () =>
    (!filter.periodo?.startDate ||
      moment(value?.atendimentoProfissional?.iniciadoEm).isSameOrAfter(filter.periodo?.startDate, 'date')) &&
    (!filter.periodo?.endDate ||
      moment(value?.atendimentoProfissional?.iniciadoEm).isSameOrBefore(filter.periodo?.endDate, 'date'))

  return searchTextFilter() && checkFilter() && periodFilter()
}

function createEncaminhamentosFromDatabase(
  encaminhamentosDatabase: EncaminhamentoExterno
): EncaminhamentoExternoRowModel {
  return {
    id: encaminhamentosDatabase.id,
    classificacaoRiscoEncaminhamento: encaminhamentosDatabase.classificacaoRiscoEncaminhamento,
    atendimentoProfissional: {
      id: encaminhamentosDatabase.atendimentoProfissional.id,
      iniciadoEm: encaminhamentosDatabase.atendimentoProfissional.iniciadoEm,
      lotacao: encaminhamentosDatabase.atendimentoProfissional.lotacao,
    },
    especialidadeSisreg: {
      id: encaminhamentosDatabase.especialidadeSisreg.id,
      descricao: encaminhamentosDatabase.especialidadeSisreg.descricao,
    },
    hipoteseDiagnostica: {
      id: encaminhamentosDatabase.hipoteseDiagnosticoCid10?.id ?? encaminhamentosDatabase.hipoteseDiagnosticoCiap.id,
      nome:
        encaminhamentosDatabase.hipoteseDiagnosticoCid10?.nome ??
        encaminhamentosDatabase.hipoteseDiagnosticoCiap.descricao,
    },
    isRegistradoAgora: false,
  }
}

function createEncaminhamentosFromCache(
  encaminhamentosCache: EncaminhamentoExternoModel,
  dataReferencia: Instant,
  atendimentoProfissionalId: ID,
  lotacao: LotacaoAtendimento | LotacaoFolhaRosto
): EncaminhamentoExternoRowModel {
  return {
    id: encaminhamentosCache._id,
    classificacaoRiscoEncaminhamento: encaminhamentosCache.classificacaoRiscoEncaminhamento,
    atendimentoProfissional: {
      id: atendimentoProfissionalId,
      iniciadoEm: dataReferencia,
      lotacao: lotacao,
    },
    especialidadeSisreg: {
      id: encaminhamentosCache.especialidadeSisreg.id,
      descricao: encaminhamentosCache.especialidadeSisreg.descricao,
    },
    hipoteseDiagnostica: {
      id: encaminhamentosCache?.cidEncaminhamento?.id ?? encaminhamentosCache.ciapEncaminhamento.id,
      nome: encaminhamentosCache?.cidEncaminhamento?.nome ?? encaminhamentosCache.ciapEncaminhamento.nome,
    },
    isRegistradoAgora: true,
  }
}
