import { IdadeFieldModel } from 'components/form'
import { TipoGravidezSelectFieldModel } from 'components/form/field/select/TipoGravidezSelectField'
import { TipoPartoSelectFieldModel } from 'components/form/field/select/TipoPartoSelectField'
import { SemanasDiasFieldModel } from 'components/form/field/SemanasDiasField'
import { differenceInDays, parseISO } from 'date-fns'
import {
  AlteracaoFenotipica,
  FatorRisco,
  MarcoDesenvolvimento,
  NeuroFaixaEtariaEnum,
  PuericulturaQuery,
  StatusAvaliadoEnum,
} from 'graphql/types.generated'
import { groupBy } from 'lodash'
import { calculateAge } from 'util/date/calculateAge'

import { AlteracoesFenotipicasModel } from './alteracao-fenotipica/AlteracaoFenotipicaView'
import { FatoresRiscoModel } from './fatores-risco/FatoresRiscoView'
import { MarcoFormSectionAccordionModel } from './marcos/MarcoFormSectionAccordion'
import { TipoAleitamentoFieldModel } from './prenatal-parto-nascimento/components/TipoAleitamentoField'
import { isFaixaEtariaAtual, medicaoPerimetroCefalicoMaisRecente } from './util'

export type Puericultura = PuericulturaQuery

export interface PrenatalPartoNascimentoModel {
  tipoParto?: TipoPartoSelectFieldModel
  tipoGravidez?: TipoGravidezSelectFieldModel
  idadeGestacional?: SemanasDiasFieldModel
  apgar?: {
    apgarUm?: number
    apgarCinco?: number
    apgarDez?: number
  }
  antropometriaAoNascer: {
    peso?: string
    comprimento?: string
    perimetroCefalico?: string
  }
}

export interface PuericulturaModel {
  hasPuericultura?: boolean
  dataNascimentoReferencia?: LocalDate
  prenatalPartoNascimento?: PrenatalPartoNascimentoModel
  tipoAleitamento?: TipoAleitamentoFieldModel
  fatoresRisco?: FatoresRiscoModel
  alteracoesFenotipicas?: AlteracoesFenotipicasModel
  marcosDesenvolvimento?: MarcoFormSectionAccordionModel
  medicaoAnteriorPerimetroCefalico: MedicaoAnteriorPuericulturaModel
}

export interface MedicaoAnteriorPuericulturaModel {
  dataMedicao: number
  valorMedicao: boolean | number
}

export interface AvaliacaoDesenvolvimentoModel {
  id?: ID
  descricao?: string
  dataAvaliacao?: LocalDate
  status?: StatusAvaliadoEnum
  statusButton?: StatusAvaliadoEnum
  disableButton?: boolean
  hint?: string
  faixaEtariaEnum?: NeuroFaixaEtariaEnum
  descricaoFaixaEtaria?: string
  alcancadoCom?: IdadeFieldModel
  faixaEtariaFim?: number
}

export const initialValueAlteracoesFenotipicas = (alteracoesFenotipicas: AlteracaoFenotipica[]) => {
  return {
    alteracoesFenotipicas: alteracoesFenotipicas
      .map((item) => ({
        ...item,
        statusButton: item.status,
        disableButton: item.status === StatusAvaliadoEnum.PRESENTE,
      }))
      .sort((a, b) => Number(a.id) - Number(b.id)),
  }
}

export const initialValueFatoresRisco = (fatoresRisco: FatorRisco[]) => {
  return {
    fatoresRisco: fatoresRisco
      .map((item) => ({ ...item, statusButton: item.status }))
      .sort((a, b) => Number(a.id) - Number(b.id)),
  }
}

export const initialValueMarcosDesenvolvimento = (
  marcosDesenvolvimento: MarcoDesenvolvimento[],
  dataAtendimento: Instant,
  cidadaoDataNascimento: string
): MarcoFormSectionAccordionModel => {
  const idadeCidadao = calculateAge(cidadaoDataNascimento, dataAtendimento)
  const idadeCidadaoEmDias = differenceInDays(dataAtendimento, parseISO(cidadaoDataNascimento))

  const mappedMarcosSort = marcosDesenvolvimento
    .sort((a, b) => Number(a.id) - Number(b.id))
    .map((item) => ({
      ...item,
      statusButton: item.status,
      alcancadoCom: isFaixaEtariaAtual(marcosDesenvolvimento, item, idadeCidadaoEmDias)
        ? { anos: idadeCidadao.years, meses: idadeCidadao.months }
        : { anos: item.alcancadoComAnos, meses: item.alcancadoComMeses },
    }))

  const marcosAgrupadoPorFaixaEtaria = groupBy(mappedMarcosSort, (item) => item.faixaEtariaEnum)

  return { marcosRecord: marcosAgrupadoPorFaixaEtaria } as MarcoFormSectionAccordionModel
}

export const initialValuesPuericulturaModel = (
  puericultura: Puericultura,
  cidadaoDataNascimento: LocalDate,
  dataAtendimento: Instant
): PuericulturaModel => ({
  prenatalPartoNascimento: undefined,
  hasPuericultura: puericultura.hasPuericultura,
  dataNascimentoReferencia: cidadaoDataNascimento,
  tipoAleitamento: undefined,
  alteracoesFenotipicas: initialValueAlteracoesFenotipicas(puericultura.alteracoesFenotipicas),
  fatoresRisco: initialValueFatoresRisco(puericultura.fatoresRisco),
  marcosDesenvolvimento: initialValueMarcosDesenvolvimento(
    puericultura.marcosDesenvolvimento,
    dataAtendimento,
    cidadaoDataNascimento
  ),
  medicaoAnteriorPerimetroCefalico: medicaoPerimetroCefalicoMaisRecente(puericultura.medicoesAnteriores, {
    dataMedicao: cidadaoDataNascimento,
    valorPerimetroCefalico: +puericultura.antecedente?.perimetroCefalico,
  }),
})
