import { TipoAtendimentoProfissional } from 'graphql/types.generated'

import { TipoAtendimentoFooter } from '../model'

interface AtendimentoFooterMessages {
  confirmTitle: string
  labelButton: string
}

const atendimentoIndividual: AtendimentoFooterMessages = {
  confirmTitle: 'Deseja cancelar o atendimento individual?',
  labelButton: 'Cancelar atendimento individual',
}

const atendimentoProcedimentos: AtendimentoFooterMessages = {
  confirmTitle: 'Deseja cancelar o atendimento de procedimentos?',
  labelButton: 'Cancelar atendimento de procedimentos',
}

const atendimentoOdontologico: AtendimentoFooterMessages = {
  confirmTitle: 'Deseja cancelar o atendimento odontológico?',
  labelButton: 'Cancelar atendimento odontológico',
}

const escutaInicial: AtendimentoFooterMessages = {
  confirmTitle: 'Deseja cancelar a escuta inicial?',
  labelButton: 'Cancelar escuta inicial',
}
const preAtendimento: AtendimentoFooterMessages = {
  confirmTitle: 'Deseja cancelar o pré-atendimento?',
  labelButton: 'Cancelar pré-atendimento',
}

const atendimentoVacinacao: AtendimentoFooterMessages = {
  confirmTitle: 'Deseja cancelar o atendimento de vacinação?',
  labelButton: 'Cancelar atendimento de vacinação',
}

export const atendimentoFooterMessage = (tipo: TipoAtendimentoFooter): AtendimentoFooterMessages => {
  switch (tipo) {
    case TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO:
      return atendimentoOdontologico
    case TipoAtendimentoProfissional.ESCUTA_INICIAL:
      return escutaInicial
    case TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS:
      return atendimentoProcedimentos
    case 'PRE_ATENDIMENTO':
      return preAtendimento
    case TipoAtendimentoProfissional.VACINACAO:
      return atendimentoVacinacao
    case TipoAtendimentoProfissional.CONSULTA:
    default:
      return atendimentoIndividual
  }
}
