import { HorarioAgendaSelectFieldModel } from 'components/form'
import { add, format, isAfter, isBefore, parseISO, startOfDay } from 'date-fns'
import { FORM_ERROR } from 'final-form'
import { createValidator, email, maxLength, required, telefone } from 'util/validation'

import { AgendamentoConsultaModel, AgendamentoReservaModel } from '../model'

const horarioReservaValidator = createValidator<HorarioAgendaSelectFieldModel>(
  {
    inicial: [required],
    final: [required],
  },
  (values, error) => {
    const valorHoraInicial = Number(values?.inicial)
    const valorHoraFinal = Number(values?.final)
    if (valorHoraInicial && valorHoraFinal && valorHoraInicial >= valorHoraFinal)
      error.final = 'Deve ser posterior ao horário inicial.'

    return error
  }
)

const horarioConsultaValidator = createValidator<HorarioAgendaSelectFieldModel>({
  inicial: [required],
})

export const agendamentoReservaValidator = (now: Date) =>
  createValidator<AgendamentoReservaModel>(
    {
      data: [required],
      horario: horarioReservaValidator,
      motivoReserva: [required],
      observacoes: [maxLength(200)],
    },
    (values, error) => {
      const hoje = startOfDay(now)
      const daqui6Meses = add(hoje, { months: 6, days: 1 })
      if (isAfter(parseISO(values.data), daqui6Meses)) {
        error.data = `Deve ser anterior à ${format(daqui6Meses, 'dd/MM/yyyy')}`
        error[FORM_ERROR] = 'Não é possível cadastrar agendamentos com mais de 6 meses de antecedência.'
      } else if (isBefore(values.horario?.inicial, now)) {
        error[FORM_ERROR] = 'Não é possível cadastrar agendamentos em horários passados.'
      }

      return error
    }
  )

export const agendamentoConsultaValidator = (now: Date) =>
  createValidator<AgendamentoConsultaModel>(
    {
      data: [required],
      horario: horarioConsultaValidator,
      observacoes: [maxLength(200)],
      cidadao: [required],
      telefoneCelular: [telefone],
      email: [email, maxLength(50)],
    },
    (values, error) => {
      const hoje = startOfDay(now)
      const daqui6Meses = add(hoje, { months: 6, days: 1 })
      if (isAfter(parseISO(values.data), daqui6Meses)) {
        error.data = `Deve ser anterior à ${format(daqui6Meses, 'dd/MM/yyyy')}`
        error[FORM_ERROR] = 'Não é possível cadastrar agendamentos com mais de 6 meses de antecedência.'
      }
      if (values.isForaUbs) {
        error.localAtendimento = required(values.localAtendimento)
      }
      if (values.cidadao?.contato.email) {
        error.email = required(values.email) || error.email
      }
      if (values.cidadao?.contato.telefoneCelular) {
        error.telefoneCelular = required(values.telefoneCelular) || error.telefoneCelular
      }

      return error
    }
  )
