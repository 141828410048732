import { HeadingSection } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { CidadaoCadsus, NacionalidadeEnum } from 'graphql/types.generated'
import React from 'react'

import CidadaoFormModel from '../types/CidadaoFormModel'
import { convertCadsusToFormModel } from '../types/convertModelToInput'
import { CidadaoDadosForm } from './CidadaoDadosForm'

export interface CadsusAtmosphereResponse {
  uuid: string
}

export function CidadaoCreateView() {
  const cidadaoCadsus = JSON.parse(sessionStorage.getItem('cidadaoCadsus')) as CidadaoCadsus

  /*
    @DeLorean
    https://github.com/laboratoriobridge/pec/pull/10135
    Quando o CADSUS voltar, buscar as informações deletadas no PR acima
  */

  const initialValues = !!cidadaoCadsus
    ? convertCadsusToFormModel(cidadaoCadsus)
    : ({
        nacionalidade: NacionalidadeEnum.BRASILEIRA,
        endereco: { pais: { id: '31', nome: 'BRASIL' } },
      } as CidadaoFormModel)

  return (
    <PageContent type='filled'>
      <Breadcrumb title='Cadastro do cidadão' />
      <HeadingSection level={1} title='Cadastro do cidadão'>
        <CidadaoDadosForm initialValues={initialValues} />
      </HeadingSection>
    </PageContent>
  )
}
