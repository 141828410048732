/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Heading, Modal, ModalBody, VFlow } from 'bold-ui'
import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { MetaPath } from 'util/metaPath'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { AdicionarLembreteForm } from './AdicionarLembreteForm'
import { LembreteFormModel } from './components/LembreteForm'
import { EditableListaLembretesModal, ReadOnlyListaLembretesModal } from './ListaLembretesModal'

interface LembretesModalProps {
  prontuarioId: ID
  name?: MetaPath<LembreteFormModel>
  lotacao: LotacaoAtendimento
  lembretesCache?: LembreteFormModel[]
}

export function LembretesModal(props: LembretesModalProps) {
  const { name, prontuarioId, lotacao, lembretesCache } = props

  const history = useHistory()
  const classes = styles()
  const match = useRouteMatch()

  const onClose = () => history.push(match.url.replace('/lembretes', ''))

  return (
    <Modal open={true} onClose={onClose}>
      <ModalBody>
        <VFlow style={classes.title}>
          <Heading level={1}>Lembretes</Heading>
          <Alert inline type='info'>
            Os lembretes são informações para os profissionais de saúde.
          </Alert>
        </VFlow>
        {name ? (
          <React.Fragment>
            <AdicionarLembreteForm name={name} />
            <EditableListaLembretesModal
              prontuarioId={prontuarioId}
              lotacao={lotacao}
              name={name}
              lembretesCache={lembretesCache}
            />
          </React.Fragment>
        ) : (
          <ReadOnlyListaLembretesModal lotacao={lotacao} prontuarioId={prontuarioId} />
        )}
      </ModalBody>
    </Modal>
  )
}

const styles = () => ({
  title: css`
    margin-bottom: 1rem;
  `,
})
