import { parseISO } from 'date-fns'
import { ProblemasECondicoesInput } from 'graphql/types.generated'
import { calculateAge } from 'util/date/calculateAge'

import { Problema } from '../../../aside/types/ProblemaModel'
import { DataProblemaFieldModel } from './DataProblemaField'
import { ProblemaCondicaoModel } from './model'

export const convertProblemaCondicao = (
  input: Problema,
  dataNascimentoCidadao: LocalDate,
  value?: ProblemaCondicaoModel
) => {
  return {
    ...problemaAttr(input, dataNascimentoCidadao, value),
    incluirNaListaProblemas: !!input,
  } as ProblemaCondicaoModel
}

export const convertDataProblema = (data: any, dataNascimentoCidadao: Date) => {
  const { years, months } = calculateAge(+dataNascimentoCidadao, data || Date.now())

  return {
    data: data,
    idade: data && {
      anos: years,
      meses: months,
    },
  } as DataProblemaFieldModel
}

const problemaAttr = (
  input: Problema,
  dataNascimentoCidadao: LocalDate,
  value: ProblemaCondicaoModel
): Partial<ProblemaCondicaoModel> => {
  const dataInicio = value ? value.dataInicio?.data : input?.ultimaEvolucao?.dataInicio
  const dataFim = value ? value.dataFim?.data : input?.ultimaEvolucao?.dataFim

  return {
    problemaId: input?.id,
    ciap: input?.ciap && { id: input?.ciap.id, nome: input?.ciap.descricao, codigo: input?.ciap.codigo },
    cid: input?.cid10 && { id: input?.cid10.id, nome: input?.cid10.nome, codigo: input?.cid10.codigo },
    situacaoProblema: value ? value.situacaoProblema : input?.situacao,
    dataInicio: dataInicio && convertDataProblema(dataInicio, parseISO(dataNascimentoCidadao)),
    dataFim: dataFim && convertDataProblema(dataFim, parseISO(dataNascimentoCidadao)),
    observacao: value ? value.observacao : input?.ultimaEvolucao?.observacao,
  }
}

export const convertLPCFormToInput = (form: ProblemaCondicaoModel): ProblemasECondicoesInput => ({
  cidId: form.cid?.id,
  ciapId: form.ciap?.id,
  situacao: form.situacaoProblema,
  dataFim: form.dataFim?.data,
  dataInicio: form.dataInicio?.data,
  observacao: form.observacao,
  automatico: form.automatico ?? false,
})
