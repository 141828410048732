import React from 'react'

import { AccordionCardSection } from '../components/AccordionCardSection'
import { ContentCardSection } from '../components/ContentCardSection'
import { Problema } from '../types/ProblemaModel'
import { ProblemasListCard } from './components/ProblemasListCard'
export interface ProblemasCardProps {
  problemasExpandido: Problema[]
  problemasAccordion: Problema[]
  loading: boolean
  canRender: boolean
  readOnly?: boolean
}

export function ProblemasCard(props: ProblemasCardProps) {
  const { loading, problemasExpandido, problemasAccordion, canRender, readOnly } = props

  return (
    <>
      <ContentCardSection isLoading={loading} messageLoading='Carregando dados dos problemas' canRender={canRender}>
        <ProblemasListCard problemas={problemasExpandido} readOnly={readOnly} />
      </ContentCardSection>

      {problemasAccordion.length > 0 && (
        <AccordionCardSection
          id='ProblemasCard'
          title={{
            closed: `Mostrar + ${problemasAccordion.length} problemas`,
            open: `Esconder problemas`,
          }}
        >
          <ProblemasListCard problemas={problemasAccordion} readOnly={readOnly} />
        </AccordionCardSection>
      )}
    </>
  )
}
