import { DateRange } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useCallback, useEffect, useState } from 'react'

import { PivotTableProps } from './PivotTable'
import { OperationsPaths, TreeBuilder, TreeMeta } from './TreeBuilder'
import { Aggregator, Dictionary } from './types'

interface TreeBuilderHookProps<T> {
  keyMapping: PivotTableProps<T>['keyMapping']
  operations?: OperationsPaths
  data?: T[]
}

export function useTreeBuilder<T>(props: TreeBuilderHookProps<T>) {
  const { keyMapping, operations, data } = props

  const [metaState, setMetaState] = useState<TreeMeta<T>>()

  const [defaulTreeState, setDefaulTreeState] = useState<Dictionary<T>>()

  const [complementaryTreeState, setComplementaryTreeState] = useState<Dictionary<T>>()

  const [maxLeafValueState, setMaxLeafValueState] = useState<number>()

  const [filterState, setFilterState] = useState<DateRange>()

  const [periodUnit, setPeriodUnit] = useState<string>()

  const [filtroCiapCid, setFiltroCiapCid] = useState<string[]>()

  const [treeBuilder] = useState<TreeBuilder<T>>(new TreeBuilder(data, operations))

  const [buildingState, setBuildState] = useState(false)

  const alert = useAlert()

  const warning = useCallback((msg: any) => alert('danger', msg.error), [alert])

  const setDateFilter = useCallback((filter: DateRange, periodUnit: string) => {
    setFilterState(filter)
    setPeriodUnit(periodUnit)
  }, [])

  const setCiapCidFilter = useCallback((filtroCiapCid: Set<string>) => {
    setFiltroCiapCid([...filtroCiapCid.values()])
  }, [])

  const demolish = useCallback(() => {
    setComplementaryTreeState(undefined)
    setDefaulTreeState(undefined)
  }, [])

  const build = useCallback(
    function <K extends keyof T>(
      rowKeys: Array<K>,
      columnKeys: Array<K>,
      aggregator: Aggregator,
      aggregatorKey: keyof T,
      filterKeys?: Map<K, Set<string>>
    ) {
      setBuildState(true)
      if (rowKeys.length > 0 && columnKeys.length > 0) {
        treeBuilder
          .build(
            [...columnKeys, ...rowKeys],
            filterState,
            periodUnit,
            filtroCiapCid,
            aggregator,
            aggregatorKey,
            filterKeys
          )
          .then((data) => {
            setComplementaryTreeState(data)
            setBuildState(false)
          })
          .catch(warning)
      }
      treeBuilder
        .build(
          [...rowKeys, ...columnKeys],
          filterState,
          periodUnit,
          filtroCiapCid,
          aggregator,
          aggregatorKey,
          filterKeys
        )
        .then((data) => {
          setDefaulTreeState(data)
          setMaxLeafValueState(treeBuilder.maxLeafValue)
          setBuildState(false)
        })
        .catch(warning)
    },
    [filterState, periodUnit, filtroCiapCid, treeBuilder, warning]
  )

  useEffect(() => {
    if (filterState !== undefined || data !== undefined) {
      setMetaState(undefined)
      treeBuilder.meta(keyMapping, filterState, periodUnit, filtroCiapCid).then(setMetaState).catch(warning)
    }
  }, [data, filterState, keyMapping, periodUnit, filtroCiapCid, treeBuilder, warning])

  return {
    meta: metaState,
    maxLeafValue: maxLeafValueState,
    defaultTree: defaulTreeState,
    complementaryTree: complementaryTreeState,
    isBuilding: buildingState,
    setDateFilter: setDateFilter,
    setCiapCidFilter: setCiapCidFilter,
    build: build,
    demolish: demolish,
  }
}
