import api from 'api'
import { ImpressaoCadernetaVacinacaoInput } from 'api/impressaoCadernetaVacinacao'
import moment from 'moment'
import { downloadFile } from 'util/downloadFile'

const asFormat = (val) => moment(val).format('YYYY-MM-DD_hhmm')

export const downloadCadernetaVacinacao = (input: ImpressaoCadernetaVacinacaoInput) => {
  return api.cadernetaVacinacao
    .imprimirCadernetaVacinacao(input)
    .then((response) => {
      downloadFile(new Blob([response.data]), `CadernetaVacinacao_${asFormat(moment().toDate())}.pdf`)
      return response
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('loadend', (e) => {
          reject((e.srcElement as any).result)
        })
        reader.readAsText(error.response.data)
      })
    })
}
