import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

/**
 * based on https://github.com/WebDevSimplified/useful-custom-react-hooks/blob/main/src/8-useStorage/useStorage.js
 */

export function useLocalStorage<T = any>(key: string, defaultValue?: T, modifier?: (value: T) => T) {
  return useStorage(key, defaultValue, window.localStorage, modifier)
}

export function useSessionStorage<T = any>(key: string, defaultValue?: T, modifier?: (value: T) => T) {
  return useStorage(key, defaultValue, window.sessionStorage, modifier)
}

function useStorage<T = any>(
  key: string,
  defaultValue: T,
  storageObject: Storage,
  modifier?: (value: T) => T
): [T, Dispatch<SetStateAction<T>>, () => void] {
  const [value, setValue] = useState<T>(() => {
    const jsonValue = storageObject.getItem(key)

    if (jsonValue != null) {
      const parsedValue = JSON.parse(jsonValue)
      return modifier ? modifier(parsedValue) : parsedValue
    } else {
      return typeof defaultValue === 'function' ? defaultValue() : defaultValue
    }
  })

  useEffect(() => {
    if (value === undefined) storageObject.removeItem(key)
    else storageObject.setItem(key, JSON.stringify(value))
  }, [key, value, storageObject])

  const remove = useCallback(() => {
    storageObject.removeItem(key)
    setValue(undefined)
  }, [key, storageObject])

  return [value, setValue, remove]
}
