import { Cell, FormControl, Grid, Paper, Text, Theme, useStyles, VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { ErrorField } from 'components/form'
import { RadioFieldContainer } from 'components/form/field/RadioFieldContainer'
import { resolveValue } from 'components/form/final-form/useField'
import React from 'react'
import { useFormState } from 'react-final-form'
import Permissions from 'types/Permissions'
import { MetaPath } from 'util/metaPath'
import { EncaminhamentoForm } from 'view/atendimentos/detail/soap/desfecho/components/EncaminhamentoForm'
import { EncaminhamentoInternoFormModel } from 'view/atendimentos/model'
import { AgendamentoDia } from 'view/atendimentos/types/AgendamentoDia'

export enum ManterCidadaoLista {
  SIM = 'SIM',
  NAO = 'NAO',
}

export interface DesfechoAtendimentoFormModel {
  manterCidadaoLista: ManterCidadaoLista
  atendimento?: EncaminhamentoInternoFormModel
}

export interface DesfechoAtendimentoPanelProps {
  name: MetaPath<DesfechoAtendimentoFormModel>
  agendamentosDia?: AgendamentoDia[]
  labelManterCidadaoLista?: string
  labelPanel?: string
}

DesfechoAtendimentoPanel.defaultProps = {
  labelPanel: 'Desfecho do atendimento',
  labelManterCidadaoLista: 'Manter cidadão na lista de atendimentos',
} as DesfechoAtendimentoPanelProps

export function DesfechoAtendimentoPanel(props: DesfechoAtendimentoPanelProps) {
  const { name, agendamentosDia, labelManterCidadaoLista, labelPanel } = props

  const { values: formValues } = useFormState({ subscription: { values: true } })
  const { classes } = useStyles(createStyles)

  const manterCidadaoLista = resolveValue(formValues, name.manterCidadaoLista) === ManterCidadaoLista.SIM

  return (
    <FormControl label={labelPanel} required>
      <VFlow vSpacing={0.5}>
        <Grid>
          <Cell size={12}>
            <RadioFieldContainer name={name.manterCidadaoLista} value={ManterCidadaoLista.NAO}>
              <Text> Liberar cidadão </Text>
            </RadioFieldContainer>
          </Cell>
          <CheckPermission permission={Permissions.visualizarListaDeAtendimento.cadastrarEditarEExcluir}>
            <Cell size={12}>
              <VFlow>
                <RadioFieldContainer name={name.manterCidadaoLista} value={ManterCidadaoLista.SIM}>
                  <Text>{labelManterCidadaoLista}</Text>
                </RadioFieldContainer>
                {manterCidadaoLista && (
                  <Paper style={classes.dadosAtendimento}>
                    <EncaminhamentoForm name={name.atendimento} agendamentosDia={agendamentosDia} />
                  </Paper>
                )}
                <ErrorField name={name.atendimento} />
              </VFlow>
            </Cell>
          </CheckPermission>
        </Grid>
      </VFlow>
    </FormControl>
  )
}

const createStyles = (theme: Theme) => ({
  dadosAtendimento: {
    padding: '1rem',
    borderColor: theme.pallete.gray.c60,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 'none',
    marginTop: '-1.1rem',
  },
})
