/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Checkbox, HFlow, Tag, Text, VFlow } from 'bold-ui'
import { TipoReceitaEnum } from 'graphql/types.generated'
import React, { useCallback } from 'react'
import { tipoReceitaRecord } from 'types/enums'

import { MedicamentoFormModel } from '../../MedicamentoFormModel'
import ImpressaoBoxItem from './ImpressaoBoxItem'
import MedicamentoImpressoInfoLabel from './MedicamentoImpressoInfoLabel'

interface MedicamentoToPrintListProps {
  medicamentosToPrint: MedicamentoFormModel[]
  alreadyPrinted(medicamentoId: ID): boolean
  onChange(checked: boolean, medicamento: MedicamentoFormModel): void
}

export default function MedicamentoToPrintList(props: MedicamentoToPrintListProps) {
  const { medicamentosToPrint, alreadyPrinted, onChange } = props

  const printed = useCallback((id: ID) => alreadyPrinted(id), [alreadyPrinted])

  const resolveMedicamento = useCallback((medicamento: MedicamentoFormModel) => {
    const principioAtivo = medicamento.registroManual
      ? medicamento.principioAtivoText
      : medicamento.principioAtivoCombo.principioAtivo.nome

    const concentracao = !medicamento.registroManual
      ? medicamento.principioAtivoCombo.medicamento.concentracao
      : !!medicamento.concentracaoText
      ? medicamento.concentracaoText
      : ''

    const tipoReceita = medicamento.registroManual
      ? medicamento.tipoReceita.tipoReceitaEnum
      : medicamento.principioAtivoCombo.principioAtivo.listaMaterial.tipoReceita

    return { principioAtivo, concentracao, tipoReceita }
  }, [])

  const renderMedicamento = useCallback(
    (medicamento: MedicamentoFormModel, index: number) => {
      const { principioAtivo, concentracao, tipoReceita } = resolveMedicamento(medicamento)

      return (
        <ImpressaoBoxItem key={medicamento.id} data-testid={`Medicamento${index}`}>
          <HFlow>
            <Checkbox
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, medicamento)}
              defaultChecked
              data-testid={`Medicamento${index}Checkbox`}
            />
            <VFlow vSpacing={0.4}>
              <Text
                fontWeight='bold'
                data-testid={`PrincipioAtivo${index}`}
              >{`${principioAtivo} ${concentracao}`}</Text>
              <HFlow>
                {medicamento.usoContinuo && (
                  <Tag
                    type='normal'
                    style={css`
                      align-self: center;
                      max-height: 1.5rem;
                    `}
                  >
                    Uso contínuo
                  </Tag>
                )}
                {tipoReceita !== TipoReceitaEnum.COMUM && (
                  <Tag
                    type='info'
                    style={css`
                      align-self: center;
                      max-height: 1.5rem;
                    `}
                  >
                    {tipoReceitaRecord[tipoReceita]}
                  </Tag>
                )}
              </HFlow>
              <Text data-testid={`Posologia${index}`}>{medicamento.posologia}</Text>
            </VFlow>
            {printed(medicamento.id) && <MedicamentoImpressoInfoLabel />}
          </HFlow>
        </ImpressaoBoxItem>
      )
    },
    [onChange, printed, resolveMedicamento]
  )

  return (
    <VFlow
      vSpacing={0.5}
      style={css`
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      `}
    >
      {medicamentosToPrint.map((medicamento, index) => renderMedicamento(medicamento, index))}
    </VFlow>
  )
}
