/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Tooltip } from 'bold-ui'
import { isAfter, isBefore } from 'date-fns'
import { dateAsDdMmYyyy } from 'util/date/formatDate'

interface DayDelimiterTooltipProps {
  day: Date
  minDate: Date
  maxDate: Date
}

const createTooltipText = (value: DayDelimiterTooltipProps) => {
  const { day, minDate, maxDate } = value

  if (isBefore(day, minDate)) return `Data do atendimento deve ser posterior ou igual à ${dateAsDdMmYyyy(minDate)}`
  if (isAfter(day, maxDate)) return `Data do atendimento deve ser anterior ou igual à ${dateAsDdMmYyyy(maxDate)}`
  return undefined
}

export function DayDelimiterTooltip(props: DayDelimiterTooltipProps) {
  return (
    <Tooltip text={createTooltipText(props)}>
      <span>{props.day.getDate()}</span>
    </Tooltip>
  )
}
