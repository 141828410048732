import { HFlow, Omit, Text } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { EquipeSelectFieldDocument } from 'graphql/hooks.generated'
import { EquipeSelectFieldQuery, EquipeSelectFieldQueryVariables } from 'graphql/types.generated'
import React from 'react'
import { capitalize } from 'util/strConversor'

import { DEFAULT_SELECT_PAGE_PARAM, useAsyncQuerySelect } from '../useAsyncQuerySelect'

export type EquipeSelectModel = EquipeSelectFieldQuery['unidadeSaude']['equipes']['content'][0]

export interface EquipeSelectFieldProps extends Omit<SelectFieldProps<EquipeSelectModel>, 'items' | 'itemToString'> {
  unidadeSaudeId: ID
  mostrarInativas?: boolean
}

export function EquipeSelectField(props: EquipeSelectFieldProps) {
  const { unidadeSaudeId, mostrarInativas, ...rest } = props

  const asyncProps = useAsyncQuerySelect<EquipeSelectModel, EquipeSelectFieldQuery, EquipeSelectFieldQueryVariables>({
    query: EquipeSelectFieldDocument,
    extractItems: (data) => data && data.unidadeSaude && data.unidadeSaude.equipes && data.unidadeSaude.equipes.content,
    variables: (inputQuery) => ({
      input: {
        query: inputQuery,
        mostrarInativas,
        pageParams: {
          ...DEFAULT_SELECT_PAGE_PARAM,
          sort: ['nome'],
        },
      },
      unidadeSaudeId,
    }),
  })

  const itemToString = (option: EquipeSelectModel) => option && option.nome

  const renderItem = (item: EquipeSelectModel) => (
    <>
      <Text fontWeight='bold'>
        {capitalize(item.nome)} – {item.tipoEquipe.sigla}
      </Text>
      <HFlow hSpacing={0.5}>
        <Text fontWeight='bold'>INE:</Text>
        {item.ine}
      </HFlow>
    </>
  )

  return (
    <SelectField<EquipeSelectModel> itemToString={itemToString} renderItem={renderItem} {...asyncProps} {...rest} />
  )
}
