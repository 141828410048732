import { VFlow } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { RichTextField } from 'components/form/final-form/RichTextField'
import React from 'react'
import { FormSpy } from 'react-final-form'
import { MetaPath } from 'util/metaPath/metaPath'
import { AtendimentoProfissional } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { grupoCboEncaminhamentoExterno, grupoCboOrientacoes } from './acessos'
import AtestadoView, { AtestadoPlanoModel } from './atestados/AtestadosView'
import {
  IntervencoesProcedimentosPlanoModel,
  IntervencoesProcedimentosView,
} from './components/IntervencoesProcedimentosView'
import { PlanoTabs, PlanoTabsEnum } from './components/PlanoTabs'
import { DeclaracaoComparecimentoFormView } from './declaracao-comparecimento/DeclaracaoComparecimentoFormView'
import EncaminhamentoExternoView from './encaminhamento-externo/components/EncaminhamentoExternoView'
import { EncaminhamentoExternoPlanoModel } from './encaminhamento-externo/model'
import EvolucoesOdontologicasView, { OdontologiaModel } from './evolucoes-odontologicas/EvolucoesOdontologicasView'
import { ExamesView } from './exames/ExamesView'
import { SolicitacaoExameModel } from './exames/model'
import { OrientacoesView } from './orientacoes/OrientacoesView'
import { OrientacaoFormModel } from './orientacoes/types/OrientacoesModel'
import PrescricaoMedicamentoView, {
  PrescricaoMedicamentoPlanoModel,
} from './prescricao-medicamento/PrescricaoMedicamentoView'

export interface PlanoFormModel {
  texto?: string
  atestado?: AtestadoPlanoModel
  solicitacoesExame?: SolicitacaoExameModel[]
  prescricaoMedicamento?: PrescricaoMedicamentoPlanoModel
  odontologia?: OdontologiaModel
  encaminhamentoExterno?: EncaminhamentoExternoPlanoModel
  orientacao?: OrientacaoFormModel[]
  intervencoesProcedimentos?: IntervencoesProcedimentosPlanoModel
}

export interface PlanoFormProps {
  name: MetaPath<PlanoFormModel>
  cidadao: CidadaoAtendimento
  prontuarioId: ID
  isAtendimentoOdonto: boolean
  isAtendimentoProcedimentos: boolean
  atendimentoId: ID
  atendimentoProfissional: AtendimentoProfissional
  dataAtendimento: Instant
  registroTardio: boolean
}

export const PlanoForm = (props: PlanoFormProps) => {
  const {
    name,
    cidadao,
    prontuarioId,
    isAtendimentoOdonto,
    isAtendimentoProcedimentos,
    atendimentoId,
    atendimentoProfissional,
    dataAtendimento,
    registroTardio,
  } = props

  return (
    <>
      {!isAtendimentoOdonto && (
        <RichTextField
          name={name.texto}
          maxLength={4000}
          placeholder='Insira informações adicionais sobre o plano de cuidado.'
        />
      )}

      <VFlow>
        <IntervencoesProcedimentosView
          cidadao={cidadao}
          name={name.intervencoesProcedimentos}
          isAtendimentoOdonto={isAtendimentoOdonto}
          isAtendimentoProcedimentos={isAtendimentoProcedimentos}
          dataAtendimento={dataAtendimento}
        />

        {isAtendimentoOdonto && (
          <EvolucoesOdontologicasView
            name={name.odontologia}
            prontuarioId={prontuarioId}
            cidadaoDataNascimento={cidadao.dataNascimento}
            dataInicioAtendimento={atendimentoProfissional.iniciadoEm}
          />
        )}

        <PlanoTabs isAtendimentoProcedimentos={isAtendimentoProcedimentos}>
          {(currentTab) => (
            <FormSpy subscription={{ values: true, pristine: true }}>
              {(soap) => (
                <>
                  {currentTab === PlanoTabsEnum.ATESTADO && (
                    <AtestadoView
                      name={name.atestado}
                      prontuarioId={prontuarioId}
                      cidadao={cidadao}
                      atendimentoProfissional={atendimentoProfissional}
                      atendimentoId={atendimentoId}
                    />
                  )}
                  {currentTab === PlanoTabsEnum.EXAMES && (
                    <ExamesView
                      name={name.solicitacoesExame}
                      cidadao={cidadao}
                      prontuarioId={prontuarioId}
                      atendimentoId={atendimentoId}
                      atendimentoProfissional={atendimentoProfissional}
                    />
                  )}
                  {currentTab === PlanoTabsEnum.ORIENTACOES && (
                    <CheckPermission permission={grupoCboOrientacoes.visualizar}>
                      <OrientacoesView
                        name={name.orientacao}
                        cidadao={cidadao}
                        prontuarioId={prontuarioId}
                        atendimentoId={atendimentoId}
                        orientacoes={soap.values?.plano?.orientacao}
                        atendimentoProfissional={atendimentoProfissional}
                      />
                    </CheckPermission>
                  )}
                  {currentTab === PlanoTabsEnum.ENCAMINHAMENTO_EXTERNO && (
                    <CheckPermission permission={grupoCboEncaminhamentoExterno}>
                      <EncaminhamentoExternoView
                        name={name.encaminhamentoExterno}
                        prontuarioId={prontuarioId}
                        atendimentoId={atendimentoId}
                        cidadao={cidadao}
                        isAtendimentoOdonto={isAtendimentoOdonto}
                        cboAtendProf={atendimentoProfissional.lotacao.cbo.cbo2002}
                        atendimentoProfissional={atendimentoProfissional}
                      />
                    </CheckPermission>
                  )}
                  {currentTab === PlanoTabsEnum.DECLARACAO_COMPARECIMENTO && (
                    <DeclaracaoComparecimentoFormView atendId={atendimentoId} />
                  )}
                  {currentTab === PlanoTabsEnum.PRESCRICAO_MEDICAMENTOS && (
                    <PrescricaoMedicamentoView
                      name={name.prescricaoMedicamento}
                      prontuarioId={prontuarioId}
                      atendimentoId={atendimentoId}
                      medicamentos={soap.values?.plano?.prescricaoMedicamento?.medicamentos}
                      cidadaoDataNascimento={cidadao.dataNascimento}
                      cidadaoSexo={cidadao.sexo}
                      isAtendimentoProcedimentos={isAtendimentoProcedimentos}
                      dataReferencia={dataAtendimento}
                      registroTardio={registroTardio}
                    />
                  )}
                </>
              )}
            </FormSpy>
          )}
        </PlanoTabs>
      </VFlow>
    </>
  )
}
