/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, colors, Grid, Heading, Text, VFlow } from 'bold-ui'
import { Color } from 'csstype'

import { SoapValidationModalItemModel } from './model'
import { SoapValidationFieldError } from './SoapValidationFieldError'

interface SoapValidationSectionErrorProps {
  section: SoapValidationModalItemModel
  color?: Color
}

export const SoapValidationSectionError = (props: SoapValidationSectionErrorProps) => {
  const { section, color = colors.gray.c10 } = props

  if (!section?.fields.length && !section?.error) return null

  const isFieldsExpanded = section.fields?.length === 1

  return (
    <VFlow vSpacing={0.5}>
      <Heading
        level={3}
        style={css`
          color: ${color};
        `}
      >
        {section.label}
      </Heading>

      <Grid>
        {section.error && (
          <Cell size={12}>
            <Text>{section.error}</Text>
          </Cell>
        )}

        {section.fields?.map((field) => (
          <SoapValidationFieldError key={field.label} item={field} expanded={isFieldsExpanded} />
        ))}
      </Grid>
    </VFlow>
  )
}
