import { Button, Cell, Grid, HFlow, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacao } from 'components/auth/useAcessoLotacao'
import { isUnidadeSaudeCEO } from 'components/auth/useSessionUtils'
import { useErrorHandler } from 'components/error'
import {
  CidadaoAtendimentoSelectField,
  CidadaoAtendimentoSelectFieldModel,
  EquipeSelectField,
  Form,
  FormRenderProps,
  SubmitButton,
} from 'components/form'
import { FormApi } from 'final-form'
import createDecorator from 'final-form-calculate'
import { useApolloClient } from 'graphql/hooks'
import { useAgendamentosDiaLazyQuery, useCidadaoLazyQuery, useSalvarAtendimentoMutation } from 'graphql/hooks.generated'
import { SituacaoAgendadoEnum } from 'graphql/types.generated'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router'
import { metaPath } from 'util/metaPath'
import { createValidator, required } from 'util/validation'
import { TipoServicoCheckField } from 'view/configuracoes/municipal/tipo-servico/TipoServicoCheckField'
import { LotacaoSelectField } from 'view/profissional/lotacao/LotacaoSelectField'

import { AgendamentosDiaField } from '../agenda/AgendamentosDiaField'
import { convertNovoAtendimentoModelToInput } from '../converter'
import { createNovoAtendimentoCalculations } from '../detail/soap/desfecho/calculator/calculatorNovoAtendimento'
import { EncaminhamentoInternoFormModel } from '../model'
import { resolveEquipeTooltip, resolveProfissionalTooltip } from '../tooltipResolver'

export interface NovoAtendimentoFormModel extends EncaminhamentoInternoFormModel {
  cidadao?: CidadaoAtendimentoSelectFieldModel
}

const path = metaPath<NovoAtendimentoFormModel>()

const validate = createValidator<NovoAtendimentoFormModel>({
  cidadao: [required],
})

export interface ListaAtendimentoFormProps {
  serverTime: Date
  cidadaoId?: string
}

const cidadaoCalculator = {
  field: path.cidadao.absolutePath(),
  updates: {
    [path.agendado.absolutePath()]: (value, allValues: NovoAtendimentoFormModel) => {
      if (allValues.agendado) {
        return undefined
      }
    },
  },
}

export function ListaAtendimentoForm(props: ListaAtendimentoFormProps) {
  const { cidadaoId, serverTime } = props

  const apollo = useApolloClient()
  const alert = useAlert()
  const history = useHistory()
  const { unidadeSaude } = useAcessoLotacao()
  const isCEO = isUnidadeSaudeCEO(unidadeSaude)
  const handleRejection = useErrorHandler()
  const [salvarAtendimentoMutation] = useSalvarAtendimentoMutation()
  const [executeCidadaoQuery, { data: cidadaoData }] = useCidadaoLazyQuery({ variables: { id: cidadaoId } })
  const [executeAgendamentosDiaQuery, { data: agendamentosDiaData }] = useAgendamentosDiaLazyQuery()
  const today = moment(serverTime).startOf('day').toDate().getTime()

  const executeAgendamentosDiaCidadaoQuery = useCallback(
    (cidadaoId: ID) =>
      executeAgendamentosDiaQuery({
        variables: {
          input: {
            cidadaoId: cidadaoId,
            dataAgendadoInicio: new Date(today),
            dataAgendadoFim: new Date(today),
            situacao: [SituacaoAgendadoEnum.AGENDADO],
            unidadeSaudeId: unidadeSaude.id,
            isForaDaUbs: false,
            isOrigemAtencaoDomiciliar: false,
          },
        },
      }),
    [executeAgendamentosDiaQuery, today, unidadeSaude.id]
  )

  useEffect(() => {
    if (cidadaoId) {
      executeCidadaoQuery({ variables: { id: cidadaoId } })
      executeAgendamentosDiaCidadaoQuery(cidadaoId)
    }
  }, [cidadaoId, executeCidadaoQuery, executeAgendamentosDiaCidadaoQuery])

  const initialValues: NovoAtendimentoFormModel = useMemo(
    () => ({
      cidadao: cidadaoId && cidadaoData && { ...cidadaoData.cidadao, presenteListaAtendimento: false },
    }),
    [cidadaoData, cidadaoId]
  )

  const handleCidadaoChange = (newValue: CidadaoAtendimentoSelectFieldModel) => {
    if (newValue && newValue.id) executeAgendamentosDiaCidadaoQuery(newValue.id)
  }

  const handleSubmit = (value: NovoAtendimentoFormModel, formApi: FormApi) => {
    !value.cidadao.dataNascimento
      ? alert(
          'danger',
          'Não é possível adicionar o cidadão. Atualize o cadastro do cidadão informando a data de nascimento.'
        )
      : save(value, formApi)
  }

  const save = (value: NovoAtendimentoFormModel, formApi: FormApi) =>
    salvarAtendimentoMutation({
      variables: {
        input: convertNovoAtendimentoModelToInput(value),
      },
    })
      .then((res) => onSubmitSuccess(res, formApi))
      .catch(handleRejection)

  const onSubmitSuccess = (result, formApi: FormApi) => {
    alert('success', `Cidadão foi adicionado com sucesso.`)
    formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
    setTimeout(formApi.reset)
    history.push('/lista-atendimento')
    return result
  }

  const renderForm = (formProps: FormRenderProps<NovoAtendimentoFormModel>) => {
    const values = formProps.values

    return (
      <Grid>
        <Cell size={5}>
          <VFlow>
            <CidadaoAtendimentoSelectField
              name={path.cidadao}
              ativo={true}
              obito={false}
              label='Cidadão'
              onChange={handleCidadaoChange}
              addCidadaoCallbackUrl='/lista-atendimento'
              required
            />

            {values?.cidadao && (
              <AgendamentosDiaField
                name={path.agendado}
                agendamentosDia={values?.cidadao && agendamentosDiaData?.agendados?.content}
              />
            )}

            <Tooltip text={resolveProfissionalTooltip(values)}>
              <LotacaoSelectField
                name={path.lotacao}
                label='Profissional'
                unidadeSaudeId={unidadeSaude.id}
                disabled={(values?.equipe && !values?.lotacao) || !!values?.agendado}
              />
            </Tooltip>

            {!isCEO && (
              <Tooltip text={resolveEquipeTooltip(values)}>
                <EquipeSelectField
                  name={path.equipe}
                  label='Equipe'
                  unidadeSaudeId={unidadeSaude.id}
                  disabled={!!values?.lotacao || !!values?.agendado}
                />
              </Tooltip>
            )}
          </VFlow>
        </Cell>

        <Cell size={7}>
          <div>
            <TipoServicoCheckField name={path.tiposServico} />
          </div>
        </Cell>

        <Cell alignSelf='flex-end' size={12}>
          <HFlow justifyContent='flex-end'>
            <Button size='medium' onClick={formProps.form.reset}>
              Limpar campos
            </Button>
            <SubmitButton size='medium' handleSubmit={formProps.handleSubmit}>
              Adicionar
            </SubmitButton>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  const decorator = useMemo(
    () => createDecorator(...createNovoAtendimentoCalculations(path, apollo), cidadaoCalculator),
    [apollo]
  )
  return (
    <Form<NovoAtendimentoFormModel>
      onSubmit={handleSubmit}
      render={renderForm}
      decorators={[decorator]}
      validate={validate}
      initialValues={initialValues}
    />
  )
}
