import api from 'api'
import { ImpressaoAgendamentoInput } from 'api/ImpressaoAgenda'
import { downloadFile } from 'util/downloadFile'

export const downloadImpressaoAgendamentos = (input: ImpressaoAgendamentoInput) => {
  return api.impressaoAgendamento
    .gerarAgendamentos(input)
    .then((response) => {
      downloadFile(
        new Blob([response.data]),
        `Agendamentos_${input.nomeProfissional.split(' ').join('_')}_${new Date().getTime()}.pdf`
      )
      return response
    })
    .catch((error) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('loadend', (e) => {
          reject((e.srcElement as any).result)
        })
        reader.readAsText(error.response.data)
      })
    })
}
