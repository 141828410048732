import { isUndefinedOrNull } from 'util/checks'
import { getFieldLabel } from 'util/fields-labels/getFieldLabel'
import { getFieldLabelProperties } from 'util/fields-labels/getFieldLabelProperties'
import { getGroupFieldLabel } from 'util/fields-labels/getGroupFieldLabel'
import { GroupLabel, Label, LabelMap } from 'util/fields-labels/model'
import { MetaPath } from 'util/metaPath'
import { SoapState } from 'view/atendimentos/atendimento-individual/model'

import { AntecedentesFormModel } from './antecedentes/AntecedentesForm'
import { AntecedentePuericulturaModel } from './antecedentes/model'
import { AvaliacaoFormModel } from './avaliacao/AvaliacaoForm'
import { DesfechoFormModel } from './desfecho'
import { ObjetivoFormModel } from './objetivo'
import { PrenatalPartoNascimentoModel } from './objetivo/puericultura/model'
import { PlanoFormModel } from './plano/PlanoForm'
import { PreNatalFormModel } from './pre-natal/model'
import { SubjetivoFormModel } from './subjetivo'

export type RequiredSoapLabelModules = Required<
  Pick<SoapState, 'antecedentes' | 'subjetivo' | 'objetivo' | 'avaliacao' | 'plano' | 'desfecho' | 'preNatal'>
>

const DEFAULT_ERROR_MESSAGE = 'Existem campos preenchidos de forma incorreta.'

export const createSoapFieldsLabels = (): LabelMap<RequiredSoapLabelModules> => {
  return {
    antecedentes: createAntecedentesFieldLabel(),
    subjetivo: createSubjetivoFieldLabel(),
    objetivo: createObjetivoFieldLabel(),
    avaliacao: createAvaliacaoFieldLabel(),
    plano: createPlanoFieldLabel(),
    desfecho: createDesfechoFieldLabel(),
    preNatal: createPreNatalFieldLabel(),
  }
}

const createPreNatalFieldLabel = (): Label<PreNatalFormModel> => ({
  label: 'Pré natal',
  fields: {
    alturaUterina: { label: 'Altura uterina (cm)' },
    batimentoCardiacoFetal: { label: 'Batimento cardíaco fetal (bpm)' },
    edema: { label: 'Edema' },
    gravidezPlanejada: { label: 'Gravidez planejada' },
    movimentacaoFetal: { label: 'Movimentação fetal' },
    tipoGravidez: { label: 'Tipo de gravidez' },
  },
})

const createAntecedentesFieldLabel = (): Label<AntecedentesFormModel> => ({
  label: 'Ancentedentes',
  fields: {
    familiar: { label: '', fields: { ciaps: { label: 'Lista de problemas dos familiares' } } },
    pessoal: {
      label: '',
      fields: {
        texto: { label: 'Campo aberto', hideLabelOnScreen: true },
        cirurgiasInternacoes: { label: 'Antecedentes hospitalares' },
        informacoesObstetricas: {
          label: 'Informações obstétricas',
          fields: {
            abortos: { label: 'Abortos' },
            filhosVivos: { label: 'Vivem' },
            gestaPrevias: { label: 'Gestas prévias' },
            nascidosVivos: { label: 'Vivos' },
            natimortos: { label: 'Mortos' },
            obitoAntesPrimeiraSemana: { label: 'Mortos na 1ª semana' },
            obitoAposPrimeiraSemana: { label: 'Mortos depois da 1ª semana' },
            partoMenosDeUmAno: { label: 'Desfecho da última gestação aconteceu há menos de um ano' },
            partos: { label: 'Partos realizados' },
            partosCesareas: { label: 'Cesáreas' },
            partosDomiciliares: { label: 'Partos domiciliares' },
            partosVaginais: { label: 'Vaginais' },
            recemNascidoAbaixo: { label: 'Menos de 2500g' },
            recemNascidoAcima: { label: 'Mais de 4500g' },
            dataAtualizacao: null,
          },
        },
        puericultura: createPreNatalPartoNascimentoFieldLabel(),
      },
    },
    formAtivo: null,
  },
})

const createSubjetivoFieldLabel = (): Label<SubjetivoFormModel> => ({
  label: 'Subjetivo',
  fields: {
    texto: { label: 'Campo aberto', hideLabelOnScreen: true },
    motivos: { label: 'Motivo da consulta (CIAP 2)' },
  },
})

const createObjetivoFieldLabel = (): Label<ObjetivoFormModel> => ({
  label: 'Objetivo',
  fields: {
    texto: { label: 'Campo aberto', hideLabelOnScreen: true },
    dum: { label: 'DUM' },
    medicoes: {
      label: 'Antropometria, sinais vitais e glicemia capilar',
      fields: {
        altura: { label: 'Altura (cm)' },
        circunferenciaAbdominal: { label: 'Circunferência abdominal (cm)' },
        frequenciaCardiaca: { label: 'Frequência cardíaca (bpm)' },
        frequenciaRespiratoria: { label: 'Frequência respiratória (mpm)' },
        glicemia: { label: 'Glicemia capilar (mg/dL)' },
        perimetroCefalico: { label: 'Perímetro cefálico (cm)' },
        perimetroPanturrilha: { label: 'Perímetro da panturrilha (cm)' },
        peso: { label: 'Peso (kg)' },
        pressaoArterialDiastolica: { label: 'Pressão arterial (mmHg)' },
        pressaoArterialSistolica: { label: 'Pressão arterial (mmHg)' },
        saturacaoO2: { label: 'Saturação de O2 (%)' },
        temperatura: { label: 'Temperatura (°C)' },
        tipoGlicemia: { label: 'Momento da coleta' },
      },
    },
    resultadosExames: { label: 'Resultados de exames' },
    atendimentosEspecificos: {
      label: 'Atendimentos específicos',
      hideLabelOnModal: true,
      fields: {
        formAtivo: null,
        puericultura: {
          label: 'Puericultura',
          fields: {
            prenatalPartoNascimento: createPreNatalPartoNascimentoFieldLabel(),
            tipoAleitamento: { label: 'Tipo de aleitamento' },
            alteracoesFenotipicas: { label: 'Alterações fenotípicas', extractError: () => DEFAULT_ERROR_MESSAGE },
            fatoresRisco: { label: 'Fatores de risco', extractError: () => DEFAULT_ERROR_MESSAGE },
            marcosDesenvolvimento: { label: 'Marcos de desenvolvimento', extractError: () => DEFAULT_ERROR_MESSAGE },
            dataNascimentoReferencia: null,
            hasPuericultura: null,
            medicaoAnteriorPerimetroCefalico: null,
          },
        },
      },
    },
    necessidadesEspeciais: { label: 'Paciente com necessidades especiais' },
    vacinacaoEmDia: { label: 'Vacinação em dia?' },
  },
})

const createAvaliacaoFieldLabel = (): Label<AvaliacaoFormModel> => ({
  label: 'Avaliação',
  fields: {
    texto: { label: 'Campo aberto', hideLabelOnScreen: true },
    problemasECondicoes: { label: 'Problemas e/ou condições avaliados neste atendimento' },
    alergias: { label: 'Alergias e reações adversas' },
    encerrarGestacao: {
      label: 'Encerrar gestação',
      fields: {
        dataDesfecho: { label: 'Data do desfecho' },
        tipoGravidez: { label: 'Tipo de gravidez' },
      },
    },
    necessidadeProtese: { label: 'Necessidade de prótese inferior/superior' },
    tiposVigilancia: { label: 'Vigilância em saúde bucal' },
  },
})

const createPlanoFieldLabel = (): Label<PlanoFormModel> => ({
  label: 'Plano',
  fields: {
    texto: {
      label: 'Campo aberto',
      hideLabelOnScreen: true,
    },
    atestado: { label: 'Atestados' },
    encaminhamentoExterno: { label: 'Encaminhamentos' },
    intervencoesProcedimentos: {
      label: 'Intervenções e/ou procedimentos clínicos realizados',
      fields: {
        ciaps: { label: 'CIAP 2' },
        procedimentos: { label: 'SIGTAP' },
        error: null,
      },
      extractError: (error) => error.error,
      hideChildrens: (error) => !!error.error,
    },
    odontologia: {
      label: 'Evoluções odontológicas',
      fields: {
        limiteProcedimentos: { label: '' },
        odontograma: { label: 'Odontograma', hideChildrens: true, extractError: () => DEFAULT_ERROR_MESSAGE },
        periodontia: {
          label: 'Periodontia',
          fields: {
            periogramaSimplificado: {
              label: 'Periograma simplificado',
              fields: {
                avaliacaoSextante1: { label: 'S1' },
                avaliacaoSextante2: { label: 'S2' },
                avaliacaoSextante3: { label: 'S3' },
                avaliacaoSextante4: { label: 'S4' },
                avaliacaoSextante5: { label: 'S5' },
                avaliacaoSextante6: { label: 'S6' },
                observacao: { label: 'Observações' },
                error: null,
              },
              hideChildrens: (error) => !!error.error,
              extractError: (error) => error.error,
            },
            periogramaCompleto: {
              label: 'Periograma completo',
              hideLabelOnModal: (error) => isUndefinedOrNull(error.observacao),
              fields: {
                dados: { label: 'Periograma completo', extractError: () => DEFAULT_ERROR_MESSAGE },
                observacao: { label: 'Observações' },
              },
            },
            evolucoes: { label: 'Registros de procedimentos' },
          },
        },
        tecidosMoles: { label: 'Tecidos moles e duros' },
      },
    },
    prescricaoMedicamento: {
      label: 'Prescrição de medicamentos',
      fields: { medicamentos: { label: 'Prescrição de medicamentos' }, alreadyPrinted: null, replicateStorage: null },
    },
    orientacao: { label: 'Orientações ao cidadão' },
    solicitacoesExame: { label: 'Solicitação de exames' },
  },
})

const createDesfechoFieldLabel = (): Label<DesfechoFormModel> => ({
  label: 'Finalização do atendimento',
  fields: {
    conduta: { label: 'Conduta' },
    condutasOdonto: { label: 'Conduta' },
    tipoAtendimento: { label: 'Tipo de atendimento' },
    tipoConsulta: { label: 'Tipo de consulta' },
    agendamentoConsulta: {
      label: 'Agendar consulta',
      fields: {
        data: { label: 'Data do agendamento' },
        horario: { label: 'Horário do agendamento', extractError: (error) => error.inicial as string },
        lotacao: { label: 'Profissional' },
        observacoes: { label: 'Observações' },
      },
    },
    atendimentoCompartilhado: { label: 'Atendimento compartilhado' },
    desfechoAtendimento: {
      label: '',
      fields: {
        manterCidadaoLista: { label: 'Liberar cidadão' },
        atendimento: {
          label: '',
          fields: {
            agendado: { label: 'Próximos agendamentos do dia' },
            equipe: { label: 'Equipe' },
            lotacao: { label: 'Profissional' },
            tiposServico: { label: 'Tipo de serviço' },
          },
        },
      },
    },
    fichasNotifiacaoCasoSuspeito: { label: 'Ficha de notificação de caso suspeito' },
    fornecimento: { label: 'Fornecimento' },
    procedimentosAdministrativos: { label: 'Procedimentos administrativos (SIGTAP)' },
    racionalidadeEmSaude: { label: 'Racionalidade em saúde (Exceto alopatia/convencional)' },
  },
})

const createPreNatalPartoNascimentoFieldLabel = (): Label<
  PrenatalPartoNascimentoModel & AntecedentePuericulturaModel
> => ({
  label: 'Pré-natal, parto e nascimento',
  fields: {
    apgar: {
      label: 'Apgar',
      fields: {
        apgarUm: { label: '1º minuto' },
        apgarCinco: { label: '5º minuto' },
        apgarDez: { label: '10º minuto' },
      },
    },
    antropometriaAoNascer: {
      label: 'Antropometria ao nascer',
      fields: {
        comprimento: { label: 'Comprimento (cm)' },
        perimetroCefalico: { label: 'Perímetro cefálico (cm)' },
        peso: { label: 'Peso (kg)' },
      },
    },
    idadeGestacional: { label: 'Idade gestacional' },
    tipoGravidez: { label: 'Tipo de gravidez' },
    tipoParto: { label: 'Tipo de parto' },
    dataAtualizacao: null,
  },
})

const soapFieldsLabels = createSoapFieldsLabels()

export const getSoapFieldLabel = (fieldName: MetaPath<any>): string => {
  return getFieldLabel(fieldName, soapFieldsLabels)
}

export const getSoapGroupFieldLabel = <FieldModel>(fieldName: MetaPath<FieldModel>): GroupLabel<FieldModel> => {
  return getGroupFieldLabel(fieldName, soapFieldsLabels)
}

export const getSoapFieldLabelProperties = (fieldName: any): Label<any> => {
  return getFieldLabelProperties(fieldName, soapFieldsLabels)
}
