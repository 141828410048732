import { isEmpty } from 'lodash'
import { isObjectDeepEmpty } from 'util/object'
import {
  afterBirthdate,
  afterEqualTo,
  beforeEqualTo,
  createValidator,
  ErrorObject,
  maxLength,
  range,
  required,
  validate,
} from 'util/validation'

import {
  AllResultadoEspecificoValueType,
  ExameQueryModel,
  FactoryResultadoExameEspecificoValidatorProps,
  ResultadoExameComSolicitacaoModel,
  ResultadoExameEspecificoModel,
  ResultadoExameQueryModel,
  ResultadoExameSemSolicitacaoModel,
  ResultadosExamesModel,
  ResultadosExamesNaoSolicitadosModel,
  ResultadosExamesSolicitadosModel,
} from '../model'
import { getResultadoExameEspecificoValidator, isExameEspecifico, removePrefix } from '../util'

export const adicionarResultadosExamesValidator = (
  cidadaoDataNascimento: LocalDate,
  resultadosSolicitados: Record<ID, ResultadoExameQueryModel>,
  dataInicioAtendimento: LocalDate
) =>
  createValidator<ResultadosExamesModel>({
    resultadosComSolicitacao: resultadosComSolicitacaoValidator(
      cidadaoDataNascimento,
      dataInicioAtendimento,
      resultadosSolicitados
    ),
    resultadosSemSolicitacao: resultadosSemSolicitacaoValidator(cidadaoDataNascimento, dataInicioAtendimento),
  })

const resultadosSemSolicitacaoValidator = (dataNascimento: LocalDate, dataInicioAtendimento: LocalDate) =>
  createValidator<ResultadosExamesNaoSolicitadosModel>({}, (values, errors) => {
    if (!isEmpty(values)) {
      Object.entries(values).forEach(([key, value]) => {
        if (!isEmpty(value)) {
          const { resultado, dataRealizado, dataResultado, dataSolicitado, exame } = value

          errors[key] = exameCommonValidator(
            exame,
            resultado,
            null,
            dataInicioAtendimento,
            dataNascimento,
            dataResultado,
            dataRealizado,
            dataSolicitado,
            false
          )
        }
      })

      return errors
    }
  })

const resultadosComSolicitacaoValidator = (
  dataNascimento: LocalDate,
  dataInicioAtendimento: LocalDate,
  resultadosSolicitados: Record<ID, ResultadoExameQueryModel>
) =>
  createValidator<ResultadosExamesSolicitadosModel>({}, (values, errors) => {
    if (!isEmpty(values) && resultadosSolicitados) {
      Object.entries(values).forEach(([key, entries]) => {
        if (!isObjectDeepEmpty(entries.resultado)) {
          const { dataRealizado, dataResultado, resultado } = entries
          const dataSolicitado = resultadosSolicitados[removePrefix(key)].solicitacao.data
          const exame = resultadosSolicitados[removePrefix(key)].exame

          errors[key] = exameCommonValidator(
            exame,
            resultado,
            entries.descricao,
            dataInicioAtendimento,
            dataNascimento,
            dataResultado,
            dataRealizado,
            dataSolicitado,
            true
          )
        }
      })

      return errors
    }
  })

const exameCommonValidator = (
  exame: ExameQueryModel,
  resultado: string & AllResultadoEspecificoValueType,
  descricao: string,
  dataInicioAtendimento: LocalDate,
  dataNascimento: LocalDate,
  dataResultado: LocalDate,
  dataRealizado: LocalDate,
  dataSolicitado: LocalDate,
  isSolicitado: boolean
) => {
  const isEspecifico = isExameEspecifico(exame)

  if (isEspecifico) {
    return exameEspecificoValidator(
      exame,
      resultado,
      descricao,
      dataRealizado,
      dataResultado,
      dataNascimento,
      dataSolicitado,
      dataInicioAtendimento
    )
  }

  if (isSolicitado) {
    return exameComSolicitacaoValidator(
      resultado,
      dataRealizado,
      dataResultado,
      dataNascimento,
      dataSolicitado,
      dataInicioAtendimento
    )
  }

  return exameSemSolicitacaoValidator(
    resultado,
    dataRealizado,
    dataResultado,
    dataNascimento,
    dataSolicitado,
    dataInicioAtendimento
  )
}

const exameComSolicitacaoValidator = (
  resultado: string,
  dataRealizado: LocalDate,
  dataResultado: LocalDate,
  dataNascimento: LocalDate,
  dataSolicitado: LocalDate,
  dataInicioAtendimento: LocalDate
): ErrorObject<ResultadoExameComSolicitacaoModel> => {
  return {
    resultado: maxLength(2000)(resultado),
    dataRealizado: validateDataRealizado(
      dataRealizado,
      dataResultado,
      dataNascimento,
      dataSolicitado,
      dataInicioAtendimento
    ),
    dataResultado: validateDataResultado(
      dataResultado,
      dataNascimento,
      dataSolicitado,
      dataRealizado,
      dataInicioAtendimento
    ),
  }
}

const exameSemSolicitacaoValidator = (
  resultado: string,
  dataRealizado: LocalDate,
  dataResultado: LocalDate,
  dataNascimento: LocalDate,
  dataSolicitado: LocalDate,
  dataInicioAtendimento: LocalDate
): ErrorObject<ResultadoExameSemSolicitacaoModel> => {
  return {
    resultado: required(resultado) || maxLength(2000)(resultado),
    dataSolicitado: validateDataSolicitado(dataSolicitado, dataNascimento, dataInicioAtendimento),
    dataRealizado: validateDataRealizado(
      dataRealizado,
      dataResultado,
      dataNascimento,
      dataSolicitado,
      dataInicioAtendimento
    ),
    dataResultado: validateDataResultado(
      dataResultado,
      dataNascimento,
      dataSolicitado,
      dataRealizado,
      dataInicioAtendimento
    ),
  }
}

const exameEspecificoValidator = (
  exame: ExameQueryModel,
  resultado: AllResultadoEspecificoValueType,
  descricao: string,
  dataRealizado: LocalDate,
  dataResultado: LocalDate,
  dataNascimento: LocalDate,
  dataSolicitado: LocalDate,
  dataInicioAtendimento: LocalDate
): ErrorObject<ResultadoExameEspecificoModel> => {
  const resultadoValidator = getResultadoExameEspecificoValidator(exame)
  const args = {
    dataRealizado,
    dataSolicitado,
    dataNascimento,
  }

  return {
    resultado: resultadoValidator({ value: resultado, args }),
    descricao: maxLength(2000)(descricao),
    dataSolicitado: validateDataSolicitado(dataSolicitado, dataNascimento, dataInicioAtendimento),
    dataRealizado: validateDataRealizado(
      dataRealizado,
      dataResultado,
      dataNascimento,
      dataSolicitado,
      dataInicioAtendimento
    ),
    dataResultado: validateDataResultado(
      dataResultado,
      dataNascimento,
      dataSolicitado,
      dataRealizado,
      dataInicioAtendimento
    ),
  }
}

const validateDataResultado = (
  dataResultado: LocalDate,
  dataNascimento: LocalDate,
  dataSolicitado: LocalDate,
  dataRealizado: LocalDate,
  dataInicioAtendimento: LocalDate
) =>
  dataResultado &&
  (afterBirthdate(dataResultado, dataNascimento) ||
    afterEqualTo(dataResultado, dataSolicitado, 'data de solicitação') ||
    afterEqualTo(dataResultado, dataRealizado, 'data de realização') ||
    beforeEqualTo(dataResultado, dataInicioAtendimento, 'data do atendimento'))

const validateDataRealizado = (
  dataRealizado: LocalDate,
  dataResultado: LocalDate,
  dataNascimento: LocalDate,
  dataSolicitado: LocalDate,
  dataInicioAtendimento: LocalDate
) =>
  required(dataRealizado) ||
  afterBirthdate(dataRealizado, dataNascimento) ||
  afterEqualTo(dataRealizado, dataSolicitado, 'data de solicitação') ||
  beforeEqualTo(dataRealizado, dataInicioAtendimento, 'data do atendimento') ||
  (dataResultado && beforeEqualTo(dataRealizado, dataResultado, 'data do resultado'))

const validateDataSolicitado = (
  dataSolicitado: LocalDate,
  dataNascimento: LocalDate,
  dataInicioAtendimento: LocalDate
) =>
  dataSolicitado &&
  (afterBirthdate(dataSolicitado, dataNascimento) ||
    beforeEqualTo(dataSolicitado, dataInicioAtendimento, 'data do atendimento'))

export const validateResultadoExamePrenatal = (
  props: FactoryResultadoExameEspecificoValidatorProps
): string | ErrorObject<AllResultadoEspecificoValueType> => {
  const { value, args } = props
  const { dataRealizado, dataSolicitado, dataNascimento } = args || {}

  const dpp = value?.dpp
  const idadeGestacional = value?.idadeGestacional

  const semanasValidation = validate(idadeGestacional?.semanas, [required, range(0, 42)])
  const diasValidation = validate(idadeGestacional?.dias, [range(0, 6)])

  const nenhumPreenchido = !dpp && isObjectDeepEmpty(idadeGestacional)

  return {
    error: nenhumPreenchido && 'É necessário o preenchimento de Idade gestacional eco ou DPP eco.',
    dpp:
      dpp &&
      (required(dpp) ||
        afterBirthdate(dpp, dataNascimento) ||
        afterEqualTo(dpp, dataSolicitado, 'data de solicitação') ||
        (dataRealizado && afterEqualTo(dpp, dataRealizado, 'data de realização'))),
    idadeGestacional:
      !isObjectDeepEmpty(idadeGestacional) &&
      ((semanasValidation && `Semanas ${semanasValidation.toLowerCase()}`) ||
        (diasValidation && `Dias ${diasValidation.toLowerCase()}`)),
  }
}

export const validateResultadoExamePuericultura = (
  props: FactoryResultadoExameEspecificoValidatorProps
): string | ErrorObject<AllResultadoEspecificoValueType> => {
  const { value } = props

  return validate(value, [required])
}
