/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, Heading, HFlow, Text, Theme, Tooltip, useTheme, VFlow } from 'bold-ui'
import { Box } from 'components/Box'
import { MouseEventHandler } from 'react'

type AsideSectionType = 'clickable' | 'flat'

export interface AsideSectionProps {
  title: React.ReactNode
  subTitle?: string
  tooltip?: string
  children: React.ReactNode
  content?: React.ReactElement
  onClick?: MouseEventHandler<HTMLDivElement>
  titleBackgroundColor?: string
  style?: ExternalStyles
}

export function ProntuarioCard(props: AsideSectionProps) {
  const { title, subTitle, children, tooltip, onClick, titleBackgroundColor, style, content } = props
  const theme = useTheme()
  const classes = createStyles(theme, onClick ? 'clickable' : 'flat', titleBackgroundColor)

  return (
    <Tooltip text={tooltip} placement='top-start'>
      <Box style={[classes.box, style]} onClick={onClick}>
        <HFlow style={classes.header} alignItems='center'>
          <VFlow vSpacing={0}>
            <Heading level={3}>{title}</Heading>

            {subTitle && <Text fontStyle='italic'>{subTitle}</Text>}
          </VFlow>
          {content}
        </HFlow>
        {children}
      </Box>
    </Tooltip>
  )
}

const createStyles = (theme: Theme, type: AsideSectionType, titleBackgroundColor) => ({
  box: css`
    border: 0.063rem solid ${type === 'flat' ? theme.pallete.gray.c80 : theme.pallete.gray.c60};
    border-radius: ${theme.radius.modal}px;
    box-sizing: border-box;
    box-shadow: ${type !== 'flat' && theme.shadows.outer[10]};
    padding: 0;
    margin-bottom: 0.375rem;
    cursor: ${type === 'clickable' && 'pointer'};
    &:hover {
      box-shadow: ${type !== 'flat' && theme.shadows.outer[40]};
    }
  }`,
  header: css`
    background: ${titleBackgroundColor ?? theme.pallete.gray.c90};
    padding: 0.5rem 1rem;
    justify-content: space-between;
    border-radius: ${theme.radius.modal}px;
  `,
})
