/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, focusBoxShadow, HFlow, Icon } from 'bold-ui'
import { AccordionAnimation } from 'components/accordion/AccordionAnimation'
import theme from 'config/theme'
import React, { memo } from 'react'
import { AccordionItem, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'

export interface AccordionCompositeItemProps {
  header: React.ReactNode
  headerButtons?: React.ReactNode
  body: React.ReactNode
  animated: boolean
  dangerouslySetExpanded?: boolean
  onClick?(): void
  style?: ExternalStyles
}

export const AccordionCompositeItem = (props: AccordionCompositeItemProps) => {
  const { animated, style, dangerouslySetExpanded, onClick, ...rest } = props

  return (
    <AccordionItem
      css={[styles.accordionItem, style]}
      dangerouslySetExpanded={dangerouslySetExpanded}
      onClick={onClick}
    >
      {animated ? (
        <AccordionAnimation>
          <AccordionButton {...rest} />
        </AccordionAnimation>
      ) : (
        <AccordionButton {...rest} />
      )}
    </AccordionItem>
  )
}

const AccordionButton = memo((props: Omit<AccordionCompositeItemProps, 'animated'>) => {
  const { body, header, headerButtons } = props

  return (
    <>
      <AccordionItemButton css={styles.accordionButton}>
        <HFlow style={styles.accordionButtonContent}>
          <Icon
            name='accordionAngleIcon'
            icon='angleDown'
            size={1.5}
            style={css`
              margin-left: 0.5rem;
              min-width: 1.5rem;
            `}
          />
          {header}
          {headerButtons && <div onClick={(e) => e.stopPropagation()}>{headerButtons}</div>}
        </HFlow>
      </AccordionItemButton>
      <AccordionItemPanel css={styles.accordionItemPanel}>{body}</AccordionItemPanel>
    </>
  )
})

AccordionCompositeItem.defaultProps = {
  animated: false,
} as AccordionCompositeItemProps

const styles = {
  accordionItem: css`
    border: 1px solid ${theme.pallete.gray.c60};
    border-radius: 2px;
    &:focus-within {
      outline: none;
      box-shadow: ${focusBoxShadow(theme, 'primary')};
      transition-property: box-shadow;
      transition-duration: 0.2s;
    }
  `,
  accordionButton: css`
    display: flex;
    cursor: pointer;
    border-radius: ${theme.radius.input}px;
    border: 0px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:hover {
      background-color: ${theme.pallete.gray.c90};
    }
    &[aria-expanded='true'] {
      border-bottom: 1px solid ${theme.pallete.gray.c80};
    }
    &[aria-expanded='false'] {
      border-bottom: 0px;
    }
    &[aria-expanded='true'] svg[name='accordionAngleIcon'],
    &[aria-selected='true'] svg[name='accordionAngleIcon'] {
      transform: rotate(180deg);
      transition: 0.4s;
    }
    &[aria-expanded='false'] svg[name='accordionAngleIcon'] {
      transform: rotate(0deg);
      transition: 0.4s;
    }
  `,
  accordionButtonContent: css`
    align-items: center;
    background-color: ${theme.pallete.surface.background};
    border-radius: 2px;
    display: flex;
    padding: 0.5rem;
    width: 100%;
    word-break: break-word;
  `,
  accordionItemPanel: css`
    flex: 1;
    background-color: ${theme.pallete.surface.main};
    & > :not(:last-child) {
      border-bottom: 1px solid ${theme.pallete.gray.c80};
    }
  `,
}
